import { useSearchParams } from "react-router-dom"

export default function useSearchParamState(paramName, defaultValue, parseFn) {
  const [searchParams, setSearchParams] = useSearchParams()

  // whenever setValue is called, it creates a new record in the browser history
  const setValue = (value) => {
    console.log(paramName," setValue: ",value)
    if(value){
      searchParams.set(paramName,value)
    } else {
      searchParams.delete(paramName)
    }
    console.log(searchParams)
    setSearchParams(searchParams)
  }

  let value = searchParams.get(paramName) ?? defaultValue
  if(parseFn && value) value = parseFn(value)

  return [value,setValue]
}