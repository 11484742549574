import React, { useEffect, useState, useMemo } from "react";
import DynamicForm from "../../../components/DynamicForm";
import SetupModal from "../../../components/SetupModal";
import API from "../../../../services/API";
import DataTable from 'react-data-table-component';
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

import equipmentQCSearch from "../../../forms/equipmentQCSearch";
import equipmentQCModal from "../../../forms/equipmentQCModal";
import ErrorModal from "../../../components/ErrorModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import subMenuParameters from "../../../forms/submenuEQCParameter"
import VisitTimer from "../../../components/VisitTimer";
import Session from "../../../../services/Session"

function EquipmentQCSetup(props){
  let subMenuString = 'Parameter'
  const [filterValues,setFilterValues] = useState();
  const [equipment, setEquipment] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [showSetupModal,setShowSetupModal] = useState(false);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [descriptionOptions, setDescriptionOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const [editValues, setEditValues] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const [eqcParameters, setEQCParameters] = useState([])
  const [paramDescOptions, setParamDescOptions] = useState([])
  const [showSubConfirmationModal, setShowSubConfirmationModal] = useState(false)
  const [showSubAdd, setShowSubAdd] = useState(false)
  const [subEditValues, setSubEditValues] = useState([])
  const [isSubMenu, setIsSubMenu] = useState(true)
  const [addNewSub, setAddNewSub] = useState(false)
  const [isSubEditMode, setIsSubEditMode] = useState(false)

  const onFilterChanged = (values) => {
    setFilterValues(values);
  }

  const onEditChange = (values) => {
    setEditValues(values)
  }

  const onSubEditChange = (values) => {
    setSubEditValues(values)
  }

  const onSave = () => {
    if(!isSaving) {
      setIsSaving(true)
      let data = {
        'category':editValues['category'],
        'description':editValues['description'],
        'eqc_serial':editValues['eqc_serial'],
        'manufacture_date':editValues['manufacture_date'],
        'manufacturer':editValues['manufacturer'],
        'owner_status':editValues['owner_status'],
        'eqc_parameter':editValues['eqc_parameter'],
        'status':(editValues['status'] && editValues['status'] == 'false')?false:true,
        'facilities':editValues['facilities']
      }
      let method = isEditMode ? 'PUT' : 'POST'
      let uri = isEditMode ? `/api/equipmentqc/${editValues.id}/` : '/api/equipmentqc/'
      API.query(method,uri,{},data, function(resp) {
        let newEquipment = equipment
        if(isEditMode) {
          let i = newEquipment.findIndex(e => e.id == editValues.id)
          if(i > -1) {
            data['id'] = editValues.id
            newEquipment[i] = data
          }
        }else {
          data['id'] = resp.id
          newEquipment.push(data)
        }
        setEquipment(newEquipment)
        setIsSaving(false)
        setShowSetupModal(false)
        setEditValues([])
        API.query("GET",`/api/eqcparameters/`,{},{}, function(resp) {
          setEQCParameters(resp)
        }, onDataLoadError)
      },onDataLoadError)
    }
  }

  const onSubSave = () => {
    if(!isSaving) {
      setIsSaving(true)
      let data = {
        'eqc_description':subEditValues['parameter_type'],
        'upper_limit':subEditValues['upper_limit'],
        'lower_limit':subEditValues['lower_limit'],
        'eqc_frequency':subEditValues['parameter_frequency']
      }
      let method = isSubEditMode ? 'PUT' : 'POST'
      let uri = isSubEditMode ? `/api/eqcparameters/${subEditValues.id}/` : '/api/eqcparameters/'
      API.query(method,uri,{},data, function(resp) {
        let newParameters = eqcParameters
        if(isSubEditMode) {
          let i = newParameters.findIndex(j => j.id == subEditValues.id)
          if(i > 0) {
            data['id'] = subEditValues.id
            newParameters[i] = data
          }
        }else {
          data['id'] = resp.id
          newParameters.push(data)
        }

        setEQCParameters(newParameters)
        if(addNewSub) {
          //let dateArr = editValues['manufacture_date'].split('/')
          //let manuDate = `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`
          let paramData = {
            'category': editValues['category'],
            'description': editValues['description'],
            'eqc_serial': editValues['eqc_serial'],
            'manufacture_date': editValues['manufacture_date'],
            'eqc_parameter': appendParameter(editValues['eqc_parameter'],resp.id),
            'manufacturer': editValues['manufacturer'],
            'owner_status': editValues['ownerStatus'],
            'status': (editValues['status'] && editValues['status'] == 'false') ? false : true,
            'facilities': editValues['facilities']
          }

          let uri =  `/api/equipmentqc/${editValues.id}/`
          API.query('PUT',uri,{},paramData, function(resp) {
            let newEquipment = equipment
            let i = newEquipment.findIndex(j => j.id == editValues.id)
            if(i > -1) {
              data['id'] = editValues.id
              newEquipment[i] = data
            }
            setEquipment(newEquipment)
          },onDataLoadError)
        }
        API.query("GET",`/api/eqcparameters/`,{},{}, function(resp) {
          setEQCParameters(resp)
        }, onDataLoadError)
        setIsSaving(false)
        setShowSubAdd(false)
        setAddNewSub(false)
        setSubEditValues([])
      },onDataLoadError)      
    }
  }

  const appendParameter = (subParamEditValues, respID) => {
    let newEditValues = subParamEditValues
    newEditValues.push(respID)
    return newEditValues
  }

  const onSetupModalClosed = () => {
    setShowSetupModal(false)
    setEditValues([])
    setIsEditMode(false)
    setIsSubMenu(true)
    API.query("GET",`/api/equipmentqc/`,{},{}, function(resp) {
      if(props.permissions.includes(11) || props.permissions.includes(11)) {
        let user = Session.get('user')
        let filtered = resp.filter((obj) => {
          var i, x, abort = false, valid = false
          for(i=0; i < user.facilities.length && !abort; i++) {
            for(x=0; x < obj.facilities.length && !abort; x++) {
              if(user.facilities[i] == obj.facilities[x]) {
                valid = true
                abort = true
              }
            }
          }
          return valid
        })
        setEquipment(filtered)
      } else {
        setEquipment(resp)
      }
    })
    API.query("GET",`/api/eqcparameters/`,{},{}, function(resp) {
      setEQCParameters(resp)
    })
  }

  const onSubAddClosed = () => {
    setShowSubAdd(false)
    setSubEditValues([])
    setIsSubEditMode(false)
  }

  const onEditClicked = (row) => {
    row.status = row.status?"true":"false"
    row.owner_status = `${row.owner_status}`
    setEditValues(row)
    setShowSetupModal(true)
    setIsEditMode(true)
  }

  const onSubEditClicked = (row) => {
    setSubEditValues(row)
    setShowSubAdd(true)
    setIsSubEditMode(true)
  }

  const onDeleteClicked = (row) => {
    setEditValues(row)
    setShowConfirmationModal(true)
  }

  const onSubDeleteClicked = (row) => {
    setSubEditValues(row)
    setShowSubConfirmationModal(true)
  }

  const onSubAddNewClicked = () => {
    setShowSubAdd(true)
    setAddNewSub(true)
  }

  const onConfirmDelete = () => {
    API.query("DELETE",`/api/equipmentqc/${editValues.id}/`,{},{}, function(resp) {
      let newEquipment = equipment.filter(e => e.id !== editValues.id)
      setEquipment(newEquipment)
      setShowConfirmationModal(false)
      setEditValues([])
    },onDataLoadError)
  }

  const onConfirmParamDelete = () => {
    API.query("DELETE",`/api/eqcparameters/${subEditValues.id}/`,{},{}, function(resp) {
      let newParameters = eqcParameters.filter(j => j.id !== subEditValues.id)
      setEQCParameters(newParameters)
      setShowSubConfirmationModal(false)
      API.query("GET",`/api/equipmentqc/${editValues.id}/`,{},{}, function(resp) {
        let newEditValues = resp
        if(newEditValues.manufacture_date != null) {
          let date = newEditValues.manufacture_date
          let dateArr = date.split('-')
          let newDate = `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`
          newEditValues.manufacture_date = newDate
        }
        setEditValues(newEditValues)
      },onDataLoadError)
    },onDataLoadError)    
  }

  const removeParam = (originalParams, oldID) => {
    let newParams = originalParams.filter(i => i !== oldID)
    return newParams
  }

  const onButtonPressed = (id) => {
    if(id == "clear") {
      setFilterValues([])
    } else if (id == "create_new") {
      setShowSetupModal(true)
      setIsSubMenu(false)
    } else {
      //unknown button
    }
  }

  const filterByFacility = (obj) => {
    var valid = true
    if(filterValues.facilities && filterValues.facilities.length != 0) {
      if(filterValues.facilities.findIndex(f => obj.id == f) == -1) valid = false
    }
    return valid
  }

  const filteredEquipment =  equipment.filter((obj) =>{
    let valid = false;
    if(filterByFacility(obj)) {
      valid = true
    }
    return valid
  })

  const filteredParameters = eqcParameters.filter((obj) => {
    let i, valid = false, abort = false
    if(editValues.eqc_parameter && editValues.eqc_parameter.length != 0) {
      for(i=0; i<editValues.eqc_parameter.length && !abort; i++) {
        if(editValues.eqc_parameter[i] == obj.id) {
          valid = true
          abort = true
        }
      }
    }
    return valid
  })

  const getOptionLabelByValue = (qc, source, id) => {
    let option = source.find(o => o.value == id)
    return option ? option.label : null
  }

  const formatLimits = (upperLimit, lowerLimit) => {
    var newFormat
    if(upperLimit != undefined && lowerLimit != undefined) {
      newFormat = `${lowerLimit}, ${upperLimit}`
    } else {
      newFormat = `No limits`
    }
    
    return newFormat
  }

  const getDateFormat = (date) => {
    if(date != null) {
      let objDateArr = date.split('T')
      let manuObjDate = objDateArr[0]
      return manuObjDate
    } else {
      return ""
    }    
  }

  const getFrequency = (freqID) => {
    if(freqID == 1) {
      return 'Daily'
    } else if(freqID == 2) {
      return 'Weekly'
    } else if(freqID == 3) {
      return 'Bi-Weekly'
    } else if(freqID == 4) {
      return 'Monthly'
    } else if(freqID == 5) {
      return 'Bi-Monthly'
    } else if(freqID == 6) {
      return 'Quarterly'
    } else if(freqID == 7) {
      return 'Annually'
    } else if(freqID == 8) {
      return 'Bi-Annually'
    } else if(freqID == 9) {
      return 'Semi-Annually'
    }
  }

  const parameterColumns = useMemo(() => [
    {name:'Parameter',selector: row => getOptionLabelByValue(row, paramDescOptions, row.eqc_description), sortable:true},
    {name:'Limits (Lower, Upper)',selector: row => formatLimits(row.upper_limit, row.lower_limit), sortable:true},
    {name:'Frequency',selector: row => getFrequency(row.eqc_frequency), sortable:true},
    {name:'Actions',selector: row =><div>
    <a title="Edit" onClick={() => onSubEditClicked(row)}><FaPencilAlt size={14} /></a>
    <a className="ml-3" title="Delete" onClick={() => onSubDeleteClicked(row)}><FaTrashAlt size={14} /></a>
    </div>}
  ])

  const columns = useMemo(() => [
    {name:'Item Category',selector: row => getOptionLabelByValue(row,categoryOptions,row.category),sortable:true},
    {name:'Manufacturer',selector: row => getOptionLabelByValue(row,manufacturerOptions,row.manufacturer),sortable:true},
    {name:'Description',selector: row => getOptionLabelByValue(row,descriptionOptions,row.description),sortable:true},
    {name:'Manufacture Date', selector: row => getDateFormat(row.manufacture_date),sortable:true},
    {name:'Serial #', selector: row => row.eqc_serial,sortable:true},
    {name:'Actions',selector: row =><div>
    <a title="Edit" onClick={() => onEditClicked(row)}><FaPencilAlt size={14} /></a>
    <a className="ml-3" title="Delete" onClick={() => onDeleteClicked(row)}><FaTrashAlt size={14} /></a>
    </div>}
  ])

  useEffect(() => {
    let queries = [
      { 'method': 'GET', 'path': '/api/equipmentqc/', },
      { 'method': 'GET', 'path': '/api/facilities/', },
      { 'method': 'GET', 'path': '/api/eqdescriptions/', },
      { 'method': 'GET', 'path': '/api/eqcategories/', },
      { 'method': 'GET', 'path': '/api/manufacturers/', },
      { 'method': 'GET', 'path': '/api/eqcparamdescriptions/', },
      { 'method': 'GET', 'path': '/api/eqcparameters/', }
    ]
    API.all(queries, function (resp) {
      if(props.permissions.includes(11) || props.extraPermissions.includes(11)) {
        let user = Session.get('user')
        let filtered = resp[1].filter((obj) => {
          var i, abort = false, valid = false
          for(i=0; i < user.facilities.length && !abort; i++) {
            if(user.facilities[i] == obj.id) {
              valid = true
              abort = true
            }
          }
          return valid
        })
        let filteredEquip = resp[0].filter((obj) => {
          var i, x, abort = false, valid = false
          for(i=0; i < user.facilities.length && !abort; i++) {
            for(x=0; x < obj.facilities.length && !abort; x++) {
              if(user.facilities[i] == obj.facilities[x]) {
                valid = true
                abort = true
              }
            }
          }
          return valid
        })
        setEquipment(filteredEquip)
        setFacilityOptions(filtered.map(i => ({ "label": i.name, "value": i.id })))
      } else {
        setFacilityOptions(resp[1].map(i => ({ "label": i.name, "value": i.id })))
        setEquipment(resp[0])
      }
      setDescriptionOptions(resp[2].map(i => ({"label": i.description, "value": i.id})))
      setCategoryOptions(resp[3].map(i => ({"label": i.name, "value": i.id})))
      setManufacturerOptions(resp[4].map(i => ({"label": i.name, "value": i.id})))
      setParamDescOptions(resp[5].map(i => ({ "label": i.description, "value": i.id })))
      setEQCParameters(resp[6])
    }, onDataLoadError)
  }, []);

  const onDataLoadError = (error) => {
    setErrorMessage(error)
    setShowErrorModal(true)
    setIsSaving(false)
  }

  const visited = () => {
    // get pageviewhistory id from user session data
    var user = Session.get('user')
    API.query('GET',`/api/pageviewhistory/${user.page_view_history}/`,{},{},function(response) {
      let data = {
        "setup_eqc": response.setup_eqc + 1
      }
      API.query('PATCH',`/api/pageviewhistory/${user.page_view_history}/`,{},data,function(resp) {}, onDataLoadError)
    }, onDataLoadError)
  }

  return <div>
    <p className="title">Equipment QC Setup</p>
    <hr />
    <DynamicForm 
      form={equipmentQCSearch} 
      values={filterValues} 
      onValuesChanged={onFilterChanged} 
      onButtonPressed={onButtonPressed} 
      source={{"facilities": facilityOptions}}
    />
    <hr />

    {
      showSetupModal
      && 
      <SetupModal
        titleString={"Equipment QC Setup"}
        form={equipmentQCModal}
        editValues={editValues}
        onEditChange={onEditChange}
        source={{
          "manufacturers": manufacturerOptions, "equipmentDescriptions": descriptionOptions, 
          "equipmentCategories": categoryOptions, "facilities": facilityOptions,
          "eqc_param_descriptions": paramDescOptions
        }}
        isSaving={isSaving}
        onSave={onSave}
        onClose={onSetupModalClosed}

        subMenu={isSubMenu}
        subForm={subMenuParameters}
        showSubAdd={showSubAdd}
        subColumns={parameterColumns}
        filteredSub={filteredParameters}
        subEditValues={subEditValues}
        onSubEditChange={onSubEditChange}
        onSubSave={onSubSave}
        onSubClose={onSubAddClosed}
        onButtonPressed={onButtonPressed}
        onSubAddNewClicked={onSubAddNewClicked}
        string = {subMenuString}
      />
    }

    {
      showErrorModal
      && 
      <ErrorModal
        message={errorMessage}
        onClose={() => setShowErrorModal(false)}
      />
    }

    {
      showSubConfirmationModal
      &&
      <ConfirmationModal
        message={"You are about to delete a Parameter. This cannot be undone."}
        onConfirm={onConfirmParamDelete}
        onClose={() => setShowSubConfirmationModal(false)}
      />
    }

    {
      showConfirmationModal
      && 
      <ConfirmationModal
        message={"You are about to delete an EquipmentQC. This cannot be undone."}
        onConfirm={onConfirmDelete}
        onClose={() => setShowConfirmationModal(false)}
      />
    }

    <p className="title is-5">Results</p>
    <DataTable
      striped
      pagination
      data={filteredEquipment}
      columns={columns}
    />

    <VisitTimer visited={visited}/>
  </div>
}

export default EquipmentQCSetup