import React, { useEffect, useState, useMemo } from "react";
import API from "../../../../services/API";
import DataTable from 'react-data-table-component';
import DynamicForm from "../../../components/DynamicForm";
import TransferModal from "../../../components/TransferModal";
import TransferSearch from "../../../forms/transferSearch";
import Session from "../../../../services/Session";
import { FaPencilAlt, FaExpandArrowsAlt } from "react-icons/fa";

function Transfer() {
  const [transfers, setTransfers] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const [itemOptions, setItemOptions] = useState([])
  const [facilityOptions, setFacilityOptions] = useState([])
  const [editValues, setEditValues] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [items, setItems] = useState([])
  const [locationCounts, setLocationCounts] = useState([])
  const [transferItems, setTransferItems] = useState([])

  const onFilterChanged = (values) => {
    setFilterValues(values)
  }

  const onEditChange = (values) => {
    setEditValues(values)
  }

  useEffect(() => {
    let queries = [
      { 'method': 'GET', 'path': '/api/transfers/', },
      { 'method': 'GET', 'path': '/api/items/', },
      { 'method': 'GET', 'path': '/api/facilities/', },
      { 'method': 'GET', 'path': '/api/locationcounts/', }
    ]
    API.all(queries, function (resp) {
      setTransfers(resp[0])
      setItems(resp[1])
      setItemOptions(resp[1].map(i => ({ "label": `${i.catalog}, ${i.description}`, "value": i.id })))
      setFacilityOptions(resp[2].map(i => ({ "label": i.name, "value": i.id })))
      setLocationCounts(resp[3])
    }, onDataLoadError)
  }, []);

  const onDataLoadError = (error) => {
    console.log('error',error)
    setIsSaving(false)
  }

  const onButtonPressed = (id) => {
    if (id == "clear") {
      setFilterValues([])
    } else if (id == "create_new") {
      setEditValues({})
    } else {
      //unknown button
    }
  }

  const onEditClicked = (row) => {
    setEditValues(row)
    setTransferItems(row.items.map((x) => {
      return {
        id: x.id,
        item: x.item.id,
        quantity: x.quantity,
      }
    }));
  }
  
  const onViewClicked = (row) => {
    setDisabled(true)
    setEditValues(row)
    setTransferItems(row.items.map((x) => {
      return {
        id: x.id,
        item: x.item.id,
        quantity: x.quantity,
      }
    }));
  }

  const columns = useMemo(() => [
    {name:'From Facility',selector: row => row.from_facility_name, sortable:true},
    {name:'To Facility',selector: row => row.to_facility_name, sortable:true},
    {name:'Status',width:"120px",selector: row => row.submitted ? "Submitted" : "Created", sortable:true},
    {name:'Actions',width:"80px",selector: row =><div>
      {
        row.submitted
        ?
        <a title="View" onClick={() => onViewClicked(row)}><FaExpandArrowsAlt size={14}/></a>
        :
        <a title="Edit" onClick={() => onEditClicked(row)}><FaPencilAlt size={14} /></a>
      }
    </div>}
  ])

  const filterByFromFacility = (obj) => {
    var valid = true
    if(filterValues.from_facility != null) {
      if(obj.from_facility != filterValues.from_facility) {
        valid = false
      }
    }
    return valid
  }

  const filterByToFacility = (obj) => {
    var valid = true
    if(filterValues.to_facility != null) {
      if(obj.to_facility != filterValues.to_facility) {
        valid = false
      }
    }
    return valid
  }

  const filterByStatus = (obj) => {
    var valid = true
    if(filterValues.status != null) {
      let objstat = obj.submitted ? "True" : "False"
      if(objstat != filterValues.status) {
        valid = false
      }
    }
    return valid
  }

  const filterByItem = (obj) => {
    var valid = true
    if(filterValues.item != null) {
      const filteredItems = obj.items?.filter(x => x.item.id === filterValues.item)
      if(filteredItems === undefined || filteredItems?.length === 0) {
        valid = false
      }
    }
    return valid
  }

  const filteredTransfers = transfers.filter((obj) => {
    var valid = false
    if(filterByFromFacility(obj)) {
      if(filterByToFacility(obj)) {
        if(filterByStatus(obj)) {
          if(filterByItem(obj)) {
            valid = true
          }
        }
      }
    }
    return valid
  })

  const onSave = () => {
    if(!isSaving) {
      setIsSaving(true)
      var data
      var user = Session.get('user')
      if(editValues.id) {
        data = {
          'submitted': true
        }
      } else {
        data = {
          'user':user.id,
          'to_facility':editValues['to_facility'],
          'from_facility':editValues['from_facility'],
          'quantity':editValues['quantity'],
          'transfer_items':transferItems.map((x) => {return {item: x.item, quantity: x.quantity}}),
          'date_created': new Date().toLocaleDateString()
        }
      }
      let uri = editValues.id ? `/api/transfers/${editValues.id}/` : `/api/transfers/`
      let method = editValues.id ? 'PATCH' : 'POST'
      API.query(method, uri, {}, data, function(response) {
        API.query('GET',`/api/transfers/`,{},{},function(resp) {
          setTransfers(resp)
          setEditValues()
          setIsSaving(false)
          setDisabled(false)
          setTransferItems([])
        },onDataLoadError)
      },onDataLoadError)
    }
  }

  const onClose = () => {
    setEditValues()
    setDisabled(false)
    setTransferItems([])
  }

  return <div>
    <p className="title">Transfers</p>
    <hr />

    <DynamicForm
      form={TransferSearch} 
      values={filterValues} 
      onValuesChanged={onFilterChanged} 
      onButtonPressed={onButtonPressed}
      source={{"facilities": facilityOptions, "items": itemOptions}}
    />

    <hr />

    <DataTable
      striped
      pagination
      data={filteredTransfers}
      columns={columns}
    />

    {
      editValues != null
      ?
      <TransferModal
        onClose={onClose}
        editValues={editValues}
        onSave={onSave}
        isSaving={isSaving}
        onEditChange={onEditChange}
        disabled={disabled}
        items={items}
        locationCounts={locationCounts}
        source={{"facilities": facilityOptions, "items": itemOptions}}
        transferItems={transferItems}
        setTransferItems={setTransferItems}
      />
      :
      null
    }
  </div>
}

export default Transfer