import React from 'react';
import DynamicForm from './DynamicForm';
import noteModalForm from "../forms/noteModal"

function NoteModal(props) {
    const { note, onClose } = props

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title">Notes</p>
            </header>
            <section className="modal-card-body p-0">
                <div className="p-5">
                    <div className="has-background-white rounded w-100">
                        <DynamicForm
                            form={noteModalForm}
                            values={note}
                        />
                        <button onClick={onClose} className="modal-close is-large" aria-label="close"></button>
                    </div>
                </div>
            </section>

            <footer className="modal-card-foot">
                <button className="button" onClick={onClose}>OK</button>
            </footer>
        </div>
    </div>
}

export default NoteModal