import React, { useEffect, useState, useMemo, useRef } from "react"
import DynamicForm from "./DynamicForm"
import API from "../../services/API"
import DataTable from "react-data-table-component"
import { FaTrashAlt } from "react-icons/fa"
import ConfirmationModal from "./ConfirmationModal"
import ErrorModal from "./ErrorModal"

import topForm from "../forms/caseDetailsTop"
import valveForm from "../forms/caseDetailsValve"
import aorticForm from "../forms/caseDetailsAortic"
import circulatoryForm from "../forms/caseDetailsCirculatory"
import heartLungForm from "../forms/caseDetailsHeartLung"
import otherForm from "../forms/caseDetailsOther"
import middleForm from "../forms/caseDetailsMiddle"
import cardioForm from "../forms/caseDetailsCardioplegia"
import noteForm from "../forms/caseDetailsNotes"
import arrestForm from "../forms/caseDetailsArrest"
import congenitalForm from "../forms/caseDetailsCongenital"
import coronarySubForm from "../forms/caseDetailsCoronarySub"
import BypassCountForm from "../forms/caseDetailsBypassCount"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

function DetailCPB(props) {
    const [isSaving, setIsSaving] = useState(false)
    const bloodProducts = [{ id: "1", label: "PRBC" }, { id: "2", label: "Platelets" }, { id: "3", label: "FFP" }, { id: "4", label: "Whole Blood" }, { id: "5", label: "CRYO" }]
    const [detail, setDetail] = useState([])
    const [topValues, setTopValues] = useState([])
    const [valveValues, setValveValues] = useState([])
    const [aorticValues, setAorticValues] = useState([])
    const [circulatoryValues, setCirculatoryValues] = useState([])
    const [heartLungValues, setHeartLungValues] = useState([])
    const [middleValues, setMiddleValues] = useState([])
    const [cardioValues, setCardioValues] = useState([])
    const [noteValues, setNoteValues] = useState([])
    const [otherValues, setOtherValues] = useState([])
    const [arrestValues, setArrestValues] = useState([])
    const [congenitalTempValues, setCongenitalTempValues] = useState({ ductus_info: null, norwood_info: null, fontan_info: null, bialock_info: null, vsd_info: null, asd_info: null, tof_info: null, aorta_info: null, truncus_info: null, tricuspid_info: null, pulmonary_info: null, anamolous_info: null, vessel_info: null, other_info: null })
    const [cpbBypassValues, setCPBBypassValues] = useState([])
    const [cpbBypassCountValues, setCPBBypassCountValues] = useState([])
    const [showGraftConfirmationModal, setShowGraftConfirmationModal] = useState(false)
    const [coronaryValues, setCoronaryValues] = useState([])
    const [allCoronaryValues, setAllCoronaryValues] = useState([])
    const [coronarySubValues, setCoronarySubValues] = useState([])
    const [accordionID, setAccordionID] = useState()
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [deleteValues, setDeleteValues] = useState([])
    const [bloodPRBCValues, setBloodPRBCValues] = useState({ preOp: null, preBypass: null, bypass: null, postBypass: null, postOp: null })
    const [bloodPlateletValues, setBloodPlateletValues] = useState({ preOp: null, preBypass: null, bypass: null, postBypass: null, postOp: null })
    const [bloodFFPValues, setBloodFFPValues] = useState({ preOp: null, preBypass: null, bypass: null, postBypass: null, postOp: null })
    const [bloodWholeValues, setBloodWholeValues] = useState({ preOp: null, preBypass: null, bypass: null, postBypass: null, postOp: null })
    const [bloodCryoValues, setBloodCryoValues] = useState({ preOp: null, preBypass: null, bypass: null, postBypass: null, postOp: null })

    const cpbTopRef = useRef()
    const cpbSecondRef = useRef()
    const cpbValveRef = useRef()
    const cpbAorticRef = useRef()
    const cpbBypassRef = useRef()
    const cpbAssistRef = useRef()
    const cpbTransplantRef = useRef()
    const cpbOtherRef = useRef()
    const cpbCardioRef = useRef()
    const cpbCongenitalRef = useRef()
    const cpbArrestRef = useRef()
    const cpbBloodRef = useRef()

    let saveBtnClasses = "button is-success";
    if (isSaving) saveBtnClasses += " is-loading"

    const graftOptions = [
        { label: "Right Coronary Artery", value: "Right Coronary Artery" },
        { label: "Acute Marginal", value: "Acute Marginal" },
        { label: "Posterior Descending Artery", value: "Posterior Descending Artery" },
        { label: "Posterolateral Branch", value: "Posterolateral Branch" },
        { label: "LAD (Proximal)", value: "LAD (Proximal)" },
        { label: "LAD (Mid)", value: "LAD (Mid)" },
        { label: "LAD (Distal)", value: "LAD (Distal)" },
        { label: "Circumflex", value: "Circumflex" },
        { label: "Diagonal 1", value: "Diagonal 1" },
        { label: "Diagonal 2", value: "Diagonal 2" },
        { label: "Ramus", value: "Ramus" },
        { label: "Obtuse Marginal 1", value: "Obtuse Marginal 1" },
        { label: "Obtuse Marginal 2", value: "Obtuse Marginal 2" },
        { label: "Obtuse Marginal 3", value: "Obtuse Marginal 3" }
    ]

    const conduitOptions = [
        { label: "Vein Graft", value: "Vein Graft" },
        { label: "In Situ LIMA", value: "In Situ LIMA" },
        { label: "In Situ RIMA", value: "In Situ RIMA" },
        { label: "Free IMA", value: "Free IMA" },
        { label: "Radial Artery", value: "Radial Artery" },
        { label: "Other", value: "Other" }
    ]

    useEffect(() => {
        API.query("GET", `/api/cpbdetails/${props.detailID}/`, {}, {}, function (resp) {
            setDetail(resp)
            if (resp.top) {
                API.query("GET", `/api/cpbtop/${resp.top}/`, {}, {}, function (response) {
                    let vals = response
                    vals.re_operation = vals.re_operation ? "true" : "false"
                    vals.robotic_assistance = vals.robotic_assistance ? "true" : "false"
                    vals.start_time = vals.start_time
                    vals.end_time = vals.end_time
                    setTopValues(vals)
                }, onDataLoadError)
            }
            if (resp.bypasses) {
                API.query("GET", `/api/cpbbypass/`, {}, {}, function (response) {
                    setAllCoronaryValues(response)
                }, onDataLoadError)
            }
            if (resp.valve) {
                API.query("GET", `/api/cpbvalves/${resp.valve}/`, {}, {}, function (response) {
                    setValveValues(response)
                }, onDataLoadError)
            }
            if (resp.aortic) {
                API.query("GET", `/api/cpbaortic/${resp.aortic}/`, {}, {}, function (response) {
                    setAorticValues(response)
                }, onDataLoadError)
            }
            if (resp.congenital) {
                API.query("GET", `/api/cpbcongenital/${resp.congenital}/`, {}, {}, function (response) {
                    setCongenitalTempValues(response)
                }, onDataLoadError)
            }
            if (resp.circulatory_device) {
                API.query("GET", `/api/cpbcircdevices/${resp.circulatory_device}/`, {}, {}, function (response) {
                    setCirculatoryValues(response)
                }, onDataLoadError)
            }
            if (resp.transplant) {
                API.query("GET", `/api/cpbtransplants/${resp.transplant}/`, {}, {}, function (response) {
                    setHeartLungValues(response)
                }, onDataLoadError)
            }
            if (resp.other) {
                API.query("GET", `/api/cpbother/${resp.other}/`, {}, {}, function (response) {
                    setOtherValues(response)
                }, onDataLoadError)
            }
            if (resp.middle) {
                API.query("GET", `/api/cpbmiddle/${resp.middle}/`, {}, {}, function (response) {
                    setMiddleValues(response)
                }, onDataLoadError)
            }
            if (resp.cardioplegia) {
                API.query("GET", `/api/cpbcardio/${resp.cardioplegia}/`, {}, {}, function (response) {
                    setCardioValues(response)
                }, onDataLoadError)
            }
            if (resp.circulatory_arrest) {
                API.query("GET", `/api/cpbcircarrest/${resp.circulatory_arrest}/`, {}, {}, function (response) {
                    setArrestValues(response)
                }, onDataLoadError)
            }
            if (resp.blood) {
                API.query("GET", `/api/cpbblood/${resp.blood}/`, {}, {}, function (response) {
                    let prbc = bloodPRBCValues
                    let platelet = bloodPlateletValues
                    let ffp = bloodFFPValues
                    let whole = bloodWholeValues
                    let cryo = bloodCryoValues

                    prbc.preOp = response.prbc_preop
                    prbc.preBypass = response.prbc_prebypass
                    prbc.bypass = response.prbc_bypass
                    prbc.postBypass = response.prbc_postbypass
                    prbc.postOp = response.prbc_postop

                    platelet.preOp = response.platelet_preop
                    platelet.preBypass = response.platelet_prebypass
                    platelet.bypass = response.platelet_bypass
                    platelet.postBypass = response.platelet_postbypass
                    platelet.postOp = response.platelet_postop

                    ffp.preOp = response.ffp_preop
                    ffp.preBypass = response.ffp_prebypass
                    ffp.bypass = response.ffp_bypass
                    ffp.postBypass = response.ffp_postbypass
                    ffp.postOp = response.ffp_postop

                    whole.preOp = response.whole_preop
                    whole.preBypass = response.whole_prebypass
                    whole.bypass = response.whole_bypass
                    whole.postBypass = response.whole_postbypass
                    whole.postOp = response.whole_postop

                    cryo.preOp = response.cryo_preop
                    cryo.preBypass = response.cryo_prebypass
                    cryo.bypass = response.cryo_bypass
                    cryo.postBypass = response.cryo_postbypass
                    cryo.postOp = response.cryo_postop

                    setBloodPRBCValues(prbc)
                    setBloodPlateletValues(platelet)
                    setBloodFFPValues(ffp)
                    setBloodWholeValues(whole)
                    setBloodCryoValues(cryo)
                }, onDataLoadError)
            }
            if (resp.notes) {
                API.query("GET", `/api/cpbnotes/${resp.notes}/`, {}, {}, function (response) {
                    setNoteValues(response)
                }, onDataLoadError)
            }
            if (resp.opcab_conversion) {
                setCoronarySubValues({ opcab_conversion: resp.opcab_conversion ? "true" : "false" })
            }
            if (resp.bypass_count) {
                setCPBBypassCountValues({ bypass_count: resp.bypass_count })
            }
        }, onDataLoadError)
    }, [])

    const onTopChanged = (value) => {
        setTopValues(value)
    }

    const onCPBBypassCountChange = (value) => {
        setCPBBypassCountValues(value)
    }

    const onOtherChanged = (value) => {
        setOtherValues(value)
    }

    const onNoteChanged = (value) => {
        setNoteValues(value)
    }

    const onMiddleChanged = (value) => {
        let perfusion_volume = parseFloat(value.perfusion_volume == null ? 0 : value.perfusion_volume)
        let anes_volume = parseFloat(value.anes_volume == null ? 0 : value.anes_volume)
        let hemoconcentration = parseFloat(value.hemoconcentration == null ? 0 : value.hemoconcentration)
        let urine_output = parseFloat(value.urine_output == null ? 0 : value.urine_output)
        value.fluid_balance = ((perfusion_volume + anes_volume) - (hemoconcentration + urine_output))
        setMiddleValues(value)
    }

    const onCardioChanged = (value) => {
        setCardioValues(value)
    }

    const onCirculatoryChanged = (value) => {
        setCirculatoryValues(value)
    }

    const onValveChanged = (value) => {
        setValveValues(value)
    }

    const onArrestChanged = (value) => {
        setArrestValues(value)
    }

    const onAorticChanged = (value) => {
        setAorticValues(value)
    }

    const onHeartLungChanged = (value) => {
        setHeartLungValues(value)
    }

    const onCoronaryChanged = (value) => {
        setCoronaryValues(value)
    }

    const onCoronarySubChanged = (value) => {
        setCoronarySubValues(value)
    }

    const onCongenitalChanged = (value) => {
        setCongenitalTempValues(value)
    }

    const removeBypass = (id) => {
        let bypasses = detail.bypasses
        var i, abort = false
        for (i = 0; i < bypasses.length && !abort; i++) {
            if (bypasses[i] == id) {
                bypasses.splice(i, 1)
            }
        }
        return bypasses
    }

    const onBypassAdd = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'graft_site': null,
                'conduit': null
            }
            API.query('POST', `/api/cpbbypass/`, {}, data, function (resp) {
                let newCoronaryValues = [...allCoronaryValues]
                newCoronaryValues.push(resp)
                setAllCoronaryValues(newCoronaryValues)
                let newIDs = {
                    'bypasses': appendBypass(resp.id)
                }
                API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, newIDs, function (response) {
                    setDetail(response)
                    setIsSaving(false)
                }, onDataLoadError)
            }, onDataLoadError)
        }
    }

    const onConfirmGraftDelete = () => {
        API.query('DELETE', `/api/cpbbypass/${deleteValues.id}/`, {}, {}, function (resp) {
            let newBypassValues = allCoronaryValues.filter(j => j.id !== deleteValues.id)
            setAllCoronaryValues(newBypassValues)
            setShowGraftConfirmationModal(false)
            let data = {
                "bypasses": removeBypass(deleteValues.id)
            }
            API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, data, function (resp) {
                setDetail(resp)
                setDeleteValues([])
            }, onDataLoadError)
        }, onDataLoadError)
    }

    const onTopClear = () => {
        setConfirmationMessage('You are about to clear the top values, please confirm.')
        setAccordionID(1)
        setShowConfirmationModal(true)
    }

    const onTransplantClear = () => {
        setConfirmationMessage('You are about to clear transplant values, please confirm.')
        setAccordionID(7)
        setShowConfirmationModal(true)
    }

    const onCongenitalClear = () => {
        setConfirmationMessage('You are about to clear congenital values, please confirm.')
        setAccordionID(5)
        setShowConfirmationModal(true)
    }

    const onOtherClear = () => {
        setConfirmationMessage('You are about to clear other values, please confirm.')
        setAccordionID(8)
        setShowConfirmationModal(true)
    }

    const onMiddleClear = () => {
        setConfirmationMessage('You are about to clear middle values, please confirm.')
        setAccordionID(9)
        setShowConfirmationModal(true)
    }

    const onCardioClear = () => {
        setConfirmationMessage('You are about to clear cardioplegia values, please confirm.')
        setAccordionID(10)
        setShowConfirmationModal(true)
    }

    const onArrestClear = () => {
        setConfirmationMessage('You are about to clear circulatory arrest values, please confirm.')
        setAccordionID(11)
        setShowConfirmationModal(true)
    }

    const onNoteClear = () => {
        setConfirmationMessage('You are about to clear note values, please confirm.')
        setAccordionID(13)
        setShowConfirmationModal(true)
    }

    const onAorticClear = () => {
        setConfirmationMessage('You are about to clear aortic values, please confirm.')
        setAccordionID(4)
        setShowConfirmationModal(true)
    }

    const onCirculatoryClear = () => {
        setConfirmationMessage('You are about to clear circulatory assist device values, please confirm.')
        setAccordionID(6)
        setShowConfirmationModal(true)
    }

    const onValveClear = () => {
        setConfirmationMessage('You are about to clear valve values, please confirm.')
        setAccordionID(3)
        setShowConfirmationModal(true)
    }

    const onBloodClear = () => {
        setConfirmationMessage('You are about to clear blood values, please confirm.')
        setAccordionID(12)
        setShowConfirmationModal(true)
    }

    const onArteryClear = () => {
        setConfirmationMessage('You are about to clear bypass values, please confirm.')
        setAccordionID(2)
        setShowConfirmationModal(true)
    }

    const onCoronaryDeleteClicked = (row) => {
        setDeleteValues(row)
        setShowGraftConfirmationModal(true)
    }

    const onBloodChange = (row, value, column) => {
        const prbc = bloodPRBCValues
        const platelets = bloodPlateletValues
        const ffp = bloodFFPValues
        const wholeBlood = bloodWholeValues
        const cryo = bloodCryoValues

        if (row == 1) {
            if (column == 2) {
                prbc.preBypass = value
            } else if (column == 3) {
                prbc.bypass = value
            } else if (column == 4) {
                prbc.postBypass = value
            }
        } else if (row == 2) {
            if (column == 2) {
                platelets.preBypass = value
            } else if (column == 3) {
                platelets.bypass = value
            } else if (column == 4) {
                platelets.postBypass = value
            }
        } else if (row == 3) {
            if (column == 2) {
                ffp.preBypass = value
            } else if (column == 3) {
                ffp.bypass = value
            } else if (column == 4) {
                ffp.postBypass = value
            }
        } else if (row == 4) {
            if (column == 2) {
                wholeBlood.preBypass = value
            } else if (column == 3) {
                wholeBlood.bypass = value
            } else if (column == 4) {
                wholeBlood.postBypass = value
            }
        } else if (row == 5) {
            if (column == 2) {
                cryo.preBypass = value
            } else if (column == 3) {
                cryo.bypass = value
            } else if (column == 4) {
                cryo.postBypass = value
            }
        }

        setBloodPRBCValues(prbc)
        setBloodPlateletValues(platelets)
        setBloodFFPValues(ffp)
        setBloodWholeValues(wholeBlood)
        setBloodCryoValues(cryo)
    }

    const getBloodValue = (row, column) => {
        if (row == 1) {
            if (column == 2) {
                return bloodPRBCValues.preBypass
            } else if (column == 3) {
                return bloodPRBCValues.bypass
            } else if (column == 4) {
                return bloodPRBCValues.postBypass
            }
        } else if (row == 2) {
            if (column == 2) {
                return bloodPlateletValues.preBypass
            } else if (column == 3) {
                return bloodPlateletValues.bypass
            } else if (column == 4) {
                return bloodPlateletValues.postBypass
            }
        } else if (row == 3) {
            if (column == 2) {
                return bloodFFPValues.preBypass
            } else if (column == 3) {
                return bloodFFPValues.bypass
            } else if (column == 4) {
                return bloodFFPValues.postBypass
            }
        } else if (row == 4) {
            if (column == 2) {
                return bloodWholeValues.preBypass
            } else if (column == 3) {
                return bloodWholeValues.bypass
            } else if (column == 4) {
                return bloodWholeValues.postBypass
            }
        } else if (row == 5) {
            if (column == 2) {
                return bloodCryoValues.preBypass
            } else if (column == 3) {
                return bloodCryoValues.bypass
            } else if (column == 4) {
                return bloodCryoValues.postBypass
            }
        }
    }

    const onNoteSave = () => {
        if (!detail.notes) {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'case_note': noteValues['case_note'],
                    'department_note': noteValues['department_note']
                }
                API.query('POST', `/api/cpbnotes/`, {}, data, function (resp) {
                    let newID = {
                        'notes': resp.id
                    }
                    API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, newID, function (response) {
                        setDetail(response)
                        setIsSaving(false)
                    }, onDataLoadError)
                }, onDataLoadError)
            }
        } else {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'case_note': noteValues['case_note'],
                    'department_note': noteValues['department_note']
                }
                API.query('PUT', `/api/cpbnotes/${detail.notes}/`, {}, data, function (resp) {
                    setIsSaving(false)
                }, onDataLoadError)
            }
        }
    }

    const onArrestSave = () => {
        if (!detail.circulatory_arrest) {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'arrest_time': arrestValues['arrest_time'],
                    'arrest_status': arrestValues['arrest_status']
                }
                API.query('POST', `/api/cpbcircarrest/`, {}, data, function (resp) {
                    let newID = {
                        'circulatory_arrest': resp.id
                    }
                    API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, newID, function (response) {
                        setDetail(response)
                        setIsSaving(false)
                    }, onDataLoadError)
                }, onDataLoadError)
            }
        } else {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'arrest_time': arrestValues['arrest_time'],
                    'arrest_status': arrestValues['arrest_status']
                }
                API.query('PUT', `/api/cpbcircarrest/${detail.circulatory_arrest}/`, {}, data, function (resp) {
                    setIsSaving(false)
                }, onDataLoadError)
            }
        }
    }

    const onCardioSave = () => {
        if (!detail.cardioplegia) {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'type': cardioValues['type'],
                    'crystalloid_ratio': cardioValues['crystalloid_ratio'],
                    'crystalloid_amount': cardioValues['crystalloid_amount'],
                    'delivery_method': cardioValues['delivery_method'],
                    'temperature': cardioValues['temperature']
                }
                API.query('POST', `/api/cpbcardio/`, {}, data, function (resp) {
                    let newID = {
                        'cardioplegia': resp.id
                    }
                    API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, newID, function (response) {
                        setDetail(response)
                        setIsSaving(false)
                    }, onDataLoadError)
                }, onDataLoadError)
            }
        } else {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'type': cardioValues['type'],
                    'crystalloid_ratio': cardioValues['crystalloid_ratio'],
                    'crystalloid_amount': cardioValues['crystalloid_amount'],
                    'delivery_method': cardioValues['delivery_method'],
                    'temperature': cardioValues['temperature']
                }
                API.query('PUT', `/api/cpbcardio/${detail.cardioplegia}/`, {}, data, function (resp) {
                    setIsSaving(false)
                }, onDataLoadError)
            }
        }
    }

    const onMiddleSave = () => {
        if (!detail.middle) {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'cpb_time': middleValues['cpb_time'],
                    'clamp_time': middleValues['clamp_time'],
                    'pre_hct': middleValues['pre_hct'],
                    'post_hct': middleValues['post_hct'],
                    'lowest_hct': middleValues['lowest_hct'],
                    'perfusion_volume': middleValues['perfusion_volume'],
                    'anes_volume': middleValues['anes_volume'],
                    'urine_output': middleValues['urine_output'],
                    'fluid_balance': middleValues['fluid_balance'],
                    'lowest_cpb': middleValues['lowest_cpb'],
                    'iabp': middleValues['iabp'],
                    'status': middleValues['status'],
                    'hemoconcentration': middleValues['hemoconcentration'],
                    'glucose': middleValues['glucose']
                }
                API.query('POST', `/api/cpbmiddle/`, {}, data, function (resp) {
                    let newID = {
                        'middle': resp.id
                    }
                    API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, newID, function (response) {
                        setDetail(response)
                        setIsSaving(false)
                    }, onDataLoadError)
                }, onDataLoadError)
            }
        } else {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'cpb_time': middleValues['cpb_time'],
                    'clamp_time': middleValues['clamp_time'],
                    'pre_hct': middleValues['pre_hct'],
                    'post_hct': middleValues['post_hct'],
                    'lowest_hct': middleValues['lowest_hct'],
                    'perfusion_volume': middleValues['perfusion_volume'],
                    'anes_volume': middleValues['anes_volume'],
                    'urine_output': middleValues['urine_output'],
                    'fluid_balance': middleValues['fluid_balance'],
                    'lowest_cpb': middleValues['lowest_cpb'],
                    'iabp': middleValues['iabp'],
                    'status': middleValues['status'],
                    'hemoconcentration': middleValues['hemoconcentration'],
                    'glucose': middleValues['glucose']
                }
                API.query('PUT', `/api/cpbmiddle/${detail.middle}/`, {}, data, function (resp) {
                    setIsSaving(false)
                }, onDataLoadError)
            }
        }
    }

    const onOtherSave = () => {
        if (!detail.other) {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'lead': otherValues['lead'],
                    'artrial': otherValues['artrial'],
                    'asd': otherValues['asd'],
                    'vsd': otherValues['vsd'],
                    'lva': otherValues['lva'],
                    'tmr': otherValues['tmr'],
                    'tumor': otherValues['tumor'],
                    'pulmonary': otherValues['pulmonary'],
                    'cardiac': otherValues['cardiac'],
                    'other': otherValues['other']
                }
                API.query('POST', `/api/cpbother/`, {}, data, function (resp) {
                    let newID = {
                        'other': resp.id
                    }
                    API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, newID, function (response) {
                        setDetail(response)
                        setIsSaving(false)
                    }, onDataLoadError)
                }, onDataLoadError)
            }
        } else {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'lead': otherValues['lead'],
                    'artrial': otherValues['artrial'],
                    'asd': otherValues['asd'],
                    'vsd': otherValues['vsd'],
                    'lva': otherValues['lva'],
                    'tmr': otherValues['tmr'],
                    'tumor': otherValues['tumor'],
                    'pulmonary': otherValues['pulmonary'],
                    'cardiac': otherValues['cardiac'],
                    'other': otherValues['other']
                }
                API.query('PUT', `/api/cpbother/${detail.other}/`, {}, data, function (resp) {
                    setIsSaving(false)
                }, onDataLoadError)
            }
        }
    }

    const onTransplantSave = () => {
        if (!detail.transplant) {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'heart_time': heartLungValues['heart_time'],
                    'right_time': heartLungValues['right_time'],
                    'left_time': heartLungValues['left_time']
                }
                API.query('POST', `/api/cpbtransplants/`, {}, data, function (resp) {
                    let newID = {
                        'transplant': resp.id
                    }
                    API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, newID, function (response) {
                        setDetail(response)
                        setIsSaving(false)
                    }, onDataLoadError)
                }, onDataLoadError)
            }
        } else {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'heart_time': heartLungValues['heart_time'],
                    'right_time': heartLungValues['right_time'],
                    'left_time': heartLungValues['left_time']
                }
                API.query('PUT', `/api/cpbtransplants/${detail.transplant}/`, {}, data, function (resp) {
                    setIsSaving(false)
                }, onDataLoadError)
            }
        }
    }

    const onTopSave = () => {
        if (!detail.top) {
            if (!isSaving) {
                setIsSaving(true)
                
                let data = {
                    'start_date': topValues['start_date'],
                    'end_date': topValues['end_date'],
                    'start_time': topValues['start_time'],
                    'end_time': topValues['end_time'],
                    'or_number': topValues['or_number'],
                    'classification': topValues['classification'],
                    're_operation': topValues['re_operation'],
                    'operative_approach': topValues['operative_approach'],
                    'robotic_assistance': topValues['robotic_assistance']
                }
                API.query('POST', `/api/cpbtop/`, {}, data, function (resp) {
                    let newID = {
                        'top': resp.id
                    }
                    API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, newID, function (response) {
                        setDetail(response)
                        setIsSaving(false)
                    }, onDataLoadError)
                }, onDataLoadError)
            }
        } else {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'start_date': topValues['start_date'],
                    'end_date': topValues['end_date'],
                    'start_time': topValues['start_time'],
                    'end_time': topValues['end_time'],
                    'or_number': topValues['or_number'],
                    'classification': topValues['classification'],
                    're_operation': topValues['re_operation'],
                    'operative_approach': topValues['operative_approach'],
                    'robotic_assistance': topValues['robotic_assistance']
                }
                API.query('PUT', `/api/cpbtop/${detail.top}/`, {}, data, function (resp) {
                    setIsSaving(false)
                }, onDataLoadError)
            }
        }
    }

    const onCirculatorySave = () => {
        if (!detail.circulatory_device) {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'lvad_status': circulatoryValues['lvad_status'],
                    'lvad_info': circulatoryValues['lvad_info'],
                    'rvad_status': circulatoryValues['rvad_status'],
                    'rvad_info': circulatoryValues['rvad_info'],
                    'tah_status': circulatoryValues['tah_status'],
                    'tah_info': circulatoryValues['tah_info'],
                    'other_status': circulatoryValues['other_status'],
                    'other_info': circulatoryValues['other_info']
                }
                API.query('POST', `/api/cpbcircdevices/`, {}, data, function (resp) {
                    let newID = {
                        'circulatory_device': resp.id
                    }
                    API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, newID, function (response) {
                        setDetail(response)
                        setIsSaving(false)
                    }, onDataLoadError)
                }, onDataLoadError)
            }
        } else {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'lvad_status': circulatoryValues['lvad_status'],
                    'lvad_info': circulatoryValues['lvad_info'],
                    'rvad_status': circulatoryValues['rvad_status'],
                    'rvad_info': circulatoryValues['rvad_info'],
                    'tah_status': circulatoryValues['tah_status'],
                    'tah_info': circulatoryValues['tah_info'],
                    'other_status': circulatoryValues['other_status'],
                    'other_info': circulatoryValues['other_info']
                }
                API.query('PUT', `/api/cpbcircdevices/${detail.circulatory_device}/`, {}, data, function (resp) {
                    setIsSaving(false)
                }, onDataLoadError)
            }
        }
    }

    const appendBypass = (id) => {
        let bypasses = detail.bypasses
        bypasses.push(id)
        return bypasses
    }

    const filteredCoronaryValues = allCoronaryValues.filter((obj) => {
        var i, abort = false, valid = false
        for (i = 0; i < detail.bypasses.length && !abort; i++) {
            if (detail.bypasses[i] == obj.id) {
                abort = true
                valid = true
            }
        }
        return valid
    })

    const coronaryColumns = useMemo(() => [
        {
            name: 'Graft Site', selector: row => <div className="select is-normal">
                <select onChange={(option) => onCPBBypassChange('graft', option.target.value, row.id)} value={row.graft_site}>
                    <option>Select Graft Site</option>
                    {
                        graftOptions.map((obj, i) => {
                            return <option key={i} value={obj.value}>{obj.label}</option>
                        })
                    }
                </select>
            </div>
        },
        {
            name: 'Conduit', selector: row => <div className="select is-normal">
                <select onChange={(option) => onCPBBypassChange('conduit', option.target.value, row.id)} value={row.conduit}>
                    <option>Select Conduit Site</option>
                    {
                        conduitOptions.map((obj, i) => {
                            return <option key={i} value={obj.value}>{obj.label}</option>
                        })
                    }
                </select>
            </div>
        },
        {
            name: 'Actions', selector: row => <div>
                <a className="ml-3" title="Delete" onClick={() => onCoronaryDeleteClicked(row)}><FaTrashAlt size={14} /></a>
            </div>
        }
    ])

    const onBloodSave = () => {
        if (!detail.blood) {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'prbc_prebypass': bloodPRBCValues.preBypass,
                    'prbc_bypass': bloodPRBCValues.bypass,
                    'prbc_postbypass': bloodPRBCValues.postBypass,
                    'platelet_prebypass': bloodPlateletValues.preBypass,
                    'platelet_bypass': bloodPlateletValues.bypass,
                    'platelet_postbypass': bloodPlateletValues.postBypass,
                    'ffp_prebypass': bloodFFPValues.preBypass,
                    'ffp_bypass': bloodFFPValues.bypass,
                    'ffp_postbypass': bloodFFPValues.postBypass,
                    'whole_prebypass': bloodWholeValues.preBypass,
                    'whole_bypass': bloodWholeValues.bypass,
                    'whole_postbypass': bloodWholeValues.postBypass,
                    'cryo_prebypass': bloodCryoValues.preBypass,
                    'cryo_bypass': bloodCryoValues.bypass,
                    'cryo_postbypass': bloodCryoValues.postBypass
                }
                API.query('POST', `/api/cpbblood/`, {}, data, function (resp) {
                    let newID = {
                        'blood': resp.id
                    }
                    API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, newID, function (response) {
                        setDetail(response)
                        setIsSaving(false)
                    }, onDataLoadError)
                }, onDataLoadError)
            }
        } else {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'prbc_prebypass': bloodPRBCValues.preBypass,
                    'prbc_bypass': bloodPRBCValues.bypass,
                    'prbc_postbypass': bloodPRBCValues.postBypass,
                    'platelet_prebypass': bloodPlateletValues.preBypass,
                    'platelet_bypass': bloodPlateletValues.bypass,
                    'platelet_postbypass': bloodPlateletValues.postBypass,
                    'ffp_prebypass': bloodFFPValues.preBypass,
                    'ffp_bypass': bloodFFPValues.bypass,
                    'ffp_postbypass': bloodFFPValues.postBypass,
                    'whole_prebypass': bloodWholeValues.preBypass,
                    'whole_bypass': bloodWholeValues.bypass,
                    'whole_postbypass': bloodWholeValues.postBypass,
                    'cryo_prebypass': bloodCryoValues.preBypass,
                    'cryo_bypass': bloodCryoValues.bypass,
                    'cryo_postbypass': bloodCryoValues.postBypass
                }
                API.query('PUT', `/api/cpbblood/${detail.blood}/`, {}, data, function (resp) {
                    setIsSaving(false)
                }, onDataLoadError)
            }
        }
    }

    const onArterySave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'opcab_conversion': coronarySubValues['opcab_conversion'],
                'bypass_count': cpbBypassCountValues['bypass_count']
            }
            API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, data, function (resp) {
                var i
                for (i = 0; i < cpbBypassValues.length; i++) {
                    let data = {
                        'graft_site': cpbBypassValues[i].graft_site,
                        'conduit': cpbBypassValues[i].conduit
                    }
                    API.query('PATCH', `/api/cpbbypass/${cpbBypassValues[i].id}/`, {}, data, function (resp) { }, onDataLoadError)
                }
                setDetail(resp)
                setIsSaving(false)
            })
        }
    }

    const onValveSave = () => {
        if (!detail.valve) {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'aortic_status': valveValues['aortic_status'],
                    'aortic_info': valveValues['aortic_info'],
                    'mitral_status': valveValues['mitral_status'],
                    'mitral_info': valveValues['mitral_info'],
                    'tricuspid_status': valveValues['tricuspid_status'],
                    'tricuspid_info': valveValues['tricuspid_info'],
                    'pulmonic_status': valveValues['pulmonic_status'],
                    'pulmonic_info': valveValues['pulmonic_info']
                }
                API.query('POST', `/api/cpbvalves/`, {}, data, function (resp) {
                    let newID = {
                        'valve': resp.id
                    }
                    API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, newID, function (response) {
                        setDetail(response)
                        setIsSaving(false)
                    }, onDataLoadError)
                }, onDataLoadError)
            }
        } else {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'aortic_status': valveValues['aortic_status'],
                    'aortic_info': valveValues['aortic_info'],
                    'mitral_status': valveValues['mitral_status'],
                    'mitral_info': valveValues['mitral_info'],
                    'tricuspid_status': valveValues['tricuspid_status'],
                    'tricuspid_info': valveValues['tricuspid_info'],
                    'pulmonic_status': valveValues['pulmonic_status'],
                    'pulmonic_info': valveValues['pulmonic_info']
                }
                API.query('PUT', `/api/cpbvalves/${detail.valve}/`, {}, data, function (resp) {
                    setIsSaving(false)
                }, onDataLoadError)
            }
        }
    }

    const onAorticSave = () => {
        if (!detail.aortic) {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'aortic_info': aorticValues['aortic_info'],
                    'hematoma_info': aorticValues['hematoma_info'],
                    'trauma_info': aorticValues['trauma_info'],
                    'coarctation_info': aorticValues['coarctation_info'],
                    'other_info': aorticValues['other_info']
                }
                API.query('POST', `/api/cpbaortic/`, {}, data, function (resp) {
                    let newID = {
                        'aortic': resp.id
                    }
                    API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, newID, function (response) {
                        setDetail(response)
                        setIsSaving(false)
                    }, onDataLoadError)
                }, onDataLoadError)
            }
        } else {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'aortic_info': aorticValues['aortic_info'],
                    'hematoma_info': aorticValues['hematoma_info'],
                    'trauma_info': aorticValues['trauma_info'],
                    'coarctation_info': aorticValues['coarctation_info'],
                    'other_info': aorticValues['other_info']
                }
                API.query('PUT', `/api/cpbaortic/${detail.aortic}/`, {}, data, function (resp) {
                    setIsSaving(false)
                }, onDataLoadError)
            }
        }
    }

    const bloodColumns = useMemo(() => [
        { name: '', selector: row => <label className="has-text-weight-semibold">{row.label}</label> },
        {
            name: 'IntraOp Pre-Bypass', selector: row => <div>
                <input
                    defaultValue={getBloodValue(row.id, 2)}
                    placeholder={getBloodValue(row.id, 2)}
                    className="input"
                    type="number"
                    onChange={(e) => onBloodChange(row.id, e.target.value, 2)}
                />
            </div>
        },
        {
            name: 'IntraOp Bypass', selector: row => <div>
                <input
                    defaultValue={getBloodValue(row.id, 3)}
                    placeholder={getBloodValue(row.id, 3)}
                    className="input"
                    type="number"
                    onChange={(e) => onBloodChange(row.id, e.target.value, 3)}
                />
            </div>
        },
        {
            name: 'IntraOp Post-Bypass', selector: row => <div>
                <input
                    defaultValue={getBloodValue(row.id, 4)}
                    placeholder={getBloodValue(row.id, 4)}
                    className="input"
                    type="number"
                    onChange={(e) => onBloodChange(row.id, e.target.value, 4)}
                />
            </div>
        }
    ])

    const onCongenitalDBSave = () => {
        if (!detail.congenital) {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'ductus_info': congenitalTempValues.ductus_info,
                    'norwood_info': congenitalTempValues.norwood_info,
                    'fontan_info': congenitalTempValues.fontan_info,
                    'bialock_info': congenitalTempValues.bialock_info,
                    'vsd_info': congenitalTempValues.vsd_info,
                    'asd_info': congenitalTempValues.asd_info,
                    'tof_info': congenitalTempValues.tof_info,
                    'aorta_info': congenitalTempValues.aorta_info,
                    'truncus_info': congenitalTempValues.truncus_info,
                    'tricuspid_info': congenitalTempValues.tricuspid_info,
                    'pulmonary_info': congenitalTempValues.pulmonary_info,
                    'anamolous_info': congenitalTempValues.anamolous_info,
                    'vessel_info': congenitalTempValues.vessel_info,
                    'other_info': congenitalTempValues.other_info
                }
                API.query('POST', `/api/cpbcongenital/`, {}, data, function (resp) {
                    let newID = {
                        'congenital': resp.id
                    }
                    API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, newID, function (response) {
                        setDetail(response)
                        setIsSaving(false)
                    }, onDataLoadError)
                }, onDataLoadError)
            }
        } else {
            if (!isSaving) {
                setIsSaving(true)
                let data = {
                    'ductus_info': congenitalTempValues.ductus_info,
                    'norwood_info': congenitalTempValues.norwood_info,
                    'fontan_info': congenitalTempValues.fontan_info,
                    'bialock_info': congenitalTempValues.bialock_info,
                    'vsd_info': congenitalTempValues.vsd_info,
                    'asd_info': congenitalTempValues.asd_info,
                    'tof_info': congenitalTempValues.tof_info,
                    'aorta_info': congenitalTempValues.aorta_info,
                    'truncus_info': congenitalTempValues.truncus_info,
                    'tricuspid_info': congenitalTempValues.tricuspid_info,
                    'pulmonary_info': congenitalTempValues.pulmonary_info,
                    'anamolous_info': congenitalTempValues.anamolous_info,
                    'vessel_info': congenitalTempValues.vessel_info,
                    'other_info': congenitalTempValues.other_info
                }
                API.query('PUT', `/api/cpbcongenital/${detail.congenital}/`, {}, data, function (resp) {
                    setIsSaving(false)
                }, onDataLoadError)
            }
        }
    }

    const onCPBBypassChange = (section, value, id) => {
        if (section == 'graft') {
            let bypasses = [...cpbBypassValues]
            let index = bypasses.findIndex((e) => e.id == id)
            if (index > -1) {
                let bypass = bypasses[index]
                bypass['graft_site'] = value
                bypasses[index] = bypass
                setCPBBypassValues(bypasses)
            } else {
                let bypass = allCoronaryValues.find((e) => e.id == id)
                bypasses.push({ id: bypass.id, graft_site: value, conduit: bypass.conduit })
                setCPBBypassValues(bypasses)
            }
        } else if (section == 'conduit') {
            let bypasses = [...cpbBypassValues]
            let index = bypasses.findIndex((e) => e.id == id)
            if (index > -1) {
                let bypass = bypasses[index]
                bypass['conduit'] = value
                bypasses[index] = bypass
                setCPBBypassValues(bypasses)
            } else {
                let bypass = allCoronaryValues.find((e) => e.id == id)
                bypasses.push({ id: bypass.id, graft_site: bypass.graft_site, conduit: value })
                setCPBBypassValues(bypasses)
            }
        }
    }

    const onDataLoadError = (error) => {
        setErrorMessage(error)
        setShowErrorModal(true)
        setIsSaving(false)
    }

    const onConfirm = () => {
        if (accordionID == 1) {
            if (detail.top && detail.top.length != 0) {
                API.query('DELETE', `/api/cpbtop/${detail.top}/`, {}, {}, function (resp) {
                    API.query('GET', `/api/cpbdetails/${detail.id}/`, {}, {}, function (response) {
                        setDetail(response)
                    }, onDataLoadError)
                }, onDataLoadError)
                setAccordionID(0)
                setConfirmationMessage('')
                setShowConfirmationModal(false)
                setTopValues({ start_date: null, end_date: null, start_time: null, end_time: null, or_number: null, classification: null, re_operation: null, operative_approach: null, robotic_assistance: null })
                cpbTopRef.current.reset()
            } else {
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                setTopValues({ start_date: null, end_date: null, start_time: null, end_time: null, or_number: null, classification: null, re_operation: null, operative_approach: null, robotic_assistance: null })
                cpbTopRef.current.reset()
            }
        } else if (accordionID == 2) {
            if ((detail.bypasses && detail.bypasses.length != 0) || (detail.bypass_count && detail.bypass_count >= 1)) {
                var i
                for (i = 0; i < detail.bypasses.length; i++) {
                    API.query('DELETE', `/api/cpbbypass/${detail.bypasses[i]}/`, {}, {}, function (resp) { }, onDataLoadError)
                }
                let data = {
                    'opcab_conversion': null,
                    'bypass_count': null,
                    'bypasses': []
                }
                API.query('PATCH', `/api/cpbdetails/${detail.id}/`, {}, data, function (response) {
                    setDetail(response)
                    setCPBBypassValues([])
                    setCPBBypassCountValues([])
                    setShowConfirmationModal(false)
                    setAccordionID(0)
                    setConfirmationMessage('')
                    setCoronarySubValues({ opcab_conversion: null })
                    cpbBypassRef.current.reset()
                }, onDataLoadError)
            } else {
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                setCoronarySubValues({ opcab_conversion: null })
            }
        } else if (accordionID == 3) {
            if (detail.valve && detail.valve.length != 0) {
                API.query('DELETE', `/api/cpbvalves/${detail.valve}/`, {}, {}, function (resp) {
                    API.query('GET', `/api/cpbdetails/${detail.id}/`, {}, {}, function (response) {
                        setDetail(response)
                        setShowConfirmationModal(false)
                        setAccordionID(0)
                        setConfirmationMessage('')
                        cpbValveRef.current.reset()
                        setValveValues({ aortic_status: null, aortic_info: null, mitral_status: null, mitral_info: null, tricuspid_status: null, tricuspid_info: null, pulmonic_status: null, pulmonic_info: null })
                    }, onDataLoadError)
                }, onDataLoadError)
            } else {
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                cpbValveRef.current.reset()
                setValveValues({ aortic_status: null, aortic_info: null, mitral_status: null, mitral_info: null, tricuspid_status: null, tricuspid_info: null, pulmonic_status: null, pulmonic_info: null })
            }
        } else if (accordionID == 4) {
            if (detail.aortic && detail.aortic.length != 0) {
                API.query('DELETE', `/api/cpbaortic/${detail.aortic}/`, {}, {}, function (resp) {
                    API.query('GET', `/api/cpbdetails/${detail.id}/`, {}, {}, function (response) {
                        setDetail(response)
                        setShowConfirmationModal(false)
                        setAccordionID(0)
                        setConfirmationMessage('')
                        cpbAorticRef.current.reset()
                        setAorticValues({ aortic_info: null, hematoma_info: null, trauma_info: null, coarctation_info: null, other_info: null })
                    }, onDataLoadError)
                }, onDataLoadError)
            } else {
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                cpbAorticRef.current.reset()
                setAorticValues({ aortic_info: null, hematoma_info: null, trauma_info: null, coarctation_info: null, other_info: null })
            }
        } else if (accordionID == 5) {
            if (detail.congenital && detail.congenital.length != 0) {
                API.query('DELETE', `/api/cpbcongenital/${detail.congenital}/`, {}, {}, function (resp) {
                    API.query('GET', `/api/cpbdetails/${detail.id}/`, {}, {}, function (response) {
                        setDetail(response)
                        setShowConfirmationModal(false)
                        setAccordionID(0)
                        setConfirmationMessage('')
                        setCongenitalTempValues({ ductus_info: null, norwood_info: null, fontan_info: null, bialock_info: null, vsd_info: null, asd_info: null, tof_info: null, aorta_info: null, truncus_info: null, tricuspid_info: null, pulmonary_info: null, anamolous_info: null, vessel_info: null, other_info: null })
                        cpbCongenitalRef.current.reset()
                    }, onDataLoadError)
                }, onDataLoadError)
            } else {
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                setCongenitalTempValues({ ductus_info: null, norwood_info: null, fontan_info: null, bialock_info: null, vsd_info: null, asd_info: null, tof_info: null, aorta_info: null, truncus_info: null, tricuspid_info: null, pulmonary_info: null, anamolous_info: null, vessel_info: null, other_info: null })
                cpbCongenitalRef.current.reset()
            }
        } else if (accordionID == 6) {
            if (detail.circulatory_device && detail.circulatory_device.length != 0) {
                API.query('DELETE', `/api/cpbcircdevices/${detail.circulatory_device}/`, {}, {}, function (resp) {
                    API.query('GET', `/api/cpbdetails/${detail.id}/`, {}, {}, function (response) {
                        setDetail(response)
                        setShowConfirmationModal(false)
                        setAccordionID(0)
                        setConfirmationMessage('')
                        cpbAssistRef.current.reset()
                        setCirculatoryValues({ lvad_status: null, lvad_info: null, rvad_status: null, rvad_info: null, tah_status: null, tah_info: null, other_status: null, other_info: null })
                    }, onDataLoadError)
                }, onDataLoadError)
            } else {
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                cpbAssistRef.current.reset()
                setCirculatoryValues({ lvad_status: null, lvad_info: null, rvad_status: null, rvad_info: null, tah_status: null, tah_info: null, other_status: null, other_info: null })
            }
        } else if (accordionID == 7) {
            if (detail.transplant && detail.transplant.length != 0) {
                API.query('DELETE', `/api/cpbtransplants/${detail.transplant}/`, {}, {}, function (resp) {
                    API.query('GET', `/api/cpbdetails/${detail.id}/`, {}, {}, function (response) {
                        setDetail(response)
                        setShowConfirmationModal(false)
                        setAccordionID(0)
                        setConfirmationMessage('')
                        cpbTransplantRef.current.reset()
                        setHeartLungValues({ heart_time: null, right_time: null, left_time: null })
                    }, onDataLoadError)
                }, onDataLoadError)
            } else {
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                cpbTransplantRef.current.reset()
                setHeartLungValues({ heart_time: null, right_time: null, left_time: null })
            }
        } else if (accordionID == 8) {
            if (detail.other && detail.other.length != 0) {
                API.query('DELETE', `/api/cpbother/${detail.other}/`, {}, {}, function (resp) {
                    API.query('GET', `/api/cpbdetails/${detail.id}/`, {}, {}, function (response) {
                        setDetail(response)
                        setShowConfirmationModal(false)
                        setAccordionID(0)
                        setConfirmationMessage('')
                        cpbOtherRef.current.reset()
                        setOtherValues({ lead: null, artrial: null, asd: null, vsd: null, lva: null, tmr: null, tumor: null, pulmonary: null, cardiac: null, other: null })
                    }, onDataLoadError)
                }, onDataLoadError)
            } else {
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                cpbOtherRef.current.reset()
                setOtherValues({ lead: null, artrial: null, asd: null, vsd: null, lva: null, tmr: null, tumor: null, pulmonary: null, cardiac: null, other: null })
            }
        } else if (accordionID == 9) {
            if (detail.middle && detail.middle.length != 0) {
                API.query('DELETE', `/api/cpbmiddle/${detail.middle}/`, {}, {}, function (resp) {
                    API.query('GET', `/api/cpbdetails/${detail.id}/`, {}, {}, function (response) {
                        setDetail(response)
                        setShowConfirmationModal(false)
                        setAccordionID(0)
                        setConfirmationMessage('')
                        setMiddleValues({ cpb_time: null, clamp_time: null, pre_hct: null, post_hct: null, lowest_hct: null, perfusion_volume: null, anes_volume: null, urine_output: null, fluid_balance: null, lowest_cpb: null, iabp: null, status: null, hemoconcentration: null, glucose: null })
                        cpbSecondRef.current.reset()
                    }, onDataLoadError)
                }, onDataLoadError)
            } else {
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                cpbSecondRef.current.reset()
                setMiddleValues({ cpb_time: null, clamp_time: null, pre_hct: null, post_hct: null, lowest_hct: null, perfusion_volume: null, anes_volume: null, urine_output: null, fluid_balance: null, lowest_cpb: null, iabp: null, status: null, hemoconcentration: null, glucose: null })
            }
        } else if (accordionID == 10) {
            if (detail.cardioplegia && detail.cardioplegia.length != 0) {
                API.query('DELETE', `/api/cpbcardio/${detail.cardioplegia}/`, {}, {}, function (resp) {
                    API.query('GET', `/api/cpbdetails/${detail.id}/`, {}, {}, function (response) {
                        setDetail(response)
                        setShowConfirmationModal(false)
                        setAccordionID(0)
                        setConfirmationMessage('')
                        cpbCardioRef.current.reset()
                        setCardioValues({ type: null, crystalloid_ratio: null, crystalloid_amount: null, delivery_method: null, temperature: null })
                    }, onDataLoadError)
                }, onDataLoadError)
            } else {
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                cpbCardioRef.current.reset()
                setCardioValues({ type: null, crystalloid_ratio: null, crystalloid_amount: null, delivery_method: null, temperature: null })
            }
        } else if (accordionID == 11) {
            if (detail.circulatory_arrest && detail.circulatory_arrest.length != 0) {
                API.query('DELETE', `/api/cpbcircarrest/${detail.circulatory_arrest}/`, {}, {}, function (resp) {
                    API.query('GET', `/api/cpbdetails/${detail.id}/`, {}, {}, function (response) {
                        setDetail(response)
                        setShowConfirmationModal(false)
                        setAccordionID(0)
                        setConfirmationMessage('')
                        cpbArrestRef.current.reset()
                        setArrestValues({ arrest_time: null, arrest_status: null })
                    }, onDataLoadError)
                }, onDataLoadError)
            } else {
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                cpbArrestRef.current.reset()
                setArrestValues({ arrest_time: null, arrest_status: null })
            }
        } else if (accordionID == 12) {
            if (detail.blood && detail.blood.length != 0) {
                API.query('DELETE', `/api/cpbblood/${detail.blood}/`, {}, {}, function (resp) {
                    API.query('GET', `/api/cpbdetails/${detail.id}/`, {}, {}, function (response) {
                        setDetail(response)
                        setShowConfirmationModal(false)
                        setAccordionID(0)
                        setConfirmationMessage('')
                        cpbBloodRef.current.reset()
                        setBloodPRBCValues({ preBypass: null, bypass: null, postBypass: null })
                        setBloodPlateletValues({ preBypass: null, bypass: null, postBypass: null })
                        setBloodFFPValues({ preBypass: null, bypass: null, postBypass: null })
                        setBloodWholeValues({ preBypass: null, bypass: null, postBypass: null })
                        setBloodCryoValues({ preBypass: null, bypass: null, postBypass: null })
                    }, onDataLoadError)
                }, onDataLoadError)
            } else {
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                cpbBloodRef.current.reset()
                setBloodPRBCValues({ preBypass: null, bypass: null, postBypass: null })
                setBloodPlateletValues({ preBypass: null, bypass: null, postBypass: null })
                setBloodFFPValues({ preBypass: null, bypass: null, postBypass: null })
                setBloodWholeValues({ preBypass: null, bypass: null, postBypass: null })
                setBloodCryoValues({ preBypass: null, bypass: null, postBypass: null })
            }
        } else if (accordionID == 13) {
            if (detail.notes && detail.notes.length != 0) {
                API.query('DELETE', `/api/cpbnotes/${detail.notes}/`, {}, {}, function (resp) {
                    API.query('GET', `/api/cpbdetails/${detail.id}/`, {}, {}, function (response) {
                        setDetail(response)
                        setShowConfirmationModal(false)
                        setAccordionID(0)
                        setConfirmationMessage('')
                        setNoteValues({ case_note: '', department_note: '' })
                    }, onDataLoadError)
                }, onDataLoadError)
            } else {
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                setNoteValues({ case_note: '', department_note: '' })
            }
        }
    }

    return <div>
        <form ref={cpbTopRef}>
            <DynamicForm
                key={1}
                form={topForm}
                values={topValues}
                onValuesChanged={onTopChanged}
            />
        </form>


        <hr />

        <div className="level w-100">
            <div className="level-left">
                <button className="button is-danger is-inverted" onClick={onTopClear}>Clear</button>
            </div>
            <div className="level-right">
                <button className={saveBtnClasses} onClick={onTopSave}>Save</button>
            </div>
        </div>

        <hr />

        <form ref={cpbSecondRef}>
            <DynamicForm
                key={2}
                form={middleForm}
                values={middleValues}
                onValuesChanged={onMiddleChanged}
            />
        </form>

        <hr />

        <div className="level w-100">
            <div className="level-left">
                <button className="button is-danger is-inverted" onClick={onMiddleClear}>Clear</button>
            </div>
            <div className="level-right">
                <button className={saveBtnClasses} onClick={onMiddleSave}>Save</button>
            </div>
        </div>

        <hr />

        <Accordion allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Coronary Artery Bypass
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>

                    <div className="level w-100">
                        <div className="level-left">
                            <form ref={cpbBypassRef}>
                                <DynamicForm
                                    key={3}
                                    form={BypassCountForm}
                                    values={cpbBypassCountValues}
                                    onValuesChanged={onCPBBypassCountChange}
                                />
                            </form>
                        </div>
                        <div className="level-right">
                            <button style={{ backgroundColor: '#1C2944', color: '#FFFFFF' }} onClick={onBypassAdd} className="button">Add CABG</button>
                        </div>
                    </div>


                    <hr />

                    <DataTable
                        striped
                        pagination
                        data={filteredCoronaryValues}
                        columns={coronaryColumns}
                    />

                    <DynamicForm
                        key={4}
                        form={coronarySubForm}
                        values={coronarySubValues}
                        onValuesChanged={onCoronarySubChanged}
                    />

                    <br/>

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onArteryClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onArterySave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Valve
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <form ref={cpbValveRef}>
                        <DynamicForm
                            key={5}
                            form={valveForm}
                            values={valveValues}
                            onValuesChanged={onValveChanged}
                        />
                    </form>


                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onValveClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onValveSave}>Save</button>
                        </div>
                    </div>
                    <div>


                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Aortic Surgery
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <form ref={cpbAorticRef}>
                        <DynamicForm
                            key={6}
                            form={aorticForm}
                            values={aorticValues}
                            onValuesChanged={onAorticChanged}
                        />
                    </form>

                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onAorticClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onAorticSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Congenital Surgery
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <form ref={cpbCongenitalRef}>
                        <DynamicForm
                            key={7}
                            form={congenitalForm}
                            values={congenitalTempValues}
                            onValuesChanged={onCongenitalChanged}
                        />
                    </form>

                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onCongenitalClear}>Cancel</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onCongenitalDBSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Circulatory Assist Device
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <form ref={cpbAssistRef}>
                        <DynamicForm
                            key={8}
                            form={circulatoryForm}
                            values={circulatoryValues}
                            onValuesChanged={onCirculatoryChanged}
                        />
                    </form>

                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onCirculatoryClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onCirculatorySave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Transplant
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <form ref={cpbTransplantRef}>
                        <DynamicForm
                            key={9}
                            form={heartLungForm}
                            values={heartLungValues}
                            onValuesChanged={onHeartLungChanged}
                        />
                    </form>

                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onTransplantClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onTransplantSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Other
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <form ref={cpbOtherRef}>
                        <DynamicForm
                            key={10}
                            form={otherForm}
                            values={otherValues}
                            onValuesChanged={onOtherChanged}
                        />
                    </form>

                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onOtherClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onOtherSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Cardioplegia
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <form ref={cpbCardioRef}>
                        <DynamicForm
                            key={11}
                            form={cardioForm}
                            values={cardioValues}
                            onValuesChanged={onCardioChanged}
                        />
                    </form>

                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onCardioClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onCardioSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Circulatory Arrest
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <form ref={cpbArrestRef}>
                        <DynamicForm
                            key={12}
                            form={arrestForm}
                            values={arrestValues}
                            onValuesChanged={onArrestChanged}
                        />
                    </form>

                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onArrestClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onArrestSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Blood Products
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <form ref={cpbBloodRef}>
                        <DataTable
                            striped
                            data={bloodProducts}
                            columns={bloodColumns}
                        />
                    </form>

                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onBloodClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onBloodSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Notes
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <DynamicForm
                        key={13}
                        form={noteForm}
                        values={noteValues}
                        onValuesChanged={onNoteChanged}
                    />

                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onNoteClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onNoteSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>

        {
            showGraftConfirmationModal
            &&
            <ConfirmationModal
                message={"You are about to delete a Graft. This cannot be undone."}
                onConfirm={onConfirmGraftDelete}
                onClose={() => setShowGraftConfirmationModal(false)}
            />
        }

        {
            showConfirmationModal
            &&
            <ConfirmationModal
                message={confirmationMessage}
                onConfirm={onConfirm}
                onClose={() => setShowConfirmationModal(false)}
            />
        }

        {
            showErrorModal
            &&
            <ErrorModal
                message={errorMessage}
                onClose={() => setShowErrorModal(false)}
            />
        }
    </div>
}

export default DetailCPB