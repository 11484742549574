import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from 'react-to-print';
import Select from 'react-select';
import DatePicker from 'react-datepicker'
import API from "../../services/API";

function PdfABCPReport(props) {
    const [userOptions, setUserOptions] = useState([])
    const [filters, setFilters] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [formattedData, setFormattedData] = useState()
    const componentRef = useRef()
    const today = new Date()

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onFilterChanged = (value, type) => {
        let fValues = { ...filters }
        if (type === 0) {
            fValues['start_date'] = value
        } else if (type === 1) {
            fValues['end_date'] = value
        } else if (type === 2) {
            fValues['facility'] = value
        } else if (type === 3) {
            fValues['user'] = value
        }
        setFilters(fValues)
    }

    const getObjectValueByString = (o, p) => {
        const keys = p.split('.');
        let obj = o;

        for (const key of keys) {
            if (obj[key] === undefined || obj[key] === null) {
                return undefined;
            }
            obj = obj[key];
        }

        return obj;
    }

    const getOperationDetails = (data) => {
        const operationArray = []
        const arrayToCheck = [
            {
                conditional: "cpb_detail.bypass_count",
                value: "cpb_detail.bypass_count",
                prepend: "CABG x",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.valve.aortic_status",
                value: "cpb_detail.valve.aortic_info",
                prepend: "Aortic Valve ",
                useConditionalValue: true,
            },
            {
                conditional: "cpb_detail.valve.mitral_status",
                value: "cpb_detail.valve.mitral_info",
                prepend: "Mitral Valve ",
                useConditionalValue: true,
            },
            {
                conditional: "cpb_detail.valve.tricuspid_status",
                value: "cpb_detail.valve.tricuspid_info",
                prepend: "Tricuspid Valve ",
                useConditionalValue: true,
            },
            {
                conditional: "cpb_detail.valve.pulmonic_status",
                value: "cpb_detail.valve.pulmonic_info",
                prepend: "Pulmonic Valve ",
                useConditionalValue: true,
            },
            {
                conditional: "cpb_detail.aortic.aortic_info",
                value: "cpb_detail.aortic.aortic_info",
                prepend: "Aortic Surgery - Aortic -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.aortic.hematoma_info",
                value: "cpb_detail.aortic.hematoma_info",
                prepend: "Aortic Surgery - Hematoma -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.aortic.trauma_info",
                value: "cpb_detail.aortic.trauma_info",
                prepend: "Aortic Surgery - Trauma -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.aortic.coarctation_info",
                value: "cpb_detail.aortic.coarctation_info",
                prepend: "Aortic Surgery - Coarctation -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.aortic.other_info",
                value: "cpb_detail.aortic.other_info",
                prepend: "Aortic Surgery - Other -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.congenital.ductus_info",
                value: "cpb_detail.congenital.ductus_info",
                prepend: "Congenital Surgery - Ductus Arteriosus Ligation -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.congenital.norwood_info",
                value: "cpb_detail.congenital.norwood_info",
                prepend: "Congenital Surgery - Norwood Procedure -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.congenital.fontan_info",
                value: "cpb_detail.congenital.fontan_info",
                prepend: "Congenital Surgery - Fontan Procedure -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.congenital.bialock_info",
                value: "cpb_detail.congenital.bialock_info",
                prepend: "Congenital Surgery - Bialock-Taussig Shunt -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.congenital.vsd_info",
                value: "cpb_detail.congenital.vsd_info",
                prepend: "Congenital Surgery - VSD Closure -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.congenital.asd_info",
                value: "cpb_detail.congenital.asd_info",
                prepend: "Congenital Surgery - ASD Closure -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.congenital.tof_info",
                value: "cpb_detail.congenital.tof_info",
                prepend: "Congenital Surgery - TOF Repair -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.congenital.aorta_info",
                value: "cpb_detail.congenital.aorta_info",
                prepend: "Congenital Surgery - Aorta Coarctation Repair -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.congenital.truncus_info",
                value: "cpb_detail.congenital.truncus_info",
                prepend: "Congenital Surgery - Truncus Arteriosus Repair -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.congenital.tricuspid_info",
                value: "cpb_detail.congenital.tricuspid_info",
                prepend: "Congenital Surgery - Tricuspid Atresia Correction -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.congenital.pulmonary_info",
                value: "cpb_detail.congenital.pulmonary_info",
                prepend: "Congenital Surgery - Pulmonary Atresia Correction -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.congenital.anamolous_info",
                value: "cpb_detail.congenital.anamolous_info",
                prepend: "Congenital Surgery - Anamolous Pulmonary Venous Return Correction -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.congenital.vessel_info",
                value: "cpb_detail.congenital.vessel_info",
                prepend: "Congenital Surgery - Vessel Transposition Correction -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.congenital.other_info",
                value: "cpb_detail.congenital.other_info",
                prepend: "Congenital Surgery - Other -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.circulatory_device.lvad_status",
                value: "cpb_detail.circulatory_device.lvad_info",
                prepend: "CAD LVAD ",
                useConditionalValue: true,
            },
            {
                conditional: "cpb_detail.circulatory_device.rvad_status",
                value: "cpb_detail.circulatory_device.rvad_info",
                prepend: "CAD RVAD ",
                useConditionalValue: true,
            },
            {
                conditional: "cpb_detail.circulatory_device.tah_status",
                value: "cpb_detail.circulatory_device.tah_info",
                prepend: "CAD TAH ",
                useConditionalValue: true,
            },
            {
                conditional: "cpb_detail.circulatory_device.other_status",
                value: "cpb_detail.circulatory_device.other_info",
                prepend: "CAD Other ",
                useConditionalValue: true,
            },
            {
                conditional: "cpb_detail.transplant.heart_time",
                value: "cpb_detail.transplant.heart_time",
                prepend: "Heart Transplant -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.transplant.right_time",
                value: "cpb_detail.transplant.right_time",
                prepend: "Right Lung Transplant -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.transplant.left_time",
                value: "cpb_detail.transplant.left_time",
                prepend: "Left Lung Transplant -",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.other.lead",
                value: "DNE",
                prepend: "Lead Insertion/Extraction",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.other.artrial",
                value: "DNE",
                prepend: "Artrial Fibrillation Surgery",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.other.asd",
                value: "DNE",
                prepend: "ASD Closure (Non-Congenital)",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.other.vsd",
                value: "DNE",
                prepend: "VSD Closure (Non-Congenital)",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.other.lva",
                value: "DNE",
                prepend: "LVA Repair",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.other.tmr",
                value: "DNE",
                prepend: "TMR Laser",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.other.tumor",
                value: "DNE",
                prepend: "Tumor Excision",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.other.pulmonary",
                value: "DNE",
                prepend: "Pulmonary Embolectomy",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.other.cardiac",
                value: "DNE",
                prepend: "Cardiac/Vascular Trauma",
                useConditionalValue: false,
            },
            {
                conditional: "cpb_detail.other.other",
                value: "cpb_detail.other.other",
                prepend: "Other - ",
                useConditionalValue: false,
            },
            {
                conditional: "auto_detail.cardiac_checked",
                value: "auto_detail.cardiac_note",
                prepend: "Cardiac",
                useConditionalValue: false,
            },
            {
                conditional: "auto_detail.vascular_checked",
                value: "auto_detail.vascular_note",
                prepend: "Vascular",
                useConditionalValue: false,
            },
            {
                conditional: "auto_detail.ortho_checked",
                value: "auto_detail.ortho_note",
                prepend: "Orthopedic",
                useConditionalValue: false,
            },
            {
                conditional: "auto_detail.hepatic_checked",
                value: "auto_detail.hepatic_note",
                prepend: "Hepatic",
                useConditionalValue: false,
            },
            {
                conditional: "auto_detail.neuro_checked",
                value: "auto_detail.neuro_note",
                prepend: "Neuro",
                useConditionalValue: false,
            },
            {
                conditional: "auto_detail.trauma_checked",
                value: "auto_detail.trauma_note",
                prepend: "Trauma",
                useConditionalValue: false,
            },
            {
                conditional: "auto_detail.other_checked",
                value: "auto_detail.other_note",
                prepend: "Other",
                useConditionalValue: false,
            },
            {
                conditional: "platelet_detail.cardiac_checked",
                value: "platelet_detail.cardiac_info",
                prepend: "Cardiac",
                useConditionalValue: false,
            },
            {
                conditional: "platelet_detail.vascular_checked",
                value: "platelet_detail.vascular_info",
                prepend: "Vascular",
                useConditionalValue: false,
            },
            {
                conditional: "platelet_detail.ortho_checked",
                value: "platelet_detail.ortho_info",
                prepend: "Orthopedic",
                useConditionalValue: false,
            },
            {
                conditional: "platelet_detail.neuro_checked",
                value: "platelet_detail.neuro_info",
                prepend: "Neuro",
                useConditionalValue: false,
            },
            {
                conditional: "platelet_detail.wound_checked",
                value: "platelet_detail.wound_info",
                prepend: "Wound Clinic",
                useConditionalValue: false,
            },
            {
                conditional: "platelet_detail.other_checked",
                value: "platelet_detail.other_info",
                prepend: "Other",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.bypass_count",
                value: "perfusion_detail.bypass_count",
                prepend: "CABG x",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.pericardial_window",
                value: "DNE",
                prepend: "Cardiac (Other) - Pericardial Window",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.re_exploration",
                value: "DNE",
                prepend: "Cardiac (Other) - Re-Exploration",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.lead_insertion",
                value: "DNE",
                prepend: "Cardiac (Other) - Lead Insertion/Extraction",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.other_trauma",
                value: "DNE",
                prepend: "Cardiac (Other) - Trauma",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.atrial",
                value: "DNE",
                prepend: "Cardiac (Other) - Atrial Fibrillation Surgery",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.tavr",
                value: "DNE",
                prepend: "Cardiac (Other) - TAVR",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.tmvr",
                value: "DNE",
                prepend: "Cardiac (Other) - TMVR",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.mitral_clip",
                value: "DNE",
                prepend: "Cardiac (Other) - Mitral Clip",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.vad_insertion",
                value: "DNE",
                prepend: "Cardiac (Other) - VAD Insertion",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.vad_removal",
                value: "DNE",
                prepend: "Cardiac (Other) - VAD Removal",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.ecmo_insertion",
                value: "DNE",
                prepend: "Cardiac (Other) - ECMO Insertion",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.ecmo_removal",
                value: "DNE",
                prepend: "Cardiac (Other) - ECMO Removal",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.cardiac_other",
                value: "perfusion_detail.cardiac_other_type",
                prepend: "Cardiac (Other) - Other",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.transplant",
                value: "DNE",
                prepend: "Pulmonary - Transplant",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.embolectomy",
                value: "DNE",
                prepend: "Pulmonary - Embolectomy",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.pulmonary_other",
                value: "perfusion_detail.pulmonary_other_type",
                prepend: "Pulmonary - Other",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.vascular_checked",
                value: "perfusion_detail.vascular_note",
                prepend: "Vascular",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.cath_checked",
                value: "perfusion_detail.cath_note",
                prepend: "Cath Lab",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.hepatic_checked",
                value: "perfusion_detail.hepatic_note",
                prepend: "Hepatic",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.auto_checked",
                value: "perfusion_detail.auto_note",
                prepend: "Autotransfusion Standby",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.ecmo_checked",
                value: "perfusion_detail.ecmo_note",
                prepend: "ECMO",
                useConditionalValue: false,
            },
            {
                conditional: "perfusion_detail.other_checked",
                value: "perfusion_detail.other_note",
                prepend: "Other",
                useConditionalValue: false,
            },
        ];
        arrayToCheck.forEach(key => {
            const conditional = getObjectValueByString(data, key.conditional);
            if (conditional) {
                if (key.useConditionalValue) {
                    const val = getObjectValueByString(data, key.value);
                    if (val) {
                        const entry = `${key.prepend}${conditional} (${val})`;
                        operationArray.push(entry);
                    } else {
                        operationArray.push(`${key.prepend}${conditional}`);
                    }
                } else {
                    const val = getObjectValueByString(data, key.value);
                    if (val) {
                        const entry = `${key.prepend ?? ""} ${val}`;
                        operationArray.push(entry);
                    } else {
                        operationArray.push(key.prepend);
                    }
                }
            }
        });
        const operation_detail_string = operationArray.join(" / ");
        return operation_detail_string;
    }

    const renderData = () => {
        if (filters.user !== null && filters.start_date !== null && filters.end_date !== null) {
            if (!isLoading) {
                setIsLoading(true)
                const start_arr = start_date.split('/');
                const end_arr = end_date.split('/');
                let params = {
                    'user_id': filters.user,
                    'start_date': `${start_arr[2]}-${start_arr[0]}-${start_arr[1]}`,
                    'end_date': `${end_arr[2]}-${end_arr[0]}-${end_arr[1]}`,
                }

                if (filters.facility?.length > 0) {
                    params = {
                        ...params,
                        'facilities': filters.facility,
                    }
                }

                API.query('GET', `/api/qcbundlereport/`, params, {}, function (resp) {
                    const data = resp.sort((a,b) => {
                        return new Date(a.date) - new Date(b.date);
                    });
                    const user_name = userOptions.find(z => filters.user === z.value).label
                    const newData = {
                        user_name: user_name,
                        cases: data.map((x,idx) => {
                            const operationString = getOperationDetails(x);
                            const procedureString = x?.procedure.map((x) => {
                                return x.name
                            })?.join(", ");
                            const roleObj = x?.personnel_roles?.find(z => z.user_full_name === user_name);
                            const roleString = roleObj?.job?.name;
                            const surgeon = x?.personnel_roles?.find(z => z?.job?.id === 15)?.user_full_name ?? '';
                            let cc = '';
                            if (procedureString === 'ECLS' && roleString.includes('1st Perfusionist')) {
                                cc = '3P';
                            }
                            if (procedureString.includes('Perfusion Standby') && roleString.includes('1st Perfusionist')) {
                                cc = '2S';
                            }
                            if (!procedureString.includes('Perfusion Standby') && procedureString.includes('CPB')) {
                                if(roleString.includes('1st Perfusionist')) {
                                    cc = '1P';
                                } else if(roleString.includes('2nd Perfusionist')) {
                                    cc = '1S';
                                }
                            }
                            return {
                                id: idx + 1,
                                facility_name: x.facility_name,
                                date: x.date,
                                surgeon: surgeon,
                                procedure: procedureString,
                                role: roleString,
                                operation: operationString,
                                cc: cc
                            };
                        })
                    };

                    setFormattedData(newData);
                    setIsLoading(false);
                }, () => onDataLoadError())
            }
        }
    }

    const onDataLoadError = (error) => {
        console.log(error);
        setIsLoading(false);
    }

    useEffect(() => {
        setIsLoading(true)

        let queries = [
            { 'method': 'GET', 'path': '/api/userlist/' }
        ]
        API.all(queries, function (resp) {
            setUserOptions([...resp[0].map(i => ({ "value": i.id, "label": i.user_full_name }))])
            setIsLoading(false)
        }, onDataLoadError)
    }, []);

    const start_date = filters.start_date ? new Date(filters.start_date).toLocaleDateString() : ''
    const end_date = filters.end_date ? new Date(filters.end_date).toLocaleDateString() : ''

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card modal-extra-large">
            <header className="modal-card-head">
                <p className="modal-card-title">ABCP Report</p>
            </header>

            <section className="modal-card-body p-0">
                <div className="p-5">
                    <div className="w-100">
                        <div className="columns">
                            <div className="column">
                                <div style={{ midWidth: '400px', maxWidth: '400px' }}>
                                    <Select
                                        options={props.facilities}
                                        placeholder={'All Facilities'}
                                        value={props.facilities.find((e) => e.value == filters.facility)}
                                        onChange={(option) => onFilterChanged(option.map(z => z.value), 2)}
                                        isMulti={true}
                                    />
                                </div>
                                <br />
                                <div style={{ maxWidth: '200px', minWidth: '200px' }}>
                                    <DatePicker
                                        className="input"
                                        selectsStart
                                        startDate={filters.start_date}
                                        endDate={filters.end_date}
                                        selected={filters.start_date}
                                        onChange={(date) => onFilterChanged(date, 0)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        maxDate={new Date()}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Start Date"
                                    />
                                </div>
                            </div>
                            <div className="column">
                                <div style={{ midWidth: '400px', maxWidth: '400px' }}>
                                    <Select
                                        options={userOptions}
                                        placeholder={'Select a User'}
                                        value={userOptions.find((e) => e.value == filters.user)}
                                        onChange={(option) => onFilterChanged(option.value, 3)}
                                    />
                                </div>
                                <br />
                                <div style={{ maxWidth: '200px', minWidth: '200px' }}>
                                    <DatePicker
                                        className="input"
                                        selectsStart
                                        startDate={filters.start_date}
                                        endDate={filters.end_date}
                                        selected={filters.end_date}
                                        onChange={(date) => onFilterChanged(date, 1)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        maxDate={new Date()}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="End Date"
                                    />
                                </div>
                            </div>
                        </div>

                        <button className={isLoading ? "button is-success is-loading" : "button is-success"} onClick={() => renderData()}>Generate</button>

                        {
                            !formattedData
                            &&
                            <div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                            </div>
                        }

                        {
                            formattedData
                            &&
                            <div className="has-background-white" ref={componentRef}>
                                <style type="text/css" media="print">{"\
                                    @page {\ size: landscape;\ }\
                                "}</style>
                                <div>
                                    <p className="is-size-2">The American Board of Cardiovascular Perfusion</p>
                                    <p>Clinical Activity Report</p>
                                    <p>&#40;Printed: {(new Date()).toLocaleDateString() ?? ''}&#41;</p>

                                    <br />

                                    <p className="has-text-weight-bold">Date Range:</p>
                                    <p>{start_date} to {end_date}</p>

                                    <br />
                                    <p>{userOptions.find(z => z.value === filters.user).label}</p>
                                    <p className="has-text-weight-bold" style={{ borderTop: "solid", maxWidth: "360px" }}>Candidate's Name</p>

                                    <br />
                                    <p className="has-text-weight-bold" style={{ borderTop: "solid", maxWidth: "360px" }}>Home Address</p>

                                    <br />
                                    <p className="has-text-weight-bold" style={{ borderTop: "solid", maxWidth: "360px" }}>City, Region, Postal Code, Country</p>

                                    <br />
                                    <p className="has-text-weight-bold" style={{ borderTop: "solid", maxWidth: "360px" }}>Accredited Affiliate Perfusion Program/School</p>

                                    <br />

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Hospital Name</th>
                                                <th>Date</th>
                                                <th>Surgeon</th>
                                                <th>Procedure Type</th>
                                                <th>Role</th>
                                                <th>Operation</th>
                                                <th>CC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formattedData.cases.map((x) => {
                                                return <tr>
                                                    <td>{x.id}</td>
                                                    <td>{x.facility_name}</td>
                                                    <td>{x.date}</td>
                                                    <td>{x.surgeon}</td>
                                                    <td>{x.procedure}</td>
                                                    <td>{x.role}</td>
                                                    <td>{x.operation}</td>
                                                    <td>{x.cc}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <button onClick={props.onClose} className="modal-close is-large" aria-label="close"></button>
            </section>

            <footer className="modal-card-foot">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="button is-danger" onClick={props.onClose}>Close</button>
                    </div>
                    <div className="level-right">
                        {
                            formattedData
                            &&
                            <button className="button is-success" onClick={handlePrint}>Print</button>
                        }
                    </div>
                </div>
            </footer>
        </div>
    </div>
}

export default PdfABCPReport;