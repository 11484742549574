import React, { useState } from "react";
import DynamicForm from "./DynamicForm";
import personnelTempForm from  "../forms/personnelTemp";

function PersonnelTempModal(props){
  const [data,setData] = useState({...props.data})

  const onSave = () => {
    props.onSave(data)
  }

  const onCancel = () => {
    props.onClose()
  }

  const isEdit = !!data.id

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head has-background-white">
        <p className="is-size-4">{isEdit?"Edit":"Add"} Temp - Personnel</p>
      </header>

      <section className="modal-card-body">
        <DynamicForm
          form={personnelTempForm}
          values={data}
          onValuesChanged={setData}
        />
      </section>

      <footer className="modal-card-foot has-background-white">
        <div className="level w-100">
          <div className="level-left">
            <button className="level-item button is-danger is-inverted" onClick={onCancel}>Cancel</button>
          </div>
          <div className="level-right">
            <button className="level-item button is-success" onClick={onSave}>Save</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
}

export default PersonnelTempModal