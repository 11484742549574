import React, { useState, useEffect, useMemo } from "react";
import DynamicForm from "../../../components/DynamicForm";
import API from "../../../../services/API";
import itemSearch from "../../../forms/itemSearch";
import DataTable from 'react-data-table-component';
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import ItemModal from "../../../components/ItemModal";
import itemModal from "../../../forms/itemModal";
import ErrorModal from "../../../components/ErrorModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import itemSubMenu from "../../../forms/subMenuItemCount";
import VisitTimer from "../../../components/VisitTimer";
import Session from "../../../../services/Session"

function ItemSetup(props){
  let subMenuString = 'Location Count'
  const [filterValues,setFilterValues] = useState();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [items, setItems] = useState([]);
  const [showSetupModal,setShowSetupModal] = useState(false);
  const [editValues, setEditValues] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [isSubEditMode, setIsSubEditMode] = useState(false);
  const [showSubConfirmationModal, setShowSubConfirmationModal] = useState(false);
  const [showSubAdd, setShowSubAdd] = useState(false);
  const [subEditValues, setSubEditValues] = useState([]);
  const [isSubMenu, setIsSubMenu] = useState(true);
  const [addNewSub, setAddNewSub] = useState(false);
  const [locationCounts, setLocationCounts] = useState([]);

  const onFilterChanged = (values) => {
    setFilterValues(values)
  }

  const onEditChange = (values) => {
    setEditValues(values)
  }

  const onSubEditChange = (values) => {
    setSubEditValues(values)
  }

  const onSave = () => {
    if(!isSaving) {
      setIsSaving(true)
      let data = {
        'item_category':editValues['item_category'],
        'item_type':editValues['item_type'],
        'status':(editValues['status'] && editValues['status'] == 'false')?false:true,
        'manufacturer':editValues['manufacturer'],
        'catalog':editValues['catalog'],
        'price':editValues['price'],
        'image':editValues['image'],
        'description':editValues['description'],
        'type':editValues['type'],
        'quantity':editValues['quantity'],
        'location_counts':editValues['location_counts']
      }
      let method = isEditMode ? 'PUT' : 'POST'
      let uri = isEditMode ? `/api/items/${editValues.id}/` : '/api/items/'
      API.query(method,uri,{},data, function(resp) {
        let newItems = items
        if(isEditMode) {
          let i = newItems.findIndex(j => j.id == editValues.id)
          if(i > -1) {
            data['id'] = editValues.id
            newItems[i] = data
          }
        }else {
          data['id'] = resp.id
          newItems.push(data)
        }
        setItems(newItems)
        setIsSaving(false)
        setShowSetupModal(false)
        props.updateID()
        setEditValues([])
        API.query("GET",`/api/locationcounts/`,{},{}, function(resp) {
          setLocationCounts(resp)
        },onDataLoadError)
      },onDataLoadError)
    }
  }

  const appendLocationCount = (locationEditValues, respID) => {
    let newEditValues = locationEditValues
    newEditValues.push(respID)
    return newEditValues
  }

  const onSubSave = () => {
    if(!isSaving) {
      setIsSaving(true)
      console.log('subeditvalues',subEditValues)
      let data = {
        'facility':subEditValues['facility'],
        'price':subEditValues['price'],
        'par':subEditValues['par'],
        'count':subEditValues['count'],
        'reorder_status':(subEditValues['reorder_status'] && subEditValues['reorder_status'] == 'false')?false:true,
        'date': new Date()
      }
      let method = isSubEditMode ? 'PUT' : 'POST'
      let uri = isSubEditMode ? `/api/locationcounts/${subEditValues.id}/` : '/api/locationcounts/'
      API.query(method,uri,{},data, function(resp) {
        let newLocationCount = [...locationCounts]
        if(isSubEditMode) {
          let i = newLocationCount.findIndex(j => j.id == subEditValues.id)
          if(i > -1) {
            data['id'] = subEditValues.id
            newLocationCount[i] = data
          }
        }else {
          data['id'] = resp.id
          newLocationCount.push(data)
        }

        setLocationCounts(newLocationCount)

        if(addNewSub) {
          let procData = {
            'location_counts': appendLocationCount(editValues['location_counts'],resp.id)
          }
          let uri =  `/api/items/${editValues.id}/`
          API.query('PATCH',uri,{},procData, function(resp) {
            API.query('GET','/api/items/',{},{},function(response) {
              setItems(response)
            },onDataLoadError)
          },onDataLoadError)
        }
        setIsSaving(false)
        setIsSubEditMode(false)
        setShowSubAdd(false)
        setAddNewSub(false)
        setSubEditValues([])
      },onDataLoadError)
    }
  }

  const onSetupModalClosed = () => {
    setShowSubAdd(false)
    setShowSetupModal(false)
    props.updateID()
    setEditValues([])
    setIsEditMode(false)
    setIsSubMenu(true)
    API.query("GET",`/api/locationcounts/`,{},{}, function(resp) {
      setLocationCounts(resp)
    },onDataLoadError)

  }

  const onEditClicked = (row) => {
    row.status = row.status?"true":"false"
    row.type = `${row.type}`
    setEditValues(row)
    setShowSetupModal(true)
    props.updateID(row.id)
    setIsEditMode(true)
  }

  const onDeleteClicked = (row) => {
    setEditValues(row)
    setShowConfirmationModal(true)
  }

  const onConfirmDelete = () => {
    API.query("DELETE",`/api/items/${editValues.id}/`,{},{}, function(resp) {
      let newItems = items.filter(j => j.id !== editValues.id)
      setItems(newItems)
      setShowConfirmationModal(false)
      setEditValues([])
    },onDataLoadError)
  }

  const onConfirmSubDelete = () => {
    API.query("DELETE",`/api/locationcounts/${subEditValues.id}/`,{},{}, function(resp) {
      let newLocationCounts = locationCounts.filter(j => j.id !== subEditValues.id)
      setLocationCounts(newLocationCounts)
      setShowSubConfirmationModal(false)
      setSubEditValues([])
      API.query("GET",`/api/items/${editValues.id}/`,{},{}, function(resp) {
        setEditValues(resp)
      },onDataLoadError)
    },onDataLoadError)
  }

  const onButtonPressed = (id) => {
    if(id == "clear") {
      setFilterValues([])
    } else if (id == "create_new") {
      setShowSetupModal(true)
      setIsSubMenu(false)
    } else {
      
    }
  }

  const onSubEditClicked = (row) => {
    row.reorder_status = row.reorder_status?"true":"false"
    setSubEditValues(row)
    setShowSubAdd(true)
    setIsSubEditMode(true)
  }

  const onSubDeleteClicked = (row) => {
    setSubEditValues(row)
    setShowSubConfirmationModal(true)
  }

  const onSubAddNewClicked = () => {
    setShowSubAdd(true)
    setAddNewSub(true)
  }

  const onSubAddClosed = () => {
    setShowSubAdd(false)
    setSubEditValues([])
    setIsSubEditMode(false)
  }

  const filterByItself = (obj) => {
    var valid, i, abort=false
    if(filterValues.item && filterValues.item.length != 0) {
      for(i=0; i < filterValues.item.length && !abort; i++) {
        if(filterValues.item[i] == obj.id) {
          valid = true
          abort = true
        } else {
          valid = false
        }
      }
    } else {
      valid = true
    }

    return valid
  }

  const filterByCategory = (obj) => {
    var i, valid, abort=false
    if(filterValues.category && filterValues.category.length != 0) {
      for (i = 0; i < filterValues.category.length && !abort; i++) {
        if(filterValues.category[i] == obj.item_category) {
          valid = true
          abort = true
        } else {
          valid = false
        }
      }
    } else {
      valid = true
    }
    return valid
  }

  const filterByManufacturer = (obj) => {
    var i,valid,abort=false
    if(filterValues.manufacturer && filterValues.manufacturer.length != 0) {
      for(i=0; i<filterValues.manufacturer.length && !abort; i++) {
        if(filterValues.manufacturer[i] == obj.manufacturer) {
          valid=true
          abort=true
        } else {
          valid=false
        }
      }
    } else {
      valid=true
    }
    return valid
  }

  const filterByType = (obj) => {
    var i, valid = false, abort = false
    if(filterValues.type && filterValues.type.length != 0) {
      for(i = 0; i < filterValues.type.length && !abort; i++) {
        if(filterValues.type[i] == obj.item_type) {
          valid = true
          abort = true
        }
      }
    } else {
      valid = true
    }
    return valid
  }

  const filteredItems = items.filter((obj) => {
    let valid = false
    if (filterByManufacturer(obj)) {
      if (filterByItself(obj)) {
        if (filterByCategory(obj)) {
          if (filterByType(obj)) {
            valid = true
          }
        }
      }
    }
    return valid
  })

  const filteredLocations = locationCounts.filter((obj) => {
    let i, valid = false, abort = false
    if(editValues.location_counts && editValues.location_counts.length != 0) {
      for(i=0; i<editValues.location_counts.length && !abort; i++) {
        if(editValues.location_counts[i] == obj.id) {
          valid = true
          abort = true
        }
      }
    }
    return valid
  })

  const getCountTotal = () => {
    let total = 0, i
    let locations = filteredLocations
    for(i=0; i < locations.length; i++) {
      total += parseInt(locations[i].count)
    }
    return total
  }

  const countTotal = getCountTotal()

  const getOptionLabelByValue = (item, source, id) => {
    let option = source.find(o => o.value == id)
    return option ? option.label : null
  }

  const columns = useMemo(() => [
    {name:'Item Category',selector: row => getOptionLabelByValue(row,categoryOptions,row.item_category),sortable:true},
    {name:'Manufacturer',selector: row => getOptionLabelByValue(row,manufacturerOptions,row.manufacturer),sortable:true},
    {name:'Item Type',selector: row => getOptionLabelByValue(row,typeOptions,row.item_type),sortable:true},
    {name:'Cost',selector: row => row.price,sortable:true},
    {name:'Status',selector: row => row.status ? "Active" : "Inactive"},
    {name:'Actions',selector: row =><div>
    <a title="Edit" onClick={() => onEditClicked(row)}><FaPencilAlt size={14} /></a>
    <a className="ml-3" title="Delete" onClick={() => onDeleteClicked(row)}><FaTrashAlt size={14} /></a>
    </div>}
  ])

  const locationColumns = useMemo(() => [
    {name:'Facility', width:"320px", selector: row => getOptionLabelByValue(row,facilityOptions,row.facility), sortable:true},
    {name:'On Order',selector: row=>row.on_order ? "Yes" : "No", sortable:true},
    {name:'Par',selector: row=>row.par, sortable:true},
    {name:'Qty Per', selector: row=>row.count, sortable:true},
    {name:'Actions',selector: row =><div>
    <a title="Edit" onClick={() => onSubEditClicked(row)}><FaPencilAlt size={14} /></a>
    <a className="ml-3" title="Delete" onClick={() => onSubDeleteClicked(row)}><FaTrashAlt size={14} /></a>
    </div>}
  ])

  useEffect(() => {
    let queries = [
      { 'method': 'GET', 'path': '/api/items/', },
      { 'method': 'GET', 'path': '/api/manufacturers/', },
      { 'method': 'GET', 'path': '/api/itemcategories/', },
      { 'method': 'GET', 'path': '/api/itemtypes/', },
      { 'method': 'GET', 'path': '/api/facilities/', },
      { 'method': 'GET', 'path': '/api/locationcounts/', }
    ]
    API.all(queries, function (resp) {
      setItems(resp[0])
      setItemOptions(resp[0].map(i => ({ "label": i.catalog, "value": i.id })))
      setCategoryOptions(resp[2].map(i => ({ "label": i.category, "value": i.id })))
      setTypeOptions(resp[3].map(i => ({ "label": i.type, "value": i.id })))
      setManufacturerOptions(resp[1].map(i => ({ "label": i.name, "value": i.id})))
      setFacilityOptions(resp[4].map(i => ({"label": i.name, "value": i.id})))
      setLocationCounts(resp[5])
      if(props.id != null) {
        let option = resp[0].find((i) => i.id == props.id)
        option.status = option.status?"true":"false"
        setEditValues(option)
        setShowSetupModal(true)
        setIsEditMode(true)
      }
    }, onDataLoadError)
  }, []);

  const onDataLoadError = (error) => {
    setErrorMessage(error)
    setShowErrorModal(true)
    setIsSaving(false)
  }

  const visited = () => {
    // get pageviewhistory id from user session data
    var user = Session.get('user')
    API.query('GET',`/api/pageviewhistory/${user.page_view_history}/`,{},{},function(response) {
      let data = {
        "setup_item": response.setup_item + 1
      }
      API.query('PATCH',`/api/pageviewhistory/${user.page_view_history}/`,{},data,function(resp) {}, onDataLoadError)
    }, onDataLoadError)
  }

  return <div>  
    <p className="title">Item Setup</p>
    <hr />
    <DynamicForm
      form={itemSearch}
      values={filterValues}
      onButtonPressed={onButtonPressed}
      onValuesChanged={onFilterChanged}
      source={{ "manufacturers": manufacturerOptions, "categories": categoryOptions, "items": itemOptions, "types": typeOptions }}
    />
    <hr />

    {
      showSetupModal
      && 
      <ItemModal
        form={itemModal}
        editValues={editValues}
        onEditChange={onEditChange}
        source={{"manufacturers": manufacturerOptions, "item_categories": categoryOptions, "item_types": typeOptions, "facilities": facilityOptions}}
        isSaving={isSaving}
        onSave={onSave}
        onClose={onSetupModalClosed}

        subMenu={isSubMenu}
        subForm={itemSubMenu}
        showSubAdd={showSubAdd}
        subColumns={locationColumns}
        filteredSub={filteredLocations}
        subEditValues={subEditValues}
        onSubEditChange={onSubEditChange}
        onSubSave={onSubSave}
        onSubClose={onSubAddClosed}
        onButtonPressed={onButtonPressed}
        onSubAddNewClicked={onSubAddNewClicked}
        string={subMenuString}
        itemTotal={countTotal}
      />
    }

    {
      showErrorModal
      && 
      <ErrorModal
        message={errorMessage}
        onClose={() => setShowErrorModal(false)}
      />
    }

    {
      showConfirmationModal
      && 
      <ConfirmationModal
        message={"You are about to delete an Item. This cannot be undone."}
        onConfirm={onConfirmDelete}
        onClose={() => setShowConfirmationModal(false)}
      />
    }

    {
      showSubConfirmationModal
      &&
      <ConfirmationModal
        message={"You are about to delete a Location Count. This cannot be undone."}
        onConfirm={onConfirmSubDelete}
        onClose={() => setShowSubConfirmationModal(false)}
      />
    }

    <p className="title is-5">Results</p>
    <DataTable
      striped
      pagination
      data={filteredItems}
      columns={columns}
    />

    <VisitTimer visited={visited}/>

  </div>
}

export default ItemSetup