import React, { useEffect, useState } from "react";
import chartingTemp from  "../forms/chartingTemp";
import DynamicForm from "./DynamicForm";

import ChartingDateTime from "./ChartingDateTime";

function TemperatureModal(props) {
  const [data,setData] = useState({...props.data})
  const [time,setTime] = useState(props.time?new Date(props.time.getTime()):new Date())
  const [missingFields,setMissingFields] = useState()

  const onSave = () => {
    if(data['warm-temp'] || data['cool-temp'] || data['drift']) {
      let saveData = {...data}
      if(props.data) saveData.id = props.data.id
      props.onSave(saveData,time)
    }else {
      setMissingFields(['warm-temp','cool-temp','drift'])
    }
  }

  const onCancel = () => {
    props.onClose()
  }

  let saveBtnClasses = "level-item button is-success";
  if (props.isSaving) saveBtnClasses += " is-loading"

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head has-background-white is-block">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="is-size-4">Enter Temp Info</p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <ChartingDateTime 
                value={time}
                onChange={setTime}
              />
            </div>
          </div>
        </div>
      </header>
      <section className="modal-card-body">
        <DynamicForm 
          form={chartingTemp}
          values={data}
          onValuesChanged={setData}
          missingFields={missingFields}
        />
      </section>

      <footer className="modal-card-foot has-background-white">
        <div className="level w-100">
          <div className="level-left">
            <button className="level-item button is-danger is-inverted" onClick={onCancel}>Cancel</button>
          </div>
          <div className="level-right">
            <button className={saveBtnClasses} onClick={onSave}>Save</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
}

export default TemperatureModal