import React, { useEffect, useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import CaseReportModal from "../../../components/CaseReportModal";
import ReportChart from "../../../components/ReportChart";
import API from "../../../../services/API";
import {
  FaCaretUp,
  FaCaretDown,
  FaFileExcel,
  FaRegFilePdf,
} from "react-icons/fa";
import { CSVLink, CSVDownload } from "react-csv";
import VisitTimer from "../../../components/VisitTimer";
import Session from "../../../../services/Session";
import Select from "react-select";
import DatePicker from "react-datepicker";
import PdfSurgeonReport from "../../../components/PdfSurgeonReport";
import ProcedureBillingReport from "../../../components/ProcedureBillingReport";
import PdfAutotransfusionAnalyticsReport from "../../../components/PdfAutotransfusionAnalyticsReport";
import PdfProcedureReport from "../../../components/PdfProcedureReport";
import PdfPatientProfile from "../../../components/PdfPatientProfile";
import PdfCPBAnalytics from "../../../components/PdfCPBAnalytics";
import PdfPerfusionStandbyAnalytics from "../../../components/PdfPerfusionStandbyAnalytics";
import PdfContinuousQualityImprovement from "../../../components/PdfContinuousQualityImprovement";
import PdfQCBundle from "../../../components/PdfQCBundle";
import PdfBloodProductSummary from "../../../components/PdfBloodProductSummary";
import PdfBloodProductDetails from "../../../components/PdfBloodProductDetails";
import PdfNonCompliance from "../../../components/PdfNonCompliance";
import PdfStudyData from "../../../components/PdfStudyData";
import PdfDisposables from "../../../components/PdfDisposables";
import PdfABCPReport from "../../../components/PdfABCPReport";

function CaseReport(props) {
  const addDays = (date, days) => {
    date = new Date(date); // copy date
    date.setDate(date.getDate() + days);
    return date;
  };

  const [showABCP, setShowABCP] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [cases, setCases] = useState([]);
  const [reportData, setReportData] = useState({});
  const [reportTitleString, setReportTitleString] = useState("");
  const [viewReportModal, setViewReportModal] = useState(false);
  const [chartFilters, setChartFilters] = useState({
    time: 0,
    chart: 0,
    start_date: addDays(new Date(), -30),
    end_date: new Date(),
  });
  const [trendFilters, setTrendFilters] = useState({ time: 0 });
  const [procedures, setProcedures] = useState([]);
  const [personnelRoles, setPersonnelRoles] = useState([]);
  const [showSurgeonReport, setShowSurgeonReport] = useState(false);
  const [categories, setCategories] = useState([]);
  const [showProcedureBillingReport, setShowProcedureBillingReport] =
    useState(false);
  const [
    showAutotransfusionAnalyticsReport,
    setShowAutotransfusionAnalyticsReport,
  ] = useState(false);
  const [showProcedureReport, setShowProcedureReport] = useState(false);
  const [showPatientProfile, setShowPatientProfile] = useState(false);
  const [showCPBAnalytics, setShowCPBAnalytics] = useState(false);
  const [showPerfusionStandbyAnalytics, setShowPerfusionStandbyAnalytics] =
    useState(false);
  const [showBloodProductUsageSummary, setShowBloodProductUsageSummary] =
    useState(false);
  const [showBloodProductUsageDetail, setShowBloodProductUsageDetail] =
    useState(false);
  const [
    showContinuousQualityImprovement,
    setShowContinuousQualityImprovement,
  ] = useState(false);
  const [
    showNonComplianceContinuousQualityImprovement,
    setShowNonComplianceContinousQualityImprovement,
  ] = useState(false);
  const [showStudyDataStatistics, setShowStudyDataStatistics] = useState(false);
  const [showDisposables, setShowDisposables] = useState(false);
  const [showQualityControlBundle, setShowQualityControlBundle] =
    useState(false);

  const reports = [
    {
      id: 3,
      name: "Autotransfusion Analytics Report",
      description: "Autotransfusion Analytics Report",
      pdf: true,
    },
    {
      id: 9,
      name: "Blood Product Usage Detail",
      description: "Blood Product Usage Detail",
      pdf: true,
    },
    {
      id: 8,
      name: "Blood Product Usage Summary",
      description: "Blood Product Usage Summary",
      pdf: true,
    },
    {
      id: 10,
      name: "Continuous Quality Improvement",
      description: "Continuous Quality Improvement",
      pdf: true,
    },
    {
      id: 6,
      name: "CPB Analytics Report",
      description: "CPB Analytics",
      pdf: true,
    },
    {
      id: 13,
      name: "Disposables Report",
      description: "Disposables Report",
      pdf: true,
    },
    {
      id: 11,
      name: "Non-Compliance Report Continuous Quality Improvement",
      description: "Non-Compliance Report Continuous Quality Improvement",
      pdf: true,
    },
    {
      id: 5,
      name: "Patient Profile",
      description: "Patient Profile",
      pdf: true,
    },
    {
      id: 7,
      name: "Perfusion Standby Analytics Report",
      description: "Perfusion Standby Analytics",
      pdf: true,
    },
    {
      id: 1,
      name: "Procedure Billing Report With Hours",
      description: "Procedure Billing Report With Hours",
      csv: true,
    },
    {
      id: 4,
      name: "Procedure Report",
      description: "Procedure Report",
      pdf: true,
    },
    {
      id: 14,
      name: "Quality Control Bundle",
      description: "Quality Control Bundle",
      pdf: true,
    },
    {
      id: 12,
      name: "Study Data Statistics Report",
      description: "Study Data Statistics Report",
      pdf: true,
    },
    {
      id: 2,
      name: "Surgeon Report",
      description: "Surgeon Report",
      pdf: true,
    },
    {
      id: 15,
      name: "ABCP Report",
      description: "ABCP Report",
      pdf: true,
    },
  ];

  const filteredChartCases = cases.filter((obj) => {
    var valid = false;
    let date = new Date();
    let caseDate = new Date(obj.date);
    if (obj.status == "Submitted") {
      if (chartFilters.time == 0) {
        //year-to-date
        if (chartFilters.facility == null) {
          if (date.getFullYear() == caseDate.getFullYear()) valid = true;
        } else {
          if (chartFilters.facility == obj.facility_id) {
            if (date.getFullYear() == caseDate.getFullYear()) valid = true;
          }
        }
      } else if (chartFilters.time == 1) {
        //month-to-date
        if (chartFilters.facility == null) {
          if (date.getMonth() == caseDate.getMonth()) {
            if (date.getFullYear() == caseDate.getFullYear()) valid = true;
          }
        } else {
          if (chartFilters.facility == obj.facility_id) {
            if (date.getMonth() == caseDate.getMonth()) {
              if (date.getFullYear() == caseDate.getFullYear()) valid = true;
            }
          }
        }
      } else if (chartFilters.time == 2) {
        //last month
        if (chartFilters.facility == null) {
          if (date.getMonth() == 0) {
            let lastMonth = 11;
            let lastYear = date.getFullYear() - 1;
            if (lastMonth == caseDate.getMonth()) {
              if (lastYear == caseDate.getFullYear()) valid = true;
            }
          } else {
            let lastMonth = date.getMonth() - 1;
            if (lastMonth == caseDate.getMonth()) {
              if (date.getFullYear() == caseDate.getFullYear()) valid = true;
            }
          }
        } else {
          if (chartFilters.facility == obj.facility_id) {
            if (date.getMonth() == 0) {
              let lastMonth = 11;
              let lastYear = date.getFullYear() - 1;
              if (lastMonth == caseDate.getMonth()) {
                if (lastYear == caseDate.getFullYear()) valid = true;
              }
            } else {
              let lastMonth = date.getMonth() - 1;
              if (lastMonth == caseDate.getMonth()) {
                if (date.getFullYear() == caseDate.getFullYear()) {
                  valid = true;
                }
              }
            }
          }
        }
      } else if (chartFilters.time == 3) {
        //custom range
        if (chartFilters.facility == null) {
          let startDate = addDays(chartFilters.start_date, -1);
          let endDate = new Date(chartFilters.end_date);
          if (caseDate >= startDate) {
            if (caseDate <= endDate) valid = true;
          }
        } else {
          if (chartFilters.facility == obj.facility_id) {
            let startDate = new Date(chartFilters.start_date);
            let endDate = new Date(chartFilters.end_date);
            if (caseDate >= startDate) {
              if (caseDate <= endDate) valid = true;
            }
          }
        }
      }
    }
    return valid;
  });

  const filteredTrendCases = cases.filter((obj) => {
    let valid = false;
    let caseDate = new Date(obj.date);
    if (obj.status == "Submitted") {
      if (trendFilters.time == 0) {
        //30 day
        if (trendFilters.facility == null) {
          let start_date = addDays(new Date(), -30);
          let end_date = new Date();
          if (caseDate >= start_date) {
            if (caseDate <= end_date) valid = true;
          }
        } else {
          if (trendFilters.facility == obj.facility_id) {
            let start_date = addDays(new Date(), -30);
            let end_date = new Date();
            if (caseDate >= start_date) {
              if (caseDate <= end_date) valid = true;
            }
          }
        }
      } else if (trendFilters.time == 1) {
        //90 day
        if (trendFilters.facility == null) {
          let start_date = addDays(new Date(), -90);
          let end_date = new Date();
          if (caseDate >= start_date) {
            if (caseDate <= end_date) valid = true;
          }
        } else {
          if (trendFilters.facility == obj.facility_id) {
            let start_date = addDays(new Date(), -90);
            let end_date = new Date();
            if (caseDate >= start_date) {
              if (caseDate <= end_date) valid = true;
            }
          }
        }
      }
    }

    return valid;
  });

  const filteredPastTrendCases = cases.filter((obj) => {
    let valid = false;
    let caseDate = new Date(obj.date);
    if (obj.status == "Submitted") {
      if (trendFilters.time == 0) {
        //30 day
        if (trendFilters.facility == null) {
          let start_date = addDays(new Date(), -60);
          let end_date = addDays(new Date(), -30);
          if (caseDate >= start_date) {
            if (caseDate <= end_date) valid = true;
          }
        } else {
          if (trendFilters.facility == obj.facility_id) {
            let start_date = addDays(new Date(), -60);
            let end_date = addDays(new Date(), -30);
            if (caseDate >= start_date) {
              if (caseDate <= end_date) valid = true;
            }
          }
        }
      } else if (trendFilters.time == 1) {
        //90 day
        if (trendFilters.facility == null) {
          let start_date = addDays(new Date(), -180);
          let end_date = addDays(new Date(), -90);
          if (caseDate >= start_date) {
            if (caseDate <= end_date) valid = true;
          }
        } else {
          if (trendFilters.facility == obj.facility_id) {
            let start_date = addDays(new Date(), -180);
            let end_date = addDays(new Date(), -90);
            if (caseDate >= start_date) {
              if (caseDate <= end_date) valid = true;
            }
          }
        }
      }
    }

    return valid;
  });

  const chartOptions = [
    { label: "Procedure Counts", value: 0 },
    { label: "Cases Submitted", value: 1 },
    { label: "Avg Procedures per Case", value: 2 },
    { label: "Quality Control - Cases", value: 3 },
  ];

  const trendTimeOptions = [
    { label: "Last 30 Days", value: 0 },
    { label: "Last 90 Days", value: 1 },
  ];

  const chartTimeOptions = [
    { label: "Year-To-Date", value: 0 },
    { label: "Month-To-Date", value: 1 },
    { label: "Last Month", value: 2 },
    { label: "Custom", value: 3 },
  ];

  const onChartFilterChanged = (value, type) => {
    let fValues = { ...chartFilters };
    if (type === 0) {
      fValues["chart"] = value;
    } else if (type === 1) {
      fValues["time"] = value;
    } else if (type === 2) {
      fValues["facility"] = value;
    } else if (type === 3) {
      fValues["start_date"] = value;
    } else if (type === 4) {
      fValues["end_date"] = value;
    }
    setChartFilters(fValues);
  };

  const onTrendFilterChanged = (value, type) => {
    let fValues = { ...trendFilters };
    if (type === 0) {
      fValues["time"] = value;
    } else if (type === 1) {
      fValues["facility"] = value;
    }
    setTrendFilters(fValues);
  };

  const getCasesSubmittedChange = () => {
    let newCount = filteredTrendCases.length;
    let pastCount = filteredPastTrendCases.length;
    return (newCount - pastCount) / newCount;
  };

  const getProcedures = () => {
    let totalProcedures = 0,
      i;
    for (i = 0; i < filteredTrendCases.length; i++) {
      totalProcedures += filteredTrendCases[i].procedure_id.length;
    }
    return totalProcedures;
  };

  const getProceduresChange = () => {
    let newTotal = 0,
      pastTotal = 0,
      i,
      x;
    for (i = 0; i < filteredTrendCases.length; i++) {
      newTotal += filteredTrendCases[i].procedure_id.length;
    }
    for (x = 0; x < filteredPastTrendCases.length; x++) {
      pastTotal += filteredPastTrendCases[x].procedure_id.length;
    }
    return (newTotal - pastTotal) / newTotal;
  };

  const renderChange = (data, change, upIsGood, isCentered = true) => {
    if (change !== null) change = Math.round(change * 100);
    const isUp = change > 0 || (change === null && !!data);
    const isGood = (isUp && upIsGood) || (!isUp && !upIsGood);

    return (
      <p
        className={
          "has-text-weight-light has-text-grey" +
          (isCentered ? " has-text-centered" : "")
        }
        style={{ whiteSpace: "nowrap" }}
      >
        {change === 0 ? (
          <>--</>
        ) : (
          <>
            <span
              key={isUp ? 1 : 2}
              style={
                isGood
                  ? { marginLeft: "10px", color: "green" }
                  : { marginLeft: "10px", color: "red" }
              }
            >
              {change === null ? (
                <Fragment>&#8734;</Fragment>
              ) : (
                Math.abs(change)
              )}
              %
              {isUp ? (
                <FaCaretUp
                  style={{ paddingTop: 10, marginRight: 5, color: "green" }}
                  size={36}
                />
              ) : (
                <FaCaretDown
                  style={{ paddingTop: 10, marginRight: 5, color: "red" }}
                  size={36}
                />
              )}
            </span>
          </>
        )}
      </p>
    );
  };

  const renderTrends = () => {
    return (
      <div>
        <div className="box">
          <div className="level w-100">
            <div className="level-left">
              <p className="title is-5">Trends</p>
            </div>
            <div className="level-right">
              <p style={{ marginRight: "12px" }}>Filter Trends:</p>
              <div style={{ marginRight: "12px", minWidth: "220px" }}>
                <Select
                  options={trendTimeOptions}
                  placeholder={""}
                  value={trendTimeOptions.find(
                    (e) => e.value == trendFilters.time
                  )}
                  onChange={(option) => onTrendFilterChanged(option.value, 0)}
                />
              </div>
              <div style={{ minWidth: "220px" }}>
                <Select
                  options={facilityOptions}
                  placeholder={"All Facilities"}
                  value={facilityOptions.find(
                    (e) => e.value == trendFilters.facility
                  )}
                  onChange={(option) => onTrendFilterChanged(option.value, 1)}
                />
              </div>
            </div>
          </div>
          <div className="columns is-centered w-100">
            <div className="column"></div>
            <div className="column is-narrow">
              <p className="title is-5" style={{ marginBottom: 0 }}>
                Cases Submitted
              </p>
              <div className="is-flex" style={{ height: "50px" }}>
                <div style={{ lineHeight: "25px", verticalAlign: "middle" }}>
                  {renderChange(
                    filteredTrendCases.length,
                    getCasesSubmittedChange(),
                    true
                  )}
                </div>

                <p
                  className="title is-1"
                  style={{
                    color: "#FFAC1C",
                    whiteSpace: "nowrap",
                    marginBottom: 0,
                  }}
                >
                  {filteredTrendCases.length}
                </p>
              </div>
            </div>
            <div className="column is-narrow">
              <p className="title is-5" style={{ marginBottom: 0 }}>
                Procedures
              </p>
              <div className="is-flex">
                {renderChange(getProcedures(), getProceduresChange(), true)}
                <p
                  className="title is-1"
                  style={{
                    color: "#FFAC1C",
                    whiteSpace: "nowrap",
                    marginBottom: 0,
                  }}
                >
                  {getProcedures()}
                </p>
              </div>
            </div>
            <div className="column"></div>
          </div>
        </div>
      </div>
    );
  };

  const renderCharts = () => {
    return (
      <div>
        <div className="box">
          <div className="level w-100">
            <div className="level-left">
              <div style={{ minWidth: "240px" }}>
                <Select
                  options={chartOptions}
                  placeholder={""}
                  value={chartOptions.find(
                    (e) => e.value == chartFilters.chart
                  )}
                  onChange={(option) => onChartFilterChanged(option.value, 0)}
                />
              </div>
            </div>
            <div className="level-right">
              <p style={{ marginRight: "12px" }}>Filter Reports:</p>
              <div style={{ marginRight: "12px", minWidth: "220px" }}>
                <Select
                  options={chartTimeOptions}
                  placeholder={""}
                  value={chartTimeOptions.find(
                    (e) => e.value == chartFilters.time
                  )}
                  onChange={(option) => onChartFilterChanged(option.value, 1)}
                />
              </div>
              <div style={{ minWidth: "220px" }}>
                <Select
                  options={facilityOptions}
                  placeholder={"All Facilities"}
                  value={facilityOptions.find(
                    (e) => e.value == chartFilters.facility
                  )}
                  onChange={(option) => onChartFilterChanged(option.value, 2)}
                />
              </div>
            </div>
          </div>

          {chartFilters.time == 3 && (
            <div>
              <div className="level w-100">
                <div className="level-left"></div>
                <div className="level-right">
                  <div style={{ marginRight: "12px", minWidth: "220px" }}>
                    <DatePicker
                      className="input"
                      selectsStart
                      startDate={chartFilters.start_date}
                      endDate={chartFilters.end_date}
                      selected={chartFilters.start_date}
                      onChange={(date) => onChartFilterChanged(date, 3)}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      maxDate={new Date()}
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                  <div style={{ minWidth: "220px" }}>
                    <DatePicker
                      className="input"
                      selectsStart
                      startDate={chartFilters.start_date}
                      endDate={chartFilters.end_date}
                      selected={chartFilters.end_date}
                      onChange={(date) => onChartFilterChanged(date, 4)}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      maxDate={new Date()}
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <ReportChart
            height={350}
            procedures={procedures}
            filters={chartFilters}
            data={filteredChartCases}
          />
        </div>
      </div>
    );
  };

  const columns = useMemo(() => [
    { name: "Name of Report", selector: (row) => row.name, sortable: true },
    { name: "Description", selector: (row) => row.description, sortable: true },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          {row.csv && (
            <a
              className="ml-3"
              title="CSV Download"
              onClick={() => {
                onViewClicked(row);
              }}
            >
              <FaFileExcel size={18} title="CSV Download" />
            </a>
          )}
          {row.pdf && (
            <a
              className="ml-3"
              title="PDF Download"
              onClick={() => onViewClicked(row)}
            >
              <FaRegFilePdf size={18} />
            </a>
          )}
        </div>
      ),
    },
  ]);

  const renderReports = () => {
    return (
      <div className="box">
        <DataTable striped data={reports} columns={columns} />
      </div>
    );
  };

  useEffect(() => {
    let queries = [
      { method: "GET", path: "/api/jobs/" },
      { method: "GET", path: "/api/facilities/" },
      { method: "GET", path: "/api/procedures/" },
      // { method: "GET", path: "/api/casepersonnelroles/" },
      // { method: "GET", path: "/api/itemcategories/" },
      // { method: "GET", path: "/api/visualcasereports/" },
    ];
    API.all(
      queries,
      function (resp) {
        if (
          props.permissions.includes(17) ||
          props.extraPermissions.includes(17)
        ) {
          let user = Session.get("user");
          let filteredFacils = resp[1].filter((obj) => {
            var i,
              abort = false,
              valid = false;
            for (i = 0; i < user.facilities.length && !abort; i++) {
              if (user.facilities[i] == obj.id) {
                valid = true;
                abort = true;
              }
            }
            return valid;
          });

          // let filteredCases = resp[5].filter((obj) => {
          //   var i, abort=false, valid=false
          //   for(i=0; i < user.facilities.length && !abort; i++) {
          //     if(obj.facility_id == user.facilities[i]) {
          //       valid = true
          //       abort = true
          //     }
          //   }
          //   return valid
          // })

          // setCases(filteredCases)
          setFacilityOptions(
            filteredFacils.map((i) => ({ label: i.name, value: i.id }))
          );
        } else {
          setFacilityOptions(
            resp[1].map((i) => ({ label: i.name, value: i.id }))
          );
          // setCases(resp[5])
        }
        setRoleOptions(resp[0].map((i) => ({ label: i.name, value: i.id })));
        setProcedures(resp[2]);
        //setPersonnelRoles(resp[3]);
        //setCategories(resp[4]);
      },
      onDataLoadError
    );
  }, []);

  const onDataLoadError = (error) => {
    setErrorMessage(error);
    setShowErrorModal(true);
    setIsSaving(false);
  };

  const onViewClicked = (row) => {
    if (row.id == 1) {
      setShowProcedureBillingReport(true);
    } else if (row.id == 2) {
      setShowSurgeonReport(true);
    } else if (row.id == 3) {
      setShowAutotransfusionAnalyticsReport(true);
    } else if (row.id == 4) {
      setShowProcedureReport(true);
    } else if (row.id == 5) {
      setShowPatientProfile(true);
    } else if (row.id == 6) {
      setShowCPBAnalytics(true);
    } else if (row.id == 7) {
      setShowPerfusionStandbyAnalytics(true);
    } else if (row.id == 8) {
      setShowBloodProductUsageSummary(true);
    } else if (row.id == 9) {
      setShowBloodProductUsageDetail(true);
    } else if (row.id == 10) {
      setShowContinuousQualityImprovement(true);
    } else if (row.id == 11) {
      setShowNonComplianceContinousQualityImprovement(true);
    } else if (row.id == 12) {
      setShowStudyDataStatistics(true);
    } else if (row.id == 13) {
      setShowDisposables(true);
    } else if (row.id == 14) {
      setShowQualityControlBundle(true);
    } else if (row.id == 15) {
      setShowABCP(true);
    }
  };

  const onReportModalClosed = () => {
    setViewReportModal(false);
    setReportData([]);
    setReportTitleString("");
  };

  const procedureBillingColumns = useMemo(() => [
    { name: "#", selector: (row) => row.number, sortable: true },
    { name: "Type", selector: (row) => row.type, sortable: true },
    { name: "Date", selector: (row) => row.date, sortable: true },
    { name: "MR#", selector: (row) => row.medical_record, sortable: true },
    {
      name: "Patient Name",
      selector: (row) => row.patient_name,
      sortable: true,
    },
    { name: "Surgeon", selector: (row) => row.surgeon, sortable: true },
    { name: "User", selector: (row) => row.user, sortable: true },
    { name: "Operation", selector: (row) => row.operation, sortable: true },
  ]);

  const visited = () => {
    // get pageviewhistory id from user session data
    var user = Session.get("user");
    API.query(
      "GET",
      `/api/pageviewhistory/${user.page_view_history}/`,
      {},
      {},
      function (response) {
        let data = {
          report_case: response.report_case + 1,
        };
        API.query(
          "PATCH",
          `/api/pageviewhistory/${user.page_view_history}/`,
          {},
          data,
          function (resp) {},
          onDataLoadError
        );
      },
      onDataLoadError
    );
  };

  return (
    <div>
      {/* <p className="title is-3">Case Trends & Reports</p> */}
      {/* {renderTrends()} */}
      {/* <br /> */}
      {/* <p className="title is-5">Reports</p> */}
      <p className="title is-">Case Reports</p>

      {/* {renderCharts()} */}
      <br />
      {renderReports()}

      {viewReportModal && (
        <CaseReportModal
          data={reportData}
          columns={procedureBillingColumns}
          onClose={onReportModalClosed}
          titleString={reportTitleString}
        />
      )}

      {showSurgeonReport && (
        <PdfSurgeonReport
          facilities={facilityOptions}
          onClose={() => setShowSurgeonReport(false)}
        />
      )}

      {showProcedureBillingReport && (
        <ProcedureBillingReport
          facilities={facilityOptions}
          onClose={() => setShowProcedureBillingReport(false)}
        />
      )}

      {showAutotransfusionAnalyticsReport && (
        <PdfAutotransfusionAnalyticsReport
          facilities={facilityOptions}
          onClose={() => setShowAutotransfusionAnalyticsReport(false)}
        />
      )}

      {showProcedureReport && (
        <PdfProcedureReport
          facilities={facilityOptions}
          onClose={() => setShowProcedureReport(false)}
        />
      )}

      {showPatientProfile && (
        <PdfPatientProfile
          facilities={facilityOptions}
          onClose={() => setShowPatientProfile(false)}
        />
      )}

      {showCPBAnalytics && (
        <PdfCPBAnalytics
          facilities={facilityOptions}
          onClose={() => setShowCPBAnalytics(false)}
        />
      )}

      {showPerfusionStandbyAnalytics && (
        <PdfPerfusionStandbyAnalytics
          facilities={facilityOptions}
          onClose={() => setShowPerfusionStandbyAnalytics(false)}
        />
      )}

      {showBloodProductUsageSummary && (
        <PdfBloodProductSummary
          facilities={facilityOptions}
          onClose={() => setShowBloodProductUsageSummary(false)}
        />
      )}

      {showBloodProductUsageDetail && (
        <PdfBloodProductDetails
          facilities={facilityOptions}
          onClose={() => setShowBloodProductUsageDetail(false)}
        />
      )}

      {showContinuousQualityImprovement && (
        <PdfContinuousQualityImprovement
          facilities={facilityOptions}
          onClose={() => setShowContinuousQualityImprovement(false)}
        />
      )}

      {showNonComplianceContinuousQualityImprovement && (
        <PdfNonCompliance
          facilities={facilityOptions}
          onClose={() => setShowNonComplianceContinousQualityImprovement(false)}
        />
      )}

      {showStudyDataStatistics && (
        <PdfStudyData
          facilities={facilityOptions}
          onClose={() => setShowStudyDataStatistics(false)}
        />
      )}

      {showDisposables && (
        <PdfDisposables
          facilities={facilityOptions}
          onClose={() => setShowDisposables(false)}
        />
      )}

      {showQualityControlBundle && (
        <PdfQCBundle
          facilities={facilityOptions}
          onClose={() => setShowQualityControlBundle(false)}
        />
      )}

      {
        showABCP && 
        <PdfABCPReport
          facilities={facilityOptions}
          onClose={() => setShowABCP(false)}
        />
      }

      <VisitTimer visited={visited} />
    </div>
  );
}

export default CaseReport;
