import React, { useEffect, useState } from "react";
import useSearchParamState from "../../hooks/useSearchParamState";
import BusinessReport from "./tabs/BusinessReport";
import CaseReport from "./tabs/CaseReport";
import InventoryReport from "./tabs/InventoryReport";
import PlatformReport from "./tabs/PlatformReport";
import HemReport from "./tabs/HemReport"

const BUSINESS_TAB = 1
const CASE_TAB = 2
const INVENTORY_TAB = 3
const PLATFORM_TAB = 4
const HEMASOURCE_TAB = 5

const TABS = [
  // {id:BUSINESS_TAB,name:'BusinessReport',component:BusinessReport},
  {id:CASE_TAB,name:'CaseReport',component:CaseReport},
  {id:INVENTORY_TAB,name:'InventoryReport',component:InventoryReport},
  // {id:PLATFORM_TAB,name:'PlatformReport',component:PlatformReport},
  {id:HEMASOURCE_TAB,name:'HemasourceReporrt',component:HemReport}
]

function Reports(props){
  const [activeTab,setActiveTab] = useSearchParamState('tab',CASE_TAB,parseInt)

  useEffect(() => {
    props.setMenuItems([
      {title:"Cases",callback:() => setActiveTab(CASE_TAB)},
      {title:"Inventory",callback:() => setActiveTab(INVENTORY_TAB)},
      // {title:"Business Data",callback:() => setActiveTab(BUSINESS_TAB)},
      // {title:"Platform Usage",callback:() => setActiveTab(PLATFORM_TAB)},
      {title:"Hemasource",callback:() => setActiveTab(HEMASOURCE_TAB)}
    ])
    
    return props.clearMenuItems
  },[])
  
  const Tab = TABS.find(tab => tab.id === activeTab)
  const TabComponent = Tab.component

  console.log("Current Tab: ",Tab.name)

  return <div>
    <TabComponent 
      permissions={props.permissions}
      extraPermissions={props.extraPermissions}
    />
  </div>
}

export default Reports;