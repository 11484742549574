import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

const propsAreEqual = (prevProps,nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps)
}

const DataTableMemo = React.memo((props) => {
  return <DataTable {...props} />
},propsAreEqual);

export default DataTableMemo;