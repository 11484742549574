import React, { useEffect, useState, useMemo, useRef } from "react";
import API from "../../../../services/API";
import ErrorModal from "../../../components/ErrorModal";
import ProcedureBox from "../../../components/ProcedureBox";

import Hem from "../../../components/Hem"
import DetailAuto from "../../../components/DetailAuto"
import DetailCPB from "../../../components/DetailCPB"
import DetailHIPC from "../../../components/DetailHIPC"
import DetailECLS from "../../../components/DetailECLS"
import DetailPlatelet from "../../../components/DetailPlatelet"
import DetailPerfusion from "../../../components/DetailPerfusion"
import DetailPOC from "../../../components/DetailPOC";
import Transport from "../../../components/DetailTransport";

function Details(props) {
  const [hem, setHem] = useState()
  const [cpbDetail, setCPBDetail] = useState()
  const [autoDetail, setAutoDetail] = useState()
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [activeTab, setActiveTab] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [hipecDetail, setHipecDetail] = useState()
  const [eclsDetail, setEclsDetail] = useState()
  const [plateletDetail, setPlateletDetail] = useState()
  const [pocLabDetail, setPocLabDetail] = useState()
  const [perfusionDetail, setPerfusionDetail] = useState()
  const [transport, setTransport] = useState()

  useEffect(() => {
    API.query('GET', `/api/cases/${props.activeCase.id}/`, {}, {}, function (response) {
      var Case = response
      props.updateActiveCase({...Case});
      props.activeCase.procedure.map((proc) => {
        if (proc.id == 3) {
          if (Case.hipec_detail != null) {
            setHipecDetail(Case.hipec_detail)
          } else {
            API.query("POST", `/api/hipecdetails/`, {}, {}, function (resp) {
              setHipecDetail(resp.id)
              props.updateActiveCase({
                'hipec_detail': resp.id
              })
              let data = {
                'hipec_detail': resp.id
              }
              API.query("PATCH", `/api/cases/${Case.id}/`, {}, data, function (resp) { }, onDataLoadError)
            }, onDataLoadError)
          }
        } else if (proc.id == 2) {
          if (Case.auto_detail != null) {
            setAutoDetail(Case.auto_detail)
          } else {
            API.query('POST', `/api/autodetails/`, {}, {}, function (resp) {
              setAutoDetail(resp.id)
              props.updateActiveCase({
                'auto_detail': resp.id
              })
              let data = {
                'auto_detail': resp.id
              }
              API.query('PATCH', `/api/cases/${Case.id}/`, {}, data, function (resp) { }, onDataLoadError)
            }, onDataLoadError)
          }
        } else if (proc.id == 1) {
          if (Case.cpb_detail != null) {
            setCPBDetail(Case.cpb_detail)
          } else {
            API.query("POST", `/api/cpbdetails/`, {}, {}, function (resp) {
              setCPBDetail(resp.id)
              props.updateActiveCase({
                'cpb_detail': resp.id
              })
              let data = {
                'cpb_detail': resp.id
              }
              API.query("PATCH", `/api/cases/${Case.id}/`, {}, data, function (resp) { }, onDataLoadError)
            }, onDataLoadError)
          }
        } else if (proc.id == 4) {
          if (Case.ecls_detail != null) {
            setEclsDetail(Case.ecls_detail)
          } else {
            API.query('POST', `/api/eclsdetails/`, {}, {}, function (resp) {
              setEclsDetail(resp.id)
              props.updateActiveCase({
                'ecls_detail': resp.id
              })
              let data = {
                'ecls_detail': resp.id
              }
              API.query('PATCH', `/api/cases/${Case.id}/`, {}, data, function (resp) { }, onDataLoadError)
            }, onDataLoadError)
          }
        } else if (proc.id == 5) {
          if (Case.platelet_detail != null) {
            setPlateletDetail(Case.platelet_detail)
          } else {
            API.query('POST', `/api/plateletdetails/`, {}, {}, function (resp) {
              setPlateletDetail(resp.id)
              props.updateActiveCase({
                'platelet_detail': resp.id
              })
              let data = {
                'platelet_detail': resp.id
              }
              API.query('PATCH', `/api/cases/${Case.id}/`, {}, data, function (resp) { }, onDataLoadError)
            }, onDataLoadError)
          }
        } else if (proc.id == 6) {
          if (Case.poc_lab_detail != null) {
            setPocLabDetail(Case.poc_lab_detail)
          } else {
            API.query('POST', `/api/poclabdetails/`, {}, {}, function (resp) {
              setPocLabDetail(resp.id)
              props.updateActiveCase({
                'poc_lab_detail': resp.id
              })
              let data = {
                'poc_lab_detail': resp.id
              }
              API.query('PATCH', `/api/cases/${Case.id}/`, {}, data, function (resp) { }, onDataLoadError)
            }, onDataLoadError)
          }
        } else if (proc.id == 7) {
          if (Case.perfusion_detail != null) {
            setPerfusionDetail(Case.perfusion_detail)
          } else {
            API.query('POST', `/api/perfusiondetails/`, {}, {}, function (resp) {
              setPerfusionDetail(resp.id)
              props.updateActiveCase({
                'perfusion_detail': resp.id
              })
              let data = {
                perfusion_detail: resp.id
              }
              API.query('PATCH', `/api/cases/${Case.id}/`, {}, data, function (resp) { }, onDataLoadError)
            }, onDataLoadError)
          }
        } else if (proc.id == 8) {
          if (Case.hem_detail == null) {
            API.query('POST', `/api/hemdetails/`, {}, {}, function (resp) {
              var id = resp.id
              props.updateActiveCase({
                'hem_detail': id
              })
              let data = {
                'hem_detail': id
              }
              API.query('PATCH', `/api/cases/${props.activeCase.id}/`, {}, data, function (resp) {
                setHem(id)
              }, () => props.setErrorMessage('Failed to attach detail to case.'))
            }, () => props.setErrorMessage('Failed to create Hem detail.'))
          } else {
            setHem(Case.hem_detail)
          }
        } else if (proc.id == 9) {
          if(Case.transport_detail == null) {
            API.query('POST',`/api/transportdetails/`,{},{},function(resp) {
              var id = resp.id
              props.updateActiveCase({
                'transport_detail': id
              })
              let data = {
                'transport_detail': id
              }
              API.query('PATCH',`/api/cases/${props.activeCase.id}/`,{},data,function(resp) {
                setTransport(id)
              }, () => props.setErrorMessage('Failed to attach detail to case.'))
            }, () => props.setErrormessage('Failed to create new transport detail.'))
          } else {
            setTransport(Case.transport_detail)
          }
        }

        setActiveTab(props.activeCase.procedure.sort((a, b) => a.id - b.id)[0].id)
      })
    }, onDataLoadError)
  }, []);

  const toggleTab = (index) => {
    setActiveTab(index)
  }

  const onDataLoadError = (error) => {
    setErrorMessage(error)
    setShowErrorModal(true)
    setIsSaving(false)
  }

  return <div>
    <p className="title is-inline-block mr-3 mb-0">Details</p>
    {
      props.activeCase.procedure.map((proc) => {
        return <span key={proc.id} style={{ marginRight: 5 }}>
          <ProcedureBox procedure={proc} />
        </span>
      })
    }

    <hr />

    <div className="tabs is-boxed">
      <ul>
        {
          props.activeCase.procedure.sort((a, b) => a.id - b.id).map((proc) => {
            if (proc.id == 1) {
              return <li key={proc.id} className={activeTab === 1 ? "is-active" : ""}>
                <a onClick={() => toggleTab(1)}>CPB</a>
              </li>
            } else if (proc.id == 2) {
              return <li key={proc.id} className={activeTab === 2 ? "is-active" : ""}>
                <a onClick={() => toggleTab(2)}>Autotransfusion</a>
              </li>
            } else if (proc.id == 3) {
              return <li key={proc.id} className={activeTab === 3 ? "is-active" : ""}>
                <a onClick={() => toggleTab(3)}>HIPEC</a>
              </li>
            } else if (proc.id == 4) {
              return <li key={proc.id} className={activeTab === 4 ? "is-active" : ""}>
                <a onClick={() => toggleTab(4)}>ECLS</a>
              </li>
            } else if (proc.id == 5) {
              return <li key={proc.id} className={activeTab === 5 ? "is-active" : ""}>
                <a onClick={() => toggleTab(5)}>Platelet Therapy</a>
              </li>
            } else if (proc.id == 6) {
              return <li key={proc.id} className={activeTab === 6 ? "is-active" : ""}>
                <a onClick={() => toggleTab(6)}>POC Lab Service</a>
              </li>
            } else if (proc.id == 7) {
              return <li key={proc.id} className={activeTab === 7 ? "is-active" : ""}>
                <a onClick={() => toggleTab(7)}>Perfusion Standby</a>
              </li>
            } else if (proc.id == 8) {
              return <li key={proc.id} className={activeTab === 8 ? "is-active" : ""}>
                <a onClick={() => toggleTab(8)}>Hem</a>
              </li>
            } else if (proc.id == 9) {
              return <li key={proc.id} className={activeTab === 9 ? "is-active": ""}>
                <a onClick={() => toggleTab(15)}>Transport</a>
              </li>
            }
          })
        }
      </ul>
    </div>

    <hr />

    <div className={activeTab === 1 ? "is-active" : "is-hidden"}>
      {
        cpbDetail
        &&
        <DetailCPB
          detailID={cpbDetail}
          setErrorMessage={props.setErrorMessage}
        />
      }
    </div>
    <div className={activeTab === 2 ? "is-active" : "is-hidden"}>
      {
        autoDetail
        &&
        <DetailAuto
          detailID={autoDetail}
          setErrorMessage={props.setErrorMessage}
        />
      }
    </div>
    <div className={activeTab === 3 ? "is-active" : "is-hidden"}>
      {
        hipecDetail
        &&
        <DetailHIPC
          detailID={hipecDetail}
          setErrorMessage={props.setErrorMessage}
        />
      }
    </div>
    <div className={activeTab === 4 ? "is-active" : "is-hidden"}>
      {
        eclsDetail
        &&
        <DetailECLS
          detailID={eclsDetail}
          setErrorMessage={props.setErrorMessage}
        />
      }
    </div>
    <div className={activeTab === 5 ? "is-active" : "is-hidden"}>
      {
        plateletDetail
        &&
        <DetailPlatelet
          detailID={plateletDetail}
          setErrorMessage={props.setErrorMessage}
        />
      }
    </div>
    <div className={activeTab === 6 ? "is-active" : "is-hidden"}>
      {
        pocLabDetail
        &&
        <DetailPOC
          detailID={pocLabDetail}
          setErrorMessage={props.setErrorMessage}
        />
      }
    </div>
    <div className={activeTab === 7 ? "is-active" : "is-hidden"}>
      {
        perfusionDetail
        &&
        <DetailPerfusion
          detailID={perfusionDetail}
          setErrorMessage={props.setErrorMessage}
        />
      }
    </div>
    <div className={activeTab === 8 ? "is-active" : "is-hidden"}>
      {
        hem
        &&
        <Hem
          setErrorMessage={props.setErrorMessage}
          detailID={hem}
        />
      }
    </div>
    <div className={activeTab === 15 ? "is-active" : "is-hidden"}>
      {
        transport
        &&
        <Transport
          setErrorMessage={props.setErrorMessage}
          detailID={transport}
        />
      }
    </div>

    {
      showErrorModal
      &&
      <ErrorModal
        message={errorMessage}
        onClose={() => setShowErrorModal(false)}
      />
    }
  </div>
}

export default Details