import React, { useEffect, useState, useMemo, useRef } from "react"
import DynamicForm from "./DynamicForm"
import API from "../../services/API"
import DataTable from "react-data-table-component"
import ConfirmationModal from "./ConfirmationModal"
import ErrorModal from "./ErrorModal"
import moment from "moment"
import hipecForm from "../forms/caseDetailsHipecBody"
import autoTopForm from "../forms/caseDetailsAutoTop"


function DetailHIPC(props) {
    const [hipecTopValues, setHipecTopValues] = useState([])
    const [hipecBodyValues, setHipecBodyValues] = useState([])
    const [hipecDetail, setHipecDetail] = useState([])
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [accordionID, setAccordionID] = useState()

    const hipcTopRef = useRef()
    const hipcBottomRef = useRef()

    let saveBtnClasses = "button is-success";
    if (isSaving) saveBtnClasses += " is-loading"

    const onDataLoadError = (error) => {
        setErrorMessage(error)
        setShowErrorModal(true)
        setIsSaving(false)
    }

    useEffect(() => {
        API.query("GET", `/api/hipecdetails/${props.detailID}/`, {}, {}, function (resp) {
            setHipecDetail(resp)
            setHipecTopValues({ start_date: resp.start_date, end_date: resp.end_date, start_time: resp.start_time, end_time: resp.end_time, or_number: resp.or_number })
            setHipecBodyValues({ priming_solution: resp.priming_solution ? `${resp.priming_solution}` : null, min_flow: resp.min_flow, max_flow: resp.max_flow, max_inflow: resp.max_inflow, max_outflow: resp.max_outflow, agent: resp.agent, doses: resp.doses, case_comments: resp.case_comments, department_comments: resp.department_comments })
        }, onDataLoadError)
    }, [])

    const onConfirm = () => {
        if (accordionID == 22) {
            let data = {
                'start_date': null,
                'end_date': null,
                'start_time': null,
                'end_time': null,
                'or_number': null
            }
            API.query('PATCH', `/api/hipecdetails/${hipecDetail.id}/`, {}, data, function (resp) {
                setHipecDetail(resp)
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                hipcTopRef.current.reset()
                setHipecTopValues({ start_date: null, end_date: null, start_time: null, end_time: null, or_number: null })
            }, onDataLoadError)
        } else if (accordionID == 23) {
            let data = {
                'priming_solution': null,
                'min_flow': null,
                'max_flow': null,
                'max_inflow': null,
                'max_outflow': null,
                'agent': null,
                'doses': null,
                'dose_amount': null,
                'washout_volume': null,
                'max_temp': null,
                'case_comments': null,
                'department_comments': null
            }
            API.query('PATCH', `/api/hipecdetails/${hipecDetail.id}/`, {}, data, function (resp) {
                setHipecDetail(resp)
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                hipcBottomRef.current.reset()
                setHipecBodyValues({ priming_solution: null, min_flow: null, max_flow: null, max_inflow: null, max_outflow: null, agent: null, doses: null, case_comments: '', department_comments: '' })
            }, onDataLoadError)
        }
    }

    const onHipecTopChanged = (value) => {
        setHipecTopValues(value)
    }

    const onHipecBodyChanged = (value) => {
        setHipecBodyValues(value)
    }

    const onHipecTopSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'start_date': hipecTopValues.start_date,
                'end_date': hipecTopValues.end_date,
                'start_time': hipecTopValues.start_time,
                'end_time': hipecTopValues.end_time,
                'or_number': hipecTopValues.or_number
            }
            API.query('PATCH', `/api/hipecdetails/${hipecDetail.id}/`, {}, data, function (resp) {
                setHipecDetail(resp)
                setIsSaving(false)
            }, onDataLoadError)
        }
    }

    const onHipecBodySave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'priming_solution': hipecBodyValues.priming_solution,
                'min_flow': hipecBodyValues.min_flow,
                'max_flow': hipecBodyValues.max_flow,
                'max_inflow': hipecBodyValues.max_inflow,
                'max_outflow': hipecBodyValues.max_outflow,
                'agent': hipecBodyValues.agent,
                'doses': hipecBodyValues.doses,
                'dose_amount': hipecBodyValues.dose_amount,
                'washout_volume': hipecBodyValues.washout_volume,
                'max_temp': hipecBodyValues.max_temp,
                'case_comments': hipecBodyValues.case_comments,
                'department_comments': hipecBodyValues.department_comments
            }
            API.query('PATCH', `/api/hipecdetails/${hipecDetail.id}/`, {}, data, function (resp) {
                setHipecDetail(resp)
                setIsSaving(false)
            }, onDataLoadError)
        }
    }

    const onHipecTopClear = () => {
        setConfirmationMessage('You are about to clear HIPEC values, please confirm.')
        setAccordionID(22)
        setShowConfirmationModal(true)
    }

    const onHipecBodyClear = () => {
        setConfirmationMessage('You are about to clear HIPEC values, please confirm.')
        setAccordionID(23)
        setShowConfirmationModal(true)
    }

    return <div>
        <form ref={hipcTopRef}>
            <DynamicForm
                key={201}
                values={hipecTopValues}
                onValuesChanged={onHipecTopChanged}
                form={autoTopForm}
            />
        </form>


        <hr />

        <div className="level w-100">
            <div className="level-left">
                <button className="button is-danger is-inverted" onClick={onHipecTopClear}>Clear</button>
            </div>
            <div className="level-right">
                <button className={saveBtnClasses} onClick={onHipecTopSave}>Save</button>
            </div>
        </div>

        <hr />

        <form ref={hipcBottomRef}>
            <DynamicForm
                key={202}
                form={hipecForm}
                values={hipecBodyValues}
                onValuesChanged={onHipecBodyChanged}
            />
        </form>

        <hr />

        <div className="level w-100">
            <div className="level-left">
                <button className="button is-danger is-inverted" onClick={onHipecBodyClear}>Clear</button>
            </div>
            <div className="level-right">
                <button className={saveBtnClasses} onClick={onHipecBodySave}>Save</button>
            </div>
        </div>

        {
            showConfirmationModal
            &&
            <ConfirmationModal
                message={confirmationMessage}
                onConfirm={onConfirm}
                onClose={() => setShowConfirmationModal(false)}
            />
        }

        {
            showErrorModal
            &&
            <ErrorModal
                message={errorMessage}
                onClose={() => setShowErrorModal(false)}
            />
        }
    </div>
}

export default DetailHIPC