import React, { useEffect, useState, useRef } from "react";
import DynamicForm from "./DynamicForm";
import API from "../../services/API";
import ConfirmationModal from "./ConfirmationModal";
import ErrorModal from "./ErrorModal";

import autoTopForm from "../forms/caseDetailsAutoTop";
import PlateletInfo from "../forms/caseDetailsPlateletInfo";
import PlateletMiddle from "../forms/caseDetailsPlateletMiddle";
import PlateletBottom from "../forms/caseDetailsPlateletBottom";

function DetailPlatelet(props) {
  const [plateletTopValues, setPlateletTopValues] = useState([]);
  const [plateletInfoValues, setPlateletInfoValues] = useState([]);
  const [plateletMiddleValues, setPlateletMiddleValues] = useState([]);
  const [plateletBottomValues, setPlateletBottomValues] = useState([]);
  const [plateletDetail, setPlateletDetail] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [accordionID, setAccordionID] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  let saveBtnClasses = "button is-success";
  if (isSaving) saveBtnClasses += " is-loading";

  const plateTopRef = useRef();
  const plateBottomRef = useRef();

  const onPlateletTopChanged = (value) => {
    setPlateletTopValues(value);
  };

  const onPlateletInfoChanged = (value) => {
    setPlateletInfoValues(value);
  };

  const onPlateletMiddleChanged = (value) => {
    setPlateletMiddleValues(value);
  };

  const onPlateletBottomChanged = (value) => {
    setPlateletBottomValues(value);
  };

  const onPlateletTopClear = () => {
    setConfirmationMessage(
      "You are about to clear Platelet values, please confirm."
    );
    setAccordionID(26);
    setShowConfirmationModal(true);
  };

  const onPlateletInfoClear = () => {
    setConfirmationMessage(
      "You are about to clear Platelet values, please confirm."
    );
    setAccordionID(27);
    setShowConfirmationModal(true);
  };

  const onPlateletMiddleClear = () => {
    setConfirmationMessage(
      "You are about to clear Platelet values, please confirm."
    );
    setAccordionID(28);
    setShowConfirmationModal(true);
  };

  const onPlateletNoteClear = () => {
    setConfirmationMessage(
      "You are about to clear Platelet values, please confirm."
    );
    setAccordionID(29);
    setShowConfirmationModal(true);
  };

  const onPlateletTopSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        start_date: plateletTopValues.start_date,
        end_date: plateletTopValues.end_date,
        start_time: plateletTopValues.start_time,
        end_time: plateletTopValues.end_time,
        or_number: plateletTopValues.or_number,
      };
      API.query(
        "PATCH",
        `/api/plateletdetails/${plateletDetail.id}/`,
        {},
        data,
        function (resp) {
          setPlateletDetail(resp);
          setIsSaving(false);
        },
        onDataLoadError
      );
    }
  };

  const onPlateletInfoSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        cardiac_info: plateletInfoValues.cardiac_info,
        cardiac_checked: plateletInfoValues.cardiac_checked,
        vascular_info: plateletInfoValues.vascular_info,
        vascular_checked: plateletInfoValues.vascular_checked,
        ortho_info: plateletInfoValues.ortho_info,
        ortho_checked: plateletInfoValues.ortho_checked,
        neuro_info: plateletInfoValues.neuro_info,
        neuro_checked: plateletInfoValues.neuro_checked,
        wound_info: plateletInfoValues.wound_info,
        wound_checked: plateletInfoValues.wound_checked,
        other_info: plateletInfoValues.other_info,
        other_checked: plateletInfoValues.other_checked,
      };
      API.query(
        "PATCH",
        `/api/plateletdetails/${plateletDetail.id}/`,
        {},
        data,
        function (resp) {
          setPlateletDetail(resp);
          setIsSaving(false);
        },
        onDataLoadError
      );
    }
  };

  const onPlateletMiddleSave = () => {
    if (!isSaving) {
      setIsSaving(false);
      let data = {
        anticoag_used: plateletMiddleValues.anticoag_used,
        cpd_used: plateletMiddleValues.cpd_used,
        acd_used: plateletMiddleValues.acd_used,
        heparin_used: plateletMiddleValues.heparin_used,
        other_used: plateletMiddleValues.other_used,
        preop_hematocrit: plateletMiddleValues.preop_hematocrit,
        platelet_count: plateletMiddleValues.platelet_count,
        total_blood_collected: plateletMiddleValues.total_blood_collected,
        rich_platelet: plateletMiddleValues.rich_platelet,
        rich_application_site: plateletMiddleValues.rich_application_site,
        rich_applicator: plateletMiddleValues.rich_applicator,
        rich_collected: plateletMiddleValues.rich_collected,
        poor_platelet: plateletMiddleValues.poor_platelet,
        poor_application_site: plateletMiddleValues.poor_application_site,
        poor_applicator: plateletMiddleValues.poor_applicator,
        poor_collected: plateletMiddleValues.poor_collected,
        activator_one_used: plateletMiddleValues.activator_one_used,
        activator_one_recombinant_amount:
          plateletMiddleValues.activator_one_recombinant_amount,
        activator_one_bovine_amount:
          plateletMiddleValues.activator_one_bovine_amount,
        activator_one_calcium_amount:
          plateletMiddleValues.activator_one_calcium_amount,
        activator_one_other_info: plateletMiddleValues.activator_one_other_info,
        activator_one_other_amount:
          plateletMiddleValues.activator_one_other_amount,
        activator_two_used: plateletMiddleValues.activator_two_used,
        activator_two_recombinant_amount:
          plateletMiddleValues.activator_two_recombinant_amount,
        activator_two_bovine_amount:
          plateletMiddleValues.activator_two_bovine_amount,
        activator_two_calcium_amount:
          plateletMiddleValues.activator_two_calcium_amount,
        activator_two_other_info: plateletMiddleValues.activator_two_other_info,
        activator_two_other_amount:
          plateletMiddleValues.activator_two_other_amount,
      };
      API.query(
        "PATCH",
        `/api/plateletdetails/${plateletDetail.id}/`,
        {},
        data,
        function (resp) {
          setIsSaving(false);
          setPlateletDetail(resp);
        },
        onDataLoadError
      );
    }
  };

  const onPlateletBottomSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        case_comments: plateletBottomValues.case_comments,
        department_comments: plateletBottomValues.department_comments,
      };
      API.query(
        "PATCH",
        `/api/plateletdetails/${plateletDetail.id}/`,
        {},
        data,
        function (resp) {
          setPlateletDetail(resp);
          setIsSaving(false);
        },
        onDataLoadError
      );
    }
  };

  const onDataLoadError = (error) => {
    setErrorMessage(error);
    setShowErrorModal(true);
    setIsSaving(false);
  };

  useEffect(() => {
    API.query(
      "GET",
      `/api/plateletdetails/${props.detailID}/`,
      {},
      {},
      function (resp) {
        setPlateletDetail(resp);
        setPlateletTopValues({
          start_date: resp.start_date,
          end_date: resp.end_date,
          start_time: resp.start_time,
          end_time: resp.end_time,
          or_number: resp.or_number,
        });
        setPlateletInfoValues({
          cardiac_info: resp.cardiac_info,
          cardiac_checked: resp.cardiac_checked,
          vascular_info: resp.vascular_info,
          vascular_checked: resp.vascular_checked,
          ortho_info: resp.ortho_info,
          ortho_checked: resp.ortho_checked,
          neuro_info: resp.neuro_info,
          neuro_checked: resp.neuro_checked,
          wound_info: resp.wound_info,
          wound_checked: resp.wound_checked,
          other_info: resp.other_info,
          other_checked: resp.other_checked,
        });
        setPlateletMiddleValues({
          anticoag_used: resp.anticoag_used,
          cpd_used: resp.cpd_used,
          acd_used: resp.acd_used,
          heparin_used: resp.heparin_used,
          other_used: resp.other_used,
          preop_hematocrit: resp.preop_hematocrit,
          platelet_count: resp.platelet_count,
          total_blood_collected: resp.total_blood_collected,
          rich_platelet: resp.rich_platelet,
          rich_application_site: resp.rich_application_site,
          rich_collected: resp.rich_collected,
          poor_platelet: resp.poor_platelet,
          poor_application_site: resp.poor_application_site,
          poor_collected: resp.poor_collected,
          activator_one_used: resp.activator_one_used,
          activator_one_recombinant_amount:
            resp.activator_one_recombinant_amount,
          activator_one_bovine_amount: resp.activator_one_bovine_amount,
          activator_one_calcium_amount: resp.activator_one_calcium_amount,
          activator_one_other_info: resp.activator_one_other_info,
          activator_one_other_amount: resp.activator_one_other_amount,
          activator_two_used: resp.activator_two_used,
          activator_two_recombinant_amount:
            resp.activator_two_recombinant_amount,
          activator_two_bovine_amount: resp.activator_two_bovine_amount,
          activator_two_calcium_amount: resp.activator_two_calcium_amount,
          activator_two_other_info: resp.activator_two_other_info,
          activator_two_other_amount: resp.activator_two_other_amount,
        });
        setPlateletBottomValues({
          case_comments: resp.case_comments,
          department_comments: resp.department_comments,
        });
      },
      onDataLoadError
    );
  }, []);

  const onConfirm = () => {
    if (accordionID == 26) {
      let data = {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        or_number: null,
      };
      API.query(
        "PATCH",
        `/api/plateletdetails/${plateletDetail.id}/`,
        {},
        data,
        function (resp) {
          setPlateletDetail(resp);
          setShowConfirmationModal(false);
          setAccordionID(0);
          setConfirmationMessage("");
          plateTopRef.current.reset();
          setPlateletTopValues({
            start_date: null,
            end_date: null,
            start_time: null,
            end_time: null,
            or_number: null,
          });
        },
        onDataLoadError
      );
    } else if (accordionID == 27) {
      let data = {
        cardiac_info: null,
        vascular_info: null,
        ortho_info: null,
        neuro_info: null,
        wound_info: null,
        other_info: null,
      };
      API.query(
        "PATCH",
        `/api/plateletdetails/${plateletDetail.id}/`,
        {},
        data,
        function (resp) {
          setPlateletDetail(resp);
          setShowConfirmationModal(false);
          setAccordionID(0);
          setConfirmationMessage("");
          setPlateletInfoValues({
            cardiac_info: "",
            cardiac_checked: false,
            vascular_info: "",
            vascular_checked: false,
            ortho_info: "",
            ortho_checked: false,
            neuro_info: "",
            neuro_checked: false,
            wound_info: "",
            wound_checked: false,
            other_info: "",
            other_checked: false,
          });
        },
        onDataLoadError
      );
    } else if (accordionID == 28) {
      let data = {
        anticoag_used: null,
        cpd_used: null,
        acd_used: null,
        heparin_used: null,
        other_used: null,
        preop_hematocrit: null,
        platelet_count: null,
        total_blood_collected: null,
        rich_platelet: null,
        rich_application_site: null,
        rich_collected: null,
        poor_platelet: null,
        poor_application_site: null,
        poor_collected: null,
        activator_one_used: null,
        activator_one_recombinant_amount: null,
        activator_one_bovine_amount: null,
        activator_one_calcium_amount: null,
        activator_one_other_info: null,
        activator_one_other_amount: null,
        activator_two_used: null,
        activator_two_recombinant_amount: null,
        activator_two_bovine_amount: null,
        activator_two_calcium_amount: null,
        activator_two_other_info: null,
        activator_two_other_amount: null,
      };
      API.query(
        "PATCH",
        `/api/plateletdetails/${plateletDetail.id}/`,
        {},
        data,
        function (resp) {
          setPlateletDetail(resp);
          setAccordionID(0);
          setShowConfirmationModal(false);
          setConfirmationMessage("");
          plateBottomRef.current.reset();
          setPlateletMiddleValues({
            anticoag_used: null,
            cpd_used: null,
            acd_used: null,
            heparin_used: null,
            other_used: null,
            preop_hematocrit: null,
            platelet_count: null,
            total_blood_collected: null,
            rich_platelet: null,
            rich_application_site: null,
            rich_collected: null,
            poor_platelet: null,
            poor_application_site: null,
            poor_collected: null,
            activator_one_used: null,
            activator_one_recombinant_amount: null,
            activator_one_bovine_amount: null,
            activator_one_calcium_amount: null,
            activator_one_other_info: null,
            activator_one_other_amount: null,
            activator_two_used: null,
            activator_two_recombinant_amount: null,
            activator_two_bovine_amount: null,
            activator_two_calcium_amount: null,
            activator_two_other_info: null,
            activator_two_other_amount: null,
          });
        },
        onDataLoadError
      );
    } else if (accordionID == 29) {
      let data = {
        case_comments: null,
        department_comments: null,
      };
      API.query(
        "PATCH",
        `/api/plateletdetails/${plateletDetail.id}/`,
        {},
        data,
        function (resp) {
          setPlateletDetail(resp);
          setAccordionID(0);
          setShowConfirmationModal(false);
          setConfirmationMessage("");
          setPlateletBottomValues({
            case_comments: "",
            department_comments: "",
          });
        },
        onDataLoadError
      );
    }
  };

  return (
    <div>
      <form ref={plateTopRef}>
        <DynamicForm
          key={401}
          form={autoTopForm}
          values={plateletTopValues}
          onValuesChanged={onPlateletTopChanged}
        />
      </form>

      <hr />

      <div className="level w-100">
        <div className="level-left">
          <button
            className="button is-danger is-inverted"
            onClick={onPlateletTopClear}
          >
            Clear
          </button>
        </div>
        <div className="level-right">
          <button className={saveBtnClasses} onClick={onPlateletTopSave}>
            Save
          </button>
        </div>
      </div>

      <hr />

      <DynamicForm
        key={402}
        form={PlateletInfo}
        values={plateletInfoValues}
        onValuesChanged={onPlateletInfoChanged}
      />

      <hr />

      <div className="level w-100">
        <div className="level-left">
          <button
            className="button is-danger is-inverted"
            onClick={onPlateletInfoClear}
          >
            Clear
          </button>
        </div>
        <div className="level-right">
          <button className={saveBtnClasses} onClick={onPlateletInfoSave}>
            Save
          </button>
        </div>
      </div>

      <hr />

      <form ref={plateBottomRef}>
        <DynamicForm
          key={403}
          form={PlateletMiddle}
          values={plateletMiddleValues}
          onValuesChanged={onPlateletMiddleChanged}
        />
      </form>

      <hr />

      <div className="level w-100">
        <div className="level-left">
          <button
            className="button is-danger is-inverted"
            onClick={onPlateletMiddleClear}
          >
            Clear
          </button>
        </div>
        <div className="level-right">
          <button className={saveBtnClasses} onClick={onPlateletMiddleSave}>
            Save
          </button>
        </div>
      </div>

      <hr />

      <DynamicForm
        key={404}
        form={PlateletBottom}
        values={plateletBottomValues}
        onValuesChanged={onPlateletBottomChanged}
      />

      <hr />

      <div className="level w-100">
        <div className="level-left">
          <button
            className="button is-danger is-inverted"
            onClick={onPlateletNoteClear}
          >
            Clear
          </button>
        </div>
        <div className="level-right">
          <button className={saveBtnClasses} onClick={onPlateletBottomSave}>
            Save
          </button>
        </div>
      </div>

      {showConfirmationModal && (
        <ConfirmationModal
          message={confirmationMessage}
          onConfirm={onConfirm}
          onClose={() => setShowConfirmationModal(false)}
        />
      )}

      {showErrorModal && (
        <ErrorModal
          message={errorMessage}
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </div>
  );
}

export default DetailPlatelet;
