import React, { useState, useEffect } from "react";
import Select from 'react-select';
import DatePicker from 'react-datepicker'
import API from "../../services/API";
import { CSVLink } from "react-csv";

function HemProcedureBilling(props) {
    const [filters, setFilters] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [categories, setCategories] = useState([])
    const [data, setData] = useState()
    const [caseTypes, setCaseTypes] = useState([])
    const today = new Date()

    useEffect(() => {
        let queries = [
            { 'method': 'GET', 'path': '/api/itemcategories/', },
            { 'method': 'GET', 'path': '/api/hemcasetypes/', }
        ]
        API.all(queries, function (resp) {
            setCategories(resp[0])
            setCaseTypes(resp[1])
        }, onDataLoadError)
    }, []);

    const onFilterChanged = (value, type) => {
        let fValues = { ...filters }
        if (type === 0) {
            fValues['start_date'] = value
        } else if (type === 1) {
            fValues['end_date'] = value
        } else if (type === 2) {
            fValues['facility'] = value
        } else if (type === 3) {
            fValues['personnel'] = value
        }
        setFilters(fValues)
    }

    const renderData = () => {
        if (filters.start_date != null && filters.end_date != null) {
            if(!isLoading) {
                setIsLoading(true)
                let facilities

                if(filters.facility && filters.facility.length > 0) {
                    facilities = [...filters.facility]
                }

                let params = {
                    'start_date': start_date,
                    'end_date': end_date,
                    'type': 'Hem'
                }

                if(facilities) {
                    params = {...params, ...{"facility": facilities}}
                }

                API.query('GET',`/api/casereports/`,params,{},function(resp) {
                    if(resp && resp.length > 0) {
                        setData(resp)
                    }
                    setIsLoading(false)
                },() => onDataLoadError())
            }
        }
    }

    const getReportData = () => {
        if(data && data.length > 0) {
            var rows = []
            rows.push(['Hem Procedure Billing Report','','','','','',`Printed: ${today.toLocaleDateString()}`])
            for(let x=0; x<data.length; x++) {
                if(data[x].hem_detail) {
                    let typeStr = ''
                    if(data[x].hem_detail.case_type && data[x].hem_detail.case_type.length > 0) {
                        for(let y=0;y<data[x].hem_detail.case_type.length;y++) {
                            if(typeStr == '') {
                                typeStr = `${data[x].hem_detail.case_type[y].type}`
                            } else {
                                typeStr = `${typeStr}, ${data[x].hem_detail.case_type[y].type}`
                            }
                        }
                    }
                    let surgStr = '';
                    if(data[x].personnel_roles && data[x].personnel_roles.length) {
                        for(let y=0; y < data[x].personnel_roles.length; y++) {
                            if(data[x].personnel_roles[y]?.job?.id === 15) {
                                if(surgStr === '') {
                                    surgStr = `${data[x].personnel_roles[y]?.user_full_name}`
                                } else {
                                    surgStr = `${surgStr}, ${data[x].personnel_roles[y]?.user_full_name}`
                                }
                            }
                        }
                    }
                    var i
                    let keys = ['#','Manufacturer','Catalog Number', 'Item Category', 'Description','Units']
                    rows.push([]) //'','','','','','',''
                    rows.push(['Start Date:',`${data[x].date ? data[x].date : null}`])
                    rows.push(['Patient Name:',`${data[x].patient.last_name}, ${data[x].patient.first_name}`])
                    rows.push(['MRN:',`${data[x].patient.medical_record_num ? data[x].patient.medical_record_num : ''}`])
                    rows.push(['Facility:',`${data[x].facility_name}`])
                    rows.push(['Total Hours:',`${data[x].hem_detail.total_hours ? data[x].hem_detail.total_hours : ''}`])
                    rows.push(['Procedures:',`${typeStr}`])
                    rows.push(['Surgeon:',`${surgStr}`])
                    rows.push([])
                    rows.push(keys)
                    for(i=0; i < data[x].items.length; i++) {
                        let category = categories.find((e) => data[x].items[i].item.item_category == e.id)
                        rows.push([
                            `${i+1}`,
                            `${data[x].items[i].item.manufacturer_name}`,
                            `${data[x].items[i].item.catalog}`,
                            `${category ? category.category : ''}`,
                            `${data[x].items[i].item.description}`,
                            `${data[x].items[i].amount}`
                        ])
                    }
                    rows.push([])
                    rows.push([])
                    rows.push([])
                }
            }
            return rows
        }
    }

    const onDataLoadError = (error) => {
        setIsLoading(false)
        console.log(error)
    }

    const start_date = filters.start_date ? new Date(filters.start_date).toLocaleDateString('en-CA') : ''
    const end_date = filters.end_date ? new Date(filters.end_date).toLocaleDateString('en-CA') : ''

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card modal-extra-large">
            <header className="modal-card-head">
                <p className="modal-card-title">Procedure Billing Report</p>
            </header>

            <section className="modal-card-body p-0">
                <div className="p-5">
                    <div className="w-100">
                        <div className="columns">
                            <div className="column"></div>
                            <div className="column">
                                <div style={{ midWidth: '499px', maxWidth: '500px' }}>
                                    <Select
                                        options={props.facilities}
                                        placeholder={'All Facilities'}
                                        value={props.facilities.find((e) => e.value == filters.facility)}
                                        onChange={(option) => onFilterChanged(option.map(option => option.value), 2)}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                            <div className="column"></div>
                        </div>
                        <div className="level w-100">
                            <div className="level-item"></div>
                            <div className="level-item">
                                <div style={{ maxWidth: '200px', minWidth: '200px' }}>
                                    <DatePicker
                                        className="input"
                                        selectsStart
                                        startDate={filters.start_date}
                                        endDate={filters.end_date}
                                        selected={filters.start_date}
                                        onChange={(date) => onFilterChanged(date, 0)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        maxDate={new Date()}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Start Date"
                                    />
                                </div>
                            </div>
                            <div className="level-item">
                                <div style={{ maxWidth: '200px', minWidth: '200px' }}>
                                    <DatePicker
                                        className="input"
                                        selectsStart
                                        startDate={filters.start_date}
                                        endDate={filters.end_date}
                                        selected={filters.end_date}
                                        onChange={(date) => onFilterChanged(date, 1)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        maxDate={new Date()}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="End Date"
                                    />
                                </div>
                            </div>
                            <div className="level-item"></div>
                        </div>

                        <div className="level w-100">
                            <div className="level-item has-text-centered">
                                <button className="button is-success" onClick={renderData}>Set Filters</button>
                            </div>
                        </div>
                        
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>

                <button onClick={props.onClose} className="modal-close is-large" aria-label="close"></button>
            </section>

            <footer className="modal-card-foot">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="button is-danger" onClick={props.onClose}>Close</button>
                    </div>
                    <div className="level-right">
                        {
                            data
                            &&
                            <CSVLink filename={`hem-proc-billing-${start_date}_${end_date}`} data={getReportData()}><button className="button is-success" >Download</button></CSVLink>
                        }
                    </div>
                </div>
            </footer>
        </div>
    </div>
}

export default HemProcedureBilling