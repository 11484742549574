import React, { useEffect, useMemo, useState } from "react";
import DataTable from 'react-data-table-component';
import { FaRegEdit, FaTimes } from "react-icons/fa";
import API from "../../../../services/API";
import CommentModal from "../../../components/CommentModal";
import Select from 'react-select';
import SearchBar from "../../../components/SearchBar";

function ItemSelection(props){
  const [items,setItems] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsLoading, setItemsLoading] = useState(true)
  const [itemTypes,setItemTypes] = useState([])
  const [query,setQuery] = useState("")
  const [activeType,setActiveType] = useState()
  const [commentModal,setCommentModal] = useState()
  const [filterValues,setFilterValues] = useState({category: null, type: null, query: null})
  const [typeOptions, setTypeOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])

  const searchColumns = useMemo(() => [
    {name:'Catalog #',width:"120px",selector: row => row.catalog},
    {name:'Item Description',selector: row => row.description},
    {name:'Manufacturer',width:"180px",selector: row => row.manufacturer_name},
    {name:'Add Component',width:"140px",center:true,selector: row => {
      const added = props.activeCase.items.find(i => i.item.id === row.id)
      return (!added ? <a className="button is-success" onClick={() => onAddItem(row)}>Add</a> : null)
    }}
  ])

  const caseColumns = useMemo(() => [
    {name:'Description',width:"420px",selector: row => row.item.description},
    {name:'Manufacturer',center:true,selector: row => row.item.manufacturer_name},
    {name:'Catalog #',width:"120px",center:true,selector: row => row.item.catalog},
    {name:'Amount',width:"120px",center:true,selector: row => 
      <div>
        <input 
          className="input"
          type="number"
          value={row.amount == null ? '' : row.amount}
          onChange={(value) => onAmountChange(row.item.id, value.target.value)}
        />
      </div>
    },
    {name:'Comment',width:"100px",center:true,selector: row => <a className="has-text-info mr-3" onClick={() => onComment(row.item.id,row.comment)}><FaRegEdit size={20} /></a>},
    {name:'Delete',width:"80px",center:true,selector: row => <a className="has-text-danger" onClick={() => onDelete(row.id)}><FaTimes size={20} /></a>},
  ])

  useEffect(() => {
    let queries = [
      { 'method': 'GET', 'path': '/api/itemtypes/', },
      { 'method': 'GET', 'path': '/api/itemcategories/',},
      { 'method': 'GET', 'path': '/api/caseitemoptions/?limit=10&offset=0',}
    ]
    API.all(queries, function (resp) {
      const [itemTypes,itemCategories,items] = resp
      setItems(items.results)
      setTotalItems(items.count)
      setItemsLoading(false)
      setItemTypes(itemTypes)
      setTypeOptions(itemTypes.map(i => ({ "label": i.type, "value": i.id })))
      setCategoryOptions(itemCategories.map(i => ({ "label": i.category, "value": i.id })))
      if(itemTypes.length > 0) setActiveType(itemTypes[1].id)
    }, () => {
      props.setErrorMessage("Error loading data")
    })
  },[])

  useEffect(() => {
    if(typeOptions.length) {
      setItemsLoading(true)
      const filterParams = {limit:10,offset:(currentPage-1)*10}
      if(filterValues.type) filterParams['item_type'] = filterValues.type
      if(filterValues.category) filterParams['item_category'] = filterValues.category
      if(filterValues.query) filterParams['query'] = filterValues.query
      API.query("GET", "/api/caseitemoptions/",filterParams,null,(resp) => {
        setItems(resp.results)
        setTotalItems(resp.count)
        setItemsLoading(false)
      },(error) => {
        console.log(error)
        setItemsLoading(false)
      })
    }
  },[filterValues,currentPage])

  const onAddItem = (item) => {
    const newCaseItem = {
      item
    }
    props.updateActiveCase({
      items: [...props.activeCase.items,newCaseItem]
    })
  }

  const onComment = (itemId,comment) => {
    setCommentModal({
      itemId: itemId,
      comment: comment
    })
  }

  const onAmountChange = (id, value) => {
    const newItems = props.activeCase.items.map(item => {
      if(item.item.id === id) item.amount = value
      return item
    })

    props.updateActiveCase({
      items: newItems
    })
  }

  const onSaveCommentModal = (newComment) => {
    const newItems = props.activeCase.items.map(item => {
      if(item.item.id === commentModal.itemId) item.comment = newComment
      return item
    })

    props.updateActiveCase({
      items: newItems
    })

    setCommentModal(null)
  }

  const onDelete = (id) => {
    props.updateActiveCase({
      items: props.activeCase.items.filter(item => item.id !== id)
    })
    
    API.query('DELETE',`/api/caseitem/${id}/`,{},{}, function (resp) {}, () => {
      props.setErrorMessage('Failed deleting item.')
    })
  }

  const filterChanged = (value, type) => {
    let fValues = {...filterValues}
    if(type == 1) {
      fValues.type = value
    } else if(type == 2) {
      fValues.category = value
    } else if(type == 3) {
      fValues.query = value
    }
    setFilterValues(fValues)
  }

  const caseItems = props.activeCase.items

  const onClearClick = () => {
    setFilterValues({category: null, type: null, query: null})
    setQuery("")
  }
  
  return <div>
    <p className="title is-inline-block mr-3 mb-0">Item Selection</p>

    <hr/>
    
    <p>Search for Components (Click or Add to Log for this Case)</p>
    <SearchBar query={query} onChange={setQuery} onSubmit={() => {
      filterChanged(query, 3)
    }}/>

    <div style={{marginTop:"12px"}} className="container">
      <p style={{paddingTop:"4px"}} className="is-pulled-left">Filter by item type:</p>
      <div className="is-pulled-left" style={{minWidth:"250px",maxWidth:"250px",marginLeft:"8px"}}>
        <Select
          placeholder="Select an item type"
          value={{...typeOptions.find(e => e.value == filterValues.type)}}
          options={typeOptions}
          onChange={(option) => filterChanged(option.value,1)}
        />
      </div>
      
      <div className="is-pulled-right" style={{minWidth:"250px",maxWidth:"250px",marginLeft:"8px"}}>
        <Select
          placeholder="Select an item category"
          value={{...categoryOptions.find(e => e.value == filterValues.category)}}
          options={categoryOptions}
          onChange={(option) => filterChanged(option.value,2)}
        />
      </div>
      <p style={{paddingTop:"4px"}} className="is-pulled-right">Filter by item category:</p>
    </div>

    <br />
    <br />

    <button className="button is-danger" onClick={onClearClick}>Clear</button>

    <hr />

    <DataTable
      striped
      pagination
      paginationServer
      paginationTotalRows={totalItems}
      paginationComponentOptions={{noRowsPerPage:true}}
      columns={searchColumns}
      data={items}
      progressPending={itemsLoading}
      onChangePage={setCurrentPage}
    />

    <hr />
    <label className="label">Logged for this case</label>
    <div className="tabs">
      <ul>
        {
          itemTypes.map((itemType,i) => (
            <li className={itemType.id===activeType?"is-active":""} key={i}>
              <a onClick={() => setActiveType(itemType.id)}>{itemType.type}</a>
            </li>
          ))
        }
      </ul>
    </div>
    <DataTable
      striped
      pagination
      columns={caseColumns}
      data={caseItems.filter(caseItem => caseItem.item.item_type === activeType)}
    />

    {
      commentModal
      ?
      <CommentModal
        comment={commentModal.comment}
        onSave={(comment) => onSaveCommentModal(comment)}
        onCancel={() => setCommentModal(null)}
      />
      :
      null
    }
    
  </div>
}

export default ItemSelection