import React, { useEffect, useState, useMemo } from "react";
import DynamicForm from "../../../components/DynamicForm";
import SetupModal from "../../../components/SetupModal";
import ListModal from "../../../components/ListModal";
import API from "../../../../services/API";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import { FaPencilAlt, FaTrashAlt, FaFileExcel } from "react-icons/fa";

import userSearch from "../../../forms/userSearch";
import userModal from "../../../forms/userModal";
import ErrorModal from "../../../components/ErrorModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import VisitTimer from "../../../components/VisitTimer";
import Session from "../../../../services/Session";

function UserSetup(props) {
  const [filterValues, setFilterValues] = useState();
  const [personnel, setPersonnel] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [personnelOptions, setPersonnelOptions] = useState([]);
  const [editValues, setEditValues] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showListModal, setShowListModal] = useState(false);
  const [list, setList] = useState([]);
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [jobOptions, setJobOptions] = useState([]);
  const [showSetupModal, setShowSetupModal] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [signature, setSignature] = useState();
  const [originalSignature, setOriginalSignature] = useState();

  const onFilterChange = (values) => {
    setFilterValues(values);
  };

  const onEditChange = (values) => {
    if (values && values.signature) {
      setSignature(values.signature.files);
      values.signature = values.signature.value;
    }
    setEditValues(values);
  };

  const onSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      var data;
      if (
        editValues["password"] == "" ||
        editValues["password"] == null ||
        editValues["password"] == undefined
      ) {
        data = {
          first_name: editValues["first_name"],
          last_name: editValues["last_name"],
          email: editValues["email"],
          phone: editValues["phone"],
          phone_type: editValues["phone_type"],
          user_status:
            editValues["user_status"] && editValues["user_status"] == "false"
              ? false
              : true,
          employment_status: editValues["employment_status"],
          permission: editValues["user_role"],
          facilities: editValues["facilities"],
          skills: editValues["skills"],
          profile_photo: editValues["profile_photo"],
          credential_file: editValues["credential_file"],
          jobs: editValues["jobs"],
          view_permissions: editValues["view_permissions"],
          role: editValues["role_id"],
        };
      } else {
        data = {
          first_name: editValues["first_name"],
          last_name: editValues["last_name"],
          email: editValues["email"],
          password: editValues["password"],
          phone: editValues["phone"],
          phone_type: editValues["phone_type"],
          user_status:
            editValues["user_status"] && editValues["user_status"] == "false"
              ? false
              : true,
          employment_status: editValues["employment_status"],
          permission: editValues["user_role"],
          facilities: editValues["facilities"],
          skills: editValues["skills"],
          profile_photo: editValues["profile_photo"],
          credential_file: editValues["credential_file"],
          jobs: editValues["jobs"],
          view_permissions: editValues["view_permissions"],
          role: editValues["role_id"],
        };
      }
      let method = isEditMode ? "PATCH" : "POST";
      let uri = isEditMode
        ? `/api/newusers/${editValues.id}/`
        : "/api/newusers/";
      API.query(
        method,
        uri,
        {},
        data,
        async function (resp) {
          if (signature && originalSignature) {
            await replaceSignature();
          } else if (signature) {
            await uploadSignature(resp.id);
          }

          delete data.password;
          let newPersonnel = personnel;
          if (isEditMode) {
            let i = newPersonnel.findIndex((j) => j.id == editValues.id);
            if (i > -1) {
              data["id"] = editValues.id;
              newPersonnel[i] = data;
            }
          } else {
            data["id"] = resp.id;
            newPersonnel.push(data);
          }

          setOriginalSignature();
          setPersonnel(newPersonnel);
          setIsSaving(false);
          setEditValues([]);
          setIsEditMode(false);
          setShowSetupModal(false);
        },
        onDataLoadError
      );
    }
  };

  const replaceSignature = async () => {
    let patch_data = {
      signature: null,
    };
    API.query(
      "PATCH",
      `/api/newusers/${editValues.id}/`,
      {},
      patch_data,
      function (resp) {
        const files = [...signature];
        const file = files[0];
        const path = `${Math.floor(Math.random() * 100)}${file.name}`;
        let params = {
          type: "new_file",
          path: `users/${editValues.id}/signature/${path}`,
        };
        let data = {
          file: file,
        };
        API.uploadFile(
          "POST",
          `/api/files/`,
          params,
          data,
          function (resp) {
            let data = {
              signature: resp[0].id,
            };
            API.query(
              "PATCH",
              `/api/newusers/${editValues.id}/`,
              {},
              data,
              function (resp) {
                setSignature();
              },
              () => {
                setErrorMessage("Failed attaching signature to user.");
                setShowErrorModal(true);
              }
            );
          },
          (response) => props.setErrorMessage(response)
        );
      },
      onDataLoadError
    );
  };

  const uploadSignature = async (id) => {
    const files = [...signature];
    const file = files[0];
    let params = {
      type: "new_file",
      path: `users/${id}/signature/${file.name}`,
    };
    let data = {
      file: file,
    };
    API.uploadFile(
      "POST",
      `/api/files/`,
      params,
      data,
      function (resp) {
        let data = {
          signature: resp[0].id,
        };
        API.query(
          "PATCH",
          `/api/newusers/${id}/`,
          {},
          data,
          function (resp) {
            setSignature();
          },
          () => {
            setErrorMessage("Failed attaching signature to user.");
            setShowErrorModal(true);
          }
        );
      },
      (response) => props.setErrorMessage(response)
    );
  };

  const onSetupModalClosed = () => {
    setEditValues([]);
    setOriginalSignature();
    setSignature();
    setIsEditMode(false);
    setShowSetupModal(false);
  };

  const onEditClicked = (row) => {
    if (row && row.signature) {
      let params = {
        type: "render_file",
        file_id: row.signature,
      };
      setOriginalSignature(row.signature);
      API.downloadFile(
        "POST",
        `/api/files/`,
        params,
        {},
        function (resp) {
          row.user_status = row.user_status ? "true" : "false";
          row.employment_status = `${row.employment_status}`;
          row.phone_type = `${row.phone_type}`;
          if (resp) {
            row.signature_file = URL.createObjectURL(resp);
            row.signature = "";
          }
          setEditValues(row);
          setIsEditMode(true);
          setShowSetupModal(true);
        },
        () => {
          setErrorMessage("Failed fetching files.");
          setShowErrorModal(true);
        }
      );
    } else {
      row.user_status = row.user_status ? "true" : "false";
      row.employment_status = `${row.employment_status}`;
      row.phone_type = `${row.phone_type}`;
      setEditValues(row);
      setOriginalSignature();
      setIsEditMode(true);
      setShowSetupModal(true);
    }
  };

  const onDeleteClicked = (row) => {
    setEditValues(row);
    setShowConfirmationModal(true);
  };

  const onConfirmDelete = () => {
    let data = {
      deleted: true,
    };
    API.query(
      "PATCH",
      `/api/personnel/${editValues.id}/`,
      {},
      data,
      function (resp) {
        let newPersonnel = personnel.filter((j) => j.id !== editValues.id);
        setPersonnel(newPersonnel);
        setShowConfirmationModal(false);
        setEditValues([]);
      },
      onDataLoadError
    );
  };

  const onButtonPressed = (id) => {
    if (id == "clear") {
      setFilterValues([]);
    } else if (id == "create_new") {
      setShowSetupModal(true);
    } else {
    }
  };

  const filterByItself = (obj) => {
    var valid;
    if (filterValues.personnel && filterValues.personnel.length != 0) {
      if (filterValues.personnel.findIndex((f) => obj.id == f) == -1) {
        valid = false;
      } else {
        valid = true;
      }
    } else {
      valid = true;
    }
    return valid;
  };

  const filterByFacility = (obj) => {
    var i,
      x,
      valid,
      abort = false;
    if (filterValues.hospital && filterValues.hospital.length != 0) {
      if (obj && obj.facilities && obj.facilities.length != 0) {
        for (i = 0; i < filterValues.hospital.length && !abort; i++) {
          for (x = 0; x < obj.facilities.length && !abort; x++) {
            if (filterValues.hospital[i] == obj.facilities[x]) {
              valid = true;
              abort = true;
            } else {
              valid = false;
            }
          }
        }
      } else {
        valid = false;
      }
    } else {
      valid = true;
    }
    return valid;
  };

  const filterByStatus = (obj) => {
    var valid;
    if (filterValues.status == "active") {
      if (!obj.status) {
        valid = true;
      } else {
        valid = false;
      }
    } else if (filterValues.status == "inactive") {
      if (obj.status) {
        valid = true;
      } else {
        valid = false;
      }
    } else {
      valid = true;
    }
    return valid;
  };

  const filteredPersonnel = personnel.filter((obj) => {
    let valid = false;

    if (filterByFacility(obj)) {
      if (filterByItself(obj)) {
        if (filterByStatus(obj)) {
          valid = true;
        }
      }
    }
    return valid;
  });

  const listModal = (id) => {
    var i,
      selectedPersonnel,
      abort1 = false;
    for (i = 0; i < personnel.length && !abort1; i++) {
      if (personnel[i].id == id) {
        selectedPersonnel = personnel[i];
        abort1 = true;
      }
    }

    let filteredList = facilityOptions.filter((obj) => {
      var x,
        abort2 = false;
      var valid = false;
      for (x = 0; x < selectedPersonnel.facilities.length && !abort2; x++) {
        if (obj.value == selectedPersonnel.facilities[x]) {
          valid = true;
          abort2 = true;
        }
      }
      return valid;
    });

    setList(filteredList);
    setShowListModal(true);
  };

  const jobListModal = (id) => {
    var i,
      selectedPersonnel,
      abort1 = false;
    for (i = 0; i < personnel.length && !abort1; i++) {
      if (personnel[i].id == id) {
        selectedPersonnel = personnel[i];
        abort1 = true;
      }
    }

    let filteredList = jobOptions.filter((obj) => {
      var x,
        abort2 = false;
      var valid = false;
      for (x = 0; x < selectedPersonnel.jobs.length && !abort2; x++) {
        if (obj.value == selectedPersonnel.jobs[x]) {
          valid = true;
          abort2 = true;
        }
      }
      return valid;
    });

    setList(filteredList);
    setShowListModal(true);
  };

  const columns = useMemo(() => [
    { name: "First Name", selector: (row) => row.first_name, sortable: true },
    { name: "Last Name", selector: (row) => row.last_name, sortable: true },
    {
      name: "Associated Hospital",
      selector: (row) => (
        <div>
          {row.facilities && row.facilities.length != 0 ? (
            <a onClick={() => listModal(row.id)}>
              {row.facilities.length > 1
                ? row.facilities.length + " Facilities"
                : row.facilities.length + " Facility"}
            </a>
          ) : (
            <p>No Facilities</p>
          )}
        </div>
      ),
    },
    {
      name: "Role",
      selector: (row) => (
        <div>
          {row.jobs && row.jobs.length != 0 ? (
            <a onClick={() => jobListModal(row.id)}>
              {row.jobs.length > 1
                ? row.jobs.length + " Roles"
                : row.jobs.length + " Role"}
            </a>
          ) : (
            <p>No Roles</p>
          )}
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => (row.user_status ? "Active" : "Inactive"),
    },
    {
      name: (
        <div className="is-flex is-align-items-center">
          <p>Actions</p>
          <CSVLink
            filename={`users`}
            data={filteredPersonnel.map((fp) => ({
              "First Name": fp?.first_name ?? "",
              "Last Name": fp?.last_name ?? "",
              Email: fp?.email ?? "",
              Role: fp?.role?.name ?? "",
              Active: fp?.user_status ?? "",
            }))}
          >
            <button className="ml-3 is-clickable">
              <FaFileExcel size={18} title="CSV Download" />
            </button>
          </CSVLink>
        </div>
      ),
      selector: (row) => (
        <div>
          <a title="Edit" onClick={() => onEditClicked(row)}>
            <FaPencilAlt size={14} />
          </a>
          <a
            className="ml-3"
            title="Delete"
            onClick={() => onDeleteClicked(row)}
          >
            <FaTrashAlt size={14} />
          </a>
        </div>
      ),
    },
  ]);

  useEffect(() => {
    let queries = [
      { method: "GET", path: "/api/users/" },
      { method: "GET", path: "/api/facilities/" },
      { method: "GET", path: "/api/viewpermissions/" },
      { method: "GET", path: "/api/jobs/" },
      { method: "GET", path: "/api/roles/" },
    ];
    API.all(
      queries,
      function (resp) {
        setPersonnel(resp[0]);
        setPersonnelOptions(
          resp[0].map((i) => ({
            label: i.first_name + " " + i.last_name,
            value: i.id,
          }))
        );
        setFacilityOptions(
          resp[1].map((i) => ({ label: i.name, value: i.id }))
        );
        setPermissionOptions(
          resp[2].map((i) => ({ label: i.name, value: i.id }))
        );
        setJobOptions(resp[3].map((i) => ({ label: i.name, value: i.id })));
        setRoleOptions(resp[4].map((i) => ({ label: i.name, value: i.id })));
        if (props.id != null) {
          let option = resp[0].find((i) => i.id == props.id);
          setEditValues(option);
          setShowSetupModal(true);
          setIsEditMode(true);
        }
      },
      onDataLoadError
    );
  }, []);

  const onDataLoadError = (error) => {
    setErrorMessage(error);
    setShowErrorModal(true);
    setIsSaving(false);
  };

  const onListClosed = () => {
    setShowListModal(false);
    setList([]);
  };

  const visited = () => {
    // get pageviewhistory id from user session data
    var user = Session.get("user");
    API.query(
      "GET",
      `/api/pageviewhistory/${user.page_view_history}/`,
      {},
      {},
      function (response) {
        let data = {
          setup_personnel: response.setup_personnel + 1,
        };
        API.query(
          "PATCH",
          `/api/pageviewhistory/${user.page_view_history}/`,
          {},
          data,
          function (resp) {},
          onDataLoadError
        );
      },
      onDataLoadError
    );
  };

  return (
    <div>
      <p className="title">User Setup</p>
      <hr />
      <DynamicForm
        form={userSearch}
        values={filterValues}
        onValuesChanged={onFilterChange}
        onButtonPressed={onButtonPressed}
        source={{ facilities: facilityOptions, personnel: personnelOptions }}
      />
      <hr />

      {showSetupModal && (
        <SetupModal
          titleString={"User Setup"}
          form={userModal}
          editValues={editValues}
          onEditChange={onEditChange}
          source={{
            facilities: facilityOptions,
            roles: roleOptions,
            viewpermissions: permissionOptions,
            jobs: jobOptions,
          }}
          isSaving={isSaving}
          onSave={onSave}
          onClose={onSetupModalClosed}
        />
      )}

      {showErrorModal && (
        <ErrorModal
          message={errorMessage}
          onClose={() => setShowErrorModal(false)}
        />
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          message={
            "You are about to delete a Personnel. This cannot be undone."
          }
          onConfirm={onConfirmDelete}
          onClose={() => setShowConfirmationModal(false)}
        />
      )}

      {showListModal && (
        <ListModal string={"Facilities"} list={list} onClose={onListClosed} />
      )}

      <p className="title is-5">Results</p>
      <DataTable
        striped
        pagination
        data={filteredPersonnel}
        columns={columns}
      />

      <VisitTimer visited={visited} />
    </div>
  );
}

export default UserSetup;
