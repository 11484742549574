import React, { useState } from 'react';

function ConfirmationModal(props) {
    const {message,onConfirm,onClose} = props

    return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">Are you sure?</p>
        <button className="delete" aria-label="close" onClick={onClose}></button>
      </header>
      <section className="modal-card-body">
        <div>{message}</div>
      </section>
      <footer className="modal-card-foot is-flex is-justify-content-end">
        <button className="button is-danger" onClick={onConfirm}>Confirm</button>
        <button className="button" onClick={onClose}>Cancel</button>
      </footer>
    </div>
  </div>
}

export default ConfirmationModal;