import React, { Component } from "react";

class Dropdown extends Component {
    state = {
        isOpen: false
    }

    node = null

    toggleDropdown = () => {
        this.setState({isOpen:!this.state.isOpen})
    }

    closeDropdown = () => {
        this.setState({isOpen:false})
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false)
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false)
    }
    handleClick = (e) => {
        let el = e.target

        // Don't close if in tour
        // modal || next button || background
        if(el.classList.contains("reactour__helper") || el.classList.contains('tour-next') || el.nodeName == 'rect'){
            return
        }

        if(!this.node.contains(el)){
            this.closeDropdown()
        }
    }

    render() {
        let {isOpen} = this.state
        let {children} = this.props

        let link = children[0]
        let dropdown = children[1]

        return <div className={this.props.className+(isOpen?" is-active":"")} style={this.props.style} ref={node => this.node = node}>
            <link.type {...link.props} onClick={this.toggleDropdown}>{link.props.children}</link.type>
            <dropdown.type {...dropdown.props}>{dropdown.props.children}</dropdown.type>
        </div>
    }
}

export default Dropdown