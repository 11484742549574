import React, { useEffect, useState, useMemo } from "react"
import DataTable from 'react-data-table-component';
import Session from "../../../../services/Session";
import IDidThisModal from "../../../components/IDidThisModal"
import API from "../../../../services/API";
import { FaPencilAlt, FaExpandArrowsAlt } from "react-icons/fa";

function IDidThis(props) {
    const [iDidThisObjs, setIDidThisObjs] = useState([])
    const [selected, setSelected] = useState()
    const [isSaving, setIsSaving] = useState(false)
    const [disabled, setDisabled] = useState()

    useEffect(() => {
        let user = Session.get('user')
        let params = {
            'user': user.id
        }
        API.query('GET',`/api/ididthis/`,params,{},function(resp){
            setIDidThisObjs(resp)
        })
    }, []);

    const onEdit = (obj) => {
        setSelected(obj)
        setDisabled(false)
    }

    const onView = (obj) => {
        setSelected(obj)
        setDisabled(true)
    }

    const onValuesChanged = (value) => {
        setSelected(value)
    }

    const columns = useMemo(() => [
        {name:'IDidThis Date',selector: row => row.date_created, sortable: true},
        {name:'Submitted',selector: row => row.submitted ? "True" : "False", sortable: true},
        {name:'',width:"80px",selector: row => row.submitted ? 
        <a onClick={() => onView(row)}><FaExpandArrowsAlt /></a>
        :
        <a onClick={() => onEdit(row)}><FaPencilAlt /></a>
        }
    ])

    const onSave = () => {
        if(!isSaving) {
            setIsSaving(true)
            let data = {
                'cpb_case_qty': selected.cpb_case_qty,
                'cpb_reg_hours': selected.cpb_reg_hours,
                'cpb_oc_hours': selected.cpb_oc_hours,
                'cpb_patients': selected.cpb_patients,
                'op_cpb_case_qty': selected.op_cpb_case_qty,
                'op_cpb_reg_hours': selected.op_cpb_reg_hours,
                'op_cpb_oc_hours': selected.op_cpb_oc_hours,
                'op_cpb_patients': selected.op_cpb_patients,
                'iabs_case_qty': selected.iabs_case_qty,
                'iabs_reg_hours': selected.iabs_reg_hours,
                'iabs_oc_hours': selected.iabs_oc_hours,
                'iabs_patients': selected.iabs_patients,
                'stnd_case_qty': selected.stnd_case_qty,
                'stnd_reg_hours': selected.stnd_reg_hours,
                'stnd_oc_hours': selected.stnd_oc_hours,
                'stnd_patients': selected.stnd_patients,
                'ats_cpb_case_qty': selected.ats_cpb_case_qty,
                'ats_cpb_reg_hours': selected.ats_cpb_reg_hours,
                'ats_cpb_oc_hours': selected.ats_cpb_oc_hours,
                'ats_cpb_patients': selected.ats_cpb_patients,
                'ats_case_qty': selected.ats_case_qty,
                'ats_reg_hours': selected.ats_reg_hours,
                'ats_oc_hours': selected.ats_oc_hours,
                'ats_patients': selected.ats_patients,
                'atsh_case_qty': selected.atsh_case_qty,
                'atsh_reg_hours': selected.atsh_reg_hours,
                'atsh_oc_hours': selected.atsh_oc_hours,
                'atsh_patients': selected.atsh_patients,
                'atsp_case_qty': selected.atsp_case_qty,
                'atsp_reg_hours': selected.atsp_reg_hours,
                'atsp_oc_hours': selected.atsp_oc_hours,
                'atsp_patients': selected.atsp_patients,
                'prp_case_qty': selected.prp_case_qty,
                'prp_reg_hours': selected.prp_reg_hours,
                'prp_oc_hours': selected.prp_oc_hours,
                'prp_patients': selected.prp_patients,
                'bma_case_qty': selected.bma_case_qty,
                'bma_reg_hours': selected.bma_reg_hours,
                'bma_oc_hours': selected.bma_oc_hours,
                'bma_patients': selected.bma_patients,
                'ecmo_case_qty': selected.ecmo_case_qty,
                'ecmo_reg_hours': selected.ecmo_reg_hours,
                'ecmo_oc_hours': selected.ecmo_oc_hours,
                'ecmo_patients': selected.ecmo_patients,
                'ecmom_case_qty': selected.ecmom_case_qty,
                'ecmom_reg_hours': selected.ecmom_reg_hours,
                'ecmom_oc_hours': selected.ecmom_oc_hours,
                'ecmom_patients': selected.ecmom_patients,
                'ecmoms_case_qty': selected.ecmoms_case_qty,
                'ecmoms_reg_hours': selected.ecmoms_reg_hours,
                'ecmoms_oc_hours': selected.ecmoms_oc_hours,
                'ecmoms_patients': selected.ecmoms_patients,
                'ecmotp_case_qty': selected.ecmotp_case_qty,
                'ecmotp_reg_hours': selected.ecmotp_reg_hours,
                'ecmotp_oc_hours': selected.ecmotp_oc_hours,
                'ecmotp_patients': selected.ecmotp_patients,
                'ecmocp_case_qty': selected.ecmocp_case_qty,
                'ecmocp_reg_hours': selected.ecmocp_reg_hours,
                'ecmocp_oc_hours': selected.ecmocp_oc_hours,
                'ecmocp_patients': selected.ecmocp_patients,
                'ecmocs_case_qty': selected.ecmocs_case_qty,
                'ecmocs_reg_hours': selected.ecmocs_reg_hours,
                'ecmocs_oc_hours': selected.ecmocs_oc_hours,
                'ecmocs_patients': selected.ecmocs_patients,
                'vad_case_qty': selected.vad_case_qty,
                'vad_reg_hours': selected.vad_reg_hours,
                'vad_oc_hours': selected.vad_oc_hours,
                'vad_patients': selected.vad_patients,
                'vadm_case_qty': selected.vadm_case_qty,
                'vadm_reg_hours': selected.vadm_reg_hours,
                'vadm_oc_hours': selected.vadm_oc_hours,
                'vadm_patients': selected.vadm_patients,
                'tps_case_qty': selected.tps_case_qty,
                'tps_reg_hours': selected.tps_reg_hours,
                'tps_oc_hours': selected.tps_oc_hours,
                'tps_patients': selected.tps_patients,
                'tpse_case_qty': selected.tpse_case_qty,
                'tpse_reg_hours': selected.tpse_reg_hours,
                'tpse_oc_hours': selected.tpse_oc_hours,
                'tpse_patients': selected.tpse_patients,
                'pocsvc_case_qty': selected.pocsvc_case_qty,
                'pocsvc_reg_hours': selected.pocsvc_reg_hours,
                'pocsvc_oc_hours': selected.pocsvc_oc_hours,
                'pocsvc_patients': selected.pocsvc_patients,
                'pocsvc_non_case_qty': selected.pocsvc_non_case_qty,
                'pocsvc_non_reg_hours': selected.pocsvc_non_reg_hours,
                'pocsvc_non_oc_hours': selected.pocsvc_non_oc_hours,
                'pocsvc_non_patients': selected.pocsvc_non_patients,
                'travel_case_qty': selected.travel_case_qty,
                'travel_reg_hours': selected.travel_reg_hours,
                'travel_oc_hours': selected.travel_oc_hours,
                'travel_patients': selected.travel_patients,
                'oc1_case_qty': selected.oc1_case_qty,
                'oc1_reg_hours': selected.oc1_reg_hours,
                'oc1_oc_hours': selected.oc1_oc_hours,
                'oc1_patients': selected.oc1_patients,
                'oc2_case_qty': selected.oc2_case_qty,
                'oc2_reg_hours': selected.oc2_reg_hours,
                'oc2_oc_hours': selected.oc2_oc_hours,
                'oc2_patients': selected.oc2_patients,
                'oc3_case_qty': selected.oc3_case_qty,
                'oc3_reg_hours': selected.oc3_reg_hours,
                'oc3_oc_hours': selected.oc3_oc_hours,
                'oc3_patients': selected.oc3_patients,
                'admin_case_qty': selected.admin_case_qty,
                'admin_reg_hours': selected.admin_reg_hours,
                'admin_oc_hours': selected.admin_oc_hours,
                'admin_patients': selected.admin_patients,
                'pto_case_qty': selected.pto_case_qty,
                'pto_reg_hours': selected.pto_reg_hours,
                'pto_oc_hours': selected.pto_oc_hours,
                'pto_patients': selected.pto_patients,
                'off_case_qty': selected.off_case_qty,
                'off_reg_hours': selected.off_reg_hours,
                'off_oc_hours': selected.off_oc_hours,
                'off_patients': selected.off_patients
            }
            API.query('PATCH',`/api/ididthis/${selected.id}/`,{},data,function(resp) {
                let ididthis = [...iDidThisObjs]
                let index = ididthis.findIndex((obj) => obj.id == resp.id)
                if(index > -1) {
                    ididthis[index] = resp
                    setIDidThisObjs(ididthis)
                }
                setSelected()
                setIsSaving(false)
                setDisabled()
            },onDataLoadError())
        }
    }

    const onSubmit = () => {
        if(!isSaving) {
            setIsSaving(true)
            let date = new Date().toLocaleString().split(',')[0]
            let data = {
                'submitted': true,
                'date_submitted': date,
                'cpb_case_qty': selected.cpb_case_qty,
                'cpb_reg_hours': selected.cpb_reg_hours,
                'cpb_oc_hours': selected.cpb_oc_hours,
                'cpb_patients': selected.cpb_patients,
                'op_cpb_case_qty': selected.op_cpb_case_qty,
                'op_cpb_reg_hours': selected.op_cpb_reg_hours,
                'op_cpb_oc_hours': selected.op_cpb_oc_hours,
                'op_cpb_patients': selected.op_cpb_patients,
                'iabs_case_qty': selected.iabs_case_qty,
                'iabs_reg_hours': selected.iabs_reg_hours,
                'iabs_oc_hours': selected.iabs_oc_hours,
                'iabs_patients': selected.iabs_patients,
                'stnd_case_qty': selected.stnd_case_qty,
                'stnd_reg_hours': selected.stnd_reg_hours,
                'stnd_oc_hours': selected.stnd_oc_hours,
                'stnd_patients': selected.stnd_patients,
                'ats_cpb_case_qty': selected.ats_cpb_case_qty,
                'ats_cpb_reg_hours': selected.ats_cpb_reg_hours,
                'ats_cpb_oc_hours': selected.ats_cpb_oc_hours,
                'ats_cpb_patients': selected.ats_cpb_patients,
                'ats_case_qty': selected.ats_case_qty,
                'ats_reg_hours': selected.ats_reg_hours,
                'ats_oc_hours': selected.ats_oc_hours,
                'ats_patients': selected.ats_patients,
                'atsh_case_qty': selected.atsh_case_qty,
                'atsh_reg_hours': selected.atsh_reg_hours,
                'atsh_oc_hours': selected.atsh_oc_hours,
                'atsh_patients': selected.atsh_patients,
                'atsp_case_qty': selected.atsp_case_qty,
                'atsp_reg_hours': selected.atsp_reg_hours,
                'atsp_oc_hours': selected.atsp_oc_hours,
                'atsp_patients': selected.atsp_patients,
                'prp_case_qty': selected.prp_case_qty,
                'prp_reg_hours': selected.prp_reg_hours,
                'prp_oc_hours': selected.prp_oc_hours,
                'prp_patients': selected.prp_patients,
                'bma_case_qty': selected.bma_case_qty,
                'bma_reg_hours': selected.bma_reg_hours,
                'bma_oc_hours': selected.bma_oc_hours,
                'bma_patients': selected.bma_patients,
                'ecmo_case_qty': selected.ecmo_case_qty,
                'ecmo_reg_hours': selected.ecmo_reg_hours,
                'ecmo_oc_hours': selected.ecmo_oc_hours,
                'ecmo_patients': selected.ecmo_patients,
                'ecmom_case_qty': selected.ecmom_case_qty,
                'ecmom_reg_hours': selected.ecmom_reg_hours,
                'ecmom_oc_hours': selected.ecmom_oc_hours,
                'ecmom_patients': selected.ecmom_patients,
                'ecmoms_case_qty': selected.ecmoms_case_qty,
                'ecmoms_reg_hours': selected.ecmoms_reg_hours,
                'ecmoms_oc_hours': selected.ecmoms_oc_hours,
                'ecmoms_patients': selected.ecmoms_patients,
                'ecmotp_case_qty': selected.ecmotp_case_qty,
                'ecmotp_reg_hours': selected.ecmotp_reg_hours,
                'ecmotp_oc_hours': selected.ecmotp_oc_hours,
                'ecmotp_patients': selected.ecmotp_patients,
                'ecmocp_case_qty': selected.ecmocp_case_qty,
                'ecmocp_reg_hours': selected.ecmocp_reg_hours,
                'ecmocp_oc_hours': selected.ecmocp_oc_hours,
                'ecmocp_patients': selected.ecmocp_patients,
                'ecmocs_case_qty': selected.ecmocs_case_qty,
                'ecmocs_reg_hours': selected.ecmocs_reg_hours,
                'ecmocs_oc_hours': selected.ecmocs_oc_hours,
                'ecmocs_patients': selected.ecmocs_patients,
                'vad_case_qty': selected.vad_case_qty,
                'vad_reg_hours': selected.vad_reg_hours,
                'vad_oc_hours': selected.vad_oc_hours,
                'vad_patients': selected.vad_patients,
                'vadm_case_qty': selected.vadm_case_qty,
                'vadm_reg_hours': selected.vadm_reg_hours,
                'vadm_oc_hours': selected.vadm_oc_hours,
                'vadm_patients': selected.vadm_patients,
                'tps_case_qty': selected.tps_case_qty,
                'tps_reg_hours': selected.tps_reg_hours,
                'tps_oc_hours': selected.tps_oc_hours,
                'tps_patients': selected.tps_patients,
                'tpse_case_qty': selected.tpse_case_qty,
                'tpse_reg_hours': selected.tpse_reg_hours,
                'tpse_oc_hours': selected.tpse_oc_hours,
                'tpse_patients': selected.tpse_patients,
                'pocsvc_case_qty': selected.pocsvc_case_qty,
                'pocsvc_reg_hours': selected.pocsvc_reg_hours,
                'pocsvc_oc_hours': selected.pocsvc_oc_hours,
                'pocsvc_patients': selected.pocsvc_patients,
                'pocsvc_non_case_qty': selected.pocsvc_non_case_qty,
                'pocsvc_non_reg_hours': selected.pocsvc_non_reg_hours,
                'pocsvc_non_oc_hours': selected.pocsvc_non_oc_hours,
                'pocsvc_non_patients': selected.pocsvc_non_patients,
                'travel_case_qty': selected.travel_case_qty,
                'travel_reg_hours': selected.travel_reg_hours,
                'travel_oc_hours': selected.travel_oc_hours,
                'travel_patients': selected.travel_patients,
                'oc1_case_qty': selected.oc1_case_qty,
                'oc1_reg_hours': selected.oc1_reg_hours,
                'oc1_oc_hours': selected.oc1_oc_hours,
                'oc1_patients': selected.oc1_patients,
                'oc2_case_qty': selected.oc2_case_qty,
                'oc2_reg_hours': selected.oc2_reg_hours,
                'oc2_oc_hours': selected.oc2_oc_hours,
                'oc2_patients': selected.oc2_patients,
                'oc3_case_qty': selected.oc3_case_qty,
                'oc3_reg_hours': selected.oc3_reg_hours,
                'oc3_oc_hours': selected.oc3_oc_hours,
                'oc3_patients': selected.oc3_patients,
                'admin_case_qty': selected.admin_case_qty,
                'admin_reg_hours': selected.admin_reg_hours,
                'admin_oc_hours': selected.admin_oc_hours,
                'admin_patients': selected.admin_patients,
                'pto_case_qty': selected.pto_case_qty,
                'pto_reg_hours': selected.pto_reg_hours,
                'pto_oc_hours': selected.pto_oc_hours,
                'pto_patients': selected.pto_patients,
                'off_case_qty': selected.off_case_qty,
                'off_reg_hours': selected.off_reg_hours,
                'off_oc_hours': selected.off_oc_hours,
                'off_patients': selected.off_patients
            }
            API.query('PATCH',`/api/ididthis/${selected.id}/`,{},data,function(resp) {
                let ididthis = [...iDidThisObjs]
                let index = ididthis.findIndex((obj) => obj.id == resp.id)
                if(index > -1) {
                    ididthis[index] = resp
                    setIDidThisObjs(ididthis)
                }
                setSelected()
                setIsSaving(false)
                setDisabled()
            },onDataLoadError())
        }
    }

    const onDataLoadError = (error) => {
        console.log(error)
    }

    const onClose = () => {
        setSelected()
        setDisabled()
    }

    return <div>
        <p className="title is-size-2">IDidThis</p>
        <p>The daily summary of all that you did &#40;almost&#41; on OnCloud.</p>
        <hr />

        <div className="columns">
            <div className="column is-4">
                <p className="title is-size-5">Daily Summary Data</p>
                <p>View past logs or edit current ones.</p>
            </div>
            <div className="column is-8">
                <DataTable 
                    data={iDidThisObjs}
                    columns={columns}
                    striped
                    pagination
                />
            </div>
        </div>

        {
            selected != null
            &&
            <IDidThisModal 
                data={selected}
                onChange={onValuesChanged}
                onSave={onSave}
                onSubmit={onSubmit}
                onClose={onClose}
                isSaving={isSaving}
                disabled={disabled}
            />
        }
    </div>
}

export default IDidThis