import React, { useEffect, useState } from "react";
import {
  FaArrowCircleRight,
  FaBell,
  FaCalendarAlt,
  FaRegClock,
  FaUserCircle,
} from "react-icons/fa";
import SampleChart from "../components/SampleChart";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ReportChart from "../components/ReportChart";
import API from "../../services/API";
import Session from "../../services/Session";

function Home(props) {
  const addDays = (date, days) => {
    date = new Date(date); // copy date
    date.setDate(date.getDate() + days);
    return date;
  };

  const [chartFilters, setChartFilters] = useState({
    time: 0,
    chart: 0,
    start_date: addDays(new Date(), -30),
    end_date: new Date(),
  });
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [cases, setCases] = useState([]);
  const [reminders, setReminders] = useState([]);

  const chartOptions = [
    { label: "Procedure Counts", value: 0 },
    { label: "Cases Submitted", value: 1 },
    { label: "Avg Procedures per Case", value: 2 },
    { label: "Quality Control - Cases", value: 3 },
  ];

  const chartTimeOptions = [
    { label: "Year-To-Date", value: 0 },
    { label: "Month-To-Date", value: 1 },
    { label: "Last Month", value: 2 },
    { label: "Custom", value: 3 },
  ];

  const filteredChartCases = cases.filter((obj) => {
    var valid = false;
    let date = new Date();
    let caseDate = new Date(obj.date);
    if (obj.status == "Submitted") {
      if (chartFilters.time == 0) {
        //year-to-date
        if (chartFilters.facility == null) {
          if (date.getFullYear() == caseDate.getFullYear()) valid = true;
        } else {
          if (chartFilters.facility == obj.facility_id) {
            if (date.getFullYear() == caseDate.getFullYear()) valid = true;
          }
        }
      } else if (chartFilters.time == 1) {
        //month-to-date
        if (chartFilters.facility == null) {
          if (date.getMonth() == caseDate.getMonth()) {
            if (date.getFullYear() == caseDate.getFullYear()) valid = true;
          }
        } else {
          if (chartFilters.facility == obj.facility_id) {
            if (date.getMonth() == caseDate.getMonth()) {
              if (date.getFullYear() == caseDate.getFullYear()) valid = true;
            }
          }
        }
      } else if (chartFilters.time == 2) {
        //last month
        if (chartFilters.facility == null) {
          if (date.getMonth() == 0) {
            let lastMonth = 11;
            let lastYear = date.getFullYear() - 1;
            if (lastMonth == caseDate.getMonth()) {
              if (lastYear == caseDate.getFullYear()) valid = true;
            }
          } else {
            let lastMonth = date.getMonth() - 1;
            if (lastMonth == caseDate.getMonth()) {
              if (date.getFullYear() == caseDate.getFullYear()) valid = true;
            }
          }
        } else {
          if (chartFilters.facility == obj.facility_id) {
            if (date.getMonth() == 0) {
              let lastMonth = 11;
              let lastYear = date.getFullYear() - 1;
              if (lastMonth == caseDate.getMonth()) {
                if (lastYear == caseDate.getFullYear()) valid = true;
              }
            } else {
              let lastMonth = date.getMonth() - 1;
              if (lastMonth == caseDate.getMonth()) {
                if (date.getFullYear() == caseDate.getFullYear()) {
                  valid = true;
                }
              }
            }
          }
        }
      } else if (chartFilters.time == 3) {
        //custom range
        if (chartFilters.facility == null) {
          let startDate = addDays(chartFilters.start_date, -1);
          let endDate = new Date(chartFilters.end_date);
          if (caseDate >= startDate) {
            if (caseDate <= endDate) valid = true;
          }
        } else {
          if (chartFilters.facility == obj.facility_id) {
            let startDate = new Date(chartFilters.start_date);
            let endDate = new Date(chartFilters.end_date);
            if (caseDate >= startDate) {
              if (caseDate <= endDate) valid = true;
            }
          }
        }
      }
    }
    return valid;
  });

  const onChartFilterChanged = (value, type) => {
    let fValues = { ...chartFilters };
    if (type === 0) {
      fValues["chart"] = value;
    } else if (type === 1) {
      fValues["time"] = value;
    } else if (type === 2) {
      fValues["facility"] = value;
    } else if (type === 3) {
      fValues["start_date"] = value;
    } else if (type === 4) {
      fValues["end_date"] = value;
    }
    setChartFilters(fValues);
  };

  const onDataLoadError = (error) => {
    setErrorMessage(error);
    setShowErrorModal(true);
    setIsSaving(false);
  };

  //   useEffect(() => {
  //     let queries = [
  //       { 'method': 'GET', 'path': '/api/facilities/', },
  //       { 'method': 'GET', 'path': '/api/visualcasereports/', },
  //       { 'method': 'GET', 'path': '/api/procedures/', },
  //     ]
  //     API.all(queries, function (resp) {
  //       if(props.permissions.includes(7) || props.extraPermissions.includes(7)) {
  //         let user = Session.get('user')
  //         let filteredFacils = resp[0].filter((obj) => {
  //           var i, abort=false, valid=false
  //           for(i=0; i < user.facilities.length && !abort; i++) {
  //             if(user.facilities[i] == obj.id) {
  //               valid = true
  //               abort = true
  //             }
  //           }
  //           return valid
  //         })

  //         let filteredCases = resp[1].filter((obj) => {
  //           var i, abort=false, valid=false
  //           for(i=0; i < user.facilities.length && !abort; i++) {
  //             if(obj.facility_id == user.facilities[i]) {
  //               valid = true
  //               abort = true
  //             }
  //           }
  //           return valid
  //         })

  //         setCases(filteredCases)
  //         setFacilityOptions(filteredFacils.map(i => ({ "label": i.name, "value": i.id })))
  //       } else {
  //         setFacilityOptions(resp[0].map(i => ({ "label": i.name, "value": i.id })))
  //         setCases(resp[1])
  //       }
  //       setProcedures(resp[2])
  //     }, onDataLoadError)
  //   }, []);

  // const renderProgramCounts = () => {
  //   return <div className="box">
  //     <p className="title is-5">Program Counts</p>
  //     <SampleChart height={300} type="line"/>
  //   </div>
  // }

  // const renderStudyDataSnapshot = () => {
  //   return <div className="box">
  //     <p className="title is-5">Study Data Snapshot</p>
  //     <SampleChart height={200} type="pie"/>
  //   </div>
  // }

  // const renderQualityControlSnapshot = () => {
  //   return <div className="box">
  //     <p className="title is-5">Quality Control Snapshot</p>
  //     <SampleChart height={200} type="bar"/>
  //   </div>
  // }

  const renderCharts = () => {
    return (
      <div>
        <div className="box">
          <div className="level w-100">
            <div className="level-left">
              <div style={{ minWidth: "220px", maxWidth: "220px" }}>
                <Select
                  options={chartOptions}
                  placeholder={""}
                  value={chartOptions.find(
                    (e) => e.value == chartFilters.chart
                  )}
                  onChange={(option) => onChartFilterChanged(option.value, 0)}
                />
              </div>
            </div>
            <div className="level-right">
              <p style={{ marginRight: "12px" }}>Filter Reports:</p>
              <div
                style={{
                  marginRight: "12px",
                  minWidth: "180px",
                  maxWidth: "180px",
                }}
              >
                <Select
                  options={chartTimeOptions}
                  placeholder={""}
                  value={chartTimeOptions.find(
                    (e) => e.value == chartFilters.time
                  )}
                  onChange={(option) => onChartFilterChanged(option.value, 1)}
                />
              </div>
              <div style={{ maxWidth: "280px", minWidth: "280px" }}>
                <Select
                  options={facilityOptions}
                  placeholder={"All Facilities"}
                  value={facilityOptions.find(
                    (e) => e.value == chartFilters.facility
                  )}
                  onChange={(option) => onChartFilterChanged(option.value, 2)}
                />
              </div>
            </div>
          </div>

          {chartFilters.time == 3 && (
            <div>
              <div className="level w-100">
                <div className="level-left"></div>
                <div className="level-right">
                  <div style={{ marginRight: "12px", minWidth: "220px" }}>
                    <DatePicker
                      className="input"
                      selectsStart
                      startDate={chartFilters.start_date}
                      endDate={chartFilters.end_date}
                      selected={chartFilters.start_date}
                      onChange={(date) => onChartFilterChanged(date, 3)}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      maxDate={new Date()}
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                  <div style={{ minWidth: "220px" }}>
                    <DatePicker
                      className="input"
                      selectsStart
                      startDate={chartFilters.start_date}
                      endDate={chartFilters.end_date}
                      selected={chartFilters.end_date}
                      onChange={(date) => onChartFilterChanged(date, 4)}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      maxDate={new Date()}
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <ReportChart
            height={350}
            procedures={procedures}
            filters={chartFilters}
            data={filteredChartCases}
          />
        </div>
      </div>
    );
  };

  const renderReminders = () => {
    return (
      <div className="box">
        <p className="title is-5 mb-3">Reminders</p>

        {reminders.length ? (
          <div>
            <p className="is-size-7 mb-3">Today</p>
            {[
              {
                name: "Craig Spilker",
                event: "Blood Transfusion",
                icon: FaUserCircle,
                date: "09/14/2022",
                time: "2:20pm",
              },
              {
                name: "Heater Cooler",
                event: "Cardioquip MCH-10",
                icon: FaBell,
                date: "09/14/2022",
                time: "4:30pm",
              },
            ].map((reminder, i) => (
              <a
                key={i}
                className={
                  "box " +
                  (i === 0
                    ? "has-background-primary has-text-white"
                    : "has-background-primary-light")
                }
              >
                {/* Top */}
                <div className="is-flex is-align-items-center is-justify-content-space-between mb-3">
                  {/* Left */}
                  <div className="is-flex is-align-items-center">
                    <FaUserCircle className="mr-3" size={50} />
                    <div>
                      <p>{reminder.name}</p>
                      <p className="is-size-7">{reminder.event}</p>
                    </div>
                  </div>

                  {/* Right */}
                  <FaArrowCircleRight size={30} />
                </div>

                {/* Bottom */}
                <p>
                  <FaCalendarAlt size={12} /> {reminder.date} at{" "}
                  <FaRegClock size={12} /> {reminder.time}
                </p>
              </a>
            ))}
          </div>
        ) : (
          <p>No reminders yet</p>
        )}
      </div>
    );
  };

  return (
    <div className="columns">
      {/* Left */}
      <div className="column is-8">
        {/* {renderCharts()} */}

        {/* <div className="columns">
        <div className="column">
          {renderStudyDataSnapshot()}
        </div>
        <div className="column">
          {renderQualityControlSnapshot()}
        </div>
      </div> */}
      </div>

      {/* Right */}
      <div className="column is-4">{renderReminders()}</div>
    </div>
  );
}

export default Home;
