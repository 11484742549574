import React, { useEffect, useState, useMemo, useRef } from "react";
import DynamicForm from "./DynamicForm";
import API from "../../services/API";
import DataTable from "react-data-table-component";
import { FaTrashAlt } from "react-icons/fa";
import ConfirmationModal from "./ConfirmationModal";
import ErrorModal from "./ErrorModal";

import PerfusionTopForm from "../forms/caseDetailsPerfusionTop";
import PerfusionCardiacForm from "../forms/caseDetailsPerfusionCardiac";
import PerfusionPulmonaryForm from "../forms/caseDetailsPerfusionPulmonary";
import BypassCountForm from "../forms/caseDetailsBypassCount";
import autoNoteForm from "../forms/caseDetailsAutoNoteCheckbox";
import LabForm from "../forms/caseDetailsLabPOC";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

function DetailPerfusion(props) {
  const [perfusionTopValues, setPerfusionTopValues] = useState([]);
  const [perfusionBypasses, setPerfusionBypasses] = useState([]);
  const [perfusionCardiacValues, setPerfusionCardiacValues] = useState([]);
  const [perfusionPulmonaryValues, setPerfusionPulmonaryValues] = useState([]);
  const [perfusionVascularValues, setPerfusionVascularValues] = useState([]);
  const [perfusionCathValues, setPerfusionCathValues] = useState([]);
  const [perfusionHepaticValues, setPerfusionHepaticValues] = useState([]);
  const [perfusionAutoValues, setPerfusionAutoValues] = useState([]);
  const [perfusionEcmoValues, setPerfusionEcmoValues] = useState([]);
  const [perfusionOtherValues, setPerfusionOtherValues] = useState([]);
  const [perfusionNoteValues, setPerfusionNoteValues] = useState([]);
  const [perfusionDetail, setPerfusionDetail] = useState([]);
  const [
    showPerfusionGraftConfirmationModal,
    setShowPerfusionGraftConfirmationModal,
  ] = useState(false);
  const [perfBypassCountValues, setPerfBypassCountValues] = useState([]);
  const [allPerfusionBypassValues, setAllPerfusionBypassValues] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [deleteValues, setDeleteValues] = useState([]);

  const perfTopRef = useRef();
  const perfCardiacRef = useRef();
  const perfPulmRef = useRef();
  const perfBypassRef = useRef();

  let saveBtnClasses = "button is-success";
  if (isSaving) saveBtnClasses += " is-loading";

  const graftOptions = [
    { label: "Right Coronary Artery", value: "Right Coronary Artery" },
    { label: "Acute Marginal", value: "Acute Marginal" },
    {
      label: "Posterior Descending Artery",
      value: "Posterior Descending Artery",
    },
    { label: "Posterolateral Branch", value: "Posterolateral Branch" },
    { label: "LAD (Proximal)", value: "LAD (Proximal)" },
    { label: "LAD (Mid)", value: "LAD (Mid)" },
    { label: "LAD (Distal)", value: "LAD (Distal)" },
    { label: "Circumflex", value: "Circumflex" },
    { label: "Diagonal 1", value: "Diagonal 1" },
    { label: "Diagonal 2", value: "Diagonal 2" },
    { label: "Ramus", value: "Ramus" },
    { label: "Obtuse Marginal 1", value: "Obtuse Marginal 1" },
    { label: "Obtuse Marginal 2", value: "Obtuse Marginal 2" },
    { label: "Obtuse Marginal 3", value: "Obtuse Marginal 3" },
  ];

  const conduitOptions = [
    { label: "Vein Graft", value: "Vein Graft" },
    { label: "In Situ LIMA", value: "In Situ LIMA" },
    { label: "In Situ RIMA", value: "In Situ RIMA" },
    { label: "Free IMA", value: "Free IMA" },
    { label: "Radial Artery", value: "Radial Artery" },
    { label: "Other", value: "Other" },
  ];

  useEffect(() => {
    API.query(
      "GET",
      `/api/perfusiondetails/${props.detailID}/`,
      {},
      {},
      function (resp) {
        setPerfusionDetail(resp);
        setPerfusionTopValues({
          start_date: resp.start_date,
          end_date: resp.end_date,
          start_time: resp.start_time,
          end_time: resp.end_time,
          or_number: resp.or_number,
          classification: resp.classification,
          re_operation: resp.re_operation ? "true" : "false",
          operative_approach: resp.operative_approach,
          robotic_assisted: resp.robotic_assisted ? "true" : "false",
        });
        setPerfusionCardiacValues({
          pericardial_window: resp.pericardial_window,
          re_exploration: resp.re_exploration,
          lead_insertion: resp.lead_insertion,
          other_trauma: resp.other_trauma,
          atrial: resp.atrial,
          tavr: resp.tavr,
          tmvr: resp.tmvr,
          mitral_clip: resp.mitral_clip,
          vad_insertion: resp.vad_insertion,
          vad_removal: resp.vad_removal,
          ecmo_insertion: resp.ecmo_insertion,
          ecmo_removal: resp.ecmo_removal,
          cardiac_other: resp.cardiac_other,
          cardiac_other_type: resp.cardiac_other_type,
        });
        setPerfusionPulmonaryValues({
          transplant: resp.transplant,
          embolectomy: resp.embolectomy,
          pulmonary_other: resp.pulmonary_other,
          pulmonary_other_type: resp.pulmonary_other_type,
        });
        setPerfusionVascularValues({
          note: resp.vascular_note,
          checked: resp.vascular_checked,
        });
        setPerfusionCathValues({
          note: resp.cath_note,
          checked: resp.cath_checked,
        });
        setPerfusionHepaticValues({
          note: resp.hepatic_note,
          checked: resp.hepatic_checked,
        });
        setPerfusionAutoValues({
          note: resp.auto_note,
          checked: resp.auto_checked,
        });
        setPerfusionEcmoValues({
          note: resp.ecmo_note,
          checked: resp.ecmo_checked,
        });
        setPerfusionOtherValues({
          note: resp.other_note,
          checked: resp.other_checked,
        });
        setPerfusionNoteValues({
          case_comments: resp.case_comments,
          department_comments: resp.department_comments,
        });
        API.query(
          "GET",
          `/api/perfusionbypasses/`,
          {},
          {},
          function (resp) {
            setPerfusionBypasses(resp);
          },
          onDataLoadError
        );
        if (resp.bypass_count) {
          setPerfBypassCountValues({ bypass_count: resp.bypass_count });
        }
      },
      onDataLoadError
    );
  }, []);

  const perfusionBypassColumns = useMemo(() => [
    {
      name: "Graft Site",
      selector: (row) => (
        <div className="select is-normal">
          <select
            onChange={(option) =>
              onPerfusionBypassChange("graft", option.target.value, row.id)
            }
            value={row.graft_site}
          >
            <option>Select Graft Site</option>
            {graftOptions.map((obj, i) => {
              return (
                <option key={i} value={obj.value}>
                  {obj.label}
                </option>
              );
            })}
          </select>
        </div>
      ),
    },
    {
      name: "Conduit",
      selector: (row) => (
        <div className="select is-normal">
          <select
            onChange={(option) =>
              onPerfusionBypassChange("conduit", option.target.value, row.id)
            }
            value={row.conduit}
          >
            <option>Select Conduit Site</option>
            {conduitOptions.map((obj, i) => {
              return (
                <option key={i} value={obj.value}>
                  {obj.label}
                </option>
              );
            })}
          </select>
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <a
            className="ml-3"
            title="Delete"
            onClick={() => onPerfusionBypassDeleteClicked(row)}
          >
            <FaTrashAlt size={14} />
          </a>
        </div>
      ),
    },
  ]);

  const onPerfBypassCountChange = (value) => {
    setPerfBypassCountValues(value);
  };

  const onPerfusionTopChanged = (value) => {
    setPerfusionTopValues(value);
  };

  const onPerfusionCardiacChanged = (value) => {
    setPerfusionCardiacValues(value);
  };

  const onPerfusionPulmonaryChanged = (value) => {
    setPerfusionPulmonaryValues(value);
  };

  const onPerfusionVascularChanged = (value) => {
    setPerfusionVascularValues(value);
  };

  const onPerfusionCathChanged = (value) => {
    setPerfusionCathValues(value);
  };

  const onPerfusionHepaticChanged = (value) => {
    setPerfusionHepaticValues(value);
  };

  const onPerfusionAutoChanged = (value) => {
    setPerfusionAutoValues(value);
  };

  const onPerfusionEcmoChanged = (value) => {
    setPerfusionEcmoValues(value);
  };

  const onPerfusionOtherChanged = (value) => {
    setPerfusionOtherValues(value);
  };

  const onPerfusionNoteChanged = (value) => {
    setPerfusionNoteValues(value);
  };

  const onConfirmPerfusionGraftDelete = () => {
    API.query(
      "DELETE",
      `/api/perfusionbypasses/${deleteValues.id}/`,
      {},
      {},
      function (resp) {
        let newValues = perfusionBypasses.filter(
          (j) => j.id !== deleteValues.id
        );
        setPerfusionBypasses(newValues);
        setShowPerfusionGraftConfirmationModal(false);
        let data = {
          perfusion_bypasses: removePerfusionBypass(deleteValues.id),
        };
        API.query(
          "PATCH",
          `/api/perfusiondetails/${perfusionDetail.id}/`,
          {},
          data,
          function (resp) {
            setPerfusionDetail(resp);
            setDeleteValues([]);
          },
          onDataLoadError
        );
      },
      onDataLoadError
    );
  };

  const removePerfusionBypass = (id) => {
    let bypasses = [...perfusionDetail.perfusion_bypasses];
    var i,
      abort = false;
    for (i = 0; i < bypasses.length && !abort; i++) {
      if (bypasses[i] == id) {
        bypasses.splice(i, 1);
      }
    }
    return bypasses;
  };

  const onPerfusionBypassAdd = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        graft_site: null,
        conduit: null,
      };
      API.query(
        "POST",
        `/api/perfusionbypasses/`,
        {},
        data,
        function (resp) {
          let newCoronaryValues = [...perfusionBypasses];
          newCoronaryValues.push(resp);
          setPerfusionBypasses(newCoronaryValues);
          let newIDs = {
            perfusion_bypasses: appendPerfusionBypass(resp.id),
          };
          API.query(
            "PATCH",
            `/api/perfusiondetails/${perfusionDetail.id}/`,
            {},
            newIDs,
            function (response) {
              setPerfusionDetail(response);
              setIsSaving(false);
            },
            onDataLoadError
          );
        },
        onDataLoadError
      );
    }
  };

  const onDataLoadError = (error) => {
    setErrorMessage(error);
    setShowErrorModal(true);
    setIsSaving(false);
  };

  const onPerfusionBypassDeleteClicked = (row) => {
    setDeleteValues(row);
    setShowPerfusionGraftConfirmationModal(true);
  };

  const onPerfusionBypassChange = (section, value, id) => {
    if (section == "graft") {
      let bypasses = [...allPerfusionBypassValues];
      let index = bypasses.findIndex((e) => e.id == id);
      if (index > -1) {
        let bypass = bypasses[index];
        bypass["graft_site"] = value;
        bypasses[index] = bypass;
        setAllPerfusionBypassValues(bypasses);
      } else {
        let bypass = perfusionBypasses.find((e) => e.id == id);
        bypasses.push({
          id: bypass.id,
          graft_site: value,
          conduit: bypass.conduit,
        });
        setAllPerfusionBypassValues(bypasses);
      }
    } else if (section == "conduit") {
      let bypasses = [...allPerfusionBypassValues];
      let index = bypasses.findIndex((e) => e.id == id);
      if (index > -1) {
        let bypass = bypasses[index];
        bypass["conduit"] = value;
        bypasses[index] = bypass;
        setAllPerfusionBypassValues(bypasses);
      } else {
        let bypass = perfusionBypasses.find((e) => e.id == id);
        bypasses.push({
          id: bypass.id,
          graft_site: bypass.graft_site,
          conduit: value,
        });
        setAllPerfusionBypassValues(bypasses);
      }
    }
  };

  const filteredPerfusionBypasses = perfusionBypasses.filter((obj) => {
    var i,
      abort = false,
      valid = false;
    for (i = 0; i < perfusionDetail.perfusion_bypasses.length && !abort; i++) {
      if (perfusionDetail.perfusion_bypasses[i] == obj.id) {
        valid = true;
        abort = true;
      }
    }
    return valid;
  });

  const appendPerfusionBypass = (id) => {
    let bypasses = [...perfusionDetail.perfusion_bypasses];
    bypasses.push(id);
    return bypasses;
  };

  const onPerfusionBypassSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      var i;
      for (i = 0; i < allPerfusionBypassValues.length; i++) {
        let data = {
          graft_site: allPerfusionBypassValues[i].graft_site,
          conduit: allPerfusionBypassValues[i].conduit,
        };
        API.query(
          "PATCH",
          `/api/perfusionbypasses/${allPerfusionBypassValues[i].id}/`,
          {},
          data,
          function (resp) {},
          onDataLoadError
        );
      }
      let perfData = {
        bypass_count: perfBypassCountValues.bypass_count,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        perfData,
        function (resp) {
          setPerfusionDetail(resp);
        },
        onDataLoadError
      );
      setIsSaving(false);
    }
  };

  const onPerfusionBypassClear = () => {
    setConfirmationMessage(
      "You are about to clear bypass values, please confirm."
    );
    setAccordionID(42);
    setShowConfirmationModal(true);
  };

  const onPerfusionTopClear = () => {
    setConfirmationMessage(
      "You are about to clear Perfusion values, please confirm."
    );
    setAccordionID(32);
    setShowConfirmationModal(true);
  };

  const onPerfusionCardiacClear = () => {
    setConfirmationMessage(
      "You are about to clear Perfusion values, please confirm."
    );
    setAccordionID(33);
    setShowConfirmationModal(true);
  };

  const onPerfusionPulmonaryClear = () => {
    setConfirmationMessage(
      "You are about to clear Perfusion values, please confirm."
    );
    setAccordionID(34);
    setShowConfirmationModal(true);
  };

  const onPerfusionVascularClear = () => {
    setConfirmationMessage(
      "You are about to clear Perfusion values, please confirm."
    );
    setAccordionID(35);
    setShowConfirmationModal(true);
  };

  const onPerfusionCathClear = () => {
    setConfirmationMessage(
      "You are about to clear Perfusion values, please confirm."
    );
    setAccordionID(36);
    setShowConfirmationModal(true);
  };

  const onPerfusionHepaticClear = () => {
    setConfirmationMessage(
      "You are about to clear Perfusion values, please confirm."
    );
    setAccordionID(37);
    setShowConfirmationModal(true);
  };

  const onPerfusionAutoClear = () => {
    setConfirmationMessage(
      "You are about to clear Perfusion values, please confirm."
    );
    setAccordionID(38);
    setShowConfirmationModal(true);
  };

  const onPerfusionEcmoClear = () => {
    setConfirmationMessage(
      "You are about to clear Perfusion values, please confirm."
    );
    setAccordionID(39);
    setShowConfirmationModal(true);
  };

  const onPerfusionOtherClear = () => {
    setConfirmationMessage(
      "You are about to clear Perfusion values, please confirm."
    );
    setAccordionID(40);
    setShowConfirmationModal(true);
  };

  const onPerfusionNoteClear = () => {
    setConfirmationMessage(
      "You are about to clear Perfusion values, please confirm."
    );
    setAccordionID(41);
    setShowConfirmationModal(true);
  };

  const onPerfusionTopSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        start_date: perfusionTopValues.start_date,
        end_date: perfusionTopValues.end_date,
        start_time: perfusionTopValues.start_time,
        end_time: perfusionTopValues.end_time,
        or_number: perfusionTopValues.or_number,
        classification: perfusionTopValues.classification,
        re_operation: perfusionTopValues.re_operation,
        operative_approach: perfusionTopValues.operative_approach,
        robotic_assisted: perfusionTopValues.robotic_assisted,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setIsSaving(false);
        },
        onDataLoadError
      );
    }
  };

  const onPerfusionCardiacSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        pericardial_window: perfusionCardiacValues.pericardial_window,
        re_exploration: perfusionCardiacValues.re_exploration,
        lead_insertion: perfusionCardiacValues.lead_insertion,
        other_trauma: perfusionCardiacValues.other_trauma,
        atrial: perfusionCardiacValues.atrial,
        tavr: perfusionCardiacValues.tavr,
        tmvr: perfusionCardiacValues.tmvr,
        mitral_clip: perfusionCardiacValues.mitral_clip,
        vad_insertion: perfusionCardiacValues.vad_insertion,
        vad_removal: perfusionCardiacValues.vad_removal,
        ecmo_insertion: perfusionCardiacValues.ecmo_insertion,
        ecmo_removal: perfusionCardiacValues.ecmo_removal,
        cardiac_other: perfusionCardiacValues.cardiac_other,
        cardiac_other_type: perfusionCardiacValues.cardiac_other_type,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setIsSaving(false);
        },
        onDataLoadError
      );
    }
  };

  const onPerfusionPulmonarySave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        transplant: perfusionPulmonaryValues.transplant,
        embolectomy: perfusionPulmonaryValues.embolectomy,
        pulmonary_other: perfusionPulmonaryValues.pulmonary_other,
        pulmonary_other_type: perfusionPulmonaryValues.pulmonary_other_type,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setIsSaving(false);
        },
        onDataLoadError
      );
    }
  };

  const onPerfusionVascularSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        vascular_note: perfusionVascularValues.note,
        vascular_checked: perfusionVascularValues.checked,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setIsSaving(false);
        },
        onDataLoadError
      );
    }
  };

  const onPerfusionCathSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        cath_note: perfusionCathValues.note,
        cath_checked: perfusionCathValues.checked,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setIsSaving(false);
        },
        onDataLoadError
      );
    }
  };

  const onPerfusionHepaticSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        hepatic_note: perfusionHepaticValues.note,
        hepatic_checked: perfusionHepaticValues.checked,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setIsSaving(false);
        },
        onDataLoadError
      );
    }
  };

  const onPerfusionAutoSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        auto_note: perfusionAutoValues.note,
        auto_checked: perfusionAutoValues.checked,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setIsSaving(false);
        },
        onDataLoadError
      );
    }
  };

  const onPerfusionEcmoSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        ecmo_note: perfusionEcmoValues.note,
        ecmo_checked: perfusionEcmoValues.checked,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setIsSaving(false);
        },
        onDataLoadError
      );
    }
  };

  const onPerfusionOtherSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        other_note: perfusionOtherValues.note,
        other_checked: perfusionOtherValues.checked,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setIsSaving(false);
        },
        onDataLoadError
      );
    }
  };

  const onPerfusionNoteSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        case_comments: perfusionNoteValues.case_comments,
        department_comments: perfusionNoteValues.department_comments,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setIsSaving(false);
        },
        onDataLoadError
      );
    }
  };

  const onConfirm = () => {
    if (accordionID == 32) {
      let data = {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        or_number: null,
        classification: null,
        re_operation: null,
        operative_approach: null,
        robotic_assisted: null,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setShowConfirmationModal(false);
          setAccordionID(0);
          setConfirmationMessage("");
          perfTopRef.current.reset();
          setPerfusionTopValues({
            start_date: null,
            end_date: null,
            start_time: null,
            end_time: null,
            or_number: null,
            classification: null,
            re_operation: null,
            operative_approach: null,
            robotic_assisted: null,
          });
        },
        onDataLoadError
      );
    } else if (accordionID == 33) {
      let data = {
        pericardial_window: null,
        re_exploration: null,
        lead_insertion: null,
        other_trauma: null,
        atrial: null,
        tavr: null,
        tmvr: null,
        mitral_clip: null,
        vad_insertion: null,
        vad_removal: null,
        ecmo_insertion: null,
        ecmo_removal: null,
        cardiac_other: null,
        cardiac_other_type: null,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setShowConfirmationModal(false);
          setAccordionID(0);
          setConfirmationMessage("");
          perfCardiacRef.current.reset();
          setPerfusionCardiacValues({
            pericardial_window: null,
            re_exploration: null,
            lead_insertion: null,
            other_trauma: null,
            atrial: null,
            tavr: null,
            tmvr: null,
            mitral_clip: null,
            vad_insertion: null,
            vad_removal: null,
            ecmo_insertion: null,
            ecmo_removal: null,
            cardiac_other: null,
            cardiac_other_type: null,
          });
        },
        onDataLoadError
      );
    } else if (accordionID == 34) {
      let data = {
        transplant: null,
        embolectomy: null,
        pulmonary_other: null,
        pulmonary_other_type: null,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setShowConfirmationModal(false);
          setAccordionID(0);
          setConfirmationMessage("");
          perfPulmRef.current.reset();
          setPerfusionPulmonaryValues({
            transplant: null,
            embolectomy: null,
            pulmonary_other: null,
            pulmonary_other_type: null,
          });
        },
        onDataLoadError
      );
    } else if (accordionID == 35) {
      let data = {
        vascular_note: null,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setShowConfirmationModal(false);
          setAccordionID(0);
          setConfirmationMessage("");
          setPerfusionVascularValues({ note: "", checked: false });
        },
        onDataLoadError
      );
    } else if (accordionID == 36) {
      let data = {
        cath_note: null,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setShowConfirmationModal(false);
          setAccordionID(0);
          setConfirmationMessage("");
          setPerfusionCathValues({ note: "", checked: false });
        },
        onDataLoadError
      );
    } else if (accordionID == 37) {
      let data = {
        hepatic_note: null,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setShowConfirmationModal(false);
          setAccordionID(0);
          setConfirmationMessage("");
          setPerfusionHepaticValues({ note: "", checked: false });
        },
        onDataLoadError
      );
    } else if (accordionID == 38) {
      let data = {
        auto_note: null,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setShowConfirmationModal(false);
          setAccordionID(0);
          setConfirmationMessage("");
          setPerfusionAutoValues({ note: "", checked: false });
        },
        onDataLoadError
      );
    } else if (accordionID == 39) {
      let data = {
        ecmo_note: null,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setShowConfirmationModal(false);
          setAccordionID(0);
          setConfirmationMessage("");
          setPerfusionEcmoValues({ note: "", checked: false });
        },
        onDataLoadError
      );
    } else if (accordionID == 40) {
      let data = {
        other_note: null,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setShowConfirmationModal(false);
          setAccordionID(0);
          setConfirmationMessage("");
          setPerfusionOtherValues({ note: "", checked: false });
        },
        onDataLoadError
      );
    } else if (accordionID == 41) {
      let data = {
        department_comments: null,
        case_comments: null,
      };
      API.query(
        "PATCH",
        `/api/perfusiondetails/${perfusionDetail.id}/`,
        {},
        data,
        function (resp) {
          setPerfusionDetail(resp);
          setShowConfirmationModal(false);
          setAccordionID(0);
          setConfirmationMessage("");
          setPerfusionNoteValues({
            case_comments: "",
            department_comments: "",
          });
        },
        onDataLoadError
      );
    } else if (accordionID == 42) {
      if (
        (perfusionDetail.perfusion_bypasses &&
          perfusionDetail.perfusion_bypasses != 0) ||
        (perfBypassCountValues.bypass_count &&
          perfBypassCountValues.bypass_count >= 1)
      ) {
        var i;
        for (i = 0; i < perfusionDetail.perfusion_bypasses; i++) {
          API.query(
            "DELETE",
            `/api/perfusionbypasses/${perfusionDetail.perfusion_bypasses[i]}/`,
            {},
            {},
            function (resp) {},
            onDataLoadError
          );
        }
        let data = {
          perfusion_bypasses: [],
          bypass_count: null,
        };
        API.query(
          "PATCH",
          `/api/perfusiondetails/${perfusionDetail.id}/`,
          {},
          data,
          function (response) {
            setPerfusionDetail(response);
            setAllPerfusionBypassValues([]);
            setPerfBypassCountValues([]);
            setShowConfirmationModal(false);
            setAccordionID(0);
            setConfirmationMessage("");
            perfBypassRef.current.reset();
          },
          onDataLoadError
        );
      } else {
        setShowConfirmationModal(false);
        setAccordionID(0);
        setConfirmationMessage("");
      }
    }
  };

  return (
    <div>
      <form ref={perfTopRef}>
        <DynamicForm
          key={501}
          values={perfusionTopValues}
          onValuesChanged={onPerfusionTopChanged}
          form={PerfusionTopForm}
        />
      </form>

      <hr />

      <div className="level w-100">
        <div className="level-left">
          <button
            className="button is-danger is-inverted"
            onClick={onPerfusionTopClear}
          >
            Clear
          </button>
        </div>
        <div className="level-right">
          <button className={saveBtnClasses} onClick={onPerfusionTopSave}>
            Save
          </button>
        </div>
      </div>

      <hr />

      <Accordion allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Cardiac &#40;OPCAB&#41;</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="level w-100">
              <div className="level-left">
                <form ref={perfBypassRef}>
                  <DynamicForm
                    key={502}
                    form={BypassCountForm}
                    values={perfBypassCountValues}
                    onValuesChanged={onPerfBypassCountChange}
                  />
                </form>
              </div>
              <div className="level-right">
                <button
                  style={{ backgroundColor: "#1C2944", color: "#FFFFFF" }}
                  onClick={onPerfusionBypassAdd}
                  className="button"
                >
                  Add CABG
                </button>
              </div>
            </div>

            <hr />

            <DataTable
              striped
              pagination
              data={filteredPerfusionBypasses}
              columns={perfusionBypassColumns}
            />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onPerfusionBypassClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button
                  className={saveBtnClasses}
                  onClick={onPerfusionBypassSave}
                >
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Cardiac &#40;Other&#41;</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <form ref={perfCardiacRef}>
              <DynamicForm
                key={503}
                form={PerfusionCardiacForm}
                values={perfusionCardiacValues}
                onValuesChanged={onPerfusionCardiacChanged}
              />
            </form>

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onPerfusionCardiacClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button
                  className={saveBtnClasses}
                  onClick={onPerfusionCardiacSave}
                >
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Pulmonary</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <form ref={perfPulmRef}>
              <DynamicForm
                key={504}
                form={PerfusionPulmonaryForm}
                values={perfusionPulmonaryValues}
                onValuesChanged={onPerfusionPulmonaryChanged}
              />
            </form>

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onPerfusionPulmonaryClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button
                  className={saveBtnClasses}
                  onClick={onPerfusionPulmonarySave}
                >
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Vascular</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <DynamicForm
              key={505}
              form={autoNoteForm}
              values={perfusionVascularValues}
              onValuesChanged={onPerfusionVascularChanged}
            />

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onPerfusionVascularClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button
                  className={saveBtnClasses}
                  onClick={onPerfusionVascularSave}
                >
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Cath Lab</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <DynamicForm
              key={506}
              form={autoNoteForm}
              values={perfusionCathValues}
              onValuesChanged={onPerfusionCathChanged}
            />

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onPerfusionCathClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button
                  className={saveBtnClasses}
                  onClick={onPerfusionCathSave}
                >
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Hepatic</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <DynamicForm
              key={507}
              form={autoNoteForm}
              values={perfusionHepaticValues}
              onValuesChanged={onPerfusionHepaticChanged}
            />

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onPerfusionHepaticClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button
                  className={saveBtnClasses}
                  onClick={onPerfusionHepaticSave}
                >
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Autotransfusion Standby</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <DynamicForm
              key={508}
              form={autoNoteForm}
              values={perfusionAutoValues}
              onValuesChanged={onPerfusionAutoChanged}
            />

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onPerfusionAutoClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button
                  className={saveBtnClasses}
                  onClick={onPerfusionAutoSave}
                >
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>ECMO</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <DynamicForm
              key={509}
              form={autoNoteForm}
              values={perfusionEcmoValues}
              onValuesChanged={onPerfusionEcmoChanged}
            />

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onPerfusionEcmoClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button
                  className={saveBtnClasses}
                  onClick={onPerfusionEcmoSave}
                >
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Other</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <DynamicForm
              key={510}
              form={autoNoteForm}
              values={perfusionOtherValues}
              onValuesChanged={onPerfusionOtherChanged}
            />

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onPerfusionOtherClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button
                  className={saveBtnClasses}
                  onClick={onPerfusionOtherSave}
                >
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Notes</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <DynamicForm
              key={511}
              form={LabForm}
              values={perfusionNoteValues}
              onValuesChanged={onPerfusionNoteChanged}
            />

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onPerfusionNoteClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button
                  className={saveBtnClasses}
                  onClick={onPerfusionNoteSave}
                >
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>

      {showPerfusionGraftConfirmationModal && (
        <ConfirmationModal
          message={"You are about to delete a Graft. This cannot be undone."}
          onConfirm={onConfirmPerfusionGraftDelete}
          onClose={() => setShowPerfusionGraftConfirmationModal(false)}
        />
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          message={confirmationMessage}
          onConfirm={onConfirm}
          onClose={() => setShowConfirmationModal(false)}
        />
      )}

      {showErrorModal && (
        <ErrorModal
          message={errorMessage}
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </div>
  );
}

export default DetailPerfusion;
