import React from 'react';
import DataTable from 'react-data-table-component';

function CaseReportModal(props) {
    const {data, columns, onClose, titleString} = props

    return <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{titleString}</p>
      </header>
        <section className="modal-card-body p-0">
          <div className="p-5">
            <div className="has-background-white rounded w-100 is-flex">              
              <DataTable 
                striped
                data={data}
                columns={columns}
              />
              <button onClick={onClose} className="modal-close is-large" aria-label="close"></button>
            </div>
          </div>
        </section>

        <footer className="modal-card-foot">
          <button className="button" onClick={onClose}>OK</button>
        </footer>
      </div>
    </div>
}

export default CaseReportModal;