import React, { useRef, useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useReactToPrint } from "react-to-print";
import {
  calculateBMI,
  calculateBSA,
  calculateFlow,
  calculateEBV,
} from "../helpers/patient";
import { getCaseTimeDate } from "../helpers/case";
import { FaRegSquare, FaRegCheckSquare } from "react-icons/fa";
import API from "../../services/API";

function PdfProcedure(props) {
  const [chartColumns, setChartColumns] = useState([]);
  //const [userSigLoaded, setUserSigLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  //const [signature, setSignature] = useState()
  const [surgeonSigLoaded, setSurgeonSigLoaded] = useState(false);
  const [surgeonSignature, setSurgeonSignature] = useState();
  const [autoSigLoaded, setAutoSigLoaded] = useState(false);
  const [autoSignature, setAutoSignature] = useState();
  const [perfSigLoaded, setPerfSigLoaded] = useState(false);
  const [perfSignature, setPerfSignature] = useState();
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [logo, setLogo] = useState();
  const data = props.caseReport;
  const componentRef = useRef();
  const today = new Date();
  const dateOptions = { hour12: false };

  useEffect(() => {
    let params = {
      facility_id: props.facility,
    };
    API.query(
      "GET",
      `/api/facilitylogfieldsettings/`,
      params,
      {},
      function (resp) {
        setChartColumns(
          resp[0].log_fields.sort((a, b) => a.ordinal - b.ordinal)
        );
        //getUserSignature()
        getAutoSignature();
        getPerfSignature();
        getSurgeonSignature();
        getFacilityLogo();
      },
      onDataLoadError
    );
  }, []);

  const onDataLoadError = () => {
    console.log("error", error);
    setIsLoading(false);
  };

  const ciValues = [1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3];

  const formatChecklists = () => {
    var checklists = data.checklist_items;
    let half_length = Math.ceil(checklists.length / 2);

    let leftCol = checklists.slice(0, half_length);
    let rightCol = checklists.slice(half_length, checklists.length);

    return { left: leftCol, right: rightCol };
  };

  const formatPersonnel = () => {
    let datetimes = getCaseTimeDate(data);
    let start = new Date(datetimes.start_date + " " + datetimes.start_time);
    let end = new Date(datetimes.end_date + " " + datetimes.end_time);

    let hours = Math.floor((end.getTime() - start.getTime()) / (1000 * 3600));
    let minutes = Math.floor(
      ((end.getTime() - start.getTime()) / (1000 * 3600) - hours) * 60
    );

    let start_date =
      start.getMonth() + 1 + "/" + start.getDate() + "/" + start.getFullYear();
    let end_date =
      end.getMonth() + 1 + "/" + end.getDate() + "/" + end.getFullYear();
    let start_time = start.toTimeString().slice(0, 5);
    let end_time = end.toTimeString().slice(0, 5);

    var total_hours = hours * data.personnel_roles.length;
    var total_minutes = minutes * data.personnel_roles.length;

    if (total_minutes > 60) {
      let newHours = Math.floor(total_minutes / 60);
      let newMinutes = Math.floor((total_minutes / 60 - newHours) * 60);

      total_hours += newHours;
      total_minutes = newMinutes;
    }

    let personnelArr = data.personnel_roles.map((e) => {
      return {
        name: e.user_full_name,
        job: e.job ? e.job.name : e.temp_job,
        start_date: start_date,
        start_time: start_time,
        end_date: end_date,
        end_time: end_time,
        total: `${hours}hr ${minutes}min`,
      };
    });

    return {
      personnel: personnelArr,
      start_date: start_date,
      end_date: end_date,
      start_time: start_time,
      end_time: end_time,
      total: `${total_hours}hr ${total_minutes}min`,
    };
  };

  const personnel = formatPersonnel();
  const checklists = formatChecklists();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const formatCI = (ci) => {
    let ciString = ci.toString();
    return ciString.includes(".") ? ciString : ciString + ".0";
  };

  const personnelColumns = useMemo(() => [
    { name: "Role", width: "120px", selector: (row) => row.job },
    { name: "Perfusionist", width: "200px", selector: (row) => row.name },
    { name: "Start Date", width: "120px", selector: (row) => row.start_date },
    { name: "Start Time", width: "100px", selector: (row) => row.start_time },
    { name: "End Date", width: "120px", selector: (row) => row.end_date },
    { name: "End Time", width: "100px", selector: (row) => row.end_time },
    { name: "Total", width: "120px", selector: (row) => row.total },
  ]);

  const getPlateletAnticoag = () => {
    let str = "";
    if (data.platelet_detail != null) {
      if (data.platelet_detail.anticoag_used == "Heparin") {
        str = `Heparin ${data.platelet_detail.heparin_used} units/l`;
      } else if (data.platelet_detail.anticoag_used == "ACD") {
        str = `ACD`;
      } else if (data.platelet_detail.anticoag_used == "CPD") {
        str = `CPD ${data.platelet_detail.cpd_used} ml`;
      } else if (data.platelet_detail.anticoag_used == "Other") {
        str = `${data.platelet_detail.other_info}`;
      }
    }

    return str;
  };

  const getActivatorType = (type, num) => {
    if (num == 1) {
      if (type == "Other") {
        return `${data.platelet_detail.activator_one_other_info}`;
      } else {
        return `${data.platelet_detail.activator_one_used}`;
      }
    } else if (num == 2) {
      if (type == "Other") {
        return `${data.platelet_detail.activator_two_other_info}`;
      } else {
        return `${data.platelet_detail.activator_two_used}`;
      }
    }
    return;
  };

  const getActivatorAmount = (type, num) => {
    if (num == 1) {
      if (type == "Other") {
        return `${data.platelet_detail.activator_one_other_amount}`;
      } else if (type == "Recombinant Thrombin") {
        return `${data.platelet_detail.activator_one_recombinant_amount}`;
      } else if (type == "Bovine Thrombin") {
        return `${data.platelet_detail.activator_one_bovine_amount}`;
      } else if (type == "Calcium") {
        return `${data.platelet_detail.activator_one_calcium_amount}`;
      }
    } else if (num == 2) {
      if (type == "Other") {
        return `${data.platelet_detail.activator_two_other_amount}`;
      } else if (type == "Recombinant Thrombin") {
        return `${data.platelet_detail.activator_two_recombinant_amount}`;
      } else if (type == "Bovine Thrombin") {
        return `${data.platelet_detail.activator_two_bovine_amount}`;
      } else if (type == "Calcium") {
        return `${data.platelet_detail.activator_two_calcium_amount}`;
      }
    }
    return;
  };

  const getAutoProcType = () => {
    var string = "";
    if (data.auto_detail) {
      const types = [
        { key: "cardiac", label: "Cardiac" },
        { key: "vascular", label: "Vascular" },
        { key: "ortho", label: "Ortho" },
        { key: "hepatic", label: "Hepatic" },
        { key: "neuro", label: "Neuro" },
        { key: "trauma", label: "Trauma" },
        { key: "other", label: "Other" },
      ];
      for (var i = 0; i < types.length; i++) {
        if (data.auto_detail[`${types[i].key}_checked`]) {
          if (data.auto_detail[`${types[i].key}_note`]) {
            if (string == "") {
              string = `${types[i].label}: ${
                data.auto_detail[`${types[i].key}_note`]
              }`;
            } else {
              string = `${string}, ${types[i].label}: ${
                data.auto_detail[`${types[i].key}_note`]
              }`;
            }
          } else {
            if (string == "") {
              string = `${types[i].label}`;
            } else {
              string = `${string}, ${types[i].label}`;
            }
          }
        }
      }
    }
    return string;
  };

  const getLabColumnInfo = (string) => {
    var value = false;
    data.charts[0].events
      .filter((x) => {
        var valid = false;
        if (x.lab_event != null) {
          valid = true;
        }
        return valid;
      })
      .map((e, idx) => {
        if (e.lab_event[`${string}`]) {
          value = true;
        }
      });
    return value;
  };

  // COMMENTING OUT FOR NOW - I FEEL LIKE THEY WILL WANT THIS BACK
  // const getUserSignature = () => {
  //     const user = data.user
  //     if (!userSigLoaded && !isLoading) {
  //         setUserSigLoaded(user.user_full_name)
  //         if (user.signature >= 0) {
  //             setIsLoading(true)
  //             let params = {
  //                 'type': 'render_file',
  //                 'file_id': user.signature
  //             }
  //             API.downloadFile('POST', `/api/files/`, params, {}, function (resp, type) {
  //                 let url = URL.createObjectURL(resp)
  //                 setIsLoading(false)
  //                 setSignature(url)
  //             }, onDataLoadError)
  //         } else {
  //             return <p>No Signature Uploaded</p>
  //         }
  //     }
  // }

  const getSurgeonSignature = () => {
    if (!isLoading && !surgeonSigLoaded) {
      const personnel = data.personnel_roles;
      const surgeon = personnel.find((e) => e.job.id == 15);
      if (surgeon) {
        let signature;
        if (surgeon.user) {
          signature = surgeon.user.signature;
        } else if (surgeon.personnel) {
          signature = surgeon.personnel.signature;
        }
        if (signature) {
          setIsLoading(true);
          let params = {
            type: "render_file",
            file_id: signature,
          };
          API.downloadFile(
            "POST",
            `/api/files/`,
            params,
            {},
            function (resp, type) {
              let url = URL.createObjectURL(resp);
              setSurgeonSigLoaded(surgeon.user_full_name);
              setIsLoading(false);
              setSurgeonSignature(url);
            },
            onDataLoadError
          );
        }
      }
    }
  };

  const getAutoSignature = () => {
    if (!isLoading && !autoSigLoaded) {
      const personnel = data.personnel_roles;
      const auto = personnel.find((e) => e.job.id == 5);
      if (auto) {
        let signature;
        if (auto.user) {
          signature = auto.user.signature;
        } else if (auto.personnel) {
          signature = auto.personnel.signature;
        }
        if (signature) {
          setIsLoading(true);
          let params = {
            type: "render_file",
            file_id: signature,
          };
          API.downloadFile(
            "POST",
            `/api/files/`,
            params,
            {},
            function (resp, type) {
              let url = URL.createObjectURL(resp);
              setAutoSigLoaded(auto.user_full_name);
              setIsLoading(false);
              setAutoSignature(url);
            },
            onDataLoadError
          );
        }
      }
    }
  };

  const getPerfSignature = () => {
    if (!isLoading && !perfSigLoaded) {
      const personnel = data.personnel_roles;
      const perf = personnel.find((e) => e.job.id == 7);
      if (perf) {
        let signature;
        if (perf.user) {
          signature = perf.user.signature;
        } else if (perf.personnel) {
          signature = perf.personnel.signature;
        }
        if (signature) {
          setIsLoading(true);
          let params = {
            type: "render_file",
            file_id: signature,
          };
          API.downloadFile(
            "POST",
            `/api/files/`,
            params,
            {},
            function (resp, type) {
              let url = URL.createObjectURL(resp);
              setPerfSigLoaded(perf.user_full_name);
              setIsLoading(false);
              setPerfSignature(url);
            },
            onDataLoadError
          );
        }
      }
    }
  };

  const getFacilityLogo = () => {
    if (!isLoading && !logoLoaded) {
      const facility = data.facility;
      const logo = facility.logo;
      if (logo && logo > 0) {
        setIsLoading(true);
        let params = {
          type: "render_file",
          file_id: logo,
        };
        API.downloadFile(
          "POST",
          `/api/files/`,
          params,
          {},
          function (resp, type) {
            let url = URL.createObjectURL(resp);
            setLogoLoaded(true);
            setIsLoading(false);
            setLogo(url);
          },
          onDataLoadError
        );
      }
    }
  };

  const calculateAge = (birthday) => {
    return Math.floor((new Date() - new Date(birthday).getTime()) / 3.15576e10);
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card modal-extra-large">
        <header className="modal-card-head">
          <p className="modal-card-title">Procedure Report</p>
        </header>
        <section className="modal-card-body p-0">
          <div className="p-5">
            <table className="print-component" ref={componentRef}>
              <thead className="has-background-white">
                <tr>
                  <th>
                    <div className="level w-100 has-text-weight-normal">
                      <div
                        className="level-item"
                        style={{
                          borderBottom: "solid",
                          borderBottomWidth: "1px",
                        }}
                      >
                        Name: {data?.patient_name}
                      </div>
                      <div
                        className="level-item"
                        style={{
                          borderBottom: "solid",
                          borderBottomWidth: "1px",
                        }}
                      >
                        MRN: {data?.patient?.medical_record_num}
                      </div>
                      <div
                        className="level-item"
                        style={{
                          borderBottom: "solid",
                          borderBottomWidth: "1px",
                        }}
                      >
                        Account No: {data?.patient?.account_num}
                      </div>
                      <div
                        className="level-item"
                        style={{
                          borderBottom: "solid",
                          borderBottomWidth: "1px",
                        }}
                      >
                        DOB: {data?.patient?.dob}
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="w-100 has-background-white">
                      {/* <style type="text/css" media="print">{"\
                            @page {\ size: landscape;\ }\
                        "}</style> */}

                      {/* PATIENT INFO */}

                      {logoLoaded && (
                        <span style={{ position: "relative" }}>
                          <img
                            width={200}
                            height={200}
                            src={logo}
                            style={{ objectFit: "contain" }}
                          ></img>
                        </span>
                      )}

                      <div className="level w-100">
                        <div className="level-item">
                          <p className="title is-3">Patient Information</p>
                        </div>
                      </div>

                      <hr />

                      <div
                        className="level w-100"
                        style={{ marginTop: "5px", marginBottom: "5px" }}
                      >
                        <div className="level-left">
                          <p className="has-text-weight-medium">Hospital: </p>
                          <p style={{ marginLeft: "8px" }}>
                            {data.facility_name}
                          </p>
                        </div>
                        <div className="level-right"></div>
                      </div>

                      <div
                        className="level w-100"
                        style={{ marginTop: "5px", marginBottom: "5px" }}
                      >
                        <div className="level-left">
                          <p className="has-text-weight-medium">
                            Medical Record #:{" "}
                          </p>
                          <p style={{ marginLeft: "8px" }}>
                            {data.patient.medical_record_num}
                          </p>
                        </div>
                        <div className="level-right">
                          <p className="has-text-weight-medium">
                            Procedure Date:{" "}
                          </p>
                          <p style={{ marginLeft: "8px" }}>{data.date}</p>
                        </div>
                      </div>

                      <div
                        className="level w-100"
                        style={{ marginTop: "5px", marginBottom: "5px" }}
                      >
                        <div className="level-left">
                          <p className="has-text-weight-medium">
                            Account Number:{" "}
                          </p>
                          <p style={{ marginLeft: "8px" }}>
                            {data.patient.account_num}
                          </p>
                        </div>
                        <div className="level-right"></div>
                      </div>

                      <div
                        className="level w-100"
                        style={{ marginTop: "5px", marginBottom: "5px" }}
                      >
                        <div className="level-item">
                          <p className="has-text-weight-medium">Last Name: </p>
                          <p style={{ marginLeft: "8px" }}>
                            {data.patient.last_name}
                          </p>
                        </div>
                        <div className="level-item">
                          <p className="has-text-weight-medium">Suffix: </p>
                          <p style={{ marginLeft: "8px" }}>
                            {data.patient.suffix}
                          </p>
                        </div>
                        <div className="level-item">
                          <p className="has-text-weight-medium">First Name: </p>
                          <p style={{ marginLeft: "8px" }}>
                            {data.patient.first_name}
                          </p>
                        </div>
                        <div className="level-item">
                          <p className="has-text-weight-medium">MI: </p>
                          <p style={{ marginLeft: "8px" }}>
                            {data.patient.middle_initial}
                          </p>
                        </div>
                      </div>

                      <div
                        className="level w-100"
                        style={{ marginTop: "5px", marginBottom: "5px" }}
                      >
                        <div className="level-item">
                          <p className="has-text-weight-medium">DOB: </p>
                          <p style={{ marginLeft: "8px" }}>
                            {data.patient.dob}
                          </p>
                        </div>
                        <div className="level-item">
                          <p className="has-text-weight-medium">Age: </p>
                          <p style={{ marginLeft: "8px" }}>{`${calculateAge(
                            data.patient.dob
                          )} years`}</p>
                        </div>
                        <div className="level-item">
                          <p className="has-text-weight-medium">Gender: </p>
                          <p style={{ marginLeft: "8px" }}>
                            {data.patient.gender}
                          </p>
                        </div>
                        <div className="level-item">
                          <p className="has-text-weight-medium">Race: </p>
                          <p style={{ marginLeft: "8px" }}>
                            {data.patient.race ? data.patient.race : ""}
                          </p>
                        </div>
                      </div>

                      <div
                        className="level w-100"
                        style={{ marginTop: "5px", marginBottom: "5px" }}
                      >
                        <div className="level-left">
                          <p className="has-text-weight-medium">Height: </p>
                          <p style={{ marginLeft: "8px" }}>
                            {data.patient_height_cm} cm &#40;
                            {data.patient_height_ft} ft,{" "}
                            {data.patient_height_in
                              ? data.patient_height_in
                              : 0}{" "}
                            in&#41;
                          </p>
                        </div>
                        <div className="level-right">
                          <p className="has-text-weight-medium">Weight: </p>
                          <p style={{ marginLeft: "8px" }}>
                            {data.patient_weight_kg} kg &#40;
                            {data.patient_weight_lb} lbs,{" "}
                            {data.patient_weight_oz
                              ? data.patient_weight_oz
                              : 0}{" "}
                            oz&#41;
                          </p>
                        </div>
                      </div>

                      <div
                        className="level w-100"
                        style={{ marginTop: "5px", marginBottom: "5px" }}
                      >
                        <div className="level-left">
                          <p className="has-text-weight-medium">
                            BSA &#40;m
                            <span
                              style={{
                                verticalAlign: "super",
                                fontSize: "50%",
                              }}
                            >
                              2
                            </span>
                            &#41;:
                          </p>
                          <p style={{ marginLeft: "8px" }}>
                            {calculateBSA(data)}
                          </p>
                        </div>
                        <div className="level-right">
                          <p className="has-text-weight-medium">BMI:</p>
                          <p style={{ marginLeft: "8px" }}>
                            {calculateBMI(data)}
                          </p>
                        </div>
                      </div>

                      <div
                        className="columns"
                        style={{ marginTop: "5px", marginBottom: "5px" }}
                      >
                        <div className="column">
                          <div className="columns">
                            <div className="column is-2">
                              <p className="has-text-weight-medium">
                                Allergies:
                              </p>
                            </div>
                            <div className="column is-10">
                              <p
                                className="is-flex-wrap-wrap"
                                style={{ marginLeft: "8px" }}
                              >
                                {data.patient_allergies.map((e, idx) => {
                                  if (
                                    data.patient_allergies.length - 1 !=
                                    idx
                                  ) {
                                    return `${e.name}, `;
                                  } else {
                                    return `${e.name}`;
                                  }
                                })}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="column is-flex-wrap-wrap">
                          <div className="columns">
                            <div className="column is-2">
                              <p className="has-text-weight-medium">History:</p>
                            </div>
                            <div className="column is-10">
                              <p style={{ marginLeft: "8px" }}>
                                {data.patient_history.map((e, idx) => {
                                  if (data.patient_history.length - 1 != idx) {
                                    return `${e.name}, `;
                                  } else {
                                    return `${e.name}`;
                                  }
                                })}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="level w-100"
                        style={{ marginTop: "5px", marginBottom: "5px" }}
                      >
                        <div className="level-item"></div>
                        <div className="level-item">
                          <table className="table is-narrow is-bordered is-fullwidth">
                            <tbody>
                              <tr>
                                <th>CI</th>
                                {ciValues.map((value, i) => (
                                  <td key={i} className="has-text-centered">
                                    {formatCI(value)}
                                  </td>
                                ))}
                              </tr>
                              <tr>
                                <th>Flow</th>
                                {ciValues.map((value, i) => (
                                  <td
                                    key={i}
                                    className="has-text-centered is-size-7 pt-2"
                                  >
                                    {calculateFlow(data, value)}
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="level-item"></div>
                      </div>

                      <div
                        className="level w-100"
                        style={{ marginTop: "5px", marginBottom: "5px" }}
                      >
                        <div className="level-left">
                          <p className="has-text-weight-medium">
                            Estimated Blood Volume &#40;mL&#41;:
                          </p>
                          <p style={{ marginLeft: "8px" }}>
                            {calculateEBV(data, data.patient)}
                          </p>
                        </div>
                        <div className="level-right"></div>
                      </div>

                      <hr />

                      {/* PROCEDURES */}
                      <div className="level w-100">
                        <div className="level-item"></div>
                        <div className="level-item">
                          <p className="title is-3">Procedure&#40;s&#41;</p>
                        </div>
                        <div className="level-item"></div>
                      </div>

                      <hr />

                      {data.procedure.map((e, idx) => {
                        return <p key={idx}>{e.name}</p>;
                      })}

                      <hr />

                      {/* PROCEDURE CHECKLISTS */}
                      <div className="level w-100">
                        <div className="level-item"></div>
                        <div className="level-item">
                          <p className="title is-3">
                            Procedure Checklist&#40;s&#41;
                          </p>
                        </div>
                        <div className="level-item"></div>
                      </div>

                      <hr />

                      <div className="columns">
                        <div className="column is-6">
                          {checklists.left.map((e, idx) => {
                            return (
                              <div
                                className="level w-100"
                                key={idx}
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                  overflowWrap: "break-word",
                                }}
                              >
                                <div className="level-left">
                                  <p>{e.item.name}</p>
                                </div>
                                <div className="level-right">
                                  <p>
                                    {e.date_checked ? (
                                      <FaRegCheckSquare />
                                    ) : (
                                      <FaRegSquare />
                                    )}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="column is-6">
                          {checklists.right.map((e, idx) => {
                            return (
                              <div
                                className="level w-100"
                                key={idx}
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                  overflowWrap: "break-word",
                                }}
                              >
                                <div className="level-left">
                                  <p>{e.item.name}</p>
                                </div>
                                <div className="level-right">
                                  <p>
                                    {e.date_checked ? (
                                      <FaRegCheckSquare />
                                    ) : (
                                      <FaRegSquare />
                                    )}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <hr />

                      {/* PERSONNEL */}
                      <div className="level w-100">
                        <div className="level-item"></div>
                        <div className="level-item">
                          <p className="title is-3">Personnel</p>
                        </div>
                        <div className="level-item"></div>
                      </div>

                      <hr />

                      <DataTable
                        striped
                        data={personnel.personnel}
                        columns={personnelColumns}
                      />

                      <div
                        className="level w-100"
                        style={{ marginTop: "5px", marginBottom: "5px" }}
                      >
                        <div className="level-left"></div>
                        <div className="level-right">
                          <p className="has-text-weight-medium">Total:</p>
                          <p style={{ marginLeft: "8px" }}>{personnel.total}</p>
                        </div>
                      </div>

                      <hr />

                      {/* PROCEDURE SPECIFIC SHIT */}
                      <div className="level w-100">
                        <div className="level-item"></div>
                        <div className="level-item">
                          <p className="title is-3">Procedure Details</p>
                        </div>
                        <div className="level-item"></div>
                      </div>

                      <hr />

                      {/* CPB */}
                      {data.procedure_id.includes(1) && data.cpb_detail && (
                        <div>
                          {data.cpb_detail.top && (
                            <div>
                              <p className="title is-5 is-underlined">
                                Cardiopulmonary Bypass
                              </p>
                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-left">
                                  <p className="has-text-weight-medium">
                                    Start Date/Time:
                                  </p>
                                  <p style={{ marginLeft: "8px" }}>
                                    {data.cpb_detail.top.start_date}{" "}
                                    {data.cpb_detail.top.start_time}
                                  </p>
                                </div>
                                <div className="level-right"></div>
                              </div>

                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-left">
                                  <p className="has-text-weight-medium">
                                    End Date/Time:
                                  </p>
                                  <p style={{ marginLeft: "8px" }}>
                                    {data.cpb_detail.top.end_date}{" "}
                                    {data.cpb_detail.top.end_time}
                                  </p>
                                </div>
                                <div className="level-right"></div>
                              </div>

                              {data.cpb_detail.top.or_number && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Operating Room:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.top.or_number}
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.top.classification && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Classification:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.top.classification}
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.top.re_operation && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Reoperation:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.top.re_operation
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.top.operative_approach && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Approach:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.top.operative_approach}
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.top.robotic_assistance && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Robotically Assisted:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.top.robotic_assistance}
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}
                            </div>
                          )}

                          {data.cpb_detail.bypass_count && (
                            <div>
                              <p className="has-text-weight-medium">
                                Coronary Artery Bypass:
                              </p>
                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-item">
                                  <p className="has-text-weight-medium">
                                    Number of CABG:
                                  </p>
                                  <p style={{ marginLeft: "8px" }}>
                                    {data.cpb_detail.bypass_count}
                                  </p>
                                </div>
                                <div className="level-item">
                                  <p className="has-text-weight-medium">
                                    OPCAB Conversion:
                                  </p>
                                  <p style={{ marginLeft: "8px" }}>
                                    {data.cpb_detail.opcab_conversion
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </div>

                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-item"></div>
                                <div className="level-item">
                                  <div className="table-container">
                                    <table className="table is-narrow is-bordered is-fullwidth">
                                      <thead>
                                        <tr>
                                          <th className="has-text-weight-medium has-text-centered">
                                            Graft Site
                                          </th>
                                          <th className="has-text-weight-medium has-text-centered">
                                            Conduit
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {data.cpb_detail
                                          ? data.cpb_detail.bypasses.length > 0
                                            ? data.cpb_detail.bypasses.map(
                                                (e, idx) => {
                                                  return (
                                                    <tr key={idx}>
                                                      <td className="has-text-centered">
                                                        {e.graft_site}
                                                      </td>
                                                      <td className="has-text-centered">
                                                        {e.conduit}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )
                                            : null
                                          : null}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="level-item"></div>
                              </div>
                            </div>
                          )}

                          {data.cpb_detail.middle && (
                            <div>
                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-item">
                                  {data.cpb_detail.middle.cpb_time && (
                                    <div>
                                      <p className="has-text-weight-medium">
                                        CPB Time:
                                      </p>
                                      <p style={{ marginLeft: "8px" }}>
                                        {data.cpb_detail.middle.cpb_time} min
                                      </p>
                                    </div>
                                  )}
                                </div>
                                <div className="level-item">
                                  {data.cpb_detail.middle.clamp_time && (
                                    <div>
                                      <p className="has-text-weight-medium">
                                        Cross Clamp Time:
                                      </p>
                                      <p style={{ marginLeft: "8px" }}>
                                        {data.cpb_detail.middle.clamp_time} min
                                      </p>
                                    </div>
                                  )}
                                </div>
                                <div className="level-item">
                                  {data.cpb_detail.middle.iabp && (
                                    <div>
                                      <p className="has-text-weight-medium">
                                        IABP:
                                      </p>
                                      <p style={{ marginLeft: "8px" }}>
                                        {data.cpb_detail.middle.iabp
                                          ? data.cpb_detail.middle.iabp
                                          : "None"}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>

                              {data.cpb_detail.middle.pre_hct && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Pre CPB HCT:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.middle.pre_hct} %
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.middle.post_hct && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Post CPB HCT:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.middle.post_hct} %
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.middle.lowest_hct && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Lowest CPB HCT:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.middle.lowest_hct} %
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.middle.lowest_cpb && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Lowest Temp on CPB:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.middle.lowest_cpb} &deg;C
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.middle.glucose && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Highest Glucose:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.middle.glucose} mg/dL
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.middle.fluid_balance && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Total Fluid Balance:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.middle.fluid_balance} mL
                                      &#40;Estimated&#41;
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.middle.status && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Patient Status Leaving OR:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.middle.status}
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.middle.hemoconcentration && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Hemoconcentration:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.middle.hemoconcentration}{" "}
                                      mL
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.middle.perfusion_volume && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Perfusion Vol Added:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.middle.perfusion_volume}{" "}
                                      mL
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.middle.anes_volume && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Anes Vol Added:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.middle.anes_volume} mL
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.middle.urine_output && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Total Urine Output:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.middle.urine_output} mL
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}
                            </div>
                          )}

                          {data.cpb_detail.cardioplegia && (
                            <div>
                              <p className="has-text-weight-medium">
                                Cardioplegia:
                              </p>

                              {data.cpb_detail.cardioplegia.type && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginLeft: "40px",
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Type:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.cardioplegia.type}
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.cardioplegia
                                .crystalloid_ratio && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginLeft: "40px",
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Ratio:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {
                                        data.cpb_detail.cardioplegia
                                          .crystalloid_ratio
                                      }
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.cardioplegia
                                .crystalloid_amount && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginLeft: "40px",
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Crystalloid Amount:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {
                                        data.cpb_detail.cardioplegia
                                          .crystalloid_amount
                                      }{" "}
                                      mL
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.cardioplegia.delivery_method && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginLeft: "40px",
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Delivery Method:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {
                                        data.cpb_detail.cardioplegia
                                          .delivery_method
                                      }
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.cardioplegia.temperature && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginLeft: "40px",
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Temperature:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.cpb_detail.cardioplegia.temperature}
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}
                            </div>
                          )}

                          {data.cpb_detail.valve && (
                            <div>
                              <div className="level w-100">
                                <div className="level-item">
                                  <p className="has-text-weight-medium">
                                    Valve:
                                  </p>
                                </div>
                                <div className="level-item">
                                  <p className="has-text-weight-medium">
                                    Valve Ring Information:
                                  </p>
                                </div>
                                <div className="level-item"></div>
                              </div>

                              {data.cpb_detail.valve.aortic_status && (
                                <div className="level w-100">
                                  <div className="level-item">
                                    <p>
                                      Aortic{" "}
                                      {data.cpb_detail.valve.aortic_status}
                                    </p>
                                  </div>
                                  <div className="level-item">
                                    <p>
                                      {data.cpb_detail.valve.aortic_info ?? ""}
                                    </p>
                                  </div>
                                  <div className="level-item"></div>
                                </div>
                              )}

                              {data.cpb_detail.valve.mitral_status && (
                                <div className="level w-100">
                                  <div className="level-item">
                                    <p>
                                      Mitral{" "}
                                      {data.cpb_detail.valve.mitral_status}
                                    </p>
                                  </div>
                                  <div className="level-item">
                                    <p>
                                      {data.cpb_detail.valve.mitral_info ?? ""}
                                    </p>
                                  </div>
                                  <div className="level-item"></div>
                                </div>
                              )}

                              {data.cpb_detail.valve.tricuspid_status && (
                                <div className="level w-100">
                                  <div className="level-item">
                                    <p>
                                      Tricuspid{" "}
                                      {data.cpb_detail.valve.tricuspid_status}
                                    </p>
                                  </div>
                                  <div className="level-item">
                                    <p>
                                      {data.cpb_detail.valve.tricuspid_info ??
                                        ""}
                                    </p>
                                  </div>
                                  <div className="level-item"></div>
                                </div>
                              )}

                              {data.cpb_detail.valve.pulmonic_status && (
                                <div className="level w-100">
                                  <div className="level-item">
                                    <p>
                                      Pulmonic{" "}
                                      {data.cpb_detail.valve.pulmonic_status}
                                    </p>
                                  </div>
                                  <div className="level-item">
                                    <p>
                                      {data.cpb_detail.valve.pulmonic_info ??
                                        ""}
                                    </p>
                                  </div>
                                  <div className="level-item"></div>
                                </div>
                              )}
                            </div>
                          )}

                          {data.cpb_detail.aortic && (
                            <div>
                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-left">
                                  <p className="has-text-weight-medium">
                                    Aortic Surgery:
                                  </p>
                                </div>
                                <div className="level-right"></div>
                              </div>

                              {data.cpb_detail.aortic.aortic_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Aortic - ${data.cpb_detail.aortic.aortic_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.aortic.aortic_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Dissection/Intramural Hematoma - ${data.cpb_detail.aortic.hematoma_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.aortic.aortic_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Trauma - ${data.cpb_detail.aortic.trauma_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.aortic.aortic_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Coarctation - ${data.cpb_detail.aortic.coarctation_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.aortic.other_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Coarctation - ${data.cpb_detail.aortic.other_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}
                            </div>
                          )}

                          {data.cpb_detail.congenital && (
                            <div>
                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-left">
                                  <p className="has-text-weight-medium">
                                    Congenital Surgery:
                                  </p>
                                </div>
                                <div className="level-right"></div>
                              </div>

                              {data.cpb_detail.congenital.ductus_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Ductus Arteriosus Ligation - ${data.cpb_detail.congenital.ductus_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.congenital.norwood_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Norwood Procedure - ${data.cpb_detail.congenital.norwood_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.congenital.fontan_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Fontan Procedure - ${data.cpb_detail.congenital.fontan_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.congenital.bialock_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Bialock-Taussig Shunt - ${data.cpb_detail.congenital.bialock_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.congenital.vsd_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`VSD Closure - ${data.cpb_detail.congenital.vsd_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.congenital.asd_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`ASD Closure - ${data.cpb_detail.congenital.asd_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.congenital.tof_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`TOF Repair - ${data.cpb_detail.congenital.tof_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.congenital.aorta_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Aorta Coarctation Repair - ${data.cpb_detail.congenital.aorta_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.congenital.truncus_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Truncus Arteriosus Repair - ${data.cpb_detail.congenital.truncus_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.congenital.tricuspid_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Tricuspid Atresia Correction - ${data.cpb_detail.congenital.tricuspid_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.congenital.pulmonary_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Pulmonary Atresia Correction - ${data.cpb_detail.congenital.pulmonary_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.congenital.anamolous_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Anamolous Pulmonary Venous Return Correction - ${data.cpb_detail.congenital.anamolous_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.congenital.vessel_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Vessel Transposition Correction - ${data.cpb_detail.congenital.vessel_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.congenital.other_info && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Other - ${data.cpb_detail.congenital.other_info}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}
                            </div>
                          )}

                          {data.cpb_detail.circulatory_device && (
                            <div>
                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-left">
                                  <p className="has-text-weight-medium">
                                    Circulatory Assist Device:
                                  </p>
                                </div>
                                <div className="level-right"></div>
                              </div>

                              {data.cpb_detail.circulatory_device
                                .lvad_status && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`LVAD - ${
                                      data.cpb_detail.circulatory_device
                                        .lvad_status === "implant"
                                        ? "Implant"
                                        : "Explant"
                                    }${
                                      data.cpb_detail.circulatory_device
                                        .lvad_info
                                        ? ` - ${data.cpb_detail.circulatory_device.lvad_info}`
                                        : ""
                                    }`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.circulatory_device
                                .rvad_status && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`RVAD - ${
                                      data.cpb_detail.circulatory_device
                                        .rvad_status === "implant"
                                        ? "Implant"
                                        : "Explant"
                                    }${
                                      data.cpb_detail.circulatory_device
                                        .rvad_info
                                        ? ` - ${data.cpb_detail.circulatory_device.rvad_info}`
                                        : ""
                                    }`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.circulatory_device
                                .tah_status && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`TAH - ${
                                      data.cpb_detail.circulatory_device
                                        .tah_status === "implant"
                                        ? "Implant"
                                        : "Explant"
                                    }${
                                      data.cpb_detail.circulatory_device
                                        .tah_info
                                        ? ` - ${data.cpb_detail.circulatory_device.tah_info}`
                                        : ""
                                    }`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.circulatory_device
                                .other_status && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Other - ${
                                      data.cpb_detail.circulatory_device
                                        .other_status === "implant"
                                        ? "Implant"
                                        : "Explant"
                                    }${
                                      data.cpb_detail.circulatory_device
                                        .other_info
                                        ? ` - ${data.cpb_detail.circulatory_device.other_info}`
                                        : ""
                                    }`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}
                            </div>
                          )}

                          {data.cpb_detail.transplant && (
                            <div>
                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-left">
                                  <p className="has-text-weight-medium">
                                    Transplant:
                                  </p>
                                </div>
                                <div className="level-right"></div>
                              </div>

                              {data.cpb_detail.transplant.heart_time && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Heart - ${data.cpb_detail.transplant.heart_time} min`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.transplant.right_time && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Right Lung - ${data.cpb_detail.transplant.right_time} min`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.transplant.left_time && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Left Lung - ${data.cpb_detail.transplant.left_time} min`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}
                            </div>
                          )}

                          {data.cpb_detail.other && (
                            <div>
                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-left">
                                  <p className="has-text-weight-medium">
                                    Other:
                                  </p>
                                </div>
                                <div className="level-right"></div>
                              </div>

                              {data.cpb_detail.other.lead && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    Lead Insertion/Extraction
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.other.artrial && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    Artrial Fibrillation Surgery
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.other.asd && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    ASD Closure (Non-Congenital)
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.other.vsd && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    VSD Closure (Non-Congenital)
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.other.lva && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">LVA Repair</div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.other.tmr && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">TMR Laser</div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.other.tumor && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    Tumor Excision
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.other.pulmonary && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    Pulmonary Embolectomy
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.other.cardiac && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    Cardiac/Vascular Trauma
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.other.other && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Other - ${data.cpb_detail.other.other}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}
                            </div>
                          )}

                          {data.cpb_detail.circulatory_arrest && (
                            <div>
                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-left">
                                  <p className="has-text-weight-medium">
                                    Circulatory Arrest:
                                  </p>
                                </div>
                                <div className="level-right"></div>
                              </div>

                              {data.cpb_detail.circulatory_arrest
                                .arrest_time && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Arrest Time - ${data.cpb_detail.circulatory_arrest.arrest_time}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}

                              {data.cpb_detail.circulatory_arrest
                                .arrest_status && (
                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    {`Cerebral Protection - ${data.cpb_detail.circulatory_arrest.arrest_status}`}
                                  </div>
                                  <div className="level-right"></div>
                                </div>
                              )}
                            </div>
                          )}

                          {data.cpb_detail.notes && (
                            <div>
                              {data.cpb_detail.notes.case_note && (
                                <div>
                                  <p
                                    style={{
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                    className="has-text-weight-medium"
                                  >
                                    Case Notes:
                                  </p>
                                  <p
                                    style={{
                                      marginLeft: "40px",
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    {data.cpb_detail.notes.case_note}
                                  </p>
                                </div>
                              )}

                              {data.cpb_detail.notes.department_note && (
                                <div>
                                  <p
                                    style={{
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                    className="has-text-weight-medium"
                                  >
                                    Department Notes:
                                  </p>
                                  <p
                                    style={{
                                      marginLeft: "40px",
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    {data.cpb_detail.notes.department_note}
                                  </p>
                                </div>
                              )}
                            </div>
                          )}

                          <hr />
                        </div>
                      )}

                      {/* Autotransfusion */}

                      {data.procedure_id.includes(2) && data.auto_detail && (
                        <div>
                          <p className="title is-5 is-underlined">
                            Autotransfusion
                          </p>

                          <div
                            className="level w-100"
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                          >
                            <div className="level-left">
                              <p className="has-text-weight-medium">
                                Collection Start Date/Time:
                              </p>
                              <p style={{ marginLeft: "8px" }}>
                                {data.auto_detail.start_date}{" "}
                                {data.auto_detail.start_time}
                              </p>
                            </div>
                            <div className="level-right"></div>
                          </div>

                          <div
                            className="level w-100"
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                          >
                            <div className="level-left">
                              <p className="has-text-weight-medium">
                                Collection End Date/Time:
                              </p>
                              <p style={{ marginLeft: "8px" }}>
                                {data.auto_detail.end_date}{" "}
                                {data.auto_detail.end_time}
                              </p>
                            </div>
                            <div className="level-right"></div>
                          </div>

                          {data.auto_detail.or_number && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Operating Room:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.auto_detail.or_number}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          <div
                            className="level w-100"
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                          >
                            <div className="level-left">
                              <p className="has-text-weight-medium">
                                Procedure Type:
                              </p>
                              <p style={{ marginLeft: "8px" }}>
                                {getAutoProcType()}
                              </p>
                            </div>
                            {data.auto_detail.cardiac_note && (
                              <div className="level-right">
                                <p className="has-text-weight-medium">
                                  Cardiac:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.auto_detail
                                    ? data.auto_detail.cardiac_note
                                    : null}
                                </p>
                              </div>
                            )}
                          </div>

                          <div
                            className="level w-100"
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                          >
                            {data.auto_detail.anticoag && (
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Anticoagulant:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.auto_detail.anticoag}
                                </p>
                              </div>
                            )}
                            {data.auto_detail.amount && (
                              <div className="level-right">
                                <p className="has-text-weight-medium">
                                  Amount &#40;1000 Units/L&#41;:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.auto_detail.amount}
                                </p>
                              </div>
                            )}
                          </div>

                          {data.auto_detail.vacuum_level && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Vacuum Level:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  -{data.auto_detail.vacuum_level} mmHg
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.auto_detail.pre_hematocrit && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Preoperative HCT:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.auto_detail.pre_hematocrit} %
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          <hr />
                          {data.auto_detail.volumes &&
                            data.auto_detail.volumes.length > 0 && (
                              <div>
                                <p className="has-text-weight-medium">
                                  Blood Processed
                                </p>

                                <div className="level w-100">
                                  <div className="level-item"></div>
                                  <div className="level-item">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>Time</th>
                                          <th>Volume Processed</th>
                                          <th>Irrigation</th>
                                          <th>Anticoagulation</th>
                                          <th>Wash Amount</th>
                                          <th>Volume Returned</th>
                                          <th>Gross EBL</th>
                                          <th>Net EBL</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {data.auto_detail.volumes.map(
                                          (e, idx) => {
                                            return (
                                              <tr key={idx}>
                                                <td>{e.time}</td>
                                                <td>{e.vol_collected}</td>
                                                <td>{e.irrigation}</td>
                                                <td>{e.anticoag}</td>
                                                <td>{e.wash}</td>
                                                <td>{e.vol_returned}</td>
                                                <td>{e.gross_ebl}</td>
                                                <td>{e.net_ebl}</td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="level-item"></div>
                                </div>

                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Total Volume Collected:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.auto_detail
                                        ? data.auto_detail.total_vol_collected
                                        : null}{" "}
                                      mL
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>

                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Irrigation Used / Amount:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {`${
                                        data.auto_detail
                                          ? data.auto_detail.total_irrigation
                                            ? "Yes"
                                            : "No"
                                          : null
                                      } / ${
                                        data.auto_detail
                                          ? data.auto_detail.total_irrigation
                                            ? data.auto_detail.total_irrigation
                                            : 0
                                          : null
                                      }`}{" "}
                                      mL
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>

                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Total Anticoagulation Amount:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.auto_detail
                                        ? data.auto_detail.total_anticoag
                                        : null}{" "}
                                      mL
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>

                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Total Wash Volume:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.auto_detail
                                        ? data.auto_detail.total_wash
                                        : null}{" "}
                                      mL
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>

                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Total Volume Returned:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.auto_detail
                                        ? data.auto_detail.total_vol_returned
                                        : null}{" "}
                                      mL
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>

                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Other Loss:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.auto_detail
                                        ? data.auto_detail.total_vol_collected
                                        : null}{" "}
                                      mL
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>

                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Total Gross EBL:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.auto_detail
                                        ? data.auto_detail.gross_ebl
                                        : null}{" "}
                                      mL
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>

                                <div
                                  className="level w-100"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="level-left">
                                    <p className="has-text-weight-medium">
                                      Net EBL:
                                    </p>
                                    <p style={{ marginLeft: "8px" }}>
                                      {data.auto_detail
                                        ? data.auto_detail.net_ebl
                                        : null}{" "}
                                      mL
                                    </p>
                                  </div>
                                  <div className="level-right"></div>
                                </div>

                                <hr />
                              </div>
                            )}
                        </div>
                      )}

                      {/* Platelet */}

                      {data.procedure_id.includes(5) &&
                        data.platelet_detail && (
                          <div>
                            <p className="title is-5 is-underlined">
                              Platelet Therapy Procedure
                            </p>

                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Start Date:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.platelet_detail.start_date}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>

                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Collection Time:
                                </p>
                                <p style={{ marginLeft: "8px" }}>???</p>
                              </div>
                              <div className="level-right"></div>
                            </div>

                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Procedure Type:
                                </p>
                                <p style={{ marginLeft: "8px" }}>???</p>
                              </div>
                              <div className="level-right"></div>
                            </div>

                            {data.platelet_detail.anticoag_used && (
                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-left">
                                  <p className="has-text-weight-medium">
                                    Anticoagulant Used:
                                  </p>
                                  <p style={{ marginLeft: "8px" }}>
                                    {getPlateletAnticoag()}
                                  </p>
                                </div>
                                <div className="level-right"></div>
                              </div>
                            )}

                            {data.platelet_detail.preop_hematocrit && (
                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-left">
                                  <p className="has-text-weight-medium">
                                    Preoperative Hematocrit:
                                  </p>
                                  <p style={{ marginLeft: "8px" }}>
                                    {data.platelet_detail.preop_hematocrit}
                                  </p>
                                </div>
                                <div className="level-right"></div>
                              </div>
                            )}

                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              {data.platelet_detail.platelet_count && (
                                <div className="level-left">
                                  <p className="has-text-weight-medium">
                                    Patient Platelet Count:
                                  </p>
                                  <p style={{ marginLeft: "8px" }}>
                                    {data.platelet_detail.platelet_count}{" "}
                                    &#40;1000/ul&#41;
                                  </p>
                                </div>
                              )}
                              {data.platelet_detail.total_blood_collected && (
                                <div className="level-right">
                                  <p className="has-text-weight-medium">
                                    Total Blood Collected:
                                  </p>
                                  <p style={{ marginLeft: "8px" }}>
                                    {data.platelet_detail.total_blood_collected}{" "}
                                    mL
                                  </p>
                                </div>
                              )}
                            </div>

                            <hr />

                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium is-underlined">
                                  Platelet Rich
                                </p>
                              </div>
                              <div className="level-right">
                                <p className="has-text-weight-medium is-underlined">
                                  Platelet Poor
                                </p>
                              </div>
                            </div>

                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Amount:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.platelet_detail.rich_platelet} mL
                                </p>
                              </div>
                              <div className="level-right">
                                <p className="has-text-weight-medium">
                                  Amount:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.platelet_detail.poor_platelet} mL
                                </p>
                              </div>
                            </div>

                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Application Site:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.platelet_detail.rich_application_site}
                                </p>
                              </div>
                              <div className="level-right">
                                <p className="has-text-weight-medium">
                                  Application Site:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.platelet_detail.poor_application_site}
                                </p>
                              </div>
                            </div>

                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Application Site:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.platelet_detail.rich_applicator}
                                </p>
                              </div>
                              <div className="level-right">
                                <p className="has-text-weight-medium">
                                  Application Site:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.platelet_detail.poor_applicator}
                                </p>
                              </div>
                            </div>

                            <hr />

                            <p className="has-text-weight-medium is-underlined">
                              Activators Used:
                            </p>

                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">Type:</p>
                                <p style={{ marginLeft: "8px" }}>
                                  {getActivatorType(
                                    data.platelet_detail.activator_one_used,
                                    1
                                  )}
                                </p>
                              </div>
                              <div className="level-right">
                                <p className="has-text-weight-medium">Type:</p>
                                <p style={{ marginLeft: "8px" }}>
                                  {getActivatorType(
                                    data.platelet_detail.activator_two_used,
                                    2
                                  )}
                                </p>
                              </div>
                            </div>

                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Amount:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {getActivatorAmount(
                                    data.platelet_detail.activator_one_used,
                                    1
                                  )}
                                </p>
                              </div>
                              <div className="level-right">
                                <p className="has-text-weight-medium">
                                  Amount:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {getActivatorAmount(
                                    data.platelet_detail.activator_two_used,
                                    2
                                  )}
                                </p>
                              </div>
                            </div>

                            <hr />
                          </div>
                        )}

                      {/* Standby */}

                      {data.procedure_id.includes(7) &&
                        data.perfusion_detail && (
                          <div>
                            <p className="title is-5 is-underlined">Standby</p>

                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Start Date/Time:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.perfusion_detail.start_date}{" "}
                                  {data.perfusion_detail.start_time}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>

                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  End Date/Time:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.perfusion_detail.end_date}{" "}
                                  {data.perfusion_detail.end_time}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>

                            {data.perfusion_detail.or_number && (
                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-left">
                                  <p className="has-text-weight-medium">
                                    Operating Room #:
                                  </p>
                                  <p style={{ marginLeft: "8px" }}>
                                    {data.perfusion_detail.or_number}
                                  </p>
                                </div>
                                <div className="level-right"></div>
                              </div>
                            )}

                            {data.perfusion_detail.classification && (
                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-left">
                                  <p className="has-text-weight-medium">
                                    Procedure Type:
                                  </p>
                                  <p style={{ marginLeft: "8px" }}>
                                    {data.perfusion_detail.classification}
                                  </p>
                                </div>
                                <div className="level-right"></div>
                              </div>
                            )}

                            {data.perfusion_detail.cath_note && (
                              <div
                                className="level w-100"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div className="level-left">
                                  <p className="has-text-weight-medium">
                                    Cath Lab:
                                  </p>
                                  <p style={{ marginLeft: "8px" }}>
                                    {data.perfusion_detail.cath_note}
                                  </p>
                                </div>
                                <div className="level-right"></div>
                              </div>
                            )}

                            <hr />
                          </div>
                        )}

                      {/* HIPEC */}

                      {data.procedure_id.includes(3) && data.hipec_detail && (
                        <div>
                          <p className="title is-5 is-underlined">HIPEC</p>

                          <div
                            className="level w-100"
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                          >
                            <div className="level-left">
                              <p className="has-text-weight-medium">
                                Start Date/Time:
                              </p>
                              <p style={{ marginLeft: "8px" }}>
                                {data.hipec_detail.start_date}{" "}
                                {data.hipec_detail.start_time}
                              </p>
                            </div>
                            <div className="level-right"></div>
                          </div>

                          <div
                            className="level w-100"
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                          >
                            <div className="level-left">
                              <p className="has-text-weight-medium">
                                End Date/Time:
                              </p>
                              <p style={{ marginLeft: "8px" }}>
                                {data.hipec_detail.end_date}{" "}
                                {data.hipec_detail.end_time}
                              </p>
                            </div>
                            <div className="level-right"></div>
                          </div>

                          {data.hipec_detail.or_number && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Operating Room #:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.hipec_detail.or_number}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.hipec_detail.priming_solution && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Priming Solution
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.hipec_detail.priming_solution == 1
                                    ? "0.9% Sodium Chloride"
                                    : data.hipec_detail.priming_solution == 2
                                    ? "Lactate Ringers"
                                    : ""}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.hipec_detail.min_flow && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Min Flow &#40;L/min&#41;:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.hipec_detail.min_flow}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.hipec_detail.max_flow && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Max Flow &#40;L/min&#41;:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.hipec_detail.max_flow}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.hipec_detail.max_inflow && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Max Inflow Temp &#40;C&#41;:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.hipec_detail.max_inflow}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.hipec_detail.max_outflow && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Max Outflow Temp &#40;C&#41;:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.hipec_detail.max_outflow}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.hipec_detail.agent && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Chemotherapy Agent:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.hipec_detail.agent}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.hipec_detail.doses && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Number of Doses:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.hipec_detail.doses}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.hipec_detail.dose_amount && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Chemotherapy Dose &#40;mg&#41;:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.hipec_detail.dose_amount}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.hipec_detail.washout_volume && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Washout Volume &#40;ml&#41;:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.hipec_detail.washout_volume}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.hipec_detail.max_temp && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Max Core Temp:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.hipec_detail.max_temp}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.hipec_detail.case_comments && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">Notes:</p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.hipec_detail.case_comments}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          <hr />
                        </div>
                      )}

                      {/* ECLS */}

                      {data.procedure_id.includes(4) && data.ecls_detail && (
                        <div>
                          <p className="title is-5 is-underlined">ECLS</p>

                          <div
                            className="level w-100"
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                          >
                            <div className="level-left">
                              <p className="has-text-weight-medium">
                                Start Date/Time:
                              </p>
                              <p style={{ marginLeft: "8px" }}>
                                {data.ecls_detail.start_date}{" "}
                                {data.ecls_detail.start_time}
                              </p>
                            </div>
                            <div className="level-right"></div>
                          </div>

                          <div
                            className="level w-100"
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                          >
                            <div className="level-left">
                              <p className="has-text-weight-medium">
                                End Date/Time:
                              </p>
                              <p style={{ marginLeft: "8px" }}>
                                {data.ecls_detail.end_date}{" "}
                                {data.ecls_detail.end_time}
                              </p>
                            </div>
                            <div className="level-right"></div>
                          </div>

                          {data.ecls_detail.or_number && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Operating Room #:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.or_number}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.admission_type && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Admission Type:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.admission_type}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.cardiac_arrest && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Pre-Cardiac Arrest:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.cardiac_arrest}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.support && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Pre-Support:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.support}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.pupil_response && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Pre Pupil Response:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.pupil_response}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.support_type && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Support Type:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.support_type}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.mode && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  ECLS Mode:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.mode}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.outflow_cannulation && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Outflow Cannulation:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.outflow_cannulation}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.inflow_cannulation && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Inflow Cannulation:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.inflow_cannulation}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.mechanical_complication && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Mechanical Complication:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.mechanical_complication}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.hemorrhage_complication && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Hemorrhage Complication:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.hemorrhage_complication}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.neurological_complication && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Neurological Complication:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.neurological_complication}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.renal_complication && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Renal Complication:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.renal_complication}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.cardio_complication && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Cardio Complication:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.cardio_complication}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.pulmonary_complication && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Pulmonary Complication:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.pulmonary_complication}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.infectious_complication && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Infectious Complication:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.infectious_complication}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.metabolic_complication && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Metabolic Complication:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.metabolic_complication}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.limb_complication && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Limb Complication:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.limb_complication}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.transported && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Transported on ECLS:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.transported}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.cannula_repair && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Cannula Site Repair:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.cannula_repair}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.discontinuing && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Discontinuing Reason:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.discontinuing}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          {data.ecls_detail.withdrawn_reason && (
                            <div
                              className="level w-100"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              <div className="level-left">
                                <p className="has-text-weight-medium">
                                  Withdrawn Reason:
                                </p>
                                <p style={{ marginLeft: "8px" }}>
                                  {data.ecls_detail.withdrawn_reason}
                                </p>
                              </div>
                              <div className="level-right"></div>
                            </div>
                          )}

                          <hr />
                        </div>
                      )}

                      {/* Charting */}

                      {data.charts && data.charts.length && (
                        <div>
                          <p className="title is-5 is-underlined">
                            Charting Log
                          </p>

                          <table style={{ border: "1px solid" }}>
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  Time
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  Event
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  Description
                                </th>
                                {chartColumns.map((e, idx) => {
                                  return (
                                    <th
                                      style={{
                                        textAlign: "center",
                                        border: "1px solid",
                                      }}
                                      key={idx}
                                    >
                                      {e.field.name} &#40;{e.field.unit}&#41;
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {data.charts.length > 0
                                ? data.charts[0].events.length > 0
                                  ? data.charts[0].events
                                      .sort(
                                        (a, b) =>
                                          Date.parse(a.time) -
                                          Date.parse(b.time)
                                      )
                                      .map((e, idx) => {
                                        return (
                                          <tr key={idx}>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {e.time}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {e.event}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {e.details}
                                            </td>
                                            {chartColumns.map((x, idx) => {
                                              return (
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                    border: "1px solid",
                                                  }}
                                                  key={idx + 100}
                                                >
                                                  {isNaN(
                                                    parseFloat(
                                                      e[x.field.column]
                                                    )
                                                  )
                                                    ? ""
                                                    : parseFloat(
                                                        e[x.field.column]
                                                      )}
                                                </td>
                                              );
                                            })}
                                          </tr>
                                        );
                                      })
                                  : null
                                : null}
                            </tbody>
                          </table>

                          <br />

                          <p className="title is-5 is-underlined">
                            Lab Values Recorded
                          </p>

                          <table style={{ border: "1px solid" }}>
                            <thead>
                              <tr style={{ border: "1px solid" }}>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  Date Time
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  PH
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  CO2 &#40;mmHg&#41;
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  PO2 &#40;mmHg&#41;
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  BE &#40;mmol/L&#41;
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  HCO3 &#40;mmol/L&#41;
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  SO2 &#40;%&#41;
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  Na+ &#40;mmol/L&#41;
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  K+ &#40;mmol/L&#41;
                                </th>
                                {getLabColumnInfo("iCa2") ? (
                                  <th
                                    style={{
                                      textAlign: "center",
                                      border: "1px solid",
                                    }}
                                  >
                                    Ca &#40;mmol/L&#41;
                                  </th>
                                ) : null}
                                {getLabColumnInfo("iCa1") ? (
                                  <th
                                    style={{
                                      textAlign: "center",
                                      border: "1px solid",
                                    }}
                                  >
                                    iCa &#40;mg/dL&#41;
                                  </th>
                                ) : null}
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  HCT &#40;%&#41;
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  Hgb &#40;gm/dL&#41;
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  LAC &#40;mg/dL&#41;
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  ACT &#40;Sec&#41;
                                </th>
                                {getLabColumnInfo("hepcon1") ? (
                                  <th
                                    style={{
                                      textAlign: "center",
                                      border: "1px solid",
                                    }}
                                  >
                                    HEPCON &#40;mg/kg&#41;
                                  </th>
                                ) : null}
                                {getLabColumnInfo("hepcon2") ? (
                                  <th
                                    style={{
                                      textAlign: "center",
                                      border: "1px solid",
                                    }}
                                  >
                                    HEPCON &#40;U/kg&#41;
                                  </th>
                                ) : null}
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid",
                                  }}
                                >
                                  GLU &#40;mg/dL&#41;
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.charts.length > 0
                                ? data.charts[0].events.length > 0
                                  ? data.charts[0].events
                                      .filter((x) => {
                                        var valid = false;
                                        if (x.lab_event != null) {
                                          valid = true;
                                        }
                                        return valid;
                                      })
                                      .sort(
                                        (a, b) =>
                                          Date.parse(a.time) -
                                          Date.parse(b.time)
                                      )
                                      .map((e, idx) => {
                                        return (
                                          <tr
                                            style={{ border: "1px solid" }}
                                            key={idx}
                                          >
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {e.time.split(" ")[0]}{" "}
                                              {e.time.split(" ")[1]}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {isNaN(
                                                parseFloat(`${e.lab_event.ph}`)
                                              )
                                                ? ""
                                                : parseFloat(
                                                    `${e.lab_event.ph}`
                                                  )}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {isNaN(
                                                parseFloat(`${e.lab_event.c02}`)
                                              )
                                                ? ""
                                                : parseFloat(
                                                    `${e.lab_event.c02}`
                                                  )}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {isNaN(
                                                parseFloat(`${e.lab_event.p02}`)
                                              )
                                                ? ""
                                                : parseFloat(
                                                    `${e.lab_event.p02}`
                                                  )}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {isNaN(
                                                parseFloat(`${e.lab_event.be}`)
                                              )
                                                ? ""
                                                : parseFloat(
                                                    `${e.lab_event.be}`
                                                  )}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {isNaN(
                                                parseFloat(
                                                  `${e.lab_event.hc03}`
                                                )
                                              )
                                                ? ""
                                                : parseFloat(
                                                    `${e.lab_event.hc03}`
                                                  )}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {isNaN(
                                                parseFloat(`${e.lab_event.s02}`)
                                              )
                                                ? ""
                                                : parseFloat(
                                                    `${e.lab_event.s02}`
                                                  )}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {isNaN(
                                                parseFloat(
                                                  `${e.lab_event.na_plus}`
                                                )
                                              )
                                                ? ""
                                                : parseFloat(
                                                    `${e.lab_event.na_plus}`
                                                  )}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {isNaN(
                                                parseFloat(
                                                  `${e.lab_event.k_plus}`
                                                )
                                              )
                                                ? ""
                                                : parseFloat(
                                                    `${e.lab_event.k_plus}`
                                                  )}
                                            </td>
                                            {getLabColumnInfo("iCa2") ? (
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  border: "1px solid",
                                                }}
                                              >
                                                {isNaN(
                                                  parseFloat(
                                                    `${e.lab_event.iCa2}`
                                                  )
                                                )
                                                  ? ""
                                                  : parseFloat(
                                                      `${e.lab_event.iCa2}`
                                                    )}
                                              </td>
                                            ) : null}
                                            {getLabColumnInfo("iCa1") ? (
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  border: "1px solid",
                                                }}
                                              >
                                                {isNaN(
                                                  parseFloat(
                                                    `${e.lab_event.iCa1}`
                                                  )
                                                )
                                                  ? ""
                                                  : parseFloat(
                                                      `${e.lab_event.iCa1}`
                                                    )}
                                              </td>
                                            ) : null}
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {isNaN(
                                                parseFloat(`${e.lab_event.hct}`)
                                              )
                                                ? ""
                                                : parseFloat(
                                                    `${e.lab_event.hct}`
                                                  )}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {isNaN(
                                                parseFloat(`${e.lab_event.hgb}`)
                                              )
                                                ? ""
                                                : parseFloat(
                                                    `${e.lab_event.hgb}`
                                                  )}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {isNaN(
                                                parseFloat(`${e.lab_event.lac}`)
                                              )
                                                ? ""
                                                : parseFloat(
                                                    `${e.lab_event.lac}`
                                                  )}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {isNaN(
                                                parseFloat(`${e.lab_event.act}`)
                                              )
                                                ? ""
                                                : parseFloat(
                                                    `${e.lab_event.act}`
                                                  )}
                                            </td>
                                            {getLabColumnInfo("hepcon1") ? (
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  border: "1px solid",
                                                }}
                                              >
                                                {isNaN(
                                                  parseFloat(
                                                    `${e.lab_event.hepcon1}`
                                                  )
                                                )
                                                  ? ""
                                                  : parseFloat(
                                                      `${e.lab_event.hepcon1}`
                                                    )}
                                              </td>
                                            ) : null}
                                            {getLabColumnInfo("hepcon2") ? (
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  border: "1px solid",
                                                }}
                                              >
                                                {isNaN(
                                                  parseFloat(
                                                    `${e.lab_event.hepcon2}`
                                                  )
                                                )
                                                  ? ""
                                                  : parseFloat(
                                                      `${e.lab_event.hepcon2}`
                                                    )}
                                              </td>
                                            ) : null}
                                            <td
                                              style={{
                                                textAlign: "center",
                                                border: "1px solid",
                                              }}
                                            >
                                              {isNaN(
                                                parseFloat(`${e.lab_event.glu}`)
                                              )
                                                ? ""
                                                : parseFloat(
                                                    `${e.lab_event.glu}`
                                                  )}
                                            </td>
                                          </tr>
                                        );
                                      })
                                  : null
                                : null}
                            </tbody>
                          </table>

                          <hr />
                        </div>
                      )}

                      {/* Items */}

                      <div className="level w-100">
                        <div className="level-item"></div>
                        <div className="level-item">
                          <p className="title is-3">Items</p>
                        </div>
                        <div className="level-item"></div>
                      </div>

                      <hr />

                      <table className="table is-fullwidth">
                        <thead>
                          <tr>
                            <th>Item Type</th>
                            <th>Manufacturer</th>
                            <th>Cat #</th>
                            <th>Description</th>
                            <th>Item Category</th>
                            <th>Lot# / Serial# / Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.items.map((e, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{e.item.item_type_name}</td>
                                <td>{e.item.manufacturer_name}</td>
                                <td>{e.item.catalog}</td>
                                <td>{e.item.description}</td>
                                <td>{e.item.category_name}</td>
                                <td>{e.comment}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      <br />

                      <hr />

                      <br />
                      <br />
                      <br />

                      {/* <div className="level w-100" style={{ marginBottom: "5px" }}>
                                            <div className="level-left">
                                                <p className="title is-8">Signature:</p>
                                            </div>
                                            <div className="level-right">
                                                <div style={{ display: "flex", flexDirection: "column", textAlign: "center"}}>  
                                                    <img height={200} width={500} style={{ objectFit: "fill" }} src={signature} />
                                                    <p>{userSigLoaded ?? ""}</p>
                                                    <p>{today.toLocaleString('en-us',dateOptions)}</p>
                                                </div>
                                            </div>
                                        </div> */}

                      {perfSigLoaded && (
                        <div
                          className="level w-100"
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          <div className="level-left">
                            <p className="title is-8">
                              Perfusionist Signature:
                            </p>
                          </div>

                          <div className="level-right">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                              }}
                            >
                              <img
                                height={200}
                                width={500}
                                style={{ objectFit: "fill" }}
                                src={perfSignature}
                              />
                              <p>{perfSigLoaded}</p>
                              <p>
                                {today.toLocaleString("en-us", dateOptions)}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {autoSigLoaded && (
                        <div
                          className="level w-100"
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          <div className="level-left">
                            <p className="title is-8">
                              Autotransfusionist Signature:
                            </p>
                          </div>

                          <div className="level-right">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                              }}
                            >
                              <img
                                height={200}
                                width={500}
                                style={{ objectFit: "fill" }}
                                src={autoSignature}
                              />
                              <p>{autoSigLoaded}</p>
                              <p>
                                {today.toLocaleString("en-us", dateOptions)}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {surgeonSigLoaded && (
                        <div
                          className="level w-100"
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          <div className="level-left">
                            <p className="title is-8">Surgeon Signature:</p>
                          </div>

                          <div className="level-right">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                              }}
                            >
                              <img
                                height={200}
                                width={500}
                                style={{ objectFit: "fill" }}
                                src={surgeonSignature}
                              />
                              <p>{surgeonSigLoaded}</p>
                              <p>
                                {today.toLocaleString("en-us", dateOptions)}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot className="table-footer has-background-white">
                <tr>
                  <td>
                    <div className="level w-100">
                      <div
                        style={{ borderTop: "solid", borderTopWidth: "1px" }}
                        className="level-item"
                      >
                        Hospital Address: {data?.facility?.address},{" "}
                        {data?.facility?.facility_city},{" "}
                        {data?.facility?.facility_state}-
                        {data?.facility?.facility_zip}
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <button
            onClick={props.closeModal}
            className="modal-close is-large"
            aria-label="close"
          ></button>
        </section>
        <footer className="modal-card-foot">
          <div className="level w-100">
            <div className="level-left">
              <button className="button is-danger" onClick={props.closeModal}>
                Close
              </button>
            </div>
            <div className="level-right">
              <button className="button is-success" onClick={handlePrint}>
                Print
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default PdfProcedure;
