import React, { useEffect, useState } from 'react';
import { calculateBMI, calculateBSA, calculateCI, calculateDO2, calculateEBV, calculateFlow } from '../helpers/patient';
import Select from 'react-select';

function PatientBloodForm(props) {
  const {caseData,setCaseData,patientData} = props

  if(typeof caseData === 'undefined' || typeof patientData === 'undefined') return null

  const setBloodType = (value) => {
    const newCaseData = {...caseData}
    newCaseData.patient_blood_type = value
    setCaseData(newCaseData)
  }

  const bloodTypeOptions = [
    {label:"A+",value:"A+"},
    {label:"A-",value:"A-"},
    {label:"B+",value:"B+"},
    {label:"B-",value:"B-"},
    {label:"AB+",value:"AB+"},
    {label:"AB-",value:"AB-"},
    {label:"O+",value:"O+"},
    {label:"O-",value:"O-"},
    {label:"Unknown",value:"UNK"}
  ]

  const formatCI = (ci) => {
    let ciString = ci.toString()
    return ciString.includes('.') ? ciString : (ciString + '.0')
  }

  const ciValues = [1.4,1.6,1.8,2,2.2,2.4,2.6,2.8,3]

  return <div className='has-background-grey-lighter rounded w-100 p-3 my-5'>
    <div className='columns'>
      <div className='column is-2'>
        <p className='has-text-weight-bold mb-2'>BSA</p>
        <p className='has-background-white rounded w-100 p-2'>{calculateBSA(caseData)}</p>
      </div>
      <div className='column is-2'>
        <p className='has-text-weight-bold mb-2'>BMI</p>
        <p className='has-background-white rounded w-100 p-2'>{calculateBMI(caseData)}</p>
      </div>
      <div className='column is-2'>
        <p className='has-text-weight-bold mb-2'>EBV</p>
        <p className='has-background-white rounded w-100 p-2'>{calculateEBV(caseData,patientData)}</p>
      </div>
      <div className='column is-2'>
        {/* <p className='has-text-weight-bold mb-2'>DO2</p>
        <p className='has-background-white rounded w-100 p-2'>{calculateDO2(caseData)}</p> */}
      </div>
      <div className='column is-4'>
      <p className='has-text-weight-bold mb-2'>Blood Type</p>
        <Select
          placeholder={"Select..."}
          options={bloodTypeOptions}
          onChange={(option) => setBloodType(option.value)}
          value={bloodTypeOptions.find(option => option.value === caseData.patient_blood_type)}
        />
      </div>
    </div>

    <div className="table-container">
      <table className="table is-narrow is-bordered is-fullwidth">
        <tbody>
          <tr>
            <th>CI</th>
            {ciValues.map((value,i) => <td key={i} className='has-text-centered'>{formatCI(value)}</td>)}
          </tr>
          <tr>
            <th>Flow</th>
            {ciValues.map((value,i) => <td key={i} className='has-text-centered is-size-7 pt-2'>{calculateFlow(caseData,value)}</td>)}
          </tr>
        </tbody>
      </table>
    </div>
  </div>
}

export default PatientBloodForm