import React, { useEffect, useState, useMemo } from "react";
import DynamicForm from "../../../components/DynamicForm";
import DataTable from 'react-data-table-component';
import SetupModal from "../../../components/SetupModal";
import API from "../../../../services/API";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

import facilitySearch from "../../../forms/facilitySearch";
import facilityModal from "../../../forms/facilityModal";
import ErrorModal from "../../../components/ErrorModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import VisitTimer from "../../../components/VisitTimer"
import Session from "../../../../services/Session"

function ProgramSetup(props){
  const [filterValues,setFilterValues] = useState();
  const [facilities, setFacilities] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [showSetupModal,setShowSetupModal] = useState(false);
  const [shortNameOptions, setShortNameOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [editValues, setEditValues] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [logo, setLogo] = useState()
  const [originalLogo, setOriginalLogo] = useState()
  
  const onFilterChange = (values) => {
    setFilterValues(values)
  }

  const onEditChange = (values) => {
    if(values && values.logo) {
      setLogo(values.logo.files)
      values.logo = values.logo.value
    }
    setEditValues(values)
  }

  const onButtonPressed = (id) => {
    if(id == "clear") {
      setFilterValues([])
      
    } else if (id == "create_new") {
      setShowSetupModal(true)
    } else {
      
    }
  }

  const onSave = () => {
    if(!isSaving) {
      setIsSaving(true)
      let data = {
        'name':editValues['name'],
        'short_name':editValues['short_name'],
        'attn': editValues['attn'],
        'ical_url': editValues['ical_url'],
        'records_dept':editValues['records_dept'],
        'status':(editValues['status'] && editValues['status'] == 'false')?false:true,
        'address':editValues['address'],
        'zip':findZipID(editValues['zip']),
        'manage_inventory':(editValues['manage_inventory'] && editValues['manage_inventory'] == 'false')?false:true,
        'facility_type':editValues['facility_type'],
        'facility_contact_name':editValues['facility_contact_name'],
        'facility_contact_email':editValues['facility_contact_email'],
        'facility_billing_contact':editValues['facility_billing_contact'],
        'facility_billing_contact_email':editValues['facility_billing_contact_email'],
        'facility_skills':editValues['facility_skills'],
      }
      let method = isEditMode ? 'PUT' : 'POST'
      let uri = isEditMode ? `/api/facilities/${editValues.id}/` : '/api/facilities/'
      API.query(method,uri,{},data, function(resp) {
        // let newFacilities = [...facilities]
        // if(isEditMode) {
        //   let i = newFacilities.findIndex(j => j.id == editValues.id)
        //   if(i > -1) {
        //     data['id'] = editValues.id
        //     newFacilities[i] = data
        //   }
        // }else {
        //   data['id'] = resp.id
        //   newFacilities.push(data)
        // }
        API.query('GET',`/api/facilities/`,{},{},async function(response) {
          if(logo && originalLogo) {
            await replaceLogo()
          } else if(logo) {
            await uploadLogo(resp.id)
          }
          if(props.permissions.includes(11) || props.extraPermissions.includes(11)) {
            let user = Session.get('user')
            let filtered = response.filter((obj) => {
              var i, abort = false, valid = false
              for(i=0; i < user.facilities.length && !abort; i++) {
                if(user.facilities[i] == obj.id) {
                  valid = true
                  abort = true
                }
              }
              return valid
            })
            setFacilities(filtered)
          } else {      
            setFacilities(response)
          }
          setIsSaving(false)
          props.updateID()
          setShowSetupModal(false)
          setEditValues([])
        },onDataLoadError)
      },onDataLoadError)
    }
  }

  const replaceLogo = async () => {
    let patch_data = {
      'logo': null
    }
    API.query('PATCH',`/api/facilities/${editValues.id}/`,{},patch_data,function(resp) {
      const files = [...logo]
      const file = files[0]
      const path = `${Math.floor(Math.random() * 100)}${file.name}`
      let params = {
        'type': 'new_file',
        'path': `facility/${editValues.id}/logo/${path}`
      }
      let data = {
        'file': file
      }
      API.uploadFile('POST',`/api/files/`,params,data,function(resp) {
        let data = {
          'logo': resp[0].id
        }
        API.query('PATCH',`/api/facilities/${editValues.id}/`,{},data,function(resp) { 
          setLogo();
        }, () => { setErrorMessage("Failed attaching logo to facility."); setShowErrorModal(true); })
      }, (response) => props.setErrorMessage(response))
    },onDataLoadError)
  }

  const uploadLogo = async (id) => {
    const files = [...logo]
    const file = files[0]
    let params = {
      'type': 'new_file',
      'path': `facility/${id}/logo/${file.name}`
    }
    let data = {
      'file': file
    }
    API.uploadFile('POST',`/api/files/`,params,data,function(resp) {
      let data = {
        'logo': resp[0].id
      }
      API.query('PATCH',`/api/facilities/${id}/`,{},data,function(resp) { setLogo(); }, () => { setErrorMessage("Failed attaching logo to facility."); setShowErrorModal(true); })
    }, (response) => props.setErrorMessage(response))
  }

  // const filteredStates = stateOptions.filter((obj) => {
  //   var i, abort = false, valid = false
  //   for(i=0; i < )
  // })

  const filteredStates = [...new Set(stateOptions)]; 

  const findZipID = (zip) => {
    // var i, zipID, abort = false
    // for(i=0; i< zipcodes.length && !abort; i++) {
    //   if(zipcodes[i].zip == zip) {
    //     zipID = zipcodes[i].id
    //     abort = true
    //   }
    // }
    let newzip = zipcodes.find((e) => e.zip == zip)
    if(newzip) {
      return newzip.id
    } else {
      return 1
    }
  }

  const onSetupModalClosed = () => {
    API.query('GET',`/api/facilities/`,{},{},function(resp) {
      if(props.permissions.includes(11) || props.extraPermissions.includes(11)) {
        let user = Session.get('user')
        let filtered = resp.filter((obj) => {
          var i, abort = false, valid = false
          for(i=0; i < user.facilities.length && !abort; i++) {
            if(user.facilities[i] == obj.id) {
              valid = true
              abort = true
            }
          }
          return valid
        })
        setFacilities(filtered)
      } else {      
        setFacilities(resp)
      }
      props.updateID()
      setShowSetupModal(false)
      setEditValues([])
      setIsEditMode(false)
      setOriginalLogo()
      setLogo()
    },onDataLoadError)
  }

  const onEditClicked = (row) => {
    if(row && row.logo) {
      let params = {
        'type': 'render_file',
        'file_id': row.logo
      }
      setOriginalLogo(row.logo)
      API.downloadFile('POST',`/api/files/`,params,{},function(resp) {
        if(row.zip != null) {
          let zip = zipcodes.find((e) => e.id == row.zip)
          row.zip = zip.zip
        }
        if(resp) {
          row.logo_file = URL.createObjectURL(resp)
          row.logo = ""
        }
        row.status = row.status?"true":"false"
        row.manage_inventory = row.manage_inventory?"true":"false"
        row.facility_type = `${row.facility_type}`
        setEditValues(row)
        props.updateID(row.id)
        setShowSetupModal(true)
        setIsEditMode(true)
      },() => props.setErrorMessage("Failed fetching logo."))
    } else {
      if(row.zip != null) {
        let zip = zipcodes.find((e) => e.id == row.zip)
        row.zip = zip.zip
      }
      row.status = row.status?"true":"false"
      row.manage_inventory = row.manage_inventory?"true":"false"
      row.facility_type = `${row.facility_type}`
      setEditValues(row)
      props.updateID(row.id)
      setOriginalLogo()
      setShowSetupModal(true)
      setIsEditMode(true)
    }
  }

  const onDeleteClicked = (row) => {
    setEditValues(row)
    setShowConfirmationModal(true)
  }

  const onConfirmDelete = () => {
    API.query("DELETE",`/api/facilities/${editValues.id}/`,{},{}, function(resp) {
      let newFacilities = facilities.filter(j => j.id !== editValues.id)
      setFacilities(newFacilities)
      setShowConfirmationModal(false)
      setEditValues([])
    },onDataLoadError)
  }

  const filterByFacilityType = (obj) => {
    var valid, y, abort = false
    if(filterValues.hospital_type && filterValues.hospital_type.length != 0) {
      if(obj.facility_type) {
        for(y=0; y < filterValues.hospital_type.length && !abort; y++) {
          if(filterValues.hospital_type[y] == obj.facility_type) {
            valid = true
            abort = true
          } else {
            valid = false
          }
        }
      } else {
        valid = false
      }
    } else {
      valid = true
    }
    return valid
  }

  const filterByShortName = (obj) => {
    var valid, y, abort=false
    if (filterValues.short_name && filterValues.short_name.length != 0) {
      for (y = 0; y < filterValues.short_name.length && !abort; y++) {
        if (filterValues.short_name[y] == obj.id) {
          valid = true
          abort = true
        } else {
          valid = false
        }
      }
    } else {
      valid = true
    }

    return valid
  }

  const filterByItself = (obj) => {
    var valid
    if(filterValues.hospital && filterValues.hospital.length != 0) {
      if(filterValues.hospital.findIndex(f => obj.id == f) == -1) {
        valid = false 
      } else { 
        valid = true 
      }
    } else {
      valid = true
    }

    return valid
  }

  const filteredShortNameOptions = shortNameOptions.filter((short_name) => {
    let valid = false
    if(short_name.label != "") {valid=true}
    return valid
  })

  const filteredFacilities =  facilities.filter((obj) =>{
    let valid = false
    if(filterByFacilityType(obj)) {
      if(filterByShortName(obj)) {
        if(filterByItself(obj)) {
          valid = true
        }
      }
    }

    return valid
  })

  const columns = useMemo(() => [
    {name:'Hospital Name',selector: row => row.name,sortable:true},
    {name:'Short Name',selector: row => row.short_name,sortable:true},
    {name:'City',selector: row => row.facility_city,sortable:true},
    {name:'State',selector: row => row.facility_state,sortable:true},
    {name:'Status',selector: row => row.status ? "Active" : "Inactive"},
    {name:'Actions',width:"75px",selector: row =><div>
    <a title="Edit" onClick={() => onEditClicked(row)}><FaPencilAlt size={14} /></a>
    <a className="ml-3" title="Delete" onClick={() => onDeleteClicked(row)}><FaTrashAlt size={14} /></a>
    </div>}
  ])

  useEffect(() => {
    let queries = [
      {'method':'GET','path':'/api/facilities/',},
      {'method':'GET','path':'/api/zipcodes/',}
    ]
    API.all(queries, function(resp) {
      if(props.permissions.includes(11) || props.extraPermissions.includes(11)) {
        let user = Session.get('user')
        let filtered = resp[0].filter((obj) => {
          var i, abort = false, valid = false
          for(i=0; i < user.facilities.length && !abort; i++) {
            if(user.facilities[i] == obj.id) {
              valid = true
              abort = true
            }
          }
          return valid
        })
        setFacilities(filtered)
        setFacilityOptions(filtered.map(i => ({"label":i.name,"value":i.id})))
      } else {      
        setFacilityOptions(resp[0].map(i => ({"label":i.name,"value":i.id})))
        setFacilities(resp[0])
      }
      setZipcodes(resp[1])
      setShortNameOptions(resp[0].map(i=> ({"label":i.short_name,"value":i.id})))
      setCityOptions(resp[1].map(i => ({"label":i.city,"value":i.id})))
      setStateOptions(resp[1].map(i =>({"label":i.state,"value":i.id})))
      if(props.id != null) {
        let option = resp[0].find((i) => i.id == props.id)
        setEditValues(option)
        setShowSetupModal(true)
      }
    },onDataLoadError)
  }, []);

  const onDataLoadError = (error) => {
    setErrorMessage(error)
    setShowErrorModal(true)
    setIsSaving(false)
  }

  const visited = () => {
    // get pageviewhistory id from user session data
    var user = Session.get('user')
    API.query('GET',`/api/pageviewhistory/${user.page_view_history}/`,{},{},function(response) {
      let data = {
        "setup_facility": response.setup_facility + 1
      }
      API.query('PATCH',`/api/pageviewhistory/${user.page_view_history}/`,{},data,function(resp) {}, onDataLoadError)
    }, onDataLoadError)
  }

  return <div>
    <p className="title">Program / Hospital Setup</p>
    <hr/>
    <DynamicForm
      form={facilitySearch}
      values={filterValues}
      onValuesChanged={onFilterChange}
      onButtonPressed={onButtonPressed}
      source={{ "facilities": facilityOptions, "short_name": filteredShortNameOptions, "cities": cityOptions, "states": filteredStates }}
    />
    <hr/>

    {
      showSetupModal
      && 
      <SetupModal
        titleString={"Facility Setup"}
        form={facilityModal}
        editValues={editValues}
        onEditChange={onEditChange}
        isSaving={isSaving}
        onSave={onSave}
        //source={{"facilityTypes":facilityTypes}}
        onClose={onSetupModalClosed}
      />
    }
   
    {
      showErrorModal
      && 
      <ErrorModal
        message={errorMessage}
        onClose={() => setShowErrorModal(false)}
      />
    }

    {
      showConfirmationModal
      && 
      <ConfirmationModal
        message={"You are about to delete a Role. This cannot be undone."}
        onConfirm={onConfirmDelete}
        onClose={() => setShowConfirmationModal(false)}
      />
    }
    
    <p className="title is-5">Results</p>
    <DataTable
      striped
      pagination
      data={filteredFacilities}
      columns={columns}
    />

    <VisitTimer visited={visited}/>
    
  </div>
}

export default ProgramSetup