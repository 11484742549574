import React from "react";

function BooleanRadioGroup({value,setValue}) {
  const onChange = (e) => {
    setValue(e.target.value === "yes")
  }

  return <div className="control">
    <label className="radio">
      <input type="radio" value="yes" checked={value===true} onChange={onChange} />
      &nbsp;Yes
    </label>
    <label className="radio">
      <input type="radio" value="no" checked={value===false} onChange={onChange} />
      &nbsp;No
    </label>
  </div>
}

export default BooleanRadioGroup;