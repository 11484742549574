import React from 'react';
import DynamicForm from './DynamicForm';
import descriptionModalForm from "../forms/descriptionModal"

function DescriptionModal(props) {
    const { onClose, onSave, descriptionValues, onDescriptionChanged } = props

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title">Notes</p>
            </header>
            <section className="modal-card-body p-0">
                <div className="p-5">
                    <div className="has-background-white rounded w-100">
                        <DynamicForm
                            form={descriptionModalForm}
                            onValuesChanged={onDescriptionChanged}
                            values={descriptionValues}
                        />
                        <button onClick={() => onClose()} className="modal-close is-large" aria-label="close"></button>
                    </div>
                </div>
            </section>

            <footer className="modal-card-foot">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="button is-danger" onClick={() => onClose()}>Cancel</button>
                    </div>
                    <div className="level-right">
                        <button className="button is-success" onClick={() => onSave()}>Save</button>
                    </div>
                </div>
            </footer>
        </div>
    </div>
}

export default DescriptionModal