import React, { useRef, useMemo, useState, useEffect } from "react";
import { useReactToPrint } from 'react-to-print';
import { FaRegSquare, FaRegCheckSquare } from "react-icons/fa";

function PdfHemasource(props) {
    const data = props.data
    const componentRef = useRef()
    const today = new Date()

    console.log('data',data)

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getPersonnel = () => {
        var surgeon = '', anes = '', auto1 = '', auto2 = '', personnel = data.personnel_roles
        for(let i=0; i < personnel.length; i++) {
            if(personnel[i].job.name.includes("urgeon")) {
                surgeon = personnel[i].user_full_name
            } else if(personnel[i].job.name.includes("nesthesiologist")) {
                anes = personnel[i].user_full_name
            } else if(personnel[i].job.name.includes("utotransfusionist")) {
                auto1 = personnel[i].user_full_name
            }
        } 
        return {
            surgeon: surgeon, 
            anes: anes, 
            auto1: auto1, 
            auto2: auto2
        }
    }

    const personnel = getPersonnel()

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card modal-extra-large">
            <header className="modal-card-head">
                <p className="modal-card-title">Procedure Report</p>
            </header>
            <section className="modal-card-body p-0">
                <div className="p-5">
                    <div className="w-100 has-background-white" ref={componentRef}>
                        {data.hem_detail && <div>
                            <div className="level w-100">
                                <div className="level-item">
                                    <p className="title is-1">Hemasource</p>
                                </div>
                            </div>

                            <hr />

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Hospital: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.facility_name}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Name: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.patient.first_name} {data.patient.last_name}</p>
                                </div>
                                <div className="level-right">
                                    <p className="has-text-weight-medium">DOB: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.patient.dob}</p>
                                </div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Medical Record No: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.medical_record_num}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Hospital: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.facility_name}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Surgeon / Anesthesiologist: </p>
                                    <p style={{ marginLeft: "8px" }}>{personnel.surgeon} / {personnel.anes}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Autotransfusionist #1: </p>
                                    <p style={{ marginLeft: "8px" }}>{personnel.auto1}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Autotransfusionist #2: </p>
                                    <p style={{ marginLeft: "8px" }}>{personnel.auto2}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <hr />

                            {/* PROCEDURES */}
                            <div className="level w-100">
                                <div className="level-item"></div>
                                <div className="level-item">
                                    <p className="title is-3">Procedure&#40;s&#41;</p>
                                </div>
                                <div className="level-item"></div>
                            </div>

                            <hr />

                            {data.hem_detail.case_type.map((e, idx) => {
                                return <p key={idx}>{e.type}</p>
                            })}

                            <hr />

                            {/* TIMES */}
                            <div className="level w-100">
                                <div className="level-item"></div>
                                <div className="level-item">
                                    <p className="title is-3">Case Times</p>
                                </div>
                                <div className="level-item"></div>
                            </div>

                            <hr />

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">OR #: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.or_number}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Sequestration Start: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.sequestrian_start}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Scheduled Start: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.scheduled_start}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">OR Time In: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.or_time_in}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">OR Time Out: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.or_time_out}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Post-OP Time Out: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.post_time_out}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Total Hours: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.total_hours}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <hr />

                            {/* Checklists */}

                            <div className="level w-100">
                                <div className="level-item"></div>
                                <div className="level-item">
                                    <p className="title is-3">Checklists</p>
                                </div>
                                <div className="level-item"></div>
                            </div>

                            <hr />

                            <div className="columns">
                                <div className="column is-6">
                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p>Positive Patient ID:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.patient_id ? <FaRegCheckSquare /> : <FaRegSquare />}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p>Consent Signed:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.consent ? <FaRegCheckSquare /> : <FaRegSquare />}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p>Anesthesia Consult:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.anes_consult ? <FaRegCheckSquare /> : <FaRegSquare />}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-6">
                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p>Tourniquet Removed:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.tourniquet ? <FaRegCheckSquare /> : <FaRegSquare />}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p>IV Drip Rate Re-Est:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.iv_drip ? <FaRegCheckSquare /> : <FaRegSquare />}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p>Reconfirm Patient ID:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.confirm_patient_id ? <FaRegCheckSquare /> : <FaRegSquare />}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <hr />

                            {/* Blood bank products */}

                            <div className="level w-100">
                                <div className="level-item"></div>
                                <div className="level-item">
                                    <p className="title is-3">Blood Bank Products</p>
                                </div>
                                <div className="level-item"></div>
                            </div>

                            <hr />

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">PRBC: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.prbc}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">FFP: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.ffp}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">PLT: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.plt}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <hr />

                            {/* Items */}

                            <div className="level w-100">
                                <div className="level-item"></div>
                                <div className="level-item">
                                    <p className="title is-3">Items</p>
                                </div>
                                <div className="level-item"></div>
                            </div>

                            <hr />

                            <table className="table is-fullwidth is-striped is-bordered">
                                <thead>
                                    <tr>
                                        <th>Item Type</th>
                                        <th>Manufacturer</th>
                                        <th>Cat #</th>
                                        <th>Description</th>
                                        <th>Item Category</th>
                                        <th>Amount</th>
                                        <th>Lot# / Serial# / Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.items.map((e, idx) => {
                                            return <tr key={idx}>
                                                <td>{e.item.item_type_name}</td>
                                                <td>{e.item.manufacturer_name}</td>
                                                <td>{e.item.catalog}</td>
                                                <td>{e.item.description}</td>
                                                <td>{e.item.category_name}</td>
                                                <td>{e.amount}</td>
                                                <td>{e.comment}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>

                            <hr />

                            {/* collection / reinfusion data */}
                            
                            <div className="level w-100">
                                <div className="level-item"></div>
                                <div className="level-item">
                                    <p className="title is-3">Autotransfusion Collection and Reinfusion Data</p>
                                </div>
                                <div className="level-item"></div>
                            </div>

                            <hr />

                            <table className="table is-fullwidth is-striped is-bordered">
                                <thead>
                                    <tr>
                                        <th>Start Time</th>
                                        <th>Processed Time</th>
                                        <th>Reinfused Time</th>
                                        <th>EBL</th>
                                        <th>Amt Returned</th>
                                        <th>Wash Volume</th>
                                        <th>Line Clear</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.hem_detail.units.map((e, idx) => {
                                            return <tr key={idx}>
                                                <td>{e.start_time}</td>
                                                <td>{e.processed_time}</td>
                                                <td>{e.reinfused_time}</td>
                                                <td>{e.ebl}</td>
                                                <td>{e.amnt_returned}</td>
                                                <td>{e.wash_volume}</td>
                                                <td>{e.line_clear ? 'Yes' : 'No'}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Total Estimated Blood Loss to Cell Saver: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.total_ebl} ml</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Total Cell Saver Blood Returned: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.total_returned} ml</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <hr />

                            {/* Plasma Sequestrian */}

                            <div className="level w-100">
                                <div className="level-item"></div>
                                <div className="level-item">
                                    <p className="title is-3">Plasma Sequestration</p>
                                </div>
                                <div className="level-item"></div>
                            </div>

                            <hr />

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Start Time: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.start_time}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Volume Drawn: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.volume_drawn} ml</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Product Used Time: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.product_used_time}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">RBC Amt. Reinfused: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.start_time} ml</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">RBC Amt. Reinfused: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.start_time}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Vol. PRP: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.prp} ml</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Vol. PPP: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.ppp} ml</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Vol. BMAC: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.bmac} ml</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Gel: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.gel ? 'Yes' : 'No'}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Number of Thrombin: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.thrombin}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <div className="level w-100" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="level-left">
                                    <p className="has-text-weight-medium">Number of Ca or Na Chloride: </p>
                                    <p style={{ marginLeft: "8px" }}>{data.hem_detail.chloride}</p>
                                </div>
                                <div className="level-right"></div>
                            </div>

                            <hr />


                            {/* Quality Control */}

                            <div className="level w-100">
                                <div className="level-item"></div>
                                <div className="level-item">
                                    <p className="title is-3">Quality Control</p>
                                </div>
                                <div className="level-item"></div>
                            </div>

                            <hr/>

                            <div className="columns">
                                <div className="column is-6">
                                    <div className="level w-100">
                                        <div className="level-item"></div>
                                        <div className="level-item">
                                            <p className="title is-5">AT</p>
                                        </div>
                                        <div className="level-item"></div>
                                    </div>
                                    
                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">Draw Time:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.draw_time}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">Hct. &#40;norm. ≥40%&#41;:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.qc_hct}%</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">K+ &#40;norm. &#60;5.0&#41;:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.k_plus}</p>
                                        </div>
                                    </div>
                                    
                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">Clinical Specialist Notified:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.at_specialist ? <FaRegCheckSquare /> : <FaRegSquare />}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-6">
                                    <div className="level w-100">
                                        <div className="level-item"></div>
                                        <div className="level-item">
                                            <p className="title is-5">PRP</p>
                                        </div>
                                        <div className="level-item"></div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">Pt. Draw Plt:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.pt_plt}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">Pt. Draw Hct:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.pt_hct}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">PRP Plt:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.prp_plt}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">PRP Hct:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.prp_hct}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">Clinical Specialist Notified:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.prp_specialist ? <FaRegCheckSquare /> : <FaRegSquare />}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div className="level w-100">
                                <div className="level-item"></div>
                                <div className="level-item">
                                    <p className="title is-3">Lot/Serial Numbers</p>
                                </div>
                                <div className="level-item"></div>
                            </div>

                            <hr/>

                            <div className="columns">
                                <div className="column is-6">
                                    <div className="level w-100">
                                        <div className="level-item"></div>
                                        <div className="level-item">
                                            <p className="title is-5">AT</p>
                                        </div>
                                        <div className="level-item"></div>
                                    </div>
                                    
                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">AT Machine Type:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.at_mach_type}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">AT Serial #:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.at_serial}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">Reservoir:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.reservoir}</p>
                                        </div>
                                    </div>
                                    
                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">Suction Line:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.suct_line}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">Wash Set:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.wash_set}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-6">
                                    <div className="level w-100">
                                        <div className="level-item"></div>
                                        <div className="level-item">
                                            <p className="title is-5">PS</p>
                                        </div>
                                        <div className="level-item"></div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">PS Machine Type:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.ps_mach_type}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">PS Serial #:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.ps_serial}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">PS Set:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.ps_set}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">Application Tips:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.application_tip}</p>
                                        </div>
                                    </div>

                                    <div className="level w-100">
                                        <div className="level-left">
                                            <p className="has-text-weight-medium">Ratio Applicators:</p>
                                        </div>
                                        <div className="level-right">
                                            <p>{data.hem_detail.ratio_applicators}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr />
                        </div>}
                    </div>
                </div>
                <button onClick={props.closeModal} className="modal-close is-large" aria-label="close"></button>
            </section>
            <footer className="modal-card-foot">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="button is-danger" onClick={props.closeModal}>Close</button>
                    </div>
                    <div className="level-right">
                        <button className="button is-success" onClick={handlePrint}>Print</button>
                    </div>
                </div>
            </footer>
        </div>
    </div>
}

export default PdfHemasource