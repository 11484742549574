// Get current date as MM/DD/YYYY
export const getCurrentDate = () => {
  const jsonDate = new Date().toJSON()
  const parts = jsonDate.split("T")[0].split("-")
  if(parts.length !== 3) return "Invalid date"
  return parts[1] + "/" + parts[2] + "/" + parts[0]
}

export const yyyymmdd = () => {
  function twoDigit(n) { return (n < 10 ? '0' : '') + n; }

  var now = new Date();
  return '' + now.getFullYear() + twoDigit(now.getMonth() + 1) + twoDigit(now.getDate());
}

export const dateToDateOnlyString = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // JavaScript months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};