import React, { useState } from 'react';

import DynamicForm from "./DynamicForm";
import DataTable from 'react-data-table-component';

function POModal(props) {
  const { form, onOrder, editValues, onClose, onEditChange, source, onSave, isSaving, subForm, showSubAdd, subColumns, filteredSub, subEditValues, onSubEditChange, subMenu, onSubClose, onSubSave, onSubAddNewClicked, selectedItem, manuOptions, getTotal } = props

  const [missingFields, setMissingFields] = useState([])

  const onSaveClick = () => {
    if (validate()) onSave()
  }

  const getOptionLabelByValue = (manu, source, id) => {
    let option = source.find(o => o.value == id)
    return option ? option.label : null
  }

  const getQty = () => {
    if (selectedItem[0].type == 1) {
      return 'Case(' + selectedItem[0].quantity + ')'
    } else {
      return 'Individual'
    }
  }

  const onOrderClick = () => {
    if (validate()) onOrder()
  }

  const onSubSaveClick = () => {
    if (validate()) onSubSave()
  }

  const validate = () => {
    let missingFields = []
    form.fields.forEach(f => {
      if (f.required && f.required == "true") {
        if (!(f.id in editValues) || editValues[f.id] == "" || editValues[f.id] == []) missingFields.push(f.id)
      }
    })
    if (missingFields.length > 0) setMissingFields(missingFields)
    return missingFields.length == 0
  }

  let saveBtnClasses = "button level-item";
  if (isSaving) saveBtnClasses += " is-loading"

  let bottomSaveBtnClasses = "button is-success level-item";
  if (isSaving) bottomSaveBtnClasses += " is-loading"

  return <div className="modal is-active" style={{ minWidth: 800 }}>
    <div className="modal-background"></div>
    <div className="modal-card modal-large">
      <header className="modal-card-head">
        <p className="modal-card-title">Field Order: Create New</p>
      </header>
      <section className="modal-card-body p-0">
        <div className="p-5">
          <div className="has-background-white rounded w-100">
            <section>
              <DynamicForm
                form={form}
                values={editValues}
                onValuesChanged={onEditChange}
                source={source}
                missingFields={missingFields}
              />
              <br />
            </section>
            <section>
              {
                !showSubAdd
                &&

                <div>
                  <div className="columns">
                    <div className="column">
                      <button style={{ backgroundColor: '#1C2944', color: '#FFFFFF' }} className="button w-100" onClick={onSubAddNewClicked}>Add Product</button>
                    </div>
                  </div>
                  <DataTable
                    striped
                    // pagination
                    data={filteredSub}
                    columns={subColumns}
                  />
                </div>
              }
              {
                showSubAdd
                &&
                <div>
                  <div className="columns">
                    <div className="column">
                      <p className="has-text-weight-bold">Manufacturer: </p><p>{selectedItem.length != 0 ? getOptionLabelByValue(selectedItem, manuOptions, selectedItem[0].manufacturer) : null}</p>
                    </div>
                    <div className="column">
                      <p className="has-text-weight-bold">Description: </p><p>{selectedItem.length != 0 ? selectedItem[0].description : null}</p>
                    </div>
                    <div className="column">
                      <p className="has-text-weight-bold">Quantity Type: </p><p>{selectedItem.length != 0 ? getQty() : null}</p>
                    </div>
                  </div>

                  <DynamicForm
                    form={subForm}
                    values={subEditValues}
                    onValuesChanged={onSubEditChange}
                    source={source}
                    missingFields={missingFields}
                  />

                  <br />

                  <div className="level w-100">
                    <div className="level-left">
                      <button className="button level-item is-danger is-inverted" onClick={onSubClose}>Cancel</button>
                    </div>
                    <div className="level-right">
                      <button style={{ backgroundColor: '#1C2944', color: '#FFFFFF' }} className={saveBtnClasses} onClick={onSubSaveClick}>Add</button>
                    </div>
                  </div>

                </div>
              }

              {
                (filteredSub.length > 0 && !showSubAdd)
                &&
                <div>
                  <br />
                  <div className="level w-100">
                    <div className="level-left">

                    </div>
                    <div className="level-right">
                      <p>Total:   ${getTotal()}</p>
                    </div>
                  </div>
                </div>

              }
              <button onClick={onClose} className="modal-close is-large" aria-label="close"></button>
            </section>
          </div>
        </div>
      </section>
      <footer className="modal-card-foot has-background-white">
        <div className="level w-100">
          <div className="level-left">
            <button className="level-item button is-danger is-inverted" onClick={onClose}>Cancel</button>
          </div>
          <div className="level-right">
            <a style={{marginRight:"8px"}} target="_blank" href="https://fs17.formsite.com/emeraldhs/ur8suzgvyq/index"><button className="button is-info">Item Request</button></a>
            <button style={{ backgroundColor: '#1C2944', color: '#FFFFFF' }} className={bottomSaveBtnClasses} onClick={onSaveClick}>Save</button>
            <button style={{ paddingLeft: 10 }} className={bottomSaveBtnClasses} onClick={onOrderClick}>Order</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
}

export default POModal;