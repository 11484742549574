import React, { useState, useEffect, useMemo } from "react"
import DynamicForm from "../../../components/DynamicForm"
import API from "../../../../services/API"
import chartingSearch from "../../../forms/chartingLocSearch"
import DataTable from 'react-data-table-component'
import { FaCaretUp, FaCaretDown, FaTrashAlt } from "react-icons/fa"
import ErrorModal from "../../../components/ErrorModal";
import VisitTimer from "../../../components/VisitTimer"
import Session from "../../../../services/Session"
import lineItemForm from "../../../forms/chartingSetupLineItem"

function ChartingSetup() {
  const [facilityOptions, setFacilityOptions] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [chartLogFieldOptions, setChartLogFieldOptions] = useState([])
  const [setting, setSetting] = useState({})
  const [tableItems, setTableItems] = useState([])
  const [changesMade, setChangesMade] = useState(false)
  const [itemValues, setItemValues] = useState(null)
  const [chartLogFields, setChartLogFields] = useState([])

  useEffect(() => {
    let queries = [
      { 'method': 'GET', 'path': '/api/facilities/' },
      { 'method': 'GET', 'path': '/api/chartlogfields/' }
    ]
    API.all(queries, function (resp) {
      setFacilityOptions(resp[0].map(i => ({ "label": i.name, "value": i.id })))
      setChartLogFields(resp[1])
      setChartLogFieldOptions(resp[1].map(i => ({ "label": i.name, "value": i.id})))
    }, onDataLoadError)
  }, []);

  const onDataLoadError = (error) => {
    setErrorMessage("There was a problem. Please try again or contact an administrator")
    setShowErrorModal(true)
    setIsSaving(false)
    console.log(error)
  }

  const onItemChanged = (value) => {
    setItemValues(value)
  }

  const onAdd = () => {
    let tempTableItems = [...tableItems]
    if(tempTableItems.length < 10) {
      let field = chartLogFields.find(i => i.id == itemValues.option)
      tempTableItems.push({field: field, ordinal: tempTableItems.length + 1})
      setTableItems(tempTableItems)
      setItemValues({option: null})
      setChangesMade(true)
    } else {
      setErrorMessage("You can't add more than 10 top line items.")
      setShowErrorModal(true)
      setItemValues({option: null})
    }
  }

  const onFilterChanged = (value) => {
    setFilterValues(value)
    let params = {
      'facility_id': value.facility
    }
    API.query('GET',`/api/facilitylogfieldsettings/`,params,{},function(resp) {
      console.log('resp',resp)
      if(resp[0].facility_id != null) {
        setSetting(resp[0])
        setTableItems(resp[0].log_fields)
      } else {
        //no facilitylogfield setting exists
        setSetting({})
        setTableItems([])
      }
    }, onDataLoadError)
  }

  const isFacilitySelected = (values) => {
    if (values && values.facility) {
      return true
    } else {
      return false
    }
  }

  const onMoveItemUp = (value) => {
    if (value.ordinal > 1) {
      var tempTableItems = [...tableItems]

      let itemIndex = tempTableItems.findIndex(i => i.ordinal == value.ordinal)
      let item2Index = tempTableItems.findIndex(i => i.ordinal == value.ordinal - 1)

      tempTableItems[itemIndex].ordinal -= 1
      tempTableItems[item2Index].ordinal += 1

      setTableItems(tempTableItems)
      if(!changesMade) {
        setChangesMade(true)
      }
    }
  }

  const onMoveItemDown = (value) => {
    if (value.ordinal < tableItems.length) {
      var tempTableItems = [...tableItems]

      let itemIndex = tempTableItems.findIndex(i => i.ordinal == value.ordinal)
      let item2Index = tempTableItems.findIndex(i => i.ordinal == value.ordinal + 1)

      tempTableItems[itemIndex].ordinal += 1
      tempTableItems[item2Index].ordinal -= 1

      setTableItems(tempTableItems)
      if(!changesMade) {
        setChangesMade(true)
      }
    }
  }

  const onSave = () => {
    if (!isSaving) {
      setIsSaving(true)
      if(setting.facility_id != null) {
        let data = {
          'new_log_fields': [...tableItems]
        }
        API.query(`PATCH`,`/api/facilitylogfieldsettings/${setting.id}/`,{},data, function (resp) {
          setIsSaving(false)
          setChangesMade(false)
        }, onDataLoadError)
      } else {
        let data = {
          "facility_id": filterValues.facility,
          "new_log_fields": [...tableItems]
        }
        API.query('POST','/api/facilitylogfieldsettings/',{},data,function(response) {
          setTableItems(response.log_fields)
          setSetting(response)
          setIsSaving(false)
          setChangesMade(false)
        }, onDataLoadError)
      }
    }
  }

  const onDeleteClicked = (row) => {
    var tempTableItems = [...tableItems]
    tempTableItems.sort((a, b) => a.ordinal - b.ordinal)
    let tempIndex = tempTableItems.findIndex(i => i.ordinal == row.ordinal)
    let originalOrdinal = tempTableItems[tempIndex].ordinal
    tempTableItems.splice(tempIndex,1)
    var extra = 0
    for(var i=tempIndex; i < tempTableItems.length; i++) {
      console.log(tempTableItems[i])
      tempTableItems[i].ordinal = originalOrdinal + extra
      extra += 1
    }
    setTableItems(tempTableItems)
    setChangesMade(true)
  }

  const columns = useMemo(() => [
    {
      maxWidth: "40px", selector: row => <div className="is-flex is-align-items-center">
        <FaCaretUp disabled className="is-clickable" size={40} onClick={() => onMoveItemUp(row)} />
        <FaCaretDown className="is-clickable" size={40} onClick={() => onMoveItemDown(row)} />
      </div>
    },
    { id: 'ordinal', maxWidth: "40px", name: 'Order', selector: row => row.ordinal, sortable: true },
    { name: 'Name', selector: row => row.field.name, sortable: true },
    { name: 'Description', selector: row => row.field.description, sortable: true },
    { name: 'Unit', selector: row => row.field.unit },
    { name: 'Delete', selector: row => <div>
      <a className="ml-3" title="Delete" onClick={() => onDeleteClicked(row)}><FaTrashAlt size={14} /></a>
    </div>}
  ])

  let saveBtnClass = "button"
  if (changesMade) saveBtnClass += " is-orange"
  if (isSaving) saveBtnClass += " is-loading"

  const visited = () => {
    // get pageviewhistory id from user session data
    var user = Session.get('user')
    API.query('GET', `/api/pageviewhistory/${user.page_view_history}/`, {}, {}, function (response) {
      let data = {
        "setup_charting_setup": response.setup_charting_setup + 1
      }
      API.query('PATCH', `/api/pageviewhistory/${user.page_view_history}/`, {}, data, function (resp) { }, onDataLoadError)
    }, onDataLoadError)
  }

  return <div>
    <p className="title">Charting Page Setup</p>
    <DynamicForm
      form={chartingSearch}
      values={filterValues}
      onValuesChanged={onFilterChanged}
      source={{ "facilities": facilityOptions }}
    />

    {
      isFacilitySelected(filterValues)
      &&
      <div>
        <div className="columns">
          <div className="column is-2">
            <button style={{marginTop: "32px",marginLeft:"16px"}} className={saveBtnClass} onClick={onSave} disabled={!changesMade}>Save Changes</button>
          </div>
          <div className="column is-8">
            <DynamicForm
              form={lineItemForm}
              values={itemValues}
              onValuesChanged={onItemChanged}
              source={{ "chartLogFields": chartLogFieldOptions }}
            />
          </div>
          <div className="column is-2">
            <button style={{marginTop: "32px",marginLeft:"16px"}} className="button is-success" disabled={itemValues && itemValues.option == null} onClick={onAdd}>Add</button>
          </div>
        </div>
      
        <DataTable
          striped
          pagination
          data={tableItems}
          columns={columns}
          defaultSortFieldId={'ordinal'}
        />
      </div>
    }

    {
      showErrorModal
      &&
      <ErrorModal
        message={errorMessage}
        onClose={() => setShowErrorModal(false)}
      />
    }

    <VisitTimer visited={visited} />

  </div>

}

export default ChartingSetup