import React from 'react';

function LoadingModal(props) {

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-content">
      <progress className="progress is-small is-primary" max="100">15%</progress>
    </div>
  </div>
}

export default LoadingModal;