import React, { useRef, useMemo, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import DatePicker from "react-datepicker";
import API from "../../services/API";
import { CSVLink, CSVDownload } from "react-csv";

function HemMasterCSV(props) {
  const [filters, setFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const componentRef = useRef();
  const today = new Date();

  const onFilterChanged = (value, type) => {
    let fValues = { ...filters };
    if (type === 0) {
      fValues["start_date"] = value;
    } else if (type === 1) {
      fValues["end_date"] = value;
    } else if (type === 2) {
      fValues["facility"] = value;
    } else if (type === 3) {
      fValues["personnel"] = value;
    }
    setFilters(fValues);
  };

  const renderData = () => {
    if (filters.start_date != null && filters.end_date != null) {
      if (!isLoading) {
        setIsLoading(true);
        // const start_date = new Date(filters.start_date)
        // const end
        let users;
        let personnel;
        let facilities;

        if (filters.facility && filters.facility.length > 0) {
          facilities = [...filters.facility];
        }

        if (filters.personnel && filters.personnel.length > 0) {
          let personnelArr = [];
          let userArr = [];

          for (let i = 0; i < filters.personnel.length; i++) {
            if (filters.personnel[i].includes("personnel")) {
              personnelArr.push(filters.personnel[i].split("personnel-")[1]);
            } else if (filters.personnel[i].includes("user")) {
              userArr.push(filters.personnel[i].split("user-")[1]);
            }
          }

          if (personnelArr.length > 0) {
            personnel = personnelArr;
          }
          if (userArr.length > 0) {
            users = userArr;
          }
        }

        let params = {
          start_date: start_date,
          end_date: end_date,
          type: "Hem",
        };

        if (facilities) {
          params = { ...params, ...{ facility: facilities } };
        }
        if (personnel) {
          params = { ...params, ...{ personnel: personnel } };
        }
        if (users) {
          params = { ...params, ...{ users: users } };
        }

        API.query(
          "GET",
          `/api/hemcasereports/`,
          params,
          {},
          function (resp) {
            if (resp && resp.length > 0) {
              setData(resp);
            }
            setIsLoading(false);
          },
          onDataLoadError()
        );
      }
    }
  };

  const getReportData = () => {
    if (data && data.length > 0) {
      let keys = [
        "#",
        "Date",
        "Tech",
        "Facility",
        "Sequestrian Start",
        "Scheduled Start",
        "OR Time In",
        "OR Time Out",
        "Total Hours",
        "Physician",
        "Case Type",
        "Patient Name",
        "MR#",
        "Baseline Hct",
        "Baseline Plt",
        "EBL",
        "Return",
        "Blood Bank Products",
        "AT Hct",
        "AT K+",
        "AT Machine Type",
        "AT Serial #",
        "PT. Draw Plt",
        "Pt. Draw Hct",
        "PRP Plt",
        "PRP Hct",
        "PRP Machine",
        "PRP Serial #",
        "Notes",
        "Description",
      ];
      let rows = [];
      rows.push(keys);
      for (let i = 0; i < data.length; i++) {
        let tempData = data[i];
        if (tempData.hem_detail) {
          let row = [];
          row.push(i + 1);
          row.push(new Date(tempData.date).toLocaleDateString("en-CA"));
          let techString = "";
          for (let x = 0; x < tempData.personnel_roles.length; x++) {
            if (tempData.personnel_roles[x].job?.id === 5) {
              if (techString === "") {
                techString = `${tempData.personnel_roles[x].user_full_name}`;
              } else {
                techString = `${techString}, ${tempData.personnel_roles[x].user_full_name}`;
              }
            }
          }
          row.push(techString);
          row.push(tempData.facility_name);
          row.push(tempData.hem_detail.sequestrian_start);
          row.push(tempData.hem_detail.scheduled_start);
          row.push(tempData.hem_detail.or_time_in);
          row.push(tempData.hem_detail.or_time_out);
          row.push(tempData.hem_detail.total_hours);
          let physString = "";
          for (let x = 0; x < tempData.personnel_roles.length; x++) {
            if (tempData.personnel_roles[x].job?.id === 15) {
              if (physString === "") {
                physString = `${tempData.personnel_roles[x].user_full_name}`;
              } else {
                physString = `${physString}, ${tempData.personnel_roles[x].user_full_name}`;
              }
            }
          }
          row.push(physString);
          let case_type_str = "";
          for (let x = 0; x < tempData.hem_detail.case_type.length; x++) {
            if (x == 0) {
              case_type_str = `${tempData.hem_detail.case_type[x].type}`;
            } else {
              case_type_str = `${case_type_str}, ${tempData.hem_detail.case_type[x].type}`;
            }
          }
          row.push(case_type_str);
          row.push(tempData.patient_name);
          row.push(tempData.patient.medical_record_num);
          row.push(tempData.hem_detail.base_hct ?? "");
          row.push(tempData.hem_detail.base_plt ?? "");
          row.push(tempData.hem_detail.total_ebl ?? "");
          row.push(tempData.hem_detail.total_returned ?? "");
          let blood_str = "";
          let blood_first = true;
          if (tempData.hem_detail.prbc != null) {
            if (blood_first) {
              blood_str = `PRBC - ${tempData.hem_detail.prbc}`;
              blood_first = false;
            } else {
              blood_str = `${blood_str}, PRBC - ${tempData.hem_detail.prbc}`;
            }
          }
          if (tempData.hem_detail.ffp != null) {
            if (blood_first) {
              blood_str = `FFP - ${tempData.hem_detail.ffp}`;
              blood_first = false;
            } else {
              blood_str = `${blood_str}, FFP - ${tempData.hem_detail.ffp}`;
            }
          }
          if (tempData.hem_detail.plt != null) {
            if (blood_first) {
              blood_str = `PLT - ${tempData.hem_detail.plt}`;
              blood_first = false;
            } else {
              blood_str = `${blood_str}, PLT - ${tempData.hem_detail.plt}`;
            }
          }
          row.push(blood_str);
          row.push(tempData.hem_detail.qc_hct ?? "");
          row.push(tempData.hem_detail.k_plus ?? "");
          row.push(tempData.hem_detail.at_mach_type ?? "");
          row.push(tempData.hem_detail.at_serial ?? "");
          row.push(tempData.hem_detail.pt_plt ?? "");
          row.push(tempData.hem_detail.pt_hct ?? "");
          row.push(tempData.hem_detail.prp_plt ?? "");
          row.push(tempData.hem_detail.prp_hct ?? "");
          row.push(tempData.hem_detail.ps_mach_type ?? "");
          row.push(tempData.hem_detail.ps_serial ?? "");
          row.push(tempData.hem_detail.note ?? "");
          row.push(tempData.hem_detail.description ?? "");

          rows.push(row);
        }
      }
      return rows;
    }
  };

  const onDataLoadError = (error) => {
    setIsLoading(false);
    console.log(error);
  };

  const start_date = filters.start_date
    ? new Date(filters.start_date).toLocaleDateString("en-CA")
    : "";
  const end_date = filters.end_date
    ? new Date(filters.end_date).toLocaleDateString("en-CA")
    : "";

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card modal-extra-large">
        <header className="modal-card-head">
          <p className="modal-card-title">Hemasource Master CSV</p>
        </header>

        <section className="modal-card-body p-0">
          <div className="p-5">
            <div className="w-100">
              <div className="columns">
                <div className="column"></div>
                <div className="column">
                  <div style={{ midWidth: "499px", maxWidth: "500px" }}>
                    <Select
                      options={props.facilities}
                      placeholder={"All Facilities"}
                      value={props.facilities.find(
                        (e) => e.value == filters.facility
                      )}
                      onChange={(option) =>
                        onFilterChanged(
                          option.map((option) => option.value),
                          2
                        )
                      }
                      isMulti={true}
                    />
                  </div>
                </div>
                <div className="column">
                  <div style={{ midWidth: "499px", maxWidth: "500px" }}>
                    <Select
                      options={props.personnel}
                      placeholder={"All Personnel"}
                      value={props.personnel.find(
                        (e) => e.value == filters.personnel
                      )}
                      onChange={(option) =>
                        onFilterChanged(
                          option.map((option) => option.value),
                          3
                        )
                      }
                      isMulti={true}
                    />
                  </div>
                </div>
                <div className="column"></div>
              </div>
              <div className="level w-100">
                <div className="level-item"></div>
                <div className="level-item">
                  <div style={{ maxWidth: "200px", minWidth: "200px" }}>
                    <DatePicker
                      className="input"
                      selectsStart
                      startDate={filters.start_date}
                      endDate={filters.end_date}
                      selected={filters.start_date}
                      onChange={(date) => onFilterChanged(date, 0)}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      maxDate={new Date()}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Start Date"
                    />
                  </div>
                </div>
                <div className="level-item">
                  <div style={{ maxWidth: "200px", minWidth: "200px" }}>
                    <DatePicker
                      className="input"
                      selectsStart
                      startDate={filters.start_date}
                      endDate={filters.end_date}
                      selected={filters.end_date}
                      onChange={(date) => onFilterChanged(date, 1)}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      maxDate={new Date()}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="End Date"
                    />
                  </div>
                </div>
                <div className="level-item"></div>
              </div>

              <div className="level w-100">
                <div className="level-item has-text-centered">
                  <button className="button is-success" onClick={renderData}>
                    Set Filters
                  </button>
                </div>
              </div>

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>

          <button
            onClick={props.onClose}
            className="modal-close is-large"
            aria-label="close"
          ></button>
        </section>

        <footer className="modal-card-foot">
          <div className="level w-100">
            <div className="level-left">
              <button className="button is-danger" onClick={props.onClose}>
                Close
              </button>
            </div>
            <div className="level-right">
              {data && (
                <CSVLink
                  filename={`master/${start_date}/${end_date}`}
                  data={getReportData()}
                >
                  <button className="button is-success">Download</button>
                </CSVLink>
              )}
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default HemMasterCSV;
