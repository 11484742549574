import React, { useEffect, useState } from "react";
import useSearchParamState from "../../hooks/useSearchParamState";
import Help from "./tabs/Help"
import Notification from "./tabs/Notification"
import Profile from "./tabs/Profile"
import Support from "./tabs/Support"
import IDidThis from "./tabs/IDidThis"

const HELP_TAB = 1
const NOTIFICATION_TAB = 2
const PROFILE_TAB = 3
const SUPPORT_TAB = 4
const IDIDTHIS_TAB = 5

const TABS = [
  {id:HELP_TAB,name:'Help',component:Help},
  {id:NOTIFICATION_TAB,name:'Notification',component:Notification},
  {id:PROFILE_TAB,name:'Profile',component:Profile},
  {id:SUPPORT_TAB,name:'Support',component:Support},
  {id:IDIDTHIS_TAB,name:'IDidThis',component:IDidThis}
]

function Settings(props){
  const [activeTab,setActiveTab] = useSearchParamState('tab',PROFILE_TAB,parseInt)

  useEffect(() => {
    props.setMenuItems([
      {title:"Profile",callback:() => setActiveTab(PROFILE_TAB)},
      {title:"IDidThis",callback:() => setActiveTab(IDIDTHIS_TAB)},
      {title:"Notification Preferences",callback:() => setActiveTab(NOTIFICATION_TAB)},
      {title:"Contact Support",callback:() => setActiveTab(SUPPORT_TAB)},
      {title:"Help Articles",callback:() => setActiveTab(HELP_TAB)},
    ])

    return props.clearMenuItems
  },[])

  const Tab = TABS.find(tab => tab.id === activeTab)
  const TabComponent = Tab.component

  console.log("Current Tab: ",Tab.name)

  return <div className="box">
    <TabComponent />
  </div>
}

export default Settings;