import React, { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import ChecklistModal from "../../../components/ChecklistModal";
import DataTableMemo from "../../../components/DataTableMemo";
import ProcedureBox from "../../../components/ProcedureBox";
import { getCurrentDate } from "../../../helpers/date";

const Checklist = forwardRef((props,ref) => {
  const [notes,setNotes] = useState("")
  const [modal,setModal] = useState(null)
  const [update,setUpdate] = useState(false)

  const columns = useMemo(() => [
    {name:'Description',selector: row => row.item.name},
    {name:'Date',width:"160px",selector: row => row.date_checked},
    {name:'Edit',width:"80px",selector: row => <a onClick={() => onEdit(row)}><FaRegEdit size={20} /></a>}
  ])

  useImperativeHandle(ref, () => ({
    onNext() {
      const remainingItems = props.activeCase.checklist_items.filter(item => item.date_checked === null)
      if(remainingItems.length === 0) return true
      setModal({
        items: remainingItems,
        isConfirmation: true
      })
      return false
    }
  }));

  const onEdit = (item) => {
    setModal({
      items: [item],
      isConfirmation: false
    })
  }

  const onCheck = ({selectedRows},procId) => {
    const selectedIds = selectedRows.map(row => row.id)
    const newItems = props.activeCase.checklist_items.map(item => {
      if(item.procedure == procId) {
        const isSelected = selectedIds.includes(item.id)
        if(isSelected){
          if(item.date_checked === null) item.date_checked = getCurrentDate()
        } else {
          if(item.date_checked !== null) item.date_checked = null
        }
        return item
      }
      return item
    })
    props.updateActiveCase({checklist_items: newItems})
  }

  const updateItems = (updatedItems) => {
    const newItems = props.activeCase.checklist_items.map(item => {
      const updatedItem = updatedItems.find(updatedItem => updatedItem.id === item.id)
      return updatedItem ?? item
    })
    props.updateActiveCase({checklist_items: newItems})
    setUpdate(!update)
  }

  return <div>
    <p className="title is-inline-block mr-3 mb-0">Checklist</p>
    {
      props.activeCase.procedure.map((proc) => {
        return <span key={proc.id} style={{marginRight:5}}>
          <ProcedureBox procedure={proc} />
        </span>
      })
    }
    
    <hr/>

    {
      props.activeCase.procedure.map((proc) => {
        return <div key={proc.id}>
          <label className="label">{proc.name} Checklist Items</label>
          <DataTableMemo
            striped
            pagination
            selectableRows
            selectableRowsHighlight
            onSelectedRowsChange={(data) => onCheck(data,proc.id)}
            columns={columns}
            data={props.activeCase.checklist_items.filter((obj) => {
              var valid=false
              if(obj.procedure == proc.id) valid=true
              return valid
            })}
            selectableRowSelected={(row) => !!row.date_checked}
          />
        </div>
      })
    }
    
    <label className="label">Notes</label>
    <textarea className="textarea" value={notes} onChange={(e) => setNotes(e.target.value)} />

    {
      modal !== null
      &&
      <ChecklistModal
        items={modal.items}
        procedure={props.activeCase.procedure}
        isConfirmation={modal.isConfirmation}
        onSave={(updatedItems) => updateItems(updatedItems)}
        onNext={() => props.onNext(true)}
        onClose={() => setModal(null)}
      />
    }
  </div>
});

export default Checklist