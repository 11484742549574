import React, { useEffect, useState } from "react";
import API from "../../services/API";
import { CSVLink } from "react-csv";
import Select from 'react-select';

function ReorderPointReport(props) {
    const [data, setData] = useState()
    const today = new Date()
    const [facility, setFacility] = useState()

    useEffect(() => {
        if(facility) {
            API.query('GET', 'api/reorderpoint/', {facility:facility.value}, {}, (resp) => {
                setData(resp)
            }, () => {
                console.log('failed loading report data')
            })
        }
    }, [facility])

    const getReportData = () => {
        if (data && data.length > 0) {
            const rows = [
                ['Reorder Point Report','','','','','','','',`Printed: ${today.toLocaleDateString()}`],
                [],
                [],
                [],
                ['Facility:',`${facility.label}`],
                [],
                ['#', 'Type', 'Manufacturer', 'Item Category', 'Catalog Number', 'Description', 'Par', 'Count']
            ]

            data.forEach((x,i) => {
                //need a better way to only get the location counts we need here
                const locCounts = x.location_counts.find(lc => lc.facility_name===facility.label)
                rows.push([i+1,x.type,x.manu_name,x.category,x.catalog,x.description,locCounts.par,locCounts.count])
            })
            rows.push([])
            rows.push([])
            rows.push([])

            return rows
        } else {
            return []
        }
    }

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card" style={{height:"600px"}}>
            <header className="modal-card-head has-background-white is-block">
                <p className="modal-card-title">Reorder Point Report</p>
            </header>

            <section className="modal-card-body w-100">
                <div className="columns">
                    <div className="column is-one-third is-offset-one-third">
                        <div style={{ midWidth: '499px', maxWidth: '500px' }}>
                            <Select
                                options={props.facilities}
                                placeholder={'Select a Facility'}
                                value={props.facilities.find((e) => e.value == facility?.value)}
                                onChange={(option) => {
                                    setFacility(option)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>

            <footer className="modal-card-foot has-background-white">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="level-item button is-danger is-inverted" onClick={props.onClose}>Close</button>
                    </div>
                    <div className="level-right">{
                            data
                            &&
                            <CSVLink
                                filename={`reorder_point_${today.toLocaleDateString()}`}
                                data={getReportData()}
                            >
                                <button className="button is-success">Download</button>
                            </CSVLink>
                        }</div>
                </div>
            </footer>
        </div>
    </div>
}

export default ReorderPointReport