import React, { useEffect, useState, useMemo } from "react"
import DataTable from 'react-data-table-component';
import CaseReportModal from "../../../components/CaseReportModal";
import { FaCaretUp, FaCaretDown, FaCloudDownloadAlt, FaExpandArrowsAlt, FaEnvelope, FaFileExcel, FaFilePdf, FaRegFilePdf } from "react-icons/fa";
import { CSVLink, CSVDownload } from "react-csv";
import PdfDisposableByPatient from "../../../components/PdfDisposableByPatient"
import API from "../../../../services/API";
import { yyyymmdd } from "../../../helpers/date";
import Session from "../../../../services/Session";

function AdminReports(props) {

  const REPORT_TYPE_IDIDTHIS = 1

  const addDays = (date, days) => {
    date = new Date(date) // copy date
    date.setDate(date.getDate() + days)
    return date
  }

  const [cases, setCases] = useState([])
  const [iDidThese, setIDidThese] = useState()

  const reports = [
    {
      id:REPORT_TYPE_IDIDTHIS,
      name:"IDidThis Report",
      description:"Submitted IDidThis data filtered by user, patient, and/or facility"
    }
  ]

  useEffect(() => {

    let user = Session.get('user')
    let queries = [
      { 'method': 'GET', 'path': '/api/ididthis/', 'params': {user:user.id}},
    ]
    API.all(queries, function (resp) {
      setIDidThese(resp[0])
    }, onDataLoadError)
  }, []);

  const onDataLoadError = (error) => {
    setErrorMessage(error)
    setShowErrorModal(true)
    setIsSaving(false)
  }

  const columns = useMemo(() => [
    {name:'Name of Report',selector: row => row.name,sortable:true},
    {name:'Description', selector: row => row.description,sortable:true},
    {name:'Actions',selector: row =><div>
      <CSVLink filename={getReportName(row)} data={getReportData(row)??[]}><FaFileExcel size={18} title="CSV Download"/></CSVLink>
      {/* <a className="ml-3" title="PDF Download" onClick={() => onViewClicked(row)}><FaRegFilePdf size={18} /></a> */}
      {/* <a className="ml-3" title="Send" onClick={() => onShareClicked(row)}><FaEnvelope size={18} /></a> */}
    </div>}
  ])

  const renderReports = () => {
    return <div className="box">
      <DataTable 
        striped
        data={reports}
        columns={columns}
      />
    </div>
  }

  const getReportData = (row) => {
    if(row.id == REPORT_TYPE_IDIDTHIS) {
      // return filtered data for ididthis report
      return iDidThese
    } 
  }

  const getReportName = (row) => {
    if(row.id == REPORT_TYPE_IDIDTHIS) {
      return `ididthis-${yyyymmdd()}.csv`
    }
  }

  return <div>
    <p className="title is-3">Reports</p>
    {renderReports()}
  </div>
}

export default AdminReports