import React, { useState } from 'react';

import DynamicForm from "./DynamicForm";
import DataTable from 'react-data-table-component';

function ItemModal(props) {
  const { form, editValues, onClose, onEditChange, source, onSave, isSaving, subForm, showSubAdd, subColumns, filteredSub, subEditValues, onSubEditChange, subMenu, onSubClose, onSubSave, onSubAddNewClicked, string, itemTotal } = props

  const [missingFields, setMissingFields] = useState([])

  const onSaveClick = () => {
    if (validate()) onSave()
  }

  const onSubSaveClick = () => {
    if (validate()) onSubSave()
  }

  const validate = () => {
    let missingFields = []
    form.fields.forEach(f => {
      if (f.required && f.required == "true") {
        if (!(f.id in editValues) || editValues[f.id] == "" || editValues[f.id] == []) missingFields.push(f.id)
      }
    })
    if (missingFields.length > 0) setMissingFields(missingFields)
    return missingFields.length == 0
  }

  let saveBtnClasses = "button is-success";
  if (isSaving) saveBtnClasses += " is-loading"

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card modal-large">
      <header className="modal-card-head">
        <div className="level w-100">
          <div className="level-left">
            <p className="modal-card-title">Item Setup</p>
          </div>
          <div className="level-right">
            <p>Total Quantity On Hand: {itemTotal}</p>
          </div>
        </div>
      </header>
      <section className="modal-card-body p-0">
        <div className="p-5">
          <div className="has-background-white rounded w-100">
            <DynamicForm
              form={form}
              values={editValues}
              onValuesChanged={onEditChange}
              source={source}
              missingFields={missingFields}
            />
            <button onClick={onClose} className="modal-close is-large" aria-label="close"></button>
            {
              subMenu
              &&
              <div>
                {
                  !showSubAdd
                  &&
                  <div>
                    <br />
                    <div className="columns">
                      <div className="column">
                        <button style={{backgroundColor:'#1C2944',color:'#FFFFFF'}} className="button w-100" onClick={onSubAddNewClicked}>Add New {string}</button>
                      </div>
                    </div>
                    <br />
                    <DataTable
                      striped
                      // pagination
                      data={filteredSub}
                      columns={subColumns}
                    />
                  </div>
                }
                {
                  showSubAdd
                  &&
                  <div>
                    <br />
                    <DynamicForm
                      form={subForm}
                      values={subEditValues}
                      onValuesChanged={onSubEditChange}
                      source={source}
                      missingFields={missingFields}
                    />
                    <br />

                    <div className="level w-100">
                      <div className="level-left">
                        <button className="button is-danger is-inverted" onClick={onSubClose}>Cancel</button>
                      </div>
                      <div className="level-right">
                        <button className={saveBtnClasses} onClick={onSubSaveClick}>Save</button>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </section>
      <footer className="modal-card-foot has-background-white">
        <div className="level w-100">
          <div className="level-left">
            <button className="button is-danger is-inverted" onClick={onClose}>Cancel</button>
          </div>
          <div className="level-right">
            <button className={saveBtnClasses} onClick={onSaveClick}>Save</button>
          </div>
        </div>        
      </footer>
    </div>
  </div>
}

export default ItemModal;