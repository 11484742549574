import React, { useRef, useMemo, useState } from "react";
import { useReactToPrint } from 'react-to-print';
import Select from 'react-select';
import DatePicker from 'react-datepicker'
import API from "../../services/API";

function PdfHemFacility(props) {
    const [filters, setFilters] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState()
    const componentRef = useRef()
    const today = new Date()

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onFilterChanged = (value, type) => {
        let fValues = { ...filters }
        if (type === 0) {
            fValues['start_date'] = value
        } else if (type === 1) {
            fValues['end_date'] = value
        } else if (type === 2) {
            fValues['facility'] = value
        }
        setFilters(fValues)
    }

    const renderData = () => {
        if (filters.facility != null && filters.start_date != null && filters.end_date != null) {
            if(!isLoading) {
                setIsLoading(true)
                // const start_date = new Date(filters.start_date)
                // const end
                let params = {
                    'facility': filters.facility,
                    'start_date': start_date,
                    'end_date': end_date,
                    'type': 'Hem'
                }
                API.query('GET',`/api/casereports/`,params,{},function(resp) {
                    setData(resp)
                    setIsLoading(false)
                },onDataLoadError())
            }
        }
    }

    const onDataLoadError = (error) => {
        console.log(error)
    }

    const formatData = () => {
        if(data && data.length > 0) {
            let count_at = 0
            let count_at_ps = 0
            let count_ps = 0
            let count_total = 0
            let at_qc = []
            let total_ebl = 0
            let total_return = 0
            let total_processed_cases = 0 
            let percent_returned = 0
            let total_blood_products = 0

            for(let i=0; i < data.length; i++) {
                if((data[i].hem_detail.case_type.includes(1) || data[i].hem_detail.case_type.includes(2)) && !data[i].hem_detail.case_type.includes(3)) {
                    count_at += 1
                } else if((data[i].hem_detail.case_type.includes(1) && data[i].hem_detail.case_type.includes(3)) || (data[i].hem_detail.case_type.includes(2) && data[i].hem_detail.case_type.includes(3))) {
                    count_at_ps += 1
                } else if(data[i].hem_detail.case_type.includes(3) && (!data[i].hem_detail.case_type.includes(1) || !data[i].hem_detail.case_type.includes(2))) {
                    count_ps = 0
                }
                count_total += 1

                let formattedDate = new Date(data[i].date).toLocaleDateString('en-CA')
                at_qc.push({date: formattedDate, hct: data[i].hem_detail.qc_hct, k_plus: data[i].hem_detail.k_plus})

                if(data[i].hem_detail.total_ebl != null) {
                    total_ebl += data[i].hem_detail.total_ebl
                }
                if(data[i].hem_detail.total_returned != null) {
                    total_return += data[i].hem_detail.total_returned
                }
                if(data[i].hem_detail.total_returned != null && data[i].hem_detail.total_ebl != null) {
                    total_processed_cases += 1
                }

                if(data[i].hem_detail.prbc != null) {
                    total_blood_products += data[i].hem_detail.prbc
                }
                if(data[i].hem_detail.ffp != null) {
                    total_blood_products += data[i].hem_detail.ffp
                }
                if(data[i].hem_detail.plt != null) {
                    total_blood_products += data[i].hem_detail.plt
                }
            }

            percent_returned = ((total_return / total_ebl) * 100).toFixed(2)

            return {
                count_at: count_at, 
                count_at_ps: count_at_ps, 
                count_ps: count_ps, 
                count_total: 
                count_total, 
                at_qc: at_qc, 
                total_ebl: total_ebl, 
                total_return: total_return,
                total_processed_cases: total_processed_cases,
                percent_returned: percent_returned,
                total_blood_products: total_blood_products
            }
        } else { return null }    
    }

    const start_date = filters.start_date ? new Date(filters.start_date).toLocaleDateString('en-CA') : ''
    const end_date = filters.end_date ? new Date(filters.end_date).toLocaleDateString('en-CA') : ''
    const formattedData = formatData()

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card modal-extra-large">
            <header className="modal-card-head">
                <p className="modal-card-title">Hemasource Facility Report</p>
            </header>

            <section className="modal-card-body p-0">
                <div className="p-5">
                    <div className="w-100">
                        <div className="columns">
                            <div className="column"></div>
                            <div className="column">
                                <div style={{ midWidth: '499px', maxWidth: '500px' }}>
                                    <Select
                                        options={props.facilities}
                                        placeholder={'Select a Facility'}
                                        value={props.facilities.find((e) => e.value == filters.facility)}
                                        onChange={(option) => onFilterChanged(option.value, 2)}
                                    />
                                </div>
                            </div>
                            <div className="column"></div>
                        </div>
                        <div className="level w-100">
                            <div className="level-item"></div>
                            <div className="level-item">
                                <div style={{ maxWidth: '200px', minWidth: '200px' }}>
                                    <DatePicker
                                        className="input"
                                        selectsStart
                                        startDate={filters.start_date}
                                        endDate={filters.end_date}
                                        selected={filters.start_date}
                                        onChange={(date) => onFilterChanged(date, 0)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        maxDate={new Date()}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Start Date"
                                    />
                                </div>
                            </div>
                            <div className="level-item">
                                <div style={{ maxWidth: '200px', minWidth: '200px' }}>
                                    <DatePicker
                                        className="input"
                                        selectsStart
                                        startDate={filters.start_date}
                                        endDate={filters.end_date}
                                        selected={filters.end_date}
                                        onChange={(date) => onFilterChanged(date, 1)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        maxDate={new Date()}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="End Date"
                                    />
                                </div>
                            </div>
                            <div className="level-item"></div>
                        </div>

                        <div className="level w-100">
                            <div className="level-item has-text-centered">
                                <button className="button is-success" onClick={() => renderData()}>Generate</button>
                            </div>
                        </div>

                        {
                            !formattedData
                            &&
                            <div>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        }

                        {
                            formattedData
                            &&
                            <div ref={componentRef} className="has-background-white">
                                <hr />

                                <div className="level w-100">
                                    <div className="level-item"></div>
                                    <div className="level-item">
                                        <p className="title is-3">Hemasource Utilization Report</p>
                                    </div>
                                    <div className="level-item"></div>
                                </div>

                                <hr />

                                <div className="columns">
                                    <div className="column is-2">
                                        <p className="has-text-weight-semibold">Hospital:</p>
                                        <p className="has-text-weight-semibold">Date Range:</p>
                                    </div>
                                    <div className="column is-6">
                                        <p>{props.facilities.find(e => e.value == filters.facility).label}</p>
                                        <p>{`From ${start_date} To ${end_date}`}</p>
                                    </div>
                                    <div className="column is-4"></div>
                                </div>

                                <hr />

                                <div className="level w-100">
                                    <div className="level-item"></div>
                                    <div className="level-item">
                                        <p className="title is-5">Case Information</p>
                                    </div>
                                    <div className="level-item"></div>
                                </div>

                                <hr />

                                <div className="columns">
                                    <div className="column is-5">
                                        <p className="has-text-weight-semibold">Autotransfusion only &#40;Standby & Processing&#41;:</p>
                                        <p className="has-text-weight-semibold">Autotransfusion with Plasma Sequestration:</p>
                                        <p className="has-text-weight-semibold">Plasma Sequestration only:</p>
                                        <p className="has-text-weight-semibold">Total:</p>
                                    </div>
                                    <div className="column is-3">
                                        <p>{formattedData.count_at}</p>
                                        <p>{formattedData.count_at_ps}</p>
                                        <p>{formattedData.count_ps}</p>
                                        <p>{formattedData.count_total}</p>
                                    </div>
                                    <div className="column is-4"></div>
                                </div>

                                <hr />

                                <div className="level w-100">
                                    <div className="level-item"></div>
                                    <div className="level-item">
                                        <p className="title is-5">QC Information</p>
                                    </div>
                                    <div className="level-item"></div>
                                </div>

                                <hr />

                                <table className="table is-fullwidth is-striped is-bordered">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>HCT</th>
                                            <th>K+</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            formattedData.at_qc.map((e, idx) => {
                                                return <tr key={idx}>
                                                    <td>{e.date}</td>
                                                    <td>{e.hct}</td>
                                                    <td>{e.k_plus}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>

                                <hr />

                                <div className="level w-100">
                                    <div className="level-item"></div>
                                    <div className="level-item">
                                        <p className="title is-5">Estimated Blood Loss and Autotransfusion Return Information</p>
                                    </div>
                                    <div className="level-item"></div>
                                </div>

                                <hr />

                                <div className="columns">
                                    <div className="column is-5">
                                        <p className="has-text-weight-semibold">Total Estimated Blood Loss:</p>
                                        <p className="has-text-weight-semibold">Total Autotransfusion Return:</p>
                                        <p className="has-text-weight-semibold">Total Processed Cases:</p>
                                        <p className="has-text-weight-semibold">Percent of Blood Returned:</p>
                                    </div>
                                    <div className="column is-3">
                                        <p>{formattedData.total_ebl}</p>
                                        <p>{formattedData.total_return}</p>
                                        <p>{formattedData.total_processed_cases}</p>
                                        <p>{formattedData.percent_returned} %</p>
                                    </div>
                                    <div className="column is-4"></div>
                                </div>

                                <hr />

                                <div className="level w-100">
                                    <div className="level-item"></div>
                                    <div className="level-item">
                                        <p className="title is-5">Blood Bank Products</p>
                                    </div>
                                    <div className="level-item"></div>
                                </div>

                                <hr />

                                <div className="columns">
                                    <div className="column is-5">
                                        <p className="has-text-weight-semibold">Blood Bank Products:</p>
                                    </div>
                                    <div className="column is-3">
                                        <p>{formattedData.total_blood_products}</p>
                                    </div>
                                    <div className="column is-4"></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <button onClick={props.onClose} className="modal-close is-large" aria-label="close"></button>
            </section>

            <footer className="modal-card-foot">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="button is-danger" onClick={props.onClose}>Close</button>
                    </div>
                    <div className="level-right">
                        {
                            data
                            &&
                            <button className="button is-success" onClick={handlePrint}>Print</button>
                        }
                    </div>
                </div>
            </footer>
        </div>
    </div>
}

export default PdfHemFacility