import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import DatePicker from "react-datepicker";
import API from "../../services/API";
import { dateToDateOnlyString } from "../helpers/date";
import {
  PdfProcedureReportMockData,
  PdfProcedureReportRenderer,
} from "./PdfProcedureReport";
import {
  PdfPatientProfileMockData,
  PdfPatientProfileRenderer,
} from "./PdfPatientProfile";
import {
  PdfCPBAnalyticsMockData,
  PdfCPBAnalyticsRenderer,
} from "./PdfCPBAnalytics";
import {
  PdfAutotransfusionAnalyticsMockData,
  PdfAutotransfusionAnalyticsRenderer,
} from "./PdfAutotransfusionAnalyticsReport";
import {
  PdfPerfusionStandbyAnalyticsMockData,
  PdfPerfusionStandbyAnalyticsRenderer,
} from "./PdfPerfusionStandbyAnalytics";
import {
  PdfBloodProductSummaryMockData,
  PdfBloodProductSummaryRenderer,
} from "./PdfBloodProductSummary";
import {
  PdfBloodProductDetailsMockData,
  PdfBloodProductDetailsRenderer,
} from "./PdfBloodProductDetails";
import {
  PdfContinuousQualityImprovementMockData,
  PdfContinuousQualityImprovementRenderer,
} from "./PdfContinuousQualityImprovement";
import {
  PdfNonComplianceMockData,
  PdfNonComplianceRenderer,
} from "./PdfNonCompliance";
import { PdfStudyDataMockData, PdfStudyDataRenderer } from "./PdfStudyData";
import {
  PdfDisposablesMockData,
  PdfDisposablesRenderer,
} from "./PdfDisposables";

const PdfQCBundle = (props) => {
  const [filters, setFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const componentRef = useRef();
  const [bundleData, setBundleData] = useState();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onFilterChanged = (value, type) => {
    let fValues = { ...filters };
    if (type === 0) {
      fValues["start_date"] = value;
    } else if (type === 1) {
      fValues["end_date"] = value;
    } else if (type === 2) {
      fValues["facility"] = value;
    }
    setFilters(fValues);
  };

  const renderData = () => {
    if (filters.facility != null && filters.start_date && filters.end_date) {
      if (!isLoading) {
        setIsLoading(true);

        const params = {
          facility_id: filters.facility,
          start_date: dateToDateOnlyString(filters.start_date),
          end_date: dateToDateOnlyString(filters.end_date),
        };

        API.getReportBundleData(
          params,
          (resp) => {
            console.log("resp", resp);
            setBundleData(resp);
            setIsLoading(false);
          },
          onDataLoadError
        );
      }
    }
  };

  const onDataLoadError = (error) => {
    console.log(error);
  };
  const start_date = filters.start_date
    ? new Date(filters.start_date).toLocaleDateString()
    : "";
  const end_date = filters.end_date
    ? new Date(filters.end_date).toLocaleDateString()
    : "";
  const facility =
    props.facilities && filters.facility
      ? props.facilities.find((f) => {
          return f.value == filters.facility;
        }).label ?? ""
      : "";
  const useMockData = false;

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card modal-extra-large">
        <header className="modal-card-head">
          <p className="modal-card-title">Quality Control Bundle Report</p>
        </header>

        <section className="modal-card-body p-0">
          <div className="p-5">
            <div className="w-100">
              <div className="columns">
                <div className="column">
                  <div style={{ midWidth: "499px", maxWidth: "500px" }}>
                    <Select
                      options={props.facilities}
                      placeholder={"All"}
                      value={props.facilities.find(
                        (e) => e.value == filters.facility
                      )}
                      onChange={(option) => onFilterChanged(option.value, 2)}
                    />
                  </div>
                </div>
                <div
                  className="column"
                  style={{ maxWidth: "200px", minWidth: "199px" }}
                >
                  <DatePicker
                    className="input"
                    selectsStart
                    startDate={filters.start_date}
                    endDate={filters.end_date}
                    selected={filters.start_date}
                    onChange={(date) => onFilterChanged(date, 0)}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Start Date"
                  />
                </div>
                <div
                  className="column"
                  style={{ maxWidth: "200px", minWidth: "199px" }}
                >
                  <DatePicker
                    className="input"
                    selectsStart
                    startDate={filters.start_date}
                    endDate={filters.end_date}
                    selected={filters.end_date}
                    onChange={(date) => onFilterChanged(date, 1)}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="End Date"
                  />
                </div>
              </div>

              <div className="level w-100">
                <div className="level-item has-text-centered">
                  <button
                    className={`button is-success ${isLoading && "is-loading"}`}
                    onClick={() => renderData()}
                    disabled={!start_date || !end_date || !facility}
                  >
                    Generate
                  </button>
                </div>
              </div>

              {!bundleData && (
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              )}

              {bundleData && !isLoading && (
                <div ref={componentRef} className="has-background-white">
                  <div className="w-100 is-size-7 has-text-centered">
                    <p className="is-size-1">Quality Control Bundle Report</p>
                    <br />
                    <br />
                    <p className="is-size-3">
                      <span>For:</span>
                      <br />
                      <span>{facility}</span>
                      <br />
                      <span className="is-italic">{`${start_date} - ${end_date}`}</span>
                    </p>
                    <br />
                    <br />
                    <p className="is-size-3">
                      <span>Presented By:</span>
                      <br />
                      <span>Perfusion.com dba EPIC STAFFING INC</span>
                      <br />
                      <span>2250 McGregor Blvd Fort Myers, Florida 33902</span>
                    </p>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <p className="is-size-4 has-text-danger">
                      *** CONFIDENTIAL INFORMATION ENCLOSED ***
                    </p>
                  </div>
                  <div style={{ breakAfter: "page" }} />
                  {PdfProcedureReportRenderer({
                    facility: facility ?? "",
                    results: useMockData
                      ? PdfProcedureReportMockData()
                      : bundleData.procedure_data,
                    dateRange: `${start_date} - ${end_date}`,
                  })}
                  <div style={{ breakAfter: "page" }} />
                  {PdfPatientProfileRenderer({
                    facility: facility ?? "",
                    results: useMockData
                      ? PdfPatientProfileMockData()
                      : bundleData.profile_data,
                    dateRange: `${start_date} - ${end_date}`,
                  })}
                  <div style={{ breakAfter: "page" }} />
                  {PdfCPBAnalyticsRenderer({
                    facility: facility ?? "",
                    results: useMockData
                      ? PdfCPBAnalyticsMockData()
                      : bundleData.cpb_analytic_data,
                    dateRange: `${start_date} - ${end_date}`,
                  })}
                  <div style={{ breakAfter: "page" }} />
                  {PdfAutotransfusionAnalyticsRenderer({
                    facility: facility ?? "",
                    results: useMockData
                      ? PdfAutotransfusionAnalyticsMockData()
                      : bundleData.auto_analytic_data,
                    dateRange: `${start_date} - ${end_date}`,
                  })}
                  <div style={{ breakAfter: "page" }} />
                  {PdfPerfusionStandbyAnalyticsRenderer({
                    facility: facility ?? "",
                    results: useMockData
                      ? PdfPerfusionStandbyAnalyticsMockData()
                      : bundleData.standby_analytic_data,
                    dateRange: `${start_date} - ${end_date}`,
                  })}
                  <div style={{ breakAfter: "page" }} />
                  {PdfBloodProductSummaryRenderer({
                    facility: facility ?? "",
                    results: useMockData
                      ? PdfBloodProductSummaryMockData()
                      : bundleData.blood_usage_summary,
                    dateRange: `${start_date} - ${end_date}`,
                  })}
                  <div style={{ breakAfter: "page" }} />
                  {PdfBloodProductDetailsRenderer({
                    facility: facility ?? "",
                    results: useMockData
                      ? PdfBloodProductDetailsMockData()
                      : bundleData.blood_detail_data,
                    dateRange: `${start_date} - ${end_date}`,
                  })}
                  <div style={{ breakAfter: "page" }} />
                  {PdfContinuousQualityImprovementRenderer({
                    facility: facility ?? "",
                    results: useMockData
                      ? PdfContinuousQualityImprovementMockData()
                      : bundleData.quality_improvement_data,
                    dateRange: `${start_date} - ${end_date}`,
                  })}
                  <div style={{ breakAfter: "page" }} />
                  {PdfNonComplianceRenderer({
                    facility: facility ?? "",
                    results: useMockData
                      ? PdfNonComplianceMockData()
                      : bundleData.non_compliance_data,
                    dateRange: `${start_date} - ${end_date}`,
                  })}
                  <div style={{ breakAfter: "page" }} />
                  {PdfStudyDataRenderer({
                    facility: facility ?? "",
                    results: useMockData
                      ? PdfStudyDataMockData()
                      : bundleData.study_data,
                    dateRange: `${start_date} - ${end_date}`,
                  })}
                  <div style={{ breakAfter: "page" }} />
                  {PdfDisposablesRenderer({
                    facility: facility ?? "",
                    results: useMockData
                      ? PdfDisposablesMockData()
                      : bundleData.disposable_data,
                    dateRange: `${start_date} - ${end_date}`,
                  })}
                </div>
              )}
            </div>
          </div>

          <button
            onClick={props.onClose}
            className="modal-close is-large"
            aria-label="close"
          ></button>
        </section>

        <footer className="modal-card-foot">
          <div className="level w-100">
            <div className="level-left">
              <button className="button is-danger" onClick={props.onClose}>
                Close
              </button>
            </div>
            <div className="level-right">
              {bundleData && (
                <button className="button is-success" onClick={handlePrint}>
                  Print
                </button>
              )}
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default PdfQCBundle;
