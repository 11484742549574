import React, { useEffect, useState, useMemo } from "react"
import DataTable from "react-data-table-component"
import API from "../../../../services/API"
import Session from "../../../../services/Session"
import ConfirmationModal from "../../../components/ConfirmationModal"

function Notification(props) {
  const tableValues = [{ id: "1", label: "Cases" }, { id: "2", label: "Inventory" }, { id: "3", label: "Equipment" }, { id: "4", label: "Reports" }]
  const [notificationValues, setNotificationValues] = useState({ case_email: null, case_sms: null, inventory_email: null, inventory_sms: null, equipment_email: null, equipment_sms: null, report_email: null, report_sms: null })
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  useEffect(() => {
    let user = Session.get('user')
    if(user.notification_preferences != null) {
      API.query('GET',`/api/notificationpreferences/${user.notification_preferences}/`,{},{},function(resp) {
        setNotificationValues(resp)
      })
    } else {
      API.query('POST',`/api/notificationpreferences/`,{},{},function(resp) {
        let data = {
          'notification_preferences': resp.id
        }
        API.query('PATCH',`/api/users/${user.id}/`,{},data,function(resp) {
          Session.set('user',resp)
        })
      })
    }
    
  }, []);

  const checkStatus = (row, column) => {
    if (row == 1) {
      if (column == 1) {
        return notificationValues.case_email
      } else if (column == 2) {
        return notificationValues.case_sms
      }
    } else if (row == 2) {
      if (column == 1) {
        return notificationValues.inventory_email
      } else if (column == 2) {
        return notificationValues.inventory_sms
      }
    } else if (row == 3) {
      if (column == 1) {
        return notificationValues.equipment_email
      } else if (column == 2) {
        return notificationValues.equipment_sms
      }
    } else if (row == 4) {
      if (column == 1) {
        return notificationValues.report_email
      } else if (column == 2) {
        return notificationValues.report_sms
      }
    }
  }

  const onNotificationChange = (row, value, column) => {
    var notification = {...notificationValues}

    if (row == 1) {
      if (column == 1) {
        notification.case_email = value
      } else if (column == 2) {
        notification.case_sms = value
      }
    } else if (row == 2) {
      if (column == 1) {
        notification.inventory_email = value
      } else if (column == 2) {
        notification.inventory_sms = value
      }
    } else if (row == 3) {
      if (column == 1) {
        notification.equipment_email = value
      } else if (column == 2) {
        notification.equipment_sms = value
      }
    } else if (row == 4) {
      if (column == 1) {
        notification.report_email = value
      } else if (column == 2) {
        notification.report_sms = value
      }
    }

    setNotificationValues(notification)
  }

  const onConfirm = () => {
    setShowConfirmationModal(true)
  }

  const onSave = () => {
    let user = Session.get('user')
    let data = {
      'case_email': notificationValues['case_email'],
      'case_sms': notificationValues['case_sms'],
      'inventory_email': notificationValues['inventory_email'],
      'inventory_sms': notificationValues['inventory_sms'],
      'equipment_email': notificationValues['equipment_email'],
      'report_email': notificationValues['report_email'],
      'report_sms': notificationValues['report_sms']
    }
    API.query('PATCH',`/api/notificationpreferences/${user.notification_preferences}/`,{},data,function(resp) {
      setShowConfirmationModal(false)
    })
  }

  const columns = useMemo(() => [
    { name: '', selector: row => <label className="has-text-weight-semibold">{row.label}</label> },
    {
      name: 'Email', selector: row => <div>
        <input
          type="checkbox"
          checked={checkStatus(row.id, 1)}
          onChange={(e) => onNotificationChange(row.id, e.target.checked, 1)}
        />
      </div>
    },
    {
      name: 'SMS', selector: row => <div>
        <input
          type="checkbox"
          checked={checkStatus(row.id, 2)}
          onChange={(e) => onNotificationChange(row.id, e.target.checked, 2)}
        />
      </div>
    }
  ])

  return <div>
    <p className="title is-size-2">Notification Preferences</p>
    <p>Select how you would prefer to receive notifications from OnCloud.</p>
    <hr />
    <div className="columns">
      <div className="column is-4">
        <p className="title is-size-4">Notification Preferences By Type</p>
        <p>Select where you'd like notifications to be sent by each type of notification on OnCloud</p>
        <br />
        <p>Note: Your Email is your OnCloud email address as shown in Profile-My Detail section. If enabled, SMS notifications go to the Primary Phone type also shown in Profile-My Detail section.</p>
      </div>
      <div className="column is-1">

      </div>
      <div className="column is-7">
        <DataTable
          striped
          data={tableValues}
          columns={columns}
        />
      </div>
    </div>

    {
      showConfirmationModal
      &&
      <ConfirmationModal
        message={'You are about to update your notification preferences. Please confirm.'}
        onConfirm={onSave}
        onClose={() => setShowConfirmationModal(false)}
      />
    }

    <hr />

    <div className="level w-100">
      <div className="level-left">

      </div>
      <div className="level-right">
        <button className="button is-success" onClick={onConfirm}>Save</button>
      </div>
    </div>
  </div>
}

export default Notification;