import React, { useEffect, useState } from "react";
import API from "../../services/API";
import { CSVLink } from "react-csv";
import Select from 'react-select';

function AllInventoryReport(props) {
    const [data, setData] = useState()
    const [isLoading,setIsLoading] = useState(false);
    const [filters, setFilters] = useState({})
    const today = new Date()

    const onFilterChanged = (value) => {
        setFilters({facility:value})
    }

    const generateReport = () => {
        if(!isLoading) { 
            setIsLoading(true)
            let params = {}

            if(filters.facility && filters.facility.length > 0) {
                params = {
                    facilities: [...filters.facility]
                }
            }

            API.query('GET', 'api/allinvreport/', params, {}, (resp) => {
                setData(resp)
                setIsLoading(false)
            }, () => {
                console.log('failed loading report data')
            })
        }
    }

    const getReportData = () => {
        if (data && data.length > 0) {
            const formattedData = data.reduce((memo,e,idx) => {
                for(let i=0; i < e.location_counts.length; i++) {
                    if(filters.facility && filters.facility.includes(e.location_counts[i].facility)) {
                        memo.push({
                            facility:e.location_counts[i].facility_name, 
                            description:e.description,
                            category:e.category,
                            type:e.type,
                            manu:e.manu_name,
                            catalog:e.catalog,
                            count:e.location_counts[i].count,
                            par:e.location_counts[i].par
                        })
                    } else if(!filters.facility) {
                        memo.push({
                            facility:e.location_counts[i].facility_name, 
                            description:e.description,
                            category:e.category,
                            type:e.type,
                            manu:e.manu_name,
                            catalog:e.catalog,
                            count:e.location_counts[i].count,
                            par:e.location_counts[i].par
                        })
                    } 
                }

                return memo
            },[]).sort((a,b) => (a.facility < b.facility) ? -1 : (a.facility > b.facility) ? 1 : 0)

            var rows = []
            rows.push(['All Inventory Report'])
            let facilityString = ''
            if(filters.facility) {
                for(let z=0; z < filters.facility.length; z++) {
                    if(z === 0) {
                        facilityString = `${props.facilities.find(e => e.value === filters.facility[z]).label}`
                    } else {
                        facilityString = facilityString + `, ${props.facilities.find(e => e.value === filters.facility[z]).label}`
                    }
                }
            } else facilityString = 'All Facilities'
            
            rows.push(['Hospital(s):',`${facilityString}`])
            rows.push([])
            rows.push([])
            rows.push([])
            let keys = ['#', 'Facility', 'Type', 'Manufacturer', 'Item Category', 'Catalog Number', 'Description', 'Par', 'Count']
            rows.push(keys)
            for(let x=0; x<formattedData.length; x++) {
                rows.push([
                    `${x+1}`,
                    `${formattedData[x].facility}`,
                    `${formattedData[x].type}`,
                    `${formattedData[x].manu}`,
                    `${formattedData[x].category}`,
                    `${formattedData[x].catalog}`,
                    `${formattedData[x].description}`,
                    `${formattedData[x].par}`,
                    `${formattedData[x].count}`
                ])
            }

            return rows
        } else {
            return []
        }
    }

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
            <header className="modal-card-head has-background-white is-block">
                <p className="modal-card-title">All Inventory Report</p>
            </header>

            <section className="modal-card-body">
                <div className="columns">
                    <div className="column"></div>
                    <div className="column">
                        <div style={{ minWidth: '499px', maxWidth: '500px' }}>
                            <Select
                                options={props.facilities}
                                placeholder={'All Facilities'}
                                value={props.facilities.find((e) => e.value == filters.facility)}
                                onChange={(option) => onFilterChanged(option.map(option => option.value))}
                                isMulti={true}
                            />
                        </div>
                    </div>
                    <div className="column"></div>
                </div>
                
                <div className="level w-100">
                    <div className="level-item has-text-centered">
                        <button disabled={isLoading} className="button is-success" onClick={() => generateReport()}>Set Filters</button>
                    </div>
                </div>
            </section>

            <footer className="modal-card-foot has-background-white">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="level-item button is-danger is-inverted" onClick={props.onClose}>Close</button>
                    </div>
                    <div className="level-right">
                        {
                            data
                            &&
                            <CSVLink
                                filename={`all_inv_${today.toLocaleDateString()}`}
                                data={getReportData()}
                            >
                                <button className="button is-danger">Download</button>
                            </CSVLink>
                        }
                    </div>
                </div>
            </footer>
        </div>
    </div>
}

export default AllInventoryReport