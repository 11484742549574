import React from 'react';

function ListModal(props) {
    const {list, onClose, string} = props

    return <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{string}</p>
      </header>
        <section className="modal-card-body p-0">
          <div className="p-5">
            <div className="has-background-white rounded w-100 is-flex content">
              <ul>
                {
                    list.map(i => (<li key={i.value}>{i.label}</li>))
                }
              </ul>
              <button onClick={onClose} className="modal-close is-large" aria-label="close"></button>
            </div>
          </div>
        </section>

        <footer className="modal-card-foot">
          <button className="button" onClick={onClose}>OK</button>
        </footer>
      </div>
    </div>
}

export default ListModal;