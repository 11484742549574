import React, { useEffect, useState, useMemo } from "react";
import VisitTimer from "../../../components/VisitTimer";
import API from "../../../../services/API";
import Session from "../../../../services/Session";
import DataTable from "react-data-table-component";
import PdfDisposableByPatient from "../../../components/PdfDisposableByPatient";
import { FaFileExcel, FaRegFilePdf } from 'react-icons/fa';
import PdfLogReport from "../../../components/PdfLogReport";
import DisposablePriceReport from "../../../components/DisposablePriceReport";
import AllOrderReport from "../../../components/AllOrderReport";
import BackorderReport from "../../../components/BackorderReport";
import AllInventoryReport from "../../../components/AllInventoryReport";
import ItemExportCSV from "../../../components/ItemExportCSV";
import ReorderPointReport from "../../../components/ReorderPointReport";
import DisposableByPatient from "../../../components/DisposableByPatient";

function InventoryReport(props){
  const [facilityOptions, setFacilityOptions] = useState([])
  const [showLogReport, setShowLogReport] = useState(false)
  const [showDisposableByPatient, setShowDisposableByPatient] = useState(false)
  const [showDisposablePriceReport, setShowDisposablePriceReport] = useState(false)
  const [showAllInventory,setShowAllInventory] = useState(false)
  const [showAllOrders, setShowAllOrders] = useState(false)
  const [showBackorders, setShowBackorders] = useState(false)
  const [showItemExport, setShowItemExport] = useState(false)
  const [showReorderPoint, setShowReorderPoint] = useState(false)
  const [showDisposableCSVByPatient, setShowDisposableCSVByPatient] = useState(false)

  const reports = [
    {
      id:1,
      name:"Inventory Log Report",
      description:"Inventory Log Report",
      csv: false,
      pdf: true
    },
    {
      id:2,
      name:"Disposable Report by Patient",
      description:"Disposable Report by Patient",
      csv: true,
      pdf: true
    },
    {
      id:3,
      name:"Patient Disposable Report with Price",
      description:"Patient Disposable Report with Price",
      csv: true,
      pdf: false
    },
    {
      id:4,
      name:"All Order Report",
      description: "All Order Report",
      csv: true,
      pdf: false
    },
    {
      id:5,
      name:"Backorder Report",
      description: "Backorder Report",
      csv: true,
      pdf: false
    },
    {
      id:6,
      name:"All Inventory Report",
      description: "All Inventory Report",
      csv: true,
      pdf: false
    },
    {
      id:7,
      name:"All Item Export",
      description:"All Item Export",
      csv: true,
      pdf: false
    },
    {
      id:8,
      name:"Reorder Point Report",
      description:"Report to show inventory levels, broke down at location level for those inventory items that are at or below PAR levels",
      csv: true,
      pdf: false
    }
  ]

  const visited = () => {
    // get pageviewhistory id from user session data
    var user = Session.get('user')
    API.query('GET',`/api/pageviewhistory/${user.page_view_history}/`,{},{},function(response) {
      let data = {
        "report_inventory": response.report_inventory + 1
      }
      API.query('PATCH',`/api/pageviewhistory/${user.page_view_history}/`,{},data,function(resp) {}, onDataLoadError)
    }, onDataLoadError)
  }

  const onDataLoadError = (error) => {
    console.log(error)
  }

  const onViewClicked = (row,csv) => {
    if(row.id == 1) {
      setShowLogReport(true)
    } else if(row.id == 2) {
      if(csv) {
        setShowDisposableCSVByPatient(true)
      } else {
        setShowDisposableByPatient(true)
      }
    } else if(row.id == 3) {
      setShowDisposablePriceReport(true)
    } else if(row.id == 4) {
      setShowAllOrders(true)
    } else if(row.id == 5) {
      setShowBackorders(true)
    } else if(row.id == 6) {
      setShowAllInventory(true)
    } else if(row.id == 7) {
      setShowItemExport(true)
    } else if(row.id == 8) {
      setShowReorderPoint(true)
    }
  }

  const columns = useMemo(() => [
    {name:'Name of Report',selector: row => row.name,sortable:true, grow:2},
    {name:'Description', selector: row => row.description,sortable:true, grow:4},
    {name:'Actions',grow:0,selector: row =><div style={{textAlign:"center"}}>
      {
        row.csv
        &&
        <a className="ml-3" title="CSV Download" onClick={() => { onViewClicked(row,true);}}><FaFileExcel size={18} title="CSV Download"/></a>
      }
      {
        row.pdf
        &&
        <a className="ml-3" title="PDF Download" onClick={() => onViewClicked(row)}><FaRegFilePdf size={18} /></a>
      }
    </div>}
  ])

  useEffect(() => {
    let queries = [
      { 'method': 'GET', 'path': '/api/facilities/', }
    ]
    API.all(queries, function (resp) {
      if(props.permissions.includes(17) || props.extraPermissions.includes(17)) {
        let user = Session.get('user')
        let filteredFacils = resp[0].filter((obj) => {
          var i, abort=false, valid=false
          for(i=0; i < user.facilities.length && !abort; i++) {
            if(user.facilities[i] == obj.id) {
              valid = true
              abort = true
            }
          }
          return valid
        })

        setFacilityOptions(filteredFacils.map(i => ({ "label": i.name, "value": i.id })))
      } else {
        setFacilityOptions(resp[0].map(i => ({ "label": i.name, "value": i.id })))
      }
    }, onDataLoadError)
  }, []);

  return <div>
    <p className="title is-3">Inventory Reports</p>

    <div className="box">
      <DataTable 
        striped
        data={reports}
        columns={columns}
      />
    </div>

    {
      showDisposableByPatient
      &&
      <PdfDisposableByPatient
        facilities={facilityOptions}
        closeModal={() => setShowDisposableByPatient(false)}
      />
    }

    {
      showLogReport
      &&
      <PdfLogReport
        facilities={facilityOptions}
        onClose={() => setShowLogReport(false)}
      />
    }

    {
      showDisposablePriceReport
      &&
      <DisposablePriceReport
        facilities={facilityOptions}
        onClose={() => setShowDisposablePriceReport(false)}
      />
    }

    {
      showAllOrders
      &&
      <AllOrderReport
        onClose={() => setShowAllOrders(false)}
      />
    }

    {
      showBackorders
      &&
      <BackorderReport
        onClose={() => setShowBackorders(false)}
      />
    }

    {
      showAllInventory
      &&
      <AllInventoryReport
        onClose={() => setShowAllInventory(false)}
        facilities={facilityOptions}
      />
    }

    {
      showItemExport
      &&
      <ItemExportCSV
        onClose={() => setShowItemExport(false)}
      />
    }

    {
      showReorderPoint
      &&
      <ReorderPointReport
        facilities={facilityOptions}
        onClose={() => setShowReorderPoint(false)}
      />
    }

    {
      showDisposableCSVByPatient
      &&
      <DisposableByPatient
      facilities={facilityOptions}
        onClose={() => setShowDisposableCSVByPatient(false)}
      />
    }

    <VisitTimer visited={visited}/>
  </div>
}

export default InventoryReport