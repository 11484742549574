import React from 'react';

function ItemListModal(props) {
  const { items, onClose } = props

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">Items on Order</p>
      </header>
      <section className="modal-card-body p-0">
        <div className="p-5">
          <div className="has-background-white rounded w-100">
            {
              items.map((item, i) => (<div key={i}>
                <div className="level w-100">
                  <div className="level-left">
                    <p className="has-text-weight-medium">Description: </p>
                  </div>
                  <div className="level-right">
                    <p>{item.desc}</p>
                  </div>
                </div>

                <div className="level w-100">
                  <div className="level-left">
                    <p className="has-text-weight-medium">Catalog Number: </p>
                  </div>
                  <div className="level-right">
                    <p>{item.cat}</p>
                  </div>
                </div>

                <div className="level w-100">
                  <div className="level-left">
                    <p className="has-text-weight-medium">Manufacturer: </p>
                  </div>
                  <div className="level-right">
                    <p>{item.manu}</p>
                  </div>
                </div>

                {
                  item.quant_type == 1
                    ?
                    <div>
                      <div className="level w-100">
                        <div className="level-left">
                          <p className="has-text-weight-medium">Packaging Type: </p>
                        </div>
                        <div className="level-right">
                          <p>Case</p>
                        </div>
                      </div>

                      <div className="level w-100">
                        <div className="level-left">
                          <p className="has-text-weight-medium">Quantity per Case: </p>
                        </div>
                        <div className="level-right">
                          <p>{item.quant}</p>
                        </div>
                      </div>
                    </div>
                    :
                    <div>
                      <div className="level w-100">
                        <div className="level-left">
                          <p className="has-text-weight-medium">Packaging Type: </p>
                        </div>
                        <div className="level-right">
                          <p>Individual</p>
                        </div>
                      </div>
                    </div>
                }

                {
                  i < (items.length - 1)
                    ?
                    <hr />
                    :
                    null
                }
              </div>))
            }
            <button onClick={onClose} className="modal-close is-large" aria-label="close"></button>
          </div>
        </div>
      </section>

      <footer className="modal-card-foot">
        <div className="level w-100">
          <div className="level-left"></div>
          <div className="level-right">
            <button className="button is-success" onClick={onClose}>OK</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
}

export default ItemListModal;