import React, { useEffect, useState } from "react";
import format from "date-fns/format";
import parse from "date-fns/parse";
import DatePicker from "react-datepicker";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import AsyncSelect from 'react-select/async'

import "react-datepicker/dist/react-datepicker.css";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

export function getInitialValuesForForm(form){
  const initialValues = {}
  form.fields.forEach(field => {
    let value = ""
    if(field.defaultValue) {
      value = field.defaultValue
    } else if(field.type === 'daterange'){
      value = [null,null]
    } else if(field.type === 'date'){
      value = null
    } else if(field.type === 'checkbox'){
      value = false
    } else if(field.type === 'select'){
      value = field.isMulti ? [] : null
    } else if (field.type === 'number'){
      value = null
    }
    initialValues[field.id] = value
  })
  return initialValues
}

function DynamicForm(props) {
  const {form,values,onValuesChanged,onButtonPressed,source,missingFields,isLoading,loaders} = props

  useEffect(() => {
    if(!values) loadDefaultValues()
  },[])

  const loadDefaultValues = () => {
    const initialValues = getInitialValuesForForm(form)
    onValuesChanged(initialValues)
  }

  const parseDate = (value,formatString) => {
    if(Array.isArray(value) || !value) return null
    try {
      return parse(value,formatString,new Date())
    } catch (e) {
      return null
    }
  }

  const parseDateRange = (range,formatString) => {
    if(!Array.isArray(range)) return [null,null]
    return [
      parseDate(range[0],formatString),
      parseDate(range[1],formatString),
    ]
  }

  const formatDate = (value,formatString) => {
    if(Array.isArray(value) || !value) return null
    try {
      return format(value,formatString)
    } catch (e) {
      return null
    }
  }

  const formatDateRange = (range,formatString) => {
    if(!Array.isArray(range)) return [null,null]
    return [
      formatDate(range[0],formatString),
      formatDate(range[1],formatString),
    ]
  }

  const onChange = (id,value) => {
    const newValues = {...values}
    newValues[id] = value
    onValuesChanged(newValues)
  }

  const getInputForField = (field,index) => {
    let value = values[field.id]
    if(field.isObject && value) value = value.id

    let input
    if(field.type === 'text') {
      input = <input
        className="input"
        type="text"
        defaultValue={value}
        placeholder={field.placeholder}
        onChange={(e) => onChange(field.id,e.target.value)}
        required={(field.required && field.required == "true") ? "required": ""}
        disabled={field.disabled||props.disabled}
      />
    } else if(field.type === 'number') {
      input = <input
        className="input"
        type="number"
        defaultValue={field.controlled && field.controlled == "true" ? undefined : value}
        value={field.controlled && field.controlled == "true" ? value??"" : undefined}
        placeholder={field.placeholder}
        onChange={(e) => {
          let value = e.target.value
          if(value) value = parseFloat(value)
          onChange(field.id,value)
        }}
        onWheel={(e)=>{e.target.blur()}}
        required={(field.required && field.required == "true") ? "required": ""}
        disabled={field.disabled||props.disabled}
        min={field.min??undefined}
        max={field.max??undefined}
        step={field.step??undefined}
      />
    } else if(field.type === 'email') {
      input = <input
        className="input"
        type="email"
        value={value}
        placeholder={field.placeholder}
        onChange={(e) => onChange(field.id,e.target.value)}
        required={(field.required && field.required == "true") ? "required": ""}
        disabled={field.disabled||props.disabled}
      />
    } else if(field.type === 'date' || field.type === 'daterange') {
      let formatString = ""
      if(field.timeOnly){
        formatString = "h:mm aa"
      } else if(field.fullTimeOnly) {
        formatString = "HH:mm:ss"
      } else if(field.includeTime){
        formatString = "MM/dd/yyyy h:mm aa"
      } else {
        formatString = "MM/dd/yyyy"
      }

      if(field.type === 'date'){
        input = <DatePicker
          className="input"
          showTimeSelect={field.includeTime}
          showTimeSelectOnly={field.timeOnly || field.fullTimeOnly}
          timeCaption="Time"
          dateFormat={formatString}
          selected={parseDate(value,formatString)}
          onChange={(date) => onChange(field.id,formatDate(date,formatString))}
          placeholderText={field.placeholder}
          disabled={field.disabled||props.disabled}
        />
    } else if (field.type === 'daterange'){
      const [startDate,endDate] = parseDateRange(value,formatString)
      input = <DatePicker
        className="input"
        showTimeSelect={field.includeTime}
        showTimeSelectOnly={field.timeOnly}
        timeCaption="Time"
        selectsRange
        dateFormat={formatString}
        startDate={startDate}
        endDate={endDate}
        onChange={(range) => onChange(field.id,formatDateRange(range,formatString))}
        placeholderText={field.placeholder}
        disabled={field.disabled||props.disabled}
      />
    }
    } else if(field.type === 'color') {
      input = <input
        className="input"
        style={{width:'3rem'}}
        type="color"
        value={value}
        placeholder={field.placeholder}
        onChange={(e) => onChange(field.id,e.target.value)}
        disabled={field.disabled||props.disabled}
      />
    } else if(field.type === 'file') {
      input = <input
        className="input"
        type="file"
        value={value}
        placeholder={field.placeholder}
        onChange={(e) => onChange(field.id,e.target)}
        disabled={field.disabled||props.disabled}
      />
    } else if (field.type === 'textarea') {
      input = <textarea
        className="textarea"
        value={value}
        placeholder={field.placeholder}
        rows={field.rows}
        onChange={(e) => onChange(field.id,e.target.value)}
        disabled={field.disabled||props.disabled}
      />
    } else if (field.type === 'select') {
      if(!value) value = []
      if(field.isMulti){
        if(field.optionsSource) {
          if(source[field.optionsSource]) {
            value = value.map(value => source[field.optionsSource].find(option => option.value === value))
          }
        }else {
          if(!field.isAsync) {
            value = value.map(value => field.options.find(option => option.value === value))
          }
        }
      } else {
        if(field.optionsSource) {
          if(source[field.optionsSource]) {
            value = source[field.optionsSource].find(option => option.value === value)
          }
        }else {
          if(!field.isAsync) value = field.options.find(option => option.value === value)
        }
      }

      let options = []
      if(field.options) options = field.options
      if(field.optionsSource && source[field.optionsSource]) options = source[field.optionsSource]
      if(typeof value == "undefined") value = []

      input = field.isCreatable ? 
      <CreatableSelect
        placeholder={field.placeholder}
        options={options}
        isMulti={field.isMulti}
        isSearchable={true}
        onChange={(option) => onChange(field.id,field.isMulti ? option.map(option => option.value) : option.value)}
        value={value}
        isDisabled={field.disabled||props.disabled}
      /> : field.isAsync ? 
      <AsyncSelect
        placeholder={field.placeholder}
        loadOptions={loaders[field.id]}
        isMulti={field.isMulti}
        defaultOptions
        value={value}
        cacheOptions
        onChange={(option) => onChange(field.id,option)}
        isDisabled={field.disabled||props.disabled}
      /> :
      <Select
        placeholder={field.placeholder}
        options={options}
        isMulti={field.isMulti}
        isSearchable={true}
        onChange={(option) => onChange(field.id,field.isMulti ? option.map(option => option.value) : option.value)}
        value={value}
        isDisabled={field.disabled||props.disabled}
      />
      
    } else if (field.type === 'checkbox') {
      input = <label className="checkbox">
        <input
          type="checkbox"
          className="checkbox"
          checked={value}
          onChange={(e) => onChange(field.id,e.target.checked)}
          disabled={field.disabled||props.disabled}
        />
        &nbsp;
        {field.label}
      </label>
    } else if (field.type === 'radio') {
      input = <div className="control">
        {
          field.options.map((option,i) => (
            <label key={i} className="radio is-block ml-0 mb-3">
              <input
                key={i}
                type="radio"
                name={field.id}
                value={option.value}
                checked={(value && value === option.value) || !value && option.value === field.default}
                onChange={(e) => onChange(field.id,e.target.value)}
                disabled={field.disabled||props.disabled}
              />
              &nbsp;
              {option.label}
            </label>
          ))
        }
      </div>
    } else if (field.type === 'image') {
      input = <img height={100} width={200} src={value} />
    }
    return input
  }

  if(!form || !values) return null

  return <div>
    { form.name && <p className="title">{form.name}</p> }
    <div className="columns is-multiline">
      {
        form.fields
        &&
        form.fields.map((field,index) => {
          if(
            field.branch_from &&
            (
              (values[field.branch_from.id] && !field.branch_from.value) ||
              values[field.branch_from.id] !== field.branch_from.value
            )
          ) return null

          let input = getInputForField(field,index)

          return <div key={index} className={"column is-"+(field.size??12)}>
            <label className="label">{field.label}</label>
            <div className="control">{input}</div>
            {
            (missingFields && missingFields.includes(field.id))
            &&
            <p className="help is-danger">This field is required</p>
            } 
          </div>
        })
      }

      {
        form.accordions
        && (<div className="column is-12">
        {form.accordions.map((accordion,accordionIndex) => {
          let accordionItems = accordion.items.map((item,itemIndex) => {

            let fields = item.fields.map((field,fieldIndex) => {
              if(
                field.branch_from &&
                (
                  (values[field.branch_from.id] && !field.branch_from.value) ||
                  values[field.branch_from.id] !== field.branch_from.value
                )
              ) return null

              let input = getInputForField(field,fieldIndex)

              return <div key={fieldIndex} className={"column is-"+(field.size??12)}>
                <label className="label">{field.label}</label>
                <div className="control">{input}</div>
                {
                (missingFields && missingFields.includes(field.id))
                &&
                <p className="help is-danger">This field is required</p>
                } 
              </div>
            })

            return (
              <AccordionItem key={itemIndex}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {item.label??null}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {fields}
                </AccordionItemPanel>
              </AccordionItem>
            )
          })
          return (
            <div>
              <label className="label">{accordion.label}</label>
              <Accordion 
                key={accordionIndex}
                allowZeroExpanded={true}
                allowMultipleExpanded={true}
              >
                {accordionItems}
              </Accordion>
            </div>
          )
        })}
        </div>)
      }

      {
        form.buttons
        &&
        <div className="column is-12">
          <div className={"field is-grouped"+(form.buttonsRight?" is-grouped-right":"")}>
            {
              form.buttons.map(button => (
                <p key={button.id} className="control">
                  <button className={`button is-${button.color??"primary"} ${isLoading?"is-loading":""}`} onClick={() => onButtonPressed(button.id)}>
                    {button.label}
                  </button>
                </p>
              ))
            }
          </div>
        </div>
      }
    </div>
  </div>
}

export default DynamicForm