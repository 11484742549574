import React, { useEffect, useState, useMemo } from "react";
import API from "../../../../services/API";
import historyForm from "../../../forms/patientHistory";
import ConfirmationModal from "../../../components/ConfirmationModal";
import AdminModal from "../../../components/AdminModal";
import ErrorModal from "../../../components/ErrorModal";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import DataTable from 'react-data-table-component';

function PatientHistory() {
    const [editValues, setEditValues] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const [patientHistory, setPatientHistory] = useState([])
    const [isSaving, setIsSaving] = useState(false)
    const [showSetupModal, setShowSetupModal] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false)
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const onEditChange = (values) => {
        setEditValues(values)
    }

    const onSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'name': editValues['name'],
                'abbreviation': editValues['abbreviation']
            }
            if(!isEditMode) {
                data = { ...data, 'is_global': true }
            }
            let method = isEditMode ? 'PATCH' : 'POST'
            let uri = isEditMode ? `/api/patienthistory/${editValues.id}/` : '/api/patienthistory/'
            API.query(method, uri, {}, data, function (resp) {
                API.query('GET','/api/patienthistory/',{},{},function(response) {
                    setPatientHistory(response)
                    setIsSaving(false)
                    setIsEditMode(false)
                    setShowSetupModal(false)
                    setEditValues([])
                },onDataLoadError)
            }, onDataLoadError)
        }
    }

    useEffect(() => {
        API.query('GET',`/api/patienthistory/`,{},{},function(resp) {
            setPatientHistory(resp)
        })
    }, []);

    const columns = useMemo(() => [
        {name:'History Name',selector: row => row.name,sortable:true},
        {name:'History Abbreviation', selector: row => row.abbreviation,sortable:true},
        {name:'Actions',selector: row =><div>
        <a title="Edit" onClick={() => onEditClicked(row)}><FaPencilAlt size={14} /></a>
        <a className="ml-3" title="Delete" onClick={() => onDeleteClicked(row)}><FaTrashAlt size={14} /></a>
        </div>}
    ])

    const onSetupModalClosed = () => {
        setShowSetupModal(false)
        setEditValues([])
        setIsEditMode(false)
    }

    const onEditClicked = (row) => {
        setEditValues(row)
        setShowSetupModal(true)
        setIsEditMode(true)
    }

    const onDeleteClicked = (row) => {
        setEditValues(row)
        setShowConfirmationModal(true)
    }

    const onConfirmDelete = () => {
        API.query("DELETE",`/api/patienthistory/${editValues.id}/`,{},{}, function(resp) {
          let newHistory = patientHistory.filter(j => j.id !== editValues.id)
          setPatientHistory(newHistory)
          setShowConfirmationModal(false)
          setEditValues([])
        },onDataLoadError)
    }

    const onDataLoadError = (error) => {
        setErrorMessage(error)
        setShowErrorModal(true)
        setIsSaving(false)
    }

    const onAddClick = () => {
        setShowSetupModal(true)
    }

    return <div>
        <p className="title is-size-2">Patient History</p>
        <p>Add, edit, or delete Patient History information.</p>
        <hr />
        <div className="columns">
            <div className="column is-4">
                <p className="title is-size-4">Patient History</p>
                <p>Add, edit, or delete Patient History information.</p>
            </div>
            <div className="column is-8">
                <button onClick={onAddClick} className="button is-medium is-fullwidth">Create New History</button>
                <DataTable
                    striped
                    pagination
                    data={patientHistory}
                    columns={columns}
                />
            </div>
        </div>

        {
            showSetupModal
            &&
            <AdminModal
                string="Create New Patient History"
                form={historyForm}
                editValues={editValues}
                onEditChange={onEditChange}
                isSaving={isSaving}
                onSave={onSave}
                onClose={onSetupModalClosed}
            />
        }

        {
            showErrorModal
            &&
            <ErrorModal
                message={errorMessage}
                onClose={() => setShowErrorModal(false)}
            />
        }

        {
            showConfirmationModal
            &&
            <ConfirmationModal
                message={"You are about to delete a LIVE Patient History. Please confirm."}
                onConfirm={onConfirmDelete}
                onClose={() => setShowConfirmationModal(false)}
            />
        }
    </div>
}

export default PatientHistory;