import React, { useEffect, useState, useRef, useMemo } from "react";
import API from "../../services/API";
import DynamicForm from "./DynamicForm";
import AutoNote from "../forms/caseDetailsAutoNote";
import HemTop from "../forms/caseDetailsHemTop";
import HemBlood from "../forms/caseDetailsHemBlood";
import HemChecklist from "../forms/caseDetailsHemPSCheck";
import HemPSForm from "../forms/caseDetailsHemPS";
import HemLab from "../forms/caseDetailsHemLab";
import HemATQC from "../forms/caseDetailsHemATQC";
import HemPRPQC from "../forms/caseDetailsHemPRPQC";
import HemATSerial from "../forms/caseDetailsHemATSerial";
import HemPSSerial from "../forms/caseDetailsHemPSSerial";
import ConfirmationModal from "./ConfirmationModal";
import BooleanRadioGroup from "./BooleanRadioGroup";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { FaTrashAlt } from "react-icons/fa";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

function Hem(props) {
  const [detail, setDetail] = useState([]);
  const [topValues, setTopValues] = useState([]);
  const [bloodValues, setBloodValues] = useState([]);
  const [unitCount, setUnitCount] = useState();
  const [units, setUnits] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState();
  const [id, setID] = useState();
  const [psValues, setPSValues] = useState();
  const [psChecklistValues, setPSChecklistValues] = useState();
  const [labValues, setLabValues] = useState();
  const [atQCValues, setATQCValues] = useState();
  const [prpQCValues, setPRPQCValues] = useState();
  const [atSerialValues, setATSerialValues] = useState();
  const [psSerialValues, setPSSerialValues] = useState();
  const [noteValues, setNoteValues] = useState();
  const [caseTypes, setCaseTypes] = useState();

  const topRef = useRef();
  const bloodRef = useRef();
  const atRef = useRef();
  const psRef = useRef();
  const checklistRef = useRef();
  const labRef = useRef();
  const atQCRef = useRef();
  const prpQCRef = useRef();
  const atSerialRef = useRef();
  const psSerialRef = useRef();
  const noteRef = useRef();

  const formatString = "HH:mm:ss";

  let saveBtnClasses = "button is-success";
  if (isSaving) saveBtnClasses += " is-loading";

  const onNoteChanged = (value) => {
    setNoteValues(value);
  };

  const onTopChanged = (value) => {
    setTopValues(value);
  };

  const onPSChanged = (value) => {
    setPSValues(value);
  };

  const onBloodChanged = (value) => {
    setBloodValues(value);
  };

  const onChecklistChanged = (value) => {
    setPSChecklistValues(value);
  };

  const onLabChanged = (value) => {
    setLabValues(value);
  };

  const onATQCChanged = (value) => {
    setATQCValues(value);
  };

  const onPRPQCChanged = (value) => {
    setPRPQCValues(value);
  };

  const onATSerialChanged = (value) => {
    setATSerialValues(value);
  };

  const onPSSerialChanged = (value) => {
    setPSSerialValues(value);
  };

  const onTopClear = () => {
    setID(1);
    setConfirmationMessage(
      "You are about to clear Hemasource Top Values, please confirm."
    );
  };

  const onBloodClear = () => {
    setID(2);
    setConfirmationMessage(
      "You are about to clear Hemasource Blood Values, please confirm."
    );
  };

  const onATClear = () => {
    setID(3);
    setConfirmationMessage(
      "You are about to clear Hemasource Autotransfusion Values, please confirm."
    );
  };

  const onTopSave = () => {
    if (!isSaving) {
      let totalHours = 0;
      let scheduled_start = new Date();
      let or_time_in = new Date();
      let sequestrian_start = new Date();
      let or_time_out = new Date();
      let post_time_out = new Date();

      const start_times = [];
      const end_times = [];

      if (topValues["scheduled_start"]) {
        const times = topValues["scheduled_start"].split(":");
        scheduled_start.setHours(times[0], times[1], times[2], 0);
        start_times.push(scheduled_start);
      }

      if (topValues["or_time_in"]) {
        const times = topValues["or_time_in"].split(":");
        or_time_in.setHours(times[0], times[1], times[2], 0);
        start_times.push(or_time_in);
      }

      if (topValues["sequestrian_start"]) {
        const times = topValues["sequestrian_start"].split(":");
        sequestrian_start.setHours(times[0], times[1], times[2], 0);
        start_times.push(sequestrian_start);
      }

      if (topValues["or_time_out"]) {
        const times = topValues["or_time_out"].split(":");
        or_time_out.setHours(times[0], times[1], times[2], 0);
        end_times.push(or_time_out);
      }

      if (topValues["post_time_out"]) {
        const times = topValues["post_time_out"].split(":");
        post_time_out.setHours(times[0], times[1], times[2], 0);
        end_times.push(post_time_out);
      }

      let earliest;
      if (start_times.length > 0) {
        for (let x = 0; x < start_times.length; x++) {
          if (x === 0) {
            earliest = start_times[x];
          } else {
            if (start_times[x] < earliest) {
              earliest = start_times[x];
            }
          }
        }
      }

      let latest;
      if (end_times.length > 0) {
        for (let x = 0; x < end_times.length; x++) {
          if (x === 0) {
            latest = end_times[x];
          } else {
            if (end_times[x] > latest) {
              latest = end_times[x];
            }
          }
        }
      }

      let ms;
      if (earliest && latest) {
        if (earliest < latest) {
          ms = latest - earliest;
        } else {
          ms = new Date(latest.getDate()).setTime(
            latest.getTime() + 86400000 - earliest
          );
        }
      }

      if (ms) {
        const hours = ms / 3600000;
        const remainder = hours % 1;
        let hour_int = Math.floor(hours);
        if (remainder.toFixed(3) >= 0.149) {
          hour_int += 1;
        }
        totalHours = hour_int;
      }

      setIsSaving(true);
      let tempValues = { ...topValues };
      tempValues.total_hours = totalHours;
      setTopValues(tempValues);
      let data = {
        case_date: tempValues["case_date"],
        or_number: tempValues["or_number"],
        sequestrian_start: tempValues["sequestrian_start"],
        scheduled_start: tempValues["scheduled_start"],
        or_time_in: tempValues["or_time_in"],
        or_time_out: tempValues["or_time_out"],
        post_time_out: tempValues["post_time_out"],
        total_hours: tempValues["total_hours"],
        case_type: tempValues["case_type"],
        description: tempValues["description"],
      };
      API.query(
        "PATCH",
        `/api/hemdetails/${detail.id}/`,
        {},
        data,
        function (resp) {
          setDetail(resp);
          setIsSaving(false);
        },
        () => props.setErrorMessage("Failed saving top values.")
      );
    }
  };

  const onBloodSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        prbc: bloodValues["prbc"],
        ffp: bloodValues["ffp"],
        plt: bloodValues["plt"],
      };
      API.query(
        "PATCH",
        `/api/hemdetails/${detail.id}/`,
        {},
        data,
        function (resp) {
          setDetail(resp);
          setIsSaving(false);
        },
        () => props.setErrorMessage("Failed saving blood values.")
      );
    }
  };

  const onATSave = () => {
    if (!isSaving) {
      for (let z = 0; z < units.length; z++) {
        if (units[z].line_clear != true) {
          props.setErrorMessage("Line has to be clear.");
          return;
        }
      }

      let newUnits = [...units];
      if (newUnits && newUnits.length > 0) {
        for (let i = 0; i < newUnits.length; i++) {
          const isNew = newUnits[i].id ? false : true;
          let data = {
            start_time: newUnits[i].start_time,
            processed_time: newUnits[i].processed_time,
            reinfused_time: newUnits[i].reinfused_time,
            ebl: newUnits[i].ebl,
            amnt_returned: newUnits[i].amnt_returned,
            wash_volume: newUnits[i].wash_volume,
            line_clear: newUnits[i].line_clear,
            atDetailID: detail.id,
          };
          let uri = isNew
            ? "/api/hemunits/"
            : `/api/hemunits/${newUnits[i].id}/`;
          let method = isNew ? "POST" : "PATCH";
          API.query(
            method,
            uri,
            {},
            data,
            function (resp) {
              newUnits[i] = {
                ...resp,
                rowID: i + 1,
                label: `Unit #${i + 1}`,
              };
            },
            () => props.setErrorMessage("Failed saving unit.")
          );
        }

        let detailPatch = {
          total_ebl: getEBL(),
          total_returned: getReturned(),
          unit_count: unitCount,
        };
        API.query(
          "PATCH",
          `/api/hemdetails/${detail.id}/`,
          {},
          detailPatch,
          function (response) {
            API.query(
              "GET",
              `/api/hemdetails/${detail.id}/`,
              {},
              {},
              function (resp) {
                setDetail(resp);
                setUnits(newUnits);
                setIsSaving(false);
              },
              () => props.setErrorMessage("Failed to fetch hemasource detail")
            );
          },
          () => props.setErrorMessage("Failed to patch hemasource detail")
        );
      } else {
        setIsSaving(false);
      }
    }
  };

  const onConfirm = () => {
    if (!isSaving) {
      setIsSaving(true);
      if (id == 1) {
        let data = {
          case_date: null,
          or_number: null,
          sequestrian_start: null,
          scheduled_start: null,
          or_time_in: null,
          or_time_out: null,
          post_time_out: null,
          total_hours: null,
          case_type: [],
          description: null,
        };
        API.query(
          "PATCH",
          `/api/hemdetails/${detail.id}/`,
          {},
          data,
          function (resp) {
            setDetail(resp);
            setID();
            setIsSaving(false);
            setConfirmationMessage();
            setTopValues({
              case_date: null,
              or_number: null,
              sequestrian_start: null,
              scheduled_start: null,
              or_time_in: null,
              or_time_out: null,
              post_time_out: null,
              total_hours: null,
              case_type: [],
              description: "",
            });
            topRef.current.reset();
          },
          () => props.setErrorMessage("Failed clearing top values.")
        );
      } else if (id == 2) {
        let data = {
          prbc: null,
          ffp: null,
          plt: null,
        };
        API.query(
          "PATCH",
          `/api/hemdetails/${detail.id}/`,
          {},
          data,
          function (resp) {
            setDetail(resp);
            setID();
            setIsSaving(false);
            setConfirmationMessage();
            setBloodValues({ prbc: null, ffp: null, plt: null });
            bloodRef.current.reset();
          },
          () => props.setErrorMessage("Failed clearing blood values.")
        );
      } else if (id == 3) {
        var i;
        for (i = 0; i < units.length; i++) {
          if (units[i].id != null) {
            API.query(
              "DELETE",
              `/api/hemunits/${units[i].id}/`,
              {},
              {},
              function (resp) {},
              () => props.setErrorMessage("Failed to clear hemasource unit")
            );
          }
        }

        let data = {
          units: [],
        };
        API.query(
          "PATCH",
          `/api/hemdetails/${detail.id}/`,
          {},
          data,
          function (resp) {
            setDetail(resp);
          },
          () => props.setErrorMessage("Failed to patch Hem: AT details")
        );

        setUnits([]);
        setUnitCount("");
        setID();
        setIsSaving(false);
        setConfirmationMessage();
        atRef.current.reset();
      } else if (id == 4) {
        let data = {
          start_time: null,
          volume_drawn: null,
          product_used_time: null,
          reinfused: null,
          prp: null,
          ppp: null,
          bmac: null,
          gel: null,
          thrombin: null,
          chloride: null,
        };
        API.query(
          "PATCH",
          `/api/hemdetails/${detail.id}/`,
          {},
          data,
          function (resp) {
            setDetail(resp);
            setID();
            setIsSaving(false);
            setConfirmationMessage();
            setPSValues({
              start_time: null,
              volume_drawn: null,
              product_used_time: null,
              reinfused: null,
              prp: null,
              ppp: null,
              bmac: null,
              gel: null,
              thrombin: null,
              chloride: null,
            });
            psRef.current.reset();
          },
          () => props.setErrorMessage("Failed clearing plasma values.")
        );
      } else if (id == 5) {
        let data = {
          patient_id: false,
          consent: false,
          anes_consult: false,
          tourniquet: false,
          iv_drip: false,
          confirm_patient_id: false,
        };
        API.query(
          "PATCH",
          `/api/hemdetails/${detail.id}/`,
          {},
          data,
          function (resp) {
            setDetail(resp);
            setID();
            setIsSaving(false);
            setConfirmationMessage();
            setPSChecklistValues({
              patient_id: false,
              consent: false,
              anes_consult: false,
              tourniquet: false,
              iv_drip: false,
              confirm_patient_id: false,
            });
            checklistRef.current.reset();
          },
          () => props.setErrorMessage("Failed clearing checklist values.")
        );
      } else if (id == 6) {
        let data = {
          base_hct: null,
          base_plt: null,
        };
        API.query(
          "PATCH",
          `/api/hemdetails/${detail.id}/`,
          {},
          data,
          function (resp) {
            setDetail(resp);
            setID();
            setIsSaving(false);
            setConfirmationMessage();
            setLabValues({ base_plt: null, base_hct: null });
            labRef.current.reset();
          },
          () => props.setErrorMessage("Failed clearing lab values.")
        );
      } else if (id == 7) {
        let data = {
          draw_time: null,
          qc_hct: null,
          k_plus: null,
          at_specialist: false,
          pt_plt: null,
          pt_hct: null,
          prp_plt: null,
          prp_hct: null,
          prp_specialist: false,
        };
        API.query(
          "PATCH",
          `/api/hemdetails/${detail.id}/`,
          {},
          data,
          function (resp) {
            setDetail(resp);
            setID();
            setConfirmationMessage();
            setIsSaving(false);
            setATQCValues({
              draw_time: null,
              qc_hct: null,
              k_plus: null,
              at_specialist: false,
            });
            setPRPQCValues({
              pt_plt: null,
              pt_hct: null,
              prp_plt: null,
              prp_hct: null,
              prp_specialist: false,
            });
            atQCRef.current.reset();
            prpQCRef.current.reset();
          },
          () => props.setErrorMessage("Failed clearing QC Values.")
        );
      } else if (id == 8) {
        let data = {
          at_mach_type: null,
          at_serial: null,
          reservoir: null,
          suct_line: null,
          wash_set: null,
          ps_mach_type: null,
          ps_serial: null,
          ps_set: null,
          application_tip: null,
          ratio_applicators: null,
        };
        API.query(
          "PATCH",
          `/api/hemdetails/${detail.id}/`,
          {},
          data,
          function (resp) {
            setDetail(resp);
            setID();
            setConfirmationMessage();
            setIsSaving(false);
            setATSerialValues({
              at_mach_type: null,
              at_serial: null,
              reservoir: null,
              suct_line: null,
              wash_set: null,
            });
            setPSSerialValues({
              ps_mach_type: null,
              ps_serial: null,
              ps_set: null,
              application_tip: null,
              ratio_applicators: null,
            });
            atSerialRef.current.reset();
            psSerialRef.current.reset();
          },
          () => props.setErrorMessage("Failed clearing QC Values.")
        );
      } else if (id == 9) {
        let data = {
          note: null,
        };
        API.query(
          "PATCH",
          `/api/hemdetails/${detail.id}/`,
          {},
          data,
          function (resp) {
            setDetail(resp);
            setID();
            setConfirmationMessage();
            setIsSaving(false);
            setNoteValues({ note: "" });
            noteRef.current.reset();
          },
          () => props.setErrorMessage("Failed clearing Note Values.")
        );
      }
    }
  };

  const onPlasmaSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        start_time: psValues["start_time"],
        volume_drawn: psValues["volume_drawn"],
        product_used_time: psValues["product_used_time"],
        reinfused: psValues["reinfused"],
        prp: psValues["prp"],
        ppp: psValues["ppp"],
        bmac: psValues["bmac"],
        gel: psValues["gel"],
        thrombin: psValues["thrombin"],
        chloride: psValues["chloride"],
      };
      API.query(
        "PATCH",
        `/api/hemdetails/${detail.id}/`,
        {},
        data,
        function (resp) {
          setDetail(resp);
          setIsSaving(false);
        },
        () => props.setErrorMessage("Failed saving plasma values.")
      );
    }
  };

  const parseDate = (value, formatString) => {
    if (Array.isArray(value) || !value) return null;
    try {
      return parse(value, formatString, new Date());
    } catch (e) {
      return null;
    }
  };

  const formatDate = (value, formatString) => {
    if (Array.isArray(value) || !value) return null;
    try {
      return format(value, formatString);
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    API.query(
      "GET",
      `/api/hemdetails/${props.detailID}/`,
      {},
      {},
      function (resp) {
        setDetail(resp);
        setTopValues({
          case_date: resp.case_date,
          or_number: resp.or_number,
          sequestrian_start: resp.sequestrian_start,
          scheduled_start: resp.scheduled_start,
          or_time_in: resp.or_time_in,
          or_time_out: resp.or_time_out,
          post_time_out: resp.post_time_out,
          total_hours: resp.total_hours,
          case_type: resp.case_type,
          description: resp.description,
        });
        setPSValues({
          start_time: resp.start_time,
          volume_drawn: resp.volume_drawn,
          product_used_time: resp.product_used_time,
          reinfused: resp.reinfused,
          prp: resp.prp,
          ppp: resp.ppp,
          bmac: resp.bmac,
          gel: resp.gel ? (resp.gel ? "true" : "false") : null,
          thrombin: resp.thrombin,
          chloride: resp.chloride,
        });
        setBloodValues({ prbc: resp.prbc, ffp: resp.ffp, plt: resp.plt });
        setLabValues({ base_hct: resp.base_hct, base_plt: resp.base_plt });
        setPSChecklistValues({
          patient_id: resp.patient_id,
          consent: resp.consent,
          anes_consult: resp.anes_consult,
          tourniquet: resp.tourniquet,
          iv_drip: resp.iv_drip,
          confirm_patient_id: resp.confirm_patient_id,
        });
        setATQCValues({
          draw_time: resp.draw_time,
          qc_hct: resp.qc_hct,
          k_plus: resp.k_plus,
          at_specialist: resp.at_specialist,
        });
        setPRPQCValues({
          pt_plt: resp.pt_plt,
          pt_hct: resp.pt_hct,
          prp_plt: resp.prp_plt,
          prp_hct: resp.prp_hct,
          prp_specialist: resp.prp_specialist,
        });
        setATSerialValues({
          at_mach_type: resp.at_mach_type,
          at_serial: resp.at_serial,
          reservoir: resp.reservoir,
          suct_line: resp.suct_line,
          wash_set: resp.wash_set,
        });
        setPSSerialValues({
          ps_mach_type: resp.ps_mach_type,
          ps_serial: resp.ps_serial,
          ps_set: resp.ps_set,
          application_tip: resp.application_tip,
          ratio_applicators: resp.ratio_applicators,
        });
        setNoteValues({ note: resp.note });
        if (resp.units.length > 0) {
          setUnitCount(resp.unit_count ?? "");
          let params = {
            units: [...resp.units],
          };
          API.query(
            "GET",
            `/api/hemunits/`,
            params,
            {},
            function (resp) {
              var newUnits = resp,
                i,
                newArr = [];
              for (i = 0; i < newUnits.length; i++) {
                newArr.push({
                  label: `Unit #${i + 1}`,
                  id: newUnits[i].id,
                  rowID: i,
                  start_time: newUnits[i].start_time,
                  processed_time: newUnits[i].processed_time,
                  reinfused_time: newUnits[i].reinfused_time,
                  ebl: newUnits[i].ebl,
                  amnt_returned: newUnits[i].amnt_returned,
                  wash_volume: newUnits[i].wash_volume,
                  line_clear: newUnits[i].line_clear,
                });
              }
              setUnits(newArr);
            },
            () => props.setErrorMessage("Failed loading hemasource units.")
          );
        } else {
          setUnits([]);
          setUnitCount("");
        }
        API.query(
          "GET",
          `/api/hemcasetypes/`,
          {},
          {},
          function (resp) {
            setCaseTypes(resp.map((i) => ({ label: i.type, value: i.id })));
          },
          () => props.setErrorMessage("Failed loading case types.")
        );
      },
      () => props.setErrorMessage("Failed loading hemasource detail.")
    );
  }, []);

  const onCountChange = (value) => {
    setUnitCount(value);
  };

  const onStartChange = (rowID, value) => {
    var newArr = [...units];
    let changedIndex = newArr.findIndex((e) => rowID == e.rowID);
    let changed = newArr.find((e) => rowID == e.rowID);
    changed["start_time"] = value;
    newArr[changedIndex] = changed;
    setUnits(newArr);
  };

  const onProcessedChange = (rowID, value) => {
    var newArr = [...units];
    let changedIndex = newArr.findIndex((e) => rowID == e.rowID);
    let changed = newArr.find((e) => rowID == e.rowID);
    changed["processed_time"] = value;
    newArr[changedIndex] = changed;
    setUnits(newArr);
  };

  const onReinfusedChange = (rowID, value) => {
    var newArr = [...units];
    let changedIndex = newArr.findIndex((e) => rowID == e.rowID);
    let changed = newArr.find((e) => rowID == e.rowID);
    changed["reinfused_time"] = value;
    newArr[changedIndex] = changed;
    setUnits(newArr);
  };

  const onEBLChange = (rowID, value) => {
    var newArr = [...units];
    let changedIndex = newArr.findIndex((e) => rowID == e.rowID);
    let changed = newArr.find((e) => rowID == e.rowID);
    changed["ebl"] = value;
    newArr[changedIndex] = changed;
    setUnits(newArr);
  };

  const onAmntChange = (rowID, value) => {
    var newArr = [...units];
    let changedIndex = newArr.findIndex((e) => rowID == e.rowID);
    let changed = newArr.find((e) => rowID == e.rowID);
    changed["amnt_returned"] = value;
    newArr[changedIndex] = changed;
    setUnits(newArr);
  };

  const onWashChange = (rowID, value) => {
    var newArr = [...units];
    let changedIndex = newArr.findIndex((e) => rowID == e.rowID);
    let changed = newArr.find((e) => rowID == e.rowID);
    changed["wash_volume"] = value;
    newArr[changedIndex] = changed;
    setUnits(newArr);
  };

  const onLineChange = (rowID, value) => {
    var newArr = [...units];
    let changedIndex = newArr.findIndex((e) => rowID == e.rowID);
    let changed = newArr.find((e) => rowID == e.rowID);
    changed["line_clear"] = value;
    newArr[changedIndex] = changed;
    setUnits(newArr);
  };

  const onUnitDeleteClicked = (row) => {
    if (row.id) {
      if (!isSaving) {
        setIsSaving(true);
        let filteredUnits = [...units].filter((j) => j.id !== row.id);
        let newIds = filteredUnits.map((i) => {
          if (i.id && i.id != null) {
            return i.id;
          }
        });
        let newUnits = filteredUnits.map((e, idx) => {
          e.rowID = idx;
          e.label = `#${idx + 1}`;
          return e;
        });
        setUnits(newUnits);
        API.query(
          "DELETE",
          `/api/hemunits/${row.id}/`,
          {},
          {},
          function (resp) {
            let data = {
              units: newIds,
            };
            API.query(
              "PATCH",
              `/api/hemdetails/${detail.id}/`,
              {},
              data,
              function (resp) {
                setDetail(resp);
                setIsSaving(false);
              },
              () => props.setErrorMessage("Failed attaching units to case.")
            );
          },
          () => props.setErrorMessage("Failed deleting unit.")
        );
      }
    } else {
      let filteredUnits = units.filter((j) => j.rowID !== row.rowID);
      let newUnits = filteredUnits.map((e, idx) => {
        e.rowID = idx;
        e.label = `#${idx + 1}`;
        return e;
      });
      setUnits(newUnits);
    }
  };

  const columns = useMemo(() => [
    {
      name: "",
      selector: (row) => (
        <label className="has-text-weight-semibold">{row.label}</label>
      ),
    },
    {
      name: "Start Time",
      selector: (row) => (
        <div>
          <DatePicker
            className="input"
            showTimeSelectOnly={true}
            timeCaption="Time"
            dateFormat={formatString}
            selected={parseDate(row.start_time, formatString)}
            onChange={(date) =>
              onStartChange(row.rowID, formatDate(date, formatString))
            }
          />
        </div>
      ),
    },
    {
      name: "Processed Time",
      selector: (row) => (
        <div>
          <DatePicker
            className="input"
            showTimeSelectOnly={true}
            timeCaption="Time"
            dateFormat={formatString}
            selected={parseDate(row.processed_time, formatString)}
            onChange={(date) =>
              onProcessedChange(row.rowID, formatDate(date, formatString))
            }
          />
        </div>
      ),
    },
    {
      name: "Reinfused Time",
      selector: (row) => (
        <div>
          <DatePicker
            className="input"
            showTimeSelectOnly={true}
            timeCaption="Time"
            dateFormat={formatString}
            selected={parseDate(row.reinfused_time, formatString)}
            onChange={(date) =>
              onReinfusedChange(row.rowID, formatDate(date, formatString))
            }
          />
        </div>
      ),
    },
    {
      name: "EBL",
      selector: (row) => (
        <div>
          <input
            defaultValue={row.ebl}
            placeholder={row.ebl}
            className="input"
            type="number"
            onChange={(e) => onEBLChange(row.rowID, e.target.value)}
          />
        </div>
      ),
    },
    {
      name: "Amount Returned",
      selector: (row) => (
        <div>
          <input
            defaultValue={row.amnt_returned}
            placeholder={row.amnt_returned}
            className="input"
            type="number"
            onChange={(e) => onAmntChange(row.rowID, e.target.value)}
          />
        </div>
      ),
    },
    {
      name: "Wash Volume",
      selector: (row) => (
        <div>
          <input
            defaultValue={row.wash_volume}
            placeholder={row.wash_volume}
            className="input"
            type="number"
            onChange={(e) => onWashChange(row.rowID, e.target.value)}
          />
        </div>
      ),
    },
    {
      name: "Line Clear?",
      selector: (row) => (
        <div>
          <BooleanRadioGroup
            value={row.line_clear}
            setValue={(value) => onLineChange(row.rowID, value)}
          />
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <a
            className="ml-3"
            title="Delete"
            onClick={() => onUnitDeleteClicked(row)}
          >
            <FaTrashAlt size={14} />
          </a>
        </div>
      ),
    },
  ]);

  const getEBL = () => {
    var total = 0,
      i;
    for (i = 0; i < units.length; i++) {
      if (units[i].ebl != null) {
        total += parseInt(units[i].ebl);
      }
    }
    return total;
  };

  const getReturned = () => {
    var total = 0,
      i;
    for (i = 0; i < units.length; i++) {
      if (units[i].amnt_returned != null) {
        total += parseInt(units[i].amnt_returned);
      }
    }
    return total;
  };

  const onPlasmaClear = () => {
    setID(4);
    setConfirmationMessage(
      "You are about to clear Plasma Values, please confirm."
    );
  };

  const onPSChecklistClear = () => {
    setID(5);
    setConfirmationMessage(
      "You are about to clear PS Checklist Values, please confirm."
    );
  };

  const onPSChecklistSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        patient_id: psChecklistValues["patient_id"],
        consent: psChecklistValues["consent"],
        anes_consult: psChecklistValues["anes_consult"],
        tourniquet: psChecklistValues["tourniquet"],
        iv_drip: psChecklistValues["iv_drip"],
        confirm_patient_id: psChecklistValues["confirm_patient_id"],
      };
      API.query(
        "PATCH",
        `/api/hemdetails/${detail.id}/`,
        {},
        data,
        function (resp) {
          setDetail(resp);
          setIsSaving(false);
        },
        () => props.setErrorMessage("Failed saving checklist values.")
      );
    }
  };

  const onLabClear = () => {
    setID(6);
    setConfirmationMessage(
      "You are about to clear Lab Values, please confirm."
    );
  };

  const onLabSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        base_hct: labValues["base_hct"],
        base_plt: labValues["base_plt"],
      };
      API.query(
        "PATCH",
        `/api/hemdetails/${detail.id}/`,
        {},
        data,
        function (resp) {
          setDetail(resp);
          setIsSaving(false);
        },
        () => props.setErrorMessage("Failed saving checklist values.")
      );
    }
  };

  const onQCClear = () => {
    setID(7);
    setConfirmationMessage("You are about to clear QC Values, please confirm.");
  };

  const onQCSave = () => {
    if (!isSaving) {
      if (
        atQCValues["qc_hct"] &&
        atQCValues["qc_hct"] < 40 &&
        !atQCValues["at_specialist"]
      ) {
        props.setErrorMessage(
          "Hct. is out of range, please correct or notify a specialist."
        );
        return;
      }
      if (atQCValues["qc_hct"] && atQCValues["qc_hct"] > 100) {
        props.setErrorMessage("Hct. must be less than 100.");
        return;
      }
      if (
        atQCValues["k_plus"] &&
        atQCValues["k_plus"] >= 5 &&
        !atQCValues["at_specialist"]
      ) {
        props.setErrorMessage(
          "K+ is out of range, please correct or notify a specialist."
        );
        return;
      }
      if (prpQCValues["pt_hct"] && prpQCValues["pt_hct"] > 100) {
        props.setErrorMessage("Pt. Draw Hct. must be less than 100.");
        return;
      }
      if (prpQCValues["prp_hct"] && prpQCValues["prp_hct"] > 100) {
        props.setErrorMessage("PRP Hct. must be less than 100.");
        return;
      }
      if (
        prpQCValues["pt_plt"] &&
        prpQCValues["prp_plt"] &&
        prpQCValues["pt_plt"] > prpQCValues["prp_plt"] &&
        !prpQCValues["prp_specialist"]
      ) {
        props.setErrorMessage(
          "Pt. Draw Plt. must be less than PRP Plt. Please correct or notify a specialist."
        );
        return;
      }
      setIsSaving(true);
      let data = {
        draw_time: atQCValues["draw_time"],
        qc_hct: atQCValues["qc_hct"],
        k_plus: atQCValues["k_plus"],
        at_specialist: atQCValues["at_specialist"],
        pt_plt: prpQCValues["pt_plt"],
        pt_hct: prpQCValues["pt_hct"],
        prp_plt: prpQCValues["prp_plt"],
        prp_hct: prpQCValues["prp_hct"],
        prp_specialist: prpQCValues["prp_specialist"],
      };
      API.query(
        "PATCH",
        `/api/hemdetails/${detail.id}/`,
        {},
        data,
        function (resp) {
          setDetail(resp);
          setIsSaving(false);
        },
        () => props.setErrorMessage("Failed saving QC Values.")
      );
    }
  };

  const onSerialClear = () => {
    setID(8);
    setConfirmationMessage(
      "You are about to clear Lot/Serial Number Values, please confirm."
    );
  };

  const onSerialSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        at_mach_type: atSerialValues["at_mach_type"],
        at_serial: atSerialValues["at_serial"],
        reservoir: atSerialValues["reservoir"],
        suct_line: atSerialValues["suct_line"],
        wash_set: atSerialValues["wash_set"],
        ps_mach_type: psSerialValues["ps_mach_type"],
        ps_serial: psSerialValues["ps_serial"],
        ps_set: psSerialValues["ps_set"],
        application_tip: psSerialValues["application_tip"],
        ratio_applicators: psSerialValues["ratio_applicators"],
      };
      API.query(
        "PATCH",
        `/api/hemdetails/${detail.id}/`,
        {},
        data,
        function (resp) {
          setDetail(resp);
          setIsSaving(false);
        },
        () => props.setErrorMessage("Failed saving Lot/Serial Number Values.")
      );
    }
  };

  const onNoteClear = () => {
    setID(9);
    setConfirmationMessage(
      "You are about to clear Note Values, please confirm."
    );
  };

  const onNoteSave = () => {
    if (!isSaving) {
      setIsSaving(true);
      let data = {
        note: noteValues["note"],
      };
      API.query(
        "PATCH",
        `/api/hemdetails/${detail.id}/`,
        {},
        data,
        function (resp) {
          setDetail(resp);
          setIsSaving(false);
        },
        () => props.setErrorMessage("Failed saving Lot/Serial Number Values.")
      );
    }
  };

  const addUnit = () => {
    const newUnits = [...units];
    newUnits.push({
      label: `Unit #${newUnits.length + 1}`,
      rowID: newUnits.length + 1,
      start_time: null,
      processed_time: null,
      reinfused_time: null,
      ebl: null,
      amnt_returned: null,
      wash_volume: null,
      line_clear: null,
    });
    setUnits(newUnits);
  };

  return (
    <div>
      <form ref={topRef}>
        <DynamicForm
          key={1}
          form={HemTop}
          onValuesChanged={onTopChanged}
          values={topValues}
          source={{ types: caseTypes }}
        />
      </form>

      <br />

      <hr />

      <div className="level w-100">
        <div className="level-left">
          <button className="button is-danger is-inverted" onClick={onTopClear}>
            Clear
          </button>
        </div>
        <div className="level-right">
          <button className={saveBtnClasses} onClick={onTopSave}>
            Save
          </button>
        </div>
      </div>

      <Accordion allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>PS Pre-Op Checklist</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <form ref={checklistRef}>
              <DynamicForm
                key={4}
                form={HemChecklist}
                onValuesChanged={onChecklistChanged}
                values={psChecklistValues}
              />
            </form>

            <br />

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onPSChecklistClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button className={saveBtnClasses} onClick={onPSChecklistSave}>
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Pre-Op Patient Labs</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <form ref={labRef}>
              <DynamicForm
                key={5}
                form={HemLab}
                onValuesChanged={onLabChanged}
                values={labValues}
              />
            </form>

            <br />

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onLabClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button className={saveBtnClasses} onClick={onLabSave}>
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Blood Bank Products</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <form ref={bloodRef}>
              <DynamicForm
                key={2}
                form={HemBlood}
                onValuesChanged={onBloodChanged}
                values={bloodValues}
              />
            </form>

            <br />

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onBloodClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button className={saveBtnClasses} onClick={onBloodSave}>
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Autotransfusion collection and reinfusion data
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="level w-100">
              <div className="level-left">
                <label className="label">Number of Units</label>
                <div style={{ marginLeft: "8px" }} className="control">
                  <form ref={atRef}>
                    <input
                      className="input"
                      type="text"
                      defaultValue={unitCount}
                      onChange={(e) => onCountChange(e.target.value)}
                    />
                  </form>
                </div>
              </div>
              <div className="level-right">
                <button
                  style={{ backgroundColor: "#1C2944", color: "#FFFFFF" }}
                  onClick={addUnit}
                  className="button"
                >
                  Add Unit
                </button>
              </div>
            </div>

            <form ref={atRef}>
              <DataTable striped columns={columns} data={units} />
            </form>

            <br />

            <div className="level w-100">
              <div className="level-left"></div>
              <div className="level-right">
                <p>Total Estimated Blood Loss to Cell Saver: {getEBL()}</p>
              </div>
            </div>

            <div className="level w-100">
              <div className="level-left"></div>
              <div className="level-right">
                <p>Total Cell Saver Blood Returned: {getReturned()}</p>
              </div>
            </div>

            <br />

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onATClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button className={saveBtnClasses} onClick={onATSave}>
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Plasma Sequestration</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <form ref={psRef}>
              <DynamicForm
                key={3}
                form={HemPSForm}
                onValuesChanged={onPSChanged}
                values={psValues}
              />
            </form>

            <br />

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onPlasmaClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button className={saveBtnClasses} onClick={onPlasmaSave}>
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Quality Control</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="title is-3">AT</p>

            <hr />

            <form ref={atQCRef}>
              <DynamicForm
                key={6}
                form={HemATQC}
                onValuesChanged={onATQCChanged}
                values={atQCValues}
              />
            </form>

            <br />

            <hr />

            <p className="title is-3">PRP</p>

            <hr />

            <form ref={prpQCRef}>
              <DynamicForm
                key={7}
                form={HemPRPQC}
                onValuesChanged={onPRPQCChanged}
                values={prpQCValues}
              />
            </form>

            <br />

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onQCClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button className={saveBtnClasses} onClick={onQCSave}>
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Lot/Serial Numbers</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="title is-3">AT</p>

            <hr />

            <form ref={atSerialRef}>
              <DynamicForm
                key={8}
                form={HemATSerial}
                onValuesChanged={onATSerialChanged}
                values={atSerialValues}
              />
            </form>

            <br />

            <hr />

            <p className="title is-3">PS</p>

            <hr />

            <form ref={psSerialRef}>
              <DynamicForm
                key={9}
                form={HemPSSerial}
                onValuesChanged={onPSSerialChanged}
                values={psSerialValues}
              />
            </form>

            <br />

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onSerialClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button className={saveBtnClasses} onClick={onSerialSave}>
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Notes</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <form ref={noteRef}>
              <DynamicForm
                key={3}
                form={AutoNote}
                onValuesChanged={onNoteChanged}
                values={noteValues}
              />
            </form>

            <br />

            <hr />

            <div className="level w-100">
              <div className="level-left">
                <button
                  className="button is-danger is-inverted"
                  onClick={onNoteClear}
                >
                  Clear
                </button>
              </div>
              <div className="level-right">
                <button className={saveBtnClasses} onClick={onNoteSave}>
                  Save
                </button>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>

      {confirmationMessage && (
        <ConfirmationModal
          message={confirmationMessage}
          onConfirm={onConfirm}
          onClose={() => setConfirmationMessage()}
        />
      )}
    </div>
  );
}

export default Hem;
