import React, { useEffect, useState } from "react";
import chartingPrePumpChecklist from  "../forms/chartingPrePumpChecklist"
import DynamicForm from "./DynamicForm";

function ChartingPrePumpModal(props) {
  const [data,setData] = useState({"hep-admin":false,"safety-devices-engaged":false,"arterial-line-check":false})
  const [missingFields,setMissingFields] = useState()

  const form = chartingPrePumpChecklist

  const onSave = () => {
    let missing = []
    
    form.fields.forEach(f => {
      if(f.required && f.required == "true") {
        if(!data[f.id]) missing.push(f.id)
      }
    })

    if(missing.length > 0) {
      setMissingFields(missing)
    }else {
      setMissingFields([])
      props.onSave(data)
    }
  }

  const onCancel = () => {
    props.onClose()
  }

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head has-background-white">
        <p className="is-size-4">Pre-Bypass Checklist</p>
      </header>

      <section className="modal-card-body">
        <DynamicForm 
          form={form}
          values={data}
          onValuesChanged={setData}
          missingFields={missingFields}
        />
      </section>

      <footer className="modal-card-foot has-background-white">
        <div className="level w-100">
          <div className="level-left">
            <button className="level-item button is-danger is-inverted" onClick={onCancel}>Cancel</button>
          </div>
          <div className="level-right">
            <button className="level-item button is-success" onClick={onSave}>Save</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
}

export default ChartingPrePumpModal