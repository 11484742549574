const dynamicStorage = localStorage

class Session {
  static keys = ['user'] // which keys will be saved in the session

  static get(name){
      let item = dynamicStorage.getItem(name)
      if(item){
          return JSON.parse(dynamicStorage.getItem(name))
      }
      return null
  }

  static has(name){
      return dynamicStorage.getItem(name) !== null
  }
  
  static set(name,value){
      dynamicStorage.setItem(name, JSON.stringify(value))
  }

  static delete(name){
      dynamicStorage.removeItem(name)
  }

  static isValid(){
      // Make sure session has all the keys we need
      let isValid = true
      let ref = this
      this.keys.forEach(key => {
          if(!ref.has(key)){
              isValid = false
              return
          }
      })
      return isValid
  }
  
  static saveSession(session){
      let ref = this
      this.keys.forEach(key => {
          ref.set(key,session[key])
      });
  }

  // static updateSession(callback,errorCallback){
  //     const ref = this
  //     API.query("GET","session",{},{},function(resp){
  //         ref.saveSession(resp)
  //         if(callback) callback(resp)
  //     },function(){
  //         if(errorCallback) errorCallback()
  //     })
  // }
  
  static logout(){
      const ref = this
      this.keys.forEach(key => {
          ref.delete(key)
      });
      window.location = '/logout'
  }
}

export default Session;