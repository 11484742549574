import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import DatePicker from "react-datepicker";
import API from "../../services/API";
import { dateToDateOnlyString } from "../helpers/date";

export const PdfBloodProductDetailsMockData = (
  numEntries = 3,
  numPatients = 2,
  numProducts = 3
) => {
  let i;
  const products = [];
  for (i = 0; i < numProducts; i++) {
    products.push({
      name: "PRBC",
      bypass: 1,
      prebypass: 2,
      postbypass: 1,
      total: 4,
    });
  }
  const patients = [];
  for (i = 0; i < numPatients; i++) {
    patients.push({
      name: "Benson, Ben",
      date: "11/04/2024",
      mrn: "1001",
      operation: "Elective / CABGx3",
      personnel: "Nick Dev",
      products: products,
    });
  }
  const results = [];
  for (i = 0; i < numEntries; i++) {
    results.push({
      name: "Docson, Doc",
      patients: patients,
      totals: {
        bypass: numPatients * numProducts,
        postbypass: numPatients * numProducts,
        prebypass: numPatients * numProducts * 2,
        total_total: numPatients * numProducts * 4,
        transfused_percent: "100.00%",
      },
    });
  }
  return results;
};

export const PdfBloodProductDetailsRenderer = (data, ref) => {
  console.log("data: ", data);
  const today = new Date();
  return (
    <div ref={ref} className="has-background-white">
      <div className="w-100 is-size-7">
        <p className="is-size-5">Blood Product Usage Detail</p>
        <p className="">{`(Printed ${today.toLocaleDateString()})`}</p>
        <p>
          <span className="has-text-weight-bold">{"Hospital:"}</span>
          &nbsp;{data.facility}
        </p>
        <p>
          <span className="has-text-weight-bold">Date Range:</span>&nbsp;
          {data.dateRange}
        </p>
      </div>

      {data.results.map((x, xIndex) => (
        <div key={xIndex}>
          <p className="mt-5 has-text-weight-bold">{x.name}</p>
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>MRN</th>
                <th>Patient</th>
                <th>Procedure Date</th>
                <th>Personnel</th>
                <th>Operation</th>
                <th>Product</th>
                <th>Intra Op Pre</th>
                <th>Intra Op</th>
                <th>Intra Op Post</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {x.patients.map((p, idx) =>
                p.products.map((pr, prIndex) =>
                  prIndex === 0 ? (
                    <tr key={`${idx}-${prIndex}`}>
                      <td rowSpan={p.products.length}>{p.mrn}</td>
                      <td rowSpan={p.products.length}>{p.name}</td>
                      <td rowSpan={p.products.length}>{p.date}</td>
                      <td rowSpan={p.products.length}>{p.personnel}</td>
                      <td rowSpan={p.products.length}>{p.operation}</td>
                      <td>{pr.name}</td>
                      <td>{pr.prebypass}</td>
                      <td>{pr.bypass}</td>
                      <td>{pr.postbypass}</td>
                      <td>{pr.total}</td>
                    </tr>
                  ) : (
                    <tr key={`${idx}-${prIndex}`}>
                      <td>{pr.name}</td>
                      <td>{pr.prebypass}</td>
                      <td>{pr.bypass}</td>
                      <td>{pr.postbypass}</td>
                      <td>{pr.total}</td>
                    </tr>
                  )
                )
              )}
              <tr key={x.patients.length}>
                <td
                  className="has-text-weight-bold"
                  colSpan={4}
                  style={{ borderTop: "2px solid black" }}
                >
                  {`% of patients that received blood products: ${x.totals.transfused_percent}`}
                </td>
                <td
                  className="has-text-weight-bold"
                  style={{ borderTop: "2px solid black" }}
                >
                  {`Total For ${x.name}`}
                </td>
                <td style={{ borderTop: "2px solid black" }}></td>
                <td
                  className="has-text-weight-bold"
                  style={{ borderTop: "2px solid black" }}
                >
                  {x.totals.prebypass}
                </td>
                <td
                  className="has-text-weight-bold"
                  style={{ borderTop: "2px solid black" }}
                >
                  {x.totals.bypass}
                </td>
                <td
                  className="has-text-weight-bold"
                  style={{ borderTop: "2px solid black" }}
                >
                  {x.totals.postbypass}
                </td>
                <td
                  className="has-text-weight-bold"
                  style={{ borderTop: "2px solid black" }}
                >
                  {x.totals.total_total}
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ breakAfter: "page" }} />
        </div>
      ))}
    </div>
  );
};

const PdfBloodProductDetails = (props) => {
  const [filters, setFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onFilterChanged = (value, type) => {
    let fValues = { ...filters };
    if (type === 0) {
      fValues["start_date"] = value;
    } else if (type === 1) {
      fValues["end_date"] = value;
    } else if (type === 2) {
      fValues["facility"] = value;
    }
    setFilters(fValues);
  };

  const renderData = () => {
    if (filters.facility != null && filters.start_date && filters.end_date) {
      if (!isLoading) {
        setIsLoading(true);

        const params = {
          facility_id: filters.facility,
          start_date: dateToDateOnlyString(filters.start_date),
          end_date: dateToDateOnlyString(filters.end_date),
        };

        API.getReportBundleData(
          params,
          function (resp) {
            setData({
              facility: facility ?? "",
              results: resp.blood_detail_data,
              dateRange: `${start_date} - ${end_date}`,
            });
            setIsLoading(false);
          },
          onDataLoadError
        );
      }
    }
  };

  const onDataLoadError = (error) => {
    console.log(error);
  };

  const start_date = filters.start_date
    ? new Date(filters.start_date).toLocaleDateString()
    : "";
  const end_date = filters.end_date
    ? new Date(filters.end_date).toLocaleDateString()
    : "";
  const facility =
    props.facilities && filters.facility
      ? props.facilities.find((f) => {
          return f.value == filters.facility;
        }).label ?? ""
      : "";

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card modal-extra-large">
        <header className="modal-card-head">
          <p className="modal-card-title">Blood Product Usage Detail</p>
        </header>

        <section className="modal-card-body p-0">
          <div className="p-5">
            <div className="w-100">
              <div className="columns">
                <div className="column">
                  <div style={{ midWidth: "499px", maxWidth: "500px" }}>
                    <Select
                      options={props.facilities}
                      placeholder={"All"}
                      value={props.facilities.find(
                        (e) => e.value == filters.facility
                      )}
                      onChange={(option) => onFilterChanged(option.value, 2)}
                    />
                  </div>
                </div>
                <div
                  className="column"
                  style={{ maxWidth: "200px", minWidth: "199px" }}
                >
                  <DatePicker
                    className="input"
                    selectsStart
                    startDate={filters.start_date}
                    endDate={filters.end_date}
                    selected={filters.start_date}
                    onChange={(date) => onFilterChanged(date, 0)}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Start Date"
                  />
                </div>
                <div
                  className="column"
                  style={{ maxWidth: "200px", minWidth: "199px" }}
                >
                  <DatePicker
                    className="input"
                    selectsStart
                    startDate={filters.start_date}
                    endDate={filters.end_date}
                    selected={filters.end_date}
                    onChange={(date) => onFilterChanged(date, 1)}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="End Date"
                  />
                </div>
              </div>
              <div className="level w-100">
                <div className="level-item has-text-centered">
                  <button
                    className={`button is-success ${isLoading && "is-loading"}`}
                    onClick={() => renderData()}
                    disabled={!start_date || !end_date || !facility}
                  >
                    Generate
                  </button>
                </div>
              </div>

              {!data && (
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              )}

              {data &&
                !isLoading &&
                PdfBloodProductDetailsRenderer(data, componentRef)}
            </div>
          </div>

          <button
            onClick={props.onClose}
            className="modal-close is-large"
            aria-label="close"
          ></button>
        </section>

        <footer className="modal-card-foot">
          <div className="level w-100">
            <div className="level-left">
              <button className="button is-danger" onClick={props.onClose}>
                Close
              </button>
            </div>
            <div className="level-right">
              {data && (
                <button className="button is-success" onClick={handlePrint}>
                  Print
                </button>
              )}
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default PdfBloodProductDetails;
