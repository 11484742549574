import React, { useEffect, useMemo, useState, useRef } from "react";
import API from "../../../../services/API";
import { FaPencilAlt } from "react-icons/fa";
import DataTableMemo from "../../../components/DataTableMemo";
import DynamicForm from "../../../components/DynamicForm";
import CaseModal from "../../../components/CaseModal";
import caseSearchForm from  "../../../forms/caseSearch";
import VisitTimer from "../../../components/VisitTimer";
import ListModal from "../../../components/ListModal";
import Session from "../../../../services/Session"
import { useSearchParams } from "react-router-dom";

function Create(props){
  const formRef = useRef()
  const [searchValues,setSearchValues] = useState({})
  const [results,setResults] = useState([])
  const [isLoadingCases,setIsLoadingCases] = useState(true)
  const [caseModal,setCaseModal] = useState({isOpen:false})
  const [procedureOptions,setProcedureOptions] = useState()
  const [allergyOptions,setAllergyOptions] = useState()
  const [historyOptions,setHistoryOptions] = useState()
  const [list, setList] = useState()
  const [procedures, setProcedures] = useState([])
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortParams, setSortParams] = useState()
  const [totalCases, setTotalCases] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const onSearchChanged = (values) => {
    setSearchValues(values)
  }

  const onFacilitySearch = (inputValue,callback) => {
    if(inputValue.length >= 3) {
      API.query('GET','/api/facilityoptions/',{query:inputValue},null,(results) => {
        callback(results.map((i) => ({"label": i.name, "value": i.id})))
      },() => {
        callback([])
      })
    }
  }

  useEffect(() => {
    if(searchParams.get('quick')) {
      // API.query("POST","/api/cases/",{},{},(resp) => {
      //   setActiveCase(resp)
      // }, setError)
    }else {
      // Load option data
      let queries = [
        { 'method': 'GET', 'path': '/api/procedures/'},
        { 'method': 'GET', 'path': '/api/allergies/'},
        { 'method': 'GET', 'path': '/api/patienthistory/'}
      ]
      API.all(queries, function (resp) {
        setProcedureOptions(resp[0].map(i => ({"label": i.name, "value": i.id})))
        setAllergyOptions(resp[1].map(i => ({"label": i.name, "value": i.id})))
        setHistoryOptions(resp[2].map(i => ({"label": i.name, "value": i.id})))
        setProcedures(resp[0])
      }, onDataLoadError)

      // Load initial results
      performSearch()
    }
  }, []);

  // listen for current page change and reload
  useEffect(() => {
    if(!isLoadingCases) performSearch()
  }, [currentPage]);

  // this is a bit hacky denying totalCases in the dependency array, but we don't want it to run first render
  useEffect(() => {
    if(totalCases && searchValues && Object.keys(searchValues).length === 0) {
      performSearch()
    }
  },[searchValues,sortParams])

  const performSearch = () => {
    setIsLoadingCases(true)
    var user = Session.get('user')
    var valuesCopy = {...searchValues}

    // create params out of search values that are not null/empty
    const params = {limit:10,offset:(currentPage-1)*10}
    // need to strip facility_id from select object if set
    if(valuesCopy && valuesCopy.facility) {
      params.facility = valuesCopy.facility.value
      delete valuesCopy.facility
    }
    if(searchValues){
      Object.keys(valuesCopy).forEach(key => {
        const value = valuesCopy[key]
        if(value) params[key] = value
      })
    }
    if(params.date) {
      const d = params.date.split('/')
      params.date = d[2] + '-' + d[0] + '-' + d[1]
    }
    if(sortParams) {
      params.order_by = sortParams.column
      params.order_dir = sortParams.direction
    }

    API.query('GET','/api/cases/',params,{},(resp) => {
      setTotalCases(resp.count)
      setResults(resp.results)
    }, onDataLoadError, () => setIsLoadingCases(false))
  }

  const onDataLoadError = (error) => {
    props.setErrorMessage(error)
  }

  const onSelectCase = (id) => {
    if(isLoadingCases) return    

    if(id === null){
      props.setActiveCase(null)
      return
    }

    // load the case
    API.query('GET','/api/cases/'+id+'/',{},{},(resp) => {
      props.setActiveCase(resp)
    },(err) => {
      props.setErrorMessage(err)
    })
  }

  const onCaseCreated = (newCase) => {
    props.setActiveCase(newCase)
    props.onNext(true)
  }

  const onCaseUpdated = (updatedCase) => {
    // update active case if applicable
    if(props.activeCase) {
      if(updatedCase.id === props.activeCase.id) props.setActiveCase(updatedCase)
    }
    
    // refresh data
    performSearch(searchValues)
  }

  const onEdit = (caseId) => {
    setCaseModal({
      isOpen: true,
      caseId
    })
  }

  const procedureListModal = (procs) => {
    var procNames = procs.map((obj) => {return {label: obj.name, value: obj.id}})
    setList(procNames)
  }

  const formatDate = (date) => {
    let dateArr = date.split("/")
    return `${dateArr[2]}/${dateArr[0]}/${dateArr[1]}`
  }

  const columns = useMemo(() => [
    {name:'Patient Name',selector: row => row.patient_name,sortable:true, sortField:"patient_name", sortOut:"patient__last_name|patient__first_name"},
    {name:'Hospital',selector: row => row.facility_name,sortable:true, sortField:"facility_name", sortOut:"facility__name"},
    {name:'Procedure',selector: row => <div>
      {
        (row.procedure.length > 1)
        ?
        <a onClick={() => procedureListModal(row.procedure)}>
          {row.procedure.length} Procedures
        </a>
        :
        row.procedure.length > 0 ? row.procedure[0].name : null
      }
    </div>},
    {name:'Medical Record No.',selector: row => row.medical_record_num},
    {name:'Account No.',selector: row => row.account_num},
    {name:'Date',selector: row => formatDate(row.date),sortable:true, sortField:"date", sortOut:"date"},
    {name:'Status',width:"80px",selector: row => row.status,sortable:true, sortField:"status", sortOut:"status"},
    {name:'',width:"80px",selector: row => <a onClick={() => onEdit(row.id)}><FaPencilAlt /></a>}
  ])

  const visited = () => {
    // get pageviewhistory id from user session data
    let user = Session.get('user')
    API.query('GET',`/api/pageviewhistory/${user.page_view_history}/`,{},{},function(response) {
      let data = {
        "case_create": response.case_create + 1
      }
      API.query('PATCH',`/api/pageviewhistory/${user.page_view_history}/`,{},data,function(resp) {}, onDataLoadError)
    }, onDataLoadError)
  }

  const onListClosed = () => {
    setList()
  }

  const clearSearch = () => {
    setSearchValues({})
    formRef.current.reset()
  }

  return <div>
    <p className="title">Create New Case</p>
    <button 
      className="button is-success is-large" 
      onClick={() => {
        props.setActiveCase(null)
        setCaseModal({isOpen: true})
      }}
    >
      Create New
    </button>

    <hr/>

    <p className="title">Search Cases</p>

    <form ref={formRef}>
      <DynamicForm
        isLoading={isLoadingCases}
        form={caseSearchForm}
        values={searchValues}
        onValuesChanged={onSearchChanged}
        source={{
          "procedures": procedureOptions
        }}
        loaders={{"facility":onFacilitySearch}}
      />
    </form>

    <br />

    <div className="level w-100">
      <div className="level-left">
        <button className="button is-orange" onClick={performSearch}>Search</button>
        <button className="button is-danger" style={{marginLeft: "12px"}} onClick={clearSearch}>Clear Search</button>
      </div>
    </div>

    <hr/>

    <p className="title is-5">Results</p>

    <DataTableMemo
      striped
      pagination
      paginationServer
      paginationTotalRows={totalCases}
      paginationComponentOptions={{noRowsPerPage:true}}
      progressPending={isLoadingCases}
      onChangePage={setCurrentPage}
      sortServer
      onSort={(column, sortDirection) => {
        setSortParams({column:column.sortOut,direction:sortDirection})
      }}
      selectableRows
      selectableRowsSingle
      selectableRowsHighlight
      onSelectedRowsChange={({selectedRows}) => {
        //console.log(selectedRows)
        onSelectCase(selectedRows.length == 1 ? selectedRows[0].id : null)
      }}
      selectableRowSelected={(row) => row.id === props.activeCaseId}
      columns={columns}
      data={results}
      disabled={isLoadingCases}
    />

    {
      list
      ?
      <ListModal 
        string={'Procedures'}
        list={list}
        onClose={onListClosed}
      />
      :
      null
    }

    {
      caseModal.isOpen
      &&
      <CaseModal
        caseId={caseModal.caseId}
        allergyOptions={allergyOptions}
        historyOptions={historyOptions}
        onCaseCreated={(newCase) => onCaseCreated(newCase)}
        onCaseUpdated={(updatedCase) => onCaseUpdated(updatedCase)}
        onClose={() => setCaseModal({isOpen:false})}
        setErrorMessage={props.setErrorMessage}
        loaders={{"facility_id":onFacilitySearch}}
      />
    }

    <VisitTimer visited={visited}/>
  </div>
}

export default Create