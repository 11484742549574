import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import DatePicker from "react-datepicker";
import API from "../../services/API";
import { dateToDateOnlyString } from "../helpers/date";

export const PdfNonComplianceMockData = (numItems = 3, numPatients = 3) => {
  const entry = {
    hospital_name: "Albany Medical Center",
    patient: "Benson, Ben",
    date: "11/04/2024",
    user: "Johnson, John",
    surgeon: "Thompson, Tom",
    notes: "Baseline base excess was -5.3",
  };
  let i;
  const entries = [];
  for (i = 0; i < numPatients; i++) {
    entries.push(entry);
  }
  const results = [];
  for (i = 0; i < numItems; i++) {
    results.push({
      procedure: "CPB",
      item_name:
        "Arterial base excess -2.5 - +2.5 mmol/L prior to termination of CPB",
      data: entries,
    });
  }
  return results;
};

export const PdfNonComplianceRenderer = (data, ref) => {
  console.log("data: ", data);
  const today = new Date();
  return (
    <div ref={ref} className="has-background-white">
      <div className="w-100 is-size-7">
        <p className="is-size-5">
          Non-Compliance Report Continuous Quality Improvement
        </p>
        <p className="">{`(Printed ${today.toLocaleDateString()})`}</p>
        <p>
          <span className="has-text-weight-bold">{"Hospital:"}</span>
          &nbsp;{data.facility}
        </p>
        <p>
          <span className="has-text-weight-bold">Date Range:</span>&nbsp;
          {data.dateRange}
        </p>
      </div>

      {data.results.map((x, i) => (
        <div key={i}>
          <p className="mt-5 has-text-weight-bold">
            The following patients did not comply with this quality indicator:
          </p>
          <p>
            <span className="has-text-weight-bold">{x.procedure}</span>
            {`: ${x.item_name}`}
          </p>
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>Hospital</th>
                <th>Patient</th>
                <th>Date of Surgery</th>
                <th>User</th>
                <th>Surgeon</th>
              </tr>
            </thead>
            <tbody>
              {x.data.map((y, idx) => (
                <React.Fragment key={idx}>
                  <tr>
                    <td>{y.hospital_name}</td>
                    <td>{y.patient}</td>
                    <td>{y.date}</td>
                    <td>{y.user}</td>
                    <td>{y.surgeon}</td>
                  </tr>
                  <tr>
                    <td colSpan={5} style={{ borderBottom: "2px solid black" }}>
                      <span className="has-text-weight-bold">Notes:</span>
                      {` ${y.notes ?? ""}`}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <div style={{ breakAfter: "page" }} />
        </div>
      ))}
    </div>
  );
};

const PdfNonCompliance = (props) => {
  const [filters, setFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onFilterChanged = (value, type) => {
    let fValues = { ...filters };
    if (type === 0) {
      fValues["start_date"] = value;
    } else if (type === 1) {
      fValues["end_date"] = value;
    } else if (type === 2) {
      fValues["facility"] = value;
    }
    setFilters(fValues);
  };

  const renderData = () => {
    if (filters.facility != null && filters.start_date && filters.end_date) {
      if (!isLoading) {
        setIsLoading(true);

        const params = {
          facility_id: filters.facility,
          start_date: dateToDateOnlyString(filters.start_date),
          end_date: dateToDateOnlyString(filters.end_date),
        };

        API.getReportBundleData(
          params,
          function (resp) {
            setData({
              facility: facility ?? "",
              results: resp.non_compliance_data,
              dateRange: `${start_date} - ${end_date}`,
            });
            setIsLoading(false);
          },
          onDataLoadError
        );
      }
    }
  };

  const onDataLoadError = (error) => {
    console.log(error);
  };

  const start_date = filters.start_date
    ? new Date(filters.start_date).toLocaleDateString()
    : "";
  const end_date = filters.end_date
    ? new Date(filters.end_date).toLocaleDateString()
    : "";
  const facility =
    props.facilities && filters.facility
      ? props.facilities.find((f) => {
          return f.value == filters.facility;
        }).label ?? ""
      : "";

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card modal-extra-large">
        <header className="modal-card-head">
          <p className="modal-card-title">
            Non-Compliance Report Continuous Quality Improvement
          </p>
        </header>

        <section className="modal-card-body p-0">
          <div className="p-5">
            <div className="w-100">
              <div className="columns">
                <div className="column">
                  <div style={{ midWidth: "499px", maxWidth: "500px" }}>
                    <Select
                      options={props.facilities}
                      placeholder={"All"}
                      value={props.facilities.find(
                        (e) => e.value == filters.facility
                      )}
                      onChange={(option) => onFilterChanged(option.value, 2)}
                    />
                  </div>
                </div>
                <div
                  className="column"
                  style={{ maxWidth: "200px", minWidth: "199px" }}
                >
                  <DatePicker
                    className="input"
                    selectsStart
                    startDate={filters.start_date}
                    endDate={filters.end_date}
                    selected={filters.start_date}
                    onChange={(date) => onFilterChanged(date, 0)}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Start Date"
                  />
                </div>
                <div
                  className="column"
                  style={{ maxWidth: "200px", minWidth: "199px" }}
                >
                  <DatePicker
                    className="input"
                    selectsStart
                    startDate={filters.start_date}
                    endDate={filters.end_date}
                    selected={filters.end_date}
                    onChange={(date) => onFilterChanged(date, 1)}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="End Date"
                  />
                </div>
              </div>
              <div className="level w-100">
                <div className="level-item has-text-centered">
                  <button
                    className={`button is-success ${isLoading && "is-loading"}`}
                    onClick={() => renderData()}
                    disabled={!start_date || !end_date || !facility}
                  >
                    Generate
                  </button>
                </div>
              </div>

              {!data && (
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              )}

              {data &&
                !isLoading &&
                PdfNonComplianceRenderer(data, componentRef)}
            </div>
          </div>

          <button
            onClick={props.onClose}
            className="modal-close is-large"
            aria-label="close"
          ></button>
        </section>

        <footer className="modal-card-foot">
          <div className="level w-100">
            <div className="level-left">
              <button className="button is-danger" onClick={props.onClose}>
                Close
              </button>
            </div>
            <div className="level-right">
              {data && (
                <button className="button is-success" onClick={handlePrint}>
                  Print
                </button>
              )}
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default PdfNonCompliance;
