import React, { useEffect, useState, useMemo } from "react";
import DynamicForm from "../../../components/DynamicForm";
import API from "../../../../services/API";
import DataTable from 'react-data-table-component';
import POModal from "../../../components/POModal";
import ErrorModal from "../../../components/ErrorModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { FaPencilAlt, FaTrashAlt, FaFileImport, FaExpandArrowsAlt, FaBan, FaBookOpen, FaForward } from "react-icons/fa";
import ListModal from "../../../components/ListModal";
import ReceiveModal from "../../../components/ReceiveModal"
import dateForm from "../../../forms/receiveDateForm"
import adminDateForm from "../../../forms/adminOrderDate"
import noteForm from '../../../forms/receiveNoteForm'
import orderSearch from "../../../forms/orderListSearch"
import orderListModal from "../../../forms/orderListModal"
import orderListSubMenu from "../../../forms/orderListSubMenu"
import VisitTimer from "../../../components/VisitTimer";
import NoteModal from "../../../components/noteModal"
import Session from "../../../../services/Session"
import AdminReceiveModal from "../../../components/AdminReceiveModal"
import ItemListModal from "../../../components/ItemListModal"

function OrderList(props) {
    const [filterValues, setFilterValues] = useState([])
    const [facilityOptions, setFacilityOptions] = useState([])
    const [manufacturerOptions, setManufacturerOptions] = useState([])
    const [purchaseOrders, setPurchaseOrders] = useState([])
    const [products, setProducts] = useState([])
    const [manufacturers, setManufacturers] = useState([])
    const [purchaseOrderOptions, setPurchaseOrderOptions] = useState([])
    const [items, setItems] = useState([])
    const [preSaveProducts, setPreSaveProducts] = useState([])
    const [showPOModal, setShowPOModal] = useState(false)
    const [isSubEditMode, setIsSubEditMode] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [showSubConfirmationModal, setShowSubConfirmationModal] = useState(false)
    const [showSubAdd, setShowSubAdd] = useState(false)
    const [editValues, setEditValues] = useState([])
    const [subEditValues, setSubEditValues] = useState([])
    const [isSubMenu, setIsSubMenu] = useState(true)
    const [selectedItem, setSelectedItem] = useState([])
    const [list, setList] = useState([])
    const [showListModal, setShowListModal] = useState(false)
    const [showReceiveModal, setShowReceiveModal] = useState(false)
    const [receiveDate, setReceiveDate] = useState([])
    const [receiveNote, setReceiveNote] = useState([])
    const [receiveValues, setReceiveValues] = useState([])
    const [orderList, setOrderList] = useState([])
    const [order, setOrder] = useState({})
    const [productList, setProductList] = useState([])
    const [isEditMode, setIsEditMode] = useState(false)
    const [historyArr, setHistoryArr] = useState([])
    const [noteValues, setNoteValues] = useState({})
    const [showNoteModal, setShowNoteModal] = useState(false)
    const [adminOrderValues, setAdminOrderValues] = useState([])
    const [showAdminReceiveModal, setShowAdminReceiveModal] = useState(false)
    const [adminDate, setAdminDate] = useState([])
    const [adminNote, setAdminNote] = useState([])
    const [locationCounts, setLocationCounts] = useState([])
    const [itemList, setItemList] = useState()
    const [isLoadingOrders, setIsLoadingOrders] = useState(false)
    const [totalOrders, setTotalOrders] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const onAdminNoteChanged = (values) => {
        setAdminNote(values)
    }

    const onAdminDateChanged = (values) => {
        setAdminDate(values)
    }

    const onDateChanged = (values) => {
        setReceiveDate(values)
    }

    const onNoteChanged = (values) => {
        setReceiveNote(values)
    }

    const onFilterChanged = (values) => {
        setFilterValues(values)
    }

    const onEditChange = (values) => {
        setEditValues(values)
    }

    const onSubEditChange = (values) => {
        setSubEditValues(values)
        findSelectedItem(values['item'])
    }

    const onSubAddNewClicked = () => {
        setShowSubAdd(true)
    }

    const onSubEditClicked = (row) => {
        setSubEditValues(row)
        setShowSubAdd(true)
        setIsSubEditMode(true)
    }

    const onSubDeleteClicked = (row) => {
        setSubEditValues(row)
        setShowSubConfirmationModal(true)
    }

    const onSubAddClosed = () => {
        setShowSubAdd(false)
        setSubEditValues([])
        setIsSubEditMode(false)
    }

    const onPOModalClosed = () => {
        setShowSubAdd(false)
        setShowPOModal(false)
        props.updateID()
        props.updateItem()
        props.updateLoc()
        setEditValues([])
        setSubEditValues([])
        if (!isEditMode) {
            var i
            for (i = 0; i < preSaveProducts.length; i++) {
                API.query("DELETE", `/api/products/${preSaveProducts[i].id}/`, {}, {}, function (resp) { }, onDataLoadError)
            }
        }

        setPreSaveProducts([])
        setIsEditMode(false)
    }

    const findSelectedItem = (itemID) => {
        let filteredItem = items.filter((obj) => {
            var valid = false
            if (itemID == obj.id) {
                valid = true
            }
            return valid
        })
        setSelectedItem(filteredItem)
    }

    const generateProductIDList = () => {
        var x, prodIDs = []
        for (x = 0; x < preSaveProducts.length; x++) {
            prodIDs.push(preSaveProducts[x].id)
        }
        return prodIDs
    }

    const onReceiveClose = () => {
        setOrder({})
        setReceiveValues([])
        setReceiveDate([])
        setReceiveNote([])
        setShowReceiveModal(false)
        props.updateID()
        API.query('GET', `/api/productlist/`, {}, {}, function (resp) {
            setProductList(resp)
        }, onDataLoadError)
    }

    const getReceiveQtyType = (product) => {
        if (order.id) {
            if (product.item_quantity_type == 1) {
                return 'CS'
            } else if (product.item_quantity_type == 2) {
                return 'PCS'
            }
        }
        return ''
    }

    const updateStatus = (newReceived, product) => {
        if ((newReceived + product.receivedQty) < product.quantity) {
            return 'Partial'
        } else {
            return 'Complete'
        }
    }

    const createHistory = (newID) => {
        let newArr = historyArr
        newArr.push(newID)
        setHistoryArr(newArr)
    }

    const appendLocCount = (arr, newid) => {
        let newarr = [...arr]
        newarr.push(newid)
        return newarr
    }

    const onReceiveSave = () => {
        //all of this probably needs a rework
        if (!isSaving) {
            if (receiveValues.length != 0) {
                var user = Session.get('user')
                var i
                setIsSaving(true)
                for (i = 0; i < receiveValues.length; i++) {
                    if (receiveValues[i].value != null && receiveValues[i].value > 0) {
                        var newProductList = productList
                        var index = newProductList.findIndex((e) => e.id == receiveValues[i].id)
                        var receiveVal = receiveValues[i].value
                        let product = newProductList[index]
                        let init = `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`
                        let historyData = {
                            'received': receiveValues[i].value,
                            'date': receiveDate['receive_date'],
                            'note': receiveNote['notes'],
                            'user_init': init,
                            'quantity_type': getReceiveQtyType(product),
                            'item_description': product.item_description,
                            'status': updateStatus(parseInt(receiveValues[i].value), product)
                        }
                        API.query('POST', `/api/receivehistory/`, {}, historyData, function (resp) {
                            createHistory(resp.id)
                        }, onDataLoadError)
                        let prodPatch = {}
                        if ((parseInt(receiveValues[i].value) + product.receivedQty) >= product.quantity) {
                            prodPatch = {
                                'receivedQty': (parseInt(receiveValues[i].value) + product.receivedQty),
                                'view': false
                            }
                        } else {
                            prodPatch = {
                                'receivedQty': (parseInt(receiveValues[i].value) + product.receivedQty)
                            }
                        }
                        API.query('PATCH', `/api/products/${receiveValues[i].id}/`, {}, prodPatch, function (resp) {
                            let item = items.find((e) => e.id == resp.item)
                            let loc_item = locationCounts.find((e) => {
                                var m, abort = false, valid = false
                                for(m=0; m < item.location_counts.length; m++) {
                                    if(e.id == item.location_counts[m]) {
                                        if(order.facility == e.facility) {
                                            valid = true
                                            abort = true
                                        }
                                    }
                                }
                                return valid
                            })
                            if(loc_item != null) {
                                let data = {
                                    'count': (parseInt(receiveVal) * item.quantity) + loc_item.count,
                                    'date': new Date()
                                }
                                API.query('PATCH',`/api/locationcounts/${loc_item.id}/`,{},data,function(response) {
                                    let newlocs = [...locationCounts]
                                    let index = locationCounts.findIndex((e) => e.id == loc_item.id)
                                    if(index > -1) {
                                        newlocs[index] = response
                                    }
                                    setLocationCounts(newlocs)
                                },onDataLoadError)
                            } else {
                                let data = {
                                    'facility': order.facility,
                                    'count': parseInt(receiveVal) * item.quantity,
                                    'date': new Date()
                                }
                                API.query('POST',`/api/locationcounts/`,{},data,function(resp) {
                                    let patchdata = {
                                        'location_counts': appendLocCount(item.location_counts, resp.id)
                                    }
                                    let newlocs = [...locationCounts]
                                    newlocs.push(resp)
                                    setLocationCounts(newlocs)
                                    API.query('PATCH',`/api/items/${item.id}/`,{},patchdata,function(response) {
                                        let index = items.findIndex((e) => e.id == response.id)
                                        let newitems = [...items]
                                        if(index > -1) {
                                            newitems[index] = response
                                        }
                                        setItems(newitems)
                                    },onDataLoadError)
                                },onDataLoadError)
                            }
                        }, onDataLoadError)
                    }
                }
                API.query('GET', `/api/orderlist/`, {}, {}, function (resp) {
                    var i, abort = false, status = 0
                    let newList = resp
                    //let index = newList.findIndex((e) => e.id == order.id)
                    let orderStatusPatch = newList.find((e) => e.id == order.id)
                    for (i = 0; i < orderStatusPatch.products.length && !abort; i++) {
                        if (orderStatusPatch.products[i].quantity <= orderStatusPatch.products[i].receivedQty) {
                            status = 5
                        } else if (0 < orderStatusPatch.products[i].receivedQty < orderStatusPatch.products[i].quantity) {
                            status = 4
                            abort = true
                        } else {
                            status = 3
                        }
                    }
                    let histArr = []
                    if (order.receive_history && order.receive_history.length != 0) {
                        let recArr = []
                        let i
                        for (i = 0; i < order.receive_history.length; i++) {
                            recArr.push(order.receive_history[i].id)
                        }
                        histArr = historyArr.concat(recArr)
                    } else {
                        histArr = historyArr
                    }
                    let data = {}
                    if (orderStatusPatch.status != status) {
                        data = {
                            'receive_history': histArr,
                            'status': status
                        }
                    } else {
                        data = {
                            'receive_history': histArr
                        }
                    }
                    API.query('PATCH', `/api/purchaseorders/${orderStatusPatch.id}/`, {}, data, function (response) {
                        //newList[index].status = status
                        let newPurchaseOrders = purchaseOrders
                        let index1 = purchaseOrders.findIndex((e) => e.id == orderStatusPatch.id)
                        newPurchaseOrders[index1] = response
                        if(props.permissions.includes(13) || props.extraPermissions.includes(13)) {
                            let user = Session.get('user')
                            let filteredFOs = newPurchaseOrders.filter((obj) => {
                                var i, abort=false, valid=false
                                for(i=0; i < user.facilities.length && !abort; i++) {
                                    if(user.facilities[i] == obj.facility) {
                                        valid = true
                                        abort = true
                                    }
                                }
                                return valid
                            })
                            setPurchaseOrders(filteredFOs)
                        } else {
                            setPurchaseOrders(newPurchaseOrders)
                        }
                        // API.query('GET', `/api/purchaseorders/`, {}, {}, function (response) {
                        //     setPurchaseOrders(response)
                        // }, onDataLoadError)
                    }, onDataLoadError)
                    API.query('GET', `/api/productlist/`, {}, {}, function (Resp) {
                        setProductList(Resp)
                        API.query('GET', `/api/orderlist/`, {}, {}, function (resp) {
                            setOrderList(resp)
                        }, onDataLoadError)
                    }, onDataLoadError)
                    //setOrderList(newList)
                    setOrder({})
                    setReceiveNote([])
                    setReceiveValues([])
                    setReceiveDate([])
                    setHistoryArr([])
                    setShowReceiveModal(false)
                    props.updateID()
                    setIsSaving(false)
                }, onDataLoadError)
            }
        }
    }

    const onOrder = () => {
        if (!isSaving) {
            setIsSaving(true)
            let user = Session.get('user')
            let data = {
                'poNumber': editValues['poNumber'],
                'products': generateProductIDList(),
                'facility': editValues['facility'],
                'req_ship_date': editValues['req_ship_date'],
                'status': 2,
                'user': user.id
            }
            let method = isEditMode ? 'PATCH' : 'POST'
            let uri = isEditMode ? `/api/purchaseorders/${editValues.id}/` : `/api/purchaseorders/`
            API.query(method, uri, {}, data, function (resp) {
                let newPOs = purchaseOrders

                if (isEditMode) {
                    let index = newPOs.findIndex((e) => e.id == resp.id)
                    newPOs[index] = resp
                } else {
                    data['id'] = resp.id
                    data['poNumber'] = resp.poNumber
                    data['order_date'] = resp.order_date
                    data['status'] = resp.status
                    newPOs.push(data)
                }

                API.query("GET", '/api/products/', {}, {}, function (resp) {
                    setProducts(resp)
                    API.query("GET", '/api/productlist/', {}, {}, function (respo) {
                        setProductList(respo)
                        API.query("GET", '/api/orderlist/', {}, {}, function (response) {
                            setOrderList(response)
                        })
                    }, onDataLoadError)
                }, onDataLoadError)
                if(props.permissions.includes(13) || props.extraPermissions.includes(13)) {
                    let user = Session.get('user')
                    let filteredFOs = newPOs.filter((obj) => {
                        var i, abort=false, valid=false
                        for(i=0; i < user.facilities.length && !abort; i++) {
                            if(user.facilities[i] == obj.facility) {
                                valid = true
                                abort = true
                            }
                        }
                        return valid
                    })
                    setPurchaseOrders(filteredFOs)
                } else {
                    setPurchaseOrders(newPOs)
                }
                setPreSaveProducts([])
                setIsSaving(false)
                setShowPOModal(false)
                props.updateID()
                props.updateItem()
                props.updateLoc()
                setEditValues([])
                setIsEditMode(false)
            }, onDataLoadError)
        }
    }

    const onSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let user = Session.get('user')
            let data = {
                'poNumber': editValues['poNumber'],
                'products': generateProductIDList(),
                'facility': editValues['facility'],
                'req_ship_date': editValues['req_ship_date'],
                'user': user.id
            }
            let method = isEditMode ? 'PATCH' : 'POST'
            let uri = isEditMode ? `/api/purchaseorders/${editValues.id}/` : `/api/purchaseorders/`
            API.query(method, uri, {}, data, function (resp) {
                let newPOs = purchaseOrders

                if (isEditMode) {
                    let index = newPOs.findIndex((e) => e.id == resp.id)
                    newPOs[index] = resp
                } else {
                    data['id'] = resp.id
                    data['poNumber'] = resp.poNumber
                    data['order_date'] = resp.order_date
                    data['status'] = resp.status
                    newPOs.push(data)
                }
                API.query("GET", '/api/products/', {}, data, function (resp) {
                    setProducts(resp)
                })
                if(props.permissions.includes(13) || props.extraPermissions.includes(13)) {
                    let user = Session.get('user')
                    let filteredFOs = newPOs.filter((obj) => {
                        var i, abort=false, valid=false
                        for(i=0; i < user.facilities.length && !abort; i++) {
                            if(user.facilities[i] == obj.facility) {
                                valid = true
                                abort = true
                            }
                        }
                        return valid
                    })
                    setPurchaseOrders(filteredFOs)
                } else {
                    setPurchaseOrders(newPOs)
                }
                setPreSaveProducts([])
                setIsSaving(false)
                setShowPOModal(false)
                props.updateID()
                props.updateItem()
                props.updateLoc()
                setEditValues([])
                setIsEditMode(false)
            }, onDataLoadError)
        }
    }

    const onSubSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'item': subEditValues['item'],
                'quantity': subEditValues['quantity'],
                'req_ship_date': subEditValues['req_ship_date'],
                'notes': subEditValues['notes']
            }
            let method = isSubEditMode ? 'PUT' : 'POST'
            let uri = isSubEditMode ? `/api/products/${subEditValues.id}/` : '/api/products/'
            API.query(method, uri, {}, data, function (resp) {
                let newPreSave = preSaveProducts
                if (isSubEditMode) {
                    let i = newPreSave.findIndex(j => j.id == subEditValues.id)
                    //if(i > 0) {
                    data['id'] = subEditValues.id
                    newPreSave[i] = data
                    //}
                } else {
                    data['id'] = resp.id
                    newPreSave.push(data)
                }
                setPreSaveProducts(newPreSave)
                setSelectedItem([])
                setIsSaving(false)
                setShowSubAdd(false)
                setSubEditValues([])
            }, onDataLoadError)
        }
    }

    const onConfirmSubDelete = () => {
        API.query("DELETE", `/api/products/${subEditValues.id}/`, {}, {}, function (resp) {
            let newProducts = preSaveProducts.filter(j => j.id !== subEditValues.id)
            setPreSaveProducts(newProducts)
            setShowSubConfirmationModal(false)
            setSubEditValues([])
        }, onDataLoadError)
    }

    const onButtonPressed = (id) => {
        if (id == "clear") {
            setFilterValues([])
        } else if (id == "create_new") {
            setIsEditMode(false)
            setShowPOModal(true)
            props.updateID(-5)
        } else {
            //unknown button
        }
    }

    const filterByFacility = (obj) => {
        var i, abort = false, valid = false
        if (filterValues.facility && filterValues.facility.length != 0) {
            for (i = 0; i < filterValues.facility.length && !abort; i++) {
                // if (filterValues.facility[i] == obj.facility) {
                //     valid = true
                //     abort = true
                // }
                if(filterValues.facility[i].value == obj.facility) {
                    valid = true
                    abort = true
                }
            }
        } else {
            valid = true
        }
        return valid
    }

    const filterByItself = (obj) => {
        var i, abort = false, valid = false
        if (filterValues.purchaseOrder && filterValues.purchaseOrder.length != 0) {
            for (i = 0; i < filterValues.purchaseOrder.length && !abort; i++) {
                if (filterValues.purchaseOrder[i] == obj.id) {
                    valid = true
                    abort = true
                }
            }
        } else {
            valid = true
        }
        return valid
    }

    const filterByStatus = (obj) => {
        var i, abort = false, valid = false
        if (filterValues.status && filterValues.status != 0) {
            for (i = 0; i < filterValues.status.length && !abort; i++) {
                if (filterValues.status[i] == obj.status) {
                    valid = true
                    abort = true
                }
            }
        } else {
            valid = true
        }
        return valid
    }

    const filterByDate = (obj) => {
        var valid = false
        if (filterValues.date) {
            let objDateArr = obj.order_date.split('T')
            let manuObjDate = objDateArr[0]
            let dateArr = filterValues['date'].split('/')
            let manuDate = `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`
            if (manuDate == manuObjDate) {
                valid = true
            }
        } else {
            valid = true
        }
        return valid
    }

    const filterByManufacturer = (po) => {
        var i, x, abort2 = false, abort = false, valid = false
        if (filterValues.manufacturer && filterValues.manufacturer.length != 0) {
            let filteredProducts = products.filter((obj) => {
                var valid1 = false, i, abort = false
                for (i = 0; i < po.products.length && !abort; i++)
                    if (obj.id == po.products[i]) {
                        valid1 = true
                        abort = true
                    }
                return valid1
            })
            let filteredItems = items.filter((obj) => {
                var valid2 = false, i, abort = false
                for (i = 0; i < filteredProducts.length && !abort; i++) {
                    if (obj.id == filteredProducts[i].item) {
                        valid2 = true
                        abort = true
                    }
                }
                return valid2
            })
            let filteredManufacturers = manufacturers.filter((obj) => {
                var i, valid3 = false, abort = false
                for (i = 0; i < filteredItems.length && !abort; i++) {
                    if (filteredItems[i].manufacturer == obj.id) {
                        valid3 = true
                        abort = true
                    }
                }
                return valid3
            })
            for (i = 0; i < filteredManufacturers.length && !abort; i++) {
                for (x = 0; x < filterValues.manufacturer.length && !abort2; x++) {
                    if (filterValues.manufacturer[x] == filteredManufacturers[i].id) {
                        valid = true
                        abort = true
                        abort2 = true
                    }
                }
            }
        } else {
            valid = true
        }

        return valid
    }

    const filteredOrders = purchaseOrders.filter((obj) => {
        var valid = false

        if (filterByFacility(obj)) {
            if (filterByItself(obj)) {
                if (filterByStatus(obj)) {
                    if (filterByManufacturer(obj)) {
                        if (filterByDate(obj)) {
                            valid = true
                        }
                    }
                }
            }
        }
        return valid
    })

    const filteredReceiveProducts = productList.filter((obj) => {
        var valid = false, i, abort = false
        if (order && order.id) {
            for (i = 0; i < order.products.length && !abort; i++) {
                if (obj.id == order.products[i].id) {
                    if (obj.view) {
                        if (obj.tempView) {
                            valid = true
                        }
                    }
                    abort = true
                }
            }
        }
        return valid
    })

    const onListClosed = () => {
        setShowListModal(false)
        setList([])
    }
    
    const onItemsClosed = () => {
        setItemList()
    }

    const checkLocationPrice = (item) => {
        if(editValues.facility != null) {
            let itemLocationCounts = locationCounts.filter((obj) => {
                var i, abort = false, valid = false
                for(i=0; i < item.location_counts.length && !abort; i++) {
                    if(item.location_counts[i] == obj.id) {
                        valid = true
                        abort = true
                    }
                }
                return valid
            })

            let facilityLocationCount = itemLocationCounts.find((obj) => {
                var valid = false
                if(editValues.facility == obj.facility) {
                    valid = true
                }
                return valid
            })

            if(facilityLocationCount != null) {
                if(facilityLocationCount.price != null) {
                    return facilityLocationCount.price
                }
            }
            return null
        }
    }

    const getItemNameByValue = (qc, source, id) => {
        let option = source.find(o => o.id == id)
        return option ? (option.item_type_name + ' ' + option.catalog) : null
    }

    const getItemPriceByValue = (qc, source, id) => {
        let option = source.find(o => o.id == id)
        let locPrice = checkLocationPrice(option)
        if(locPrice != null) {
            return locPrice
        } else {
            return option ? option.price : null
        }
    }

    const getItemDescriptionByValue = (qc, source, id) => {
        let option = source.find(o => o.id == id)
        return option ? option.description : null
    }

    const getItemManufacturerByValue = (qc, source, id) => {
        let option = source.find(o => o.id == id)
        return option ? getOptionLabelByValue(option, manufacturerOptions, option.manufacturer) : null
    }

    const getSubTotalByValue = (qc, source, id, quantity) => {
        let price = getItemPriceByValue(qc,source,id)
        let newQty = parseInt(quantity).toFixed(2)

        return (price * newQty)
    }

    const getOptionLabelByValue = (qc, source, id) => {
        let option = source.find(o => o.value == id)
        return option ? option.label : null
    }

    const getDescriptionListByPO = (id) => {
        let po = orderList.find((e) => e.id == id)
        var i, items = []
        for(i=0; i < po.products.length; i++) {
            items.push({
                'desc': po.products[i].item_description,
                'cat': po.products[i].item_catalog,
                'manu': po.products[i].item_manu,
                'quant': po.products[i].item_quantity,
                'quant_type': po.products[i].item_quantity_type
            })
        }
        setItemList(items)
    }

    const getItemCount = (po) => {
        var i, x, total = 0

        for (i = 0; i < po.products.length; i++) {
            for (x = 0; x < products.length; x++) {
                if (po.products[i] == products[x].id) {
                    total = total + products[x].quantity
                }
            }
        }
        return total
    }

    const getDateFormat = (date) => {
        let objDateArr = date.split('T')
        let manuObjDate = objDateArr[0]
        return manuObjDate
    }

    const itemOptions = items.map(i => ({
        "label": `${i.catalog}, ` + getOptionLabelByValue(i, manufacturerOptions, i.manufacturer) + ', ' + i.description, "value": i.id
    }))

    const onReceiveClicked = (id) => {
        let selected = orderList.find((e) => e.id == id)
        setOrder(selected)
        setShowReceiveModal(true)
        props.updateID(id)
    }

    const onReceiveChanged = (newid, newvalue) => {
        const newValues = receiveValues

        var i, abort = false, matchfound = false
        if (newValues && newValues.length != 0) {
            for (i = 0; i < newValues.length && !abort; i++) {
                if (newValues[i].id == newid) {
                    newValues[i].value = newvalue
                    matchfound = true
                    abort = true
                }
            }
        }

        if (!matchfound) {
            newValues.push({ "id": newid, "value": newvalue })
        }

        setReceiveValues(newValues)
    }

    const onHideClicked = (row) => {
        let newProductList = [...productList]
        let index = newProductList.findIndex((e) => e.id == row.id)
        newProductList[index].tempView = false
        setProductList(newProductList)
    }

    const checkColumnStatus = (status) => {
        if (status == 1) {
            return 'Saved'
        } else if (status == 2) {
            return 'Submitted'
        } else if (status == 3) {
            return 'On-Order'
        } else if (status == 4) {
            return 'Partially Received'
        } else if (status == 5) {
            return 'Finished'
        }
    }

    const onViewClicked = (id) => {
        let selected = orderList.find((e) => e.id == id)
        setOrder(selected)
        setShowReceiveModal(true)
        props.updateID(id)
    }

    const onSavedEditClick = (row) => {
        // let dateArr = receiveDate['receive_date'].split('/')
        // let manudate = `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`
        let prodIdArr = row.products
        let newProdArr = products.filter((obj) => {
            var i, abort = false, valid = false
            for (i = 0; i < prodIdArr.length && !abort; i++) {
                if (prodIdArr[i] == obj.id) {
                    valid = true
                    abort = true
                }
            }
            return valid
        })
        setPreSaveProducts(newProdArr)
        // let manudatearr = row.req_ship_date.split('-')
        // let manudate = `${manudatearr[1]}/${manudatearr[2]}/${manudatearr[0]}`
        setEditValues({ id: row.id, req_ship_date: row.req_ship_date, poNumber: row.poNumber, facility: row.facility })
        setIsEditMode(true)
        setShowPOModal(true)
        props.updateID(row.id)
    }

    const onSubmittedClick = (id) => {
        let selected = orderList.find((e) => e.id == id)
        setOrder(selected)
        setShowAdminReceiveModal(true)
        props.updateID(id)
    }

    const columns = useMemo(() => [
        { name: 'Field Order #', width:"140px", selector: row => row.poNumber, sortable: true },
        { name: 'Location', center:true, selector: row => getOptionLabelByValue(row, facilityOptions, row.facility), sortable: true },
        {
            name: 'Items',  width: "110px", center:true, selector: row => 
                <div>
                    {
                        <a onClick={() => getDescriptionListByPO(row.id)}>
                            Items
                        </a>
                    }
                </div>
        },
        { name: 'Item QTY', width: "100px", center:true, selector: row => getItemCount(row), sortable: true },
        { name: 'Date', width: "100px",center:true,selector: row => getDateFormat(row.order_date), sortable: true },
        { name: 'Status', width: "140px",center:true,selector: row => checkColumnStatus(row.status) },
        {
            name: 'Actions', width:"80px", center:true, selector: row => <div>
                {
                    row.status == 1
                    &&
                    <a title="Edit" onClick={() => onSavedEditClick(row)}><FaPencilAlt size={14} /></a>
                }
                {
                    row.status == 2
                    &&
                    <a title="Submitted" onClick={() => onSubmittedClick(row.id)}><FaForward size={14} /></a>
                }
                {
                    row.status == 3
                    &&
                    <a title="On-Order" onClick={() => onReceiveClicked(row.id)}><FaFileImport size={14} /></a>
                }
                {
                    row.status == 4
                    &&
                    <a title="Receive Items" onClick={() => onReceiveClicked(row.id)}><FaFileImport size={14} /></a>
                }
                {
                    row.status == 5
                    &&
                    <a title="View Finished" onClick={() => onViewClicked(row.id)}><FaExpandArrowsAlt size={14} /></a>
                }
            </div>
        }
    ])

    const onProductNoteClicked = (product) => {
        var tempNote = {}

        if (product.notes != null) {
            tempNote = { note: `${product.notes}` }
            setNoteValues(tempNote)
            setShowNoteModal(true)
        }
    }

    const onHistoryNoteClicked = (history) => {
        var tempNote = {}
        if (history.note != null) {
            tempNote = { note: `${history.note}` }
            setNoteValues(tempNote)
            setShowNoteModal(true)
        }
    }

    const checkProductNoteStyles = (product) => {
        if (product.notes != null) {
            //desired color style if note is present
            return ""
        } else {
            return "has-text-grey-lighter"
        }
    }

    const checkHistoryNoteStyles = (history) => {
        if (history.note != null) {
            //desired color style if note is present
            return ""
        } else {
            return "has-text-grey-lighter"
        }
    }

    const onAdminOrderedChange = (newid, newvalue) => {
        const newValues = [...adminOrderValues]

        var i, abort = false, matchfound = false
        if (newValues && newValues.length != 0) {
            for (i = 0; i < newValues.length && !abort; i++) {
                if (newValues[i].id == newid) {
                    newValues[i].quantity = newvalue
                    matchfound = true
                    abort = true
                }
            }
        }

        if (!matchfound) {
            newValues.push({ "id": newid, "quantity": newvalue, "item_status": null })
        }

        setAdminOrderValues(newValues)
    }

    const onAdminSelectChange = (newid, newvalue) => {
        const newValues = [...adminOrderValues]

        var i, abort = false, matchfound = false
        if (newValues && newValues.length != 0) {
            for (i = 0; i < newValues.length && !abort; i++) {
                if (newValues[i].id == newid) {
                    newValues[i].item_status = newvalue
                    matchfound = true
                    abort = true
                }
            }
        }

        if (!matchfound) {
            newValues.push({ "id": newid, "quantity": null, "item_status": newvalue })
        }

        setAdminOrderValues(newValues)
    }

    const adminOptions = [
        { value: 1, label: 'Ordered' },
        { value: 3, label: 'Backordered'}
    ]

    const productColumns = useMemo(() => [
        { name: 'Item Name CAT#', width:"165px", selector: row => getItemNameByValue(row, items, row.item), sortable: true },
        { name: 'Manufacturer', width:"140px", selector: row => getItemManufacturerByValue(row, items, row.item), sortable: true },
        { name: 'Description', width:"255px", selector: row => getItemDescriptionByValue(row, items, row.item), sortable: true },
        { name: 'Quantity', width:"100px", selector: row => row.quantity, sortable: true },
        { name: 'Unit Price', width: "105px", selector: row => `$${getItemPriceByValue(row, items, row.item)}`, sortable: true },
        { name: 'Subtotal', width: "110px", selector: row => `$${getSubTotalByValue(row, items, row.item, row.quantity).toFixed(2)}`, sortable: true },
        {
            name: 'Actions', width: "75px", center: true, selector: row => <div>
                <a title="Edit" onClick={() => onSubEditClicked(row)}><FaPencilAlt size={14} /></a>
                <a className="ml-3" title="Delete" onClick={() => onSubDeleteClicked(row)}><FaTrashAlt size={14} /></a>
            </div>
        }
    ])

    const historyColumns = useMemo(() => [
        { name: 'Items & Description', width: "300px", selector: row => row.item_description, sortable: true },
        { name: 'Received Status', selector: row => row.status, sortable: true },
        { name: 'Received QTY', selector: row => (row.received + `(${row.quantity_type})`) },
        { name: 'Received Date', selector: row => row.date, sortable: true },
        { name: 'Received User', selector: row => row.user_init, sortable: true },
        {
            name: 'Notes', selector: row => <div>
                <a title="View Notes" className={checkHistoryNoteStyles(row)} onClick={() => onHistoryNoteClicked(row)}><FaBookOpen size={16} /></a>
            </div>
        }
    ])

    const receiveColumns = useMemo(() => [
        { name: 'Item Description', width: "1fr", selector: row => row.item_description },
        { name: 'Catalog #', width: "120px", selector: row => row.item_catalog },
        { name: 'Manufacturer', width: "120px", selector: row => row.item_manu },
        { name: 'Req Delivery', width:"120px", selector: row => row.req_ship_date != null ? row.req_ship_date : null},
        { name: 'Ordered', width: "80px", selector: row => (row.quantity + `(${getReceiveQtyType(row)})`) },
        { name: 'Received', width: "100px", selector: row => row.receivedQty },
        {
            name: 'Receive Now', width:"120px", selector: row => <div>
                {
                    row.item_status == 1
                    &&
                    <input
                        className="input"
                        type="number"
                        onChange={(e) => onReceiveChanged(row.id, e.target.value)}
                    />
                }
                {
                    row.item_status == 3
                    &&
                    <input 
                        className="input"
                        type="text"
                        disabled
                        value="Backordered"
                    />
                }
            </div>
        },
        {
            name: '', width: "75px", selector: row => <div>
                <a title="Hide Line Item" onClick={() => onHideClicked(row)}><FaBan size={16} /></a>
                <a title="View Notes" className={checkProductNoteStyles(row)} style={{ paddingLeft: 6 }} onClick={() => onProductNoteClicked(row)}><FaBookOpen size={16} /></a>
            </div>
        }   
    ])

    const adminColumns = useMemo(() => [
        { name: 'Item Description', width: "1fr", selector: row => row.item_description },
        { name: 'Catalog #', width: "120px", selector: row => row.item_catalog },
        { name: 'Manufacturer', width: "120px", selector: row => row.item_manu },
        { name: 'Req Delivery', width:"120px", selector: row => row.req_ship_date != null ? row.req_ship_date : null},
        { name: 'Submitted', width: "110px", selector: row => (row.quantity + `(${getReceiveQtyType(row)})`) },
        {
            name: 'Ordered', width: "100px", selector: row => <div>
                <input
                    className="input"
                    type="number"
                    onChange={(e) => onAdminOrderedChange(row.id, e.target.value)}
                />
            </div>
        },
        {
            name: 'Status', width: "160px", selector: row => <div className="select">
                <select onChange={(option) => onAdminSelectChange(row.id, option.target.value)} value={row.item_status}>
                    <option>Select Status</option>
                    {
                        adminOptions.map((obj, i) => {
                            return <option key={i} value={obj.value}>{obj.label}</option>
                        })
                    }
                </select>
            </div>
        },
        {
            name: '', width: "80px", selector: row => <div>
                <a title="Hide Line Item" onClick={() => onHideClicked(row)}><FaBan size={16} /></a>
            </div>
        }
    ])

    useEffect(() => {
        let queries = [
            { 'method': 'GET', 'path': '/api/purchaseorders/', },
            { 'method': 'GET', 'path': '/api/manufacturers/', },
            { 'method': 'GET', 'path': '/api/facilities/', },
            { 'method': 'GET', 'path': '/api/items/', },
            { 'method': 'GET', 'path': '/api/products/', },
            { 'method': 'GET', 'path': '/api/orderlist/' },
            { 'method': 'GET', 'path': '/api/productlist/' },
            { 'method': 'GET', 'path': '/api/locationcounts/'}
        ]
        API.all(queries, function (resp) {
            if(props.permissions.includes(13) || props.extraPermissions.includes(13)) {
                let user = Session.get('user')
                let filteredFOs = resp[0].filter((obj) => {
                    var i, abort=false, valid=false
                    for(i=0; i < user.facilities.length && !abort; i++) {
                        if(user.facilities[i] == obj.facility) {
                            valid = true
                            abort = true
                        }
                    }
                    return valid
                })

                let filteredFacils = resp[2].filter((obj) => {
                    var i, abort=false, valid=false
                    for(i=0; i < user.facilities.length && !abort; i++) {
                        if(user.facilities[i] == obj.id) {
                            abort=true
                            valid=true
                        }
                    }
                    return valid
                })
                setPurchaseOrders(filteredFOs)
                setPurchaseOrderOptions(filteredFOs.map(i => ({ "label": i.poNumber, "value": i.id })))
                setFacilityOptions(filteredFacils.map(i => ({ "label": i.name, "value": i.id })))
            } else {
                setPurchaseOrders(resp[0])
                setPurchaseOrderOptions(resp[0].map(i => ({ "label": i.poNumber, "value": i.id })))
                setFacilityOptions(resp[2].map(i => ({ "label": i.name, "value": i.id })))
            }
            setManufacturerOptions(resp[1].map(i => ({ "label": i.name, "value": i.id })))
            setItems(resp[3])
            setProducts(resp[4])
            setManufacturers(resp[1])
            setOrderList(resp[5])
            setProductList(resp[6])
            setLocationCounts(resp[7])
            if (props.id != null) {
                if (props.id == -5) {
                    setShowPOModal(true)
                } else {
                    let option = resp[0].find((i) => i.id == props.id)
                    if (option.status == 1) {
                        let prodIdArr = option.products
                        let newProdArr = resp[4].filter((obj) => {
                            var i, abort = false, valid = false
                            for (i = 0; i < prodIdArr.length && !abort; i++) {
                                if (prodIdArr[i] == obj.id) {
                                    valid = true
                                    abort = true
                                }
                            }
                            return valid
                        })
                        setPreSaveProducts(newProdArr)
                        let manudatearr = option.req_ship_date.split('-')
                        let manudate = `${manudatearr[1]}/${manudatearr[2]}/${manudatearr[0]}`
                        setEditValues({ id: option.id, req_ship_date: manudate, poNumber: option.poNumber, facility: option.facility })
                        setIsEditMode(true)
                        setShowPOModal(true)
                    } else {
                        let receiveOption = resp[5].find((i) => i.id == props.id)
                        setOrder(receiveOption)
                        setShowReceiveModal(true)
                    }
                }
            }
            if (props.item != null) {
                if (props.loc != null) {
                    setEditValues({ facility: parseInt(props.loc) })
                    setSubEditValues({ item: parseInt(props.item) })
                    setShowPOModal(true)
                    setShowSubAdd(true)
                } else {
                    setSubEditValues({ item: parseInt(props.item) })
                    setShowPOModal(true)
                    setShowSubAdd(true)
                }
            }
        }, onDataLoadError)
    }, []);

    const onDataLoadError = (error) => {
        setErrorMessage(error)
        setShowErrorModal(true)
        setIsSaving(false)
    }

    const visited = () => {
        // get pageviewhistory id from user session data
        var user = Session.get('user')
        API.query('GET', `/api/pageviewhistory/${user.page_view_history}/`, {}, {}, function (response) {
            let data = {
                "inventory_orders": response.inventory_orders + 1
            }
            API.query('PATCH', `/api/pageviewhistory/${user.page_view_history}/`, {}, data, function (resp) { }, onDataLoadError)
        }, onDataLoadError)
    }

    const onNoteModalClosed = () => {
        setShowNoteModal(false)
        setNoteValues({})
    }

    const onAdminSave = () => {
        if (!isSaving) {
            if(adminOrderValues.length == order.products.length) {
                var i, valid=true
                for(i=0; i < adminOrderValues.length; i++) {
                    if(adminOrderValues[i].quantity == null) {
                        valid = false
                    }
                    if(adminOrderValues[i].item_status == null || adminOrderValues[i].item_status == 'Select Status') {
                        valid = false
                    }
                }

                if(valid) {
                    setIsSaving(true)
                    let dateArr = adminDate['order_date'].split('/')
                    let manuDate = `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`
                    //need a check for if there is only 1 item on the order and it gets backordered - needs to update status to finished , not on-order
                    var data
                    if(adminOrderValues.length == 1) {
                        if(adminOrderValues[0].item_status == 3) {
                            data = {
                                'status': 5,
                                'admin_note': adminNote.notes,
                                'placed_order_date': manuDate
                            }
                        } else {
                            data = {
                                'status': 3,
                                'admin_note': adminNote.notes,
                                'placed_order_date': manuDate
                            }
                        }
                    } else {
                        data = {
                            'status': 3,
                            'admin_note': adminNote.notes,
                            'placed_order_date': manuDate
                        }
                    }
                    API.query('PATCH', `/api/purchaseorders/${order.id}/`, {}, data, function (resp) {
                        API.query('GET', `/api/purchaseorders/`, {}, {}, function (response) {
                            if(props.permissions.includes(13) || props.extraPermissions.includes(13)) {
                                let user = Session.get('user')
                                let filteredFOs = response.filter((obj) => {
                                    var i, abort=false, valid=false
                                    for(i=0; i < user.facilities.length && !abort; i++) {
                                        if(user.facilities[i] == obj.facility) {
                                            valid = true
                                            abort = true
                                        }
                                    }
                                    return valid
                                })
                                setPurchaseOrders(filteredFOs)
                            } else {
                                setPurchaseOrders(response)
                            }
                            
                            setOrder({})
                            setReceiveValues([])
                            setAdminDate([])
                            setAdminNote([])
                            setShowAdminReceiveModal(false)
                            props.updateID()
                            setIsSaving(false)
                            var i
                            for(i=0; i < adminOrderValues.length; i++) {
                                let productData = {
                                    'quantity': adminOrderValues[i].quantity,
                                    'item_status': adminOrderValues[i].item_status
                                }
                                API.query('PATCH',`/api/products/${adminOrderValues[i].id}/`,{},productData,function(resp) {},onDataLoadError)
                            }
                            setAdminOrderValues([])
                            API.query('GET',`/api/products/`,{},{},function(resp) {
                                setProducts(resp)
                                API.query('GET',`/api/productlist/`,{},{},function(response) {
                                    setProductList(response)
                                },onDataLoadError)
                            },onDataLoadError)
                        }, onDataLoadError)
                    }, onDataLoadError)
                } else {
                    alert("Please fill out the information for all products on order.")
                }
            } else {
                alert("Please fill out the information for all products on order.")
            }
        }
    }

    const onAdminReceiveClose = () => {
        setOrder({})
        setReceiveValues([])
        setAdminDate([])
        setAdminNote([])
        setAdminOrderValues([])
        setShowAdminReceiveModal(false)
        props.updateID()
        API.query('GET', `/api/productlist/`, {}, {}, function (resp) {
            setProductList(resp)
        }, onDataLoadError)
    }

    const getTotal = () => {
        if(preSaveProducts.length > 0) {
            var total = 0.00, i
            for(i=0; i < preSaveProducts.length; i++) {
                let preSubTotal = getSubTotalByValue(i, items, preSaveProducts[i].item, preSaveProducts[i].quantity)
                total = parseInt(total) + preSubTotal
            }
            return total.toFixed(2)
        } else {
            return null
        }
    }

    const onFacilitySearch = (inputValue,callback) => {
        if(inputValue.length >= 3) {
          API.query('GET','/api/facilityoptions/',{query:inputValue},null,(results) => {
            callback(results.map((i) => ({"label": i.name, "value": i.id})))
          },() => {
            callback([])
          })
        }
      }

    // listen for current page change and reload
    // useEffect(() => {
    //     if(!isLoadingOrders) performSearch()
    // }, [currentPage]);

    // const performSearch = () => {
    //     setIsLoadingOrders(true);
    //     const params = {limit:10,offset:(currentPage-1)*10}

    //     API.query('GET','/api/orderlist/',params,{},(resp) => {
    //         setTotalOrders(resp.count)
    //         // setResults(resp.results)
    //         console.log(resp)
    //       }, onDataLoadError, () => setIsLoadingCases(false))
    // }

    return <div>
        <p className="title">Field Orders List</p>
        <hr />
        <DynamicForm
            isLoading={isLoadingOrders}
            form={orderSearch}
            values={filterValues}
            onValuesChanged={onFilterChanged}
            onButtonPressed={onButtonPressed}
            source={{ "facilities": facilityOptions, "manufacturers": manufacturerOptions, "purchaseorders": purchaseOrderOptions }}
            loaders={{"facility":onFacilitySearch}}
        />
        <hr />

        <p className="title is-5">Results</p>
        <DataTable
            striped
            pagination
            // paginationServer
            // paginationTotalRows={13}
            paginationComponentOptions={{noRowsPerPage:true}}
            progressPending={isLoadingOrders}
            onChangePage={setCurrentPage}
            data={filteredOrders}
            columns={columns}
            disabled={isLoadingOrders}
        />

        {
            showPOModal
            &&
            <POModal
                form={orderListModal}
                editValues={editValues}
                onEditChange={onEditChange}
                source={{ "facilities": facilityOptions, "itemOptions": itemOptions }}
                isSaving={isSaving}
                onSave={onSave}
                onClose={onPOModalClosed}

                subMenu={isSubMenu}
                subForm={orderListSubMenu}
                showSubAdd={showSubAdd}
                subColumns={productColumns}
                subEditValues={subEditValues}
                onSubEditChange={onSubEditChange}
                onSubSave={onSubSave}
                onSubClose={onSubAddClosed}
                onButtonPressed={onButtonPressed}
                onSubAddNewClicked={onSubAddNewClicked}
                filteredSub={preSaveProducts}
                selectedItem={selectedItem}
                isEditMode={isEditMode}
                onOrder={onOrder}
                manuOptions={manufacturerOptions}
                getTotal={getTotal}
            />
        }

        {
            showSubConfirmationModal
            &&
            <ConfirmationModal
                message={"You are about to delete a Product. This cannot be undone."}
                onConfirm={onConfirmSubDelete}
                onClose={() => setShowSubConfirmationModal(false)}
            />
        }

        {
            showErrorModal
            &&
            <ErrorModal
                message={errorMessage}
                onClose={() => setShowErrorModal(false)}
            />
        }

        {
            showListModal
            &&
            <ListModal
                string={"Manufacturers"}
                list={list}
                onClose={onListClosed}
            />
        }

        {
            showReceiveModal
            &&
            <ReceiveModal
                order={order}
                onSave={onReceiveSave}
                onClose={onReceiveClose}
                isSaving={isSaving}
                receiveColumns={receiveColumns}
                historyColumns={historyColumns}
                dateForm={dateForm}
                receiveDate={receiveDate}
                onDateChanged={onDateChanged}
                noteForm={noteForm}
                receiveNote={receiveNote}
                onNoteChanged={onNoteChanged}
                filteredProducts={filteredReceiveProducts}
            />
        }

        {
            showNoteModal
            &&
            <NoteModal
                note={noteValues}
                onClose={onNoteModalClosed}
            />
        }

        {
            showAdminReceiveModal
            &&
            <AdminReceiveModal
                order={order}
                onSave={onAdminSave}
                onClose={onAdminReceiveClose}
                isSaving={isSaving}
                adminColumns={adminColumns}
                noteForm={noteForm}
                dateForm={adminDateForm}
                onNoteChanged={onAdminNoteChanged}
                onDateChanged={onAdminDateChanged}
                filteredProducts={filteredReceiveProducts}
                adminDate={adminDate}
                adminNote={adminNote}
                adminOrderValues={adminOrderValues}
            />
        }

        {
            itemList
            ?
            <ItemListModal 
                items={itemList}
                onClose={onItemsClosed}
            />
            :
            null
        }

        <VisitTimer visited={visited} />
    </div>
}

export default OrderList