import React, { useEffect, useState } from "react";
import chartingCPG from  "../forms/chartingCPG"
import DynamicForm from "./DynamicForm";
import ChartingDateTime from "./ChartingDateTime";

function CPGModal(props) {
  const [data,setData] = useState({...props.data})
  const [missingFields,setMissingFields] = useState()
  const [time,setTime] = useState(props.time?new Date(props.time.getTime()):new Date())

  const CPGTypeOptions = [
    {"label": "ANTE", "value": 1},
    {"label": "RETRO", "value": 2},
    {"label": "BOTH", "value": 3}
  ]

  const form = chartingCPG

  const onSave = () => {
    let missing = []
    
    form.fields.forEach(f => {
      if(f.required && f.required == "true") {
        let branchConditionMet = !f.branch_from
        if(f.branch_from) {
          let branchField = form.fields.find(bf => bf.id == f.branch_from.id)
          if(branchField && data[branchField.id]) {
            branchConditionMet = f.branch_from.value == data[branchField.id]
          }
        }
        if(!data[f.id] && branchConditionMet) missing.push(f.id)
      }
    })

    if(missing.length > 0) {
      setMissingFields(missing)
    }else {
      setMissingFields([])
      data['typeName'] = CPGTypeOptions.find(t => t.value == data['type']).label
      props.onSave(data,time)
    }
  }

  const onCancel = () => {
    props.onClose()
  }

  let saveBtnClasses = "level-item button is-success";
  if (props.isSaving) saveBtnClasses += " is-loading"

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
    <header className="modal-card-head has-background-white is-block">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="is-size-4">Enter CPG Info</p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <ChartingDateTime 
                value={time}
                onChange={setTime}
              />
            </div>
          </div>
        </div>
      </header>

      <section className="modal-card-body">
        <DynamicForm 
          form={chartingCPG}
          values={data}
          onValuesChanged={setData}
          source={{"cpgTypes":CPGTypeOptions}}
          missingFields={missingFields}
        />
      </section>

      <footer className="modal-card-foot has-background-white">
        <div className="level w-100">
          <div className="level-left">
            <button className="level-item button is-danger is-inverted" onClick={onCancel}>Cancel</button>
          </div>
          <div className="level-right">
            <button className={saveBtnClasses} onClick={onSave}>Save</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
}

export default CPGModal