import React, { useEffect, useState } from "react"
import DynamicForm from "./DynamicForm"
import API from "../../services/API"
import ConfirmationModal from "./ConfirmationModal"
import ErrorModal from "./ErrorModal"
import form from "../forms/caseDetailsTransport"

function DetailTransport(props) {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [info, setInfo] = useState({})
    const [detail,setDetail] = useState({})

    let saveBtnClasses = "button is-success";
    if (isSaving) saveBtnClasses += " is-loading"

    useEffect(() => {
        API.query('GET', `/api/transportdetails/${props.detailID}/`, {}, {}, function (resp) {
            setDetail(resp)
            setInfo({info: resp.info})
        }, onDataLoadError)
    },[])
    
    const onTransportChanged = (value) => {
        setInfo(value)
    }

    const onTransportClear = () => {
        setConfirmationMessage('You are about to clear transport information, please confirm.')
        setShowConfirmationModal(true)
    }

    const onTransportSave = () => {
        if(!isSaving) {
            setIsSaving(true)
            let data = {
                'info': info.info
            }
            API.query('PATCH',`/api/transportdetails/${detail.id}/`,{},data,function(resp) {
                setInfo(resp)
                setIsSaving(false)
            },onDataLoadError)
        }
    }

    console.log('info',info)

    const onConfirm = () => {
        if(!isSaving) {
            let data = {
                'info': null
            }
            API.query('PATCH',`/api/transportdetails/${detail.id}/`,{},data,function(resp) {
                setInfo({info: ''})
                setShowConfirmationModal(false)
                setIsSaving(false)
            },onDataLoadError)
        }
    }

    const onDataLoadError = (error) => {
        setErrorMessage(error)
        setShowErrorModal(true)
        setIsSaving(false)
    }

    return <div>
        <DynamicForm
            key={625}
            form={form}
            values={info}
            onValuesChanged={onTransportChanged}
        />

        <hr />

        <div className="level w-100">
            <div className="level-left">
                <button className="button is-danger is-inverted" onClick={onTransportClear}>Clear</button>
            </div>
            <div className="level-right">
                <button className={saveBtnClasses} onClick={onTransportSave}>Save</button>
            </div>
        </div>

        {
            showConfirmationModal
            &&
            <ConfirmationModal
                message={confirmationMessage}
                onConfirm={onConfirm}
                onClose={() => setShowConfirmationModal(false)}
            />
        }

        {
            showErrorModal
            &&
            <ErrorModal
                message={errorMessage}
                onClose={() => setShowErrorModal(false)}
            />
        }
    </div>
}

export default DetailTransport