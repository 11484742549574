import React, { useState, useMemo } from "react";
import DynamicForm from "./DynamicForm";
import TransferForm from "../forms/transferModal";
import TransferAddFrom from "../forms/transferAddForm";
import DataTable from "react-data-table-component";
import { FaTrashAlt } from "react-icons/fa";

function SetupModal(props) {
  const {
    disabled,
    editValues,
    onClose,
    onEditChange,
    source,
    onSave,
    isSaving,
    transferItems,
    setTransferItems,
  } = props;

  const [missingFields, setMissingFields] = useState([]);
  const [addValues, setAddValues] = useState({});

  const onAddChange = (value) => {
    setAddValues(value);
  };

  const onSaveClick = () => {
    if (validate()) onSave();
  };

  const onButtonPressed = (id) => {
    if (id == "add_item") {
      if (addValues.item) {
        const tempItems = [...transferItems];
        tempItems.push({
          id: tempItems.length ?? 0,
          item: addValues.item,
          quantity: 1,
        });
        setTransferItems(tempItems);
        setAddValues({});
      }
    }
  };

  const validate = () => {
    let missingFields = [];
    TransferForm.fields.forEach((f) => {
      if (f.required && f.required == "true") {
        if (
          !(f.id in editValues) ||
          editValues[f.id] == "" ||
          editValues[f.id] == []
        )
          missingFields.push(f.id);
      }
    });
    if (missingFields.length > 0) setMissingFields(missingFields);
    return missingFields.length == 0;
  };

  const getFromAmount = (itemId) => {
    if (editValues.from_facility) {
      let item = props.items.find((e) => e.id == itemId);
      let counts = props.locationCounts.filter((e) =>
        item.location_counts.includes(e.id)
      );
      if (counts.length) {
        let countIndex = counts.findIndex(
          (e) => e.facility == editValues.from_facility
        );
        if (countIndex >= 0) {
          return `${counts[countIndex].count}`;
        } else {
          return `Not setup`;
        }
      }
    }
    return "Not Setup";
  };

  const getToAmount = (itemId) => {
    if (editValues.to_facility) {
      let item = props.items.find((e) => e.id == itemId);
      let counts = props.locationCounts.filter((e) =>
        item.location_counts.includes(e.id)
      );
      if (counts.length) {
        let countIndex = counts.findIndex(
          (e) => e.facility == editValues.to_facility
        );
        if (countIndex >= 0) {
          return `${counts[countIndex].count}`;
        } else {
          return `Not setup`;
        }
      }
    }
    return "Not Setup";
  };

  const getQuantityType = (itemId) => {
    if (itemId) {
      let item = props.items.find((e) => e.id == itemId);
      if (item.item_type == 1) {
        return `Case (${item.quantity})`;
      } else if (item.item_type == 2) {
        return `Individual`;
      }
    }
    return "Not Setup";
  };

  const onDeleteClicked = (rowId) => {
    let tempItems = [...transferItems];
    tempItems = tempItems.filter((x) => x.id !== rowId);
    setTransferItems(tempItems);
  };

  const onQuantityChange = (rowId, value) => {
    const tempItems = [...transferItems];
    const tempItem = tempItems.findIndex((x) => x.id === rowId);
    tempItems[tempItem].quantity = value;
    setTransferItems(tempItems);
  };

  const columns = useMemo(() => [
    {
      name: "Item Description",
      selector: (row) =>
        props?.items?.find((x) => x.id === row.item).description,
    },
    {
      name: "Quantity Type",
      width: "140px",
      selector: (row) => getQuantityType(row.item),
    },
    {
      name: "From Quantity",
      width: "140px",
      selector: (row) => getFromAmount(row.item),
    },
    {
      name: "To Quantity",
      width: "140px",
      selector: (row) => getToAmount(row.item),
    },
    {
      name: "Quantity",
      width: "100px",
      selector: (row) => (
        <div>
          {disabled ? (
            <input
              className="input"
              type="number"
              defaultValue={row.quantity}
              placeholder={row.quantity}
              onChange={(e) => onQuantityChange(row.id, e.target.value)}
              disabled={true}
            />
          ) : (
            <input
              className="input"
              type="number"
              defaultValue={row.quantity}
              placeholder={row.quantity}
              onChange={(e) => onQuantityChange(row.id, e.target.value)}
            />
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      width: "80px",
      selector: (row) => (
        <div>
          {disabled ? (
            <p></p>
          ) : (
            <a
              className="ml-3"
              title="Delete"
              onClick={() => onDeleteClicked(row.id)}
            >
              <FaTrashAlt size={14} />
            </a>
          )}
        </div>
      ),
    },
  ]);

  let saveBtnClasses = "button is-success";
  if (isSaving) saveBtnClasses += " is-loading";

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card modal-extra-large">
        <header className="modal-card-head">
          <p className="modal-card-title">New Transfer</p>
        </header>
        <section className="modal-card-body p-0">
          <div className="p-5">
            <div className="has-background-white rounded w-100">
              {disabled ? (
                <DynamicForm
                  form={TransferForm}
                  values={editValues}
                  onValuesChanged={onEditChange}
                  source={source}
                  missingFields={missingFields}
                  disabled
                />
              ) : (
                <DynamicForm
                  form={TransferForm}
                  values={editValues}
                  onValuesChanged={onEditChange}
                  source={source}
                  missingFields={missingFields}
                />
              )}

              <hr />

              {disabled ? null : (
                <DynamicForm
                  form={TransferAddFrom}
                  values={addValues}
                  onValuesChanged={onAddChange}
                  source={source}
                  onButtonPressed={onButtonPressed}
                />
              )}

              <br />

              <DataTable
                striped
                pagination
                data={transferItems}
                columns={columns}
              />
              <button
                onClick={onClose}
                className="modal-close is-large"
                aria-label="close"
              ></button>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot has-background-white">
          <div className="level w-100">
            <div className="level-left">
              <button
                className="level-item button is-danger is-inverted"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
            <div className="level-right">
              {!disabled && transferItems && transferItems.length && (
                <div>
                  {editValues.id ? (
                    <button className={saveBtnClasses} onClick={onSaveClick}>
                      Submit
                    </button>
                  ) : (
                    <button className={saveBtnClasses} onClick={onSaveClick}>
                      Save
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default SetupModal;
