import React, { useRef, useMemo } from "react";
import DataTable from "react-data-table-component";
import { useReactToPrint } from 'react-to-print';

function PdfPatientDisposable(props) {
    const componentRef = useRef()
    const today = new Date()

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const rowData = props.activeCase.items.map((e, idx) => {
        let category = props.categories.filter((x) => { if (x.id == e.item.item_category) return true })[0]
        let cost = (e.amount > 0 ? e.amount : 1) * parseFloat(e.item.price)

        return {
            number: idx + 1,
            manufacturer: e.item.manufacturer_name,
            catalog: e.item.catalog,
            category: category ? category.category : null,
            description: e.item.description,
            amount: e.amount,
            cost: cost
        }
    })

    const getTotal = () => {
        var total = 0.00
        for (let i = 0; i < rowData.length; i++) {
            total += rowData[i].cost
        }
        return total
    }

    const columns = useMemo(() => [
        // { name: '#', width: "50px", compact:true,selector: row => row.number },
        { name: 'Manufacturer', width: "110px", wrap:true,selector: row => row.manufacturer },
        { name: 'Catalog #', width:"110px", selector: row => row.catalog },
        { name: 'Item Category', width:"130px",wrap:true,selector: row => row.category },
        { name: 'Description', width:"140px", wrap:true, selector: row => row.description },
        { name: 'Units', width: "70px", selector: row => row.amount },
        { name: 'Price', width: "70px", selector: row => `$${row.cost}` }
    ])

    const total = getTotal()

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card modal-extra-large">
            <header className="modal-card-head">
                <p className="modal-card-title">Patient Disposables Report with Price</p>
            </header>

            <section className="modal-card-body p-0">
                <div className="p-5 full-print" style={{minWidth:"1000px"}} ref={componentRef}>
                    <style type="text/css" media="print">{"\
                    @page {\ size: landscape;\ }\
                    "}</style>
                    <div className="w-100">
                        <div>
                            <div className="level w-100">
                                <div className="level-left">
                                    <p className="title is-5">Patient Disposables Report with Price</p>
                                </div>
                                <div className="level-right">
                                    <p className="title is-5">{today.toLocaleDateString()}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="columns">
                                <div className="column is-2">
                                    <p className="has-text-weight-semibold">Start Date:</p>
                                    <p className="has-text-weight-semibold">Patient Name:</p>
                                    <p className="has-text-weight-semibold">MRN:</p>
                                    <p className="has-text-weight-semibold">Account No.:</p>
                                </div>
                                <div className="column is-2">
                                    <p>{props.activeCase.date ? props.activeCase.date : null}</p>
                                    <p>{props.activeCase.patient.last_name}, {props.activeCase.patient.first_name}</p>
                                    <p>{props.activeCase.patient.medical_record_num ? props.activeCase.patient.medical_record_num : ''}</p>
                                    <p>{props.activeCase.patient.account_num}</p>
                                </div>
                                <div className="column is-8"></div>
                            </div>

                            <hr />

                            <DataTable
                                striped
                                data={rowData}
                                columns={columns}
                                className="page-break"
                            />

                            <hr />

                            <div className="level w-100">
                                <div className="level-left"></div>
                                <div className="level-right">
                                    <p className="has-text-weight-medium">Total: ${total}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <button onClick={props.closeModal} className="modal-close is-large" aria-label="close"></button>
            </section>

            <footer className="modal-card-foot">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="button is-danger" onClick={props.closeModal}>Close</button>
                    </div>
                    <div className="level-right">
                        <button className="button is-success" onClick={handlePrint}>Print</button>
                    </div>
                </div>
            </footer>
        </div>
    </div>

}

export default PdfPatientDisposable