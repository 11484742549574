import React, { useEffect, useState, useMemo, useRef } from "react";
import autoNoteForm from "../forms/caseDetailsAutoNoteCheckbox"
import autoTopForm from "../forms/caseDetailsAutoTop"
import API from "../../services/API";
import ConfirmationModal from "./ConfirmationModal";
import ErrorModal from "./ErrorModal";
import DynamicForm from "./DynamicForm";
import DatePicker from "react-datepicker"
import DataTable from "react-data-table-component";
import format from "date-fns/format";
import parse from "date-fns/parse";
import autoMiddleForm from "../forms/caseDetailsAutoMiddle";
import { FaTrashAlt } from "react-icons/fa"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

function DetailAuto(props) {
    const [isSaving, setIsSaving] = useState(false)
    const [autoDetail, setAutoDetail] = useState([])
    const [autoTopValues, setAutoTopValues] = useState([])
    const [autoCardiacValues, setAutoCardiacValues] = useState([])
    const [autoVascularValues, setAutoVascularValues] = useState([])
    const [autoOrthoValues, setAutoOrthoValues] = useState([])
    const [autoHepaticValues, setAutoHepaticValues] = useState([])
    const [autoNeuroValues, setAutoNeuroValues] = useState([])
    const [autoTraumaValues, setAutoTraumaValues] = useState([])
    const [autoOtherValues, setAutoOtherValues] = useState([])
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [accordionID, setAccordionID] = useState([])
    const [confirmationMessage, setConfirmationMessage] = useState([])
    const [errorMessage, setErrorMessage] = useState()
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [volumeCount, setVolumeCount] = useState()
    const [volumes, setVolumes] = useState([])
    const [totalVolumes, setTotalVolumes] = useState([])
    const [otherLoss, setOtherLoss] = useState()
    const [autoMiddleValues, setAutoMiddleValues] = useState([])

    const autoTopRef = useRef()
    const bloodRef = useRef()
    const autoMiddleRef = useRef()

    const formatString = "HH:mm:ss"

    let saveBtnClasses = "button is-success";
    if (isSaving) saveBtnClasses += " is-loading"

    useEffect(() => {
        API.query("GET", `/api/autodetails/${props.detailID}/`, {}, {}, function (resp) {
            setAutoDetail(resp)
            setAutoMiddleValues({
                anticoag: resp.anticoag,
                other_coag:  resp.other_coag,
                amount: resp.amount
            })
            setAutoTopValues({ start_date: resp.start_date, end_date: resp.end_date, start_time: resp.start_time, end_time: resp.end_time, or_number: resp.or_number })
            setAutoMiddleValues({
                anticoag: resp.anticoag,
                other_coag: resp.other_coag,
                amount: resp.amount,
                vacuum_level: resp.vacuum_level,
                pre_hematocrit: resp.pre_hematocrit,
                pre_hemoglobin: resp.pre_hemoglobin
            })
            setAutoCardiacValues({ checked: resp.cardiac_checked, note: resp.cardiac_note })
            setAutoVascularValues({ checked: resp.vascular_checked, note: resp.vascular_note })
            setAutoOrthoValues({ checked: resp.ortho_checked, note: resp.ortho_note })
            setAutoHepaticValues({ checked: resp.hepatic_checked, note: resp.hepatic_note })
            setAutoNeuroValues({ checked: resp.neuro_checked, note: resp.neuro_note })
            setAutoTraumaValues({ checked: resp.trauma_checked, note: resp.trauma_note })
            setAutoOtherValues({ checked: resp.other_checked, note: resp.other_note })
            setOtherLoss(resp.other_loss)
            if (resp.volumes.length > 0) {
                let params = {
                    'volumes': [...resp.volumes]
                }
                API.query('GET', `/api/autovolumes/`, params, {}, function (resp) {
                    var newVolumes = resp, i, newArr = []
                    var collected_total = 0
                    var irrigation_total = 0
                    var anticoag_total = 0
                    var wash_total = 0
                    var returned_total = 0
                    var net_total = 0
                    var gross_total = 0

                    for (i = 0; i < newVolumes.length; i++) {
                        newArr.push({
                            label: `#${i + 1}`,
                            id: newVolumes[i].id,
                            rowID: i + 1,
                            time: newVolumes[i].time,
                            vol_collected: newVolumes[i].vol_collected,
                            irrigation: newVolumes[i].irrigation,
                            anticoag: newVolumes[i].anticoag,
                            wash: newVolumes[i].wash,
                            vol_returned: newVolumes[i].vol_returned,
                            net_ebl: newVolumes[i].net_ebl,
                            gross_ebl: newVolumes[i].gross_ebl
                        })
                        collected_total = collected_total + (isNaN(newVolumes[i].vol_collected) ? 0 : newVolumes[i].vol_collected)
                        irrigation_total = irrigation_total + (isNaN(newVolumes[i].irrigation) ? 0 : newVolumes[i].irrigation)
                        anticoag_total = anticoag_total + (isNaN(newVolumes[i].anticoag) ? 0 : newVolumes[i].anticoag)
                        wash_total = wash_total + (isNaN(newVolumes[i].wash) ? 0 : newVolumes[i].wash)
                        returned_total = returned_total + (isNaN(newVolumes[i].vol_returned) ? 0 : newVolumes[i].vol_returned)
                        net_total = net_total + (isNaN(newVolumes[i].net_ebl) ? 0 : newVolumes[i].net_ebl)
                        gross_total = gross_total + (isNaN(newVolumes[i].gross_ebl) ? 0 : newVolumes[i].gross_ebl)
                    }
                    setVolumes(newArr)
                    setTotalVolumes([{
                        vol_collected: collected_total,
                        irrigation: irrigation_total,
                        anticoag: anticoag_total,
                        wash: wash_total,
                        vol_returned: returned_total,
                        net_ebl: net_total,
                        gross_ebl: gross_total
                    }])
                    setVolumeCount(newArr.length)
                }, () => props.setErrorMessage('Failed fetching auto volumes.'))
            } else {
                setTotalVolumes([{
                    vol_collected: null,
                    irrigation: null,
                    anticoag: null,
                    wash: null,
                    vol_returned: null,
                    gross_ebl: null,
                    net_ebl: null
                }])
            }
        }, () => props.setErrorMessage('Failed fetching autotransfusion detail.'))
    }, [])

    const parseDate = (value, formatString) => {
        if (Array.isArray(value) || !value) return null
        try {
            return parse(value, formatString, new Date())
        } catch (e) {
            return null
        }
    }

    const formatDate = (value, formatString) => {
        if (Array.isArray(value) || !value) return null
        try {
            return format(value, formatString)
        } catch (e) {
            return null
        }
    }

    const onDataLoadError = (error) => {
        setErrorMessage(error)
        setShowErrorModal(true)
        setIsSaving(false)
    }

    const onAutoMiddleChanged = (value) => {
        setAutoMiddleValues(value)
    }

    const onAutoOtherChanged = (value) => {
        setAutoOtherValues(value)
    }

    const onAutoTraumaChanged = (value) => {
        setAutoTraumaValues(value)
    }

    const onAutoNeuroChanged = (value) => {
        setAutoNeuroValues(value)
    }

    const onAutoHepaticChanged = (value) => {
        setAutoHepaticValues(value)
    }

    const onAutoOrthoChanged = (value) => {
        setAutoOrthoValues(value)
    }

    const onAutoCardiacChanged = (value) => {
        setAutoCardiacValues(value)
    }

    const onAutoVascularChanged = (value) => {
        setAutoVascularValues(value)
    }

    const onAutoTopChanged = (value) => {
        setAutoTopValues(value)
    }

    const onAutoMiddleClear = () => {
        setConfirmationMessage('You are about to clear autotransfusion values, please confirm.')
        setAccordionID(10)
        setShowConfirmationModal(true)
    }

    const onAutoCardiacClear = () => {
        setConfirmationMessage('You are about to clear cardiac note values, please confirm.')
        setAccordionID(15)
        setShowConfirmationModal(true)
    }

    const onAutoTopClear = () => {
        setConfirmationMessage('You are about to clear top values, please confirm.')
        setAccordionID(14)
        setShowConfirmationModal(true)
    }

    const onAutoVascularClear = () => {
        setConfirmationMessage('You are about to clear vascular note values, please confirm.')
        setAccordionID(16)
        setShowConfirmationModal(true)
    }

    const onAutoOrthoClear = () => {
        setConfirmationMessage('You are about to clear orthopedic note values, please confirm.')
        setAccordionID(17)
        setShowConfirmationModal(true)
    }

    const onAutoHepaticClear = () => {
        setConfirmationMessage('You are about to clear hepatic note values, please confirm.')
        setAccordionID(18)
        setShowConfirmationModal(true)
    }

    const onAutoNeuroClear = () => {
        setConfirmationMessage('You are about to clear neuro note values, please confirm.')
        setAccordionID(19)
        setShowConfirmationModal(true)
    }

    const onAutoTraumaClear = () => {
        setConfirmationMessage('You are about to clear trauma note values, please confirm.')
        setAccordionID(20)
        setShowConfirmationModal(true)
    }

    const onAutoOtherClear = () => {
        setConfirmationMessage('You are about to clear other note values, please confirm.')
        setAccordionID(21)
        setShowConfirmationModal(true)
    }

    const onConfirm = () => {
        if (!isSaving) {
            setIsSaving(true)
            if (accordionID == 10) {
                let data = {
                    'anticoag': null,
                    'other_coag': null,
                    'amount': null,
                    'vacuum_level': null,
                    'pre_hematocrit': null,
                    'pre_hemoglobin': null
                }
                API.query('PATCH', `/api/autodetails/${autoDetail.id}/`,{}, data,function(resp) {
                    setAutoDetail(resp)
                    setAccordionID(0)
                    setConfirmationMessage('')
                    setShowConfirmationModal(false)
                    autoMiddleRef.current.reset()
                    setAutoMiddleValues({
                        anticoag: null,
                        other_coag: null,
                        amount: null,
                        vacuum_level: null,
                        pre_hematocrit: null,
                        pre_hemoglobin: null,
                    })
                    setIsSaving(false)                    
                }, onDataLoadError)
            } else if (accordionID == 14) {
                let data = {
                    'start_date': null,
                    'end_date': null,
                    'start_time': null,
                    'end_time': null,
                    'or_number': null
                }
                API.query('PATCH', `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                    setAutoDetail(resp)
                    setShowConfirmationModal(false)
                    setAccordionID(0)
                    setConfirmationMessage('')
                    autoTopRef.current.reset()
                    setAutoTopValues({ start_date: null, end_date: null, start_time: null, end_time: null, or_number: null })
                    setIsSaving(false)
                }, onDataLoadError)
            } else if (accordionID == 15) {
                let data = {
                    'cardiac_note': null
                }
                API.query('PATCH', `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                    setAutoDetail(resp)
                    setShowConfirmationModal(false)
                    setAccordionID(0)
                    setConfirmationMessage('')
                    setAutoCardiacValues({ note: '' })
                    setIsSaving(false)
                }, onDataLoadError)
            } else if (accordionID == 16) {
                let data = {
                    'vascular_note': null
                }
                API.query('PATCH', `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                    setAutoDetail(resp)
                    setShowConfirmationModal(false)
                    setAccordionID(0)
                    setConfirmationMessage('')
                    setAutoVascularValues({ note: '' })
                    setIsSaving(false)
                }, onDataLoadError)
            } else if (accordionID == 17) {
                let data = {
                    'ortho_note': null
                }
                API.query('PATCH', `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                    setAutoDetail(resp)
                    setShowConfirmationModal(false)
                    setAccordionID(0)
                    setConfirmationMessage('')
                    setAutoOrthoValues({ note: '' })
                    setIsSaving(false)
                }, onDataLoadError)
            } else if (accordionID == 18) {
                let data = {
                    'hepatic_note': null
                }
                API.query('PATCH', `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                    setAutoDetail(resp)
                    setShowConfirmationModal(false)
                    setAccordionID(0)
                    setConfirmationMessage('')
                    setAutoHepaticValues({ note: '' })
                    setIsSaving(false)
                }, onDataLoadError)
            } else if (accordionID == 19) {
                let data = {
                    'neuro_note': null
                }
                API.query('PATCH', `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                    setAutoDetail(resp)
                    setShowConfirmationModal(false)
                    setAccordionID(0)
                    setConfirmationMessage('')
                    setAutoNeuroValues({ note: '' })
                    setIsSaving(false)
                }, onDataLoadError)
            } else if (accordionID == 20) {
                let data = {
                    'trauma_note': null
                }
                API.query('PATCH', `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                    setAutoDetail(resp)
                    setShowConfirmationModal(false)
                    setAccordionID(0)
                    setConfirmationMessage('')
                    setAutoTraumaValues({ note: '' })
                    setIsSaving(false)
                }, onDataLoadError)
            } else if (accordionID == 21) {
                let data = {
                    'other_note': null
                }
                API.query('PATCH', `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                    setAutoDetail(resp)
                    setShowConfirmationModal(false)
                    setAccordionID(0)
                    setConfirmationMessage('')
                    setAutoOtherValues({ note: '' })
                    setIsSaving(false)
                }, onDataLoadError)
            } else if (accordionID == 22) {
                var i
                for(i=0; i < volumes.length; i++) {
                    if(volumes[i].id != null) {
                        API.query('DELETE',`/api/autovolumes/${volumes[i].id}/`,{},{},function(resp) {},()=> props.setErrorMessage('Failed to delete volume.'))
                    }
                }

                let data = {
                    'volumes': [],
                    'other_loss': null,
                    'total_vol_collected': null,
                    'total_irrigation': null,
                    'total_anticoag': null,
                    'total_wash': null,
                    'total_vol_returned': null,
                    'gross_ebl': null,
                    'net_ebl': null
                }
                API.query('PATCH',`/api/autodetails/${autoDetail.id}/`,{},data,function(resp) {
                    setAutoDetail(resp)
                }, () => props.setErrorMessage('Failed to patch auto detail.'))

                setVolumeCount(0)
                setVolumes([])
                setTotalVolumes([{
                    vol_collected: null,
                    irrigation: null,
                    anticoag: null,
                    wash: null,
                    vol_returned: null,
                    gross_ebl: null,
                    net_ebl: null
                }])
                setShowConfirmationModal(false)
                setAccordionID()
                setOtherLoss(0)
                setConfirmationMessage('')
                setIsSaving(false)
                bloodRef.current.reset()
            }
        }
    }

    const onAutoMiddleSave = () => {
        if(!isSaving) {
            setIsSaving(true)
            let data = {
                'anticoag': autoMiddleValues.anticoag,
                'other_coag': autoMiddleValues.other_coag,
                'amount': autoMiddleValues.amount,
                'vacuum_level': autoMiddleValues.vacuum_level,
                'pre_hematocrit': autoMiddleValues.pre_hematocrit,
                'pre_hemoglobin': autoMiddleValues.pre_hemoglobin
            }
            API.query('PATCH',`/api/autodetails/${autoDetail.id}/`, {}, data, function(resp) {
                setAutoDetail(resp)
                setIsSaving(false)
            },onDataLoadError)
        }
    }

    const onAutoTopSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'start_date': autoTopValues.start_date,
                'end_date': autoTopValues.end_date,
                'start_time': autoTopValues.start_time,
                'end_time': autoTopValues.end_time,
                'or_number': autoTopValues.or_number
            }
            API.query("PATCH", `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                setAutoDetail(resp)
                setIsSaving(false)
            }, onDataLoadError)
        }
    }

    const onAutoCardiacSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'cardiac_note': autoCardiacValues.note,
                'cardiac_checked': autoCardiacValues.checked
            }
            API.query("PATCH", `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                setAutoDetail(resp)
                setIsSaving(false)
            }, onDataLoadError)
        }
    }

    const onAutoVascularSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'vascular_note': autoVascularValues.note,
                'vascular_checked': autoVascularValues.checked
            }
            API.query("PATCH", `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                setAutoDetail(resp)
                setIsSaving(false)
            }, onDataLoadError)
        }
    }

    const onAutoOrthoSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'ortho_note': autoOrthoValues.note,
                'ortho_checked': autoOrthoValues.checked
            }
            API.query("PATCH", `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                setAutoDetail(resp)
                setIsSaving(false)
            }, onDataLoadError)
        }
    }

    const onAutoHepaticSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'hepatic_note': autoHepaticValues.note,
                'hepatic_checked': autoHepaticValues.checked
            }
            API.query("PATCH", `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                setAutoDetail(resp)
                setIsSaving(false)
            }, onDataLoadError)
        }
    }

    const onAutoNeuroSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'neuro_note': autoNeuroValues.note,
                'neuro_checked': autoNeuroValues.checked
            }
            API.query("PATCH", `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                setAutoDetail(resp)
                setIsSaving(false)
            }, onDataLoadError)
        }
    }

    const onAutoTraumaSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'trauma_note': autoTraumaValues.note,
                'trauma_checked': autoTraumaValues.checked
            }
            API.query("PATCH", `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                setAutoDetail(resp)
                setIsSaving(false)
            }, onDataLoadError)
        }
    }

    const onAutoOtherSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'other_note': autoOtherValues.note,
                'other_checked': autoOtherValues.checked
            }
            API.query("PATCH", `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                setAutoDetail(resp)
                setIsSaving(false)
            }, onDataLoadError)
        }
    }

    const onCountChange = (value) => {
        setVolumeCount(value)
    }

    const onTimeChange = (rowID, value) => {
        var newArr = [...volumes]
        let changedIndex = newArr.findIndex((e) => rowID == e.rowID)
        let changed = newArr.find((e) => rowID == e.rowID)
        changed['time'] = value
        newArr[changedIndex] = changed
        setVolumes(newArr)
    }

    const onCollectedChange = (rowID, value) => {
        var newArr = [...volumes]
        let changedIndex = newArr.findIndex((e) => rowID == e.rowID)
        let changed = newArr.find((e) => rowID == e.rowID)
        var gross_ebl = isNaN(changed['gross_ebl']) ? 0 : changed['gross_ebl']
        var net_ebl = isNaN(changed['net_ebl']) ? 0 : changed['net_ebl']
        var difference = (isNaN(parseInt(value)) ? 0 : parseInt(value)) - (isNaN(changed['vol_collected']) ? 0 : changed['vol_collected'])
        changed['gross_ebl'] = gross_ebl + difference
        changed['net_ebl'] = net_ebl + difference
        changed['vol_collected'] = isNaN(parseInt(value)) ? 0 : parseInt(value)
        newArr[changedIndex] = changed
        setVolumes(newArr)

        var i, collected_total = 0, net_total = 0, gross_total = 0
        for (i = 0; i < newArr.length; i++) {
            let vol_collected = parseInt(newArr[i].vol_collected)
            let net_ebl = parseInt(newArr[i].net_ebl)
            let gross_ebl = parseInt(newArr[i].gross_ebl)
            if (!isNaN(vol_collected)) {
                collected_total = parseInt(collected_total) + vol_collected
            }
            if (!isNaN(net_ebl)) {
                net_total = parseInt(net_total) + net_ebl
            }
            if (!isNaN(gross_ebl)) {
                gross_total = parseInt(gross_total) + gross_ebl
            }
        }

        var totalArr = [...totalVolumes]
        totalArr[0].vol_collected = collected_total
        totalArr[0].net_ebl = net_total
        totalArr[0].gross_ebl = gross_total
        setTotalVolumes(totalArr)
    }

    const onIrrigationChange = (rowID, value) => {
        var newArr = [...volumes]
        let changedIndex = newArr.findIndex((e) => rowID == e.rowID)
        let changed = newArr.find((e) => rowID == e.rowID)
        var gross_ebl = isNaN(changed['gross_ebl']) ? 0 : changed['gross_ebl']
        var net_ebl = isNaN(changed['net_ebl']) ? 0 : changed['net_ebl']
        var difference = (isNaN(parseInt(value)) ? 0 : parseInt(value)) - (isNaN(changed['irrigation']) ? 0 : changed['irrigation'])
        changed['gross_ebl'] = gross_ebl - difference
        changed['net_ebl'] = net_ebl - difference
        changed['irrigation'] = isNaN(parseInt(value)) ? 0 : parseInt(value)
        newArr[changedIndex] = changed
        setVolumes(newArr)

        var i, irrigation_total = 0, net_total = 0, gross_total = 0
        for (i = 0; i < newArr.length; i++) {
            let irrigation = parseInt(newArr[i].irrigation)
            let net_ebl = parseInt(newArr[i].net_ebl)
            let gross_ebl = parseInt(newArr[i].gross_ebl)
            if (!isNaN(irrigation)) {
                irrigation_total = parseInt(irrigation_total) + irrigation
            }
            if (!isNaN(net_ebl)) {
                net_total = parseInt(net_total) + net_ebl
            }
            if (!isNaN(gross_ebl)) {
                gross_total = parseInt(gross_total) + gross_ebl
            }
        }

        var totalArr = [...totalVolumes]
        totalArr[0].irrigation = irrigation_total
        totalArr[0].net_ebl = net_total
        totalArr[0].gross_ebl = gross_total
        setTotalVolumes(totalArr)
    }

    const onAnticoagChange = (rowID, value) => {
        var newArr = [...volumes]
        let changedIndex = newArr.findIndex((e) => rowID == e.rowID)
        let changed = newArr.find((e) => rowID == e.rowID)
        var gross_ebl = isNaN(changed['gross_ebl']) ? 0 : changed['gross_ebl']
        var net_ebl = isNaN(changed['net_ebl']) ? 0 : changed['net_ebl']
        var difference = (isNaN(parseInt(value)) ? 0 : parseInt(value)) - (isNaN(changed['anticoag']) ? 0 : changed['anticoag'])
        changed['gross_ebl'] = gross_ebl - difference
        changed['net_ebl'] = net_ebl - difference
        changed['anticoag'] = isNaN(parseInt(value)) ? 0 : parseInt(value)
        newArr[changedIndex] = changed
        setVolumes(newArr)

        var i, anticoag_total = 0, net_total = 0, gross_total = 0
        for (i = 0; i < newArr.length; i++) {
            let anticoag = parseInt(newArr[i].anticoag)
            let net_ebl = parseInt(newArr[i].net_ebl)
            let gross_ebl = parseInt(newArr[i].gross_ebl)
            if (!isNaN(anticoag)) {
                anticoag_total = parseInt(anticoag_total) + anticoag
            }
            if (!isNaN(net_ebl)) {
                net_total = parseInt(net_total) + net_ebl
            }
            if (!isNaN(gross_ebl)) {
                gross_total = parseInt(gross_total) + gross_ebl
            }
        }

        var totalArr = [...totalVolumes]
        totalArr[0].anticoag = anticoag_total
        totalArr[0].net_ebl = net_total
        totalArr[0].gross_ebl = gross_total
        setTotalVolumes(totalArr)
    }

    const onWashChange = (rowID, value) => {
        var newArr = [...volumes]
        let changedIndex = newArr.findIndex((e) => rowID == e.rowID)
        let changed = newArr.find((e) => rowID == e.rowID)
        changed['wash'] = isNaN(parseInt(value)) ? 0 : parseInt(value)
        newArr[changedIndex] = changed
        setVolumes(newArr)

        var i, wash_total = 0
        for (i = 0; i < newArr.length; i++) {
            let wash = parseInt(newArr[i].wash)
            if (!isNaN(wash)) {
                wash_total = wash_total + wash
            }
        }

        var totalArr = [...totalVolumes]
        totalArr[0].wash = wash_total
        setTotalVolumes(totalArr)
    }

    const onReturnChange = (rowID, value) => {
        var newArr = [...volumes]
        let changedIndex = newArr.findIndex((e) => rowID == e.rowID)
        let changed = newArr.find((e) => rowID == e.rowID)
        var net_ebl = isNaN(changed['net_ebl']) ? 0 : changed['net_ebl']
        var difference = (isNaN(parseInt(value)) ? 0 : parseInt(value)) - (isNaN(changed['vol_returned']) ? 0 : changed['vol_returned'])
        changed['net_ebl'] = net_ebl - difference
        changed['vol_returned'] = isNaN(parseInt(value)) ? 0 : parseInt(value)
        newArr[changedIndex] = changed
        setVolumes(newArr)

        var i, returned_total = 0, net_total = 0
        for (i = 0; i < newArr.length; i++) {
            let vol_returned = parseInt(newArr[i].vol_returned)
            let net_ebl = parseInt(newArr[i].net_ebl)
            if (!isNaN(vol_returned)) {
                returned_total = parseInt(returned_total) + vol_returned
            }
            if (!isNaN(net_ebl)) {
                net_total = parseInt(net_total) + net_ebl
            }
        }

        var totalArr = [...totalVolumes]
        totalArr[0].vol_returned = returned_total
        totalArr[0].net_ebl = net_total
        setTotalVolumes(totalArr)
    }

    const onOtherLossChange = (value) => {
        var totalArr = [...totalVolumes]
        setOtherLoss(value)

        if (!isNaN(parseInt(value))) {
            if(volumes && volumes.length > 0) {
                let net_ebl = 0
                let gross_ebl = 0
                for(let i=0;i<volumes.length;i++) {
                    if(!isNaN(volumes[i].net_ebl)) {
                        net_ebl += volumes[i].net_ebl
                    }
                    if(!isNaN(volumes[i].gross_ebl)) {
                        gross_ebl += volumes[i].gross_ebl
                    }
                }
                totalArr[0].net_ebl = net_ebl + parseInt(value)
                totalArr[0].gross_ebl = gross_ebl + parseInt(value)
            } else {
                totalArr[0].net_ebl = parseInt(value)
                totalArr[0].gross_ebl = parseInt(value)
            }
        } else {
            if(volumes && volumes.length > 0) {
                let net_ebl = 0
                let gross_ebl = 0
                for(let i=0;i<volumes.length;i++) {
                    if(!isNaN(volumes[i].net_ebl)) {
                        net_ebl += volumes[i].net_ebl
                    }
                    if(!isNaN(volumes[i].gross_ebl)) {
                        gross_ebl += volumes[i].gross_ebl
                    }
                }
                totalArr[0].net_ebl = net_ebl
                totalArr[0].gross_ebl = gross_ebl
            } else {
                totalArr[0].net_ebl = null
                totalArr[0].gross_ebl = null
            }
        }

        setTotalVolumes(totalArr)
    }

    const resetTotals = (newVolumes) => {
        if(newVolumes && newVolumes.length > 0) {
            let vol_collected = 0
            let irrigation = 0
            let anticoag = 0
            let wash = 0
            let vol_returned = 0
            let gross_ebl = 0
            let net_ebl = 0

            for(let i=0;i<newVolumes.length;i++) {
                if(!isNaN(newVolumes[i].vol_collected)) {
                    vol_collected += newVolumes[i].vol_collected
                }

                if(!isNaN(newVolumes[i].irrigation)) {
                    irrigation += newVolumes[i].irrigation
                }

                if(!isNaN(newVolumes[i].anticoag)) {
                    anticoag += newVolumes[i].anticoag
                }

                if(!isNaN(newVolumes[i].wash)) {
                    wash += newVolumes[i].wash
                }

                if(!isNaN(newVolumes[i].vol_returned)) {
                    vol_returned += newVolumes[i].vol_returned
                }

                if(!isNaN(newVolumes[i].gross_ebl)) {
                    gross_ebl += newVolumes[i].gross_ebl
                }

                if(!isNaN(newVolumes[i].net_ebl)) {
                    net_ebl += newVolumes[i].net_ebl
                }
            }

            if(otherLoss > 0) {
                net_ebl = net_ebl + parseInt(otherLoss)
                gross_ebl = gross_ebl + parseInt(otherLoss)
            }

            setTotalVolumes([{
                vol_collected: vol_collected,
                irrigation: irrigation,
                anticoag: anticoag,
                wash: wash,
                vol_returned: vol_returned,
                gross_ebl: gross_ebl,
                net_ebl: net_ebl
            }])
        } else {
            if(otherLoss > 0) {
                setTotalVolumes([{
                    vol_collected: null,
                    irrigation: null,
                    anticoag: null,
                    wash: null,
                    vol_returned: null,
                    gross_ebl: parseInt(otherLoss),
                    net_ebl: parseInt(otherLoss)
                }])
            } else {
                setTotalVolumes([{
                    vol_collected: null,
                    irrigation: null,
                    anticoag: null,
                    wash: null,
                    vol_returned: null,
                    gross_ebl: null,
                    net_ebl: null
                }])
            }
        }
    }

    const onBloodDeleteClicked = (row) => {
        if(row.id) {
            if(!isSaving) {
                setIsSaving(true)
                let filteredVolumes = [...volumes].filter(j => j.id !== row.id)
                let newIds = filteredVolumes.map((i) => {
                    if(i.id && i.id != null) {
                        return i.id
                    }
                })
                let newVolumes = filteredVolumes.map((e,idx) => {
                    e.rowID = idx + 1
                    e.label = `#${idx + 1}`
                    return e
                })
                setVolumes(newVolumes)
                resetTotals(newVolumes)
                API.query('DELETE', `/api/autovolumes/${row.id}/`, {}, {}, function (resp) {
                    let data = {
                        "volumes": newIds
                    }
                    API.query('PATCH', `/api/autodetails/${autoDetail.id}/`, {}, data, function (resp) {
                        setAutoDetail(resp)
                        setIsSaving(false)
                    }, onDataLoadError)
                }, onDataLoadError)
            }
        } else {
            let filteredVolumes = volumes.filter(j => j.rowID !== row.rowID)
            let newVolumes = filteredVolumes.map((e,idx) => {
                e.rowID = idx + 1
                e.label = `#${idx + 1}`
                return e
            })
            setVolumes(newVolumes)
            resetTotals(newVolumes)
        }
    }

    const columns = useMemo(() => [
        { name: '', width: "50px", selector: row => <label className="has-text-weight-semibold">{row.label}</label> },
        {
            name: 'Time', width: "120px", selector: row => <div>
                <DatePicker
                    className="input"
                    showTimeSelectOnly={true}
                    timeCaption="Time"
                    dateFormat={formatString}
                    selected={parseDate(row.time, formatString)}
                    onChange={(date) => onTimeChange(row.rowID, formatDate(date, formatString))}
                />
            </div>
        },
        {
            name: 'Vol. Collected (ml)', selector: row => <div>
                <input
                    defaultValue={row.vol_collected}
                    placeholder={row.vol_collected}
                    className="input"
                    type="number"
                    onChange={(e) => onCollectedChange(row.rowID, e.target.value)}
                />
            </div>
        },
        {
            name: 'Irrigation Amnt (ml)', selector: row => <div>
                <input
                    defaultValue={row.irrigation}
                    placeholder={row.irrigation}
                    className="input"
                    type="number"
                    onChange={(e) => onIrrigationChange(row.rowID, e.target.value)}
                />
            </div>
        },
        {
            name: 'Anticoag Amnt (ml)', selector: row => <div>
                <input
                    defaultValue={row.anticoag}
                    placeholder={row.anticoag}
                    className="input"
                    type="number"
                    onChange={(e) => onAnticoagChange(row.rowID, e.target.value)}
                />
            </div>
        },
        {
            name: 'Wash Amnt (ml)', selector: row => <div>
                <input
                    defaultValue={row.wash}
                    placeholder={row.wash}
                    className="input"
                    type="number"
                    onChange={(e) => onWashChange(row.rowID, e.target.value)}
                />
            </div>
        },
        {
            name: 'Vol. Returned (ml)', selector: row => <div>
                <input
                    defaultValue={row.vol_returned}
                    placeholder={row.vol_returned}
                    className="input"
                    type="number"
                    onChange={(e) => onReturnChange(row.rowID, e.target.value)}
                />
            </div>
        },
        {
            name: 'Gross EBL (ml)', selector: row => <div>
                <input
                    placeholder={row.gross_ebl}
                    className="input"
                    type="number"
                    disabled={true}
                />
            </div>
        },
        {
            name: 'NET EBL (ml)', selector: row => <div>
                <input
                    placeholder={row.net_ebl}
                    className="input"
                    type="number"
                    disabled={true}
                />
            </div>
        },
        {
            name: 'Actions', selector: row => <div>
                <a className="ml-3" title="Delete" onClick={() => onBloodDeleteClicked(row)}><FaTrashAlt size={14} /></a>
            </div>
        }
    ])

    const totalColumns = useMemo(() => [
        {
            name: 'Other Loss (ml)', selector: row => <div>
                <input
                    defaultValue={otherLoss}
                    placeholder={otherLoss}
                    className="input"
                    type="number"
                    onChange={(e) => onOtherLossChange(e.target.value)}
                />
            </div>
        },
        {
            name: 'Vol. Collected (ml)', selector: row => <div>
                <input
                    placeholder={row.vol_collected}
                    className="input"
                    type="number"
                    disabled={true}
                />
            </div>
        },
        {
            name: 'Irrigation Amnt (ml)', selector: row => <div>
                <input
                    placeholder={row.irrigation}
                    className="input"
                    type="number"
                    disabled={true}
                />
            </div>
        },
        {
            name: 'Anticoag Amnt (ml)', selector: row => <div>
                <input
                    placeholder={row.anticoag}
                    className="input"
                    type="number"
                    disabled={true}
                />
            </div>
        },
        {
            name: 'Wash Amnt (ml)', selector: row => <div>
                <input
                    placeholder={row.wash}
                    className="input"
                    type="number"
                    disabled={true}
                />
            </div>
        },
        {
            name: 'Vol. Returned (ml)', selector: row => <div>
                <input
                    placeholder={row.vol_returned}
                    className="input"
                    type="number"
                    disabled={true}
                />
            </div>
        },
        {
            name: 'Gross EBL (ml)', selector: row => <div>
                <input
                    placeholder={row.gross_ebl}
                    className="input"
                    type="number"
                    disabled={true}
                />
            </div>
        },
        {
            name: 'NET EBL (ml)', selector: row => <div>
                <input
                    placeholder={row.net_ebl}
                    className="input"
                    type="number"
                    disabled={true}
                />
            </div>
        }
    ])

    const onBloodSave = () => {
        if(!isSaving) {
            setIsSaving(true)
            let newVolumes = [...volumes]
            if(newVolumes && newVolumes.length > 0) {
                for(let i=0; i < newVolumes.length; i++) {
                    const isNew = newVolumes[i].id ? false : true
                    let data = {
                        'time': newVolumes[i].time,
                        'vol_collected': newVolumes[i].vol_collected,
                        'irrigation': newVolumes[i].irrigation,
                        'anticoag': newVolumes[i].anticoag,
                        'wash': newVolumes[i].wash,
                        'vol_returned': newVolumes[i].vol_returned,
                        'net_ebl': newVolumes[i].net_ebl,
                        'gross_ebl': newVolumes[i].gross_ebl,
                        'autoDetailID': autoDetail.id,
                        'other_loss': otherLoss,
                        'total_vol_collected': totalVolumes[0].vol_collected,
                        'total_irrigation': totalVolumes[0].irrigation,
                        'total_anticoag': totalVolumes[0].anticoag,
                        'total_wash': totalVolumes[0].wash,
                        'total_vol_returned': totalVolumes[0].vol_returned,
                        'total_gross_ebl': totalVolumes[0].gross_ebl,
                        'total_net_ebl': totalVolumes[0].net_ebl
                    }
                    let uri = isNew ? '/api/autovolumes/' : `/api/autovolumes/${newVolumes[i].id}/`
                    let method = isNew ? 'POST' : 'PATCH'
                    API.query(method,uri,{},data,function(resp) {
                        newVolumes[i] = {
                            ...resp,
                            'rowID': i + 1,
                            'label': `#${i + 1}`
                        }
                    },onDataLoadError)
                }
                API.query('GET', `/api/autodetails/${autoDetail.id}/`, {}, {}, function (resp) {
                    setAutoDetail(resp)
                    setVolumes(newVolumes)
                    setIsSaving(false)
                }, onDataLoadError)
            } else {
                setIsSaving(false)
            }
        }
    }

    const onBloodClear = () => {
        setAccordionID(22)
        setShowConfirmationModal(true)
        setConfirmationMessage('You are about to clear autotransfusion blood values. Please confirm.')
    }

    const addUnit = () => {
        const newVolumes = [...volumes]
        let label = `#${newVolumes.length + 1}`
        newVolumes.push({
            label: label,
            rowID: newVolumes.length + 1,
            time: null,
            vol_collected: null,
            irrigation: null,
            anticoag: null,
            wash: null,
            vol_returned: null,
            net_ebl: null,
            gross_ebl: null
        })
        setVolumes(newVolumes)
    }

    return <div>
        <form ref={autoTopRef}>
            <DynamicForm
                key={101}
                form={autoTopForm}
                values={autoTopValues}
                onValuesChanged={onAutoTopChanged}
            />
        </form>

        <hr />

        <div className="level w-100">
            <div className="level-left">
                <button className="button is-danger is-inverted" onClick={onAutoTopClear}>Clear</button>
            </div>
            <div className="level-right">
                <button className={saveBtnClasses} onClick={onAutoTopSave}>Save</button>
            </div>
        </div>

        <hr />

        <form ref={autoMiddleRef}>
            <DynamicForm
                key={109}
                form={autoMiddleForm}
                values={autoMiddleValues}
                onValuesChanged={onAutoMiddleChanged}
            />
        </form>

        <hr />

        <div className="level w-100">
            <div className="level-left">
                <button className="button is-danger is-inverted" onClick={onAutoMiddleClear}>Clear</button>
            </div>
            <div className="level-right">
                <button className={saveBtnClasses} onClick={onAutoMiddleSave}>Save</button>
            </div>
        </div>

        <hr />

        <Accordion allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Blood Collected
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className="level w-100">
                        <div className="level-left">
                            <label className="label">Number of Units</label>
                            <div style={{marginLeft:"8px"}} className="control">
                                <form ref={bloodRef}>
                                    <input
                                        className="input"
                                        type="number"
                                        defaultValue={volumeCount}
                                        onChange={(e) => onCountChange(e.target.value)}
                                    />
                                </form>
                            </div>
                        </div>
                        <div className="level-right">
                            <button style={{ backgroundColor: '#1C2944', color: '#FFFFFF' }} onClick={addUnit} className="button">Add Unit</button>
                        </div>
                    </div>

                    <hr />

                    <form ref={bloodRef}>
                        <DataTable
                            striped
                            columns={columns}
                            data={volumes}
                        />

                        <hr />

                        <label className="label">Totals</label>

                        <hr />

                        <DataTable
                            striped
                            columns={totalColumns}
                            data={totalVolumes}
                        />
                    </form>

                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onBloodClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onBloodSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Cardiac
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <DynamicForm
                        key={102}
                        form={autoNoteForm}
                        values={autoCardiacValues}
                        onValuesChanged={onAutoCardiacChanged}
                    />
                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onAutoCardiacClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onAutoCardiacSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Vascular
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <DynamicForm
                        key={103}
                        form={autoNoteForm}
                        values={autoVascularValues}
                        onValuesChanged={onAutoVascularChanged}
                    />
                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onAutoVascularClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onAutoVascularSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Orthopedic
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <DynamicForm
                        key={104}
                        form={autoNoteForm}
                        values={autoOrthoValues}
                        onValuesChanged={onAutoOrthoChanged}
                    />
                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onAutoOrthoClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onAutoOrthoSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Hepatic
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <DynamicForm
                        key={105}
                        form={autoNoteForm}
                        values={autoHepaticValues}
                        onValuesChanged={onAutoHepaticChanged}
                    />
                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onAutoHepaticClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onAutoHepaticSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Neuro
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <DynamicForm
                        key={106}
                        form={autoNoteForm}
                        values={autoNeuroValues}
                        onValuesChanged={onAutoNeuroChanged}
                    />
                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onAutoNeuroClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onAutoNeuroSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Trauma
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <DynamicForm
                        key={107}
                        form={autoNoteForm}
                        values={autoTraumaValues}
                        onValuesChanged={onAutoTraumaChanged}
                    />
                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onAutoTraumaClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onAutoTraumaSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Other
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <DynamicForm
                        key={108}
                        form={autoNoteForm}
                        values={autoOtherValues}
                        onValuesChanged={onAutoOtherChanged}
                    />
                    <hr />

                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={onAutoOtherClear}>Clear</button>
                        </div>
                        <div className="level-right">
                            <button className={saveBtnClasses} onClick={onAutoOtherSave}>Save</button>
                        </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>

        {
            showConfirmationModal
            &&
            <ConfirmationModal
                message={confirmationMessage}
                onConfirm={onConfirm}
                onClose={() => setShowConfirmationModal(false)}
            />
        }

        {
            showErrorModal
            &&
            <ErrorModal
                message={errorMessage}
                onClose={() => setShowErrorModal(false)}
            />
        }
    </div>
}

export default DetailAuto