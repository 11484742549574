import React, { useEffect, useState } from "react";

import { FaHeartbeat, FaCheck } from "react-icons/fa";
import API from "../../../../services/API";

const DEFAULT_BORDER_COLOR = "#DFDFDF"
const DEFAULT_COLOR = "#5F6D85"
const SELECTED_COLOR = "#ED1E24"

function Procedure(props){
  const [procedures,setProcedures] = useState(null)
  const [procIDs, setProcIDs] = useState([...props.activeCase.procedure_id])

  useEffect(() => {
    API.query('GET','/api/procedures/',{},{},(resp) => {
      setProcedures(resp)
    })
  },[])

  const setProcedure = (procedure) => {
    // TODO: warn user when changing existing procedure
    if(!props.isSaving) {
      let newids = [...procIDs]
      newids.push(procedure.id)
      setProcIDs(newids)
      props.updateActiveCase({procedure})
    }
  }

  const removeProcedure = (procedure) => {
    if(!props.isSaving) {
      let newids = [...procIDs]
      let index = newids.indexOf(procedure.id)
      if(index !== -1) {
        newids.splice(index, 1)
      }
      setProcIDs(newids)
      props.updateActiveCase({remove_procedure: procedure})
    }
  }

  return <div>
    <p className="title">Select Procedure</p>
    <hr/>
    <div className="columns is-multiline">
      {
        procedures === null
        ?
        <p>Loading procedures...</p>
        :
        procedures.map((procedure,i) => {
          const IconComponent = procedure.icon ?? FaHeartbeat
          //const isSelected = (props.activeCase.procedure?.id === procedure.id)
          const isSelected = (procIDs.includes(procedure.id))?true:false
          const borderColor = isSelected ? SELECTED_COLOR : DEFAULT_BORDER_COLOR
          const color = isSelected ? SELECTED_COLOR : DEFAULT_COLOR
          const columnSize = procedure.name.length > 8 ? "is-one-fourth is-one-fourth-widescreen is-one-fifth-fullhd" : "is-one-sixth"

          return <div key={procedure.name} className={`column ${columnSize}`}>
            <div className="box has-text-centered is-clickable" onClick={() => isSelected ? removeProcedure(procedure) : setProcedure(procedure)} style={{
              border: "8px solid " + borderColor,
              position: 'relative',
            }}>
              {/* Corner */}
              <div style={{
                borderStyle: "solid",
                borderWidth: "0 0 60px 60px",
                borderColor: "transparent transparent transparent " + borderColor,
                position: 'absolute',
                top: 0,
                left: 0
              }} />

              {/* Show checkmark or white corner to give illusion to transparency */}
              {
                isSelected
                ?
                <FaCheck size={30} color="#fff" style={{
                  position: 'absolute',
                  top: 3,
                  left: 3
                }} />
                :
                <div style={{
                  borderStyle: "solid",
                  borderWidth: "0 0 50px 50px",
                  borderColor: "transparent transparent transparent #fff",
                  position: 'absolute',
                  top: 0,
                  left: 0
                }} />
              }

              <IconComponent size={75} color={color} />
              <p className={`title ${columnSize}`} style={{color:color,whiteSpace: 'nowrap'}}>{procedure.name}</p>
            </div>
            
          </div>
        })
      }
    </div>
  </div>
}

export default Procedure