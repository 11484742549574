import React, { useEffect, useState, useMemo, useRef } from "react"
import DynamicForm from "./DynamicForm"
import API from "../../services/API"
import ConfirmationModal from "./ConfirmationModal"
import ErrorModal from "./ErrorModal"

import autoTopForm from "../forms/caseDetailsAutoTop"
import LabForm from "../forms/caseDetailsLabPOC"

function DetailPOC(props) {
    const [pocLabTopValues, setPocLabTopValues] = useState([])
    const [pocLabNoteValues, setPocLabNoteValues] = useState([])
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [accordionID, setAccordionID] = useState(0)
    const [errorMessage, setErrorMessage] = useState('')
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [pocLabDetail, setPocLabDetail] = useState([])
    
    const pocTopRef = useRef()

    let saveBtnClasses = "button is-success";
    if (isSaving) saveBtnClasses += " is-loading"

    useEffect(() => {
        API.query('GET', `/api/poclabdetails/${props.detailID}/`, {}, {}, function (resp) {
            setPocLabDetail(resp)
            setPocLabTopValues({ start_date: resp.start_date, end_date: resp.end_date, start_time: resp.start_time, end_time: resp.end_time, or_number: resp.or_number })
            setPocLabNoteValues({ case_comments: resp.case_comments, department_comments: resp.department_comments })
        }, onDataLoadError)
    },[])

    const onPocLabTopChanged = (value) => {
        setPocLabTopValues(value)
    }
    
    const onPocLabNoteChanged = (value) => {
        setPocLabNoteValues(value)
    }

    const onLabTopClear = () => {
        setConfirmationMessage('You are about to clear POC Lab values, please confirm.')
        setAccordionID(30)
        setShowConfirmationModal(true)
    }

    const onLabNoteClear = () => {
        setConfirmationMessage('You are about to clear POC Lab values, please confirm.')
        setAccordionID(31)
        setShowConfirmationModal(true)
    }

    const onLabTopSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'start_date': pocLabTopValues.start_date,
                'end_date': pocLabTopValues.end_date,
                'start_time': pocLabTopValues.start_time,
                'end_time': pocLabTopValues.end_time,
                'or_number': pocLabTopValues.or_number
            }
            API.query('PATCH', `/api/poclabdetails/${pocLabDetail.id}/`, {}, data, function (resp) {
                setPocLabDetail(resp)
                setIsSaving(false)
            }, onDataLoadError)
        }
    }

    const onLabNoteSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'case_comments': pocLabNoteValues.case_comments,
                'department_comments': pocLabNoteValues.department_comments
            }
            API.query('PATCH', `/api/poclabdetails/${pocLabDetail.id}/`, {}, data, function (resp) {
                setPocLabDetail(resp)
                setIsSaving(false)
            }, onDataLoadError)
        }
    }

    const onConfirm = () => {
        if (accordionID == 30) {
            let data = {
                'start_date': null,
                'end_date': null,
                'start_time': null,
                'end_time': null,
                'or_number': null
            }
            API.query('PATCH', `/api/poclabdetails/${pocLabDetail.id}/`, {}, data, function (resp) {
                setPocLabDetail(resp)
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                pocTopRef.current.reset()
                setPocLabTopValues({ start_date: null, end_date: null, start_time: null, end_time: null, or_number: null })
            }, onDataLoadError)
        } else if (accordionID == 31) {
            let data = {
                'case_comments': null,
                'department_comments': null
            }
            API.query('PATCH', `/api/poclabdetails/${pocLabDetail.id}/`, {}, data, function (resp) {
                setPocLabDetail(resp)
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                setPocLabNoteValues({ case_comments: '', department_comments: '' })
            }, onDataLoadError)
        }
    }

    const onDataLoadError = (error) => {
        setErrorMessage(error)
        setShowErrorModal(true)
        setIsSaving(false)
    }

    return <div>
        <form ref={pocTopRef}>
            <DynamicForm
                key={601}
                form={autoTopForm}
                values={pocLabTopValues}
                onValuesChanged={onPocLabTopChanged}
            />
        </form>


        <hr />

        <div className="level w-100">
            <div className="level-left">
                <button className="button is-danger is-inverted" onClick={onLabTopClear}>Clear</button>
            </div>
            <div className="level-right">
                <button className={saveBtnClasses} onClick={onLabTopSave}>Save</button>
            </div>
        </div>

        <hr />

        <DynamicForm
            key={602}
            form={LabForm}
            values={pocLabNoteValues}
            onValuesChanged={onPocLabNoteChanged}
        />

        <hr />

        <div className="level w-100">
            <div className="level-left">
                <button className="button is-danger is-inverted" onClick={onLabNoteClear}>Clear</button>
            </div>
            <div className="level-right">
                <button className={saveBtnClasses} onClick={onLabNoteSave}>Save</button>
            </div>
        </div>

        {
            showConfirmationModal
            &&
            <ConfirmationModal
                message={confirmationMessage}
                onConfirm={onConfirm}
                onClose={() => setShowConfirmationModal(false)}
            />
        }

        {
            showErrorModal
            &&
            <ErrorModal
                message={errorMessage}
                onClose={() => setShowErrorModal(false)}
            />
        }
    </div>
}

export default DetailPOC