import React, { useEffect, useState, useMemo, useRef } from "react"
import DynamicForm from "./DynamicForm"
import API from "../../services/API"
import ConfirmationModal from "./ConfirmationModal"
import ErrorModal from "./ErrorModal"

import ECLSForm from "../forms/caseDetailsECLS"
import autoTopForm from "../forms/caseDetailsAutoTop"

function DetailECLS(props) {
    const [eclsTopValues, setEclsTopValues] = useState([])
    const [eclsBodyValues, setEclsBodyValues] = useState([])
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [accordionID, setAccordionID] = useState()
    const [eclsDetail, setEclsDetail] = useState()

    const eclsTopRef = useRef()
    const eclsBottomRef = useRef()

    let saveBtnClasses = "button is-success";
    if (isSaving) saveBtnClasses += " is-loading"

    const onEclsTopChanged = (value) => {
        setEclsTopValues(value)
    }

    const onEclsBodyChanged = (value) => {
        setEclsBodyValues(value)
    }

    const onEclsTopSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'start_date': eclsTopValues.start_date,
                'end_date': eclsTopValues.end_date,
                'start_time': eclsTopValues.start_time,
                'end_time': eclsTopValues.end_time,
                'or_number': eclsTopValues.or_number
            }
            API.query('PATCH', `/api/eclsdetails/${eclsDetail.id}/`, {}, data, function (resp) {
                setEclsDetail(resp)
                setIsSaving(false)
            }, onDataLoadError)
        }
    }

    const onEclsBodySave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'admission_type': eclsBodyValues.admission_type,
                'cardiac_arrest': eclsBodyValues.cardiac_arrest,
                'support': eclsBodyValues.support,
                'pupil_response': eclsBodyValues.pupil_response,
                'support_type': eclsBodyValues.support_type,
                'mode': eclsBodyValues.mode,
                'outflow_cannulation': eclsBodyValues.outflow_cannulation,
                'inflow_cannulation': eclsBodyValues.inflow_cannulation,
                'mechanical_complication': eclsBodyValues.mechanical_complication,
                'hemorrhage_complication': eclsBodyValues.hemorrhage_complication,
                'neurological_complication': eclsBodyValues.neurological_complication,
                'renal_complication': eclsBodyValues.renal_complication,
                'cardio_complication': eclsBodyValues.cardio_complication,
                'pulmonary_complication': eclsBodyValues.pulmonary_complication,
                'infectious_complication': eclsBodyValues.infectious_complication,
                'metabolic_complication': eclsBodyValues.metabolic_complication,
                'limb_complication': eclsBodyValues.limb_complication,
                'transported': eclsBodyValues.transported,
                'cannula_repair': eclsBodyValues.cannula_repair,
                'discontinuing': eclsBodyValues.discontinuing,
                'withdrawn_reason': eclsBodyValues.withdrawn_reason
            }
            API.query('PATCH', `/api/eclsdetails/${eclsDetail.id}/`, {}, data, function (resp) {
                setEclsDetail(resp)
                setIsSaving(false)
            }, onDataLoadError)
        }
    }

    const onEclsTopClear = () => {
        setConfirmationMessage('You are about to clear ECLS values, please confirm.')
        setAccordionID(24)
        setShowConfirmationModal(true)
    }

    const onEclsBodyClear = () => {
        setConfirmationMessage('You are about to clear ECLS values, please confirm.')
        setAccordionID(25)
        setShowConfirmationModal(true)
    }

    useEffect(() => {
        API.query('GET', `/api/eclsdetails/${props.detailID}/`, {}, {}, function (resp) {
            setEclsDetail(resp)
            setEclsTopValues({ start_date: resp.start_date, end_date: resp.end_date, start_time: resp.start_time, end_time: resp.end_time, or_number: resp.or_number })
            setEclsBodyValues({ admission_type: resp.admission_type, cardiac_arrest: resp.cardiac_arrest, support: resp.support, pupil_response: resp.pupil_response, support_type: resp.support_type, mode: resp.mode, outflow_cannulation: resp.outflow_cannulation, inflow_cannulation: resp.inflow_cannulation, mechanical_complication: resp.mechanical_complication, hemorrhage_complication: resp.hemorrhage_complication, neurological_complication: resp.neurological_complication, renal_complication: resp.renal_complication, cardio_complication: resp.cardio_complication, pulmonary_complication: resp.pulmonary_complication, infectious_complication: resp.infectious_complication, metabolic_complication: resp.metabolic_complication, limb_complication: resp.limb_complication, transported: resp.transported, cannula_repair: resp.cannula_repair, discontinuing: resp.discontinuing, withdrawn_reason: resp.withdrawn_reason })
        }, onDataLoadError)
    }, [])

    const onDataLoadError = (error) => {
        setErrorMessage(error)
        setShowErrorModal(true)
        setIsSaving(false)
    }

    const onConfirm = () => {
        if (accordionID == 24) {
            let data = {
                'start_date': null,
                'end_date': null,
                'start_time': null,
                'end_time': null,
                'or_number': null
            }
            API.query('PATCH', `/api/eclsdetails/${eclsDetail.id}/`, {}, data, function (resp) {
                setEclsDetail(resp)
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                eclsTopRef.current.reset()
                setEclsTopValues({ start_date: null, end_date: null, start_time: null, end_time: null, or_number: null })
            }, onDataLoadError)
        } else if (accordionID == 25) {
            let data = {
                'admission_type': null,
                'cardiac_arrest': null,
                'support': null,
                'pupil_response': null,
                'support_type': null,
                'mode': null,
                'outflow_cannulation': null,
                'inflow_cannulation': null,
                'mechanical_complication': null,
                'hemorrhage_complication': null,
                'neurological_complication': null,
                'renal_complication': null,
                'cardio_complication': null,
                'pulmonary_complication': null,
                'infectious_complication': null,
                'metabolic_complication': null,
                'limb_complication': null,
                'transported': null,
                'cannula_repair': null,
                'discontinuing': null,
                'withdrawn_reason': null
            }
            API.query('PATCH', `/api/eclsdetails/${eclsDetail.id}/`, {}, data, function (resp) {
                setEclsDetail(resp)
                setShowConfirmationModal(false)
                setAccordionID(0)
                setConfirmationMessage('')
                eclsBottomRef.current.reset()
                setEclsBodyValues({ admission_type: null, cardiac_arrest: null, support: null, pupil_response: null, support_type: null, mode: null, outflow_cannulation: null, inflow_cannulation: null, mechanical_complication: null, hemorrhage_complication: null, neurological_complication: null, renal_complication: null, cardio_complication: null, pulmonary_complication: null, infectious_complication: null, metabolic_complication: null, limb_complication: null, transported: null, cannula_repair: null, discontinuing: null, withdrawn_reason: null })
            }, onDataLoadError)
        }
    }

    return <div>
        <form ref={eclsTopRef}>
            <DynamicForm
                key={301}
                form={autoTopForm}
                values={eclsTopValues}
                onValuesChanged={onEclsTopChanged}
            />
        </form>

        <hr />

        <div className="level w-100">
            <div className="level-left">
                <button className="button is-danger is-inverted" onClick={onEclsTopClear}>Clear</button>
            </div>
            <div className="level-right">
                <button className={saveBtnClasses} onClick={onEclsTopSave}>Save</button>
            </div>
        </div>

        <hr />

        <form ref={eclsBottomRef}>
            <DynamicForm
                key={302}
                form={ECLSForm}
                values={eclsBodyValues}
                onValuesChanged={onEclsBodyChanged}
            />
        </form>

        <hr />

        <div className="level w-100">
            <div className="level-left">
                <button className="button is-danger is-inverted" onClick={onEclsBodyClear}>Clear</button>
            </div>
            <div className="level-right">
                <button className={saveBtnClasses} onClick={onEclsBodySave}>Save</button>
            </div>
        </div>

        {
            showConfirmationModal
            &&
            <ConfirmationModal
                message={confirmationMessage}
                onConfirm={onConfirm}
                onClose={() => setShowConfirmationModal(false)}
            />
        }

        {
            showErrorModal
            &&
            <ErrorModal
                message={errorMessage}
                onClose={() => setShowErrorModal(false)}
            />
        }
    </div>
}

export default DetailECLS