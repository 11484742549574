import React, { useEffect, useState } from "react";
import API from "../../services/API";
import { CSVLink } from "react-csv";

function BackorderReport(props) {
    const [data, setData] = useState()
    const today = new Date()
    const statuses = ['','Saved','Submitted','On Order','Partially Received']
    const productStatuses = ['','Ordered','Transfered','Backordered']

    useEffect(() => {
        let params = {
            'type': 'backorders'
        }
        API.query('GET', 'api/orderlist/', params, {}, (resp) => {
            setData(resp)
        }, () => {
            console.log('failed loading report data')
        })
    }, [])

    const getReportData = () => {
        if (data && data.length > 0) {
            var rows = []
            rows.push(['Backorder Report','','','','','','',`Printed: ${today.toLocaleDateString()}`])
            for(let x=0; x<data.length; x++) {
                var i
                let keys = ['#','Manufacturer','Catalog Number', 'Item Category', 'Description','Quantity','Req. Ship Date','Status']
                rows.push([]) //'','','','','','',''
                rows.push(['FO Number:',`${data[x].poNumber}`])
                rows.push(['Requested Ship Date:',`${data[x].req_ship_date ? data[x].req_ship_date : ''}`])
                rows.push(['Orderer Name:',`${data[x].user_full_name}`])
                rows.push(['Facility:',`${data[x].facility_name ? data[x].facility_name : ''}`])
                rows.push(['Status:',`${statuses[data[x].status]}`])
                rows.push([])
                rows.push(keys)
                for(i=0; i < data[x].products.length; i++) {
                    rows.push([`${i+1}`,
                    `${data[x].products[i].item_manu}`,
                    `${data[x].products[i].item_catalog}`,
                    `${data[x].products[i].item_category}`,
                    `${data[x].products[i].item_description}`,
                    `${data[x].products[i].quantity}`,
                    `${data[x].products[i].req_ship_date ? data[x].products[i].req_ship_date : ''}`,
                    `${productStatuses[data[x].products[i].item_status ? data[x].products[i].item_status : 0]}`
                    ])
                }
                rows.push([])
                rows.push([])
                rows.push([])
            }
            return rows
        } else {
            return []
        }
    }

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
            <header className="modal-card-head has-background-white is-block">
                <p className="modal-card-title">Backorder Report</p>
            </header>

            <section className="modal-card-body">
                <div className="level w-100">
                    <div className="level-item"></div>
                    <div className="level-item">
                        {
                            data
                            &&
                            <CSVLink
                                filename={`backorders_${today.toLocaleDateString()}`}
                                data={getReportData()}
                            >
                                <button className="button is-success">Download</button>
                            </CSVLink>
                        }
                    </div>
                    <div className="level-item"></div>
                </div>
            </section>

            <footer className="modal-card-foot has-background-white">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="level-item button is-danger is-inverted" onClick={props.onClose}>Close</button>
                    </div>
                    <div className="level-right">
                    </div>
                </div>
            </footer>
        </div>
    </div>
}

export default BackorderReport