function rounded(value){
  return Math.round(value*100)/100
}

export const formatHeight = (caseData) => {
  if(!caseData) return null
  if(caseData.patient_height_cm) return `${caseData.patient_height_cm} cm`
  return `${caseData.patient_height_ft} ft ${caseData.patient_height_in??0} in`
}

export const formatWeight = (caseData) => {
  if(!caseData) return null
  if(caseData.patient_weight_kg) return `${caseData.patient_weight_kg} kg`
  return `${caseData.patient_weight_lb} lb ${caseData.patient_weight_oz??0} oz`
}

export const getAge = (dob) => {
  if(!dob) return ""
  const ageMs = new Date() - new Date(dob).getTime();
  const ageDate = new Date(ageMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const getWeightKg = (caseData) => {
  if(caseData) {
    if(caseData.patient_weight_kg) return caseData.patient_weight_kg
    if(caseData.patient_weight_lb) return rounded(caseData.patient_weight_lb / 2.205)
  }
  return 0
}

export const getWeightLbs = (caseData) => {
  if(caseData.patient_weight_lb) return caseData.patient_weight_lb
  if(caseData.patient_weight_kg) return rounded(caseData.patient_weight_kg * 2.205)
  return 0
}

export const getHeightCm = (caseData) => {
  if(caseData.patient_height_cm) return caseData.patient_height_cm
  return rounded(getHeightIn(caseData) * 2.54)
}

export const getHeightIn = (caseData) => {
  if(caseData.patient_height_ft || caseData.patient_height_in) return rounded((caseData.patient_height_ft ?? 0) * 12 + (caseData.patient_height_in ?? 0))
  if(caseData.patient_height_cm) return rounded(caseData.patient_height_cm / 2.54)
  return 0
}

export const calculateBSA = (caseData) => {
  if(!caseData) return 0
  // kg^0.425 × cm^0.725 × 0.007184
  return rounded(Math.pow(getWeightKg(caseData),0.428) * Math.pow(getHeightCm(caseData),0.725) * 0.007184)
}

export const calculateBMI = (caseData) => {
  // (lb/in^2)x703
  const heightIn = getHeightIn(caseData)
  if(heightIn === 0) return 0 // avoid divide by 0
  return rounded((getWeightLbs(caseData) / Math.pow(heightIn,2))*703)
}

export const calculateEBV = (caseData,patientData) => {
  // For Males = (0.006012 x in^3) + (14.6 x kg) + 604
  // For Females = (0.005835 x in^3) + (15 x kg) + 183
  //if(patientData.gender === 'male') return rounded((0.006012 * Math.pow(getHeightIn(caseData),3)) + (14.6 * getWeightKg(caseData)) + 604)
  //if(patientData.gender === 'female') return rounded((0.005835 * Math.pow(getHeightIn(caseData),3)) + (15 * getWeightKg(caseData)) + 183)
  if(patientData.gender === 'male') return rounded(getWeightKg(caseData) * 70)
  if(patientData.gender ==='female') return rounded(getWeightKg(caseData) * 65)
  return 0
}

export const calculateDO2 = (caseData) => {
  // CO x (sO2 x ceHb x 1.39) + (PaO2 x 0.03)
  // TODO
  return 0
}

export const calculateFlow = (caseData,ci) => {
  // FLOW = BSA * CI
  return rounded(calculateBSA(caseData) * ci)
}