import React, { useEffect, useState } from "react";
import ProgramSetup from "./tabs/ProgramSetup";
import ChartingDetails from "./tabs/ChartingDetails";
import ChecklistSetup from "./tabs/ChecklistSetup";
import CommsSetup from "./tabs/CommsSetup";
import EquipmentQCSetup from "./tabs/EquipmentQCSetup";
import ItemSetup from "./tabs/ItemSetup";
import ProcedureSetup from "./tabs/ProcedureSetup";
import QCSetup from "./tabs/QCSetup";
import StudyDataSetup from "./tabs/StudyDataSetup";
import ChartingSetup from "./tabs/ChartingSetup"
import useSearchParamState from "../../hooks/useSearchParamState";
import Personnel from "./tabs/Personnel";

const PERSONNEL_TAB = 1
const PROGRAM_TAB = 2
const PROCEDURE_TAB = 3
const CHECKLIST_TAB = 4
const ITEM_TAB = 5
const QC_TAB = 6
const STUDY_TAB = 7
const CHARTING_TAB = 8
const CHARTING_DETAILS_TAB = 9
const EQUIPMENT_TAB = 10
const COMMS_TAB = 11

const TABS = [
  {id:PERSONNEL_TAB,name:'Personnel Setup',component:Personnel},
  //{id:ROLE_TAB,name:'Role Setup',component:RoleSetup},
  {id:PROGRAM_TAB,name:'Program Setup',component:ProgramSetup},
  //{id:PROCEDURE_TAB,name:'Procedure Setup',component:ProcedureSetup},
  {id:CHECKLIST_TAB,name:'Checklist Setup',component:ChecklistSetup},
  {id:ITEM_TAB,name:'Item Setup',component:ItemSetup},
  {id:QC_TAB,name:'QC Setup',component:QCSetup},
  {id:STUDY_TAB,name:'Study Data Setup',component:StudyDataSetup},
  {id:CHARTING_TAB,name:'Charting Setup',component:ChartingSetup},
  {id:CHARTING_DETAILS_TAB,name:'Charting Details',component:ChartingDetails},
  {id:EQUIPMENT_TAB,name:'Equipment QC Setup',component:EquipmentQCSetup},
  //{id:COMMS_TAB,name:'Comms Setup',component:CommsSetup},
]

function Setup(props){
  const [activeTab,setActiveTab] = useSearchParamState('tab',PERSONNEL_TAB,parseInt)
  const [id, setID] = useSearchParamState('id')

  useEffect(() => {
    const menuItems = TABS.map(tab => (
      {
        title: tab.name,
        callback:() => setActiveTab(tab.id),
        active: tab.id === activeTab
      }
    ))
    props.setMenuItems(menuItems)
    
    return props.clearMenuItems
  },[activeTab])

  const updateID = (newID) => {
    setID(newID)
  }
  
  const Tab = TABS.find(tab => tab.id === activeTab)
  const TabComponent = Tab.component

  console.log("Current Tab: ",Tab.name)

  return <div className="box">
    <TabComponent 
      id={id}
      updateID={updateID}
      permissions={props.permissions}
      extraPermissions={props.extraPermissions}
    />
  </div>
}

export default Setup;