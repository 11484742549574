import React, { useEffect, useState } from "react";
import chartingMedication from  "../forms/chartingMedication";
import DynamicForm from "./DynamicForm";
import API from "../../services/API";
import ChartingDateTime from "./ChartingDateTime";
import medicationUnitNum from "../forms/chartingMedicationUnitNum";

function MedicationsModal(props) {
  const [data,setData] = useState({...props.data})
  const [time,setTime] = useState(props.time?new Date(props.time.getTime()):new Date())
  const [loading,setLoading] = useState(true)
  const [medications,setMedications] = useState(props.medications)
  const [medicationOptions,setMedicationOptions] = useState()
  const [missingFields,setMissingFields] = useState()

  const onSave = () => {
    if(data['medication']) {
      let med = medications.find(m => m.id == data.medication)
      if(med) {
        let saveData = {'medication':med,'amount':data['amount'],'unit':med.unit,'description':med.description,'unit_num':data['unit_num']}
        if(props.data) saveData.id = props.data.id
        props.onSave(saveData,time)
      }
    }else {
      let missing = []
      if(!data['medication']) missing.push('medication')
      setMissingFields(missing)
    }
  }

  const onCancel = () => {
    props.onClose()
  }

  useEffect(() => {
    if(!medications) {
      API.query('GET','api/medications/',{},{},(resp) => {
        const medData = props.filter && props.filter.length ? resp.filter((r) => props.filter.includes(r.id)) : resp
        setMedications(medData)
        setMedicationOptions(medData.map(i => ({"label": `${i.medication} (${i.unit})`, "value": i.id})))
        setLoading(false)
      },() => {
        props.setErrorMessage('Could not load medications')
      })
    }else {
      //already passed in as prop
      const medData = props.filter && props.filter.length ? medications.filter((r) => props.filter.includes(r.id)) : medications
      setMedicationOptions(medData.map(i => ({"label": `${i.medication} (${i.unit})`, "value": i.id})))
      setLoading(false)
    }
  },[])

  const getSelectedMedications = () => {
    if(medications && data && data.medication) {
      return medications.find(e => e.id === data.medication)
    } else {
      return null
    }
  }

  const selectedMedication = getSelectedMedications()
  
  let saveBtnClasses = "level-item button is-success";
  if (props.isSaving) saveBtnClasses += " is-loading"

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
    <header className="modal-card-head has-background-white is-block">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="is-size-4">Enter Meds Info</p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <ChartingDateTime 
                value={time}
                onChange={setTime}
              />
            </div>
          </div>
        </div>
      </header>

      <section className="modal-card-body" style={{paddingBottom:"250px"}}>
        {
        !loading
        &&
        <div>
          <DynamicForm 
            form={chartingMedication}
            values={data}
            onValuesChanged={setData}
            source={{"medications":medicationOptions}}
            missingFields={missingFields}
          />

          {
            selectedMedication && 
            selectedMedication.use_unit_num &&
            <div>
              <DynamicForm
                form={medicationUnitNum}
                values={data}
                onValuesChanged={setData}
              />
            </div>
          }
        </div>
        }
      </section>

      <footer className="modal-card-foot has-background-white">
        <div className="level w-100">
          <div className="level-left">
            <button className="level-item button is-danger is-inverted" onClick={onCancel}>Cancel</button>
          </div>
          <div className="level-right">
            <button className={saveBtnClasses} onClick={onSave}>Save</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
}

export default MedicationsModal