import React, { useRef, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useReactToPrint } from 'react-to-print';
import Select from 'react-select';
import DatePicker from 'react-datepicker'
import API from "../../services/API";

function PdfDisposableByPatient(props) {
    const [filters, setFilters] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [formattedData, setFormattedData] = useState()
    const [manuData,setManuData] = useState()
    const componentRef = useRef()
    const today = new Date()

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onFilterChanged = (value, type) => {
        let fValues = { ...filters }
        if (type === 0) {
            fValues['start_date'] = value
        } else if (type === 1) {
            fValues['end_date'] = value
        } else if (type === 2) {
            fValues['facility'] = value
        }
        setFilters(fValues)
    }

    const renderData = () => {
        if (filters.facility != null) {
            if(!isLoading) {
                setIsLoading(true)
                let params = {
                    'facility_id': filters.facility
                }
                API.query('GET',`/api/inventorycasereports/`,params,{},function(resp) {
                    let response = [...resp]
                    let filtered_resp = response.filter((v) => {
                        var valid = true
                        let caseDate = new Date(v.date)
                        if (filters.start_date && filters.end_date) {
                            let startDate = new Date(filters.start_date)
                            let endDate = new Date(filters.end_date)
                            if (caseDate >= startDate) {
                                if (caseDate <= endDate) { valid = true } else { valid = false }
                            } else { valid = false }
                        } else {
                            valid = true
                        }
                        return valid
                    })
                    
                    //this log and following are intentional to debug this silently failing in prod
                    console.log('filtered_resp',filtered_resp);

                    var new_data
                    let manu_data = filtered_resp.reduce((memo,e,idx) => {
                        if(!memo.init) {
                            memo = {
                                data: [],
                                init: true
                            }
                        }

                        console.log('e.items',e.items);
        
                        for(let x=0; x < e.items.length; x++) {
                            if(e.items[x].item.manufacturer_name && e.items[x].item.manufacturer_name !== "") {
                                const case_type = e.items[x].item.type
                                let case_count = 0
        
                                if(case_type === 2) {
                                    case_count = (e.items[x].amount ?? 1) * 1
                                } else {
                                    case_count = Math.ceil((e.items[x].amount ?? 1) / (e.items[x].item.quantity ?? 1))
                                }

                                const existing_manu_index = memo.data.findIndex(y => y.name === e.items[x].item.manufacturer_name)
        
                                if(existing_manu_index === -1) {
                                    memo.data.push({
                                        name: e.items[x].item.manufacturer_name,
                                        items: [{
                                            id: e.items[x].item.id,
                                            name: e.items[x].item.manufacturer_name,
                                            catalog: e.items[x].item.catalog,
                                            category: e.items[x].item.item_category_name ?? '',
                                            description: e.items[x].item.description,
                                            units: (e.items[x].amount ?? 1),
                                            case_count: case_count
                                        }]
                                    })
                                } else {
                                    //increase count
                                    const existing_item_index = memo.data[existing_manu_index].items.findIndex(z => z.id === e.items[x].item.id)
        
                                    if(existing_item_index === -1) {
                                        memo.data[existing_manu_index].items.push({
                                            id: e.items[x].item.id,
                                            name: e.items[x].item.manufacturer_name,
                                            catalog: e.items[x].item.catalog,
                                            category: e.items[x].item.item_category_name ?? '',
                                            description: e.items[x].item.description,
                                            units: (e.items[x].amount ?? 1),
                                            case_count: case_count
                                        })
                                    } else {
                                        memo.data[existing_manu_index].items[existing_item_index].units += (e.items[x].amount ?? 1)
        
                                        if(case_type === 2) {
                                            case_count = memo.data[existing_manu_index].items[existing_item_index].units * 1
                                        } else {
                                            case_count = Math.ceil(memo.data[existing_manu_index].items[existing_item_index].units / (e.items[x].item.quantity ?? 1))
                                        }
                                        memo.data[existing_manu_index].items[existing_item_index].case_count = case_count
                                    }
                                }
                            } else {
                                console.log('FAILED MANUFACTURER_NAME CHECK')
                            }
                        }
                        return memo
                    },{})

                    console.log('manu_data',manu_data);

                    if(filtered_resp.length > 1) {
                        new_data = filtered_resp.reduce(function (memo, e, idx) {
                            let index = -1
                            if (memo && memo.length > 0) {
                                index = memo.findIndex(a => a.medical_record_num == e.patient.medical_record_num)
                            } else {
                                memo = []
                            }
                    
                            if (index > -1) {
                                let array = e.items.map((y, idx) => {
                                    return {
                                        manufacturer: y.item.manufacturer_name,
                                        catalog: y.item.catalog,
                                        category: y.item.item_category_name,
                                        description: y.item.description,
                                        units: (y.amount ?? 1)
                                    }
                                })
                    
                                let origArr = [...memo[index].items]
                    
                                memo[index].items = [...origArr, ...array].sort(function (a, b) {
                                    var textA = a.manufacturer.toUpperCase();
                                    var textB = b.manufacturer.toUpperCase();
                                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                })
                            } else {
                                let array = e.items?.map((y, idx) => {                
                                    return {
                                        manufacturer: y.item.manufacturer_name,
                                        catalog: y.item.catalog,
                                        category: y.item.item_category_name,
                                        description: y.item.description,
                                        units: (y.amount ?? 1)
                                    }
                                })
                    
                                memo.push({
                                    date: e.date,
                                    medical_record_num: e.patient.medical_record_num,
                                    patient_name: `${e.patient.full_name}`,
                                    items: [...array].sort(function (a, b) {
                                        var textA = a.manufacturer.toUpperCase();
                                        var textB = b.manufacturer.toUpperCase();
                                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                    })
                                })
                            }
                            return memo
                        }, [])
                    } else if(filtered_resp.length == 1) {
                        new_data = []
                        let z = filtered_resp[0]
                        let array = z.items.map((y, idx) => {
                            return {
                                manufacturer: y.item.manufacturer_name,
                                catalog: y.item.catalog,
                                category: y.item.item_category_name,
                                description: y.item.description,
                                units: (y.amount ?? 1)
                            }
                        })
                        new_data.push({
                            date: z.date,
                            medical_record_num: z.patient.medical_record_num,
                            patient_name: `${z.patient.full_name}`,
                            items: [...array].sort(function (a, b) {
                                var textA = a.manufacturer.toUpperCase();
                                var textB = b.manufacturer.toUpperCase();
                                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                            })
                        })
                    }
                    
                    let new_arr = []
                    if(!Array.isArray(new_data)) {
                        new_arr.push(new_data)
                    } else {
                        new_arr = new_data
                    }
                    setFormattedData(new_arr)
                    setIsLoading(false)
                    setManuData(manu_data)
                },() => onDataLoadError())
            }
        }
    }

    const onDataLoadError = (error) => {
        console.log(error);
        setIsLoading(false);
    }

    const start_date = filters.start_date ? new Date(filters.start_date).toLocaleDateString() : ''
    const end_date = filters.end_date ? new Date(filters.end_date).toLocaleDateString() : ''

    const columns = useMemo(() => [
        { name: 'Manufacturer', width: "150px", selector: row => row.manufacturer },
        { name: 'Catalog Number', width: "150px", selector: row => row.catalog },
        { name: 'Item Category', width: "120px", selector: row => row.category },
        { name: 'Description', width: "450px", selector: row => row.description },
        { name: 'Units', width: "70px", selector: row => row.units }
    ])

    const manuColumns = useMemo(() => [
        { name: 'Manufacturer', width: "150px", selector: row => row.name },
        { name: 'Catalog Number', width: "150px", selector: row => row.catalog },
        { name: 'Item Category', width: "120px", selector: row => row.category },
        { name: 'Description', width: "450px", selector: row => row.description },
        { name: 'Units', width: "70px", selector: row => row.units },
        { name: 'Case Count', width: "100px", selector: row => row.case_count },
    ])

    const formatted_manu_data = manuData ? manuData?.data?.reduce((memo,e,idx) => {
        if(!memo.init) {
            memo = {
                init: true,
                data: []
            }
        }

        for(let x=0; x < e.items.length; x++) {
            memo.data.push({
                name: e.name,
                catalog: e.items[x].catalog,
                category: e.items[x].category,
                description: e.items[x].description,
                units: e.items[x].units,
                case_count: e.items[x].case_count
            })
        }
        return memo
    },{})?.data?.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }) : null

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card modal-extra-large">
            <header className="modal-card-head">
                <p className="modal-card-title">Disposables Report by Patient</p>
            </header>

            <section className="modal-card-body p-0">
                <div className="p-5">
                    <div className="w-100">
                        <div className="columns">
                            <div className="column"></div>
                            <div className="column">
                                <div style={{midWidth: '400px', maxWidth: '400px'}}>
                                    <Select
                                        options={props.facilities}
                                        placeholder={'Select a Facility'}
                                        value={props.facilities.find((e) => e.value == filters.facility)}
                                        onChange={(option) => onFilterChanged(option.value, 2)}
                                    />
                                </div>
                            </div>
                            <div className="column"></div>
                        </div>
                        <div className="level w-100">
                            <div className="level-item"></div>
                            <div className="level-item">
                                <div style={{maxWidth: '200px', minWidth: '200px' }}>
                                    <DatePicker
                                        className="input"
                                        selectsStart
                                        startDate={filters.start_date}
                                        endDate={filters.end_date}
                                        selected={filters.start_date}
                                        onChange={(date) => onFilterChanged(date, 0)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        maxDate={new Date()}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Start Date"
                                    />
                                </div>
                            </div>
                            <div className="level-item">
                                <div style={{maxWidth: '200px', minWidth: '200px' }}>
                                    <DatePicker
                                        className="input"
                                        selectsStart
                                        startDate={filters.start_date}
                                        endDate={filters.end_date}
                                        selected={filters.end_date}
                                        onChange={(date) => onFilterChanged(date, 1)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        maxDate={new Date()}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="End Date"
                                    />
                                </div>
                            </div>
                            <div className="level-item"></div>
                        </div>

                        <div className="level w-100">
                            <div className="level-item has-text-centered">
                                <button className="button is-success" onClick={() => renderData()}>Generate</button>
                            </div>
                        </div>

                        {
                            !formattedData
                            &&
                            <div>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        }

                        {
                            formattedData
                            &&
                            <div ref={componentRef}>
                                <div className="level w-100">
                                    <div className="level-left">
                                        <p className="title is-5">Disposables Report by Patient</p>
                                    </div>
                                    <div className="level-right">
                                        <p>{`(Printed ${today.toLocaleDateString()})`}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="columns">
                                    <div className="column is-2">
                                        <p className="has-text-weight-semibold">Hospital:</p>
                                        <p className="has-text-weight-semibold">Date Range:</p>
                                    </div>
                                    <div className="column is-6">
                                        <p>{props.facilities.find(e => e.value == filters.facility).label}</p>
                                        <p>{`${start_date} - ${end_date}`}</p>
                                    </div>
                                    <div className="column is-4"></div>
                                </div>

                                <hr />

                                {formattedData ? formattedData.map((e, idx) => {
                                    return <div key={idx}>
                                        <div className="level w-100">
                                            <div className="level-item has-text-centered">
                                                <div>
                                                    <p className="has-text-weight-medium">{e?.date}</p>
                                                </div>
                                            </div>
                                            <div className="level-item has-text-centered">
                                                <div>
                                                    <p className="has-text-weight-medium">{e?.patient_name}</p>
                                                </div>
                                            </div>
                                            <div className="level-item has-text-centered">
                                                <div>
                                                    <p className="has-text-weight-medium">{e?.medical_record_num}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <hr />

                                        <DataTable
                                            striped
                                            data={e?.items ?? []}
                                            columns={columns}
                                        />

                                        <hr />
                                    </div>
                                }) : null}

                                {
                                    formatted_manu_data
                                    ?
                                    <div>
                                        <hr />
                                        <div className="level w-100">
                                            <div className="level-item"></div>
                                            <div className="level-item">
                                                <p className="title is-5">Summary by Manufacturer</p>
                                            </div>
                                            <div className="level-item"></div>
                                        </div>
                                        <hr />
                                        <DataTable 
                                            striped
                                            columns={manuColumns}
                                            data={formatted_manu_data}
                                        />
                                    </div>
                                    :
                                    <></>
                                }

                                
                            </div>
                        }
                    </div>
                </div>

                <button onClick={props.closeModal} className="modal-close is-large" aria-label="close"></button>
            </section>

            <footer className="modal-card-foot">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="button is-danger" onClick={props.closeModal}>Close</button>
                    </div>
                    <div className="level-right">
                        {
                            formattedData
                            &&
                            <button className="button is-success" onClick={handlePrint}>Print</button>
                        }   
                    </div>
                </div>
            </footer>
        </div>
    </div>
}

export default PdfDisposableByPatient