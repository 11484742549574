import React, { useEffect, useState } from "react";
import useSearchParamState from "../../hooks/useSearchParamState";
import InventoryReport from "./tabs/InventoryReport";
import ManageInventory from "./tabs/ManageInventory";
import OrderList from "./tabs/OrderList";
import Transfer from "./tabs/Transfer";

const ORDER_TAB = 1
const MANAGE_TAB = 2
const REPORT_TAB = 3
const TRANSFER_TAB = 4

const TABS = [
  {id:ORDER_TAB,name:'InventoryOrder',component:OrderList},
  {id:MANAGE_TAB,name:'InventoryManage',component:ManageInventory},
  // {id:REPORT_TAB,name:'InventoryReport',component:InventoryReport},
  {id:TRANSFER_TAB,name:'Transfer',component:Transfer}
]

function Inventory(props){
  const [activeTab,setActiveTab] = useSearchParamState('tab',ORDER_TAB,parseInt)
  const [id, setID] = useSearchParamState('id')
  const [loc, setLoc] = useState()
  const [item, setItem] = useState()

  useEffect(() => {
    props.setMenuItems([
      {title:"Field Orders",callback:() => setActiveTab(ORDER_TAB)},
      {title:"Manage Inventory",callback:() => setActiveTab(MANAGE_TAB)},
      //{title:"Inventory Reports",callback:() => setActiveTab(REPORT_TAB)},
      {title:"Transfer",callback:() => setActiveTab(TRANSFER_TAB)},
    ])
    
    return props.clearMenuItems
  },[])

  const updateID = (newID) => {
    setID(newID)
  }
  
  const updateLoc = (newLoc) => {
    setLoc(newLoc)
  }

  const updateItem = (newItem) => {
    setItem(newItem)
  }
  
  const Tab = TABS.find(tab => tab.id === activeTab)
  const TabComponent = Tab.component

  console.log("Current Tab: ",Tab.name)

  return <div className="box">
    <TabComponent 
      id={id}
      updateID={updateID}
      loc={loc}
      updateLoc={updateLoc}
      item={item}
      updateItem={updateItem}
      permissions={props.permissions}
      extraPermissions={props.extraPermissions}
    />
  </div>
}

export default Inventory;