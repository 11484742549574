import React, { useEffect, useState } from "react";
import useSearchParamState from "../hooks/useSearchParamState";
import { FaSearch, FaHospitalAlt, FaShoppingCart, FaSyringe } from "react-icons/fa";
import API from "../../services/API"
import InitialAvatar from "../components/InitialAvatar";
import { useNavigate } from "react-router-dom";

const boxStyle = {
    "maxWidth": "118px",
    "minWidth": "118px",
    "marginRight": "11px"
}

function Search() {
    const navigate = useNavigate()
    const [view, setView] = useState({ personnel: true, patients: true, facilities: true, items: true, purchaseOrders: true })
    const [query, setQuery] = useSearchParamState('query')
    const [facilities, setFacilities] = useState([])
    const [personnel, setPersonnel] = useState([])
    const [items, setItems] = useState([])
    const [purchaseOrders, setPurchaseOrders] = useState([])

    const getInitials = (first, last) => {
        let fI = first.charAt(0)
        let lI = last.charAt(0)

        return `${fI}${lI}`
    }

    const viewAll = () => {
        setView({ personnel: true, patients: true, facilities: true, items: true, purchaseOrders: true })
    }

    const viewPersonnel = () => {
        setView({ personnel: true, patients: false, facilities: false, items: false, purchaseOrders: false })
    }

    const viewPatients = () => {
        setView({ personnel: false, patients: true, facilities: false, items: false, purchaseOrders: false })
    }

    const viewFacilities = () => {
        setView({ personnel: false, patients: false, facilities: true, items: false, purchaseOrders: false })
    }

    const viewItems = () => {
        setView({ personnel: false, patients: false, facilities: false, items: true, purchaseOrders: false })
    }

    const viewPOs = () => {
        setView({ personnel: false, patients: false, facilities: false, items: false, purchaseOrders: true })
    }

    const filteredPersonnel = personnel.filter((obj) => {
        if (query === "" || query == null) return true
        return (
            obj.first_name.toLowerCase().includes(query != null ? query.toLowerCase() : query) ||
            obj.last_name.toLowerCase().includes(query != null ? query.toLowerCase() : query)
        )
    })

    const filteredFacilities = facilities.filter((obj) => {
        if (query === "" || query == null) return true
        return (
            obj.name.toLowerCase().includes(query != null ? query.toLowerCase() : query) ||
            obj.short_name.toLowerCase().includes(query != null ? query.toLowerCase() : query) ||
            obj.facility_state.toLowerCase().includes(query != null ? query.toLowerCase() : query) ||
            obj.facility_city.toLowerCase().includes(query != null ? query.toLowerCase() : query)
        )
    })

    const filteredPurchaseOrders = purchaseOrders.filter((obj) => {
        if (query === "" || query == null) return true
        return (
            obj.poNumber.toLowerCase().includes(query != null ? query.toLowerCase() : query) ||
            obj.order_date.toLowerCase().includes(query != null ? query.toLowerCase() : query) ||
            obj.facility_name.toLowerCase().includes(query != null ? query.toLowerCase() : query)
        )
    })

    const filteredItems = items.filter(item => {
        if (query === "" || query == null) return true
        return (
            item.item_type_name.toLowerCase().includes(query != null ? query.toLowerCase() : query) ||
            item.description.toLowerCase().includes(query != null ? query.toLowerCase() : query) ||
            item.manufacturer_name.toLowerCase().includes(query != null ? query.toLowerCase() : query)
        )
    })

    useEffect(() => {
        let queries = [
            { 'method': 'GET', 'path': '/api/facilities/', },
            { 'method': 'GET', 'path': '/api/users/', },
            { 'method': 'GET', 'path': '/api/items/', },
            { 'method': 'GET', 'path': '/api/orderlist/', }
        ]
        API.all(queries, function (resp) {
            setFacilities(resp[0])
            setPersonnel(resp[1])
            setItems(resp[2])
            setPurchaseOrders(resp[3])
        })
        if (query != null) setQuery(query.toLowerCase())
    }, [])

    const onSearchChange = (e) => {
        //remove for manual search
        setQuery(e.target.value)
    }

    const onSearch = (e) => {
        if (e.key !== "Enter") return
        setQuery(e.target.value)
    }

    return <div className="columns">
        <div className="column is-8">
            <div className="box">
                <p className="title">Search Results</p>
                <p>Search results are sorted by platform sections, scroll to the right for more.</p>
                {
                    (view.personnel && filteredPersonnel.length > 0)
                    &&
                    <div>
                        <hr />
                        <p className="title is-6">Users</p>
                        <div className="is-flex is-flex-wrap-wrap is-align-self-stretch">
                            {
                                filteredPersonnel.map(i => (<div className="columns has-text-centered" style={boxStyle} key={i.id}>
                                    <div className="column">
                                        <div>
                                            <a onClick={() => navigate("/setup?tab=3&id=" + i.id)}>
                                                <InitialAvatar text={getInitials(i.first_name, i.last_name)} />
                                                <p>{i.first_name} {i.last_name}</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>))
                            }
                        </div>
                    </div>
                }

                {
                    (view.facilities && filteredFacilities.length > 0)
                    &&
                    <div>
                        <hr />
                        <p className="title is-6">Hospitals</p>
                        <div className="is-flex is-flex-wrap-wrap is-align-self-stretch">
                            {
                                filteredFacilities.map(i => (<div className="columns has-text-centered" style={boxStyle}>
                                    <div className="column">
                                        <a onClick={() => navigate("/setup?tab=2&id=" + i.id)}>
                                            <FaHospitalAlt size="48" />
                                            <p>{i.name}</p>
                                        </a>
                                    </div>

                                </div>))
                            }
                        </div>
                    </div>
                }

                {
                    (view.purchaseOrders && filteredPurchaseOrders.length > 0)
                    &&
                    <div>
                        <hr />
                        <p className="title is-6">Purchase Orders</p>
                        <div className="is-flex is-flex-wrap-wrap is-align-self-stretch">
                            {
                                filteredPurchaseOrders.map(i => (<div className="columns has-text-centered" style={boxStyle}>
                                    <div className="column">
                                        <a onClick={() => navigate("/inventory?tab=1&id=" + i.id)}>
                                            <FaShoppingCart size="48" />
                                            <p>{i.poNumber}</p>
                                            <p>{i.facility_name}</p>
                                        </a>
                                    </div>
                                </div>))
                            }
                        </div>
                    </div>
                }

                {
                    (view.items && filteredItems.length > 0)
                    &&
                    <div>
                        <hr />
                        <p className="title is-6">Items</p>
                        <div className="is-flex is-flex-wrap-wrap is-align-self-stretch">
                            {
                                filteredItems.map(i => (<div className="columns has-text-centered" style={boxStyle}>
                                    <div className="column">
                                        <a onClick={() => navigate("/setup?tab=6&id=" + i.id)}>
                                            <FaSyringe size="48" />
                                            <p>{i.description}</p>
                                        </a>
                                    </div>
                                </div>))
                            }
                        </div>
                    </div>
                }
                
                <hr />
            </div>
        </div>
        <div className="column is-4">
            <div className="box">
                <div className="control has-icons-left">
                    <span className="icon is-small is-left has-text-primary">
                        <FaSearch />
                    </span>
                    <input className="input" type="text" value={query} placeholder="Search Anything" onKeyUp={onSearch} onChange={onSearchChange} />
                </div>
                <hr />
                <p className="title is-6">Type</p>
                <a onClick={() => viewAll()}><p>All</p></a>
                <a onClick={() => viewPersonnel()}><p>Users</p></a>
                <a onClick={() => viewPatients()}><p>Patients</p></a>
                <a onClick={() => viewFacilities()}><p>Hospitals</p></a>
                <a onClick={() => viewItems()}><p>Inventory Items</p></a>
                <a onClick={() => viewPOs()}><p>Purchase Orders</p></a>
            </div>
        </div>
    </div>
}

export default Search;