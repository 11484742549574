import React, { useEffect, useMemo, useRef, useState } from "react";
import API from "../../../../services/API";
import DataTable from "react-data-table-component";
import { FaRegEdit, FaTimes, FaUpload } from "react-icons/fa";
import { FaFilePdf, FaFileExcel, FaRegFilePdf, FaDownload, FaExpandArrowsAlt, FaEnvelope } from "react-icons/fa";
import { CSVLink, CSVDownload } from "react-csv";
import PdfPatientDisposable from "../../../components/PdfPatientDisposable";
import PdfProcedure from "../../../components/PdfProcedure";
import PdfHemasource from "../../../components/PdfHemasource";
import DescriptionModal from "../../../components/DescriptionModal";

function Documents(props){
  const reports = [
    {
      id:2,
      name:"Procedure Report",
      description:"Procedure Report",
      csv: false
    }
  ]

  const hemaReports = [
    {
      id:3,
      name:"Hemasource Report",
      description:"Hemasource Report",
      csv: false
    }
  ]

  const [documents,setDocuments] = useState([])
  const [categories, setCategories] = useState([])
  const [showPatientDisposable, setShowPatientDisposable] = useState(false)
  const [caseReport, setCaseReport] = useState({})
  const [showProcedure, setShowProcedure] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [showHemasource, setShowHemasource] = useState(false)
  const [descriptionValues, setDescriptionValues] = useState()
  const [isSaving, setIsSaving] = useState(false)

  const fileInput = useRef()
  const dropArea = useRef()

  const hemoIds = [8]
  const isHema = hemoIds.includes(props.activeCase.procedure_id[0])
  
  const columns = useMemo(() => [
    {name:'File Name',width:"320px",selector: row => row.file_name},
    {name:'Description',selector: row => row.description || "No description provided"},
    {name:'',width:"160px",right:true,selector: row => <div>
      <a className="has-text-success mr-4" disabled={isSaving} onClick={() => onDownload(row.id)}><FaDownload size={20} /></a>
      <a className="has-text-info mr-3" disabled={isSaving} onClick={() => onEdit(row.id)}><FaRegEdit size={20} /></a>
      <a className="has-text-danger" disabled={isSaving} onClick={() => onDelete(row.id)}><FaTimes size={20} /></a>
    </div>}
  ])

  useEffect(() => {
    let fileArr = ''
    if(props.activeCase.files && props.activeCase.files.length > 0) {
      for(let i=0; i < props.activeCase.files.length; i++) {
        if(i==0) {
          fileArr = `${props.activeCase.files[i]}`
        } else {
          fileArr = `${fileArr},${props.activeCase.files[i]}`
        }
      }
    }
    let queries = [
      { 'method': 'GET', 'path': '/api/itemcategories/', },
      { 'method': 'GET', 'path': `/api/casereports/${props.activeCase.id}/`, },
      { 'method': 'GET', 'path': `/api/files/`, 'params': {'type': 'list_files', 'ids': fileArr}}
    ]
    API.all(queries, function (resp) {
      setCategories(resp[0])
      setCaseReport(resp[1])
      setDocuments(resp[2])
      setIsLoaded(true)
    },() => props.setErrorMessage('Failed to load data.'))
  },[])

  // Add/remove listeners for drop area
  useEffect(() => {
    dropArea.current?.addEventListener('dragover', handleDragOver);
    dropArea.current?.addEventListener('drop', handleDrop);

    return () => {
      dropArea.current?.removeEventListener('dragover', handleDragOver);
      dropArea.current?.removeEventListener('drop', handleDrop);
    };
  },[documents])

  const onEdit = (id) => {
    let file = documents.find(e => e.id == id)
    setDescriptionValues(file)
  }

  const onDownload = (id) => {
    if(!isSaving) {
      setIsSaving(true)
      let params = {
        'file_id': id,
        'type': 'download_file'
      }
      API.downloadFile('POST',`/api/files/`,params,{},function(resp,type) {
        console.log('response',resp)
        setIsSaving(false)
      }, () => props.setErrorMessage("Failed downloading file."))
    }
  }

  const onDelete = (id) => {
    if(!isSaving) {
      setIsSaving(true)
      let data = {
        'deleted': true
      }
      API.query('PATCH',`/api/files/${id}/`,{},data,function(resp) {
        let newArr = props.activeCase.files
        newArr = newArr.filter(e => e != id)
        let data = {
          'files': data
        }
        API.query('PATCH',`/api/cases/${props.activeCase.id}/`,{},data,function(resp) {
          props.updateActiveCase({files: data})
          const newDocuments = documents.filter(item => item.id !== id)
          setDocuments(newDocuments)
          setIsSaving(false)
        }, () => props.setErrorMessage("Failed updating case files."))
      }, () => props.setErrorMessage("Failed deleting case files."))
    }
  }

  const uploadDocuments = (files) => {
    if(files == null || files.length === 0) return
    if(!isSaving) {
      setIsSaving(true)
      const newDocuments = [...documents]
      for(let i = 0; i < files.length; i++) {
        let file = files[i]
        let params = {
          'type': 'new_file',
          'path': `cases/${props.activeCase.id}/${file.name}`
        }
        let data = {
          'file': file
        }
        API.uploadFile('POST',`/api/files/`,params,data,function(resp) {
          let newArr = []
          newArr.push(resp[0].id)
          if(props.activeCase.files.length > 0) {
            newArr = [...newArr, ...props.activeCase.files]
          }
          let data = {
            'files': newArr
          }
          API.query('PATCH',`/api/cases/${props.activeCase.id}/`,{},data,function(response) {
            props.updateActiveCase({files: newArr})
            newDocuments.push({
              id: resp[0].id,
              file_name: resp[0].file_name,
              description: resp[0].description
            })
            setIsSaving(false)
          }, () => props.setErrorMessage("Failed attaching file to case."))
        }, (response) => props.setErrorMessage(response))
      }
      setDocuments(newDocuments)
    }
  }

  const onDescriptionChanged = (value) => {
    setDescriptionValues(value)
  }

  const onDescriptionSave = () => {
    if(!isSaving) {
      setIsSaving(true)
      let data = {
        'description': descriptionValues['description']
      }
      API.query('PATCH',`/api/files/${descriptionValues.id}/`,{},data,function(resp) {
        setDescriptionValues()
        if(resp && resp.id) {
          let tempDocs = [...documents]
          let index = documents.findIndex(e => e.id == resp.id)
          tempDocs[index] = resp
          setDocuments(tempDocs)
          setIsSaving(false)
        }
      }, () => props.setErrorMessage("Failed saving file description."))
    }
  }

  // START DROP LOGIC

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    uploadDocuments(e.dataTransfer.files)
  };

  const onPdfClicked = (id) => {
    if(id == 1) {
      setShowPatientDisposable(true)
    } else if(id == 2) {
      setShowProcedure(true)
    } else if(id == 3) {
      setShowHemasource(true)
    }
  }

  const reportColumns = useMemo(() => [
    {name:'Name of Report',selector: row => row.name,sortable:true},
    {name:'Description', selector: row => row.description,sortable:true},
    {name:'Actions',selector: row =><div>
      {
        row.csv
        &&
        <CSVLink filename={getReportName(row)} data={getReportData(row)}><FaFileExcel size={18} title="CSV"/></CSVLink>
      }
      <a className="ml-3" title="PDF" onClick={() => onPdfClicked(row.id)}><FaRegFilePdf size={18} /></a>
    </div>}
  ])

  const getReportData = (row) => {
    if(row.id == 1) {
      
    } else if(row.id == 2) {

    }
  }

  const getReportName = (row) => {
    if(row.id == 1) {
      return `${props.activeCase.id}-patientdisposableprice.csv`
    }
  }

  return <div ref={dropArea}>
    <p className="title">Documents Upload</p>

    <hr/>

    <label className="label">Upload or Drag and Drop all pertinent documents here</label>

    <button
      className="button is-fullwidth is-success"
      onClick={() => fileInput.current.click()}
    >
      <FaUpload />
      &nbsp;
      Upload Documents
    </button>

    {/* Invisible file input activated by button */}
    <input
      type="file"
      ref={fileInput}
      onChange={(e) => uploadDocuments(e.target.files)}
      style={{display:'none'}}
      multiple
    />

    <DataTable
      striped
      columns={columns}
      data={documents}
    />

    <br />
    <hr />

    {
      isLoaded
      &&
      <div>
        <p className="title">Case Reports</p>

        <hr/>

        <DataTable 
          striped
          data={isHema ? hemaReports : reports}
          columns={reportColumns}
        />
      </div>
    }

    {
      showPatientDisposable
      &&
      <PdfPatientDisposable
        activeCase={props.activeCase}
        categories={categories}
        closeModal={() => setShowPatientDisposable(false)}
      />
    }

    {
      showProcedure
      &&
      <PdfProcedure
        caseReport={caseReport}
        closeModal={() => setShowProcedure(false)}
        facility={props.activeCase.facility_id}
      />
    }

    {
      showHemasource
      &&
      <PdfHemasource
        data={caseReport}
        closeModal={() => setShowHemasource(false)}
      />
    }

    {
      descriptionValues
      &&
      <DescriptionModal
        onClose={() => setDescriptionValues()}
        onSave={() => onDescriptionSave()}
        descriptionValues={descriptionValues}
        onDescriptionChanged={onDescriptionChanged}
      />
    }
  </div>
}

export default Documents