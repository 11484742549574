import React, { useState } from "react";

function QCModal(props){
  const [item,setItem] = useState({...props.item})

  const setNotes = (e) => {
    const newItem = {...item}
    newItem.notes = e.target.value
    setItem(newItem)
  }

  const onSave = () => {
    props.onSave(item)
  }

  const onCancel = () => {
    props.onClose()
  }

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head has-background-white">
        <p className="is-size-4">Add QC Notes</p>
      </header>

      <section className="modal-card-body">
        <p><b>Checklist Item:</b> {item.item.description}</p>
        <label className="label">QC Notes</label>
        <textarea className="textarea" placeholder="Enter in any notes for this QC item" value={item.notes??""} onChange={setNotes} />
      </section>

      <footer className="modal-card-foot has-background-white">
        <div className="level w-100">
          <div className="level-left">
            <button className="level-item button is-danger is-inverted" onClick={onCancel}>Cancel</button>
          </div>
          <div className="level-right">
            <button className="level-item button is-success" onClick={onSave}>Save</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
}

export default QCModal