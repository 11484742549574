import React, { useEffect, useState, useMemo } from "react";
import DynamicForm from "../../../components/DynamicForm";
import API from "../../../../services/API";
import SetupModal from "../../../components/SetupModal";
import DataTable from 'react-data-table-component';
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

import qcSearch from "../../../forms/qcSearch";
import qcModal from "../../../forms/qcModal";
import ErrorModal from "../../../components/ErrorModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import VisitTimer from "../../../components/VisitTimer";
import Session from "../../../../services/Session"

function QCSetup(){
  const [filterValues,setFilterValues] = useState();
  const [qc, setQC] = useState([]);
  const [procedureOptions, setProcedureOptions] = useState([]);
  const [qcOptions, setQCOptions] = useState([]);
  const [showSetupModal,setShowSetupModal] = useState(false);

  const [editValues, setEditValues] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [facilityOptions, setFacilityOptions] = useState([])
  
  const onFilterChange = (values) => {
    setFilterValues(values);
  }

  const onEditChange = (values) => {
    setEditValues(values)
  }

  const onSave = () => {
    if(!isSaving) {
      setIsSaving(true)
      let data = {
        'description':editValues['description'],
        'facilities':editValues['facilities'],
        'procedures':editValues['procedures'],
        'threshold':editValues['threshold'],
        'status':(editValues['status'] && editValues['status'] == 'false')?false:true,
        'min':editValues['min'],
        'max':editValues['max'],
        'required':(editValues['required'] && editValues['required'] == 'false')?false:true
      }
      let method = isEditMode ? 'PUT' : 'POST'
      let uri = isEditMode ? `/api/qc/${editValues.id}/` : '/api/qc/'
      API.query(method,uri,{},data, function(resp) {
        let newQC = qc
        if(isEditMode) {
          let i = newQC.findIndex(j => j.id == editValues.id)
          if(i > -1) {
            data['id'] = editValues.id
            newQC[i] = data
          }
        }else {
          data['id'] = resp.id
          newQC.push(data)
        }
        setQC(newQC)
        setIsSaving(false)
        setShowSetupModal(false)
        setEditValues([])
      },onDataLoadError)
    }
  }

  const onSetupModalClosed = () => {
    setShowSetupModal(false)
    setEditValues([])
    setIsEditMode(false)
  }

  const onEditClicked = (row) => {
    row.status = row.status?"true":"false"
    row.required = row.required?"true":"false"
    setEditValues(row)
    setShowSetupModal(true)
    setIsEditMode(true)
  }

  const onDeleteClicked = (row) => {
    setEditValues(row)
    setShowConfirmationModal(true)
  }

  const onButtonPressed = (id) => {
    if(id == "clear") {
      setFilterValues([])
      
    } else if (id == "create_new") {
      setShowSetupModal(true)

    } else {

    }
  }

  const onConfirmDelete = () => {
    API.query("DELETE",`/api/qc/${editValues.id}/`,{},{}, function(resp) {
      let newQC = qc.filter(j => j.id !== editValues.id)
      setQC(newQC)
      setShowConfirmationModal(false)
      setEditValues([])
    },onDataLoadError)
  }


  const filterByProcedure = (obj) => {
    var y, z, valid, abort=false
    if(filterValues.procedure && filterValues.procedure.length != 0) {
      if(obj.procedures.length != 0) {
        for(y=0; y < filterValues.procedure.length && !abort; y++) {
          for(z=0; z < obj.procedures.length && !abort; z++) {
            if (filterValues.procedure[y] == obj.procedures[z]) {
              valid = true
              abort = true
            } else {
              valid = false
            }
          }
        }
      } else {
        valid = false
      }
    } else {
      valid = true
    }
    return valid
  }

  const filterByItself = (obj) => {
    var valid
    if(filterValues.qc && filterValues.qc.length != 0) {
      if(filterValues.qc.findIndex(f => obj.id == f) == -1) {
        valid = false 
      } else { 
        valid = true 
      }
    } else {
      valid = true
    }
    return valid
  }

  const filterByStatus = (obj) => {
    var valid
    if(filterValues.status == "active") {
      if(obj.status) { valid=true; } else { valid = false; }
    } else if(filterValues.status == "inactive") {
      if(!obj.status) { valid=true; } else { valid=false; }
    } else {
      valid = true
    }
    return valid
  }

  const filteredQC =  qc.filter((obj) =>{
    let valid = false;
    
    if(filterByItself(obj)) {
      if(filterByProcedure(obj)) {
        if(filterByStatus(obj)) {
          valid = true
        }
      }
    }
    return valid;
  })

  const columns = useMemo(() => [
    {name:'Quality Control Item Details',selector: row => row.description,sortable:true},
    {name:'Threshold %',selector: row => row.threshold,sortable:true},
    {name:'Status',selector: row => row.status ? "Active" : "Inactive"},
    {name:'Actions',selector: row =><div>
    <a title="Edit" onClick={() => onEditClicked(row)}><FaPencilAlt size={14} /></a>
    <a className="ml-3" title="Delete" onClick={() => onDeleteClicked(row)}><FaTrashAlt size={14} /></a>
    </div>}
  ])

  useEffect(() => {
    let queries = [
      { 'method': 'GET', 'path': '/api/qc/', },
      { 'method': 'GET', 'path': '/api/procedures/', },
      { 'method': 'GET', 'path': '/api/facilities/', }
    ]
    API.all(queries, function (resp) {
      setQC(resp[0])
      setQCOptions(resp[0].map(i => ({ "label": i.description, "value": i.id })))
      setProcedureOptions(resp[1].map(i => ({ "label": i.name, "value": i.id })))
      setFacilityOptions(resp[2].map(i => ({ "label": i.name, "value": i.id })))
    }, onDataLoadError)
  }, []);

  const onDataLoadError = (error) => {
    setErrorMessage(error)
    setShowErrorModal(true)
    setIsSaving(false)
  }

  const visited = () => {
    // get pageviewhistory id from user session data
    var user = Session.get('user')
    API.query('GET',`/api/pageviewhistory/${user.page_view_history}/`,{},{},function(response) {
      let data = {
        "setup_qc": response.setup_qc + 1
      }
      API.query('PATCH',`/api/pageviewhistory/${user.page_view_history}/`,{},data,function(resp) {}, onDataLoadError)
    }, onDataLoadError)
  }

  return <div>
    <p className="title">Quality Control Setup</p>
    <hr/>
    <DynamicForm
      form={qcSearch}
      values={filterValues}
      onButtonPressed={onButtonPressed}
      onValuesChanged={onFilterChange}
      source={{ "procedures": procedureOptions, "qc": qcOptions }}
    />
    <hr/>

    {
      showSetupModal
      && 
      <SetupModal
        titleString={"Quality Control Setup"}
        form={qcModal}
        editValues={editValues}
        onEditChange={onEditChange}
        isSaving={isSaving}
        onClose={onSetupModalClosed}
        onSave={onSave}
        source={{"procedures": procedureOptions, "facilities": facilityOptions}}
      />
    }

{
      showErrorModal
      && 
      <ErrorModal
        message={errorMessage}
        onClose={() => setShowErrorModal(false)}
      />
    }

    {
      showConfirmationModal
      && 
      <ConfirmationModal
        message={"You are about to delete a Quality Control Item. This cannot be undone."}
        onConfirm={onConfirmDelete}
        onClose={() => setShowConfirmationModal(false)}
      />
    }

    <p className="title is-5">Results</p>
    <DataTable
      striped
      pagination
      data={filteredQC}
      columns={columns}
    />

    <VisitTimer visited={visited}/>

  </div>
}

export default QCSetup