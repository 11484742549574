import React, { useEffect, useState, useMemo } from "react"
import API from "../../../../services/API"
import descriptionForm from "../../../forms/checklistDescription"
import ConfirmationModal from "../../../components/ConfirmationModal";
import AdminModal from "../../../components/AdminModal";
import ErrorModal from "../../../components/ErrorModal";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import DataTable from 'react-data-table-component';

function ChecklistDescriptions() {
    const [editValues, setEditValues] = useState([])
    const [categories, setCategories] = useState([])
    const [isSaving, setIsSaving] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showSetupModal, setShowSetupModal] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false)
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const onEditChange = (values) => {
        setEditValues(values)
    }

    const onSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'description': editValues['description']
            }
            let method = isEditMode ? 'PATCH' : 'POST'
            let uri = isEditMode ? `/api/checklistdescriptions/${editValues.id}/` : '/api/checklistdescriptions/'
            API.query(method, uri, {}, data, function (resp) {
                API.query('GET','/api/checklistdescriptions/',{},{},function(response) {
                    setCategories(response)
                    setIsSaving(false)
                    setIsEditMode(false)
                    setShowSetupModal(false)
                    setEditValues([])
                }, onDataLoadError) 
            }, onDataLoadError)
        }
    }

    useEffect(() => {
        API.query('GET',`/api/checklistdescriptions/`,{},{},function(resp){
            setCategories(resp)
        })
    }, []);

    const columns = useMemo(() => [
        {name:'Description',selector: row => row.description,sortable:true},
        {name:'Actions',selector: row =><div>
        <a title="Edit" onClick={() => onEditClicked(row)}><FaPencilAlt size={14} /></a>
        <a className="ml-3" title="Delete" onClick={() => onDeleteClicked(row)}><FaTrashAlt size={14} /></a>
        </div>}
    ])

    const onSetupModalClosed = () => {
        setShowSetupModal(false)
        setEditValues([])
        setIsEditMode(false)
    }

    const onEditClicked = (row) => {
        setEditValues(row)
        setShowSetupModal(true)
        setIsEditMode(true)
    }

    const onDeleteClicked = (row) => {
        setEditValues(row)
        setShowConfirmationModal(true)
    }

    const onConfirmDelete = () => {
        API.query("DELETE",`/api/checklistdescriptions/${editValues.id}/`,{},{}, function(resp) {
          let newCategories = categories.filter(j => j.id !== editValues.id)
          setCategories(newCategories)
          setShowConfirmationModal(false)
          setEditValues([])
        },onDataLoadError)
    }

    const onDataLoadError = (error) => {
        setErrorMessage(error)
        setShowErrorModal(true)
        setIsSaving(false)
    }

    const onAddClick = () => {
        setShowSetupModal(true)
    }

    return <div>
        <p className="title is-size-2">Checklist Descriptions</p>
        <p>Add, edit, or delete Checklist description information.</p>
        <hr />
        <div className="columns">
          <div className="column is-4">
            <p className="title is-size-4">Checklist Descriptions</p>
            <p>Add, edit, or delete Checklist Description information.</p>
          </div>
          <div className="column is-8">
            <button onClick={onAddClick} className="button is-medium is-fullwidth">Create New Checklist Description</button>
            <DataTable 
                striped
                pagination
                data={categories}
                columns={columns}
            />
          </div>
        </div>
        
        {
            showSetupModal
            &&
            <AdminModal
                string="Create New Description"
                form={descriptionForm}
                editValues={editValues}
                onEditChange={onEditChange}
                isSaving={isSaving}
                onSave={onSave}
                onClose={onSetupModalClosed}
            />
        }

        {
            showErrorModal
            &&
            <ErrorModal
                message={errorMessage}
                onClose={() => setShowErrorModal(false)}
            />
        }

        {
            showConfirmationModal
            &&
            <ConfirmationModal
                message={"You are about to delete a LIVE Item Category. Please confirm."}
                onConfirm={onConfirmDelete}
                onClose={() => setShowConfirmationModal(false)}
            />
        }
    </div>
}

export default ChecklistDescriptions;