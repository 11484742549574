import React, { useState } from "react"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

function ChartingDateTime(props) {

  const onTimeChange = (event) => {
    //console.log(event.target)
    const time = event.target.value
    const [hh, mm, ss] = time.split(':')
    const targetDate = props.value instanceof Date && !isNaN(props.value) ? props.value : new Date()
    targetDate.setHours(Number(hh) || 0, Number(mm) || 0, Number(ss) || 0);
    props.onChange(targetDate);
  }

  const onDateChange = (date) => {
    console.log(date)
    props.onChange(date)
  }

  return (
    <div className="is-flex">
      <DatePicker
        selected={props.value}
        onSelect={onDateChange}
      />
      <input
        style={{width:"150px"}}
        type="time"
        step="1"
        defaultValue={props.value&&props.value instanceof Date?props.value.toLocaleTimeString([],{hour12:false}):"00:00:00"}
        onChange={onTimeChange}
      />
    </div>
    
  )
}

export default ChartingDateTime