import React, { useState, useEffect } from "react";
import Select from 'react-select';
import DatePicker from 'react-datepicker'
import API from "../../services/API";
import { CSVLink } from "react-csv";

function DisposablePriceReport(props) {
    const [filters, setFilters] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [categories, setCategories] = useState([])
    const [data, setData] = useState()
    const today = new Date()

    useEffect(() => {
        let queries = [
            { 'method': 'GET', 'path': '/api/itemcategories/', }
        ]
        API.all(queries, function (resp) {
            setCategories(resp[0])
        }, onDataLoadError)
    }, []);

    const onFilterChanged = (value, type) => {
        let fValues = { ...filters }
        if (type === 0) {
            fValues['start_date'] = value
        } else if (type === 1) {
            fValues['end_date'] = value
        } else if (type === 2) {
            fValues['facility'] = value
        } else if (type === 3) {
            fValues['personnel'] = value
        }
        setFilters(fValues)
    }

    const renderData = () => {
        if (filters.start_date != null && filters.end_date != null) {
            if(!isLoading) {
                setIsLoading(true)
                let facilities

                if(filters.facility && filters.facility.length > 0) {
                    facilities = [...filters.facility]
                }

                let params = {
                    'start_date': start_date,
                    'end_date': end_date
                }

                if(facilities) {
                    params = {...params, ...{"facility": facilities}}
                }

                API.query('GET',`/api/disposablepricereport/`,params,{},function(resp) {
                    if(resp && resp.length > 0) {
                        setData(resp)
                    }
                    setIsLoading(false)
                },onDataLoadError())
            }
        }
    }

    const getReportData = () => {
        if(data && data.length > 0) {
            var rows = []
            rows.push(['Patient Disposables Report with Price'])
            let facilityString = ''
            for(let z=0; z < filters.facility.length; z++) {
                if(z === 0) {
                    facilityString = `${props.facilities.find(e => e.value === filters.facility[z]).label}`
                } else {
                    facilityString = facilityString + `, ${props.facilities.find(e => e.value === filters.facility[z]).label}`
                }
            }
            rows.push(['Hospital:',`${facilityString}`])
            rows.push(['Date Range:',`${new Date(filters.start_date).toLocaleDateString('en-us')} to ${new Date(filters.end_date).toLocaleDateString('en-us')}`])
            rows.push([])
            rows.push([])
            for(let x=0; x<data.length; x++) {
                var i
                let keys = ['#','Manufacturer','Catalog Number', 'Item Category', 'Description','Units','Price']
                var totalCost = 0.00
                rows.push([]) //'','','','','','',''
                rows.push(['Start Date:',`${data[x].date ? data[x].date : null}`])
                rows.push(['Patient Name:',`${data[x].patient.last_name}, ${data[x].patient.first_name}`])
                rows.push(['MRN:',`${data[x].patient.medical_record_num ? data[x].patient.medical_record_num : ''}`])
                rows.push(['Account No.:',`${data[x].patient.account_num}`])
                rows.push([])
                rows.push(keys)

                for(i=0; i < data[x].items.length; i++) {
                    const loc_count = data[x]?.items[i]?.item?.location_counts.find((z) => z.facility === data[x]?.facility_id)
                    let category = categories.find((e) => data[x].items[i].item.item_category == e.id)
                    let cost = 0
                    
                    if(loc_count?.price) {
                        cost = (data[x].items[i].amount > 0 ? data[x].items[i].amount : 1) * (isNaN(parseFloat(loc_count?.price)) ? 0 : parseFloat(loc_count?.price))
                        totalCost += cost
                    }
                    
                    rows.push([`${i+1}`,
                    `${data[x].items[i].item.manufacturer_name}`,
                    `${data[x].items[i].item.catalog}`,
                    `${category ? category.category : null}`,
                    `${data[x].items[i].item.description}`,
                    `${data[x].items[i].amount ?? `1`}`,
                    `$${cost}`
                    ])
                }
                rows.push(['','','','','',`Total:`,`$${totalCost}`])
                rows.push([])
                rows.push([])
                rows.push([])
            }
            rows.push([])
            rows.push([])
            rows.push([])
            
            let manuData = data.reduce((memo,e,idx) => {
                if(!memo.init) {
                    memo = {
                        data: [],
                        init: true
                    }
                }

                for(let x=0; x < e.items.length; x++) {
                    if(e.items[x].item.manufacturer_name && e.items[x].item.manufacturer_name !== "") {
                        const case_type = e.items[x].item.type
                        let case_count = 0

                        if(case_type === 2) {
                            case_count = (e.items[x].amount ?? 1) * 1
                        } else {
                            case_count = Math.ceil((e.items[x].amount ?? 1) / (e.items[x].item.quantity ?? 1))
                        }

                        const category = categories.find((q) => e.items[x].item.item_category === q.id)
                        const existing_manu_index = memo.data.findIndex(y => y.name === e.items[x].item.manufacturer_name)

                        if(existing_manu_index === -1) {
                            memo.data.push({
                                name: e.items[x].item.manufacturer_name,
                                items: [{
                                    id: e.items[x].item.id,
                                    name: e.items[x].item.manufacturer_name,
                                    catalog: e.items[x].item.catalog,
                                    category: category.category ?? '',
                                    description: e.items[x].item.description,
                                    units: (e.items[x].amount ?? 1),
                                    case_count: case_count
                                }]
                            })
                        } else {
                            //increase count
                            const existing_item_index = memo.data[existing_manu_index].items.findIndex(z => z.id === e.items[x].item.id)

                            if(existing_item_index === -1) {
                                memo.data[existing_manu_index].items.push({
                                    id: e.items[x].item.id,
                                    name: e.items[x].item.manufacturer_name,
                                    catalog: e.items[x].item.catalog,
                                    category: category.category ?? '',
                                    description: e.items[x].item.description,
                                    units: e.items[x].amount ?? 1,
                                    case_count: case_count
                                })
                            } else {
                                memo.data[existing_manu_index].items[existing_item_index].units += (e.items[x].amount ?? 1)

                                if(case_type === 2) {
                                    case_count = memo.data[existing_manu_index].items[existing_item_index].units * 1
                                } else {
                                    case_count = Math.ceil(memo.data[existing_manu_index].items[existing_item_index].units / (e.items[x].item.quantity ?? 1))
                                }
                                memo.data[existing_manu_index].items[existing_item_index].case_count = case_count
                            }
                        }
                    }
                }
                return memo
            },{})
            
            rows.push([])
            rows.push(['Summary By Manufacturer'])
            rows.push([])

            rows.push(['Manufacturer','Catalog Number', 'Item Category', 'Description','Units','Case Count'])
            for(let y=0; y < manuData.data.length; y++) {
                for(let z=0; z < manuData.data[y].items.length; z++) {
                    rows.push([
                        manuData.data[y].name,
                        manuData.data[y].items[z].catalog,
                        manuData.data[y].items[z].category,
                        manuData.data[y].items[z].description,
                        manuData.data[y].items[z].units,
                        manuData.data[y].items[z].case_count,
                    ])
                }
                rows.push([])
            }

            return rows
        }
    }

    const onDataLoadError = (error) => {
        setIsLoading(false)
        console.log(error)
    }

    const start_date = filters.start_date ? new Date(filters.start_date).toLocaleDateString('en-CA') : ''
    const end_date = filters.end_date ? new Date(filters.end_date).toLocaleDateString('en-CA') : ''

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card modal-extra-large">
            <header className="modal-card-head">
                <p className="modal-card-title">Patient Disposable Report with Price</p>
            </header>

            <section className="modal-card-body p-0">
                <div className="p-5">
                    <div className="w-100">
                        <div className="columns">
                            <div className="column"></div>
                            <div className="column">
                                <div style={{ midWidth: '499px', maxWidth: '500px' }}>
                                    <Select
                                        options={props.facilities}
                                        placeholder={'All Facilities'}
                                        value={props.facilities.find((e) => e.value == filters.facility)}
                                        onChange={(option) => onFilterChanged(option.map(option => option.value), 2)}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                            <div className="column"></div>
                        </div>
                        <div className="level w-100">
                            <div className="level-item"></div>
                            <div className="level-item">
                                <div style={{ maxWidth: '200px', minWidth: '200px' }}>
                                    <DatePicker
                                        className="input"
                                        selectsStart
                                        startDate={filters.start_date}
                                        endDate={filters.end_date}
                                        selected={filters.start_date}
                                        onChange={(date) => onFilterChanged(date, 0)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        maxDate={new Date()}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Start Date"
                                    />
                                </div>
                            </div>
                            <div className="level-item">
                                <div style={{ maxWidth: '200px', minWidth: '200px' }}>
                                    <DatePicker
                                        className="input"
                                        selectsStart
                                        startDate={filters.start_date}
                                        endDate={filters.end_date}
                                        selected={filters.end_date}
                                        onChange={(date) => onFilterChanged(date, 1)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        maxDate={new Date()}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="End Date"
                                    />
                                </div>
                            </div>
                            <div className="level-item"></div>
                        </div>

                        <div className="level w-100">
                            <div className="level-item has-text-centered">
                                <button className="button is-success" onClick={renderData}>Set Filters</button>
                            </div>
                        </div>
                        
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>

                <button onClick={props.onClose} className="modal-close is-large" aria-label="close"></button>
            </section>

            <footer className="modal-card-foot">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="button is-danger" onClick={props.onClose}>Close</button>
                    </div>
                    <div className="level-right">
                        {
                            data
                            &&
                            <CSVLink filename={`disp-price_${start_date}_${end_date}`} data={getReportData()}><button className="button is-success" >Download</button></CSVLink>
                        }
                    </div>
                </div>
            </footer>
        </div>
    </div>
}

export default DisposablePriceReport