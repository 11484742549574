import React, { useEffect, useMemo, useState } from "react";
import DataTable from 'react-data-table-component';
import { FaRegEdit } from "react-icons/fa";
import BooleanRadioGroup from "../../../components/BooleanRadioGroup";
import ProcedureBox from "../../../components/ProcedureBox";
import QCModal from "../../../components/QCModal";

function QC(props){
  const [editItem,setEditItem] = useState(null)

  const columns = useMemo(() => [
    {name:'Item Name',selector: row => row.item.description},
    {name:'Threshold %',width:"160px",center:true,selector: row => row.item.threshold+"%"},
    {name:'Achieved?',width:"160px",center:true,selector: row => <BooleanRadioGroup value={row.achieved} setValue={(value) => setAchieved(row.id,value)} />},
    {name:'Notes',width:"80px",center:true,selector: row => <a onClick={() => setEditItem(row)}><FaRegEdit size={20} /></a>}
  ])

  const setAchieved = (itemId,achieved) => {
    const newItems = props.activeCase.qc_items.map(item => {
      if(item.id === itemId) item.achieved = achieved
      return item
    })
    props.updateActiveCase({
      qc_items: newItems
    })
  }

  const onSaveItem = (newItem) => {
    const newItems = props.activeCase.qc_items.map(item => {
      if(item.id === newItem.id) {
        return newItem
      }
      return item
    })
    props.updateActiveCase({
      qc_items: newItems
    })
    setEditItem(null)
  }
  
  return <div>
    <p className="title is-inline-block mr-3 mb-0">Quality Control</p>

    {
      props.activeCase.procedure.map((proc) => {
        return <span key={proc.id} style={{marginRight:5}}>
          <ProcedureBox procedure={proc} />
        </span>
      })
    }

    <hr/>

    {
      props.activeCase.procedure.map((proc) => {
        return <div key={proc.id}>
          <label className="label">{proc.name} Checklist Items</label>
          <DataTable
            striped
            pagination
            columns={columns}
            data={props.activeCase.qc_items.filter((obj) => {
              var valid=false
              if(obj.procedure == proc.id) valid = true
              return valid
            })}
          />
        </div>
      })
    }

    {
      editItem !== null
      &&
      <QCModal
        item={editItem}
        onSave={onSaveItem}
        onClose={() => setEditItem(null)}
      />
    }
  </div>
}

export default QC