import React, { useEffect, useState } from "react";
import chartingEvents from  "../forms/chartingEvents";
import DynamicForm from "./DynamicForm";
import API from "../../services/API";
import ChartingDateTime from "./ChartingDateTime";

function EventsModal(props) {
  const [data,setData] = useState({...props.data})
  const [loading,setLoading] = useState(true)
  const [events,setEvents] = useState(props.events)
  const [eventOptions,setEventOptions] = useState()
  const [missingFields,setMissingFields] = useState()
  const [time,setTime] = useState(props.time?new Date(props.time.getTime()):new Date())

  const onSave = () => {
    if(data['event']) {
      let event = events.find(e => e.id == data.event)
      if(event) {
        let saveData = {'event':event,'info':data['info'] ? data['info'] : null,'unit':event.unit}
        if(props.data) saveData.id = props.data.id
        props.onSave(saveData,time)
      }
    }else {
      let missing = []
      if(!data['event']) missing.push('event')
      //if(!data['info']) missing.push('info')
      setMissingFields(missing)
    }
  }

  const onCancel = () => {
    props.onClose()
  }

  useEffect(() => {
    if(!events) {
      API.query('GET','api/events/',{},{},(resp) => {
        const evtData = props.filter && props.filter.length ? resp.filter((r) => props.filter.includes(r.id)) : resp
        setEvents(evtData)
        setEventOptions(evtData.map(i => {
          let label = i.name
          if(i.unit) label +=  " (" + i.unit + ")"
          return (
            {"label": label, "value": i.id}
          )
        }))
        setLoading(false)
      },() => {
        props.setErrorMessage('Could not load events')
      })
    }else {
      //already passed in as prop
      const evtData = props.filter && props.filter.length ? events.filter((r) => props.filter.includes(r.id)) : events
      setEventOptions(evtData.map(i => {
        let label = i.name
        if(i.unit) label +=  " (" + i.unit + ")"
        return {"label": label, "value": i.id}
      }))
      setLoading(false)
    }
  },[])

  let saveBtnClasses = "level-item button is-success";
  if (props.isSaving) saveBtnClasses += " is-loading"

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
    <header className="modal-card-head has-background-white is-block">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="is-size-4">Enter Event Info</p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <ChartingDateTime 
                value={time}
                onChange={setTime}
              />
            </div>
          </div>
        </div>
      </header>

      <section className="modal-card-body" style={{paddingBottom:"200px"}}>
        {
        !loading
        &&
        <DynamicForm 
          form={chartingEvents}
          values={data}
          onValuesChanged={setData}
          source={{"events":eventOptions}}
          missingFields={missingFields}
        />
        }
      </section>

      <footer className="modal-card-foot has-background-white">
        <div className="level w-100">
          <div className="level-left">
            <button className="level-item button is-danger is-inverted" onClick={onCancel}>Cancel</button>
          </div>
          <div className="level-right">
            <button className={saveBtnClasses} onClick={onSave}>Save</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
}

export default EventsModal