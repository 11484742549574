import React, { useEffect, useState } from "react";
import {FaArrowCircleLeft, FaArrowCircleRight, FaPlusCircle ,FaLongArrowAltRight} from "react-icons/fa";
import {GoChecklist} from "react-icons/go";
import Select from 'react-select'
import { formatHeight, formatWeight, calculateBSA, calculateDO2 } from "../helpers/patient";

import { getFormattedTime } from "../helpers/time";
import ChartingPatientModal from "./ChartingPatientModal"
import TemperatureModal from "./TemperatureModal";
import MedicationsModal from "./MedicationsModal";
import EventsModal from "./EventsModal";
import CPGModal from "./CPGModal";
import LabsModal from "./LabsModal";
import ChartingPrePumpModal from "./ChartingPrePumpModal";
import toast from 'react-hot-toast';
import LoadingModal from "./LoadingModal"
import API from "../../services/API";
import { useSearchParams } from "react-router-dom";
import ConfirmationModal from "./ConfirmationModal";
import CommentModal from "./CommentModal";

function ChartingModal(props){
  const [isSaving, setIsSaving] = useState(false)
  const [elapsed,setElapsed] = useState(0)
  const [patientModalOpen,setPatientModalOpen] = useState(false)
  const [tempModalOpen,setTempModalOpen] = useState(false)
  const [medicationsModalOpen,setMedicationsModalOpen] = useState(false)
  const [eventsModalOpen,setEventsModalOpen] = useState(false)
  const [confirmationModalOpen,setConfirmationModalOpen] = useState(false)
  const [pushDetailsModalOpen,setPushDetailsModalOpen] = useState(false)
  const [commentModalOpen,setCommentModalOpen] = useState(false)
  const [cpgModalOpen,setCPGModalOpen] = useState(false)
  const [labsModalOpen,setLabsModalOpen] = useState(false)
  const [prePumpModalOpen,setPrePumpModelOpen] = useState(false)
  const [editEvent,setEditEvent] = useState()
  const [details,setDetails] = useState()
  const [facilities,setFacilities] = useState(null)
  const [medications, setMedications] = useState(null)
  const [eventEvents, setEventEvents] = useState(null)
  const [allergies,setAllergies] = useState(null)
  const [patientHistories,setPatientHistories] = useState(null)
  const [autoLogWarningTime] = useState(60)
  const [autoLogTime,setAutoLogTime] = useState(900)
  const [labWarningTime,setLabWarningTime] = useState(1800)
  const [cpgWarningTime,setCPGWarningTime] = useState(1200)
  const [isLoading,setIsLoading] = useState(true)
  const [autoLogWarning,setAutoLogWarning] = useState(false)
  const [labWarningReached,setLabWarningReached] = useState(false)
  const [cpgWarningReached,setCPGWarningReached] = useState(false)
  const [searchParams] = useSearchParams();
  const [isSavingEvent, setIsSavingEvent] = useState(false)
  const [do2,setDO2] = useState(0)
  const [data,setData] = useState([
      {name:"FLOW",propName:"flow",units:"LPM",value:""},
      {name:"MAP",propName:"map",units:"mmHg",value:""},
      {name:"AIR/O2",propName:"air_o2",units:"LPM",value:""},
      {name:"FIO2",propName:"fio2",units:"%",value:""},
      {name:"CORE",propName:"core",units:"C",value:""},
      {name:"SVO2",propName:"svo2",units:"%",value:""},
      {name:"HCT",propName:"hct",units:"%",value:""},
      {name:"ISO",propName:"iso",units:"%",value:""},
      {name:"CO-L",propName:"co_l",units:"%",value:""},
      {name:"CO-R",propName:"co_r",units:"%",value:""},
  ])

  const [timers,setTimers] = useState(
    ["PUMP","CLAMP","C ARREST","CPG"].map(name => (
      {name,startTime:null,off:null}
    ))
  )

  const buttons = [
    {name:"PUMP",callback:() => onPumpClick(),timer:0},
    {name:"CLAMP",callback:() => onClampClick(),timer:1},
    {name:"CPG",callback:() => onCPGClick(),timer:3},
    {name:"C ARREST",callback:() => onCArrestClick(),timer:2},
    {name:"TEMP",callback:() => setTempModalOpen(true)},
    {name:"LABS",callback:() => setLabsModalOpen(true)},
    {name:"EVENTS",callback:() => setEventsModalOpen(true)},
    {name:"MEDS",callback:() => setMedicationsModalOpen(true)}
  ]

  const EVENT_TYPE_AUTO_LOG = 1
  const EVENT_TYPE_MANUAL_LOG = 2
  const EVENT_TYPE_PUMP_ON = 3
  const EVENT_TYPE_PUMP_OFF = 4
  const EVENT_TYPE_CLAMP_ON = 5
  const EVENT_TYPE_CLAMP_OFF = 6
  const EVENT_TYPE_C_ARREST_ON = 7
  const EVENT_TYPE_C_ARREST_OFF = 8
  const EVENT_TYPE_CPG_ON = 9
  const EVENT_TYPE_CPG_OFF = 10
  const EVENT_TYPE_TEMP = 11
  const EVENT_TYPE_LABS = 12
  const EVENT_TYPE_EVENTS = 13
  const EVENT_TYPE_MEDS = 14
  const EVENT_TYPE_PRE_PUMP = 15

  const EVENT_TYPES = {
    [EVENT_TYPE_AUTO_LOG]:'AUTO LOG',
    [EVENT_TYPE_MANUAL_LOG]:'MANUAL LOG',
    [EVENT_TYPE_PUMP_ON]:'PUMP ON',
    [EVENT_TYPE_PUMP_OFF]:'PUMP OFF',
    [EVENT_TYPE_CLAMP_ON]:'CLAMP ON',
    [EVENT_TYPE_CLAMP_OFF]:'CLAMP OFF',
    [EVENT_TYPE_C_ARREST_ON]:'C ARREST ON',
    [EVENT_TYPE_C_ARREST_OFF]:'C ARREST OFF',
    [EVENT_TYPE_CPG_ON]:'CPG ON',
    [EVENT_TYPE_CPG_OFF]:'CPG OFF',
    [EVENT_TYPE_TEMP]:'TEMP',
    [EVENT_TYPE_LABS]:'LABS',
    [EVENT_TYPE_EVENTS]:'EVENTS',
    [EVENT_TYPE_MEDS]:'MEDS',
    [EVENT_TYPE_PRE_PUMP]:'PRE PUMP',
    'AUTO_LOG':EVENT_TYPE_AUTO_LOG,
    'MANUAL_LOG':EVENT_TYPE_MANUAL_LOG,
    'PUMP_ON':EVENT_TYPE_PUMP_ON,
    'PUMP_OFF':EVENT_TYPE_PUMP_OFF,
    'CLAMP_ON':EVENT_TYPE_CLAMP_ON,
    'CLAMP_OFF':EVENT_TYPE_CLAMP_OFF,
    'C_ARREST_ON':EVENT_TYPE_C_ARREST_ON,
    'C_ARREST_OFF':EVENT_TYPE_C_ARREST_OFF,
    'CPG_ON':EVENT_TYPE_CPG_ON,
    'CPG_OFF':EVENT_TYPE_CPG_OFF,
    'TEMP':EVENT_TYPE_TEMP,
    'LABS':EVENT_TYPE_LABS,
    'EVENTS':EVENT_TYPE_EVENTS,
    'MEDS':EVENT_TYPE_MEDS,
    'PRE_PUMP':EVENT_TYPE_PRE_PUMP
  } 

  const [events,setEvents] = useState([])

  const tabOptions =  [
    {label:"Procedure",value:2},
    {label:"Checklist",value:3},
    {label:"Personnel",value:4},
    {label:"Details",value:6},
    {label:"Item Selection",value:7},
    {label:"QC",value:8},
    {label:"Study Data",value:9},
    {label:"Documents",value:10}
  ]

  const formatModalSaveData = (modalData) => {
    //need to change dash(-) to underscore(_) for the backend and vice versa
    //should probably tweak dynamicform to use underscore instead, but could break too much right now
    let formattedData = {}
    for(const key in modalData) {
      let newKey = key.replace('-','_')
      formattedData[newKey] = modalData[key]
    }
    return formattedData
  }

  const formatModalImportData = (modalData) => {
    let formattedData = {}
    for(const key in modalData) {
      let newKey = key.replace('_','-')
      formattedData[newKey] = modalData[key]
    }
    return formattedData
  }

  const getEventsByType = (eventType) => {
    return events.filter(e => e.type == eventType)
  }

  const importEvents = (events,logData) => {
    console.log(props.activeCase)
    let newEvents = []
    let lastLog = -1
    let lastPumpOn = -1
    let lastPumpOff = -1
    let lastClampOn = -1
    let lastClampOff = -1
    let lastCArrestOn = -1
    let lastCArrestOff = -1
    let lastCPGOn = -1
    let lastCPGOff = -1
    events.forEach((e, i) => {
      if(e.type) {
        let modalData = null
        if(e.type == EVENT_TYPE_AUTO_LOG || e.type == EVENT_TYPE_MANUAL_LOG) {
          lastLog = i
        }if(e.type == EVENT_TYPE_PUMP_ON) {
          lastPumpOn = i
        }else if(e.type == EVENT_TYPE_PUMP_OFF) {
          lastPumpOff = i
        }else if(e.type == EVENT_TYPE_CLAMP_ON) {
          lastClampOn = i
        }else if(e.type == EVENT_TYPE_CLAMP_OFF) {
          lastClampOff = i
        }else if(e.type == EVENT_TYPE_C_ARREST_ON) {
          lastCArrestOn = i
        }else if(e.type == EVENT_TYPE_C_ARREST_OFF) {
          lastCArrestOff = i
        }else if(e.type == EVENT_TYPE_CPG_ON) {
          lastCPGOn = i
        }else if(e.type == EVENT_TYPE_CPG_OFF) {
          lastCPGOff = i
          if(e.cpg_event) {
            modalData = formatModalImportData(e.cpg_event)
          }
        }else if(e.type == EVENT_TYPE_TEMP) {
          if(e.temp_event) {
            modalData = formatModalImportData(e.temp_event)
          }
        }else if(e.type == EVENT_TYPE_EVENTS) {
          if(e.event_event) {
            modalData = formatModalImportData(e.event_event)
          }
        }else if(e.type == EVENT_TYPE_MEDS) {
          if(e.med_event) {
            modalData = formatModalImportData(e.med_event)
          }
        }else if(e.type == EVENT_TYPE_LABS) {
          if(e.lab_event) {
            modalData = formatModalImportData(e.lab_event)
          }
        }else {
          //unhandled type
        }
        let event = {
          id: e.id,
          type: e.type,
          time: new Date(e.time),
          event: e.event,
          details: e.details,
          modalData: modalData,
          data: logData.map(d => ({name:d.name,propName:d.propName,units:d.units,value:e[d.propName]??""}))
        }
        newEvents.push(event)
      }
    })
    setEvents(newEvents)

    // update top line if we have a log entry
    if(lastLog >= 0) {
      const e = events[lastLog]
      logData.forEach(d => {
        d.value = e[d.propName]??"---"
      })
      setData([...logData])
    }

    const newTimers = [...timers]
    const now = new Date()
    
    if(lastPumpOn > lastPumpOff && events[lastPumpOn].time) {
      const pumpDate = new Date(events[lastPumpOn].time)
      const t = newTimers[0]
      t.startTime = pumpDate
      t.off = false
      
      if(lastClampOn > lastClampOff && events[lastClampOn].time) {
        const clampDate = new Date(events[lastClampOn].time)
        if(clampDate.getTime() > pumpDate.getTime()) {
          const t = newTimers[1]
          t.startTime = clampDate
          t.off = false
        }
      }
      if(lastCArrestOn > lastCArrestOff && events[lastCArrestOn].time) {
        const cArrestDate = new Date(events[lastCArrestOn].time)
        if(cArrestDate.getTime() > pumpDate.getTime()) {
          const t = newTimers[2]
          t.startTime = cArrestDate
          t.off = false
        }
      }
      //force clamp on to run timers
      if(lastClampOn > lastClampOff) { 
        if(lastCPGOn > lastCPGOff && events[lastCPGOn].time) {
          const cpgDate = new Date(events[lastCPGOn].time)
          if(cpgDate.getTime() > pumpDate.getTime()) {
            const t = newTimers[3]
            t.startTime = cpgDate
            t.off = false
          }
        }else if(lastCPGOff > lastCPGOn && events[lastCPGOff].time) {
          const cpgDate = new Date(events[lastCPGOff].time)
          if(cpgDate.getTime() > pumpDate.getTime()) {
            const t = newTimers[3]
            t.startTime = cpgDate
            t.off = true
          }
        }
      }
    }
    
    setTimers(newTimers)
  }

  const pushChartDetails = () => {
    setIsLoading(true)
    API.query('GET','api/cases/'+props.activeCaseId+'/push_details/',{},{},(resp) => {
      toast.success("Details have been sent")
      setPushDetailsModalOpen(false)
      setIsLoading(false)
    },() => {
      toast.error("Could not send details")
      setIsLoading(false)
    })
  }

  const onAddComment = (eventData) => {
    let data = {...eventData}
    data.modalData = {...data.modalData}
    if(data.type) {
      if(data.type == EVENT_TYPE_EVENTS) {
        //set event back to id instead of name for the select default
        if(!Number.isInteger(eventData.modalData.event)) {
          data.modalData.event = eventData.modalData.event.id
        }else {
          data.modalData.event = eventData.modalData.event
        }
        setEventsModalOpen(true)
      }else if(data.type == EVENT_TYPE_MEDS) {
        if(!Number.isInteger(eventData.modalData.medication)) {
          data.modalData.medication = data.modalData.medication.id
        }else {
          data.modalData.medication = data.modalData.medication
        }
        setMedicationsModalOpen(true)
      }else {
        setCommentModalOpen(true)
      }
      setEditEvent(data)
    }
  }

  const onAddCommentCancel = () => {
    setEditEvent(null)
    setCommentModalOpen(false)
  }

  const onAddCommentSave = (comment,time) => {
    if(comment) {
      if(editEvent) {
        setIsSaving(true)
        const newEvents = [...events]
        const eventIndex = newEvents.findIndex(e => e.id == editEvent.id)
        newEvents[eventIndex].details = comment
        newEvents[eventIndex].comment = comment
        newEvents[eventIndex].time = time
        newEvents.sort((a,b)=>a.time.getTime()-b.time.getTime());

        let saveData = {
          details:comment,
          comment,
          time:new Date(time.getTime() - (time.getTimezoneOffset() * 60000)).toJSON()
        }
        updateEvent(editEvent.id, saveData, (resp) => {
          setEvents(newEvents)
        })
        setEditEvent(null)
      }
    }
    setCommentModalOpen(false)
  }

  const addEventBatch = (batch, onSuccess=null) => {
    setIsSavingEvent(true)
    let queries = []
    batch.forEach(e => {
      queries.push({ 'method': 'POST', 'path': 'api/chartevents/', 'data': e.saveData})
    })
    API.all(queries, function (resp) {
      const newEvents = [...events]
      resp.forEach((r,i) => {
        batch[i].id = r.id
        delete batch[i].saveData
        newEvents.push(batch[i])
      })
      setEvents(newEvents)
      setIsSavingEvent(false)
      toast.success("All changes saved")
      if(onSuccess && typeof onSuccess === 'function') onSuccess()
    },() => {
      toast.error("Could NOT save events")
      setIsSavingEvent(false)
    })
  }

  const addEvent = (type, event, details, useData=false, modalData=null, time=null, onSuccess=null, onFail=null) => {

    let newEvent = {
      type: type,
      time: time?time:new Date(),
      event: event,
      details: details,
      modalData: modalData,
      data: useData ? data.map(a => ({...a})) : data.map(a => ({value:"---"}))
    }

    let saveData = {
      chart_id:props.activeCase.charts[0].id,
      type: type,
      time: new Date(newEvent.time.getTime() - (newEvent.time.getTimezoneOffset() * 60000)).toJSON(),//toJSON uses UTC, need offset
      event: event,
      details: details
    }
    if(useData) {
      newEvent.data.forEach(d => {
        if(Number.isInteger(parseInt(d.value))) saveData[d.propName] = d.value
      })
    }
    if(modalData) {
      if(type == EVENT_TYPE_TEMP) {
        saveData['temp_event'] = formatModalSaveData(modalData)
      }else if(type == EVENT_TYPE_EVENTS) {
        saveData['event_event'] = formatModalSaveData(modalData)
        //we send in the entire event object so we can put the name in the event column
        //but we only need to post the id for saving
        saveData['event_event'].event = saveData['event_event'].event.id
      }else if(type == EVENT_TYPE_MEDS) {
        saveData['med_event'] = formatModalSaveData(modalData)
        saveData['med_event'].medication = saveData['med_event'].medication.id
      }else if(type == EVENT_TYPE_CPG_OFF) {
        saveData['cpg_event'] = formatModalSaveData(modalData)
      }else if(type == EVENT_TYPE_LABS) {
        saveData['lab_event'] = formatModalSaveData(modalData)
      }else {
        //unhandled type
      }
    }

    saveEvent(saveData, (resp) => {
      const newEvents = [...events]
      newEvent.id = resp.id
      if(modalData) {
        if(type == EVENT_TYPE_TEMP) {
          newEvent.modalData.id = resp.temp_event.id
        }else if(type == EVENT_TYPE_EVENTS) {
          newEvent.modalData.id = resp.event_event.id
        }else if(type == EVENT_TYPE_MEDS) {
          newEvent.modalData.id = resp.med_event.id
        }else if(type == EVENT_TYPE_CPG_OFF) {
          newEvent.modalData.id = resp.cpg_event.id
        }else if(type == EVENT_TYPE_LABS) {
          newEvent.modalData.id = resp.lab_event.id
        }else {
          //unhandled type
        }
      }
      newEvents.push(newEvent)
      setEvents(newEvents)
      if(onSuccess && typeof onSuccess === "function") onSuccess(resp)
    },onFail)
  }

  const saveEvent = (eventData, onSuccess, onFail=null) => {
    setIsSavingEvent(true)
    API.query('POST','api/chartevents/',{},eventData,(resp) => {
      toast.success("All changes saved")
      if(onSuccess && typeof onSuccess === "function") onSuccess(resp)
      setIsSavingEvent(false)
      setIsSaving(false)
    },(error) => {
      toast.error("Could not save event")
      setIsSavingEvent(false)
      setIsSaving(false)
      if(onFail && typeof onFail === "function") onFail(error)
    })
  }

  const deleteEvent = (eventId, onSuccess) => {
    setIsSavingEvent(true)
    API.query('DELETE',`api/chartevents/${eventId}/`,{chart_id:props.activeCase.charts[0].id},{},(resp) => {
      toast.success("Event Deleted")
      if(onSuccess && typeof onSuccess === "function") onSuccess(resp)
      setIsSavingEvent(false)
      setIsSaving(false)
    },() => {
      toast.error("Could not delete event")
      setIsSavingEvent(false)
      setIsSaving(false)
    })
  }

  const updateEvent = (eventId, eventData, onSuccess, onFail=null) => {
    API.query('PATCH',`api/chartevents/${eventId}/`,{chart_id:props.activeCase.charts[0].id},eventData,(resp) => {
      toast.success("All changes saved")
      if(onSuccess && typeof onSuccess === "function") onSuccess(resp)
      setIsSaving(false)
    },(error) => {
      toast.error("Could not save event")
      if(onFail && typeof onFail === "function") onFail(error)
      setIsSaving(false)
    })
  }

  //load events and details
  useEffect(() => {
    console.log("ChartingModal props: ", props)
    if(searchParams.get('quick') && !searchParams.get('case_id')) {
      if(props.facilities) {
        setFacilities(props.facilities)
        setPatientModalOpen(true)
        setIsLoading(false)
      }else {
        let queries = [
          { 'method': 'GET', 'path': '/api/facilities/', 'params': {},},
          { 'method': 'GET', 'path': '/api/allergies/', 'params': {},},
          { 'method': 'GET', 'path': '/api/patienthistory/', 'params': {},},
          { 'method': 'GET', 'path': '/api/medications/', 'params': {},},
          { 'method': 'GET', 'path': '/api/events/', 'params': {},}
        ]
        API.all(queries, function (resp) {
          setFacilities(resp[0].map(i => ({"label":i.name,"value":i.id})))
          setAllergies(resp[1].map(i => ({"label":i.name,"value":i.id})))
          setPatientHistories(resp[2].map(i => ({"label":i.name,"value":i.id})))
          setMedications(resp[3])
          setEventEvents(resp[4])
          setPatientModalOpen(true)
          setIsLoading(false)
        },() => {
          props.setErrorMessage("Could not load facilities")
        })
      }
    }else {
      let queries = [
        { 'method': 'GET', 'path': '/api/chartevents/', 'params': {chart_id:props.activeCase.charts[0].id},},
        { 'method': 'GET', 'path': '/api/chartingdetails/', 'params': {facility_id:props.activeCase.facility_id},},
        { 'method': 'GET', 'path': '/api/facilities/', 'params': {},},
        { 'method': 'GET', 'path': '/api/allergies/', 'params': {},},
        { 'method': 'GET', 'path': '/api/patienthistory/', 'params': {},},
        { 'method': 'GET', 'path': '/api/facilitylogfieldsettings/', 'params': {facility_id:props.activeCase.facility_id},},
        { 'method': 'GET', 'path': '/api/medications/', 'params': {},},
        { 'method': 'GET', 'path': '/api/events/', 'params': {},},
      ]
      API.all(queries, function (resp) {
        let details = resp[1][0]
        let autoTime = autoLogTime
        let labTime = labWarningTime
        let cpgTime = cpgWarningTime

        setFacilities(resp[2].map(i => ({"label":i.name,"value":i.id})))
        setAllergies(resp[3].map(i => ({"label":i.name,"value":i.id})))
        setPatientHistories(resp[4].map(i => ({"label":i.name,"value":i.id})))
        const orderedData = resp[5][0].log_fields.sort((a,b) => a.ordinal - b.ordinal)
        setMedications(resp[6])
        setEventEvents(resp[7])
        const logData = orderedData.map(lf => ({name:lf.field.name,propName:lf.field.column,units:lf.field.unit,value:""}))
        setData(logData)

        if(details) {
          if(details['auto_logging_time'] && details['auto_logging_unit']) {
            if(details['auto_logging_unit'] == "hour") {
              autoTime = parseInt(details['auto_logging_time'])*3600
            }else if(details['auto_logging_unit'] == "min") {
              autoTime = parseInt(details['auto_logging_time'])*60
            }else if(details['auto_logging_unit'] == "sec") {
              autoTime = parseInt(details['auto_logging_time'])
            }
          }
          if(details['lab_warning_time'] && details['lab_warning_unit']) {
            if(details['lab_warning_unit'] == "hour") {
              labTime = parseInt(details['lab_warning_time'])*3600
            }else if(details['lab_warning_unit'] == "min") {
              labTime = parseInt(details['lab_warning_time'])*60
            }else if(details['lab_warning_unit'] == "sec") {
              labTime = parseInt(details['lab_warning_time'])
            }
          }
          if(details['cpg_warning_time'] && details['cpg_warning_unit']) {
            if(details['cpg_warning_unit'] == "hour") {
              cpgTime = parseInt(details['cpg_warning_time'])*3600
            }else if(details['cpg_warning_unit'] == "min") {
              cpgTime = parseInt(details['cpg_warning_time'])*60
            }else if(details['cpg_warning_unit'] == "sec") {
              cpgTime = parseInt(details['cpg_warning_time'])
            }
          }
        }
        setLabWarningTime(labTime)
        setAutoLogTime(autoTime)
        setCPGWarningTime(cpgTime)
        importEvents(resp[0],[...logData])
        setDetails(details)
        setIsLoading(false)
          
      },() => {
        toast.error("Failed to load chart")
        setIsLoading(false)
      })
    }
  }, [])

  //elapsed timer
  useEffect(() => {
    const interval = setInterval(() => {
      let newElapsed = elapsed+1
      setElapsed(newElapsed)
      if(!isLoading){
        //only check timer warnings if pump is on
        const pumpTimer = timers.find(t => t.startTime !== null && t.name == "PUMP")
        if(pumpTimer) {
          const pumpElapsed = (new Date().getTime() - pumpTimer.startTime.getTime())/1000
          //AUTO LOG
          let remainder = pumpElapsed%autoLogTime
          let timeLeft = autoLogTime - remainder
          setAutoLogWarning(timeLeft < autoLogWarningTime)
          let lastAutoLog = getEventsByType(EVENT_TYPE_AUTO_LOG).slice(-1)
          let lastTime = pumpElapsed
          if(lastAutoLog.length) {
            lastAutoLog = lastAutoLog[0]
            lastTime = Math.round((new Date().getTime() - new Date(lastAutoLog.time).getTime())/1000)
          }
          if(pumpElapsed > autoLogTime && lastTime > autoLogTime && !isSavingEvent) {
            addEvent(EVENT_TYPE_AUTO_LOG,EVENT_TYPES[EVENT_TYPE_AUTO_LOG],"",true)
            setAutoLogWarning(false)
          }

          //LAB WARNING
          lastTime = pumpElapsed
          let lastLab = getEventsByType(EVENT_TYPE_LABS).slice(-1)
          if(lastLab.length) {
            lastLab = lastLab[0]
            lastTime = (new Date().getTime() - new Date(lastLab.time).getTime())/1000
          }
          setLabWarningReached(pumpElapsed > 0 && lastTime >= labWarningTime) 

          //CPG WARNING
          lastTime = pumpElapsed
          const clampTimer = timers.find(t => t.startTime !== null && t.name == "CLAMP")
          let lastCPGOn = getEventsByType(EVENT_TYPE_CPG_ON).slice(-1)
          let lastCPGOff = getEventsByType(EVENT_TYPE_CPG_OFF).slice(-1)
          //no need to check if it's never been on
          if(lastCPGOn.length) {
            lastCPGOn = lastCPGOn[0]
            //no need to check if it's currently on
            if(lastCPGOn.time.getTime()/1000 > pumpElapsed) {
              lastTime = 0
              //check if it's been turned off
              if(lastCPGOff.length) {
                lastCPGOff = lastCPGOff[0]
                if(lastCPGOff.time.getTime() > lastCPGOn.time.getTime()) {
                  //it's been turned on and is currently off, let's check warning
                  //we don't want to show warning if clampTimer is off
                  if(clampTimer) {
                    //use clamp time if greater than last off time
                    const clampTime = new Date(clampTimer.startTime).getTime()
                    const offTime = new Date(lastCPGOff.time).getTime()
                    const now = new Date().getTime();
                    lastTime = (now - Math.max(clampTime,offTime))/1000                  
                  }
                  
                }
              }
            }
          }
          setCPGWarningReached(pumpElapsed > 0 && lastTime >= cpgWarningTime)
        }
      }
    },1000)

    return () => {
      clearInterval(interval)
    }
  },[elapsed])

  // update do2 when events change
  useEffect(() => {
    const labEvents = getEventsByType(EVENT_TYPE_LABS).sort((a,b) => Date.parse(b.time) - Date.parse(a.time))
    const history = labEvents.filter(e => e.modalData.type === 3).map(h => h.modalData)
    const flowRaw = data.find(d => d.propName=="flow")
    const flow = parseFloat(flowRaw ? flowRaw.value : 0)
    let hgb,so2,po2
    history.forEach(h => {
      if(!hgb && h.hgb) hgb = parseFloat(h.hgb)
      if(!so2 && h.s02) so2 = parseFloat(h.s02)
      if(!po2 && h.p02) po2 = parseFloat(h.p02)
    })
    setDO2(flow && hgb && so2 && po2 ? (flow * 10 * (((hgb * 1.34) * (so2/100)) + (po2 * 0.0031))).toFixed(2) : 0)
  },[events,data])

  const toggleTimer = (timerIndex,off=false, details="", modalData=null, makeEvent=true, onSuccess=null, onFail=null) => {
    const newTimers = [...timers]
    const t = newTimers[timerIndex]

    if(t.startTime) {
      t.startTime = null
      t.off = null
    } else {
      t.startTime = new Date()
      t.off = off
    }
    setTimers(newTimers)
    if(makeEvent) {
      let typeIndex = t.name.replaceAll(" ","_") + "_" + (t.startTime && !off ? "ON" : "OFF")
      let type = EVENT_TYPES[typeIndex]
      let event = typeIndex.replaceAll("_"," ")
      addEvent(type, event, details,false,modalData,null,onSuccess,onFail)
    }
  }

  const onPrePumpComplete = () => {
    let chartData = {pre_pump_check_completed:true}
    API.query('PATCH',`api/charts/${props.activeCase.charts[0].id}/`,{},chartData,(resp) => {
      addEvent(EVENT_TYPE_PRE_PUMP,"PRE PUMP","Completed Pre-Pump Checklist",false,null,null,(resp) => {
        toast.success("Pre-Pump Check Completed!")
        setPrePumpModelOpen(false)
      },(error) => {
        props.setErrorMessage(error)
      })
    },() => {
      toast.error("Could NOT Complete Pre-PumpCheck")
    })
  }

  const onPumpClick = () => {
    const pumpTimer = timers.find(t => t.startTime !== null && t.name == "PUMP")

    if(pumpTimer) {
      //shut down other timers
      let saveEvents = []
      let totalTime = 0
      let time
      let details = ""
      //cpg
      if(timers[3].startTime) {
        time = new Date()
        totalTime = (time.getTime() - timers[3].startTime.getTime())/1000
        details = "Total Time: " + getFormattedTime(totalTime)
        saveEvents.push({
          type: EVENT_TYPE_CPG_OFF,
          time: time,
          event: EVENT_TYPES[EVENT_TYPE_CPG_OFF],
          details: details,
          modalData: null,
          data: data.map(a => ({value:"---"})),
          saveData: {
            chart_id:props.activeCase.charts[0].id,
            type: EVENT_TYPE_CPG_OFF,
            time: new Date(time.getTime() - (time.getTimezoneOffset() * 60000)).toJSON(),
            event: EVENT_TYPES[EVENT_TYPE_CPG_OFF],
            details: details
          }
        })
      }
      //carrest
      if(timers[2].startTime) {
        time = new Date()
        totalTime = (time.getTime() - timers[2].startTime.getTime())/1000
        details = "Total Time: " + getFormattedTime(totalTime)
        saveEvents.push({
          type: EVENT_TYPE_C_ARREST_OFF,
          time: time,
          event: EVENT_TYPES[EVENT_TYPE_C_ARREST_OFF],
          details: details,
          modalData: null,
          data: data.map(a => ({value:"---"})),
          saveData: {
            chart_id:props.activeCase.charts[0].id,
            type: EVENT_TYPE_C_ARREST_OFF,
            time: new Date(time.getTime() - (time.getTimezoneOffset() * 60000)).toJSON(),
            event: EVENT_TYPES[EVENT_TYPE_C_ARREST_OFF],
            details: details
          }
        })
      }
      //clamp
      if(timers[1].startTime) {
        time = new Date()
        totalTime = (time.getTime() - timers[1].startTime.getTime())/1000
        details = "Total Time: " + getFormattedTime(totalTime)
        saveEvents.push({
          type: EVENT_TYPE_CLAMP_OFF,
          time: time,
          event: EVENT_TYPES[EVENT_TYPE_CLAMP_OFF],
          details: details,
          modalData: null,
          data: data.map(a => ({value:"---"})),
          saveData: {
            chart_id:props.activeCase.charts[0].id,
            type: EVENT_TYPE_CLAMP_OFF,
            time: new Date(time.getTime() - (time.getTimezoneOffset() * 60000)).toJSON(),
            event: EVENT_TYPES[EVENT_TYPE_CLAMP_OFF],
            details: details
          }
        })
      }
      //pump
      time = new Date()
      totalTime = (time.getTime() - timers[0].startTime.getTime())/1000
      details = "Total Time: " + getFormattedTime(totalTime)
      saveEvents.push({
        type: EVENT_TYPE_PUMP_OFF,
        time: time,
        event: EVENT_TYPES[EVENT_TYPE_PUMP_OFF],
        details: details,
        modalData: null,
        data: data.map(a => ({value:"---"})),
        saveData: {
          chart_id:props.activeCase.charts[0].id,
          type: EVENT_TYPE_PUMP_OFF,
          time: new Date(time.getTime() - (time.getTimezoneOffset() * 60000)).toJSON(),
          event: EVENT_TYPES[EVENT_TYPE_PUMP_OFF],
          details: details
        }
      })

      addEventBatch(saveEvents, () => {
        if(timers[3].startTime) toggleTimer(3,false,"",null,false)
        if(timers[2].startTime) toggleTimer(2,false,"",null,false)
        if(timers[1].startTime) toggleTimer(1,false,"",null,false)
        toggleTimer(0,false,"",null,false)
      })

      //shut down warnings
      setAutoLogWarning(false)
      setLabWarningReached(false)
      setCPGWarningReached(false)
      //reset data
      let sourceData = [...data]
      sourceData.forEach(d => d.value = "---")
      setData(sourceData)
    }else {
      toggleTimer(0)
    }
  }

  const onClampClick = () => {
    const pumpTimer = timers.find(t => t.startTime !== null && t.name == "PUMP")

    if(pumpTimer) {
      const clampTimer = timers.find(t => t.startTime !== null && t.name == "CLAMP")
      if(clampTimer) {
        let lastClampOn = getEventsByType(EVENT_TYPE_CLAMP_ON).slice(-1)[0]
        let totalTime = (new Date().getTime() - new Date(lastClampOn.time).getTime())/1000
        let details = "Total Time: " + getFormattedTime(totalTime)
        toggleTimer(1,false,details)
        //turn off cpg, but no new event
        if(timers[3].startTime) toggleTimer(3,false,"",null,false)
      }else {
        toggleTimer(1)
      }
      
    }else {
      props.setErrorMessage("Pump must be on first")
    }
  }

  const onCArrestClick = () => {
    const pumpTimer = timers.find(t => t.startTime !== null && t.name == "PUMP")

    if(pumpTimer) {
      const cArrestTimer = timers.find(t => t.startTime !== null && t.name == "C ARREST")
      if(cArrestTimer) {
        let lastCArrestOn = getEventsByType(EVENT_TYPE_C_ARREST_ON).slice(-1)[0]
        let totalTime = (new Date().getTime() - new Date(lastCArrestOn.time).getTime())/1000
        let details = "Total Time: " + getFormattedTime(totalTime)
        toggleTimer(2,false,details)
      }else {
        toggleTimer(2)
      }
    }else {
      props.setErrorMessage("Pump must be on first")
    }
  }

  const onCPGClick = () => {
    const pumpTimer = timers.find(t => t.startTime !== null && t.name == "PUMP")
    if(pumpTimer) {
      const timer = timers.find(t => t.name == "CPG")
      if(timer.startTime) {
        if(timer.off) {
          //need to turn off off timer and on on :)
          toggleTimer(3,false,"",null,false)
          toggleTimer(3,false,"",null,true)
        }else {
          setCPGModalOpen(true)
        }
      }else {
        toggleTimer(3,false,"",null,true)
      }
    }else {
      props.setErrorMessage("Pump must be on to chart CPG")
    }
  }

  const onCPGClose = () => {
    setEditEvent(null)
    setCPGModalOpen(false)
  }

  const onCPGSave = (cpgData,time) => {
    if(cpgData) {
      if(cpgData.type == 1) delete cpgData['retro']
      let details = `Type: ${cpgData['typeName']}, Amount: ${cpgData['amount']} ml, Temp: ${cpgData['temp']}°, `
      details += `Line: ${cpgData['line' ]} mmHg, Flow: ${cpgData['flow-rate']} ml/min`
      // let details = "Type: " + cpgData['typeName']
      const timer = timers[3]
      if(timer.startTime) {
        let lastCPGOn = getEventsByType(EVENT_TYPE_CPG_ON).slice(-1)[0]
        let totalTime = (time.getTime() - new Date(lastCPGOn.time).getTime())/1000
        details += " Total Time: " + getFormattedTime(totalTime)
      }
      //if(cpgData['retro']) details += `, Retro: ${cpgData['retro']} mmHg`
      if(cpgData['comment']) details += ', Comment: ' + cpgData['comment']
      if(editEvent) {
        setIsSaving(true)
        const newEvents = [...events]
        const eventIndex = newEvents.findIndex(e => e.id == editEvent.id)
        newEvents[eventIndex].modalData = cpgData
        newEvents[eventIndex].details = details
        newEvents[eventIndex].time = time
        newEvents.sort((a,b)=>a.time.getTime()-b.time.getTime());
        
        let saveData = {
          cpg_id:cpgData.id,
          cpg_event:formatModalSaveData(cpgData),
          details,
          time:new Date(time.getTime() - (time.getTimezoneOffset() * 60000)).toJSON()
        }
        updateEvent(editEvent.id, saveData, (resp) => {
          setEvents(newEvents)
          setCPGModalOpen(false)
          setEditEvent(null)
        },(error) => {
          props.setErrorMessage(error)
        })
      }else {
        setIsSaving(true)
        toggleTimer(3,false,"",null,false)
        toggleTimer(3,true,details,cpgData,true,(resp) => {
          setCPGModalOpen(false)
        },(error) => {
          props.setErrorMessage(error)
        })
      }
    }
  }

  const onLabsClose = () => {
    setEditEvent(null)
    setLabsModalOpen(false)
  }

  const onLabsSave = (labData,time) => {
    if(labData) {
      let details = `Type: ${labData['typeName']}`
      if(labData['ph']) details += `, PH: ${labData['ph']}`
      if(labData['hgb']) details += `, Hgb: ${labData['hgb']} gm/dL` 
      if(labData['c02']) details += `, C02: ${labData['c02']} gm/dL` 
      if(labData['lac']) details += `, LAC: ${labData['lac']} mg/dL` 
      if(labData['p02']) details += `, P02: ${labData['p02']} mmHg` 
      if(labData['act']) details += `, ACT: ${labData['act']} Sec` 
      if(labData['hc03']) details += `, HC03: ${labData['hc03']} mmol/L` 
      if(labData['k_plus']) details += `, K+: ${labData['k_plus']} mmol/L` 
      if(labData['s02']) details += `, S02: ${labData['s02']} %` 
      if(labData['na_plus']) details += `, Na+: ${labData['na_plus']} mmol/L`
      if(labData['hepcon1']) details += `, HEPCON: ${labData['hepcon1']} mg/kg`
      if(labData['hepcon2']) details += `, HEPCON: ${labData['hepcon2']} U/ml`
      if(labData['iCa1']) details += `, iCa: ${labData['iCa1']} mg/dL`
      if(labData['iCa2']) details += `, iCa: ${labData['iCa2']} mmol/L`
      if(labData['hct']) details += `, HCT: ${labData['hct']} %`
      if(labData['glu']) details += `, GLU: ${labData['glu']} mg/dL`
      
      if(editEvent) {
        setIsSaving(true)
        const newEvents = [...events]
        const eventIndex = newEvents.findIndex(e => e.id == editEvent.id)
        newEvents[eventIndex].modalData = labData
        newEvents[eventIndex].details = details
        newEvents[eventIndex].time = time
        newEvents.sort((a,b)=>a.time.getTime()-b.time.getTime());
        
        let saveData = {
          lab_id:labData.id,
          lab_event:formatModalSaveData(labData),
          details,
          time:new Date(time.getTime() - (time.getTimezoneOffset() * 60000)).toJSON()
        }
        updateEvent(editEvent.id, saveData, (resp) => {
          setEvents(newEvents)
          setEditEvent(null)
          setLabsModalOpen(false)
        },(error) => {
          props.setErrorMessage(error)
        })
      }else {
        setIsSaving(true)
        addEvent(EVENT_TYPE_LABS,"LABS",details,false,labData,time,(resp) => {
          setLabsModalOpen(false)
        },(error) => {
          props.setErrorMessage(error)
        })
      }
    }
  }

  const onTempClose = () => {
    setEditEvent(null)
    setTempModalOpen(false)
  }

  const onTempSave = (tempData,time) => {
    if(tempData) {
      let event = ""
      let details = ""
      if(tempData['warm-temp']) {
        event = "Temp warmed"
        details = `${tempData['warm-temp']}°`
        tempData['cool-temp'] = null
        tempData['drift'] = null
      }else if(tempData['cool-temp']) {
        event = "Temp cooled"
        details = `${tempData['cool-temp']}°`
        tempData['warm-temp'] = null
        tempData['drift'] = null
      }else if(tempData['drift']) {
        event = "Drift"
        details = `${tempData['drift']}°`
        tempData['warm-temp'] = null
        tempData['cool-temp'] = null
      }
      if(event != "") {
        if(editEvent) {
          setIsSaving(true)
          const newEvents = [...events]
          const eventIndex = newEvents.findIndex(e => e.id == editEvent.id)
          newEvents[eventIndex].modalData = tempData
          newEvents[eventIndex].event = event
          newEvents[eventIndex].details = details
          newEvents[eventIndex].time = time
          newEvents.sort((a,b)=>a.time.getTime()-b.time.getTime());

          let saveData = {
            temp_id:tempData.id,
            temp_event:formatModalSaveData(tempData),
            event,
            details,
            time:new Date(time.getTime() - (time.getTimezoneOffset() * 60000)).toJSON()
          }
          updateEvent(editEvent.id, saveData, (resp) => {
            setEvents(newEvents)
            setEditEvent(null)
            setTempModalOpen(false)
          },(error) => {
            props.setErrorMessage(error)
          })
        }else {
          setIsSaving(true)
          addEvent(EVENT_TYPE_TEMP,event,details,false,tempData,time,(resp) => {
            setTempModalOpen(false)
          },(error) => {
            props.setErrorMessage(error)
          })
        }
      }
    }
  }

  const onMedsClose = () => {
    setEditEvent(null)
    setMedicationsModalOpen(false)
  }

  const onMedsSave = (medsData,time) => {
    if(medsData) {
      let event = ""
      let details = ""
      if(medsData['medication']) {
        event = `${medsData['medication'].medication}`
      }
      if(medsData['amount']) {
        if(medsData['medication'].use_unit_num && medsData['medication'].description != null && medsData['unit_num']) {
          details = `${medsData['amount']} ${medsData['unit']}, ${medsData['unit_num']}, ${medsData['medication'].description}`
        } else if(medsData['medication'].use_unit_num && medsData['medication'].description != null) {
          details = `${medsData['amount']} ${medsData['unit']}, ${medsData['medication'].description}`
        } else if (medsData['unit_num'] != null) { 
          details = `${medsData['amount']} ${medsData['unit']}, ${medsData['unit_num']}`
        } else {
          details = `${medsData['amount']} ${medsData['unit']}`
        }
      }
      if(event != "") {
        if(editEvent) {
          setIsSaving(true)
          const newEvents = [...events]
          const eventIndex = newEvents.findIndex(e => e.id == editEvent.id)
          newEvents[eventIndex].modalData = medsData
          newEvents[eventIndex].event = event
          newEvents[eventIndex].details = details
          newEvents[eventIndex].time = time
          newEvents.sort((a,b)=>a.time.getTime()-b.time.getTime());
          
          let saveData = {
            med_id:medsData.id,
            med_event:formatModalSaveData(medsData),
            event,
            details,
            time:new Date(time.getTime() - (time.getTimezoneOffset() * 60000)).toJSON()
          }
          saveData.med_event.medication = saveData.med_event.medication.id
          updateEvent(editEvent.id, saveData, (resp) => {
            setEvents(newEvents)
            setEditEvent(null)
            setMedicationsModalOpen(false)
          },(error) => {
            props.setErrorMessage(error)
          })
        }else {
          setIsSaving(true)
          addEvent(EVENT_TYPE_MEDS,event,details,false,medsData,time,(resp) => {
            setMedicationsModalOpen(false)
          },(error) => {
            props.setErrorMessage(error)
          })
        }
      }
    }
  }

  const onEventsClose = () => {
    setEditEvent(null)
    setEventsModalOpen(false)
  }

  const onEventsSave = (eventData,time) => {
    if(eventData) {
      let event = ""
      let details = ""
      if(eventData['event']) {
        event = `${eventData['event'].name}`
      }
      if(eventData['info']) {
        details = `${eventData['info']} ${eventData['unit']}`
      }
      if(event != "") {
        if(editEvent) {
          setIsSaving(true)
          const newEvents = [...events]
          const eventIndex = newEvents.findIndex(e => e.id == editEvent.id)
          newEvents[eventIndex].modalData = eventData
          newEvents[eventIndex].event = event
          newEvents[eventIndex].details = details
          newEvents[eventIndex].time = time
          newEvents.sort((a,b)=>a.time.getTime()-b.time.getTime());

          let saveData = {
            event_id:eventData.id,
            event_event:formatModalSaveData(eventData),
            event,
            details,
            time:new Date(time.getTime() - (time.getTimezoneOffset() * 60000)).toJSON()
          }
          saveData.event_event.event = saveData.event_event.event.id
          updateEvent(editEvent.id, saveData, (resp) => {
            setEvents(newEvents)
            setEditEvent(null)
            setEventsModalOpen(false)
          },(error) => {
            props.setErrorMessage(error)
          }) 
        }else {
          setIsSaving(true)
          addEvent(EVENT_TYPE_EVENTS,event,details,false,eventData,time,(resp) => {
            setEventsModalOpen(false)
          },(error) => {
            props.setErrorMessage(error)
          })
        }
      }
    }
  }

  const onEventDoubleClick = (event,eventData,index) => {
    let data = {...eventData}
    setConfirmationModalOpen(true)
    setEditEvent(data)
  }

  const onDeleteEventConfirmation = () => {
    deleteEvent(editEvent.id, () => {
      const eventIndex = events.findIndex(e => e.id == editEvent.id)
      const newEvents = events.toSpliced(eventIndex,1)
      setEvents(newEvents)
    })
    setConfirmationModalOpen(false)
    setEditEvent(null)
  }

  const onDeleteEventCancel = () => {
    setConfirmationModalOpen(false)
    setEditEvent(null)
  }

  const onDetailsDoubleClick = (event, eventData, index) => {
    let data = {...eventData}
    data.modalData = {...data.modalData}
    if(data.type) {
      if(data.type == EVENT_TYPE_CPG_OFF) {
        setCPGModalOpen(true)
      }else if(data.type == EVENT_TYPE_TEMP) {
        setTempModalOpen(true)
      }else if(data.type == EVENT_TYPE_LABS) {
        setLabsModalOpen(true)
      }else if(data.type == EVENT_TYPE_EVENTS) {
        //set event back to id instead of name for the select default
        if(!Number.isInteger(eventData.modalData.event)) {
          data.modalData.event = eventData.modalData.event.id
        }else {
          data.modalData.event = eventData.modalData.event
        }
        setEventsModalOpen(true)
      }else if(data.type == EVENT_TYPE_MEDS) {
        if(!Number.isInteger(eventData.modalData.medication)) {
          data.modalData.medication = data.modalData.medication.id
        }else {
          data.modalData.medication = data.modalData.medication
        }
        setMedicationsModalOpen(true)
      }else {
        setCommentModalOpen(true)
      }
      setEditEvent(data)
    }
  }

  const onDataClick = (event, eventData, index) => {
    //console.log(event,eventData,index)
    const newEvents = [...events]
    const eventIndex = newEvents.findIndex(e => e.id == eventData.id)
    event.target.focus();
    event.target.contentEditable = true;
    const range = document.createRange();
    range.selectNodeContents(event.target);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range)
    // newEvents[eventIndex].data[index].value = ""
    setEvents(newEvents)
  }

  const onDataBlur = (event, eventData, index) => {
    let newValue = event.target.innerHTML
    if (Number.isNaN(Number.parseFloat(newValue))) {
      newValue = null
    }
    const newEvents = [...events]
    const eventIndex = newEvents.findIndex(e => e.id == eventData.id)

    if(newValue != newEvents[eventIndex].data[index].value) {
      if(newValue !== null) {
        newEvents[eventIndex].data[index].value = newValue
        let saveData = {}
        saveData[data[index].propName] = newValue
        updateEvent(newEvents[eventIndex].id,saveData,(resp) => {
          setEvents(newEvents)
        })
      }else {
        event.target.innerHTML = "---"
        newEvents[eventIndex].data[index].value = "---"
        setEvents(newEvents)
      }    
    }
  }

  const onChangeData = (index,value) => {
    const newData = [...data]
    newData[index].value = value
    setData(newData)
  }

  return isLoading ? <LoadingModal/> : <div className="modal full-modal is-active" style={{zIndex:2000}}>
    {
      patientModalOpen
      &&
      <ChartingPatientModal
        caseId={props.activeCaseId}
        facilityOptions={facilities}
        allergyOptions={allergies}
        historyOptions={patientHistories}
        onCaseCreated={(newCase) => props.setActiveCase(newCase)}
        onCaseUpdated={(updatedCase) => props.updateActiveCase(updatedCase)}
        onClose={() => setPatientModalOpen(false)}
        setErrorMessage={props.setErrorMessage}
      />
    }

    {
      tempModalOpen
      &&
      <TemperatureModal
        isSaving={isSaving}
        onClose={onTempClose}
        onSave={onTempSave}
        data={editEvent?editEvent.modalData:null}
        time={editEvent?editEvent.time:null}
      />
    }

    {
      medicationsModalOpen
      &&
      <MedicationsModal
        isSaving={isSaving}
        onClose={onMedsClose}
        onSave={onMedsSave}
        data={editEvent?editEvent.modalData:null}
        time={editEvent?editEvent.time:null}
        medications={medications}
        setErrorMessage={props.setErrorMessage}
        filter={details && details.medications ? details.medications : undefined}
      />
    }

    {
      eventsModalOpen
      &&
      <EventsModal
        isSaving={isSaving}
        onClose={onEventsClose}
        onSave={onEventsSave}
        data={editEvent?editEvent.modalData:null}
        time={editEvent?editEvent.time:null}
        events={eventEvents}
        setErrorMessage={props.setErrorMessage}
        filter={details && details.events ? details.events : undefined}
      />
    }

    {
      labsModalOpen
      &&
      <LabsModal
        isSaving={isSaving}
        onClose={onLabsClose}
        onSave={onLabsSave}
        data={editEvent?editEvent.modalData:null}
        time={editEvent?editEvent.time:null}
        history={getEventsByType(EVENT_TYPE_LABS).sort((a,b) => Date.parse(b.time) - Date.parse(a.time))}
        setErrorMessage={props.setErrorMessage}
      />
    }

    {
      cpgModalOpen
      &&
      <CPGModal
        isSaving={isSaving}
        onClose={onCPGClose}
        onSave={onCPGSave}
        data={editEvent?editEvent.modalData:null}
        time={editEvent?editEvent.time:null}
      />
    }

    {
      prePumpModalOpen
      &&
      <ChartingPrePumpModal
        onClose={() => setPrePumpModelOpen(false)}
        onSave={onPrePumpComplete}
      />
    }

    {
      confirmationModalOpen
      &&
      <ConfirmationModal
        onConfirm={onDeleteEventConfirmation}
        onClose={onDeleteEventCancel}
        message={`You are about to permanently remove this ${EVENT_TYPES[editEvent.type]} event. This cannot be undone.`}
      />
    }

    {
      pushDetailsModalOpen
      &&
      <ConfirmationModal
        onConfirm={pushChartDetails}
        onClose={() => setPushDetailsModalOpen(false)}
        message={'You are about to push chart details. This could permanently overwrite some details that have been manually entered'}
      />
    }

    {
      commentModalOpen
      &&
      <CommentModal
        onSave={onAddCommentSave}
        onCancel={onAddCommentCancel}
        time={editEvent?editEvent.time:null}
        comment={editEvent?editEvent.details:null}
      />

    }

    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head p-1" style={{display:"block"}}>
        <div className="w-100">
          <div className="level w-100 py-0">
            <div className="level-left">
              <a onClick={props.onCancel} className="level-item has-text-dark mr-5"><FaArrowCircleLeft size={30} /></a>

              <div className="level-item">
                <div className="table-container rounded has-border is-clickable" style={{width:620}} onClick={() => setPatientModalOpen(true)}>
                  <table className="table is-bordered mb-0">
                    <tbody>
                      <tr>
                        <td style={{width:240,borderTop:0,borderLeft:0,borderBottom:0}}>{props?.activeCase?.patient?.first_name??""} {props?.activeCase?.patient?.middle_initial??""} {props?.activeCase?.patient?.last_name??""}</td>
                        <td style={{width:120,borderTop:0}}>
                          <span className="is-size-7">HT:</span> {formatHeight(props?.activeCase)}
                        </td>
                        <td style={{width:120,borderTop:0,borderRight:0}}>
                          <span className="is-size-7">WT:</span> {formatWeight(props?.activeCase)}
                        </td>
                        <td style={{width:120,borderTop:0,borderRight:0}}>
                          <span className="is-size-7">BSA:</span> {calculateBSA(props?.activeCase)}
                        </td>
                        <td style={{width:120,borderTop:0,borderRight:0}}>
                          <span className="is-size-7">DO2:</span> {do2}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* <table className="table is-bordered">
                    <tbody>
                      <tr>
                        <td style={{width:120,borderTop:0,borderBottom:0,borderLeft:0}}><span className="is-size-7">BSA (m2):</span></td>
                        <td style={{width:140,borderTop:0,borderBottom:0}}><span className="is-size-7">B VOL (ml):</span></td>
                        <td style={{width:80,borderTop:0,borderBottom:0}}><span className="is-size-7">CI:</span></td>
                        <td style={{width:80,borderTop:0,borderBottom:0}}><span className="is-size-7">DO2:</span></td>
                        <td style={{width:80,borderTop:0,borderBottom:0,borderRight:0}}><span className="is-size-7">RBC:</span></td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>
              </div>
              <Select
                className="level-item ml-5"
                menuPlacement="top"
                placeholder="Jump to Section"
                options={tabOptions}
                onChange={(option) => props.setTab(option.value)}
              />
              <button className="level-item button ml-5" onClick={pushChartDetails}>
                <FaLongArrowAltRight size={20} />
                <span className="ml-2">Details</span>
              </button>
            </div>
            <div className="level-right">
              <a onClick={props.onSave} className="level-item has-text-dark"><FaArrowCircleRight size={30} /></a>
            </div>
          </div>
        </div>
        <div className="w-100">
          <div className="px-5 pt-5">
            <div className={"has-background-white rounded w-100 is-flex" + (autoLogWarning?" border-pulse":"")}>
              <button 
                className="button is-medium is-ghost mt-4"
                onClick={() => addEvent(EVENT_TYPE_MANUAL_LOG,EVENT_TYPES[EVENT_TYPE_MANUAL_LOG],"",true)}
              >
                <FaPlusCircle className="title"/>
              </button>
              <table className="table has-background-white rounded w-100 has-text-centered">
                <thead>
                  <tr>
                    {
                      data.map((value,i) => {
                        let style = {width:80}
                        return <th style={style} key={i}>{value.name}</th>
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {
                      data.map((value,i) => {
                        let style = {width:80}
                        return (
                          <td key={i} style={style}> 
                            <input
                              type="text"
                              placeholder="---"
                              value={value.value}
                              onChange={(e) => onChangeData(i,e.target.value)}
                              onFocus={(e) => e.target.select()}
                              className={"w-100 is-size-5 has-text-centered"}
                            />
                          </td>
                        )
                      })
                    }
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div className="p-5 columns">
              <div 
                className="column is-narrow is-flex is-align-items-center is-clickable"
                onClick={() => setPrePumpModelOpen(true)}
              >
                <GoChecklist size={30} />
              </div>
              {
                buttons.map((button,index) => {
                  let btnOn = false;
                  let timer = null;
                  let off = false;
                  let pulse = false;
                  if((button.name == "LABS" && labWarningReached) || (button.name == "CPG" && cpgWarningReached)) pulse = true
                  if(typeof button.timer !== "undefined") {
                    timer = timers[button.timer]
                    btnOn = (timer.startTime != null) && !timer.off
                    // killing this for now cpg no longer red when off per reeder
                    //if(timer.off) off = true
                  }
                  let elapsed = (timer && timer.startTime) ? (new Date().getTime() - timer.startTime.getTime())/1000 : null
                  return (
                    <div key={index} className="column">
                      <button
                        className={"button is-large w-100 level-item" + (btnOn?" is-success":"") + (off?" is-danger":"") + (pulse?" bg-pulse":"")}
                        onClick={button.callback}
                      >
                        <div>
                          {
                            (timer && timer.startTime)
                            ?
                            <>
                              <p className="is-size-5 has-text-weight-bold">{getFormattedTime(elapsed)}</p>
                              <p className="is-size-7">{button.name} {timer.off ? "OFF":"ON"}</p>
                            </>
                            :
                            <p className="has-text-weight-bold">{button.name}</p>
                          }
                        </div>
                      </button>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </header>
      
      <div className="modal-card-body p-0 w-100">
        <table className="table w-100 has-text-centered" style={{borderCollapse:"separate"}}>
          <thead>
            {/* Header */}
            <tr>
              <th className="has-background-white" style={{width:120,position:"sticky",top:0}}>TIME</th>
              <th className="has-background-white" style={{width:160,position:"sticky",top:0}}>EVENT</th>
              <th className="has-text-left has-background-white" style={{position:"sticky",top:0}}>DETAILS</th>
              { 
                data.map((data,i) => {
            
                  return (
                    <th 
                      className="has-background-white px-1" 
                      style={{width:80,position:"sticky",top:0}} 
                      key={i}
                    >
                      <p>{data.name}<br/><span className="is-size-7">{`(${data.units})`}</span></p>
                    </th>
                  )
                }) 
              }
            </tr>
            
          </thead>
          <tbody>
            {/* Data */}
            {
              [...events].reverse().map((event,idx) => <tr key={idx}>
                <td style={{width:120}}><p>{event.time.toLocaleTimeString([],{hour12:false})}</p></td>
                <td style={{width:160}} onDoubleClick={(e) => onEventDoubleClick(e,event,idx)}>{event.event}</td>
                <td className="has-text-left" onDoubleClick={(e) => onDetailsDoubleClick(e,event,idx)}>
                  <div className="clamp-2">
                    {event.details == "" ? <a className="is-size-7" onClick={() => onAddComment(event)}>Add Comment</a> : event.details}
                  </div>
                </td>
                {
                event.data.map((value,i) => {
                  let cellValue = value.value??"---"
                  return (
                    <td 
                      className="px-1"
                      tabIndex={20+idx}
                      style={{width:80}} 
                      key={i}
                      onClick={(e) => {onDataClick(e,event,i)}}
                      onFocus={(e) => {onDataClick(e,event,i)}}
                      onBlur={(e) => onDataBlur(e,event,i)}
                      suppressContentEditableWarning={true}
                    >
                      {cellValue}
                    </td>
                  )
                })
                }
              </tr>)
            }
          </tbody>
        </table>
      </div>

      <footer className="modal-card-foot p-3"></footer>
    </div>
  </div>
}

export default ChartingModal;