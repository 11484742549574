import React, { useEffect, useState, useMemo } from "react"
import API from "../../../../services/API"
import manufacturerForm from "../../../forms/manufacturer"
import ConfirmationModal from "../../../components/ConfirmationModal";
import SetupModal from "../../../components/SetupModal";
import contactForm from "../../../forms/subMenuManuContact"
import ErrorModal from "../../../components/ErrorModal";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import DataTable from 'react-data-table-component';
import VisitTimer from "../../../components/VisitTimer";
import Session from "../../../../services/Session"

function Manufacturer() {
    let subMenuString = 'Manufacturer Contacts'
    const [editValues, setEditValues] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const [manufacturers, setManufacturers] = useState([])
    const [isSaving, setIsSaving] = useState(false)
    const [showSetupModal, setShowSetupModal] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false)
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [showSubConfirmationModal,setShowSubConfirmationModal] = useState(false)
    const [isSubMenu, setIsSubMenu] = useState(true)
    const [showSubAdd, setShowSubAdd] = useState(false)
    const [subEditValues, setSubEditValues] = useState([])
    const [isSubEditMode, setIsSubEditMode] = useState(false)
    const [addNewSub, setAddNewSub] = useState(false)
    const [contacts, setContacts] = useState([])

    const onEditChange = (values) => {
        setEditValues(values)
    }
    
    const onSubEditChange = (values) => {
        setSubEditValues(values)
    }

    const onSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            let data = {
                'name': editValues['name'],
                'address': editValues['address'],
                'city': editValues['city'],
                'state': editValues['state'],
                'zip': editValues['zip']
            }
            let method = isEditMode ? 'PATCH' : 'POST'
            let uri = isEditMode ? `/api/manufacturers/${editValues.id}/` : '/api/manufacturers/'
            API.query(method, uri, {}, data, function (resp) {
                API.query('GET','/api/manufacturers/',{},{},function(response) {
                    setManufacturers(response)
                    setIsSaving(false)
                    setIsEditMode(false)
                    setShowSetupModal(false)
                    setEditValues([])
                },onDataLoadError)
            }, onDataLoadError)
        }
    }

    const onSubSave = () => {
        if(!isSaving) {
          setIsSaving(true)
          let data = {
            'name':subEditValues['name'],
            'phone':subEditValues['phone'],
            'email':subEditValues['email'],
            'fax':subEditValues['fax']
          }
          let method = isSubEditMode ? 'PUT' : 'POST'
          let uri = isSubEditMode ? `/api/manufacturercontacts/${subEditValues.id}/` : '/api/manufacturercontacts/'
          API.query(method,uri,{},data, function(response) {
            let newContacts = contacts
            if(isSubEditMode) {
              let i = newContacts.findIndex(j => j.id == subEditValues.id)
              if(i > -1) {
                data['id'] = subEditValues.id
                newContacts[i] = data
              }
            }else {
              data['id'] = response.id
              newContacts.push(data)
            }
    
            setContacts(newContacts)

            if(addNewSub) {
              let subData = {
                'name': editValues['name'],
                'address': editValues['address'],
                'city': editValues['city'],
                'state': editValues['state'],
                'zip': editValues['zip'],
                'manufacturer_contacts': appendContact(editValues['manufacturer_contacts'],response.id)
              }
              API.query('PUT',`/api/manufacturers/${editValues.id}/`,{},subData, function(resp) {
                let newManufacturers = manufacturers
                let i = newManufacturers.findIndex(j => j.id == editValues.id)
                if(i > 0) {
                  newManufacturers[i] = response
                }
                setManufacturers(newManufacturers)
              },onDataLoadError)
            }

            setIsSaving(false)
            setShowSubAdd(false)
            setAddNewSub(false)
            setSubEditValues([])
          },onDataLoadError)
        }
    }

    const appendContact = (contactEditValues, respID) => {
        if(!contactEditValues) {
            let newEditValues = []
            newEditValues.push(respID)
            return newEditValues
        } else {
            let newEditValues = contactEditValues
            newEditValues.push(respID)
            return newEditValues
        }
    }

    const onConfirmSubDelete = () => {
        API.query("DELETE",`/api/manufacturercontacts/${subEditValues.id}/`,{},{}, function(resp) {
          let newContacts = contacts.filter(j => j.id !== subEditValues.id)
          setContacts(newContacts)
          setShowSubConfirmationModal(false)
          setSubEditValues([])
          API.query("GET",`/api/manufacturers/${editValues.id}/`,{},{}, function(resp) {
            setEditValues(resp)
          },onDataLoadError)
        },onDataLoadError)
    }

    useEffect(() => {
        let queries = [
            { 'method': 'GET', 'path': '/api/manufacturers/', },
            { 'method': 'GET', 'path': '/api/manufacturercontacts/', }
          ]
          API.all(queries, function (resp) {
            setManufacturers(resp[0])
            setContacts(resp[1])
          }, onDataLoadError)
    }, []);

    const columns = useMemo(() => [
        {name:'Manufacturer Name',selector: row => row.name,sortable:true},
        {name:'Actions',selector: row =><div>
        <a title="Edit" onClick={() => onEditClicked(row)}><FaPencilAlt size={14} /></a>
        <a className="ml-3" title="Delete" onClick={() => onDeleteClicked(row)}><FaTrashAlt size={14} /></a>
        </div>}
    ])

    const contactColumns = useMemo(() => [
        {name:'Contact Name',selector: row => row.name,sortable:true},
        {name:'Contact Phone', selector: row => row.phone,sortable:true},
        {name:'Actions',selector: row =><div>
        <a title="Edit" onClick={() => onSubEditClicked(row)}><FaPencilAlt size={14} /></a>
        <a className="ml-3" title="Delete" onClick={() => onSubDeleteClicked(row)}><FaTrashAlt size={14} /></a>
        </div>}
    ])

    const filteredContacts = contacts.filter((obj) => {
        let i, valid=false, abort=false
        if(editValues.manufacturer_contacts && editValues.manufacturer_contacts.length != 0) {
            for(i=0; i<editValues.manufacturer_contacts.length && !abort; i++) {
                if(editValues.manufacturer_contacts[i] == obj.id) {
                    valid = true
                    abort = true
                }
            }
        }
        return valid
    })

    const onSetupModalClosed = () => {
        setShowSubAdd(false)
        setShowSetupModal(false)
        setEditValues([])
        setIsEditMode(false)
        setIsSubMenu(true)
    }

    const onSubAddClosed = () => {
        setShowSubAdd(false)
        setSubEditValues([])
        setIsSubEditMode(false)
    }

    const onEditClicked = (row) => {
        setEditValues(row)
        setShowSetupModal(true)
        setIsEditMode(true)
    }

    const onSubEditClicked = (row) => {
        setSubEditValues(row)
        setShowSubAdd(true)
        setIsSubEditMode(true)
    }

    const onSubAddNewClicked = () => {
        setShowSubAdd(true)
        setAddNewSub(true)
    }

    const onDeleteClicked = (row) => {
        setEditValues(row)
        setShowConfirmationModal(true)
    }

    const onSubDeleteClicked = (row) => {
        setSubEditValues(row)
        setShowSubConfirmationModal(true)
    }

    const onConfirmDelete = () => {
        API.query("DELETE",`/api/manufacturers/${editValues.id}/`,{},{}, function(resp) {
          let newManufacturers = manufacturers.filter(j => j.id !== editValues.id)
          setManufacturers(newManufacturers)
          setShowConfirmationModal(false)
          setEditValues([])
        },onDataLoadError)
    }

    const onDataLoadError = (error) => {
        setErrorMessage(error)
        setShowErrorModal(true)
        setIsSaving(false)
    }

    const onAddClick = () => {
        setShowSetupModal(true)
        setIsSubMenu(false)
    }

    const visited = () => {
        // get pageviewhistory id from user session data
        var user = Session.get('user')
        API.query('GET',`/api/pageviewhistory/${user.page_view_history}/`,{},{},function(response) {
          let data = {
            "admin_manufacturer": response.admin_manufacturer + 1
          }
          API.query('PATCH',`/api/pageviewhistory/${user.page_view_history}/`,{},data,function(resp) {}, onDataLoadError)
        }, onDataLoadError)
    }

    return <div>
        <p className="title is-size-2">Manufacturers</p>
        <p>Add, edit, or delete Manufacturer information.</p>
        <hr />
        <div className="columns">
            <div className="column is-4">
                <p className="title is-size-4">Manufacturers</p>
                <p>Add, edit, or delete Manufacturer information.</p>
            </div>
            <div className="column is-8">
                <button onClick={onAddClick} className="button is-medium is-fullwidth">Create New Manufacturer</button>
                <DataTable
                    striped
                    pagination
                    data={manufacturers}
                    columns={columns}
                />
            </div>
        </div>

        {
            showSetupModal
            &&
            <SetupModal
                titleString="Create New Manufacturer"
                form={manufacturerForm}
                editValues={editValues}
                onEditChange={onEditChange}
                isSaving={isSaving}
                onSave={onSave}
                onClose={onSetupModalClosed}

                subMenu={isSubMenu}
                subForm={contactForm}
                showSubAdd={showSubAdd}
                subColumns={contactColumns}
                filteredSub={filteredContacts}
                subEditValues={subEditValues}
                onSubEditChange={onSubEditChange}
                onSubSave={onSubSave}
                onSubClose={onSubAddClosed}
                onSubAddNewClicked={onSubAddNewClicked}
                string={subMenuString}
            />
        }

        {
            showErrorModal
            &&
            <ErrorModal
                message={errorMessage}
                onClose={() => setShowErrorModal(false)}
            />
        }

        {
            showConfirmationModal
            &&
            <ConfirmationModal
                message={"You are about to delete a LIVE Manufacturer. Please confirm."}
                onConfirm={onConfirmDelete}
                onClose={() => setShowConfirmationModal(false)}
            />
        }

        {
            showSubConfirmationModal
            &&
            <ConfirmationModal
                message={"You are about to delete a manufacturer contact. This cannot be undone."}
                onConfirm={onConfirmSubDelete}
                onClose={() => setShowSubConfirmationModal(false)}
            />
        }

        <VisitTimer visited={visited}/>
    </div>
}

export default Manufacturer;