import React,{ useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import SideNav from "./components/SideNav";
import Cases from "./pages/cases/Cases";
import Reports from "./pages/reports/Reports";
import Setup from "./pages/setup/Setup";
import Inventory from "./pages/inventory/Inventory";
import Settings from "./pages/settings/Settings";
import Search from "./pages/Search";
import Admin from "./pages/admin/Admin"
import toast, { Toaster } from 'react-hot-toast';
import Session from "../services/Session"
import ErrorModal from "./components/ErrorModal"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Home from "./pages/Home";
import TopNav from "./components/TopNav";
import FormBuilder from "./pages/FormBuilder";
import TopButtons from "./components/TopButtons";
import API from "../services/API";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function App() {
  const [isAuth, setIsAuth] = useState(null);
  const [menuItems, setMenuItems] = useState([])
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("")
  const [rolePermissions, setRolePermissions] = useState([])
  const [extraPermissions, setExtraPermissions] = useState([])

  const userDNE = () => {
    window.location.href = '/account/login/'
  }

  useEffect(() => {
    API.query('GET',`/api/usertoken/`,{},{},function(resp) {
      if(resp.length > 0) {
        Session.set('token',resp)

        API.query('GET',`/api/loggeduser/`,{},{},function(resp) {
          if(resp.length != 0) {
            setRolePermissions(resp[0].role.view_permissions)
            setExtraPermissions(resp[0].view_permissions)
            Session.set('user',resp[0])
            setIsAuth(true)
          } else {
            userDNE()
          }
        },userDNE)
      }
    })
    
  }, []);

  const onLogout = () => {
    localStorage.clear();
    setIsAuth(false)
  }

  const clearMenuItems = () => {
    setMenuItems([])
  }

  if(isAuth === null) return <p>Loading...</p>

  return <>
    <Toaster position="top-center"/>
    <TopNav permissions={rolePermissions} extraPermissions={extraPermissions}/>

    {
      isAuth
      &&
      <SideNav onLogout={onLogout} menuItems={menuItems}/>
    }

    {
      errorMessage != ""
      &&
      <ErrorModal
        onClose={() => setErrorMessage("")}
        message={errorMessage}
      />
    }
    
    <div className="w-100 h-100" style={{paddingLeft:150,paddingTop:80}}>
      <div className="h-100 p-5" style={{overflowY:'auto'}}>
        {isAuth && <TopButtons />}

        {isAuth ? (
          <Routes>
            <Route path="home" element={<Home permissions={rolePermissions} extraPermissions={extraPermissions} setErrorMessage={setErrorMessage}/>} />

            {
              (rolePermissions.includes(1) || extraPermissions.includes(1))
              &&
              <Route path="cases" element={<Cases permissions={rolePermissions} extraPermissions={extraPermissions} setMenuItems={setMenuItems} clearMenuItems={clearMenuItems} setErrorMessage={setErrorMessage}/>} />
            }
            
            {
              (rolePermissions.includes(2) || extraPermissions.includes(2))
              &&
              <Route path="setup" element={<Setup permissions={rolePermissions} extraPermissions={extraPermissions} setMenuItems={setMenuItems} clearMenuItems={clearMenuItems} setErrorMessage={setErrorMessage}/>} />
            }
            
            {
              (rolePermissions.includes(3) || extraPermissions.includes(3))
              &&
              <Route path="reports" element={<Reports permissions={rolePermissions} extraPermissions={extraPermissions} setMenuItems={setMenuItems} clearMenuItems={clearMenuItems} setErrorMessage={setErrorMessage}/>} />
            }
            
            {
              (rolePermissions.includes(4) || extraPermissions.includes(4))
              &&
              <Route path="inventory" element={<Inventory permissions={rolePermissions} extraPermissions={extraPermissions} setMenuItems={setMenuItems} clearMenuItems={clearMenuItems} setErrorMessage={setErrorMessage}/>} />
            }
            
            <Route path="settings" element={<Settings setMenuItems={setMenuItems} clearMenuItems={clearMenuItems} setErrorMessage={setErrorMessage}/>} />
            <Route path="search" element={<Search permissions={rolePermissions} extraPermissions={extraPermissions} setMenuItems={setMenuItems} clearMenuItems={clearMenuItems} setErrorMessage={setErrorMessage}/>} />

            {
              (rolePermissions.includes(5) || extraPermissions.includes(5))
              &&
              <Route path="admin" element={<Admin setMenuItems={setMenuItems} clearMenuItems={clearMenuItems} setErrorMessage={setErrorMessage}/>}/>
            }

            <Route path="/accounts/profile/" element={<Navigate replace={true} to="/home"/>}/>
            <Route path="form-builder" element={<FormBuilder />} />
            <Route path="/" element={<Navigate replace to="/home"/>} />
            <Route path="*" element={<p>404</p>}/>
          </Routes>
        ):(
          <Routes>
            <Route path="*" element={<Navigate replace={true} to="/account/login/"/>}/>
            <Route path="/form-builder" element={<FormBuilder />} />
          </Routes>
        )}
      </div>
    </div>
  </>
}

export default App;