import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import DynamicForm from "./DynamicForm";
import API from '../../services/API';

function receiveModal(props) {
    const { onSave, onClose, onReceiveChange, order, isSaving, historyColumns, receiveColumns, noteForm, dateForm, onNoteChanged, onDateChanged, filteredProducts, receiveDate, receiveNote } = props

    let saveBtnClasses = "button is-success";
    if (isSaving) saveBtnClasses += " is-loading"

    const [missingFields, setMissingFields] = useState([])

    const onSaveClick = () => {
        if (validate()) onSave()
    }

    const validate = () => {
        let missingFields = []
        dateForm.fields.forEach(f => {
            if (f.required && f.required == "true") {
                if (!(f.id in receiveDate) || receiveDate[f.id] == "" || receiveDate[f.id] == []) missingFields.push(f.id)
            }
        })
        if (missingFields.length > 0) setMissingFields(missingFields)
        return missingFields.length == 0
    }

    const checkReceiveStatus = () => {
        if (filteredProducts.length != 0) {
            return true
        } else {
            return false
        }
    }

    const checkHistoryStatus = () => {
        if (order.receive_history.length != 0) {
            return true
        }
        return false
    }

    const getTotalReceived = () => {
        var i, total = 0
        let arr = order.receive_history
        for (i = 0; i < arr.length; i++) {
            total = total + arr[i].received
        }
        return total
    }

    const getTotalOrdered = () => {
        var i, total = 0
        let arr = order.products
        for (i = 0; i < arr.length; i++) {
            total = total + arr[i].quantity
        }
        return total
    }

    const getStatusClasses = () => {
        if (order.status == 3) {
            return "is-danger"
        } else {
            return "is-success"
        }
    }

    const checkOrderStatus = () => {
        if (order.status > 3) {
            return true
        }
        return false
    }

    const getOrderStatus = () => {
        if (order.status == 4) {
            return 'PARTIAL'
        } else {
            return 'COMPLETE'
        }
    }

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card modal-large">
            <header className="modal-card-head">
                <div className="level w-100">
                    <div className="level-left">
                        <p>Field Order Submitted: </p>
                        <p style={{paddingLeft: 8}}>{order.order_date.split(" ").shift()}</p>
                    </div>
                    <div className="level-right">
                        <p>{order.facility_name}</p>
                    </div>
                </div>
            </header>

            <section className="modal-card-body p-0">
                <div className="p-5">
                    <div className="has-background-white rounded w-250">
                        <div className="level w-100">
                            <div className="level-left">
                                <p>Field Order Number: {order.poNumber}</p>
                            </div>
                            <div className="level-center">
                                <p>Requested Delivery Date: {order.req_ship_date}</p>
                            </div>
                            <div className="level-right">
                                <p>Field Order Created by: {order.user_full_name}</p>
                            </div>
                        </div>
                        {
                            checkReceiveStatus()
                            &&
                            <div>
                                {
                                    order.status != 5
                                    &&
                                    <section>
                                        <DynamicForm
                                            form={dateForm}
                                            onValuesChanged={onDateChanged}
                                            missingFields={missingFields}
                                            values={receiveDate}
                                        />
                                        <hr />
                                    </section>
                                }
                                
                                <section>
                                    <DataTable
                                        columns={receiveColumns}
                                        data={filteredProducts}
                                        striped
                                    />
                                    <br />
                                </section>
                                {
                                    order.status != 5
                                    &&
                                    <section>
                                        <DynamicForm
                                            form={noteForm}
                                            onValuesChanged={onNoteChanged}
                                            values={receiveNote}
                                        />
                                        
                                    </section>
                                }
                                
                            </div>
                        }
                        {
                            checkHistoryStatus()
                            &&
                            <div>
                                <hr />
                                <DataTable
                                    columns={historyColumns}
                                    data={order.receive_history}
                                    striped
                                />
                            </div>
                        }
                        <br />
                        <br />

                        <div className="level w-100">
                            <div className="level-left">
                                {
                                            checkOrderStatus()
                                            &&
                                            <div>
                                                <p>Status: </p> <p className={getStatusClasses()}>{getOrderStatus()}</p>
                                            </div>
                                }
                            </div>
                            <div className="level-right">
                                <p>Total Items Ordered: {getTotalOrdered()}</p>
                                <p style={{paddingLeft: 32}}>Total Items Received: {getTotalReceived()}</p>
                            </div>
                        </div>

                        <button onClick={onClose} className="modal-close is-large" aria-label="close"></button>
                    </div>
                </div>
            </section>
            <footer className="modal-card-foot has-background-white">
                {
                    order.status != 5
                        ?
                        <div className="level w-100">
                            <div className="level-left">
                                <button className="button is-danger is-inverted" onClick={onClose}>Cancel</button>
                            </div>
                            <div className="level-right">
                                <p>Print</p>
                                <p style={{ paddingLeft: 16, paddingRight: 16 }}>Changelog</p>
                                <button style={{ paddingLeft: 16 }} className={saveBtnClasses} onClick={onSaveClick}>Receive</button>
                            </div>
                        </div>
                        :
                        <div className="level w-100">
                            <div className="level-left">

                            </div>
                            <div className="level-right">
                                <p>Print</p>
                                <p style={{ paddingLeft: 16, paddingRight: 16 }}>Changelog</p>
                                <button className="button is-danger is-inverted" onClick={onClose}>Close</button>
                            </div>
                        </div>
                }
            </footer>
        </div>
    </div>
}

export default receiveModal