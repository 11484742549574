import React, { useState } from "react";
import chartingLabs from  "../forms/chartingLabs"
import DynamicForm from "./DynamicForm";
import ChartingDateTime from "./ChartingDateTime";
import DataTableMemo from "./DataTableMemo";

function LabsModal(props) {
  const [data,setData] = useState({...props.data})
  const [time,setTime] = useState(props.time?new Date(props.time.getTime()):new Date())
  const [missingFields,setMissingFields] = useState()
  const form = chartingLabs

  const LabTypeOptions = [
    {"label": "ART", "value": 3},
    {"label": "VEN", "value": 1},
    {"label": "MIX", "value": 2}
  ]

  const LabTypes = {1:"VEN",2:"MIX",3:"ART"}

  const onSave = () => {
    let missing = []
    
    form.fields.forEach(f => {
      if(f.required && f.required == "true") {
        let branchConditionMet = !f.branch_from
        if(f.branch_from) {
          let branchField = form.fields.find(bf => bf.id == f.branch_from.id)
          if(branchField && data[branchField.id]) {
            branchConditionMet = f.branch_from.value == data[branchField.id]
          }
        }
        if(!data[f.id] && branchConditionMet) missing.push(f.id)
      }
    })

    if(missing.length > 0) {
      setMissingFields(missing)
    }else {
      setMissingFields([])
      data['typeName'] = LabTypeOptions.find(t => t.value == data['type']).label
      props.onSave(data,time)
    }
  }

  const onCancel = () => {
    props.onClose()
  }

  let saveBtnClasses = "level-item button is-success";
  if (props.isSaving) saveBtnClasses += " is-loading"

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
    <header className="modal-card-head has-background-white is-block">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="is-size-4">Enter Labs Info</p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <ChartingDateTime 
                value={time}
                onChange={setTime}
              />
            </div>
          </div>
        </div>
      </header>

      <section className="modal-card-body">
        <div className="columns">
          <div className="column is-half">
            <DynamicForm 
              form={chartingLabs}
              values={data}
              onValuesChanged={setData}
              source={{"labTypes":LabTypeOptions}}
              missingFields={missingFields}
            />
          </div>
          <div className="column is-half pt-0">
            {
              (props.history.length > 0)
              &&
              form.fields.map((f,i) => {
                let cols = []
                let hist = {}
                props.history.map((lab) => {
                  let time = lab.time.toLocaleTimeString([],{hour12:false})
                  cols.push({'name':time,selector: row => row[time]})
                  hist[time] = i==0?LabTypes[lab.modalData[f.id]]:lab.modalData[f.id]??''
                })

                return (
                  
                  <div key={i} style={{height:"6rem"}}>
                    <DataTableMemo
                      key={i}
                      dense
                      columns={cols}
                      data={[hist]}
                    />
                  </div>
                  
                )
              })
            }
          </div>
        </div>
        
      </section>

      <footer className="modal-card-foot has-background-white">
        <div className="level w-100">
          <div className="level-left">
            <button className="level-item button is-danger is-inverted" onClick={onCancel}>Cancel</button>
          </div>
          <div className="level-right">
            <button className={saveBtnClasses} onClick={onSave}>Save</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
}

export default LabsModal