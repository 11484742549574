import React, { useEffect, useState } from "react";
import API from "../../services/API";
import { CSVLink } from "react-csv";

function ItemExportCSV(props) {
    const [data, setData] = useState()
    const today = new Date()

    useEffect(() => {
        API.query('GET', 'api/itemlist/', {}, {}, (resp) => {
            setData(resp)
        }, () => {
            console.log('failed loading report data')
        })
    }, [])

    console.log('data', data)

    const getReportData = () => {
        if (data && data.length > 0) {
            var rows = []
            rows.push(['All Items Export', '', '', '', '', '', '', '', `Printed: ${today.toLocaleDateString()}`])
            rows.push([])
            rows.push([])
            rows.push([])
            var i
            let keys = ['#', 'Catalog #', 'Manufacturer', 'Description', 'Item Type', 'Quantity Type', 'Quantity Per','Price']
            rows.push([])
            rows.push([])
            rows.push([])
            rows.push([])
            rows.push(keys)
            for (i = 0; i < data.length; i++) {
                rows.push([`${i + 1}`,
                    `${data[i].catalog}`,
                    `${data[i].manufacturer}`,
                    `${data[i].description}`,
                    `${data[i].item_type}`,
                    `${data[i].type > 1 ? 'Individual' : 'Case'}`,
                    `${data[i].quantity}`,
                    `${data[i].price}`
                ])
            }
            rows.push([])
            rows.push([])
            rows.push([])


            return rows
        } else {
            return []
        }
    }

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
            <header className="modal-card-head has-background-white is-block">
                <p className="modal-card-title">All Item Export</p>
            </header>

            <section className="modal-card-body">
                <div className="level w-100">
                    <div className="level-item"></div>
                    <div className="level-item">
                        {
                            data
                            &&
                            <CSVLink
                                filename={`all_items_${today.toLocaleDateString()}`}
                                data={getReportData()}
                            >
                                <button className="button is-success">Download</button>
                            </CSVLink>
                        }
                    </div>
                    <div className="level-item"></div>
                </div>
            </section>

            <footer className="modal-card-foot has-background-white">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="level-item button is-danger is-inverted" onClick={props.onClose}>Close</button>
                    </div>
                    <div className="level-right"></div>
                </div>
            </footer>
        </div>
    </div>
}

export default ItemExportCSV