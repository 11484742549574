import React, { useEffect, useState, useMemo} from "react";
import DynamicForm from "../../../components/DynamicForm";
import API from "../../../../services/API";
import DataTable from "react-data-table-component";
import ItemModal from "../../../components/ItemModal";
import itemModal from "../../../forms/itemModal";
import manageInventorySearch from "../../../forms/manageInventorySearch";
import ErrorModal from "../../../components/ErrorModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import itemSubMenu from "../../../forms/subMenuItemCount";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import VisitTimer from "../../../components/VisitTimer";
import { useNavigate } from "react-router-dom";
import Session from "../../../../services/Session"

function ManageInventory(props){
  const navigate = useNavigate()
  let subMenuString = 'Location Count'
  const [locationSelected, setLocationSelected] = useState(false)
  const [filterValues, setFilterValues] = useState([])
  const [purchaseOrders, setPurchaseOrders] = useState([])
  const [facilityOptions, setFacilityOptions] = useState([])
  const [items, setItems] = useState([])
  const [products, setProducts] = useState([])
  const [locationCounts, setLocationCounts] = useState([])
  const [itemCategoryOptions, setItemCategoryOptions] = useState([])
  const [showSetupModal, setShowSetupModal] = useState(false)
  const [editValues, setEditValues] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [isSubEditMode, setIsSubEditMode] = useState(false)
  const [showSubAdd, setShowSubAdd] = useState(false)
  const [subEditValues, setSubEditValues] = useState([])
  const [isSubMenu, setIsSubMenu] = useState(true)
  const [itemTypeOptions, setItemTypeOptions] = useState([])
  const [manufacturerOptions, setManufacturerOptions] = useState([])
  const [showSubConfirmationModal, setShowSubConfirmationModal] = useState(false);
  const [addNewSub, setAddNewSub] = useState(false);
  const [amountValues, setAmountValues] = useState([])
  const [amountChanged, setAmountChanged] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const onFilterChanged = (values) => {
    setFilterValues(values)
    if(values.facility) {
      setLocationSelected(true)
    }
  }

  const onEditChange = (values) => {
    setEditValues(values)
  }

  const onSubEditChange = (values) => {
    setSubEditValues(values)
  }

  const onSave = () => {
    if(!isSaving) {
      setIsSaving(true)
      let data = {
        'item_category':editValues['item_category'],
        'item_type':editValues['item_type'],
        'status':(editValues['status'] && editValues['status'] == 'false')?false:true,
        'manufacturer':editValues['manufacturer'],
        'catalog':editValues['catalog'],
        'price':editValues['price'],
        'image':editValues['image'],
        'description':editValues['description'],
        'type':editValues['type'],
        'quantity':editValues['quantity'],
        'location_counts':editValues['location_counts']
      }

      API.query('PUT',`/api/items/${editValues.id}/`,{},data, function(resp) {
        let newItems = items
        let i = newItems.findIndex(j => j.id == resp.id)
        newItems[i] = resp
        setItems(newItems)
        setIsSaving(false)
        setShowSetupModal(false)
        setEditValues([])
        API.query("GET",`/api/locationcounts/`,{},{}, function(resp) {
          setLocationCounts(resp)
        },onDataLoadError)
      },onDataLoadError)
    }
  }

  const appendLocationCount = (locationEditValues, respID) => {
    let newEditValues = locationEditValues
    newEditValues.push(respID)
    return newEditValues
  }

  const onSubSave = () => {
    if(!isSaving) {
      setIsSaving(true)
      let data = {
        'facility':subEditValues['facility'],
        'price':subEditValues['price'],
        'par':subEditValues['par'],
        'count':subEditValues['count'],
        'reorder_status':(subEditValues['reorder_status'] && subEditValues['reorder_status'] == 'false')?false:true,
        'date': new Date()
      }
      let method = isSubEditMode ? 'PUT' : 'POST'
      let uri = isSubEditMode ? `/api/locationcounts/${subEditValues.id}/` : '/api/locationcounts/'
      API.query(method,uri,{},data, function(resp) {
        let newLocationCount = locationCounts
        if(isSubEditMode) {
          let i = newLocationCount.findIndex(j => j.id == subEditValues.id)
          if(i > -1) {
            data['id'] = subEditValues.id
            newLocationCount[i] = data
          }
        }else {
          data['id'] = resp.id
          newLocationCount.push(data)
        }

        setLocationCounts(newLocationCount)

        if(addNewSub) {
          let procData = {
            'location_counts': appendLocationCount(editValues['location_counts'],resp.id)
          }
          let uri =  `/api/items/${editValues.id}/`
          API.query('PATCH',uri,{},procData, function(resp) {
            API.query('GET','/api/items/',{},{},function(response) {
              setItems(response)
            },onDataLoadError)
          },onDataLoadError)
        }
        setIsSaving(false)
        setIsSubEditMode(false)
        setShowSubAdd(false)
        setAddNewSub(false)
        setSubEditValues([])
      },onDataLoadError)
    }
  }

  const onAmountSaveClick = () => {
    if(!isSaving) {
      setIsSaving(true)
      var i
      for(i=0; i < amountValues.length; i++) {
        let data = {
          'count':amountValues[i].value,
          'date': new Date()
        }
        let uri =  `/api/locationcounts/${amountValues[i].id}/`
        API.query('PATCH',uri,{},data, function(resp) {},onDataLoadError)
      }
      API.query("GET",`/api/locationcounts/`,{},{}, function(resp) {
        setLocationCounts(resp)
        setIsSaving(false)
        setAmountValues([])
        setAmountChanged(false)
        setShowConfirmationModal(false)
      },onDataLoadError)
    }
  }

  const onSetupModalClosed = () => {
    setShowSubAdd(false)
    setShowSetupModal(false)
    setEditValues([])
    setIsSubMenu(true)
    API.query("GET",`/api/locationcounts/`,{},{}, function(resp) {
      setLocationCounts(resp)
    },onDataLoadError)
  }

  const onButtonPressed = (id) => {
    if(id == "clear") {
      setLocationSelected(false)
      setFilterValues([])
    } if(id == "create_new_po") {
      
      window.location.href = '/inventory?tab=1&id=-5'
      //create new po
    } else {
      //unknown button
    }
  }

  const onSubEditClicked = (row) => {
    row.reorder_status = row.reorder_status?"true":"false"
    setSubEditValues(row)
    setShowSubAdd(true)
    setIsSubEditMode(true)
  }

  const onSubDeleteClicked = (row) => {
    setSubEditValues(row)
    setShowSubConfirmationModal(true)
  }

  const onSubAddNewClicked = () => {
    setShowSubAdd(true)
    setAddNewSub(true)
  }

  const onSubAddClosed = () => {
    setShowSubAdd(false)
    setSubEditValues([])
    setIsSubEditMode(false)
  }

  const onItemEditClicked = (row) => {
    row.status = row.status?"true":"false"
    row.type = `${row.type}`
    setEditValues(row)
    setShowSetupModal(true)
  }

  const onLocationEditClicked = (row) => {
    let item = items.filter((obj) => {
      var i, abort=false, valid = false
      for(i=0; i < obj.location_counts.length && !abort; i++) {
        if(obj.location_counts[i] == row.id) {
          valid = true
          abort = true
        }
      }
      return valid
    })
    
    item[0].status = item[0].status?"true":"false"
    row.reorder_status = row.reorder_status?"true":"false"
    setEditValues(item[0])
    setSubEditValues(row)
    setShowSetupModal(true)
    setShowSubAdd(true)
    setIsSubEditMode(true)
  }

  const onConfirmSubDelete = () => {
    API.query("DELETE",`/api/locationcounts/${subEditValues.id}/`,{},{}, function(resp) {
      let newLocationCounts = locationCounts.filter(j => j.id !== subEditValues.id)
      setLocationCounts(newLocationCounts)
      setShowSubConfirmationModal(false)
      setSubEditValues([])
      API.query("GET",`/api/items/${editValues.id}/`,{},{}, function(resp) {
        setEditValues(resp)
      },onDataLoadError)
    },onDataLoadError)
  }

  useEffect(() => {
    let queries = [
      { 'method': 'GET', 'path': '/api/purchaseorders/', },
      { 'method': 'GET', 'path': '/api/facilities/', },
      { 'method': 'GET', 'path': '/api/items/', },
      { 'method': 'GET', 'path': '/api/products/', },
      { 'method': 'GET', 'path': '/api/locationcounts/', },
      { 'method': 'GET', 'path': '/api/itemcategories/', },
      { 'method': 'GET', 'path': '/api/itemtypes/', },
      { 'method': 'GET', 'path': '/api/manufacturers/', }
    ]
    API.all(queries, function (resp) {
      if(props.permissions.includes(15) || props.extraPermissions.includes(15)) {
        let user = Session.get('user')
        let filteredFacils = resp[1].filter((obj) => {
          var i, abort=false, valid=false
          for(i=0; i < user.facilities.length && !abort; i++) {
            if(user.facilities[i] == obj.id) {
              valid=true
              abort=true
            }
          }
          return valid
        })
        setFacilityOptions(filteredFacils.map(i => ({ "label": i.name, "value": i.id })))
      } else {
        setFacilityOptions(resp[1].map(i => ({ "label": i.name, "value": i.id })))
      }
      setPurchaseOrders(resp[0])
      setItems(resp[2])
      setProducts(resp[3])
      setLocationCounts(resp[4])
      setItemCategoryOptions(resp[5].map(i => ({ "label": i.category, "value": i.id})))
      setItemTypeOptions(resp[6].map(i => ({ "label": i.type, "value": i.id })))
      setManufacturerOptions(resp[7].map(i => ({ "label": i.name, "value": i.id})))
    }, onDataLoadError)
  }, []);

  const onDataLoadError = (error) => {
    setErrorMessage(error)
    setShowErrorModal(true)
    setIsSaving(false)
  }

  const filterByManufacturer = (obj) => {
    var i,abort=false,valid=false
    if(filterValues.manufacturer && filterValues.manufacturer.length != 0) {
      for(i=0; i < filterValues.manufacturer && !abort; i++) {
        if(filterValues.manufacturer[i] == obj.manufacturer) {
          valid=true
          abort=true
        }
      }
    } else {
      valid=true
    }
    return valid
  }

  const filterByCategory = (obj) => {
    var i, abort=false, valid=false
    if(filterValues.category && filterValues.category.length != 0) {
      for(i=0; i < filterValues.category.length && !abort; i++) {
        if(filterValues.category[i] == obj.item_category) {
          valid=true
          abort=true
        }
      }
    } else {
      valid = true
    }
    return valid
  }

  const filteredItems = items.filter((obj) => {
    var valid = false
    if(filterByManufacturer(obj)) {
      if(filterByCategory(obj)) {
        valid = true
      }
    }
    return valid
  })

  const filteredLocations = locationCounts.filter((obj) => {
    let i, valid = false, abort = false
    if(editValues.location_counts && editValues.location_counts.length != 0) {
      for(i=0; i<editValues.location_counts.length && !abort; i++) {
        if(editValues.location_counts[i] == obj.id) {
          valid = true
          abort = true
        }
      }
    }
    return valid
  })

  const getCountTotal = () => {
    let total = 0, i
    let locations = filteredLocations
    for(i=0; i < locations.length; i++) {
      total += parseInt(locations[i].count)
    }
    return total
  }

  const countTotal = getCountTotal()

  const getQtyType = (quantity, type) => {
    if(type == 1) {
      return ("Case (" + quantity + ")")
    } else {
      return "Indiv"
    }
  }

  const getOptionLabelByValue = (item, source, id) => {
    let option = source.find(o => o.value == id)
    return option ? option.label : null
  }

  const getManufacturerByLocation = (id) => {
    let item = items.filter((obj) => {
      var i, abort=false,valid=false
      for(i=0; i < obj.location_counts.length && !abort; i++) {
        if(obj.location_counts[i] == id) {
          valid = true
          abort = true
        }
      }
      return valid
    })
    if(item.length != 0) {
      return getOptionLabelByValue(id, manufacturerOptions, item[0].manufacturer)
    } else {
      return null
    }
    
  }

  const getCatalogByLocation = (id) => {
    let item = items.filter((obj) => {
      var i, abort=false, valid = false
      for(i=0; i < obj.location_counts.length && !abort; i++) {
        if(obj.location_counts[i] == id) {
          valid = true
          abort = true
        }
      }
      return valid
    })

    if(item.length != 0) {
      return item[0].catalog
    } else {
      return null
    }
  }

  const getCategoryByLocation = (id) => {
    let item = items.filter((obj) => {
      var i, abort=false, valid = false
      for(i=0; i < obj.location_counts.length && !abort; i++) {
        if(obj.location_counts[i] == id) {
          valid = true
          abort = true
        }
      }
      return valid
    })

    if(item.length != 0) {
      return getOptionLabelByValue(id, itemCategoryOptions, item[0].item_category)
    } else {
      return null
    } 
  }

  const getQtyTypeByLocation = (id) => {
    let item = items.filter((obj) => {
      var i, abort=false, valid = false
      for(i=0; i < obj.location_counts.length && !abort; i++) {
        if(obj.location_counts[i] == id) {
          valid = true
          abort = true
        }
      }
      return valid
    })

    if(item.length != 0) {
      return getQtyType(item[0].quantity, item[0].type)
    } else {
      return null
    }
    
  }

  const checkOrderStatus = (id) => {
    let item = items.filter((obj) => {
      var i, abort=false, valid = false
      for(i=0; i < obj.location_counts.length && !abort; i++) {
        if(obj.location_counts[i] == id) {
          valid = true
          abort = true
        }
      }
      return valid
    })

    if(item.length != 0) {
      let filteredProducts = products.filter((obj) => {
        var valid=false
        if(obj.item == item[0].id) {
          valid = true
        }
        return valid
      })
  
      let filteredPurchaseOrders = purchaseOrders.filter((obj) => {
        var i, x, abort=false, valid=false
        for(i=0; i < obj.products.length && !abort; i++) {
          for(x=0; x < filteredProducts.length && !abort; x++) {
            if(filteredProducts[x].id == obj.products[i]) {
              if(obj.facility == filterValues.facility) {
                valid = true
                abort = true
              }
            }
          }
        }
        return valid
      })
  
      var i
      for(i=0; i < filteredPurchaseOrders.length; i++) {
        if(filteredPurchaseOrders[i].status != 5 && filteredPurchaseOrders[i].status != 1) {
          return "Yes"
        }
      }
      return "No"
    } else {
      return null
    }
  }

  const filterByStatus = (obj) => {
    let orderStatus = checkOrderStatus(obj.id)
    if(filterValues.status == 1) {
      if(obj.count >= obj.par) {
        return true
      } else {
        return false
      }
    } else if(filterValues.status == 2) {
      if(obj.count < obj.par) {
        if(checkOrderStatus(obj.id) == "Yes") {
          return true
        }
        return false
      } else {
        return false
      }
    } else if(filterValues.status == 3) {
      if(obj.count < obj.par) {
        if(checkOrderStatus(obj.id) == "No") {
          return true
        }
        return false
      } else {
        return false
      }
    }
  }

  const filteredLocationCounts = locationCounts.filter((obj) => {
    var valid = false

    let tempItem = items.filter((i) => {
      var valid = false, x, abort=false
      for(x=0; x < i.location_counts.length  && !abort; x++) {
        if(i.location_counts[x] == obj.id) {
          valid = true
          abort = true
        }
      }
      return valid
    })

    if(filterValues.facility && filterValues.facility.length != 0) {
      if(filterValues.facility == obj.facility) {

        if(filterValues.manufacturer && filterValues.manufacturer.length != 0) {
          if(filterValues.manufacturer == tempItem[0].manufacturer) {

            if(filterValues.category && filterValues.category.length != 0) {
              if(filterValues.category == tempItem[0].item_category) {

                if(filterValues.status && filterValues.status.length != 0) {
                  if(filterByStatus(obj)) {
                    valid = true
                  } else {
                    valid = false
                  }
                } else {
                  valid = true
                }
              } else {
                valid = false
              }
            } else {
              valid = true
            }
          } else {
            valid = false
          }
        } else {
          if(filterValues.category && filterValues.category.length != 0) {
            if(filterValues.category == tempItem[0].item_category) {

              if(filterValues.status && filterValues.status.length != 0) {
                if(filterByStatus(obj)) {
                  valid = true
                } else {
                  valid = false
                }
              } else {
                valid = true
              }             
            } else {
              valid = false
            }
          } else {
            if(filterValues.status && filterValues.status.length != 0) {
              if(filterByStatus(obj)) {
                valid = true
              } else {
                valid = false
              }
            } else {
              valid = true
            }
          }
        }
      }
    } else {
      valid = true
    }
    return valid
  })

  const onAmountChange = (newid, newvalue) => {
    const newValues = amountValues

    var i, abort = false, matchfound = false
    if(newValues && newValues.length != 0) {
      for(i=0; i < newValues.length && !abort; i++) {
        if(newValues[i].id == newid) {
          newValues[i].value = newvalue
          matchfound = true
          abort = true
        }
      }
    }

    if(!matchfound) {
      newValues.push({"id":newid, "value":newvalue})
    }

    setAmountValues(newValues)
    setAmountChanged(true)
  }

  const onClear = () => {
    setAmountValues([])
    setAmountChanged(false)
  }

  const getStyleByRow = (row) => {
    if(row.count < row.par) {
      if(checkOrderStatus(row.id) == "Yes") {
        return "input is-danger has-text-danger-dark"
      } else {
        return "input is-danger has-text-danger-dark has-background-danger-light"
      }
    } else {
      return "input"
    }
  }

  const getColorByRow = (row) => {
    if(row.count < row.par) {
      if(checkOrderStatus(row.id) == "Yes") {
        return "has-text-weight-bold has-text-success"
      } else {
        return "has-text-weight-bold has-text-danger"
      }
    } else {
      return "has-text-weight-bold"
    }
  }

  const getMessage = (values) => {
    let number = values.length
    return `You are about to change the inventory count amounts of (${number}) products. Please confirm.`
  }
  
  const newLocationOrder = (obj) => {
    let item = items.find((i) => {
      var valid = false, x, abort=false
      for(x=0; x < i.location_counts.length && !abort; x++) {
        if(i.location_counts[x] == obj.id) {
          valid = true
          abort = true
        }
      }
      return valid
    })

    props.updateLoc(obj.facility)
    props.updateItem(item.id)
    navigate("/inventory?tab=1")
    // navigate("/inventory?tab=1&item=" + item.id + "&loc=" + obj.facility)
  }

  const newItemOrder = (itemID) => {
    props.updateItem(itemID)
    navigate("/inventory?tab=1")
  }

  const columns = useMemo(() => [
    {name:'Vendor/Manu',selector: row => getOptionLabelByValue(row,manufacturerOptions,row.manufacturer),sortable:true},
    {name:'Cat #',selector: row => row.catalog,sortable:true},
    {name:'Category',selector: row => getOptionLabelByValue(row,itemCategoryOptions,row.item_category),sortable:true},
    {name:'Qty Type',selector: row => getQtyType(row.quantity, row.type),sortable:true},
    {name:'Actions',selector: row =><div>
    <a title="Edit" onClick={() => onItemEditClicked(row)}><FaPencilAlt size={14} /></a>
    <a className="ml-3" title="New PO" onClick={() => newItemOrder(row.id)}><FaPlus size={14} /></a>
    </div>}
  ])

  const locationColumns = useMemo(() => [
    {name:'Vendor/Manu',selector: row => getManufacturerByLocation(row.id),sortable:true},
    {name:'Cat #',selector: row => getCatalogByLocation(row.id),sortable:true},
    {name:'Category',selector: row => getCategoryByLocation(row.id),sortable:true},
    {name:'Qty Type',selector: row => getQtyTypeByLocation(row.id),sortable:true},
    {name:'Par Level',selector: row => row.par,sortable:true},
    {name:'Quantity',selector: row => <div>
        <input
          className={getStyleByRow(row)}
          type="number"
          defaultValue={row.count}
          placeholder={row.count}
          onChange={(e) => onAmountChange(row.id,e.target.value)}
        />
      </div>},
    {name:'On Order',selector: row => <p className={getColorByRow(row)}>{checkOrderStatus(row.id)}</p>,sortable:true},
    {name:'Actions',selector: row =><div>
    <a title="Edit" onClick={() => onLocationEditClicked(row)}><FaPencilAlt size={14} /></a>
    <a className="ml-3" title="New PO" onClick={() => newLocationOrder(row)}><FaPlus size={14} /></a>
    </div>}
  ])

  const subLocationColumns = useMemo(() => [
    {name:'Facility', width:"320px", selector: row => getOptionLabelByValue(row,facilityOptions,row.facility), sortable:true},
    {name:'On Order',selector: row=> row.on_order ? "Yes" : "No", sortable:true},
    {name:'Par',selector: row=> row.par, sortable:true},
    {name:'Qty Per', selector: row=> row.count, sortable:true},
    {name:'Actions',selector: row => <div>
    <a title="Edit" onClick={() => onSubEditClicked(row)}><FaPencilAlt size={14} /></a>
    <a className="ml-3" title="Delete" onClick={() => onSubDeleteClicked(row)}><FaTrashAlt size={14} /></a>
    </div>}
  ])

  const visited = () => {
    // get pageviewhistory id from user session data
    let user = Session.get('user')
    API.query('GET',`/api/pageviewhistory/${user.page_view_history}/`,{},{},function(response) {
      let data = {
        "inventory_manage": response.inventory_manage + 1
      }
      API.query('PATCH',`/api/pageviewhistory/${user.page_view_history}/`,{},data,function(resp) {}, onDataLoadError)
    }, onDataLoadError)
  }

  return <div>

    <p className="title">Manage Inventory</p>
    <DynamicForm
      form={manageInventorySearch}
      values={filterValues}
      onValuesChanged={onFilterChanged}
      onButtonPressed={onButtonPressed}
      source={{"manufacturers":manufacturerOptions,"facilities":facilityOptions,"categories":itemCategoryOptions}}
    />
    
    <hr />

    {
      showSetupModal
      && 
      <ItemModal
        form={itemModal}
        editValues={editValues}
        onEditChange={onEditChange}
        source={{"manufacturers": manufacturerOptions, "item_categories": itemCategoryOptions, "item_types": itemTypeOptions, "facilities": facilityOptions}}
        isSaving={isSaving}
        onSave={onSave}
        onClose={onSetupModalClosed}

        subMenu={isSubMenu}
        subForm={itemSubMenu}
        showSubAdd={showSubAdd}
        subColumns={subLocationColumns}
        filteredSub={filteredLocations}
        subEditValues={subEditValues}
        onSubEditChange={onSubEditChange}
        onSubSave={onSubSave}
        onSubClose={onSubAddClosed}
        onButtonPressed={onButtonPressed}
        onSubAddNewClicked={onSubAddNewClicked}
        string={subMenuString}
        itemTotal={countTotal}
      />
    }

    {
      showConfirmationModal
      && 
      <ConfirmationModal
        message={getMessage(amountValues)}
        onConfirm={onAmountSaveClick}
        onClose={() => setShowConfirmationModal(false)}
      />
    }

    {
      showErrorModal
      && 
      <ErrorModal
        message={errorMessage}
        onClose={() => setShowErrorModal(false)}
      />
    }

    {
      showSubConfirmationModal
      &&
      <ConfirmationModal
        message={"You are about to delete a Location Count. This cannot be undone."}
        onConfirm={onConfirmSubDelete}
        onClose={() => setShowSubConfirmationModal(false)}
      />
    }

    {
      locationSelected
      ?
      <DataTable 
        striped
        pagination
        columns={locationColumns}
        data={filteredLocationCounts}
      />
      :
      <DataTable 
        striped
        pagination
        columns={columns}
        data={filteredItems}
      />
    }

    {
      amountChanged
      &&
      <div>
        <br />
        <div className="level w-100">
          <div className="level-left">
            <button className="button is-danger is-inverted" onClick={onClear}>Clear</button>
          </div>
          <div className="level-right">
            <button className="button is-success" onClick={() => setShowConfirmationModal(true)}>Save</button>
          </div>
        </div>   
      </div>
    }

    <VisitTimer visited={visited}/>

  </div>
}

export default ManageInventory