import React, { useEffect, useState } from "react";
import useSearchParamState from "../../hooks/useSearchParamState";
import Categories from "./tabs/Categories"
import Events from "./tabs/Events"
import Manufacturer from "./tabs/Manufacturer"
import Medications from "./tabs/Medications"
import User from "./tabs/UserSetup"
import Role from "./tabs/RoleSetup"
import Reports from "./tabs/Reports";
import ChecklistDescriptions from "./tabs/ChecklistDescriptions";
import PatientAllergies from "./tabs/PatientAllergies";
import PatientHistory from "./tabs/PatientHistory";

const CATEGORY_TAB = 1
const EVENT_TAB = 2
const MANUFACTURER_TAB = 3
const MEDICATION_TAB = 4
const USER_TAB = 5
const ROLE_TAB = 6
const REPORTS_TAB = 7
const DESCRIPTION_TAB = 8
const ALLERGY_TAB = 9
const HISTORY_TAB = 10

const TABS = [
  {id:CATEGORY_TAB,name:'Item Category',component:Categories},
  {id:DESCRIPTION_TAB,name:'Checklist Descriptions',component:ChecklistDescriptions},
  {id:EVENT_TAB,name:'Events',component:Events},
  {id:MANUFACTURER_TAB,name:'Manufacturers',component:Manufacturer},
  {id:MEDICATION_TAB,name:'Medications',component:Medications},
  {id:USER_TAB,name:'Users',component:User},
  {id:ROLE_TAB,name:'Roles',component:Role},
  {id:ALLERGY_TAB,name:'Allergies',component:PatientAllergies},
  {id:HISTORY_TAB,name:'Patient History',component:PatientHistory},
  {id:REPORTS_TAB,name:'Reports',component:Reports},
]

function Admin(props){
  const [activeTab,setActiveTab] = useSearchParamState('tab',CATEGORY_TAB,parseInt)

  useEffect(() => {

    props.setMenuItems([
      {title:"Item Category",callback:() => setActiveTab(CATEGORY_TAB)},
      {title:"Checklist Descriptions",callback:() => setActiveTab(DESCRIPTION_TAB)},
      {title:"Events",callback:() => setActiveTab(EVENT_TAB)},
      {title:"Manufacturers",callback:() => setActiveTab(MANUFACTURER_TAB)},
      {title:"Medications",callback:() => setActiveTab(MEDICATION_TAB)},
      {title:"Users",callback:() => setActiveTab(USER_TAB)},
      {title:"Roles",callback:() => setActiveTab(ROLE_TAB)},
      {title:"Allergies",callback:() => setActiveTab(ALLERGY_TAB)},
      {title:"Patient History",callback:() => setActiveTab(HISTORY_TAB)},
      {title:"Reports",callback:() => setActiveTab(REPORTS_TAB)}
    ])

    return props.clearMenuItems
  },[])

  const Tab = TABS.find(tab => tab.id === activeTab)
  const TabComponent = Tab.component

  console.log("Current Tab: ",Tab.name)

  return <div className="box">
    <TabComponent {...props}/>
  </div>
}

export default Admin;