import React, { useEffect, useState, useRef, forwardRef, useMemo } from "react";
import { useNavigate,useSearchParams,useLocation } from "react-router-dom";
import ChartingModal from "../../components/ChartingModal";
import Checklist from "./tabs/Checklist";
import Details from "./tabs/Details";
import Documents from "./tabs/Documents";
import ItemSelection from "./tabs/ItemSelection";
import Create from "./tabs/Create";
import Personnel from "./tabs/Personnel";
import Procedure from "./tabs/Procedure";
import QC from "./tabs/QC";
import StudyData from "./tabs/StudyData";
import API from "../../../services/API";
import { ErrorBoundary } from "react-error-boundary";
import CaseHistoryModal from "../../components/CaseHistoryModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { isForwardRef } from "../../helpers/types";
import { formatCaseData } from "../../helpers/case";

const CREATE_TAB = 1
const PROCEDURE_TAB = 2
const CHECKLIST_TAB = 3
const PERSONNEL_TAB = 4
const CHART_TAB = 5
const DETAILS_TAB = 6
const ITEM_SELECTION_TAB = 7
const QC_TAB = 8
const STUDY_DATA_TAB = 9
const DOCUMENTS_TAB = 10

// TODO: Add check for each tab to determine if it should be disabled
const TABS = [
  {id:CREATE_TAB,name:'Create',component:Create},
  {id:PROCEDURE_TAB,name:'Procedure',component:Procedure},
  {id:CHECKLIST_TAB,name:'Checklist',component:Checklist},
  {id:PERSONNEL_TAB,name:'Personnel',component:Personnel},
  {id:CHART_TAB,name:'Chart',component:ChartingModal},
  {id:DETAILS_TAB,name:'Details',component:Details},
  {id:ITEM_SELECTION_TAB,name:'Item Selection',component:ItemSelection},
  {id:QC_TAB,name:'QC',component:QC},
  {id:STUDY_DATA_TAB,name:'Study Data',component:StudyData},
  {id:DOCUMENTS_TAB,name:'Documents',component:Documents},
]

const HEMTABS = [
  {id:1,name:'Create',component:Create},
  {id:2,name:'Procedure',component:Procedure},
  {id:3,name:'Personnel',component:Personnel},
  {id:4,name:'Details',component:Details},
  {id:5,name:'Item Selection',component:ItemSelection},
  {id:6,name:'Documents',component:Documents},
]

function Cases(props){
  const [previousActiveCase,setPreviousActiveCase] = useState(null)
  const [activeCase,setActiveCaseRaw] = useState(null)
  const [isSaving,setIsSaving] = useState(false)
  const [changesMade,setChangesMade] = useState(false)
  const [caseLoading,setCaseLoading] = useState(false)
  const [showHistoryModal,setShowHistoryModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab,setActiveTab] = useState(parseInt(searchParams.get('tab')))
  const [activeCaseId,setActiveCaseId] = useState(parseInt(searchParams.get('case_id')))
  const [confirmationMessage, setConfirmationMessage] = useState()
  const [confirmationId, setConfirmationId] = useState(0)
  const [activeTabSet, setActiveTabSet] = useState(TABS)
  const navigate = useNavigate()
  const ref = useRef();
  const location = useLocation();

  useEffect(() => {
    // check for mismatch between search params and active case
    //console.log("activeCaseId: ",activeCaseId,", activeCase.id: ",activeCase?activeCase.id:null)
    if(activeCaseId !== activeCase?.id){
      if(activeCaseId){
        // load new case
        setCaseLoading(true)
        API.query('GET','api/cases/'+activeCaseId+'/',{},{},(resp) => {
          const enabledTab = getEnabledTab(resp)
          setActiveCase(resp)
          if(activeTab > enabledTab) setActiveTab(enabledTab)
        },() => {
          setActiveCase(null)
          setActiveTab(CREATE_TAB)
          props.setErrorMessage('Could not load case #'+activeCaseId)
        },() => {
          setCaseLoading(false)
        })
      } else {
        setActiveCaseRaw(null)
        if(searchParams.get('tab')==CHART_TAB && searchParams.get('quick')) {
          setActiveTab(CHART_TAB)
        }else {
          setActiveTab(CREATE_TAB)
        }
      }
    }
  },[activeCaseId,location])

  useEffect(() => {
    // if changes to procedure, we need to reload data
    if(
      activeCase && previousActiveCase &&
      activeCase.procedure !== previousActiveCase.procedure
    ){
      onSave()
    } else if(activeCase) {
      if(("remove_procedure" in activeCase)) {
        onSave()
      }
    }

    // save previous
    setPreviousActiveCase(activeCase)
  },[activeCase])

  useEffect(() => {
    //update search params
    let tempSet = [...activeTabSet]
    let newParams = {tab:activeTab}
    if(searchParams.get('quick')) newParams['quick'] = 1 //preserve emergency case param
    if(activeCaseId) newParams['case_id'] = activeCaseId
    setSearchParams(newParams)
    if(isHemasource && activeTabSet.length > 7) {
      setActiveTabSet(HEMTABS)
      tempSet = HEMTABS
    }
    if(!isHemasource && activeTabSet.length < 7) {
      setActiveTabSet(TABS)
      tempSet = TABS
    }

    // update left side menu
    const enabledTab = getEnabledTab(activeCase)
    const menuItems = tempSet.map(tab => (
      {
        title: tab.id === CREATE_TAB ? 'Create New' : tab.name,
        callback:() => setActiveTab(tab.id),
        active: tab.id === activeTab,
        disabled: tab.id > enabledTab
      }
    ))
      
    props.setMenuItems(menuItems)
    return props.clearMenuItems
  },[activeCase,activeTab])

  const setActiveCase = (activeCase) => {
    setActiveCaseRaw(activeCase)
    setActiveCaseId(activeCase ? parseInt(activeCase.id) : null)
  }

  const updateActiveCase = (updates) => {
    setActiveCase({
      ...activeCase,
      ...updates
    })
    setChangesMade(true)
  }

  const getEnabledTab = (activeCase) => {
    let enabledTab
    if(activeCase === null){
      enabledTab = CREATE_TAB
    } else if(activeCase.procedure.length < 1) {
      enabledTab = PROCEDURE_TAB
    } else {
      enabledTab = DOCUMENTS_TAB
    }

    return enabledTab
  }

  const onNext = (force=false) => {
    let canAdvance = true
    if(!force && ref?.current?.onNext) canAdvance = ref.current.onNext()
    if(!isSaving) {
      if(canAdvance) setActiveTab(activeTab+1)
    }
  }

  const onBack = () => {
    setActiveTab(activeTab-1)
  }

  const onSave = () => {
    if(!changesMade) return
    setIsSaving(true)

    // map data for api
    const apiData = formatCaseData(activeCase)
    API.query('PATCH',`api/cases/${apiData.id}/`,{},apiData,(resp) => {
      setActiveCase(resp)
      setChangesMade(false)
    },() => {
      props.setErrorMessage("Could not save case")
    },() => {
      setIsSaving(false)
    })
  }

  const onSubmit = () => {
    setConfirmationMessage("You are about to submit a Case. This cannot be undone.")
    setShowConfirmationModal(true)
    setConfirmationId(1)
  }

  const onConfirmSubmit = () => {
    if(confirmationId == 1) {
      let apiData = formatCaseData(activeCase)
      apiData['status'] = 'Submitted'
      API.query('PATCH',`/api/cases/${activeCase.id}/`,{},apiData,function(resp) {
        setConfirmationId(0)
        setConfirmationMessage("")
        navigate('/home')
      })
    } else if(confirmationId == 2) {
      let apiData = formatCaseData(activeCase)
      apiData['status'] = 'Inactive'
      API.query('PATCH',`/api/cases/${activeCase.id}/`,{},apiData,function(resp) {
        setConfirmationId(0)
        setConfirmationMessage("")
        navigate('/home')
      })
    }
  }

  const onCancel = () => {
    setConfirmationMessage("You are about to inactivate a Case. This cannot be undone.")
    setShowConfirmationModal(true)
    setConfirmationId(2)
  }

  if(caseLoading){
    return <div className="box">
      <p>Loading case...</p>
    </div>
  }

  const enabledTab = getEnabledTab(activeCase)

  let TabComponent = () => null
  //console.log('activeTab: ',activeTab,", enabledTab: ",enabledTab,", searchParams: ",searchParams)
  if(activeTab <= enabledTab || searchParams.get('tab')==CHART_TAB && searchParams.get('quick')){
    let tab = activeTabSet.find(tab => tab.id === activeTab)
    if(tab.component) TabComponent = tab.component
  }

  const isHemasource = activeCase && activeCase.procedure.length > 0 && activeCase.procedure.find(p => p.nickname === "Hemasource");

  let saveBtnClasses = "button is-info";
  if(isSaving) saveBtnClasses += " is-loading"

  return <div className="box">
    <div className="tabs is-toggle is-fullwidth">
      <ul>
        {
          activeTabSet.map(tab => (
            <li
              key={tab.id}
              className={tab.id === activeTab ? "is-active":""}
              disabled={tab.id > enabledTab || isSaving}
            >
              <a onClick={() => {if(!isSaving) setActiveTab(tab.id)}}>{tab.name}</a>
            </li>
          ))
        }
      </ul>
    </div>

    {
      showHistoryModal
      &&
      <CaseHistoryModal caseId={activeCase.id} onCancel={() => setShowHistoryModal(false)} setErrorMessage={props.setErrorMessage}/>
    }

    {
      showConfirmationModal
      &&
      <ConfirmationModal 
        message={confirmationMessage}
        onConfirm={onConfirmSubmit}
        onClose={() => setShowConfirmationModal(false)}
      />
    }

    <ErrorBoundary
      fallbackRender={() => <p>Error loading tab</p>}
      resetKeys={[activeTab]}
    >
      <TabComponent
        ref={isForwardRef(TabComponent) ? ref : undefined}
        isSaving={isSaving}
        activeCase={activeCase}
        activeCaseId={activeCaseId}
        setActiveCase={setActiveCase}
        updateActiveCase={updateActiveCase}
        setTab={setActiveTab}
        onNext={(force) => onNext(force)}
        onBack={() => onBack()}
        onSave={() => onNext()} // charting modal
        onCancel={() => onBack()}
        setErrorMessage={props.setErrorMessage}
        permissions={props.permissions}
        extraPermissions={props.extraPermissions}
      />
    </ErrorBoundary>
    
    <div className="columns mt-5">
        <div className="column has-text-left">
          {
            (activeTab != CREATE_TAB && activeCase?.status != 'Submitted' && activeCase?.status != 'Inactive')
            &&
            <button className="button is-danger is-outlined" onClick={onCancel}>Inactivate</button>
          }
        </div>
        <div className="column">
          <div className="buttons is-centered">
            <button className="button is-info" disabled={activeTab === CREATE_TAB || isSaving} onClick={() => onBack()}>Back</button>
            <button className="button is-orange" disabled={isSaving || !changesMade || activeCase === null || (isHemasource && activeTab == 4) || (!isHemasource && activeTab == DETAILS_TAB)} onClick={() => onSave()}>Save Form</button>
            <button className="button is-info" disabled={activeTab+1>enabledTab || isSaving} onClick={() => onNext()}>Next</button>
          </div>
        </div>
        <div className="column has-text-right">
          <button className="button is-primary is-inverted" disabled={activeCase===null} onClick={() => setShowHistoryModal(true)}>View Change Log</button>
          {
            ((activeTab == DOCUMENTS_TAB || (isHemasource && activeTab == 6)) && activeCase?.status != 'Submitted' && activeCase?.status != 'Inactive')
            &&
            <button className="button is-danger" disabled={activeCase===null} onClick={() => onSubmit()}>Submit Case</button>
          }
        </div>
    </div>
  </div>
}

export default Cases;