import React, { useEffect, useMemo, useState } from "react";
import DataTable from 'react-data-table-component';
import { FaRegEdit, FaTimes } from "react-icons/fa";
import API from "../../../../services/API";
import DynamicForm from "../../../components/DynamicForm";
import PersonnelRoleModal from "../../../components/PersonnelRoleModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import personnelSearchForm from  "../../../forms/personnelSearch";
import PersonnelTempModal from "../../../components/PersonnelTempModal";

function Personnel(props){
  const [personnelRoles,setPersonnelRoles] = useState([])
  const [roleModalData,setRoleModalData] = useState()
  const [tempModalData, setTempModalData] = useState()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const facilityOptions = [{"label": props.activeCase.facility.name, "value": props.activeCase.facility_id}]
  const procedureOptions = [{"label":props.activeCase.procedure[0].name, "value": props.activeCase.procedure[0].id}]
  const [personnelOptions,setPersonnelOptions] = useState([])
  const [jobOptions,setJobOptions] = useState([])
  const [deleteID, setDeleteID] = useState()
  const [jobs, setJobs] = useState([])
  const [personnel, setPersonnel] = useState([])

  useEffect(() => {
    // Load option data
    let queries = [
      { 'method': 'GET', 'path': '/api/users/'},
      { 'method': 'GET', 'path': '/api/jobs/'},
      { 'method': 'GET', 'path': '/api/casepersonnelroles/', 'params': {'case':props.activeCase.id}},
      { 'method': 'GET', 'path': '/api/personnel/'}
    ]
    API.all(queries, function (resp) {
      setPersonnelOptions([
        ...resp[0].map(i => ({"label": `${i.first_name} ${i.last_name}`, "value": `${i.id}-user`})),
        ...resp[3].map(i => ({"label": `${i.first_name} ${i.last_name}`, "value": `${i.id}-personnel`}))
      ])
      setJobOptions(resp[1].map(i => ({"label": i.name, "value": i.id})))
      setPersonnelRoles(resp[2])
      setPersonnel([
        ...resp[0].map(i => {
          let user = i
          user.id = `${i.id}-user`
          return user
        }),
        ...resp[3].map(i => {
          let personnel = i
          personnel.id = `${i.id}-personnel`
          return personnel
        })
      ])
      setJobs(resp[1])
    }, onDataLoadError)
  },[])

  const onDataLoadError = (error) => {
    props.setErrorMessage(error)
  }

  const getOptionLabelByRow = (source,row) => {
    let id
    if(row.user != null) {
      id = `${row.user}-user`
    } else if(row.personnel != null) {
      id = `${row.personnel}-personnel`
    }
    let option = source.find(o => o.value == id)
    return option ? option.label : null
  }

  const getOptionLabelByValue = (source,id) => {
    let option = source.find(o => o.value == id)
    return option ? option.label : null
  }

  const columns = useMemo(() => [
    {name:'Role',width:'320px',selector: row => row.temp_job != null ? row.temp_job : getOptionLabelByValue(jobOptions,row.job)},
    {name:'Name',selector: row => row.temp_name != null ? row.temp_name : getOptionLabelByRow(personnelOptions,row)},
    {name:'Conflict',width:'160px',center:true,selector: row => (
      row.conflict ?
      <span className="has-text-danger">YES</span>:
      <span className="has-text-success">NO</span>
    )},
    {name:'',width:"160px",right:true,selector: row => <div>
      <a className="has-text-info mr-3" onClick={() => row.temp_name != null ? onTempEdit(row) : onEdit(row) }><FaRegEdit size={20} /></a>
      <a className="has-text-danger" onClick={() => onDelete(row.id)}><FaTimes size={20} /></a>
    </div>}
  ])

  const filteredPersonnelRoles = personnelRoles.filter((obj) => {
    var valid = false
    if(obj.case == props.activeCase.id) {
      valid = true
    }
    return valid
  })

  const filteredPersonnel = personnel.filter((obj) => {
    var valid=false, i, abort=false, x
    if(roleModalData != null) {
      for(x=0; x < obj.jobs.length && !abort; x++) {
        if(obj.jobs[x] == roleModalData.job) {
          for(i=0; i < obj.facilities.length && !abort; i++) {
            if(props.activeCase.facility.id == obj.facilities[i]) {
              valid = true
              abort = true
            }
          }
        }
      }
    }
    return valid
  })

  const filteredPersonnelOptions = personnelOptions.filter((obj) => {
    var valid = false, i, abort=false
    for(i=0; i < filteredPersonnel.length && !abort; i++) {
      if(filteredPersonnel[i].id == obj.value) {
        valid=true
        abort=true
      }
    }
    return valid
  })

  const filteredJobs = jobs.filter((obj) => {
    var valid=false, i, abort=false
    if(obj.facilities.length > 0) {
      for(i=0; i < obj.facilities.length && !abort; i++) {
        if(obj.facilities[i] == props.activeCase.facility.id) {
          //multiple procedure selected logic
          valid=true
          abort=true
        }
      }
    } else {
      //this handles all facilities selected
      //mutlitple procedure selected logic
      valid = true
    }
    return valid
  })

  const filteredJobOptions = jobOptions.filter((obj) => {
    var valid=false, i, abort=false
    for(i=0; i < filteredJobs.length && !abort;i++) {
      if(filteredJobs[i].id == obj.value) {
        abort=true
        valid=true
      }
    }
    return valid
  })

  const onAddTemp = () => {
    setTempModalData({})
  }

  const onAddRole = () => {
    setRoleModalData({})
  }

  const onTempEdit = (temp) => {
    setTempModalData({...temp})
  }

  const onEdit = (row) => {
    let id
    if(row.user != null) {
      id = `${row.user}-user`
    } else if(row.personnel != null) {
      id = `${row.personnel}-personnel`
    }
    row.personnel = id
    setRoleModalData({...row})
  }

  const onDelete = (roleID) => {
    setDeleteID(roleID)
    setShowConfirmationModal(true)
  }

  const onConfirmDelete = () => {
    API.query("DELETE",`/api/casepersonnelroles/${deleteID}/`,{},{}, function(resp) {
      let newPersonnelRoles = personnelRoles.filter(j => j.id !== deleteID)
      setPersonnelRoles(newPersonnelRoles)
      setShowConfirmationModal(false)
      setDeleteID()
    },onDataLoadError)
  }

  const addPersonnelRole = (obj) => {
    let newPersonnelRoles = [...personnelRoles]
    if(obj.user != null) {
      obj.id = `${obj.id}-user`
    } else if(obj.personnel != null) {
      obj.id = `${obj.id}-personnel`
    }
    newPersonnelRoles.push(obj)
    setPersonnelRoles(newPersonnelRoles)
  }

  const updatePersonnelRole = (obj) => {
    let newPersonnelRoles = [...personnelRoles]
    let index = newPersonnelRoles.findIndex((e) => e.id == obj.id)
    newPersonnelRoles[index] = obj
    setPersonnelRoles(newPersonnelRoles)
  }

  const onSaveModal = (data) => {
    if(data.id){
      // Update
      let updateData
      let personnel_arr = data.personnel.split('-')
      if(personnel_arr[1] == 'user') {
        updateData = {
          'job': data.job,
          'user': personnel_arr[0],
        }
      } else if(personnel_arr[1] == 'personnel') {
        updateData = {
          'job': data.job,
          'personnel': personnel_arr[0],
        }
      }
      
      API.query('PATCH',`/api/casepersonnelroles/${data.id}/`,{},updateData,function(resp) {
        updatePersonnelRole(resp)
      },onDataLoadError)
    } else {
      // Add
      let saveData
      let personnel_arr = data.personnel.split('-')
      if(personnel_arr[1] == 'user') {
        saveData = {
          'case': props.activeCase.id,
          'job': data.job,
          'user': personnel_arr[0],
        }
      } else if(personnel_arr[1] == 'personnel') {
        saveData = {
          'case': props.activeCase.id,
          'job': data.job,
          'personnel': personnel_arr[0],
        }
      }
      
      API.query('POST',`/api/casepersonnelroles/`,{},saveData,function(resp) {
        addPersonnelRole(resp)
      },onDataLoadError)
    }
    setRoleModalData()
  }

  const onTempSave = (data) => {
    if(data.id) {
      let updateData = {
        'temp_name': data.name,
        'temp_job': data.job
      }
      API.query('PATCH',`/api/casepersonnelroles/${data.id}/`,{},updateData,function(resp) {
        updatePersonnelRole(resp)
      },onDataLoadError)
    } else {
      let saveData = {
        'case': props.activeCase.id,
        'temp_name': data.name,
        'temp_job': data.job
      }
      API.query('POST',`/api/casepersonnelroles/`,{},saveData,function(resp) {
        addPersonnelRole(resp)
      },onDataLoadError)
    }
    setTempModalData()
  }

  return <div>
    <p className="title">Personnel</p>
    <hr/>
    <DynamicForm form={personnelSearchForm} source={{
      facilities: facilityOptions,
      procedures: procedureOptions
    }} values={{
      selected_facility: props.activeCase.facility_id,
      selected_procedure: props.activeCase.procedure ? props.activeCase.procedure[0].id : null,
      patient_name: `${props.activeCase.patient.full_name}`,
      case_num: props.activeCase.id,
      case_date: props.activeCase.date
    }} />
    <hr/>

    <p className="title is-5">Who's present for this Procedure? <span className="has-text-grey">(Select a Row to Edit or Add New Personnel)</span></p>

    <div className="level w-100">
      <div className="level-left">
        <button className="button is-success" onClick={onAddRole}>Add Personnel</button>
      </div>
      <div className="level-right">
        <button className="button is-danger" onClick={onAddTemp}>Add Temp</button>
      </div>
    </div>

    <DataTable
      striped
      pagination
      columns={columns}
      data={filteredPersonnelRoles}
    />

    {
      roleModalData
      ?
      <PersonnelRoleModal
        data={roleModalData}
        setData={setRoleModalData}
        personnelOptions={filteredPersonnelOptions}
        roleOptions={filteredJobOptions}
        onClose={() => setRoleModalData()}
        onSave={onSaveModal}
      />
      :
      null
    }

    {
      tempModalData
      ?
      <PersonnelTempModal
        data={tempModalData}
        onClose={() => setTempModalData()}
        onSave={onTempSave}
      />
      :
      null
    }

    {
      showConfirmationModal
      &&
      <ConfirmationModal
        message={"You are about to delete a Personnel Role. Please confirm."}
        onConfirm={onConfirmDelete}
        onClose={() => setShowConfirmationModal(false)}
      />
    }
  </div>
}

export default Personnel