import React, { useEffect, useState } from "react";
import { FaSearch, FaRegBell } from "react-icons/fa";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from '../assets/epicLogo.png';

import TimeAgo from 'react-timeago';
import API from "../../services/API";
import Dropdown from "./Dropdown";

const isIE = !!document.documentMode

function TopNav(props) {
  const navigate = useNavigate()

  const [totalAlerts, setTotalAlerts] = useState(0);
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    let queries = [
      { 'method': 'GET', 'path': '/api/alerts/', }
    ]
    API.all(queries, function (resp) {
      setAlerts(resp[0])
      setTotalAlerts(resp[0].count)
    }, onDataLoadError)
  }, []);

  const onDataLoadError = (error) => {
    console.log(error)
  }
  
  const navClass = ({isActive}) => {
    return isActive ? "is-active" : undefined
  }

  const onSearch = (e) => {
    if(e.key !== "Enter") return
    const query = e.target.value
    navigate("search?query="+query)
    e.target.value = ''
    e.target.blur()
  }

  const onOpenAlert = (alert) => {
    if (!alert.is_read) {
      API.query("PATCH", "alerts/" + alert.id, {}, { is_read: true })
    }
  }

  const onCloseAlert = (alert) => {
    let { alerts, totalAlerts } = this.state

    alerts = alerts.filter(a => a.id != alert.id)
    totalAlerts = totalAlerts - 1

    this.setState({ alerts, totalAlerts })
  }

  return <div
    className="is-flex is-justify-content-space-between has-background-white p-3 has-border-bottom"
    style={{
      position: 'absolute',
      width:'100%',
      height:80
    }}
  >
    <div className="is-flex" style={{margin:'5px 30px'}}>
      <div style={{marginRight:70}}>
        <img src={logo} width={50}/>
      </div>

      <div className="tabs">
        <ul>
          <li><NavLink to="home" className={navClass}>Home</NavLink></li>
          {
            (props.permissions.includes(1) || props.extraPermissions.includes(1))
            &&
            <li><NavLink to="cases" className={navClass}>Cases</NavLink></li>
          }
          
          {
            (props.permissions.includes(2) || props.extraPermissions.includes(2))
            &&
            <li><NavLink to="setup" className={navClass}>Setup</NavLink></li>
          }

          {
            (props.permissions.includes(3) || props.extraPermissions.includes(3))
            &&
            <li><NavLink to="reports" className={navClass}>Reports</NavLink></li>
          }

          {
            (props.permissions.includes(4) || props.extraPermissions.includes(4))
            &&
            <li><NavLink to="inventory" className={navClass}>Inventory</NavLink></li>
          }

          {
            (props.permissions.includes(5) || props.extraPermissions.includes(5))
            &&
            <li><NavLink to="admin" className={navClass}>Admin</NavLink></li>
          }
        </ul>
      </div>
    </div>
    
    <div className="is-flex is-align-items-center">
      <div className="control has-icons-left">
        <span className="icon is-small is-left has-text-primary">
          <FaSearch />
        </span>
        <input className="input" type="text" placeholder="Search Anything" onKeyUp={onSearch}/>
      </div>

      <Dropdown className="navbar-item has-dropdown">
        <div className="navbar-link is-arrowless">
          <FaRegBell size={30} />
          {
            totalAlerts > 0
            &&
            <div className="blink">
              {!isIE && <span className="heartbit"></span>}
              <span className="point"></span>
            </div>
          }

        </div>
        <div className="navbar-dropdown is-right" style={{ width: "240px" }}>
          <div className="navbar-item">
            {
              totalAlerts > 0
                ?
                <p className="title is-6">{totalAlerts} unread alerts</p>
                :
                <p className="title is-6">No unread alerts</p>
            }
          </div>
          {
            alerts.map(alert =>
              <a key={alert.id} onClick={() => onOpenAlert(alert)} onClose={() => onCloseAlert(alert)} className="navbar-item">  {/* activity={alert.activity} */}
                <div className="level-left">
                  <div className="level-item">
                    <p>pic</p>
                  </div>
                  <div className="level-item">
                    <div>
                      <p style={{ width: "170px", overflow: "hidden" }}>alert message</p>   {/* {alert.message} */}
                      <TimeAgo className="is-size-7" date={alert.date} />
                    </div>
                  </div>
                </div>
              </a>
            )
          }
          <div className="navbar-item">
            <Link to="/alerts">View all alerts</Link>
          </div>
        </div>
      </Dropdown>
    </div>
  </div>
}

export default TopNav;