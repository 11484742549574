import React from 'react';

function InitialAvatar(props) {
    const initials = `${props.text}`
    const circle = {
        "width": "76px",
        "height": "76px",
        "backgroundColor": "#D3D3D3",
        "borderRadius": "50%"
    }
    return <div style={circle}>
        <span><p className="title is-size-2" style={{paddingTop: "12px"}}>{initials}</p></span>
    </div>
}

export default InitialAvatar;