import React, { useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import DynamicForm from "./DynamicForm";
import API from '../../services/API';

function receiveModal(props) {
    const { onSave, onClose, order, isSaving, noteForm, dateForm, onNoteChanged, onDateChanged, filteredProducts, adminDate, adminNote, adminOrderValues, adminColumns } = props

    let saveBtnClasses = "button is-success";
    if (isSaving) saveBtnClasses += " is-loading"

    const [missingFields, setMissingFields] = useState([])

    const onSaveClick = () => {
        if (validate()) onSave()
    }

    const validate = () => {
        let missingFields = []
        dateForm.fields.forEach(f => {
            if (f.required && f.required == "true") {
                if (!(f.id in adminDate) || adminDate[f.id] == "" || adminDate[f.id] == []) missingFields.push(f.id)
            }
        })
        if (missingFields.length > 0) setMissingFields(missingFields)
        return missingFields.length == 0
    }

    const getTotalAdminSubmitted = () => {
        let total = 0, x
        for(x=0; x < adminOrderValues.length; x++) {
            total = total + parseInt(adminOrderValues[x].quantity)
        }
        return total
    }

    const getTotalSubmitted = () => {
        //update when back order and transfer counts are available
        let total = 0, x
        for(x=0; x < adminOrderValues.length; x++) {
            total = total + parseInt(adminOrderValues[x].quantity)
        }
        return total
    }

    const getTotalOrdered = () => {
        var i, total = 0
        let arr = order.products
        for (i = 0; i < arr.length; i++) {
            total = total + arr[i].quantity
        }
        return total
    }

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card modal-large">
            <header className="modal-card-head">
                <div className="level w-100">
                    <div className="level-left">
                        <p>Field Order Submitted: </p>
                        <p style={{paddingLeft:8}}>{order.order_date.split(" ").shift()}</p>
                    </div>
                    <div className="level-right">
                        <p>{order.facility_name}</p>
                    </div>
                </div>                
            </header>

            <section className="modal-card-body p-0">
                <div className="p-5">
                    <div className="has-background-white rounded w-250">
                        <div className="level w-100">
                            <div className="level-left">
                                <p>Field Order Number: {order.poNumber}</p>
                            </div>
                            <div className="level-center">
                                <p>Requested Delivery Date: {order.req_ship_date}</p>
                            </div>
                            <div className="level-right">
                                <p>Field Order Created by: {order.user_full_name}</p>
                            </div>
                        </div>
                        <div>
                            <section>
                                <DynamicForm
                                    form={dateForm}
                                    onValuesChanged={onDateChanged}
                                    missingFields={missingFields}
                                    values={adminDate}
                                />
                                <hr />
                            </section>
                            <section>
                                <DataTable
                                    columns={adminColumns}
                                    data={filteredProducts}
                                    striped
                                />
                                <br />
                            </section>
                            <section>
                                <DynamicForm
                                    form={noteForm}
                                    onValuesChanged={onNoteChanged}
                                    values={adminNote}
                                />
                                <hr />
                            </section>
                        </div>
                        

                        <div className="level w-100">
                            <div className="level-left">
                                <p>Total Items Ordered:  {getTotalAdminSubmitted()}</p>
                            </div>
                            <div className="level-right">
                                <p>Amount Submitted:  {getTotalOrdered()}</p>
                            </div>
                        </div>
                        <div className="level w-100">
                            <div className="level-left">

                            </div>
                            <div className="level-right">
                                <p>TOTAL:  {getTotalSubmitted()}</p>
                            </div>
                        </div>

                        <button onClick={onClose} className="modal-close is-large" aria-label="close"></button>
                    </div>
                </div>
            </section>
            <footer className="modal-card-foot has-background-white">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="button is-danger is-inverted" onClick={onClose}>Cancel</button>
                    </div>
                    <div className="level-right">
                        <p style={{paddingLeft: 16}}>Print</p>
                        <p style={{paddingLeft: 16, paddingRight: 16}}>Changelog</p>
                        <button style={{paddingLeft: 16}} className={saveBtnClasses} onClick={onSaveClick}>Submit</button>
                    </div>
                </div>
            </footer>
        </div>
    </div>
}

export default receiveModal