import React, { useEffect, useMemo, useState } from "react";
import API from "../../services/API";
import DataTableMemo from "./DataTableMemo";
import { MdPrint } from "react-icons/md";

function CaseHistoryModal(props){
  const [history,setHistory] = useState(null)

  useEffect(() => {
    API.query('GET','api/cases/'+props.caseId+'/logs/',{},{},(resp) => {
      setHistory(resp)
    },() => {
      setHistory([])
      props.setErrorMessage("Failed to load case history")
    })
  },[])

  const columns = useMemo(() => [
    {name:'Date',width:"120px",selector: row => row.date.split(" ")[0]},
    {name:'Time',width:"120px",selector: row => row.date.split(" ").slice(-2).join(" ")},
    {name:'Username',width:"120px",selector: row => row.user.username},
    {name:'Action',selector: row => row.action},
  ])

  const onPrint = () => {

  }

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head has-background-white">
        <p className="is-size-4">Change Log Details</p>
      </header>

      <section className="modal-card-body">
        {
          history === null
          ?
          <p>Loading history...</p>
          :
          <DataTableMemo
            striped
            columns={columns}
            data={history}
          />
        }
      </section>

      <footer className="modal-card-foot has-background-white">
        <div className="level w-100">
          <div className="level-left">
            <button className="level-item button is-danger is-inverted" onClick={props.onCancel}>Cancel</button>
          </div>
          <div className="level-right">
            <button className="level-item button is-dark is-inverted" onClick={onPrint} disabled><MdPrint size={20}/>&nbsp;Print</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
}

export default CaseHistoryModal