import React, { useEffect, useState } from "react"
import DynamicForm from "../../../components/DynamicForm"
import supportForm from "../../../forms/supportForm"
import toast from 'react-hot-toast';
import API from "../../../../services/API";

function Support(props) {
    const [supportValues, setSupportValues] = useState([])

    const onSupportChanged = (values) => {
        setSupportValues(values)
    }

    const onSend = () => {
      const data = {
        category:supportValues["support-request-category"],
        message:supportValues["support-request-description"],
        is_urgent:supportValues["support-need-immediate-response"] ? 1 : 0
      }
      API.query("POST","/api/supportmail/", {}, data, function (resp) {
        toast.success(resp.message)
        setSupportValues([])
      }, function(error) {
        toast.error(error.message)
      })
    }

    return <div>
        <p className="title is-size-2">Contact Support</p>
        <p>If you need help with OnCloud please submit a help ticket.</p>
        <hr />
        <div className="columns">
          <div className="column is-4">
            <p className="title is-size-4">Contact Support Desk</p>
            <p>Submit a ticket for help regarding anything inside of OnCloud. Please make sure to include a category of help request. Someone from the support team will reach out to help you.</p>
            <br />
            <p>Note: Support for OnCloud is only for the assistance with technical issues when using OnCloud.</p>
            <br />
            <p>Check our <a>HELP ARTICLES</a> as well</p>
          </div>
          <div className="column is-1">
            
          </div>
          <div className="column is-7">
            <DynamicForm
              form={supportForm}
              values={supportValues}
              onValuesChanged={onSupportChanged}
            />
          </div>
        </div>
        <hr />
        <div className="level w-100">
          <div className="level-left">

          </div>
          <div className="level-right">
            <button className="button is-success" onClick={onSend}>Submit</button>
          </div>
        </div>
    </div>
}

export default Support;