import React, { useEffect, useState, useMemo } from "react";
import DynamicForm from "../../../components/DynamicForm";
import API from "../../../../services/API";
import SetupModal from "../../../components/SetupModal";
import DataTable from 'react-data-table-component';
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

import checklistSearch from "../../../forms/checklistSearch";
import checklistModal from "../../../forms/checklistModal";
import ErrorModal from "../../../components/ErrorModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import VisitTimer from "../../../components/VisitTimer";
import Session from "../../../../services/Session"

function ChecklistSetup(props){
  const [filterValues,setFilterValues] = useState();
  const [checklists, setChecklists] = useState([]);
  const [procedureOptions, setProcedureOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [checklistOptions, setChecklistOptions] = useState([]);
  const [showSetupModal,setShowSetupModal] = useState(false);
  const [editValues, setEditValues] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [descriptionOptions, setDescriptionOptions] = useState([]);

  const onFilterChange = (values) => {
    setFilterValues(values);
  }

  const onEditChange = (values) => {
    setEditValues(values)
  }

  const onSave = () => {
    if(!isSaving) {
      setIsSaving(true)
      let data = {
        'description':editValues['description'],
        'procedures':editValues['procedures'],
        'facilities':editValues['facilities'],
        'status':(editValues['status'] && editValues['status'] == 'false')?false:true
      }
      let method = isEditMode ? 'PUT' : 'POST'
      let uri = isEditMode ? `/api/checklists/${editValues.id}/` : '/api/checklists/'
      API.query(method,uri,{},data, function(resp) {
        let newChecklists = checklists
        if(isEditMode) {
          let i = newChecklists.findIndex(j => j.id == editValues.id)
          if(i > -1) {
            data['id'] = editValues.id
            newChecklists[i] = data
          }
        }else {
          data['id'] = resp.id
          newChecklists.push(data)
        }
        setChecklists(newChecklists)
        setIsSaving(false)
        setShowSetupModal(false)
        setEditValues([])
      },onDataLoadError)
    }
  }

  const onButtonPressed = (id, row) => {
    if(id == "clear") {
      setFilterValues([])
      
    } else if (id == "create_new") {
      setShowSetupModal(true)

    } else {

    }

  }

  const onSetupModalClosed = () => {
    setShowSetupModal(false)
    setEditValues([])
    setIsEditMode(false)
  }

  const onEditClicked = (row) => {
    row.status = row.status?"true":"false"
    setEditValues(row)
    setShowSetupModal(true)
    setIsEditMode(true)
  }

  const onDeleteClicked = (row) => {
    setEditValues(row)
    setShowConfirmationModal(true)
  }

  const onConfirmDelete = () => {
    API.query("DELETE",`/api/checklists/${editValues.id}/`,{},{}, function(resp) {
      let newChecklists = checklists.filter(j => j.id !== editValues.id)
      setChecklists(newChecklists)
      setShowConfirmationModal(false)
      setEditValues([])
    },onDataLoadError)
  }

  const filterByFacility = (obj) => {
    var y, z, valid, abort=false
    if(filterValues.facility && filterValues.facility.length != 0) {
      if(obj.facilities && obj.facilities.length != 0) {
        for(y=0; y < filterValues.facility.length && !abort; y++) {
          for(z=0; z < obj.facilities.length && !abort; z++) {
            if (filterValues.facility[y] == obj.facilities[z]) {
              valid = true
              abort = true
            } else {
              valid = false
            }
          }
        }
      } else {
        valid = false
      }
    } else {
      valid = true
    }
    return valid
  }

  const filterByProcedure = (obj) => {
    var y, z, valid, abort=false
    if(filterValues.procedure && filterValues.procedure.length != 0) {
      if(obj.procedures.length != 0) {
        for(y=0; y < filterValues.procedure.length && !abort; y++) {
          for(z=0; z < obj.procedures.length && !abort; z++) {
            if (filterValues.procedure[y] == obj.procedures[z]) {
              valid = true
              abort = true
            } else {
              valid = false
            }
          }
        }
      } else {
        valid = false
      }
    } else {
      valid = true
    }
    return valid
  }

  const filterByStatus = (obj) => {
    var valid
    if(filterValues.status == "active") {
      if(obj.status) { valid=true; } else { valid = false; }
    } else if(filterValues.status == "inactive") {
      if(!obj.status) { valid=true; } else { valid=false; }
    } else {
      valid = true
    }
    return valid
  }

  const filteredChecklists =  checklists.filter((obj) =>{
    let valid = false
    if (filterByProcedure(obj)) {
      if (filterByStatus(obj)) {
        if (filterByFacility(obj)) {
          valid = true
        }
      }
    }
    return valid
  })

  const getDescription = (id) => {
    let description = descriptionOptions.find(e => e.value == id)
    if(description) {
      return description.label
    }
    return ''
  }


  const columns = useMemo(() => [
    {name:'Checklist Item',selector: row => getDescription(row.description),sortable:true},
    {name:'Status',selector: row => row.status ? "Active" : "Inactive"},
    {name:'Actions',selector: row =><div>
    <a title="Edit" onClick={() => onEditClicked(row)}><FaPencilAlt size={14} /></a>
    <a className="ml-3" title="Delete" onClick={() => onDeleteClicked(row)}><FaTrashAlt size={14} /></a>
    </div>}
  ])

  useEffect(() => {
    let queries = [
      { 'method': 'GET', 'path': '/api/checklists/', },
      { 'method': 'GET', 'path': '/api/procedures/', },
      { 'method': 'GET', 'path': '/api/facilities/', },
      { 'method': 'GET', 'path': '/api/checklistdescriptions/', }
    ]
    API.all(queries, function (resp) {
      if(props.permissions.includes(11) || props.extraPermissions.includes(11)) {
        let user = Session.get('user')
        let filtered = resp[0].filter((obj) => {
          var i, x, abort = false, valid = false
          for(i=0; i < user.facilities.length && !abort; i++) {
            for(x=0; x < obj.facilities.length && !abort; x++) {
              if(user.facilities[i] == obj.facilities[x]) {
                valid = true
                abort = true
              }
            }
          }
          return valid
        })
        setChecklists(filtered)
        setChecklistOptions(filtered.map(i => ({ "label": i.name, "value": i.id })))
      } else {
        setChecklists(resp[0])
        setChecklistOptions(resp[0].map(i => ({ "label": i.name, "value": i.id })))
      }
      setDescriptionOptions(resp[3].map(i => ({"label": i.description, "value": i.id})))
      setProcedureOptions(resp[1].map(i => ({ "label": i.name, "value": i.id })))
      if(props.permissions.includes(11) || props.extraPermissions.includes(11)) {
        let user = Session.get('user')
        let filtered = resp[2].filter((obj) => {
          let i, abort = false, valid = false
          for(i=0; i < user.facilities.length && !abort; i++) {
            if(user.facilities[i] == obj.id) {
              valid = true
              abort = true
            }
          }
          return valid
        })
        setFacilityOptions(filtered.map(i => ({ "label": i.name, "value": i.id })))
      } else {
        setFacilityOptions(resp[2].map(i => ({ "label": i.name, "value": i.id })))
      }
    }, onDataLoadError)
  }, []);

  const onDataLoadError = (error) => {
    setErrorMessage(error)
    setShowErrorModal(true)
    setIsSaving(false)
  }

  const visited = () => {
    // get pageviewhistory id from user session data
    var user = Session.get('user')
    API.query('GET',`/api/pageviewhistory/${user.page_view_history}/`,{},{},function(response) {
      let data = {
        "setup_checklist": response.setup_checklist + 1
      }
      API.query('PATCH',`/api/pageviewhistory/${user.page_view_history}/`,{},data,function(resp) {}, onDataLoadError)
    }, onDataLoadError)
  }

  return <div>
    <p className="title">Checklist Setup</p>
    <hr />
    <DynamicForm 
      form={checklistSearch} 
      values={filterValues} 
      onButtonPressed={onButtonPressed} 
      onValuesChanged={onFilterChange} 
      source={{"procedures": procedureOptions, "checklists": checklistOptions, "facilities": facilityOptions}}
    />
    <hr />

    {
      showSetupModal
      && 
      <SetupModal
        titleString={"Checklist Setup"}
        form={checklistModal}
        editValues={editValues}
        onEditChange={onEditChange}
        isSaving={isSaving}
        onSave={onSave}
        source={{"descriptions": descriptionOptions, "facilities": facilityOptions, "procedures": procedureOptions}}
        onClose={onSetupModalClosed}
      />
    }

    {
      showErrorModal
      && 
      <ErrorModal
        message={errorMessage}
        onClose={() => setShowErrorModal(false)}
      />
    }

    {
      showConfirmationModal
      && 
      <ConfirmationModal
        message={"You are about to delete a Role. This cannot be undone."}
        onConfirm={onConfirmDelete}
        onClose={() => setShowConfirmationModal(false)}
      />
    }

    <p className="title is-5">Results</p>
    <DataTable
      striped
      pagination
      data={filteredChecklists}
      columns={columns}
    />

    <VisitTimer visited={visited}/>

  </div>
}

export default ChecklistSetup