// Format case data for API
export const formatCaseData = (data) => {
  const newData = {...data}
  //facility is now a select option, need to extract
  newData['facility_id'] = data.facility_id.value

  if (typeof newData.procedure !== 'undefined') {
    if (newData.id != null) {
      if (newData.procedure.length == null) {
        if (newData.procedure_id.length > 0) {
          let arr = [...newData.procedure_id]
          arr.push(newData?.procedure?.id ?? null)
          newData.procedure_id = arr
        } else if (newData.procedure_id.length == 0) {
          let arr = []
          arr.push(newData?.procedure?.id ?? null)
          newData.procedure_id = arr
        }
      }
    }
    delete newData.procedure
  }

  if(newData.patient_allergy_ids && newData.patient_allergy_ids.length) {
    newData.patient_allergy_ids = newData.patient_allergy_ids.filter(a=>Number.isInteger(a))
  }

  if(newData.patient_history_ids && newData.patient_history_ids.length) {
    newData.patient_history_ids = newData.patient_history_ids.filter(ph=>Number.isInteger(ph))
  }

  if(newData.remove_procedure) {
    let arr = [...newData.procedure_id]
    var i, abort=false
    for(i=0; i < arr.length && !abort; i++) {
      if(arr[i] == newData.remove_procedure.id) {
        arr.splice(i,1)
        abort=true
      }
    }
    newData.procedure_id = arr
    delete newData.remove_procedure
  }

  if(newData.personnel_roles){
    delete newData.personnel_roles
  }

  if(newData.items) {
    newData.items = newData.items.map(item => {
      const newItem = {...item}
      newItem.item_id = newItem.item.id ?? null
      delete newItem.item;
      return newItem;
    })
  }

  return newData
}

export const getCaseTimeDate = (data) => {
  var start_date, end_date, start_time, end_time

  if(data.procedure_id.includes(1)) {
    start_date = data.cpb_detail ? (data.cpb_detail.top ? data.cpb_detail.top.start_date : null) : null
    end_date = data.cpb_detail ? (data.cpb_detail.top ? data.cpb_detail.top.end_date : null) : null
    start_time = data.cpb_detail ? (data.cpb_detail.top ? data.cpb_detail.top.start_time : null) : null
    end_time = data.cpb_detail ? (data.cpb_detail.top ? data.cpb_detail.top.end_time : null) : null

    return { start_date: start_date, end_date: end_date, start_time: start_time, end_time: end_time }
  }

  if(data.procedure_id.includes(2)) {
    start_date = data.auto_detail ? data.auto_detail.start_date : null
    end_date = data.auto_detail ? data.auto_detail.end_date : null
    start_time = data.auto_detail ? data.auto_detail.start_time : null
    end_time = data.auto_detail ? data.auto_detail.end_time : null

    return { start_date: start_date, end_date: end_date, start_time: start_time, end_time: end_time }
  }

  if(data.procedure_id.includes(3)) {
    start_date = data.hipec_detail ? data.hipec_detail.start_date : null
    end_date = data.hipec_detail ? data.hipec_detail.end_date : null
    start_time = data.hipec_detail ? data.hipec_detail.start_time : null
    end_time = data.hipec_detail ? data.hipec_detail.end_time : null

    return { start_date: start_date, end_date: end_date, start_time: start_time, end_time: end_time }
  }

  if(data.procedure_id.includes(4)) {
    start_date = data.ecls_detail ? data.ecls_detail.start_date : null
    end_date = data.ecls_detail ? data.ecls_detail.end_date : null
    start_time = data.ecls_detail ? data.ecls_detail.start_time : null
    end_time = data.ecls_detail ? data.ecls_detail.end_time : null

    return { start_date: start_date, end_date: end_date, start_time: start_time, end_time: end_time }
  }

  if(data.procedure_id.includes(5)) {
    start_date = data.platelet_detail ? data.platelet_detail.start_date : null
    end_date = data.platelet_detail ? data.platelet_detail.end_date : null
    start_time = data.platelet_detail ? data.platelet_detail.start_time : null
    end_time = data.platelet_detail ? data.platelet_detail.end_time : null

    return { start_date: start_date, end_date: end_date, start_time: start_time, end_time: end_time }
  }

  if(data.procedure_id.includes(6)) {
    start_date = data.poc_lab_detail ? data.poc_lab_detail.start_date : null
    end_date = data.poc_lab_detail ? data.poc_lab_detail.end_date : null
    start_time = data.poc_lab_detail ? data.poc_lab_detail.start_time : null
    end_time = data.poc_lab_detail ? data.poc_lab_detail.end_time : null

    return { start_date: start_date, end_date: end_date, start_time: start_time, end_time: end_time }
  }

  if(data.procedure_id.includes(7)) {
    start_date = data.perfusion_detail ? data.perfusion_detail.start_date : null
    end_date = data.perfusion_detail ? data.perfusion_detail.end_date : null
    start_time = data.perfusion_detail ? data.perfusion_detail.start_time : null
    end_time = data.perfusion_detail ? data.perfusion_detail.end_time : null

    return { start_date: start_date, end_date: end_date, start_time: start_time, end_time: end_time }
  }
}