import React, { useEffect, useState } from "react";
import API from "../../services/API";
import { CSVLink } from "react-csv";
import Select from 'react-select';
import DatePicker from 'react-datepicker'

function ProcedureBillingReport(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [filters, setFilters] = useState([])
    const [data, setData] = useState()
    const today = new Date()

    const onFilterChanged = (value, type) => {
        let fValues = { ...filters }
        if (type === 0) {
            fValues['start_date'] = value
        } else if (type === 1) {
            fValues['end_date'] = value
        } else if (type === 2) {
            fValues['facility'] = value
        }
        setFilters(fValues)
    }

    const onDataLoadError = (error) => {
        console.log('error',error)
        setIsLoading(false)
    }

    const fetchData = () => {
        if(!isLoading) {
            if(filters.facility && filters.start_date && filters.end_date) {
                setIsLoading(true)

                let params = {
                    'facility': filters.facility,
                    'start_date': start_date,
                    'end_date': end_date
                }

                API.query('GET',`/api/procedurebillingreport/`,params,{},function(resp) {
                    if(resp && resp.length > 0) {
                        setData(resp)
                    } else {
                        setData()
                    }
                    setIsLoading(false)
                }, () => onDataLoadError())
            }
        }
    }

    const getReportData = () => {
        if (data && data.length > 0) {
            let keys = ['#', 'Type', 'Date', 'MR#', 'Patient Name', 'Surgeon', 'User', 'Operation','Hours']
            let rows = []
            rows.push(['Procedure Billing Report With Hours'])
            rows.push(['Hospital:',`${props.facilities.find(e => e.value === filters['facility'])?.label}`])
            rows.push(['Date Range',`${new Date(filters.start_date).toLocaleDateString('en-us')} to ${new Date(filters.end_date).toLocaleDateString('en-us')}`])
            rows.push([])
            rows.push([])
            rows.push([])
            rows.push(keys)
            for(let i=0; i < data.length; i++) {
                let start_date, end_date, start_time, end_time, time_found = false;
                if(data[i].cpb_detail && data[i].cpb_detail.top && data[i].cpb_detail.top.end_date && data[i].cpb_detail.top.end_time && data[i].cpb_detail.top.start_date && data[i].cpb_detail.top.start_time) {
                    start_date = data[i].cpb_detail.top.start_date
                    end_date = data[i].cpb_detail.top.end_date

                    start_time = data[i].cpb_detail.top.start_time
                    end_time = data[i].cpb_detail.top.end_time
                    time_found = true
                }

                if(data[i].auto_detail && data[i].auto_detail.start_date && data[i].auto_detail.start_time && data[i].auto_detail.end_date && data[i].auto_detail.end_time && !time_found) {
                    start_date = data[i].auto_detail.start_date
                    end_date = data[i].auto_detail.end_date

                    start_time = data[i].auto_detail.start_time
                    end_time = data[i].auto_detail.end_time
                    time_found = true
                }

                if(data[i].hipec_detail && data[i].hipec_detail.start_date && data[i].hipec_detail.end_date && data[i].hipec_detail.start_time && data[i].hipec_detail.end_time && !time_found) {
                    start_date = data[i].hipec_detail.start_date
                    end_date = data[i].hipec_detail.end_date

                    start_time = data[i].hipec_detail.start_time
                    end_time = data[i].hipec_detail.end_time
                    time_found = true
                }

                if(data[i].ecls_detail && data[i].ecls_detail.start_date && data[i].ecls_detail.end_date && data[i].ecls_detail.start_time && data[i].ecls_detail.end_time && !time_found) {
                    start_date = data[i].ecls_detail.start_date
                    end_date = data[i].ecls_detail.end_date

                    start_time = data[i].ecls_detail.start_time
                    end_time = data[i].ecls_detail.end_time
                    time_found = true
                }

                if(data[i].platelet_detail && data[i].platelet_detail.start_date && data[i].platelet_detail.end_date && data[i].platelet_detail.start_time && data[i].platelet_detail.end_time && !time_found) {
                    start_date = data[i].platelet_detail.start_date
                    end_date = data[i].platelet_detail.end_date

                    start_time = data[i].platelet_detail.start_time
                    end_time = data[i].platelet_detail.end_time
                    time_found = true
                }

                if(data[i].poc_lab_detail && data[i].poc_lab_detail.start_date && data[i].poc_lab_detail.end_date && data[i].poc_lab_detail.start_time && data[i].poc_lab_detail.end_time && !time_found) {
                    start_date = data[i].poc_lab_detail.start_date
                    end_date = data[i].poc_lab_detail.end_date

                    start_time = data[i].poc_lab_detail.start_time
                    end_time = data[i].poc_lab_detail.end_time
                    time_found = true
                }

                if(data[i].perfusion_detail && data[i].perfusion_detail.start_date && data[i].perfusion_detail.end_date && data[i].perfusion_detail.start_time && data[i].perfusion_detail.end_time && !time_found) {
                    start_date = data[i].perfusion_detail.start_date
                    end_date = data[i].perfusion_detail.end_date

                    start_time = data[i].perfusion_detail.start_time
                    end_time = data[i].perfusion_detail.end_time
                    time_found = true
                }

                let hour_string = ''
                if(start_date && end_date && start_time && end_time) {
                    let start = new Date
                    let end = new Date

                    let start_arr = start_date.split('/');
                    let end_arr = end_date.split('/');

                    let start_time_arr = start_time.split(':');
                    let end_time_arr = end_time.split(':');

                    start.setFullYear(start_arr[2],parseInt(start_arr[0]) - 1,start_arr[1])
                    start.setHours(start_time_arr[0])
                    start.setMinutes(start_time_arr[1])

                    end.setFullYear(end_arr[2],parseInt(end_arr[0]) - 1,end_arr[1])
                    end.setHours(end_time_arr[0])
                    end.setMinutes(end_time_arr[1])

                    hour_string = `${((end - start) / 3600000).toFixed(1)}`
                }

                let surgeon = data[i].personnel_roles.find((e) => {
                    let valid = false
                    if(e?.job?.id === 15) {
                        valid = true
                    }
                    return valid
                })

                let procedureString = ''
                for(let x=0;x < data[i].procedure.length;x++) {
                    if(x == 0) {
                        procedureString = data[i].procedure[x].name
                    } else {
                        procedureString = procedureString + ', ' + data[i].procedure[x].name
                    }
                }

                let operation_detail_string
                // TODO: All operation detail options
                //CPB Options
                if(data[i].cpb_detail?.bypass_count) {
                    if(operation_detail_string) {
                        operation_detail_string = operation_detail_string + `, CABG x ${data[i].cpb_detail?.bypass_count}`
                    } else {
                        operation_detail_string = `CABG x ${data[i].cpb_detail?.bypass_count}`
                    }
                }

                //perfusion standby options
                if(data[i].perfusion_detail?.cath_note) {
                    if(operation_detail_string) {
                        operation_detail_string = operation_detail_string + `, Cath Lab (${data[i].perfusion_detail?.cath_note})`
                    } else {
                        operation_detail_string = `Cath Lab (${data[i].perfusion_detail?.cath_note})`
                    }
                }

                //Auto Options
                if(data[i].auto_detail?.cardiac_checked) {
                    if(operation_detail_string) {
                        operation_detail_string = operation_detail_string + `, Cardiac` + (data[i].auto_detail?.cardiac_note ? ` (${data[i].auto_detail?.cardiac_note})` : '');
                    } else {
                        operation_detail_string =  `Cardiac` + (data[i].auto_detail?.cardiac_note ? ` (${data[i].auto_detail?.cardiac_note})` : '')
                    }
                }

                if(data[i].auto_detail?.vascular_checked) {
                    if(operation_detail_string) {
                        operation_detail_string = operation_detail_string + `, Vascular` + (data[i].auto_detail?.vascular_note ? ` (${data[i].auto_detail?.vascular_note})` : '');
                    } else {
                        operation_detail_string = `Vascular` + (data[i].auto_detail?.vascular_note ? ` (${data[i].auto_detail?.vascular_note})` : '');
                    }
                }

                if(data[i].auto_detail?.neuro_checked) {
                    if(operation_detail_string) {
                        operation_detail_string = operation_detail_string + `, Neuro` + (data[i].auto_detail?.neuro_note ? ` (${data[i].auto_detail?.neuro_note})` : '');
                    } else {
                        operation_detail_string = `Neuro` + (data[i].auto_detail?.neuro_note ? ` (${data[i].auto_detail?.neuro_note})` : '');
                    }
                }

                let row = []
                row.push(i+1)
                row.push(procedureString)
                row.push(data[i].date)
                row.push(data[i].medical_record_num)
                row.push(data[i].patient_name)
                row.push(surgeon?.user_full_name ?? '')
                row.push(data[i].user.user_full_name)
                row.push(operation_detail_string ?? '')
                row.push(hour_string)
                rows.push(row)
            }
            return rows
        } else {
            return []
        }
    }
    
    const start_date = filters.start_date ? new Date(filters.start_date).toLocaleDateString('en-CA') : ''
    const end_date = filters.end_date ? new Date(filters.end_date).toLocaleDateString('en-CA') : ''

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
            <header className="modal-card-head has-background-white is-block">
                <p className="modal-card-title">Procedure Billing Report</p>
            </header>

            <section className="modal-card-body">
                <div className="columns">
                    <div className="column"></div>
                    <div className="column">
                        <div style={{ midWidth: '499px', maxWidth: '500px' }}>
                            <Select
                                options={props.facilities}
                                placeholder={'Select a Facility'}
                                value={props.facilities.find((e) => e.value == filters.facility)}
                                onChange={(option) => onFilterChanged(option.value, 2)}
                            />
                        </div>
                    </div>
                    <div className="column"></div>
                </div>
                <div className="level w-100">
                    <div className="level-item"></div>
                    <div className="level-item">
                        <div style={{ maxWidth: '200px', minWidth: '200px' }}>
                            <DatePicker
                                className="input"
                                selectsStart
                                startDate={filters.start_date}
                                endDate={filters.end_date}
                                selected={filters.start_date}
                                onChange={(date) => onFilterChanged(date, 0)}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                maxDate={new Date()}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Start Date"
                            />
                        </div>
                    </div>
                    <div className="level-item">
                        <div style={{ maxWidth: '200px', minWidth: '200px' }}>
                            <DatePicker
                                className="input"
                                selectsStart
                                startDate={filters.start_date}
                                endDate={filters.end_date}
                                selected={filters.end_date}
                                onChange={(date) => onFilterChanged(date, 1)}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                maxDate={new Date()}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="End Date"
                            />
                        </div>
                    </div>
                    <div className="level-item"></div>
                </div>
                <div className="level w-100">
                    <div className="level-item has-text-centered">
                        <button className="button is-success" onClick={() => fetchData()}>Generate</button>
                    </div>
                </div>

                <br />
                <br />
                <br />

            </section>

            <footer className="modal-card-foot has-background-white">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="level-item button is-danger is-inverted" onClick={props.onClose}>Close</button>
                    </div>
                    <div className="level-right">
                        {
                            data
                            &&
                            <CSVLink
                                filename={`procedure_billing_${today.toLocaleDateString()}`}
                                data={getReportData()}
                            >
                                <button className="button is-success">Download</button>
                            </CSVLink>
                        }
                    </div>
                </div>
            </footer>
        </div>
    </div>
}

export default ProcedureBillingReport