import React, { useState } from 'react';

import DynamicForm from "./DynamicForm";

function CategoryModal(props) {
    const {form, editValues, onClose, onEditChange, source, onSave, isSaving, string} = props

    const [missingFields, setMissingFields] = useState([])

    const onSaveClick = () => {
      if(validate()) onSave()
    }

    const validate = () => {
      let missingFields = []
      form.fields.forEach(f => {
        if(f.required && f.required == "true") {
          if(!(f.id in editValues) || editValues[f.id] == "" || editValues[f.id] == []) missingFields.push(f.id)
        }
      })
      if(missingFields.length > 0) setMissingFields(missingFields)
      return missingFields.length == 0
    }
 
    let saveBtnClasses = "button is-success";
    if(isSaving) saveBtnClasses += " is-loading"

    return <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{string}</p>
      </header>
        <section className="modal-card-body p-0">
          <div className="p-5">
            <div className="has-background-white rounded w-100">
              <DynamicForm 
                form={form} 
                values={editValues} 
                onValuesChanged={onEditChange} 
                source={source} 
                missingFields={missingFields}
              />
              <button onClick={onClose} className="modal-close is-large" aria-label="close"></button>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <div className="level w-100">
            <div className="level-left">
              <button className="button is-danger is-inverted" onClick={onClose}>Cancel</button>
            </div>
            <div className="level-right">
              <button className={saveBtnClasses} onClick={onSaveClick}>Save</button>
            </div>
          </div>
        </footer>
      </div>
    </div>
}

export default CategoryModal;