import React, { useEffect, useMemo, useState } from "react";
import DataTable from 'react-data-table-component';
import { FaRegEdit } from "react-icons/fa";
import BooleanRadioGroup from "../../../components/BooleanRadioGroup";
import ProcedureBox from "../../../components/ProcedureBox";
import StudyDataModal from "../../../components/StudyDataModal";

function StudyData(props){
  const [editItem,setEditItem] = useState(null)

  const getItemString = (studyData) => {
    if(studyData.item.value_type !== 'Numeric') {
      return `${studyData.item.details}`
    } else {
      return `${studyData.item.details} (${studyData.item.unit_measure})`
    }
  }

  const columns = useMemo(() => [
    {name:'Item Name',selector: row => <p>{getItemString(row)}</p>},
    {name:'Achieved?',width:"160px",center:true,selector: row => <div>
      {  
        row.item.value_type !== 'Numeric'
        &&
        <BooleanRadioGroup 
          value={row.achieved} 
          setValue={(value) => setAchieved(row.id,value,1)} 
        />
      }

      {
        row.item.value_type === 'Numeric'
        &&
        <input 
          className="input"
          type="number"
          value={row.achieved_numeric == null ? '' : row.achieved_numeric}
          onChange={(value) => setAchieved(row.id, value.target.value, 2)}
        />
      }
      </div>
    },
    {name:'Notes',width:"80px",center:true,selector: row => <a onClick={() => setEditItem(row)}><FaRegEdit size={20} /></a>}
  ])

  const setAchieved = (itemId,achieved,type) => {
    if(type == 1) {
      const newItems = props.activeCase.study_items.map(item => {
        if(item.id === itemId) item.achieved = achieved
        return item
      })
      props.updateActiveCase({
        study_items: newItems
      })
    } else if(type == 2) {
      const newItems = props.activeCase.study_items.map(item => {
        if(item.id === itemId) item.achieved_numeric = achieved
        return item
      })
      props.updateActiveCase({
        study_items: newItems
      })
    }
    
  }

  const onSaveItem = (newItem) => {
    const newItems = props.activeCase.study_items.map(item => {
      if(item.id === newItem.id) {
        return newItem
      }
      return item
    })
    props.updateActiveCase({
      study_items: newItems
    })
    setEditItem(null)
  }
  
  return <div>
    <p className="title is-inline-block mr-3 mb-0">Study Data</p>
    {
      props.activeCase.procedure.map((proc) => {
        return <span key={proc.id} style={{marginRight:5}}>
          <ProcedureBox procedure={proc} />
        </span>
      })
    }

    <hr/>

    {
      props.activeCase.procedure.map((proc) => {
        return <div key={proc.id}>
          <label className="label">{proc.name} Study Data Items</label>
          <DataTable
            striped
            pagination
            columns={columns}
            data={props.activeCase.study_items.filter((obj) => {
              var valid=false
              if(obj.procedure == proc.id) valid=true
              return valid
            })}
          />
        </div>
      })
    }

    {
      editItem !== null
      &&
      <StudyDataModal
        item={editItem}
        onSave={onSaveItem}
        onClose={() => setEditItem(null)}
      />
    }
  </div>
}

export default StudyData