import React, { useState } from 'react';

function ErrorModal(props) {
    const {message,onClose} = props

    return <div className="modal is-active" style={{zIndex:4000}}>
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">We ran into a problem</p>
        <button className="delete" aria-label="close" onClick={onClose}></button>
      </header>
      <section className="modal-card-body">
        <div>{message}</div>
      </section>
      <footer className="modal-card-foot is-flex is-justify-content-end">
        <button className="button is-success" onClick={onClose}>OK</button>
      </footer>
    </div>
  </div>
}

export default ErrorModal;