import React, { createRef, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import DynamicForm from "../components/DynamicForm";

import exampleForm from  "../forms/example";
import documentation from  "../forms/documentation";
import axios from 'axios';
import API from "../../services/API";

function FormBuilder() {
  const [formString,setFormString] = useState()
  const [isValid,setIsValid] = useState(false)
  const [form,setForm] = useState()
  const [values,setValues] = useState()
  const [checklists,setChecklists] = useState([])
  const [equipmentqc,setEquipmentQC] = useState([])
  const [facilities, setFacilities] = useState([])
  const [jobs,setJobs] = useState([])
  const [users,setUsers] = useState([])
  const [procedures,setProcedures] = useState([])
  const [qc,setQC] = useState([])
  const [roles, setRoles] = useState([])
  const [studydata,setStudyData] = useState([])

  const textareaRef = createRef()

  useEffect(() => {
    let formString = localStorage.getItem("formbuilderstring")
    if(formString) {
      setFormString(formString)
    } else {
      loadDefault()
    }
  },[])

  useEffect(() => {
    try{
      const form = JSON.parse(formString)
      setForm(form)
      if(!isValid) setIsValid(true)
    } catch (e) {
      if(isValid) setIsValid(false)
    }
  },[formString])

  useEffect(() => {
    let queries = [
      {'method':'GET','path':'/api/checklists/'},
      {'method':'GET','path':'/api/equipmentqc/'},
      {'method':'GET','path':'/api/facilities/'},
      {'method':'GET','path':'/api/jobs/'},
      {'method':'GET','path':'/api/users/'},
      {'method':'GET','path':'/api/procedures/'},
      {'method':'GET','path':'/api/qc/'},
      {'method':'GET','path':'/api/roles/'},
      {'method':'GET','path':'/api/studydata/'}
    ]
    API.all(queries, function(resp) {
      setChecklists(resp[0].map(o => ({"label":o.name,"value":o.id})))
      setEquipmentQC(resp[1].map(o => ({"label":o.description.description,"value":o.id})))
      setFacilities(resp[2].map(o => ({"label":o.name,"value":o.id})))
      setJobs(resp[3].map(o => ({"label":o.name,"value":o.id})))
      setUsers(resp[4].map(o => ({"label":`${o.first_name} ${o.last_name}`,"value":o.id})))
      setQC(resp[6].map(o => ({"label":o.description,"value":o.id})))
      setRoles(resp[7].map(o => ({"label":o.name,"value":o.id})))
      setStudyData(resp[8].map(o => ({"label":o.details,"value":o.id})))
    },onDataLoadError)
  },[])

  const onDataLoadError = (error) => {
    console.log(error)
  }

  const loadDefault = () => {
    const formString = JSON.stringify(exampleForm,null,"\t")
    setFormString(formString)
  }

  const onChange = (e) => {
    const formString = e.target.value
    setFormString(formString)
    localStorage.setItem("formbuilderstring",formString)
  }

  const onKeyDown = (e) => {
    // https://stackoverflow.com/a/6637396
    if (e.key == 'Tab') {
      e.preventDefault();

      const start = textareaRef.current.selectionStart;
      const end = textareaRef.current.selectionEnd;
  
      // set textarea value to: text before caret + tab + text after caret
      textareaRef.current.value = textareaRef.current.value.substring(0, start) + "\t" + textareaRef.current.value.substring(end);
  
      // put caret at right position again
      textareaRef.current.selectionStart = textareaRef.current.selectionEnd = start + 1;
    }
  }

  const ErrorFallback = ({error}) => {
    return <div className="box">
      <p className="title is-3">Error</p>
      <pre>{error.message}</pre>
    </div>
  }

  return <div>
    <p className="title">Form Builder</p>

    <div className="columns is-multiline">
      <div className="column is-6">
        <div className="box">
          {
            isValid
            ?
            <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[form]}>
              <DynamicForm
                key={JSON.stringify(form)}
                form={form}
                values={values}
                onValuesChanged={setValues}
                source={{
                  "checklists":checklists,"equipmentqc":equipmentqc,"facilities":facilities,"jobs":jobs,
                  "users":users,"procedures":procedures,"qc":qc,"roles":roles,"studydata":studydata
                }}
              />
            </ErrorBoundary>
            :
            <p>Invalid JSON</p>
          }
        </div>
      </div>
      <div className="column is-6">
        <div className="box">
          <textarea className="textarea mb-3" value={formString} onChange={onChange} onKeyDown={onKeyDown} rows={20} ref={textareaRef} />
          <button className="button" onClick={loadDefault}>Load Default</button>
        </div>
      </div>
      <div className="column is-6">
        <div className="box">
          <p className="title is-5 mb-3">Values</p>
          {
            values
            &&
            <pre>
              {JSON.stringify(values,null,"\t")}
            </pre>
          }
        </div>
      </div>
      <div className="column is-6">
        <div className="box">
          <p className="title is-5 mb-3">Format</p>
          <pre>{JSON.stringify(documentation,null,"\t")}</pre>
        </div>
      </div>
    </div>
    
  </div>
}

export default FormBuilder;