import React, { useState } from 'react';
import ChartingDateTime from "./ChartingDateTime";

function CommentModal(props) {
  const [comment,setComment] = useState(props.comment)
  const [time,setTime] = useState(props.time?new Date(props.time.getTime()):new Date())

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head has-background-white is-block">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="is-size-4">Comment</p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <ChartingDateTime 
                value={time}
                onChange={setTime}
              />
            </div>
          </div>
        </div>
      </header>
      <section className="modal-card-body">
        <textarea
          className="textarea"
          value={comment??""}
          onChange={(e) => setComment(e.target.value)}
        />
      </section>
      <footer className="modal-card-foot is-flex is-justify-content-end">
        <button className="button is-success" onClick={() => props.onSave(comment,time)}>Save</button>
        <button className="button" onClick={() => props.onCancel()}>Cancel</button>
      </footer>
    </div>
  </div>
}

export default CommentModal;