import React, { useEffect, useMemo, useState } from "react";
import API from "../../services/API";

import newPatientForm from  "../forms/newPatient";
import newCaseFormA from  "../forms/newCaseA";
import newCaseFormB from  "../forms/newCaseB";
import newCaseFormC from  "../forms/newCaseC";

import { formatCaseData } from "../helpers/case";
import DynamicForm, { getInitialValuesForForm } from "./DynamicForm";
import PatientBloodForm from "./PatientBloodForm";


function ChartingPatientModal(props){
  const isUpdate = !!props.caseId
  const [isLoading,setIsLoading] = useState(false)

  const [allMissingFields, setAllMissingFields] = useState([])

  const [caseData,setCaseData] = useState({
    ...getInitialValuesForForm(newCaseFormA),
    ...getInitialValuesForForm(newCaseFormB),
    ...getInitialValuesForForm(newCaseFormC),
    patient_blood_type: ""
  })
  const [patientData,setPatientData] = useState(getInitialValuesForForm(newPatientForm))

  const [patientHistory,setPatientHistory] = useState([])
  const [showPatientHistory,setShowPatientHistory] = useState(false)

  const onFacilitySearch = (inputValue,callback) => {
    if(inputValue.length >= 3) {
      API.query('GET','/api/facilityoptions/',{query:inputValue},null,(results) => {
        callback(results.map((i) => ({"label": i.name, "value": i.id})))
      },() => {
        callback([])
      })
    }
  }

  useEffect(() => {
    //console.log(props)
    if(props.caseId){
      setIsLoading(true)
      API.query("GET",`api/cases/${props.caseId}/`,{},{},(resp) => {
        // split patient data into it's own data object
        if(resp.patient){
          setPatientData(resp.patient)
          loadPatientHistory(resp.patient.id,resp.id)
          delete resp.patient
        }

        // put the rest into caseData
        resp['facility_id'] = {"value":resp.facility.id,"label":resp.facility.name}
        setCaseData(resp)

        setIsLoading(false)
      }, () => {
        props.setErrorMessage("Could not load case")
        props.onClose()
      })
    }
  },[])

  const loadPatient = (patient) => {
    setView(NEW_PATIENT_VIEW)
    setPatientData(patient)
    loadPatientHistory(patient.id)
  }

  const loadPatientHistory = (patientId,currentCaseID=null) => {
    API.query("GET",`api/cases/`,{patient:patientId},{},(resp) => {
      if(currentCaseID !== null) resp.filter(c => c.id !== currentCaseID)
      setPatientHistory(resp)
    },() => {
      props.setErrorMessage("Could not load patient history")
    })
  }

  const onSave = () => {
    const apiData = formatCaseData(caseData)
    apiData.patient = patientData

    //console.log(apiData)

    if(validateData()) {
      setIsLoading(true)
      if(props.caseId){
        API.query("PATCH",`/api/cases/${apiData.id}/`,{},apiData,(resp) => {
          props.onCaseUpdated(resp)
          props.onClose()
        }, props.setErrorMessage, () => setIsLoading(false))
      } else {
        API.query("POST","/api/cases/",{},apiData,(resp) => {
          props.onCaseCreated(resp)
          props.onClose()
        }, props.setErrorMessage, () => setIsLoading(false))
      }
    }
  }

  const validateData = () => {
    //console.log(caseData)
    //console.log(patientData)
    let missingFields = []
    // TODO: need to nail down the requirement for quick case
    if(!caseData['facility_id']) missingFields.push('facility_id')
    if(!patientData['first_name']) missingFields.push('first_name')
    if(!patientData['last_name']) missingFields.push('last_name')
    if(!patientData['medical_record_num']) missingFields.push('medical_record_num')
    if(!patientData['dob']) missingFields.push('dob')
    if(!patientData['gender']) missingFields.push('gender')
    if(!caseData['patient_height_cm']) missingFields.push('patient_height_cm')
    if(!caseData['patient_weight_kg']) missingFields.push('patient_weight_kg')

    // newCaseFormB.fields.forEach(f => {
    //   if(f.required && f.required == "true") {
    //     if(!(f.id in caseData) || caseData[f.id] == "" || caseData[f.id] == [] || caseData[f.id] == null) missingFields.push(f.id)
    //   }
    // })
    // newPatientForm.fields.forEach(f => {
    //   if(f.required && f.required == "true") {
    //     if(!(f.id in patientData) || patientData[f.id] == "" || patientData[f.id] == [] || patientData[f.id] == null) missingFields.push(f.id)
    //   }
    // })
    // newCaseFormA.fields.forEach(f => {
    //   if(f.required && f.required == "true") {
    //     if(!(f.id in caseData) || caseData[f.id] == "" || caseData[f.id] == [] || caseData[f.id] == null) missingFields.push(f.id)
    //   }
    // })
    if (missingFields.length > 0) setAllMissingFields(missingFields)
    return missingFields.length == 0
  }

  const onCaseDataChange = (value) => {
    var cm, ft, inch, lb, oz, kg

    if(value.patient_height_ft != caseData.patient_height_ft || value.patient_height_in != caseData.patient_height_in) {
      cm = Math.round((value.patient_height_ft * 30.48) + (value.patient_height_in * 2.54))
    }else if(value.patient_height_cm != caseData.patient_height_cm) {
      let totInch = Math.round(value.patient_height_cm / 2.54)
      ft = Math.floor(totInch / 12)
      inch = Math.round(totInch % 12)
    }
    if(value.patient_weight_lb != caseData.patient_weight_lb || value.patient_weight_oz != caseData.patient_weight_oz) {
      kg = Math.round((value.patient_weight_lb * 0.453592) + (value.patient_weight_oz * 0.0283495))
    }else if(value.patient_weight_kg != caseData.patient_weight_kg) {
      let totOz = Math.round(value.patient_weight_kg * 35.274)
      lb = Math.floor(totOz / 16)
      oz = Math.round(totOz % 16)
    }

    if(cm) value['patient_height_cm'] = cm
    if(ft) value['patient_height_ft'] = ft
    if(inch || inch == 0) value['patient_height_in'] = inch
    if(lb) value['patient_weight_lb'] = lb
    if(oz || oz == 0) value['patient_weight_oz'] = oz
    if(kg) value['patient_weight_kg'] = kg
    //console.log(value)
    setCaseData(value)
  }

  const columns = useMemo(() => [
    {name:'First Name',selector: row => row.first_name},
    {name:'Last Name',selector: row => row.last_name},
    {name:'Medical Record No.',selector: row => row.medical_record_num},
    {name:'Account No.',selector: row => row.account_num},
    {name:'New Case?',selector: row => <button onClick={() => loadPatient(row)} className="button is-success is-small">Create New Case</button>}
  ])

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head has-background-white">
        <p className="is-size-4">
          {isUpdate ? "Patient Info" : "New Case Setup - Patient Info"}
        </p>
      </header>

      <section className="modal-card-body">
        <p className="title is-5">Case Info</p>
        <DynamicForm 
          form={newCaseFormA}
          values={caseData}
          onValuesChanged={setCaseData}
          loaders={{facility_id:onFacilitySearch}}
          missingFields={allMissingFields}
        />

        <hr/>

        <>
          <p className="title is-5">Patient Info</p>
          <DynamicForm 
            form={newPatientForm}
            values={patientData}
            onValuesChanged={setPatientData}
            missingFields={allMissingFields}
          />
          <DynamicForm 
            form={newCaseFormB}
            values={caseData}
            onValuesChanged={onCaseDataChange}
            missingFields={allMissingFields}
          />
          <PatientBloodForm
            caseData={caseData}
            setCaseData={setCaseData}
            patientData={patientData}
          />
          <DynamicForm 
            form={newCaseFormC}
            values={caseData}
            onValuesChanged={setCaseData}
            source={{
              "allergies": props.allergyOptions,
              "history": props.historyOptions
            }}
          />

          {
            patientHistory.length > 0
            &&
            (
              showPatientHistory
              ?
              <div>
                <hr />
                <p className="title is-5">Patient History</p>
                <table className="table is-narrow is-fullwidth">
                  <thead>
                    <th>Date</th>
                    <th>Location</th>
                    <th>Personnel</th>
                    <th>Procedures</th>
                  </thead>
                  <tbody>
                    {
                      patientHistory.map(c => <tr className="has-background-white-ter">
                        <td>{c.date}</td>
                        <td>{c.facility_name}</td>
                        <td>{c.personnel_names}</td>
                        <td>{c.procedure.map(p => p.name).join(', ')}</td>
                      </tr>)
                    }
                  </tbody>
                </table>
              </div>
              :
              <button className="button is-info is-fullwidth mt-3" onClick={() => setShowPatientHistory(true)}>View Patient History</button>
            )
          }
        </>
      </section>

      <footer className="modal-card-foot has-background-white">
        <div className="level w-100">
          <div className="level-left">
            <button className="level-item button is-danger is-inverted" onClick={props.onClose}>Cancel</button>
          </div>
          <div className="level-right">
            <button className={"level-item button is-success"+(isLoading?" is-loading ":"")} onClick={() => onSave()}>Save</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
}

export default ChartingPatientModal