import React, { useState } from "react";
import { getCurrentDate } from "../helpers/date";
import ProcedureBox from "./ProcedureBox";

function ChecklistModal(props){
  const [items,setItems] = useState(props.items.map(item => ({...item}))) // deep copy items

  const onCheck = (index,checked) => {
    items[index].date_checked = checked ? getCurrentDate() : null
    setItems([...items])
  }

  const onComment = (index,comment) => {
    items[index].comment = comment
    setItems([...items])
  }

  const onSave = () => {
    props.onSave(items)
    props.onClose()
  }

  const onSaveAndContinue = () => {
    props.onSave(items)
    props.onNext()
  }

  const onCreateReminder = () => {
    // todo
  }

  const onDismiss = () => {
    props.onNext()
  }

  const onCancel = () => {
    props.onClose()
  }

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head has-background-white">
        <p className="is-size-4">Checklist - Comments</p>
      </header>

      <section className="modal-card-body">
        <p>Please include all comments or notes about the unchecked items below.</p>

        {
          items.map((item,i) => (
            <div key={i} className="media box-alt">
              <div className="media-left">
                <input
                  className="checkbox-large"
                  style={{marginTop:40}}
                  type="checkbox"
                  checked={!!item.date_checked}
                  onChange={(e) => onCheck(i,e.target.checked)}
                />
              </div>
              <div className="media-content">
                <ProcedureBox procedure={props.procedure} />
                <div className="field">
                  <label className="label">{item.item.name}</label>
                  <p className="control">
                    <textarea
                      className="textarea"
                      onChange={(e) => onComment(i,e.target.value)}
                      value={item.comment ?? ""}
                    />
                  </p>
                </div>
              </div>
            </div>
          ))
        }

      </section>

      <footer className="modal-card-foot has-background-white">
        <div className="level w-100">
          <div className="level-left">
            <button className="level-item button is-danger is-inverted" onClick={() => onCancel()}>Cancel</button>
          </div>
          <div className="level-right">
            {
              props.isConfirmation
              ?
              <>
                <button className="level-item button is-blue" onClick={() => onDismiss()}>Dismiss</button>
                <button className="level-item button is-orange" disabled onClick={() => onCreateReminder()}>Create Reminder</button>
                <button className="level-item button is-success" onClick={() => onSaveAndContinue()}>Save & Continue</button>
              </>
              :
              <button className="level-item button is-success" onClick={() => onSave()}>Save</button>
            }
            
          </div>
        </div>
      </footer>
    </div>
  </div>
}

export default ChecklistModal