import React from "react";
import { FaHeartbeat } from "react-icons/fa";

function ProcedureBox({procedure}) {
  if(procedure == null) return null
  
  const IconComponent = procedure.icon ?? FaHeartbeat
  const color = "#ED1E24"

  return <div className="is-inline-block">
    <div className="level" style={{
      padding: 4,
      border: "3px solid "+color,
      borderRadius: 16
    }}>
      <IconComponent className={"level-item mr-1"} size={20} color={color} />
      <p className="level-item" style={{
        color,
        fontSize:12
      }}>{procedure.name}</p>
    </div>
  </div>
}

export default ProcedureBox;