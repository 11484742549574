import React, { useState, useMemo, useEffect } from 'react';
import DataTable from 'react-data-table-component';

function IDidThisModal(props) {
    const onChange = (id, value, type) => {
        let newData = {...props.data}
        if(id == 1) {
            if(type == 'case_qty') {
                newData['cpb_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['cpb_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['cpb_oc_hours'] = value
            } else if(type == 'patients') {
                newData['cpb_patients'] = value
            }
        } else if(id == 2) {
            if(type == 'case_qty') {
                newData['op_cpb_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['op_cpb_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['op_cpb_oc_hours'] = value
            } else if(type == 'patients') {
                newData['op_cpb_patients'] = value
            }
        } else if(id == 3) {
            if(type == 'case_qty') {
                newData['iabs_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['iabs_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['iabs_oc_hours'] = value
            } else if(type == 'patients') {
                newData['iabs_patients'] = value
            }
        } else if(id == 4) {
            if(type == 'case_qty') {
                newData['stnd_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['stnd_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['stnd_oc_hours'] = value
            } else if(type == 'patients') {
                newData['stnd_patients'] = value
            }
        } else if(id == 5) {
            if(type == 'case_qty') {
                newData['ats_cpb_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['ats_cpb_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['ats_cpb_oc_hours'] = value
            } else if(type == 'patients') {
                newData['ats_cpb_patients'] = value
            }
        } else if(id == 6) {
            if(type == 'case_qty') {
                newData['ats_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['ats_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['ats_oc_hours'] = value
            } else if(type == 'patients') {
                newData['ats_patients'] = value
            }
        } else if(id == 7) {
            if(type == 'case_qty') {
                newData['atsh_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['atsh_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['atsh_oc_hours'] = value
            } else if(type == 'patients') {
                newData['atsh_patients'] = value
            }
        } else if(id == 8) {
            if(type == 'case_qty') {
                newData['atsp_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['atsp_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['atsp_oc_hours'] = value
            } else if(type == 'patients') {
                newData['atsp_patients'] = value
            }
        } else if(id == 9) {
            if(type == 'case_qty') {
                newData['prp_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['prp_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['prp_oc_hours'] = value
            } else if(type == 'patients') {
                newData['prp_patients'] = value
            }
        } else if(id == 10) {
            if(type == 'case_qty') {
                newData['bma_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['bma_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['bma_oc_hours'] = value
            } else if(type == 'patients') {
                newData['bma_patients'] = value
            }
        } else if(id == 11) {
            if(type == 'case_qty') {
                newData['ecmo_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['ecmo_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['ecmo_oc_hours'] = value
            } else if(type == 'patients') {
                newData['ecmo_patients'] = value
            }
        } else if(id == 12) {
            if(type == 'case_qty') {
                newData['ecmom_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['ecmom_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['ecmom_oc_hours'] = value
            } else if(type == 'patients') {
                newData['ecmom_patients'] = value
            }
        } else if(id == 13) {
            if(type == 'case_qty') {
                newData['ecmoms_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['ecmoms_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['ecmoms_oc_hours'] = value
            } else if(type == 'patients') {
                newData['ecmoms_patients'] = value
            }
        } else if(id == 14) {
            if(type == 'case_qty') {
                newData['ecmotp_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['ecmotp_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['ecmotp_oc_hours'] = value
            } else if(type == 'patients') {
                newData['ecmotp_patients'] = value
            }
        } else if(id == 15) {
            if(type == 'case_qty') {
                newData['ecmocp_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['ecmocp_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['ecmocp_oc_hours'] = value
            } else if(type == 'patients') {
                newData['ecmocp_patients'] = value
            }
        } else if(id == 16) {
            if(type == 'case_qty') {
                newData['ecmocs_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['ecmocs_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['ecmocs_oc_hours'] = value
            } else if(type == 'patients') {
                newData['ecmocs_patients'] = value
            }
        } else if(id == 17) {
            if(type == 'case_qty') {
                newData['vad_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['vad_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['vad_oc_hours'] = value
            } else if(type == 'patients') {
                newData['vad_patients'] = value
            }
        } else if(id == 18) {
            if(type == 'case_qty') {
                newData['vadm_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['vadm_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['vadm_oc_hours'] = value
            } else if(type == 'patients') {
                newData['vadm_patients'] = value
            }
        } else if(id == 19) {
            if(type == 'case_qty') {
                newData['tps_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['tps_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['tps_oc_hours'] = value
            } else if(type == 'patients') {
                newData['tps_patients'] = value
            }
        } else if(id == 20) {
            if(type == 'case_qty') {
                newData['tpse_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['tpse_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['tpse_oc_hours'] = value
            } else if(type == 'patients') {
                newData['tpse_patients'] = value
            }
        } else if(id == 21) {
            if(type == 'case_qty') {
                newData['pocsvc_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['pocsvc_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['pocsvc_oc_hours'] = value
            } else if(type == 'patients') {
                newData['pocsvc_patients'] = value
            }
        } else if(id == 22) {
            if(type == 'case_qty') {
                newData['pocsvc_non_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['pocsvc_non_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['pocsvc_non_oc_hours'] = value
            } else if(type == 'patients') {
                newData['pocsvc_non_patients'] = value
            }
        } else if(id == 23) {
            if(type == 'case_qty') {
                newData['travel_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['travel_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['travel_oc_hours'] = value
            } else if(type == 'patients') {
                newData['travel_patients'] = value
            }
        } else if(id == 24) {
            if(type == 'case_qty') {
                newData['oc1_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['oc1_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['oc1_oc_hours'] = value
            } else if(type == 'patients') {
                newData['oc1_patients'] = value
            }
        } else if(id == 25) {
            if(type == 'case_qty') {
                newData['oc2_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['oc2_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['oc2_oc_hours'] = value
            } else if(type == 'patients') {
                newData['oc2_patients'] = value
            }
        } else if(id == 26) {
            if(type == 'case_qty') {
                newData['oc3_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['oc3_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['oc3_oc_hours'] = value
            } else if(type == 'patients') {
                newData['oc3_patients'] = value
            }
        } else if(id == 27) {
            if(type == 'case_qty') {
                newData['admin_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['admin_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['admin_oc_hours'] = value
            } else if(type == 'patients') {
                newData['admin_patients'] = value
            }
        } else if(id == 28) {
            if(type == 'case_qty') {
                newData['pto_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['pto_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['pto_oc_hours'] = value
            } else if(type == 'patients') {
                newData['pto_patients'] = value
            }
        } else if(id == 29) {
            if(type == 'case_qty') {
                newData['off_case_qty'] = value
            } else if(type == 'reg_hours') {
                newData['off_reg_hours'] = value
            } else if(type == 'oc_hours') {
                newData['off_oc_hours'] = value
            } else if(type == 'patients') {
                newData['off_patients'] = value
            }
        }
        props.onChange(newData)
    }

    console.log('props.data',props.data)

    const data = [
        {
            id: 1,
            description: 'Cardiopulmonary Bypass',
            case_qty: props.data.cpb_case_qty,
            reg_hours: props.data.cpb_reg_hours,
            oc_hours: props.data.cpb_oc_hours,
            patients: props.data.cpb_patients
        },
        {
            id: 2,
            description: 'Off Pump Cardiopulmonary Bypass',
            case_qty: props.data.op_cpb_case_qty,
            reg_hours: props.data.op_cpb_reg_hours,
            oc_hours: props.data.op_cpb_oc_hours,
            patients: props.data.op_cpb_patients
        },
        {
            id: 3,
            description: 'Intra-Aortic Balloon Pump Setup',
            case_qty: props.data.iabs_case_qty,
            reg_hours: props.data.iabs_reg_hours,
            oc_hours: props.data.iabs_oc_hours,
            patients: props.data.iabs_patients
        },
        {
            id: 4,
            description: 'Perfusion Standby',
            case_qty: props.data.stnd_case_qty,
            reg_hours: props.data.stnd_reg_hours,
            oc_hours: props.data.stnd_oc_hours,
            patients: props.data.stnd_patients
        },
        {
            id: 5,
            description: 'Autotransfusion Case w/ CPB',
            case_qty: props.data.ats_cpb_case_qty,
            reg_hours: props.data.ats_cpb_reg_hours,
            oc_hours: props.data.ats_cpb_oc_hours,
            patients: props.data.ats_cpb_patients
        },
        {
            id: 6,
            description: 'Autotransfusion Case - non-cardiac',
            case_qty: props.data.ats_case_qty,
            reg_hours: props.data.ats_reg_hours,
            oc_hours: props.data.ats_oc_hours,
            patients: props.data.ats_patients
        },
        {
            id: 7,
            description: 'Autotransfusion Hours in excess of allowable ATS (2 hrs or 4 hrs)',
            case_qty: props.data.atsh_case_qty,
            reg_hours: props.data.atsh_reg_hours,
            oc_hours: props.data.atsh_oc_hours,
            patients: props.data.atsh_patients
        },
        {
            id: 8,
            description: 'Autotransfusion Case - post-operative',
            case_qty: props.data.atsp_case_qty,
            reg_hours: props.data.atsp_reg_hours,
            oc_hours: props.data.atsp_oc_hours,
            patients: props.data.atsp_patients
        },
        {
            id: 9,
            description: 'Platelet Rich Plasma Case',
            case_qty: props.data.prp_case_qty,
            reg_hours: props.data.prp_reg_hours,
            oc_hours: props.data.prp_oc_hours,
            patients: props.data.prp_patients
        },
        {
            id: 10,
            description: 'Bone Marrow Aspirate Case',
            case_qty: props.data.bma_case_qty,
            reg_hours: props.data.bma_reg_hours,
            oc_hours: props.data.bma_oc_hours,
            patients: props.data.bma_patients
        },
        {
            id: 11,
            description: 'ECMO Setup and Initiation',
            case_qty: props.data.ecmo_case_qty,
            reg_hours: props.data.ecmo_reg_hours,
            oc_hours: props.data.ecmo_oc_hours,
            patients: props.data.ecmo_patients
        },
        {
            id: 12,
            description: 'ECMO Management/Monitoring (hours) - by CCP',
            case_qty: props.data.ecmom_case_qty,
            reg_hours: props.data.ecmom_reg_hours,
            oc_hours: props.data.ecmom_oc_hours,
            patients: props.data.ecmom_patients
        },
        {
            id: 13,
            description: 'ECMO Management/Monitoring (hours) - by ECMO Specialist',
            case_qty: props.data.ecmoms_case_qty,
            reg_hours: props.data.ecmoms_reg_hours,
            oc_hours: props.data.ecmoms_oc_hours,
            patients: props.data.ecmoms_patients
        },
        {
            id: 14,
            description: 'ECMO Transport (ground, fixed wing, helo)',
            case_qty: props.data.ecmotp_case_qty,
            reg_hours: props.data.ecmotp_reg_hours,
            oc_hours: props.data.ecmotp_oc_hours,
            patients: props.data.ecmotp_patients
        },
        {
            id: 15,
            description: 'ECMO Consulting and Training (hours) - by Physician',
            case_qty: props.data.ecmocp_case_qty,
            reg_hours: props.data.ecmocp_reg_hours,
            oc_hours: props.data.ecmocp_oc_hours,
            patients: props.data.ecmocp_patients
        },
        {
            id: 16,
            description: 'ECMO Consulting and Training (hours) - by CCP or ECMO Specialist',
            case_qty: props.data.ecmocs_case_qty,
            reg_hours: props.data.ecmocs_reg_hours,
            oc_hours: props.data.ecmocs_oc_hours,
            patients: props.data.ecmocs_patients
        },
        {
            id: 17,
            description: 'VAD Setup and Initiation',
            case_qty: props.data.vad_case_qty,
            reg_hours: props.data.vad_reg_hours,
            oc_hours: props.data.vad_oc_hours,
            patients: props.data.vad_patients
        },
        {
            id: 18,
            description: 'VAD Management/Monitoring (hours) - by CCP',
            case_qty: props.data.vadm_case_qty,
            reg_hours: props.data.vadm_reg_hours,
            oc_hours: props.data.vadm_oc_hours,
            patients: props.data.vadm_patients
        },
        {
            id: 19,
            description: 'Temp / Per Diem CCP Services (hours)',
            case_qty: props.data.tps_case_qty,
            reg_hours: props.data.tps_reg_hours,
            oc_hours: props.data.tps_oc_hours,
            patients: props.data.tps_patients
        },
        {
            id: 20,
            description: 'Temp / Per Diem ECMO Services (hours)',
            case_qty: props.data.tpse_case_qty,
            reg_hours: props.data.tpse_reg_hours,
            oc_hours: props.data.tpse_oc_hours,
            patients: props.data.tpse_patients
        },
        {
            id: 21,
            description: 'Point of Care Lab Service - w/ CPB case',
            case_qty: props.data.pocsvc_case_qty,
            reg_hours: props.data.pocsvc_reg_hours,
            oc_hours: props.data.pocsvc_oc_hours,
            patients: props.data.pocsvc_patients
        },
        {
            id: 22,
            description: 'Point of Care Lab Service - independent of CPB case',
            case_qty: props.data.pocsvc_non_case_qty,
            reg_hours: props.data.pocsvc_non_reg_hours,
            oc_hours: props.data.pocsvc_non_oc_hours,
            patients: props.data.pocsvc_non_patients
        },
        {
            id: 23,
            description: 'Travel Time (if billable - do not include daily commute)',
            case_qty: props.data.travel_case_qty,
            reg_hours: props.data.travel_reg_hours,
            oc_hours: props.data.travel_oc_hours,
            patients: props.data.travel_patients
        },
        {
            id: 24,
            description: 'On call (1st call)',
            case_qty: props.data.oc1_case_qty,
            reg_hours: props.data.oc1_reg_hours,
            oc_hours: props.data.oc1_oc_hours,
            patients: props.data.oc1_patients
        },
        {
            id: 25,
            description: 'On call (2nd call)',
            case_qty: props.data.oc2_case_qty,
            reg_hours: props.data.oc2_reg_hours,
            oc_hours: props.data.oc2_oc_hours,
            patients: props.data.oc2_patients
        },
        {
            id: 26,
            description: 'On call (3rd call)',
            case_qty: props.data.oc3_case_qty,
            reg_hours: props.data.oc3_reg_hours,
            oc_hours: props.data.oc3_oc_hours,
            patients: props.data.oc3_patients
        },
        {
            id: 27,
            description: 'General Admin Time',
            case_qty: props.data.admin_case_qty,
            reg_hours: props.data.admin_reg_hours,
            oc_hours: props.data.admin_oc_hours,
            patients: props.data.admin_patients
        },
        {
            id: 28,
            description: 'PTO',
            case_qty: props.data.pto_case_qty,
            reg_hours: props.data.pto_reg_hours,
            oc_hours: props.data.pto_oc_hours,
            patients: props.data.pto_patients
        },
        {
            id: 29,
            description: 'Off Time',
            case_qty: props.data.off_case_qty,
            reg_hours: props.data.off_reg_hours,
            oc_hours: props.data.off_oc_hours,
            patients: props.data.off_patients
        }
    ]

    const columns = useMemo(() => [
        {   name: 'Description', width:"480px", selector: row => row.description, sortable:true },
        {
            name: 'Case Quantity', selector: row => <div>
                {
                    props.disabled
                    ?
                    <input
                        className="input"
                        type="number"
                        disabled
                        value={row.case_qty == null ? '' : row.case_qty}
                        onChange={(e) => onChange(row.id, e.target.value, 'case_qty')}
                    />
                    :
                    <input
                        className="input"
                        type="number"
                        value={row.case_qty == null ? '' : row.case_qty}
                        onChange={(e) => onChange(row.id, e.target.value, 'case_qty')}
                    />
                }
                
            </div>
        },
        { 
            name: 'Regular Hours', selector: row => <div>
                {
                    props.disabled
                    ?
                    <input
                        className="input"
                        type="number"
                        disabled
                        value={row.reg_hours == null ? '' : row.reg_hours}
                        onChange={(e) => onChange(row.id, e.target.value, 'reg_hours')}
                    />
                    :
                    <input
                        className="input"
                        type="number"
                        value={row.reg_hours == null ? '' : row.reg_hours}
                        onChange={(e) => onChange(row.id, e.target.value, 'reg_hours')}
                    />
                }
                
            </div>
        },
        {
            name: 'On Call Hours', selector: row => <div>
                {
                    props.disabled
                    ?
                    <input
                        className="input"
                        type="number"
                        disabled
                        value={row.oc_hours == null ? '' : row.oc_hours}
                        onChange={(e) => onChange(row.id, e.target.value, 'oc_hours')}
                    />
                    :
                    <input
                        className="input"
                        type="number"
                        value={row.oc_hours == null ? '' : row.oc_hours}
                        onChange={(e) => onChange(row.id, e.target.value, 'oc_hours')}
                    />
                }
                
            </div>
        },
        {
            name: 'Patients', selector: row => <div>
                {
                    props.disabled
                    ?
                    <input
                        className="input"
                        type="number"
                        disabled
                        value={row.patients == null ? '' : row.patients}
                        onChange={(e) => onChange(row.id, e.target.value, 'patients')}
                    />
                    :
                    <input
                        className="input"
                        type="number"
                        value={row.patients == null ? '' : row.patients}
                        onChange={(e) => onChange(row.id, e.target.value, 'patients')}
                    />
                }
                
            </div>
        }
    ])

    let bottomSaveBtnClasses = "button is-success level-item";
    if (props.isSaving) bottomSaveBtnClasses += " is-loading"

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card modal-large">
            <header className="modal-card-head">
                <div className="level w-100">
                    <div className="level-left">
                        <p className="title is-size-3">{props.data.date_created}</p>
                    </div>
                    <div className="level-right">

                    </div>
                </div>
            </header>

            <section className="modal-card-body p-0">
                <div className="p-5">
                    <div className="has-background-white rounded w-250">
                        <DataTable 
                            data={data}
                            columns={columns}
                            striped
                        />
                        <button onClick={() => props.onClose()} className="modal-close is-large" aria-label="close"></button>
                    </div>
                </div>
            </section>
            <footer className="modal-card-foot has-background-white">
                {
                    props.disabled
                    ?
                    <div className="level w-100">
                        <div className="level-left">

                        </div>
                        <div className="level-right">
                            <button style={{ backgroundColor: '#1C2944', color: '#FFFFFF' }} className="button" onClick={() => props.onSave()}>Close</button>
                        </div>
                    </div>
                    :
                    <div className="level w-100">
                        <div className="level-left">
                            <button className="button is-danger is-inverted" onClick={() => props.onClose()}>Cancel</button>
                        </div>
                        <div className="level-right">
                            <button style={{ backgroundColor: '#1C2944', color: '#FFFFFF' }} className={bottomSaveBtnClasses} onClick={() => props.onSave()}>Save</button>
                            <button style={{ paddingLeft: 10 }} className={bottomSaveBtnClasses} onClick={() => props.onSubmit()}>Submit</button>
                        </div>
                    </div>
                }
                
            </footer>
        </div>
    </div>
}

export default IDidThisModal