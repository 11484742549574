import React, { useEffect } from 'react';

function SearchBar(props) {

  useEffect(() => {
    
  },[])

  return <div className="field has-addons">
    <div className="control is-expanded">
      <input
        className="input"
        type="text"
        value={props.query}
        placeholder="Search here..."
        onChange={(e) => props.onChange(e.target.value)}
        onKeyDown={(e) => {if(e.key === "Enter") props.onSubmit()}}
      />
    </div>
    <div className="control">
      <button onClick={() => props.onSubmit()} className="button is-success">
        Search
      </button>
    </div>    
  </div>
}

export default SearchBar;