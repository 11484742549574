import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from 'react-to-print';
import Select from 'react-select';
import DatePicker from 'react-datepicker'
import API from "../../services/API";

function PdfSurgeonReport(props) {
    const [filters, setFilters] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState()
    const [userOptions, setUserOptions] = useState()
    const componentRef = useRef()
    const today = new Date()

    useEffect(() => {
        setIsLoading(true)

        let queries = [
            { 'method': 'GET', 'path': '/api/personnellist/', 'params': { 'job': 'surgeon' } },
            { 'method': 'GET', 'path': '/api/userlist/', 'params': { 'job': 'surgeon' } }
        ]
        API.all(queries, function (resp) {
            setUserOptions([...resp[0].map(i => ({"value": `personnel-${i.id}`, "label": i.user_full_name})), 
                ...resp[1].map(i => ({"value": `user-${i.id}`, "label": i.user_full_name}))
            ])
            setIsLoading(false)
        }, onDataLoadError)
    }, []);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onFilterChanged = (value, type) => {
        let fValues = { ...filters }
        if (type === 0) {
            fValues['start_date'] = value
        } else if (type === 1) {
            fValues['end_date'] = value
        } else if (type === 2) {
            fValues['facility'] = value
        } else if (type === 3) {
            fValues['user'] = value
        }
        setFilters(fValues)
    }

    const renderData = () => {
        if (filters.user != null) {
            if (!isLoading) {
                var start_date, end_date, dateFilter = false
                var today = new Date()
                let today_month = today.getMonth()
                let today_year = today.getFullYear()
                setIsLoading(true)
                if (filters.start_date && filters.end_date) {
                    start_date = new Date(filters.start_date)
                    end_date = new Date(filters.end_date)
                    dateFilter = true
                }

                let user
                let personnel

                if(filters.user && filters.user.length > 0) {
                    if(filters.user.includes('personnel')) {
                        personnel = filters.user.split('personnel-')[1]
                    } else if(filters.user.includes('user')) {
                        user = filters.user.split('user-')[1]
                    }
                }

                let params = {}

                if(user) {
                    params['user_id'] = user
                }

                if(personnel) {
                    params['personnel_id'] = personnel
                }

                if (filters.facility != null) {
                    params = {
                        ...params,
                        'facility_id': filters.facility
                    }
                }

                API.query('GET', `/api/surgeonreport/`, params, {}, function (resp) {
                    var prior_years = []
                    let reducedResp = resp.reduce(function (memo, e, idx) {
                        let case_date = new Date(e.date)
                        let case_year = case_date.getFullYear()
                        let case_month = case_date.getMonth()

                        if (idx == 0) {
                            memo = [
                                { label: 'Current', cpb: 0, auto: 0, standby: 0, therapy: 0, ecls: 0, hipec: 0, service: 0, total: 0 },
                                { label: 'Last Month', cpb: 0, auto: 0, standby: 0, therapy: 0, ecls: 0, hipec: 0, service: 0, total: 0 },
                                { label: 'Year to Date', cpb: 0, auto: 0, standby: 0, therapy: 0, ecls: 0, hipec: 0, service: 0, total: 0 },
                                { label: 'Prior YTD', cpb: 0, auto: 0, standby: 0, therapy: 0, ecls: 0, hipec: 0, service: 0, total: 0 }
                            ]
                        }

                        //Current
                        if (dateFilter) {
                            if (start_date <= case_date <= end_date) {
                                let index = memo.findIndex(x => x.label == 'Current')
                                let total = 0
                                if (e.procedure.includes(1)) {
                                    memo[index].cpb += 1
                                    total += 1
                                }
                                if (e.procedure.includes(2)) {
                                    memo[index].auto += 1
                                    total += 1
                                }
                                if (e.procedure.includes(3)) {
                                    memo[index].hipec += 1
                                    total += 1
                                }
                                if (e.procedure.includes(4)) {
                                    memo[index].ecls += 1
                                    total += 1
                                }
                                if (e.procedure.includes(5)) {
                                    memo[index].therapy += 1
                                    total += 1
                                }
                                if (e.procedure.includes(6)) {
                                    memo[index].service += 1
                                    total += 1
                                }
                                if (e.procedure.includes(7)) {
                                    memo[index].standby += 1
                                    total += 1
                                }
                                memo[index].total += total
                            }
                        } else {
                            let beginning = new Date(today_year, today_month, 1)
                            if (beginning <= case_date && case_date <= today) {
                                let index = memo.findIndex(x => x.label == 'Current')
                                let total = 0
                                if (e.procedure.includes(1)) {
                                    memo[index].cpb += 1
                                    total += 1
                                }
                                if (e.procedure.includes(2)) {
                                    memo[index].auto += 1
                                    total += 1
                                }
                                if (e.procedure.includes(3)) {
                                    memo[index].hipec += 1
                                    total += 1
                                }
                                if (e.procedure.includes(4)) {
                                    memo[index].ecls += 1
                                    total += 1
                                }
                                if (e.procedure.includes(5)) {
                                    memo[index].therapy += 1
                                    total += 1
                                }
                                if (e.procedure.includes(6)) {
                                    memo[index].service += 1
                                    total += 1
                                }
                                if (e.procedure.includes(7)) {
                                    memo[index].standby += 1
                                    total += 1
                                }
                                memo[index].total += total
                            }
                        }

                        //Last Month
                        if (today_month == 0) {
                            let new_month = 11
                            let new_year = today_year - 1
                            if (new_month == case_month && new_year == case_year) {
                                let index = memo.findIndex(x => x.label == 'Last Month')
                                let total = 0
                                if (e.procedure.includes(1)) {
                                    memo[index].cpb += 1
                                    total += 1
                                }
                                if (e.procedure.includes(2)) {
                                    memo[index].auto += 1
                                    total += 1
                                }
                                if (e.procedure.includes(3)) {
                                    memo[index].hipec += 1
                                    total += 1
                                }
                                if (e.procedure.includes(4)) {
                                    memo[index].ecls += 1
                                    total += 1
                                }
                                if (e.procedure.includes(5)) {
                                    memo[index].therapy += 1
                                    total += 1
                                }
                                if (e.procedure.includes(6)) {
                                    memo[index].service += 1
                                    total += 1
                                }
                                if (e.procedure.includes(7)) {
                                    memo[index].standby += 1
                                    total += 1
                                }
                                memo[index].total += total
                            }
                        } else {
                            let new_month = today_month - 1
                            if (new_month == case_month && today_year == case_year) {
                                let index = memo.findIndex(x => x.label == 'Last Month')
                                let total = 0
                                if (e.procedure.includes(1)) {
                                    memo[index].cpb += 1
                                    total += 1
                                }
                                if (e.procedure.includes(2)) {
                                    memo[index].auto += 1
                                    total += 1
                                }
                                if (e.procedure.includes(3)) {
                                    memo[index].hipec += 1
                                    total += 1
                                }
                                if (e.procedure.includes(4)) {
                                    memo[index].ecls += 1
                                    total += 1
                                }
                                if (e.procedure.includes(5)) {
                                    memo[index].therapy += 1
                                    total += 1
                                }
                                if (e.procedure.includes(6)) {
                                    memo[index].service += 1
                                    total += 1
                                }
                                if (e.procedure.includes(7)) {
                                    memo[index].standby += 1
                                    total += 1
                                }
                                memo[index].total += total
                            }
                        }

                        //Year to Date
                        if (case_year == today_year) {
                            let index = memo.findIndex(x => x.label == 'Year to Date')
                            let total = 0
                            if (e.procedure.includes(1)) {
                                memo[index].cpb += 1
                                total += 1
                            }
                            if (e.procedure.includes(2)) {
                                memo[index].auto += 1
                                total += 1
                            }
                            if (e.procedure.includes(3)) {
                                memo[index].hipec += 1
                                total += 1
                            }
                            if (e.procedure.includes(4)) {
                                memo[index].ecls += 1
                                total += 1
                            }
                            if (e.procedure.includes(5)) {
                                memo[index].therapy += 1
                                total += 1
                            }
                            if (e.procedure.includes(6)) {
                                memo[index].service += 1
                                total += 1
                            }
                            if (e.procedure.includes(7)) {
                                memo[index].standby += 1
                                total += 1
                            }
                            memo[index].total += total
                        }

                        //Prior YTD
                        if (case_year == (today_year - 1)) {
                            let index = memo.findIndex(x => x.label == 'Prior YTD')
                            let total = 0
                            if (e.procedure.includes(1)) {
                                memo[index].cpb += 1
                                total += 1
                            }
                            if (e.procedure.includes(2)) {
                                memo[index].auto += 1
                                total += 1
                            }
                            if (e.procedure.includes(3)) {
                                memo[index].hipec += 1
                                total += 1
                            }
                            if (e.procedure.includes(4)) {
                                memo[index].ecls += 1
                                total += 1
                            }
                            if (e.procedure.includes(5)) {
                                memo[index].therapy += 1
                                total += 1
                            }
                            if (e.procedure.includes(6)) {
                                memo[index].service += 1
                                total += 1
                            }
                            if (e.procedure.includes(7)) {
                                memo[index].standby += 1
                                total += 1
                            }
                            memo[index].total += total
                        }

                        //Prior Year Logic
                        if (case_year < today_year) {
                            let index = prior_years.findIndex(x => x.label == `${case_year}`)
                            if (index == -1) {
                                prior_years.push({
                                    label: `${case_year}`,
                                    cpb: 0,
                                    auto: 0,
                                    standby: 0,
                                    therapy: 0,
                                    ecls: 0,
                                    hipec: 0,
                                    service: 0,
                                    total: 0
                                })

                                let new_index = prior_years.findIndex(e => e.label == `${case_year}`)
                                let total = 0

                                if (e.procedure.includes(1)) {
                                    prior_years[new_index].cpb += 1
                                    total += 1
                                }
                                if (e.procedure.includes(2)) {
                                    prior_years[new_index].auto += 1
                                    total += 1
                                }
                                if (e.procedure.includes(3)) {
                                    prior_years[new_index].hipec += 1
                                    total += 1
                                }
                                if (e.procedure.includes(4)) {
                                    prior_years[new_index].ecls += 1
                                    total += 1
                                }
                                if (e.procedure.includes(5)) {
                                    prior_years[new_index].therapy += 1
                                    total += 1
                                }
                                if (e.procedure.includes(6)) {
                                    prior_years[new_index].service += 1
                                    total += 1
                                }
                                if (e.procedure.includes(7)) {
                                    prior_years[new_index].standby += 1
                                    total += 1
                                }
                                prior_years[new_index].total += total
                            } else {
                                let total = 0
                                if (e.procedure.includes(1)) {
                                    prior_years[new_index].cpb += 1
                                    total += 1
                                }
                                if (e.procedure.includes(2)) {
                                    prior_years[new_index].auto += 1
                                    total += 1
                                }
                                if (e.procedure.includes(3)) {
                                    prior_years[new_index].hipec += 1
                                    total += 1
                                }
                                if (e.procedure.includes(4)) {
                                    prior_years[new_index].ecls += 1
                                    total += 1
                                }
                                if (e.procedure.includes(5)) {
                                    prior_years[new_index].therapy += 1
                                    total += 1
                                }
                                if (e.procedure.includes(6)) {
                                    prior_years[new_index].service += 1
                                    total += 1
                                }
                                if (e.procedure.includes(7)) {
                                    prior_years[new_index].standby += 1
                                    total += 1
                                }
                                prior_years[new_index].total += total
                            }
                        }

                        return memo
                    }, [])
                    //sort prior_years and append
                    prior_years.sort(function(a, b) {return parseInt(a.label) - parseInt(b.label)})
                    setData([...reducedResp, ...prior_years])
                    setIsLoading(false)
                }, onDataLoadError)
            }
        }
    }

    const onDataLoadError = (error) => {
        console.log(error)
    }

    const start_date = filters.start_date ? new Date(filters.start_date).toLocaleDateString() : ''
    const end_date = filters.end_date ? new Date(filters.end_date).toLocaleDateString() : ''

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card modal-extra-large">
            <header className="modal-card-head">
                <p className="modal-card-title">Surgeon Report</p>
            </header>

            <section className="modal-card-body p-0">
                <div className="p-5">
                    <div className="w-100">
                        <div className="columns">
                            <div className="column">
                                <div style={{ midWidth: '499px', maxWidth: '500px' }}>
                                    <Select
                                        options={props.facilities}
                                        placeholder={'All'}
                                        value={props.facilities.find((e) => e.value == filters.facility)}
                                        onChange={(option) => onFilterChanged(option.value, 2)}
                                    />
                                </div>
                            </div>
                            <div className="column">
                                <div style={{ midWidth: '499px', maxWidth: '500px' }}>
                                    {
                                        userOptions
                                        &&
                                        <Select
                                            options={userOptions}
                                            placeholder={'Select a Surgeon'}
                                            value={userOptions.find((e) => e.value == filters.facility)}
                                            onChange={(option) => onFilterChanged(option.value, 3)}
                                        />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="level w-100">
                            <div className="level-item">
                                <div style={{ maxWidth: '200px', minWidth: '199px' }}>
                                    <DatePicker
                                        className="input"
                                        selectsStart
                                        startDate={filters.start_date}
                                        endDate={filters.end_date}
                                        selected={filters.start_date}
                                        onChange={(date) => onFilterChanged(date, 0)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        maxDate={new Date()}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Start Date"
                                    />
                                </div>
                            </div>
                            <div className="level-item">
                                <div style={{ maxWidth: '200px', minWidth: '199px' }}>
                                    <DatePicker
                                        className="input"
                                        selectsStart
                                        startDate={filters.start_date}
                                        endDate={filters.end_date}
                                        selected={filters.end_date}
                                        onChange={(date) => onFilterChanged(date, 1)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        maxDate={new Date()}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="End Date"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="level w-100">
                            <div className="level-item has-text-centered">
                                <button className="button is-success" onClick={() => renderData()}>Generate</button>
                            </div>
                        </div>

                        {
                            (!data)
                            &&
                            <div>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        }

                        {
                            (data && !isLoading)
                            &&
                            <div ref={componentRef} className="has-background-white">
                                <div className="level w-100">
                                    <div className="level-left">
                                        <p className="title is-5">Surgeon Report</p>
                                    </div>
                                    <div className="level-right">
                                        <p>{`(Printed ${today.toLocaleDateString()})`}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="columns">
                                    <div className="column is-2">
                                        <p className="has-text-weight-semibold">Surgeon:</p>
                                        <p className="has-text-weight-semibold">Hospital:</p>
                                        <p className="has-text-weight-semibold">Date Range:</p>
                                    </div>
                                    <div className="column is-6">
                                        <p>{userOptions.find(e => e.value == filters.user).label}</p>
                                        <p>{filters.facility ? props.facilities.find(e => e.value == filters.facility).label : ``}</p>
                                        <p>{`${start_date} - ${end_date}`}</p>
                                    </div>
                                    <div className="column is-4"></div>
                                </div>

                                <hr />

                                <p className="title is-5">Hospital Volume:</p>

                                <hr />

                                <table className="table is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>CPB</th>
                                            <th>ATS</th>
                                            <th>Standby</th>
                                            <th>Therapy</th>
                                            <th>ECLS</th>
                                            <th>HIPEC</th>
                                            <th>Service</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.map((e, idx) => {
                                                return <tr key={idx}>
                                                    <td>{e.label}</td>
                                                    <td>{e.cpb}</td>
                                                    <td>{e.auto}</td>
                                                    <td>{e.standby}</td>
                                                    <td>{e.therapy}</td>
                                                    <td>{e.ecls}</td>
                                                    <td>{e.hipec}</td>
                                                    <td>{e.service}</td>
                                                    <td>{e.total}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>

                <button onClick={props.onClose} className="modal-close is-large" aria-label="close"></button>
            </section>

            <footer className="modal-card-foot">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="button is-danger" onClick={props.onClose}>Close</button>
                    </div>
                    <div className="level-right">
                        {
                            data
                            &&
                            <button className="button is-success" onClick={handlePrint}>Print</button>
                        }
                    </div>
                </div>
            </footer>
        </div>
    </div>
}

export default PdfSurgeonReport