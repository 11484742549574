import React from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "./Dropdown";
import {
  FaUserCircle,
  FaClipboardCheck,
  FaCog,
  FaAddressCard,
  FaEnvelopeOpenText,
  FaQuestionCircle,
  FaInfoCircle,
  FaSignOutAlt,
} from "react-icons/fa";

function SideNav(props) {
  const handleLogout = (e) => {
    e.preventDefault();
    props.onLogout();
    window.location.href = "/logout";
  };

  return (
    <div
      className="is-flex is-flex-direction-column is-justify-content-space-between p-5 has-background-white has-border-right"
      style={{
        position: "absolute",
        top: 80,
        width: 150,
        height: "calc(100% - 80px)",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      {/* Dynamic Menu */}
      <div className="is-flex is-flex-direction-column mt-5">
        {props.menuItems.map((item, i) => (
          <a
            key={i}
            className={
              "is-size-7 mb-4 has-text-weight-" +
              (item.active ? "bold" : "semibold")
            }
            onClick={item.callback}
            disabled={item.disabled}
          >
            {item.title}
          </a>
        ))}
      </div>

      {/* Bottom */}
      <div className="is-flex is-flex-direction-column is-align-items-center">
        <NavLink to="settings" className="has-text-black mb-5">
          <FaCog size={40} style={{ marginBottom: "48px" }} />
        </NavLink>
        {/* <a className="has-text-black mb-5"><FaUserCircle size={60}/></a> */}
        <Dropdown
          className="navbar-item has-dropdown has-dropdown-up"
          style={{ marginTop: "48px", position: "fixed", zIndex: 1000 }}
        >
          <div className="navbar-link is-arrowless has-text-black mb-5">
            <FaUserCircle size={60} />
          </div>
          <div className="navbar-dropdown">
            <div className="navbar-item">
              <NavLink to="settings?tab=3">
                <FaAddressCard size={12} />
                <span style={{ marginLeft: "6px" }}>Profile</span>
              </NavLink>
            </div>
            <div className="navbar-item">
              <NavLink to="settings?tab=5">
                <FaClipboardCheck size={12} />
                <span style={{ marginLeft: "6px" }}>IDidThis</span>
              </NavLink>
            </div>
            <div className="navbar-item">
              <NavLink to="settings?tab=2">
                <FaEnvelopeOpenText size={12} />
                <span style={{ marginLeft: "6px" }}>
                  Notification Preferences
                </span>
              </NavLink>
            </div>
            <div className="navbar-item">
              <NavLink to="settings?tab=4">
                <FaQuestionCircle size={12} />
                <span style={{ marginLeft: "6px" }}>Contact Support</span>
              </NavLink>
            </div>
            <div className="navbar-item">
              <NavLink to="settings?tab=1">
                <FaInfoCircle size={12} />
                <span style={{ marginLeft: "6px" }}>Help Articles</span>
              </NavLink>
            </div>
            <div className="navbar-item">
              <a onClick={handleLogout}>
                <FaSignOutAlt size={12} />
                <span style={{ marginLeft: "6px" }}>LOGOUT</span>
              </a>
            </div>
          </div>
        </Dropdown>
        <a
          className="has-text-black is-size-7 has-text-weight-bold mb-5"
          onClick={handleLogout}
          style={{ marginTop: "14px" }}
        >
          LOGOUT
        </a>
        <a className="has-text-black is-size-7 has-text-centered">
          VERSION 2.3.3.4
        </a>
      </div>
    </div>
  );
}

export default SideNav;
