import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import DatePicker from "react-datepicker";
import API from "../../services/API";
import { dateToDateOnlyString } from "../helpers/date";

export const PdfContinuousQualityImprovementMockData = (numEntries = 10) => {
  const procedures = [
    "CPB",
    "Autotransfusion",
    "Perfusion Standby",
    "HIPEC",
    "ECLS",
  ];
  let i;
  const items = [];
  for (i = 0; i < numEntries; i++) {
    items.push({
      item_name: "Procedure conducted and terminated without incident",
      total_count: 68,
      yes_count: 67,
      no_count: 1,
      compliance: "98.5%",
      target: "90%",
    });
  }
  const results = procedures.map((p) => ({ procedure: p, items: items }));
  return results;
};

export const PdfContinuousQualityImprovementRenderer = (data, ref) => {
  console.log("data: ", data);
  const today = new Date();
  return (
    <div ref={ref} className="has-background-white">
      <div className="w-100 is-size-7">
        <p className="is-size-5">Continuous Quality Improvement</p>
        <p className="">{`(Printed ${today.toLocaleDateString()})`}</p>
        <p>
          <span className="has-text-weight-bold">{"Hospital:"}</span>
          &nbsp;{data.facility}
        </p>
        <p>
          <span className="has-text-weight-bold">Date Range:</span>&nbsp;
          {data.dateRange}
        </p>
      </div>

      <table className="table is-fullwidth mt-5">
        <thead>
          <tr>
            <th></th>
            <th>Quality Control Item</th>
            <th>Total Cases</th>
            <th>Yes</th>
            <th>No</th>
            <th>Compliance</th>
            <th>Target %</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((x, idx) => (
            <React.Fragment key={idx}>
              <tr key={idx}>
                <td colSpan={7} className="has-text-weight-bold">
                  {x.procedure}
                </td>
              </tr>
              {x.items.map((item, itemIndex) => (
                <tr key={`${idx}-${itemIndex}`}>
                  <td></td>
                  <td>{item.item_name}</td>
                  <td>{item.total_count}</td>
                  <td>{item.yes_count}</td>
                  <td>{item.no_count}</td>
                  <td>{item.compliance}</td>
                  <td>{item.target}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const PdfContinuousQualityImprovement = (props) => {
  const [filters, setFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onFilterChanged = (value, type) => {
    let fValues = { ...filters };
    if (type === 0) {
      fValues["start_date"] = value;
    } else if (type === 1) {
      fValues["end_date"] = value;
    } else if (type === 2) {
      fValues["facility"] = value;
    }
    setFilters(fValues);
  };

  const renderData = () => {
    if (filters.facility != null && filters.start_date && filters.end_date) {
      if (!isLoading) {
        setIsLoading(true);

        const params = {
          facility_id: filters.facility,
          start_date: dateToDateOnlyString(filters.start_date),
          end_date: dateToDateOnlyString(filters.end_date),
        };

        API.getReportBundleData(
          params,
          function (resp) {
            setData({
              facility: facility ?? "",
              results: resp.quality_improvement_data,
              dateRange: `${start_date} - ${end_date}`,
            });
            setIsLoading(false);
          },
          onDataLoadError
        );
      }
    }
  };

  const onDataLoadError = (error) => {
    console.log(error);
  };

  const start_date = filters.start_date
    ? new Date(filters.start_date).toLocaleDateString()
    : "";
  const end_date = filters.end_date
    ? new Date(filters.end_date).toLocaleDateString()
    : "";
  const facility =
    props.facilities && filters.facility
      ? props.facilities.find((f) => {
          return f.value == filters.facility;
        }).label ?? ""
      : "";

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card modal-extra-large">
        <header className="modal-card-head">
          <p className="modal-card-title">Continuous Quality Improvement</p>
        </header>

        <section className="modal-card-body p-0">
          <div className="p-5">
            <div className="w-100">
              <div className="columns">
                <div className="column">
                  <div style={{ midWidth: "499px", maxWidth: "500px" }}>
                    <Select
                      options={props.facilities}
                      placeholder={"All"}
                      value={props.facilities.find(
                        (e) => e.value == filters.facility
                      )}
                      onChange={(option) => onFilterChanged(option.value, 2)}
                    />
                  </div>
                </div>
                <div
                  className="column"
                  style={{ maxWidth: "200px", minWidth: "199px" }}
                >
                  <DatePicker
                    className="input"
                    selectsStart
                    startDate={filters.start_date}
                    endDate={filters.end_date}
                    selected={filters.start_date}
                    onChange={(date) => onFilterChanged(date, 0)}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Start Date"
                  />
                </div>
                <div
                  className="column"
                  style={{ maxWidth: "200px", minWidth: "199px" }}
                >
                  <DatePicker
                    className="input"
                    selectsStart
                    startDate={filters.start_date}
                    endDate={filters.end_date}
                    selected={filters.end_date}
                    onChange={(date) => onFilterChanged(date, 1)}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="End Date"
                  />
                </div>
              </div>
              <div className="level w-100">
                <div className="level-item has-text-centered">
                  <button
                    className={`button is-success ${isLoading && "is-loading"}`}
                    onClick={() => renderData()}
                    disabled={!start_date || !end_date || !facility}
                  >
                    Generate
                  </button>
                </div>
              </div>

              {!data && (
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              )}

              {data &&
                !isLoading &&
                PdfContinuousQualityImprovementRenderer(data, componentRef)}
            </div>
          </div>

          <button
            onClick={props.onClose}
            className="modal-close is-large"
            aria-label="close"
          ></button>
        </section>

        <footer className="modal-card-foot">
          <div className="level w-100">
            <div className="level-left">
              <button className="button is-danger" onClick={props.onClose}>
                Close
              </button>
            </div>
            <div className="level-right">
              {data && (
                <button className="button is-success" onClick={handlePrint}>
                  Print
                </button>
              )}
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default PdfContinuousQualityImprovement;
