import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import ChartingModal from "./ChartingModal";
import ButtonModal from "./ButtonModal";
import Dropdown from "./Dropdown";
import API from "../../services/API";
import Session from "../../services/Session"

import { useNavigate } from "react-router-dom";

function TopButtons() {
  const navigate = useNavigate()
  const [showChartingModal, setShowChartingModal] = useState(false)
  const [firstPath, setFirstPath] = useState({})
  const [secondPath, setSecondPath] = useState({})
  const [buttons, setButtons] = useState([])
  const [selected, setSelected] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const [showButtonModal, setShowButtonModal] = useState(false)

  const onEditChange = (value) => {
    setSelected(value)
  }

  const paths = [
    { id: 1, label: 'EMERGENCY CASE', path: '/cases?tab=5&quick=1', value: 1 },
    { id: 2, label: 'SETUP PERSONNEL', path: '/setup?tab=1', value:2 },
    { id: 3, label: 'SETUP A HOSPITAL', path: '/setup?tab=2', value: 3 },
    { id: 4, label: 'SETUP A PROCEDURE', path: '/setup?tab=3', value: 4 },
    { id: 5, label: 'SETUP A CHECKLIST', path: '/setup?tab=4', value: 5 },
    { id: 6, label: 'SETUP AN ITEM', path: '/setup?tab=5', value: 6 },
    { id: 7, label: 'SETUP A QC ITEM', path: '/setup?tab=6', value: 7 },
    { id: 8, label: 'SETUP STUDY DATA', path: '/setup?tab=7', value: 8 },
    { id: 9, label: 'SETUP CHARTING', path: '/setup?tab=8', value: 9 },
    { id: 10, label: 'SETUP CHARTING DETAILS', path: '/setup?tab=9', value: 10 },
    { id: 11, label: 'SETUP EQUIPMENT QC', path: '/setup?tab=10', value: 11 },
    { id: 12, label: 'SETUP COMMUNICATIONS', path: '/setup?tab=11', value: 12 },
    { id: 14, label: 'CASE REPORTS', path: '/reports?tab=2', value: 14 },
    { id: 15, label: 'INVENTORY REPORTS', path: '/reports?tab=3', value: 15 },
    { id: 16, label: 'BUSINESS REPORTS', path: '/reports?tab=1', value: 16 },
    { id: 17, label: 'PLATFORM USAGE REPORTS', path: '/reports?tab=4', value: 17 },
    { id: 18, label: 'FIELD ORDERS', path: '/inventory?tab=1', value: 18 },
    { id: 19, label: 'MANAGE INVENTORY', path: '/inventory?tab=2', value: 19 },
    // { id: 20, label: 'ITEM CATEGORIES ADMIN', path: '/settings?tab=5' },
    // { id: 21, label: 'MANUFACTURER ADMIN', path: '/settings?tab=6' },
    // { id: 22, label: 'MEDICATION ADMIN', path: '/settings?tab=7' },
    // { id: 23, label: 'CHARTING EVENT ADMIN', path: '/settings?tab=8' },
    // { id: 3, label: 'USER ADMIN', path: '/settings?tab=9' },
  ]

  const getPathID = (name) => {
    if (name == 'case_create') {
      return 1
    } else if (name == 'setup_personnel') {
      return 2
    } else if (name == 'setup_facility') {
      return 3
    } else if (name == 'setup_procedure') {
      return 4
    } else if (name == 'setup_checklist') {
      return 5
    } else if (name == 'setup_item') {
      return 6
    } else if (name == 'setup_qc') {
      return 7
    } else if (name == 'setup_study') {
      return 8
    } else if (name == 'setup_charting_setup') {
      return 9
    } else if (name == 'setup_charting_details') {
      return 10
    } else if (name == 'setup_eqc') {
      return 11
    } else if (name == 'setup_comms') {
      return 12
    } else if (name == 'report_case') {
      return 14
    } else if (name == 'report_inventory') {
      return 15
    } else if (name == 'report_business') {
      return 16
    } else if (name == 'report_usage') {
      return 17
    } else if (name == 'inventory_orders') {
      return 18
    } else if (name == 'inventory_manage') {
      return 19
    } else if (name == 'admin_category') {
      return 20
    } else if (name == 'admin_manufacturer') {
      return 21
    } else if (name == 'admin_medications') {
      return 22
    } else if (name == 'admin_events') {
      return 23
    }
  }

  const updateSelected = () => {
    if(!isSaving) {
      setIsSaving(true)
      let data = {
        'selected_id': selected.selected_id
      }
      let user = Session.get('user')
      API.query('PATCH', `/api/pageviewhistory/${user.page_view_history}/`, {}, data, function (resp) {
        let path = paths.find((i) => i.id == resp.selected_id)
        let tempButtons = [
          {
            title: firstPath.label,
            color: "#AC1F3E",
            onClick: () => navigate(firstPath.path)
          },
          {
            title: secondPath.label,
            color: "#1C2944",
            onClick: () => navigate(secondPath.path)
          },
          {
            title: path.label,
            color: "#AC1F3E",
            onClick: () => navigate(path.path)
          }
        ]
        setButtons(tempButtons)
        setIsSaving(false)
        setShowButtonModal(false)
      }, onDataLoadError)
    }
  }

  Object.defineProperty(Object.prototype, 'pop', {
    enumerable: false,
    configurable: true,
    writable: true,
    value: function (key) {
      const ret = this[key];
      delete this[key];

      return ret;
    }
  });

  useEffect(() => {
    var user = Session.get('user')
    if (user && user.page_view_history) {
      API.query('GET', `/api/pageviewhistory/${user.page_view_history}/`, {}, {}, function (resp) {
        let origSelectedID = resp.selected_id
        let hist = resp

        hist.pop('id')
        hist.pop('selected_id')

        let newHist = []
        for (var h in hist) {
          newHist.push([h, hist[h]])
        }

        newHist.sort(function (a, b) {
          return a[1] - b[1];
        });

        let first = newHist[newHist.length - 1]
        let firstID = getPathID(first[0])
        let tempFirstPath = paths.find((i) => i.id == firstID)
        setFirstPath(tempFirstPath)

        let second = newHist[newHist.length - 2]
        let secondID = getPathID(second[0])
        let tempSecondPath = paths.find((i) => i.id == secondID)
        setSecondPath(tempSecondPath)
        
        let tempButtons = []
        if (origSelectedID) {
          let tempSelectedPath = paths.find((i) => origSelectedID == i.id)
          tempButtons = [
            {
              title: tempFirstPath.label,
              color: "#AC1F3E",
              onClick: () => navigate(tempFirstPath.path)
            },
            {
              title: tempSecondPath.label,
              color: "#1C2944",
              onClick: () => navigate(tempSecondPath.path)
            },
            {
              title: tempSelectedPath.label,
              color: "#AC1F3E",
              onClick: () => navigate(tempSelectedPath.path)
            }
          ]
          setSelected({selected_id: origSelectedID})
        } else {
          tempButtons = [
            {
              title: tempFirstPath.label,
              color: "#AC1F3E",
              onClick: () => navigate(tempFirstPath.path)
            },
            {
              title: tempSecondPath.label,
              color: "#1C2944",
              onClick: () => navigate(tempSecondPath.path)
            },
            {
              title: "",
              color: "#fff",
              onClick: null
            }
          ]
        }

        setButtons(tempButtons)
      }, onDataLoadError)
    } else {
      API.query('POST', `/api/pageviewhistory/`, {}, {}, function (resp) {
        var newHistObj = resp
        let data = {
          page_view_history: resp.id
        }
        API.query('PATCH', `/api/users/${user.id}/`, {}, data, function (resp) {
          Session.set('user', resp)
          let origSelectedID = newHistObj.selected_id
          let hist = newHistObj

          hist.pop('id')
          hist.pop('selected_id')

          let newHist = []
          for (var h in hist) {
            newHist.push([h, hist[h]])
          }

          newHist.sort(function (a, b) {
            return a[1] - b[1];
          });

          let first = newHist[newHist.length - 1]
          let firstID = getPathID(first[0])
          let tempFirstPath = paths.find((i) => i.id == firstID)
          setFirstPath(tempFirstPath)

          let second = newHist[newHist.length - 2]
          let secondID = getPathID(second[0])
          let tempSecondPath = paths.find((i) => i.id == secondID)
          setSecondPath(tempSecondPath)

          let tempButtons = []
          if (origSelectedID) {
            let tempSelectedPath = paths.find((i) => origSelectedID == i.id)
            tempButtons = [
              {
                title: tempFirstPath.label,
                color: "#AC1F3E",
                onClick: () => navigate(tempFirstPath.path)
              },
              {
                title: tempSecondPath.label,
                color: "#1C2944",
                onClick: () => navigate(tempSecondPath.path)
              },
              {
                title: tempSelectedPath.label,
                color: "#AC1F3E",
                onClick: () => navigate(tempSelectedPath.path)
              }
            ]
            setSelected({selected_id: origSelectedID})
          } else {
            tempButtons = [
              {
                title: tempFirstPath.label,
                color: "#AC1F3E",
                onClick: () => navigate(tempFirstPath.path)
              },
              {
                title: tempSecondPath.label,
                color: "#1C2944",
                onClick: () => navigate(tempSecondPath.path)
              },
              {
                title: "",
                color: "#fff",
                onClick: null
              }
            ]
          }

          setButtons(tempButtons)
        }, onDataLoadError)
      }, onDataLoadError)
    }    
  }, [])

  const onDataLoadError = (error) => {
    console.log(error)
  }

  const onClose = () => {
    setShowButtonModal(false)
  }

  return <div className="is-flex is-align-items-center mb-5">
    {
      buttons.map((button, i) => (
        <a
          key={i}
          onClick={button.onClick}
          className={"box mb-0 mr-5 p-0 is-flex is-align-items-center is-justify-content-space-between"}
          style={{
            width: 280,
            height: 60
          }}
        >
          <div className="ml-3" style={{
            width: 3,
            height: "100%",
            backgroundColor: button.color
          }} />
          <p className="mx-5 has-text-centered has-text-weight-bold">{button.title}</p>
          <FaPlus className="mr-3" size={40} color={button.color} />
        </a>
      ))
    }

    {/* <Dropdown className="navbar-item has-dropdown">
      <div className="navbar-link is-arrowless">
        <FaPlus size={40} color={'#1C2944'} />
      </div>
      <div className="navbar-dropdown is-right" style={{ width: "240px" }}>
        {
          paths.map(path => <a key={path.id} onClick={() => updateSelected(path)}>
            <div>
              <p className="is-size-5 has-text-weight-medium px-2">
                {path.label}
              </p>
            </div>
          </a>)
        }
      </div>
    </Dropdown> */}

    <a style={{paddingTop:"6px"}} onClick={() => setShowButtonModal(true)}><FaPlus size={41} color={'#1C2944'} /></a>

    {
      showChartingModal
      &&
      <ChartingModal
        onCancel={() => setShowChartingModal(false)}
      />
    }

    {
      showButtonModal
      &&
      <ButtonModal 
        editValues={selected}
        onEditChange={onEditChange}
        onSave={updateSelected}
        source={{"options": paths}}
        isSaving={isSaving}
        onClose={onClose}
      />
    }
  </div>
}

export default TopButtons;