import React, { useEffect, useMemo, useState } from "react";
import API from "../../services/API";

import newPatientForm from  "../forms/newPatient";
import newCaseFormA from  "../forms/newCaseA";
import newCaseFormB from  "../forms/newCaseB";
import newCaseFormC from  "../forms/newCaseC";

import patientSearchForm from  "../forms/patientSearch";
import { formatCaseData } from "../helpers/case";
import DynamicForm, { getInitialValuesForForm } from "./DynamicForm";
import DataTableMemo from "./DataTableMemo";
import PatientBloodForm from "./PatientBloodForm";

const NEW_OR_SEARCH_VIEW = 1
const NEW_PATIENT_VIEW = 2
const SEARCH_PATIENT_VIEW = 3

function CaseModal(props){
  const isUpdate = !!props.caseId
  const [view,setView] = useState(NEW_OR_SEARCH_VIEW)
  const [isLoading,setIsLoading] = useState(false)

  const [patientSearchData,setPatientSearchData] = useState()
  const [patientResults,setPatientResults] = useState(null)
  const [isLoadingPatients,setIsLoadingPatients] = useState(false)
  const [allMissingFields, setAllMissingFields] = useState([])

  const [caseData,setCaseData] = useState({
    ...getInitialValuesForForm(newCaseFormA),
    ...getInitialValuesForForm(newCaseFormB),
    ...getInitialValuesForForm(newCaseFormC),
    patient_blood_type: ""
  })
  const [patientData,setPatientData] = useState(getInitialValuesForForm(newPatientForm))

  const [newPatientHistories,setNewPatientHistories] = useState([])
  const [customPatientHistoryOptions,setCustomPatientHistoryOptions] = useState([])
  const [patientHistory,setPatientHistory] = useState([])
  const [showPatientHistory,setShowPatientHistory] = useState(false)
  const [newAllergies,setNewAllergies] = useState([])
  const [customAllergyOptions,setCustomAllergyOptions] = useState([])

  useEffect(() => {
    if(props.caseId){
      setView(NEW_PATIENT_VIEW)
      setIsLoading(true)
      API.query("GET",`api/cases/${props.caseId}/`,{},{},(resp) => {
        // split patient data into it's own data object
        if(resp.patient){
          setPatientData(resp.patient)
          loadPatientHistory(resp.patient.id,resp.id)
          delete resp.patient
        }
        let customAllergies = resp['patient_allergies'].filter(a=>a.is_global==false).map(a=>({label:a.name,value:a.id}))
        setCustomAllergyOptions(customAllergies)
        let customHistories = resp['patient_history'].filter(ph=>ph.is_global==false).map(ph=>({label:ph.name,value:ph.id}))
        setCustomPatientHistoryOptions(customHistories)

        // put the rest into caseData
        // need to format our select option for facility
        if(resp.facility) resp['facility_id'] = {"value":resp.facility.id,"label":resp.facility.name}
        setCaseData(resp)

        setIsLoading(false)
      }, () => {
        props.setErrorMessage("Could not load case")
        props.onClose()
      })
    }
  },[])

  const loadPatient = (patient) => {
    setView(NEW_PATIENT_VIEW)
    setPatientData(patient)
    loadPatientHistory(patient.id)
  }

  const loadPatientHistory = (patientId,currentCaseID=null) => {
    API.query("GET",`api/cases/`,{patient:patientId},{},(resp) => {
      if(currentCaseID !== null) resp.filter(c => c.id !== currentCaseID)
      //let customHistories = resp['patient_history'].filter(ph=>ph.is_global==false).map(ph=>({label:ph.name,value:ph.id}))
      //setCustomPatientHistoryOptions(customHistories)
    },() => {
      props.setErrorMessage("Could not load patient history")
    })
  }

  const onSave = () => {
    const apiData = formatCaseData(caseData)
    apiData.patient = patientData
    if(newAllergies.length) apiData.new_allergies = newAllergies.map(a=>a.value)
    if(newPatientHistories.length) apiData.new_patient_histories = newPatientHistories.map(ph=>ph.value)

    // validate by view
    if(view === NEW_OR_SEARCH_VIEW){
      props.setErrorMessage("You must create a new patient or select an existing one")
      return
    } else if(view === SEARCH_PATIENT_VIEW){
      props.setErrorMessage("You must select a patient")
      return
    }

    if(validateData()) {
      setIsLoading(true)
      if (props.caseId) {
        let params = {
          'mr': patientData.medical_record_num,
          'patientID': patientData.id
        }
        API.query('GET',`/api/uniquemr/`,params,{},function(response) {
          if(response.length == 0) {
            API.query("PATCH", `/api/cases/${apiData.id}/`, {}, apiData, (resp) => {
              props.onCaseUpdated(resp)
              props.onClose()
            }, props.setErrorMessage, () => setIsLoading(false))
          } else {
            props.setErrorMessage('You cannot change Medical Record #, it is a unique identifier.')
            setIsLoading(false)
          }
        }, () => props.setErrorMessage('Failed checking for unique unique medical record #.'), () => setIsLoading(false))
      } else {
        if(patientData.id == null) {
          let params = {
            'mr': patientData.medical_record_num
          }
          API.query('GET', `/api/uniquemr/`, params, {}, function (response) {
            if(response.length == 0) {
              API.query("POST", "/api/cases/", {}, apiData, (resp) => {
                props.onCaseCreated(resp)
              }, props.setErrorMessage, () => setIsLoading(false))
            } else {
              props.setErrorMessage('A patient already exists with this Medical Record #. Please double check if patient is already in the system.')
              setIsLoading(false)
            }
          }, () => props.setErrorMessage('Failed checking for unique medical record #.'), () => setIsLoading(false))
        } else {
          API.query("POST", "/api/cases/", {}, apiData, (resp) => {
            props.onCaseCreated(resp)
          }, props.setErrorMessage, () => setIsLoading(false))
        }
      }
    }
  }

  const doSearch = () => {
    // create params out of search values that are not null/empty
    const params = {}
    if(patientSearchData){
      Object.keys(patientSearchData).forEach(key => {
        const value = patientSearchData[key]
        if(value) params[key] = value
      })
    }

    // if no params, clear results and don't query
    if (Object.keys(params).length === 0) {
      setPatientResults()
      return
    }

    setIsLoadingPatients(true)
    API.query('GET','/api/patients/',params,{},(resp) => {
      setPatientResults(resp)
    }, props.setErrorMessage, () => setIsLoadingPatients(false))
  }

  const validateData = () => {
    let missingFields = []
    newCaseFormB.fields.forEach(f => {
      if(f.required && f.required == "true") {
        if(!(f.id in caseData) || caseData[f.id] == "" || caseData[f.id] == [] || caseData[f.id] == null) missingFields.push(f.id)
      }
    })
    newPatientForm.fields.forEach(f => {
      if(f.required && f.required == "true") {
        if(!(f.id in patientData) || patientData[f.id] == "" || patientData[f.id] == [] || patientData[f.id] == null) missingFields.push(f.id)
      }
    })
    newCaseFormA.fields.forEach(f => {
      if(f.required && f.required == "true") {
        if(!(f.id in caseData) || caseData[f.id] == "" || caseData[f.id] == [] || caseData[f.id] == null) missingFields.push(f.id)
      }
    })
    if (missingFields.length > 0) setAllMissingFields(missingFields)
    return missingFields.length == 0
  }

  const onCaseDataChange = (value) => {
    var cm, ft, inch, lb, oz, kg

    if(value.patient_height_ft != caseData.patient_height_ft || value.patient_height_in != caseData.patient_height_in) {
      cm = Math.round((value.patient_height_ft * 30.48) + (value.patient_height_in * 2.54))
    }else if(value.patient_height_cm != caseData.patient_height_cm) {
      let totInch = Math.round(value.patient_height_cm / 2.54)
      ft = Math.floor(totInch / 12)
      inch = Math.round(totInch % 12)
    }
    if(value.patient_weight_lb != caseData.patient_weight_lb || value.patient_weight_oz != caseData.patient_weight_oz) {
      kg = Math.round((value.patient_weight_lb * 0.453592) + (value.patient_weight_oz * 0.0283495))
    }else if(value.patient_weight_kg != caseData.patient_weight_kg) {
      let totOz = Math.round(value.patient_weight_kg * 35.274)
      lb = Math.floor(totOz / 16)
      oz = Math.round(totOz % 16)
    }

    if(cm) value['patient_height_cm'] = cm
    if(ft) value['patient_height_ft'] = ft
    if(inch || inch == 0) value['patient_height_in'] = inch
    if(lb) value['patient_weight_lb'] = lb
    if(oz || oz == 0) value['patient_weight_oz'] = oz
    if(kg) value['patient_weight_kg'] = kg
    setCaseData(value)
  }

  const onPatientHistoryChanged = (value) => {
    if(value.patient_allergy_ids && value.patient_allergy_ids.length) {
      let newAllergies = []
      value.patient_allergy_ids.forEach(a => {
        if(!Number.isInteger(a)) newAllergies.push({label:a,value:a})
      })
      setNewAllergies(newAllergies)
    }
    if(value.patient_history_ids && value.patient_history_ids.length) {
      let newHistories = []
      value.patient_history_ids.forEach(ph => {
        if(!Number.isInteger(ph)) newHistories.push({label:ph,value:ph})
      })
      setNewPatientHistories(newHistories)
    }
    setCaseData(value)
  }

  const columns = useMemo(() => [
    {name:'First Name',selector: row => row.first_name},
    {name:'Last Name',selector: row => row.last_name},
    {name:'Medical Record No.',selector: row => row.medical_record_num},
    {name:'Account No.',selector: row => row.account_num},
    {name:'New Case?',selector: row => <button onClick={() => loadPatient(row)} className="button is-success is-small">Create New Case</button>}
  ])

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card modal-large">
      <header className="modal-card-head has-background-white">
        <p className="is-size-4">
          {isUpdate ? "Patient Info" : "New Case Setup - Patient Info"}
        </p>
      </header>

      <section className="modal-card-body">
        <p className="title is-5">Case Info</p>
        <DynamicForm 
          form={newCaseFormA}
          values={caseData}
          onValuesChanged={setCaseData}
          loaders={props.loaders}
          missingFields={allMissingFields}
        />

        <hr/>

        {
          view === NEW_OR_SEARCH_VIEW
          &&
          <>
            <p className="title is-5">New or Existing Patient</p>
            <div className="columns">
              <div className="column">
                <button className="button is-success is-fullwidth" onClick={() => setView(NEW_PATIENT_VIEW)}>Create New Patient</button>
              </div>
              <div className="column">
                <button className="button is-info is-fullwidth" onClick={() => setView(SEARCH_PATIENT_VIEW)}>Search For Existing Patient</button>
              </div>
            </div>
          </>
        }

        {
          view === SEARCH_PATIENT_VIEW
          &&
          <>
            <p className="title is-5">Search for Existing Patient</p>
            <DynamicForm 
              form={patientSearchForm}
              values={patientSearchData}
              onValuesChanged={setPatientSearchData}
              onButtonPressed={doSearch}
              isLoading={isLoadingPatients}
            />

            {
              patientResults !== null
              &&
              <>
                <p className="title is-6">Search Results</p>
                <DataTableMemo
                  striped
                  pagination
                  selectableRowSelected={(row) => row.id === props.activeCaseId}
                  columns={columns}
                  data={patientResults}
                  disabled={isLoadingPatients}
                />
              </>
            }
          </>
        }

        {
          view === NEW_PATIENT_VIEW
          &&
          <>
            <p className="title is-5">Patient Info</p>
            <DynamicForm 
              form={newPatientForm}
              values={patientData}
              onValuesChanged={setPatientData}
              missingFields={allMissingFields}
            />
            <DynamicForm 
              form={newCaseFormB}
              values={caseData}
              onValuesChanged={onCaseDataChange}
              missingFields={allMissingFields}
            />
            <PatientBloodForm
              caseData={caseData}
              setCaseData={setCaseData}
              patientData={patientData}
            />
            <DynamicForm 
              form={newCaseFormC}
              values={caseData}
              onValuesChanged={onPatientHistoryChanged}
              source={{
                "allergies": props.allergyOptions.concat(newAllergies,customAllergyOptions),
                "history": props.historyOptions.concat(newPatientHistories,customPatientHistoryOptions)
              }}
            />

            {
              patientHistory.length > 0
              &&
              (
                showPatientHistory
                ?
                <div>
                  <hr />
                  <p className="title is-5">Patient History</p>
                  <table className="table is-narrow is-fullwidth">
                    <thead>
                      <th>Date</th>
                      <th>Location</th>
                      <th>Personnel</th>
                      <th>Procedures</th>
                    </thead>
                    <tbody>
                      {
                        patientHistory.map(c => <tr className="has-background-white-ter">
                          <td>{c.date}</td>
                          <td>{c.facility_name}</td>
                          <td>{c.personnel_names}</td>
                          <td>{c.procedure.map(p => p.name).join(', ')}</td>
                        </tr>)
                      }
                    </tbody>
                  </table>
                </div>
                :
                <button className="button is-info is-fullwidth mt-3" onClick={() => setShowPatientHistory(true)}>View Patient History</button>
              )
            }
          </>
        }
      </section>

      <footer className="modal-card-foot has-background-white">
        <div className="level w-100">
          <div className="level-left">
            <button className="level-item button is-danger is-inverted" onClick={props.onClose}>Cancel</button>
          </div>
          <div className="level-right">
            <button className={"level-item button is-success"+(isLoading?" is-loading ":"")} onClick={() => onSave()}>Save</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
}

export default CaseModal