import React, { useEffect, useState, useMemo } from "react";
import API from "../../../../services/API";
import Session from "../../../../services/Session";
import DataTable from "react-data-table-component";
import PdfHemFacility from "../../../components/PdfHemFacility";
import MasterCSV from "../../../components/HemMasterCSV"
import PdfHemDashboard from "../../../components/PdfHemDashboard"
import { FaFileExcel, FaRegFilePdf } from 'react-icons/fa';
import HemProcedureBilling from "../../../components/HemProcedureBilling";

function HemReport(props) {
    const [facilityOptions, setFacilityOptions] = useState([])
    const [showDashboardReport, setShowDashboardReport] = useState(false)
    const [showFacilityReport, setShowFacilityReport] = useState(false)
    const [showMasterCSV, setShowMasterCSV] = useState(false)
    const [personnelOptions, setPersonnelOptions] = useState([])
    const [showProcedureBilling, setShowProcedureBilling] = useState(false)

    const reports = [
        {
            id: 1,
            name: "Master CSV Spreadsheet",
            description: "Master CSV Spreadsheet",
            csv: true,
            pdf: false
        },
        {
            id: 2,
            name: "Facility Dashboard Report",
            description: "Facility Dashboard Report",
            csv: false,
            pdf: false
        },
        {
            id: 3,
            name: "Facility Report",
            description: "Facility Report",
            csv: false,
            pdf: true
        },
        {
            id: 4,
            name: "Procedure Billing Report",
            description: "Procedure Billing Report",
            csv: true,
            pdf: false
        }
    ]

    const onDataLoadError = (error) => {
        console.log(error)
    }

    const onViewClicked = (row) => {
        if (row.id == 1) {
            setShowMasterCSV(true)
        } else if (row.id == 2) {
            setShowDashboardReport(true)
        } else if (row.id == 3) {
            setShowFacilityReport(true)
        } else if (row.id == 4) {
            setShowProcedureBilling(true)
        }
    }

    const columns = useMemo(() => [
        { name: 'Name of Report', selector: row => row.name, sortable: true },
        { name: 'Description', selector: row => row.description, sortable: true },
        {
            name: 'Actions', selector: row => <div>
                {
                    row.csv
                    &&
                    <a className="ml-3" title="CSV Download" onClick={() => onViewClicked(row)}><FaFileExcel size={18} title="CSV Download" /></a>
                }
                {
                    row.pdf
                    &&
                    <a className="ml-3" title="PDF Download" onClick={() => onViewClicked(row)}><FaRegFilePdf size={18} /></a>
                }
                {/* <a className="ml-3" title="Send" onClick={() => onShareClicked(row)}><FaEnvelope size={18} /></a> */}
            </div>
        }
    ])

    useEffect(() => {
        let queries = [
            { 'method': 'GET', 'path': '/api/facilities/', },
            { 'method': 'GET', 'path': '/api/personnellist/', },
            { 'method': 'GET', 'path': '/api/userlist/', }
        ]
        API.all(queries, function (resp) {
            if (props.permissions.includes(17) || props.extraPermissions.includes(17)) {
                let user = Session.get('user')
                let filteredFacils = resp[0].filter((obj) => {
                    var i, abort = false, valid = false
                    for (i = 0; i < user.facilities.length && !abort; i++) {
                        if (user.facilities[i] == obj.id) {
                            valid = true
                            abort = true
                        }
                    }
                    return valid
                })

                setFacilityOptions(filteredFacils.map(i => ({ "label": i.name, "value": i.id })))
            } else {
                setFacilityOptions(resp[0].map(i => ({ "label": i.name, "value": i.id })))
            }
            setPersonnelOptions([...resp[1].map(i => ({"value": `personnel-${i.id}`, "label": i.user_full_name})), 
                ...resp[2].map(i => ({"value": `user-${i.id}`, "label": i.user_full_name}))
            ])
        }, onDataLoadError)
    }, []);

    return <div>
        <p className="title is-3">Hemasource Reports</p>

        <div className="box">
            <DataTable
                striped
                data={reports}
                columns={columns}
            />
        </div>

        {
            showDashboardReport
            &&
            <PdfHemDashboard
                facilities={facilityOptions}
                closeModal={() => setShowDashboardReport(false)}
            />
        }

        {
            showFacilityReport
            &&
            <PdfHemFacility
                facilities={facilityOptions}
                onClose={() => setShowFacilityReport(false)}
            />
        }

        {
            showMasterCSV
            &&
            <MasterCSV
                facilities={facilityOptions}
                personnel={personnelOptions}
                onClose={() => setShowMasterCSV(false)}
            />
        }

        {
            showProcedureBilling
            &&
            <HemProcedureBilling
                facilities={facilityOptions}
                onClose={() => setShowProcedureBilling(false)}
            />
        }

        {/* <VisitTimer visited={visited}/> */}
    </div>
}

export default HemReport