export const incrementTimer = (time) => {
  const timeSplit = time.split(":")
  let hours = parseInt(timeSplit[0])
  let minutes = parseInt(timeSplit[1])
  let seconds = parseInt(timeSplit[2])

  // Increment time
  seconds++
  if (seconds === 60) {
    minutes += 1
    seconds = 0
  }
  if (minutes === 60) {
    hours += 1
    minutes = 0
  }

  // Add 0 padding
  if(seconds < 10) seconds = "0"+seconds
  if(minutes < 10) minutes = "0"+minutes
  if(hours < 10) hours = "0"+hours

  return hours+":"+minutes+":"+seconds
}

export const getTimeDifference = (startTime,endTime) => {
  const startSplit = startTime.split(":")
  let startHours = parseInt(startSplit[0])
  let startMinutes = parseInt(startSplit[1])
  let startSeconds = parseInt(startSplit[2])
  const endSplit = endTime.split(":")
  let endHours = parseInt(endSplit[0])
  let endMinutes = parseInt(endSplit[1])
  let endSeconds = parseInt(endSplit[2])

  let dh = endHours - startHours
  let dm = endMinutes - startMinutes
  if(dm < 0) {
    dh -= 1
    dm += 60
  }
  let ds = endSeconds - startSeconds
  if(ds < 0) {
    dm -= 1
    ds += 60
  }

  // Add 0 padding
  if(ds < 10) ds = "0"+ds
  if(dm < 10) dm = "0"+dm
  if(dh < 10) dh = "0"+dh

  return dh+":"+dm+":"+ds
}

export const getTimeInSeconds = (time) => {
  const timeSplit = time.split(":")
  let hours = parseInt(timeSplit[0])
  let minutes = parseInt(timeSplit[1])
  let seconds = parseInt(timeSplit[2])

  return hours*3600+minutes*60+seconds
}

export const getFormattedTime = (totalSeconds) => {
  totalSeconds = Math.round(totalSeconds)
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;

  // Add 0 padding
  if(seconds < 10) seconds = "0"+seconds
  if(minutes < 10) minutes = "0"+minutes
  if(hours < 10) hours = "0"+hours

  return hours+":"+minutes+":"+seconds
}