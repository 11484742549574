import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
const CancelToken = axios.CancelToken;
import { useNavigate } from "react-router-dom";
import Session from "./Session";
import { calculateBSA, calculateBMI } from "../src/helpers/patient";
import moment from "moment";

const logout = () => {
  const navigate = useNavigate();
  navigate("/logout");
};

const getObjectValueByString = (o, p) => {
  const keys = p.split('.');
  let obj = o;

  for (const key of keys) {
    if (obj[key] === undefined || obj[key] === null) {
      return undefined;
    }
    obj = obj[key];
  }

  return obj;
}

const PROCEDURES = [
  {
    id: 1,
    value: "CPB",
  },
  {
    id: 2,
    value: "Autotransfusion",
  },
  {
    id: 3,
    value: "HIPEC",
  },
  {
    id: 4,
    value: "ECLS",
  },
  {
    id: 5,
    value: "Platelet Therapy",
  },
  {
    id: 6,
    value: "POC Lab Service",
  },
  {
    id: 7,
    value: "Perfusion Standby",
  },
  {
    id: 8,
    value: "Hem",
  },
  {
    id: 9,
    value: "Transport",
  },
];

const getProcedureSortRank = (procedure) => {
  if (procedure.includes("CPB")) {
    return 0;
  } else if (procedure === "Autotransfusion") {
    return 1;
  } else if (procedure === "Platelet Therapy") {
    return 2;
  } else if (procedure === "Perfusion Standby") {
    return 3;
  } else {
    return 4;
  }
};

class API {
  static all(queries, successCallback, errorCallback, alwaysCallback) {
    let axiosQueries = [];
    queries.forEach((query) => {
      axiosQueries.push(
        axios({
          method: query["method"],
          url: this.formatPath(query["path"], query["params"]),
          data: query["data"],
          headers: this.getHeaders(),
        })
      );
    });

    axios
      .all(axiosQueries)
      .then((results) => {
        if (successCallback) {
          let resp = results.map((result) => result.data);
          successCallback(resp);
        }
        if (alwaysCallback) alwaysCallback();
      })
      .catch((error) => {
        // Unauthorized
        console.log(error);
        if (error.response.status == 401 && Session) {
          Session.logout();
          logout();
          return;
        }

        let message = this.formatError(error);
        if (errorCallback) errorCallback(message);
        if (alwaysCallback) alwaysCallback();
      });
  }

  static query(
    method,
    path,
    params,
    data,
    successCallback,
    errorCallback,
    alwaysCallback
  ) {
    let cancelRequest;
    axios({
      method: method,
      url: this.formatPath(path, params),
      data: data,
      cancelToken: new CancelToken(function executor(c) {
        cancelRequest = c;
      }),
      headers: path.includes("api/usertoken") ? {} : this.getHeaders(),
    })
      .then((resp) => {
        if (successCallback) successCallback(resp.data);
        if (alwaysCallback) alwaysCallback();
      })
      .catch((error) => {
        console.log(error);

        // Unauthorized
        if (error.response.status == 401 && Session) {
          Session.logout();
          logout();
          return;
        }
        let message = this.formatError(error);
        if (errorCallback) errorCallback(message);
        if (alwaysCallback) alwaysCallback();
      });
    return cancelRequest;
  }

  static getReportBundleData(params, successCallback, errorCallback) {
    let cancelRequest;
    axios({
      method: "GET",
      url: this.formatPath(`/api/qcbundlereport/`, params),
      cancelToken: new CancelToken(function executor(c) {
        cancelRequest = c;
      }),
      headers: this.getHeaders(),
    })
      .then((resp) => {
        if (successCallback) {
          const data = resp.data;
          const res = {
            procedure_data: null,
            profile_data: null,
            cpb_analytic_data: null,
            auto_analytic_data: null,
            standby_analytic_data: null,
            blood_usage_summary: null,
            blood_detail_data: null,
            quality_improvement_data: null,
            non_compliance_data: null,
            study_data: null,
            disposable_data: null,
          };

          //#region Procedure
          const procedure_data = [];
          for (let i = 0; i < data.length; i++) {
            let surgeon = data[i].personnel_roles.find((e) => {
              let valid = false;
              if (e?.job?.id === 15) {
                valid = true;
              }
              return valid;
            });

            let procedureString = "";
            for (let x = 0; x < data[i].procedure.length; x++) {
              if (x == 0) {
                procedureString = data[i].procedure[x].name;
              } else {
                procedureString =
                  procedureString + ", " + data[i].procedure[x].name;
              }
            }

            const operationArray = []
            const arrayToCheck = [
              {
                conditional:"cpb_detail.bypass_count",
                value: "cpb_detail.bypass_count",
                prepend:"CABG x",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.valve.aortic_status",
                value: "cpb_detail.valve.aortic_info",
                prepend:"Aortic Valve ",
                useConditionalValue: true,
              },
              {
                conditional:"cpb_detail.valve.mitral_status",
                value: "cpb_detail.valve.mitral_info",
                prepend:"Mitral Valve ",
                useConditionalValue: true,
              },
              {
                conditional:"cpb_detail.valve.tricuspid_status",
                value: "cpb_detail.valve.tricuspid_info",
                prepend:"Tricuspid Valve ",
                useConditionalValue: true,
              },
              {
                conditional:"cpb_detail.valve.pulmonic_status",
                value: "cpb_detail.valve.pulmonic_info",
                prepend:"Pulmonic Valve ",
                useConditionalValue: true,
              },
              {
                conditional:"cpb_detail.aortic.aortic_info",
                value: "cpb_detail.aortic.aortic_info",
                prepend:"Aortic Surgery - Aortic -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.aortic.hematoma_info",
                value: "cpb_detail.aortic.hematoma_info",
                prepend:"Aortic Surgery - Hematoma -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.aortic.trauma_info",
                value: "cpb_detail.aortic.trauma_info",
                prepend:"Aortic Surgery - Trauma -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.aortic.coarctation_info",
                value: "cpb_detail.aortic.coarctation_info",
                prepend:"Aortic Surgery - Coarctation -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.aortic.other_info",
                value: "cpb_detail.aortic.other_info",
                prepend:"Aortic Surgery - Other -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.congenital.ductus_info",
                value: "cpb_detail.congenital.ductus_info",
                prepend:"Congenital Surgery - Ductus Arteriosus Ligation -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.congenital.norwood_info",
                value: "cpb_detail.congenital.norwood_info",
                prepend:"Congenital Surgery - Norwood Procedure -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.congenital.fontan_info",
                value: "cpb_detail.congenital.fontan_info",
                prepend:"Congenital Surgery - Fontan Procedure -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.congenital.bialock_info",
                value: "cpb_detail.congenital.bialock_info",
                prepend:"Congenital Surgery - Bialock-Taussig Shunt -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.congenital.vsd_info",
                value: "cpb_detail.congenital.vsd_info",
                prepend:"Congenital Surgery - VSD Closure -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.congenital.asd_info",
                value: "cpb_detail.congenital.asd_info",
                prepend:"Congenital Surgery - ASD Closure -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.congenital.tof_info",
                value: "cpb_detail.congenital.tof_info",
                prepend:"Congenital Surgery - TOF Repair -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.congenital.aorta_info",
                value: "cpb_detail.congenital.aorta_info",
                prepend:"Congenital Surgery - Aorta Coarctation Repair -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.congenital.truncus_info",
                value: "cpb_detail.congenital.truncus_info",
                prepend:"Congenital Surgery - Truncus Arteriosus Repair -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.congenital.tricuspid_info",
                value: "cpb_detail.congenital.tricuspid_info",
                prepend:"Congenital Surgery - Tricuspid Atresia Correction -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.congenital.pulmonary_info",
                value: "cpb_detail.congenital.pulmonary_info",
                prepend:"Congenital Surgery - Pulmonary Atresia Correction -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.congenital.anamolous_info",
                value: "cpb_detail.congenital.anamolous_info",
                prepend:"Congenital Surgery - Anamolous Pulmonary Venous Return Correction -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.congenital.vessel_info",
                value: "cpb_detail.congenital.vessel_info",
                prepend:"Congenital Surgery - Vessel Transposition Correction -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.congenital.other_info",
                value: "cpb_detail.congenital.other_info",
                prepend:"Congenital Surgery - Other -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.circulatory_device.lvad_status",
                value: "cpb_detail.circulatory_device.lvad_info",
                prepend:"CAD LVAD ",
                useConditionalValue: true,
              },
              {
                conditional:"cpb_detail.circulatory_device.rvad_status",
                value: "cpb_detail.circulatory_device.rvad_info",
                prepend:"CAD RVAD ",
                useConditionalValue: true,
              },
              {
                conditional:"cpb_detail.circulatory_device.tah_status",
                value: "cpb_detail.circulatory_device.tah_info",
                prepend:"CAD TAH ",
                useConditionalValue: true,
              },
              {
                conditional:"cpb_detail.circulatory_device.other_status",
                value: "cpb_detail.circulatory_device.other_info",
                prepend:"CAD Other ",
                useConditionalValue: true,
              },
              {
                conditional:"cpb_detail.transplant.heart_time",
                value: "cpb_detail.transplant.heart_time",
                prepend:"Heart Transplant -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.transplant.right_time",
                value: "cpb_detail.transplant.right_time",
                prepend:"Right Lung Transplant -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.transplant.left_time",
                value: "cpb_detail.transplant.left_time",
                prepend:"Left Lung Transplant -",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.other.lead",
                value: "DNE",
                prepend: "Lead Insertion/Extraction",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.other.artrial",
                value: "DNE",
                prepend: "Artrial Fibrillation Surgery",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.other.asd",
                value: "DNE",
                prepend: "ASD Closure (Non-Congenital)",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.other.vsd",
                value: "DNE",
                prepend: "VSD Closure (Non-Congenital)",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.other.lva",
                value: "DNE",
                prepend: "LVA Repair",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.other.tmr",
                value: "DNE",
                prepend: "TMR Laser",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.other.tumor",
                value: "DNE",
                prepend: "Tumor Excision",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.other.pulmonary",
                value: "DNE",
                prepend: "Pulmonary Embolectomy",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.other.cardiac",
                value: "DNE",
                prepend: "Cardiac/Vascular Trauma",
                useConditionalValue: false,
              },
              {
                conditional:"cpb_detail.other.other",
                value: "cpb_detail.other.other",
                prepend: "Other - ",
                useConditionalValue: false,
              },
              {
                conditional: "auto_detail.cardiac_checked",
                value: "auto_detail.cardiac_note",
                prepend: "Cardiac",
                useConditionalValue: false,
              },
              {
                conditional: "auto_detail.vascular_checked",
                value: "auto_detail.vascular_note",
                prepend: "Vascular",
                useConditionalValue: false,
              },
              {
                conditional: "auto_detail.ortho_checked",
                value: "auto_detail.ortho_note",
                prepend: "Orthopedic",
                useConditionalValue: false,
              },
              {
                conditional: "auto_detail.hepatic_checked",
                value: "auto_detail.hepatic_note",
                prepend: "Hepatic",
                useConditionalValue: false,
              },
              {
                conditional: "auto_detail.neuro_checked",
                value: "auto_detail.neuro_note",
                prepend: "Neuro",
                useConditionalValue: false,
              },
              {
                conditional: "auto_detail.trauma_checked",
                value: "auto_detail.trauma_note",
                prepend: "Trauma",
                useConditionalValue: false,
              },
              {
                conditional: "auto_detail.other_checked",
                value: "auto_detail.other_note",
                prepend: "Other",
                useConditionalValue: false,
              },
              {
                conditional: "platelet_detail.cardiac_checked",
                value: "platelet_detail.cardiac_info",
                prepend: "Cardiac",
                useConditionalValue: false,
              },
              {
                conditional: "platelet_detail.vascular_checked",
                value: "platelet_detail.vascular_info",
                prepend: "Vascular",
                useConditionalValue: false,
              },
              {
                conditional: "platelet_detail.ortho_checked",
                value: "platelet_detail.ortho_info",
                prepend: "Orthopedic",
                useConditionalValue: false,
              },
              {
                conditional: "platelet_detail.neuro_checked",
                value: "platelet_detail.neuro_info",
                prepend: "Neuro",
                useConditionalValue: false,
              },
              {
                conditional: "platelet_detail.wound_checked",
                value: "platelet_detail.wound_info",
                prepend: "Wound Clinic",
                useConditionalValue: false,
              },
              {
                conditional: "platelet_detail.other_checked",
                value: "platelet_detail.other_info",
                prepend: "Other",
                useConditionalValue: false,
              },
              {
                conditional:"perfusion_detail.bypass_count",
                value: "perfusion_detail.bypass_count",
                prepend:"CABG x",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.pericardial_window",
                value: "DNE",
                prepend: "Cardiac (Other) - Pericardial Window",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.re_exploration",
                value: "DNE",
                prepend: "Cardiac (Other) - Re-Exploration",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.lead_insertion",
                value: "DNE",
                prepend: "Cardiac (Other) - Lead Insertion/Extraction",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.other_trauma",
                value: "DNE",
                prepend: "Cardiac (Other) - Trauma",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.atrial",
                value: "DNE",
                prepend: "Cardiac (Other) - Atrial Fibrillation Surgery",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.tavr",
                value: "DNE",
                prepend: "Cardiac (Other) - TAVR",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.tmvr",
                value: "DNE",
                prepend: "Cardiac (Other) - TMVR",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.mitral_clip",
                value: "DNE",
                prepend: "Cardiac (Other) - Mitral Clip",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.vad_insertion",
                value: "DNE",
                prepend: "Cardiac (Other) - VAD Insertion",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.vad_removal",
                value: "DNE",
                prepend: "Cardiac (Other) - VAD Removal",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.ecmo_insertion",
                value: "DNE",
                prepend: "Cardiac (Other) - ECMO Insertion",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.ecmo_removal",
                value: "DNE",
                prepend: "Cardiac (Other) - ECMO Removal",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.cardiac_other",
                value: "perfusion_detail.cardiac_other_type",
                prepend: "Cardiac (Other) - Other",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.transplant",
                value: "DNE",
                prepend: "Pulmonary - Transplant",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.embolectomy",
                value: "DNE",
                prepend: "Pulmonary - Embolectomy",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.pulmonary_other",
                value: "perfusion_detail.pulmonary_other_type",
                prepend: "Pulmonary - Other",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.vascular_checked",
                value: "perfusion_detail.vascular_note",
                prepend: "Vascular",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.cath_checked",
                value: "perfusion_detail.cath_note",
                prepend: "Cath Lab",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.hepatic_checked",
                value: "perfusion_detail.hepatic_note",
                prepend: "Hepatic",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.auto_checked",
                value: "perfusion_detail.auto_note",
                prepend: "Autotransfusion Standby",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.ecmo_checked",
                value: "perfusion_detail.ecmo_note",
                prepend: "ECMO",
                useConditionalValue: false,
              },
              {
                conditional: "perfusion_detail.other_checked",
                value: "perfusion_detail.other_note",
                prepend: "Other",
                useConditionalValue: false,
              },
            ];
            arrayToCheck.forEach(key => {
              const conditional = getObjectValueByString(data[i], key.conditional);
              if(conditional) {
                if(key.useConditionalValue) {
                  const val = getObjectValueByString(data[i],key.value);
                  if(val) {
                    const entry = `${key.prepend}${conditional} (${val})`;
                    operationArray.push(entry);
                  } else {
                    operationArray.push(`${key.prepend}${conditional}`);
                  }
                } else {
                  const val = getObjectValueByString(data[i],key.value);
                  if(val) {
                    const entry = `${key.prepend??""} ${val}`;
                    operationArray.push(entry);
                  } else {
                    operationArray.push(key.prepend);
                  }
                }
              }
            });
            const operation_detail_string = operationArray.join(" / ");
            
            const new_obj = {
              proc_type: procedureString,
              date: data[i].date,
              medical_record_num: data[i].medical_record_num,
              patient_name: data[i].patient_name,
              surgeon_name: surgeon?.user_full_name ?? "",
              user_name: data[i].user.user_full_name,
              detail_string: operation_detail_string ?? "",
            };
            procedure_data.push(new_obj);
          }
          res.procedure_data = procedure_data.sort((a, b) => {
            const aRank = getProcedureSortRank(a.proc_type);
            const bRank = getProcedureSortRank(b.proc_type);
            if (aRank === bRank) {
              return new Date(a.date) - new Date(b.date);
            } else {
              return aRank - bRank;
            }
          });

          //#region Patient Profile
          const temp_profile_data = [];
          data.map((e) => {
            const surgeon = e.personnel_roles.find((x) => x.job?.id === 15);
            if (surgeon) {
              const existingIndex = temp_profile_data.findIndex(
                (x) => x.name === surgeon.user_full_name
              );

              const startDate = moment(new Date(e.patient_dob));
              const endDate = moment(new Date());
              const duration = moment.duration(endDate.diff(startDate));
              const years = duration.years();
              const months = duration.months();
              const days = duration.days();
              if (existingIndex > -1) {
                const existing = temp_profile_data[existingIndex];
                const gender_pieces = existing.gender_count.split("/");

                const new_obj = {
                  name: existing.name,
                  patients: existing.patients + 1,
                  gender_count:
                    e.patient_gender === "male"
                      ? `${parseInt(gender_pieces[0]) + 1}/${gender_pieces[1]}`
                      : `${gender_pieces[0]}/${parseInt(gender_pieces[1]) + 1}`,
                  cpb_count: e.procedure.find((z) => z.id === 1)
                    ? existing.cpb_count + 1
                    : existing.cpb_count,
                  auto_count: e.procedure.find((z) => z.id === 2)
                    ? existing.auto_count + 1
                    : existing.auto_count,
                  platelet_count: e.procedure.find((z) => z.id === 5)
                    ? existing.platelet_count + 1
                    : existing.platelet_count,
                  standby_count: e.procedure.find((z) => z.id === 7)
                    ? existing.standby_count + 1
                    : existing.standby_count,
                  other_count: e.procedure.find((z) =>
                    [3, 4, 6, 9].includes(z.id)
                  )
                    ? existing.other_count + 1
                    : existing.other_count,
                  age: [
                    ...existing.age,
                    {
                      years: years,
                      months: months,
                      days: days,
                    },
                  ],
                  height: e.patient_height_cm
                    ? [...existing.height, e.patient_height_cm]
                    : existing.height,
                  weight: e.patient_weight_kg
                    ? [...existing.weight, e.patient_weight_kg]
                    : existing.weight,
                  bsa: [...existing.bsa, calculateBSA(e)],
                  bmi: [...existing.bmi, calculateBMI(e)],
                };

                temp_profile_data[existingIndex] = new_obj;
              } else {
                const new_obj = {
                  name: surgeon.user_full_name,
                  patients: 1,
                  gender_count: e.patient_gender === "male" ? `1/0` : `0/1`,
                  cpb_count: e.procedure.find((z) => z.id === 1) ? 1 : 0,
                  auto_count: e.procedure.find((z) => z.id === 2) ? 1 : 0,
                  platelet_count: e.procedure.find((z) => z.id === 5) ? 1 : 0,
                  standby_count: e.procedure.find((z) => z.id === 7) ? 1 : 0,
                  other_count: e.procedure.find((z) =>
                    [3, 4, 6, 9].includes(z.id)
                  )
                    ? 1
                    : 0,
                  age: [
                    {
                      years: years,
                      months: months,
                      days: days,
                    },
                  ],
                  height: e.patient_height_cm ? [e.patient_height_cm] : [],
                  weight: e.patient_weight_kg ? [e.patient_weight_kg] : [],
                  bsa: [calculateBSA(e)],
                  bmi: [calculateBMI(e)],
                };
                temp_profile_data.push(new_obj);
              }
            }
          });
          const profile_data = [];
          temp_profile_data.map((e) => {
            let years = 0,
              months = 0,
              days = 0;
            e.age.map((x) => {
              years = years + x.years;
              months = months + x.months;
              days = days + x.days;
            });
            const avg_years = Math.floor(years / e.age.length);
            const avg_months = Math.floor(months / e.age.length);
            const avg_days = Math.floor(days / e.age.length);

            let height = 0;
            e.height.map((x) => {
              height = height + x;
            });
            const avg_height = (height / e.height.length).toFixed(2);

            let weight = 0;
            e.weight.map((x) => {
              weight = weight + x;
            });
            const avg_weight = (weight / e.weight.length).toFixed(2);

            let bsa = 0;
            e.bsa.map((x) => {
              bsa = bsa + x;
            });
            const avg_bsa = (bsa / e.bsa.length).toFixed(2);

            let bmi = 0;
            e.bmi.map((x) => {
              bmi = bmi + x;
            });
            const avg_bmi = (bmi / e.bmi.length).toFixed(2);

            const new_obj = {
              ...e,
              age: `${avg_years}yr, ${avg_months}mo, ${avg_days}d`,
              height: avg_height,
              weight: avg_weight,
              bsa: avg_bsa,
              bmi: avg_bmi,
            };
            profile_data.push(new_obj);
          });
          res.profile_data = profile_data;

          // #region CPB Analytics
          const temp_cpb_analytic = [];
          data.map((e) => {
            const cpb = e.procedure.find((e) => e.id === 1);
            if (cpb) {
              const surgeon = e.personnel_roles.find((x) => x.job?.id === 15);
              if (surgeon) {
                const existingIndex = temp_cpb_analytic.findIndex(
                  (x) => x.name === surgeon.user_full_name
                );
                if (existingIndex > -1) {
                  const existing = temp_cpb_analytic[existingIndex];
                  const start_date = e.cpb_detail?.top?.start_date;
                  const start_time = e.cpb_detail?.top?.start_time;

                  const end_date = e.cpb_detail?.top?.end_date;
                  const end_time = e.cpb_detail?.top?.end_time;

                  let minutes;
                  if (start_date && start_time && end_date && end_time) {
                    const new_start = new Date(`${start_date}, ${start_time}`);
                    const new_end = new Date(`${end_date}, ${end_time}`);

                    const diff = new_end - new_start;
                    minutes = Math.floor(diff / (1000 * 60));
                  }

                  const new_obj = {
                    name: existing.name,
                    patients: existing.patients + 1,
                    surgery_times: [...existing.surgery_times, minutes ?? 0],
                    clamp_times: [
                      ...existing.clamp_times,
                      e.cpb_detail?.middle?.clamp_time ?? null,
                    ],
                    pump_times: [
                      ...existing.pump_times,
                      e.cpb_detail?.middle?.cpb_time ?? null,
                    ],
                    circ_times: [
                      ...existing.circ_times,
                      e.cpb_detail?.circulatory_arrest?.arrest_time ?? null,
                    ],
                    cabg_count: [
                      ...existing.cabg_count,
                      e.cpb_detail?.bypass_count ?? null,
                    ],
                    pre_hct: [
                      ...existing.pre_hct,
                      e.cpb_detail?.middle?.pre_hct ?? null,
                    ],
                    post_hct: [
                      ...existing.post_hct,
                      e.cpb_detail?.middle?.post_hct ?? null,
                    ],
                    low_hct: [
                      ...existing.low_hct,
                      e.cpb_detail?.middle?.lowest_hct ?? null,
                    ],
                    low_temp: [
                      ...existing.low_temp,
                      e.cpb_detail?.middle?.lowest_cpb ?? null,
                    ],
                  };

                  temp_cpb_analytic[existingIndex] = new_obj;
                } else {
                  const start_date = e.cpb_detail?.top?.start_date;
                  const start_time = e.cpb_detail?.top?.start_time;

                  const end_date = e.cpb_detail?.top?.end_date;
                  const end_time = e.cpb_detail?.top?.end_time;

                  let minutes;
                  if (start_date && start_time && end_date && end_time) {
                    const new_start = new Date(`${start_date}, ${start_time}`);
                    const new_end = new Date(`${end_date}, ${end_time}`);

                    const diff = new_end - new_start;
                    minutes = Math.floor(diff / (1000 * 60));
                  }

                  const new_obj = {
                    name: surgeon.user_full_name,
                    patients: 1,
                    surgery_times: [minutes ?? 0],
                    clamp_times: [e.cpb_detail?.middle?.clamp_time ?? null],
                    pump_times: [e.cpb_detail?.middle?.cpb_time ?? null],
                    circ_times: [
                      e.cpb_detail?.circulatory_arrest?.arrest_time ?? null,
                    ],
                    cabg_count: [e.cpb_detail?.bypass_count ?? 0],
                    pre_hct: [e.cpb_detail?.middle?.pre_hct ?? null],
                    post_hct: [e.cpb_detail?.middle?.post_hct ?? null],
                    low_hct: [e.cpb_detail?.middle?.lowest_hct ?? null],
                    low_temp: [e.cpb_detail?.middle?.lowest_cpb ?? null],
                  };
                  temp_cpb_analytic.push(new_obj);
                }
              }
            }
          });
          const cpb_analytic_data = temp_cpb_analytic.map((e) => {
            let surgery_len = e.surgery_times.length;
            let total_surgery_minutes = 0;
            e.surgery_times.map((x) => {
              if (x || x === 0) {
                total_surgery_minutes = total_surgery_minutes + x;
              } else {
                surgery_len = surgery_len - 1;
              }
            });
            const surgery_minutes = total_surgery_minutes % 60;
            const surgery_hours = Math.floor(total_surgery_minutes / 60);
            const avg_minutes = Math.round(total_surgery_minutes / surgery_len);
            const avg_surg_hours = Math.floor(avg_minutes / 60);
            const avg_surg_minutes = avg_minutes % 60;

            let clamp_len = e.clamp_times.length;
            let total_clamp_time = 0;
            e.clamp_times.map((x) => {
              if (x || x === 0) {
                total_clamp_time = total_clamp_time + parseFloat(x);
              } else {
                clamp_len = clamp_len - 1;
              }
            });
            const avg_clamp_time = isNaN(
              Math.round(total_clamp_time / clamp_len)
            )
              ? 0
              : Math.round(total_clamp_time / clamp_len);
            const clamp_hours = Math.floor(avg_clamp_time / 60) ?? 0;
            const clamp_minutes = Math.round(avg_clamp_time % 60) ?? 0;

            let pump_len = e.pump_times.length;
            let total_pump_time = 0;
            e.pump_times.map((x) => {
              if (x || x === 0) {
                total_pump_time = total_pump_time + parseFloat(x);
              } else {
                pump_len = pump_len - 1;
              }
            });
            const avg_pump_time = isNaN(Math.round(total_pump_time / pump_len))
              ? 0
              : Math.round(total_pump_time / pump_len);
            const pump_hours = Math.floor(avg_pump_time / 60) ?? 0;
            const pump_minutes = Math.round(avg_pump_time % 60) ?? 0;

            let circ_len = e.circ_times.length;
            let total_circ_time = 0;
            e.circ_times.map((x) => {
              if (x || x === 0) {
                total_circ_time = total_circ_time + parseFloat(x);
              } else {
                circ_len = circ_len - 1;
              }
            });
            const avg_circ_time = isNaN(Math.round(total_circ_time / circ_len))
              ? 0
              : Math.round(total_circ_time / circ_len);
            const circ_hours = Math.floor(avg_circ_time / 60) ?? 0;
            const circ_minutes = Math.round(avg_circ_time % 60) ?? 0;

            let cabg_len = e.cabg_count.length;
            let total_cabg = 0;
            e.cabg_count.map((x) => {
              if (x || x === 0) {
                total_cabg = total_cabg + parseFloat(x);
              } else {
                cabg_len = cabg_len - 1;
              }
            });
            const avg_cabg = (total_cabg / cabg_len ?? 0).toFixed(2);

            let pre_hct_len = e.pre_hct.length;
            let total_pre_hct = 0;
            e.pre_hct.map((x) => {
              if (x || x === 0) {
                total_pre_hct = total_pre_hct + parseFloat(x);
              } else {
                pre_hct_len = pre_hct_len - 1;
              }
            });
            const avg_pre_hct = (total_pre_hct / pre_hct_len ?? 0).toFixed(2);

            let post_hct_len = e.post_hct.length;
            let total_post_hct = 0;
            e.post_hct.map((x) => {
              if (x || x === 0) {
                total_post_hct = total_post_hct + parseFloat(x);
              } else {
                post_hct_len = post_hct_len - 1;
              }
            });
            const avg_post_hct = (total_post_hct / post_hct_len).toFixed(2);

            let low_hct_len = e.low_hct.length;
            let total_low_hct = 0;
            e.low_hct.map((x) => {
              if (x || x === 0) {
                total_low_hct = total_low_hct + parseFloat(x);
              } else {
                low_hct_len = low_hct_len - 1;
              }
            });
            const avg_low_hct = (total_low_hct / low_hct_len).toFixed(2);

            let low_temp_len = e.low_temp.length;
            let total_low_temp = 0;
            e.low_temp.map((x) => {
              if ((x || x === 0) && x !== '200.000') {
                total_low_temp = total_low_temp + parseFloat(x);
              } else {
                low_temp_len = low_temp_len - 1;
              }
            });
            const avg_low_temp = (total_low_temp / low_temp_len).toFixed(2);

            return {
              name: e.name,
              patients: e.patients,
              total_surgery: `${surgery_hours}hr ${surgery_minutes}min`,
              avg_surgery: `${avg_surg_hours}hr ${avg_surg_minutes}min`,
              avg_clamp: `${clamp_hours}hr ${clamp_minutes}min`,
              avg_pump: `${pump_hours}hr ${pump_minutes}min`,
              avg_circ: `${circ_hours}hr ${circ_minutes}min`,
              avg_cabg: isNaN(avg_cabg) ? 0 : avg_cabg,
              avg_pre_hct: isNaN(avg_pre_hct) ? 0 : avg_pre_hct,
              avg_post_hct: isNaN(avg_post_hct) ? 0 : avg_post_hct,
              avg_low_hct: isNaN(avg_low_hct) ? 0 : avg_low_hct,
              avg_low_temp: isNaN(avg_low_temp) ? 0 : avg_low_temp,
            };
          });
          res.cpb_analytic_data = cpb_analytic_data;

          // #region Autotransfusion Analytics
          const temp_auto_data = [];
          data.map((e) => {
            const auto = e.procedure.find((e) => e.id === 2);
            if (auto) {
              const surgeon = e.personnel_roles.find((x) => x.job?.id === 15);
              if (surgeon) {
                const existingIndex = temp_auto_data.findIndex(
                  (x) => x.name === surgeon.user_full_name
                );
                if (existingIndex > -1) {
                  const existing = temp_auto_data[existingIndex];
                  const start_date = e.auto_detail?.start_date;
                  const start_time = e.auto_detail?.start_time;

                  const end_date = e.auto_detail?.end_date;
                  const end_time = e.auto_detail?.end_time;

                  let minutes;
                  if (start_date && start_time && end_date && end_time) {
                    const new_start = new Date(`${start_date}, ${start_time}`);
                    const new_end = new Date(`${end_date}, ${end_time}`);

                    const diff = new_end - new_start;
                    minutes = Math.floor(diff / (1000 * 60));
                  }

                  const new_obj = {
                    name: existing.name,
                    patients: existing.patients + 1,
                    surgery_times: [...existing.surgery_times, minutes ?? 0],
                    pre_hct: [
                      ...existing.pre_hct,
                      e.auto_detail?.pre_hematocrit ?? 0,
                    ],
                    pre_hemo: [
                      ...existing.pre_hemo,
                      e.auto_detail?.pre_hemoglobin ?? 0,
                    ],
                    irrigation_used: [
                      ...existing.irrigation_used,
                      e.auto_detail?.total_irrigation &&
                      e.auto_detail?.total_irrigation > 0
                        ? 1
                        : 0,
                    ],
                    vol_collected: [
                      ...existing.vol_collected,
                      e.auto_detail?.total_vol_collected ?? 0,
                    ],
                    blood_processed: [
                      ...existing.blood_processed,
                      e.auto_detail?.total_vol_returned &&
                      e.auto_detail?.total_vol_returned > 0
                        ? 1
                        : 0,
                    ],
                    volume_returned: [
                      ...existing.volume_returned,
                      e.auto_detail?.total_vol_returned ?? 0,
                    ],
                    total_gross:
                      existing.total_gross + (e.auto_detail?.gross_ebl ?? 0),
                    total_net:
                      existing.total_net + (e.auto_detail?.net_ebl ?? 0),
                  };

                  temp_auto_data[existingIndex] = new_obj;
                } else {
                  const start_date = e.auto_detail?.start_date;
                  const start_time = e.auto_detail?.start_time;

                  const end_date = e.auto_detail?.end_date;
                  const end_time = e.auto_detail?.end_time;

                  let minutes;
                  if (start_date && start_time && end_date && end_time) {
                    const new_start = new Date(`${start_date}, ${start_time}`);
                    const new_end = new Date(`${end_date}, ${end_time}`);

                    const diff = new_end - new_start;
                    minutes = Math.floor(diff / (1000 * 60));
                  }

                  const new_obj = {
                    name: surgeon.user_full_name,
                    patients: 1,
                    surgery_times: [minutes ?? 0],
                    pre_hct: [e.auto_detail?.pre_hematocrit ?? 0],
                    pre_hemo: [e.auto_detail?.pre_hemoglobin ?? 0],
                    irrigation_used: [
                      e.auto_detail?.total_irrigation &&
                      e.auto_detail?.total_irrigation > 0
                        ? 1
                        : 0,
                    ],
                    vol_collected: [e.auto_detail?.total_vol_collected ?? 0],
                    blood_processed: [
                      e.auto_detail?.total_vol_returned &&
                      e.auto_detail?.total_vol_returned > 0
                        ? 1
                        : 0,
                    ],
                    volume_returned: [e.auto_detail?.total_vol_returned ?? 0],
                    total_gross: e.auto_detail?.gross_ebl ?? 0,
                    total_net: e.auto_detail?.net_ebl ?? 0,
                  };
                  temp_auto_data.push(new_obj);
                }
              }
            }
          });
          const auto_analaytic_data = temp_auto_data.map((e) => {
            let surgery_len = e.surgery_times.length;
            let total_surgery_minutes = 0;
            e.surgery_times.map((x) => {
              if (x || x === 0) {
                total_surgery_minutes = total_surgery_minutes + x;
              } else {
                surgery_len = surgery_len - 1;
              }
            });
            const surgery_minutes = total_surgery_minutes % 60;
            const surgery_hours = Math.floor(total_surgery_minutes / 60);
            const avg_minutes = Math.round(total_surgery_minutes / surgery_len);
            const avg_surg_hours = Math.floor(avg_minutes / 60);
            const avg_surg_minutes = avg_minutes % 60;

            let total_hct = 0;
            e.pre_hct.map((x) => {
              total_hct = total_hct + x;
            });
            const avg_hct = (total_hct / e.pre_hct.length).toFixed(1);

            let total_hemo = 0;
            e.pre_hemo.map((x) => {
              total_hemo = total_hemo + x;
            });
            const avg_hemo = (total_hemo / e.pre_hemo.length).toFixed(2);

            let total_irrigation = 0;
            e.irrigation_used.map((x) => {
              total_irrigation = total_irrigation + x;
            });
            const irrigation_percent = (
              (total_irrigation / e.irrigation_used.length) *
              100
            ).toFixed(2);

            let total_volume_collected = 0;
            e.vol_collected.map((x) => {
              total_volume_collected = total_volume_collected + x;
            });
            const avg_vol_collected = Math.round(
              total_volume_collected / e.vol_collected.length
            );

            let total_processed = 0;
            e.blood_processed.map((x) => {
              total_processed = total_processed + x;
            });
            const processed_percent = (
              (total_processed / e.blood_processed.length) *
              100
            ).toFixed(2);

            let vol_returned = 0;
            e.volume_returned.map((x) => {
              vol_returned = vol_returned + x;
            });
            const avg_volume_returned = Math.round(
              vol_returned / e.volume_returned.length
            );

            return {
              name: e.name,
              patient_count: e.patients,
              total_collection_time: `${surgery_hours}hr ${surgery_minutes}min`,
              avg_collection_time: `${avg_surg_hours}hr ${avg_surg_minutes}min`,
              avg_preop_hct: isNaN(avg_hct) ? 0 : avg_hct,
              avg_preop_hemoglobin: isNaN(avg_hemo) ? 0 : avg_hemo,
              irrigation_used: `${irrigation_percent}%`,
              avg_volume_collected: isNaN(avg_vol_collected)
                ? 0
                : avg_vol_collected,
              blood_processed: `${processed_percent}%`,
              avg_volume_returned: isNaN(avg_volume_returned)
                ? 0
                : avg_volume_returned,
              total_gross_estimated_blood_loss: e.total_gross,
              total_net_estimated_blood_loss: e.total_net,
            };
          });
          res.auto_analytic_data = auto_analaytic_data;

          // #region Perfusion Standby
          const temp_standby_analytic = [];
          data.map((e) => {
            const standby = e.procedure.find((e) => e.id === 7);
            if (standby) {
              const surgeon = e.personnel_roles.find((x) => x.job?.id === 15);
              if (surgeon) {
                const existingIndex = temp_standby_analytic.findIndex(
                  (x) => x.name === surgeon.user_full_name
                );
                if (existingIndex > -1) {
                  const existing = temp_standby_analytic[existingIndex];
                  const start_date = e.perfusion_detail?.start_date;
                  const start_time = e.perfusion_detail?.start_time;

                  const end_date = e.perfusion_detail?.end_date;
                  const end_time = e.perfusion_detail?.end_time;

                  let minutes;
                  if (start_date && start_time && end_date && end_time) {
                    const new_start = new Date(`${start_date}, ${start_time}`);
                    const new_end = new Date(`${end_date}, ${end_time}`);

                    const diff = new_end - new_start;
                    minutes = Math.floor(diff / (1000 * 60));
                  }

                  const new_obj = {
                    name: existing.name,
                    patients: existing.patients + 1,
                    surgery_times: [...existing.surgery_times, minutes ?? 0],
                    cabg_count: [
                      ...existing.cabg_count,
                      e.perfusion_detail?.bypass_count ?? null,
                    ],
                  };

                  temp_standby_analytic[existingIndex] = new_obj;
                } else {
                  const start_date = e.perfusion_detail?.start_date;
                  const start_time = e.perfusion_detail?.start_time;

                  const end_date = e.perfusion_detail?.end_date;
                  const end_time = e.perfusion_detail?.end_time;

                  let minutes;
                  if (start_date && start_time && end_date && end_time) {
                    const new_start = new Date(`${start_date}, ${start_time}`);
                    const new_end = new Date(`${end_date}, ${end_time}`);

                    const diff = new_end - new_start;
                    minutes = Math.floor(diff / (1000 * 60));
                  }

                  const new_obj = {
                    name: surgeon.user_full_name,
                    patients: 1,
                    surgery_times: [minutes ?? 0],
                    cabg_count: [e.perfusion_detail?.bypass_count ?? 0],
                  };
                  temp_standby_analytic.push(new_obj);
                }
              }
            }
          });
          const standby_analytic_data = temp_standby_analytic.map((e) => {
            let surgery_len = e.surgery_times.length;
            let total_surgery_minutes = 0;
            e.surgery_times.map((x) => {
              if (x || x === 0) {
                total_surgery_minutes = total_surgery_minutes + x;
              } else {
                surgery_len = surgery_len - 1;
              }
            });
            const total_hours = Math.floor(total_surgery_minutes / 60);
            const total_minutes = total_surgery_minutes % 60;
            const avg_surg_minutes = Math.floor(
              total_surgery_minutes / surgery_len
            );
            const avg_minutes = avg_surg_minutes % 60;
            const avg_hours = Math.floor(avg_surg_minutes / 60);

            let cabg_len = e.cabg_count.length;
            let total_cabg = 0;
            e.cabg_count.map((x) => {
              if (x || x === 0) {
                total_cabg = total_cabg + x;
              } else {
                cabg_len = cabg_len - 1;
              }
            });
            const avg_cabg = total_cabg / cabg_len ?? 0;

            return {
              name: e.name,
              patients: e.patients,
              total_standby: `${total_hours}hr ${total_minutes}min`,
              avg_standby: `${avg_hours}hr ${avg_minutes}min`,
              avg_cabg: avg_cabg,
            };
          });
          res.standby_analytic_data = standby_analytic_data;

          // #region Blood Product Usage Summary
          const temp_blood_data = [];
          data.map((e) => {
            const cpb = e.procedure.find((e) => e.id === 1);
            if (cpb) {
              const surgeon = e.personnel_roles.find((x) => x.job?.id === 15);
              if (surgeon) {
                const existingIndex = temp_blood_data.findIndex(
                  (x) => x.name === surgeon.user_full_name
                );
                if (existingIndex > -1) {
                  const existing = temp_blood_data[existingIndex];
                  let transfused = false;
                  let total_used = 0;
                  let prebypass = 0;
                  let bypass = 0;
                  let postbypass = 0;
                  if (e.cpb_detail?.blood) {
                    for (var key in e.cpb_detail.blood) {
                      if (e.cpb_detail.blood[key] && key !== "id") {
                        transfused = true;
                        total_used =
                          total_used + parseInt(e.cpb_detail.blood[key]);
                        if (key.includes("_prebypass")) {
                          prebypass =
                            prebypass + parseInt(e.cpb_detail.blood[key]);
                        } else if (key.includes("_bypass")) {
                          bypass = bypass + parseInt(e.cpb_detail.blood[key]);
                        } else if (key.includes("_postbypass")) {
                          postbypass =
                            postbypass + parseInt(e.cpb_detail.blood[key]);
                        }
                      }
                    }
                  }

                  const blood_used = transfused
                    ? {
                        prebypass: prebypass,
                        bypass: bypass,
                        postbypass: postbypass,
                      }
                    : undefined;

                  const new_obj = {
                    name: surgeon.user_full_name,
                    patients: existing.patients + 1,
                    transfused_count:
                      existing.transfused_count + (transfused ? 1 : 0),
                    total_used: existing.total_used + total_used,
                    blood_used: [...existing.blood_used, blood_used],
                  };

                  temp_blood_data[existingIndex] = new_obj;
                } else {
                  let transfused = false;
                  let total_used = 0;
                  let prebypass = 0;
                  let bypass = 0;
                  let postbypass = 0;
                  if (e.cpb_detail?.blood) {
                    for (var key in e.cpb_detail.blood) {
                      if (e.cpb_detail.blood[key] && key !== "id") {
                        transfused = true;
                        total_used =
                          total_used + parseInt(e.cpb_detail.blood[key]);
                        if (key.includes("_prebypass")) {
                          prebypass =
                            prebypass + parseInt(e.cpb_detail.blood[key]);
                        } else if (key.includes("_bypass")) {
                          bypass = bypass + parseInt(e.cpb_detail.blood[key]);
                        } else if (key.includes("_postbypass")) {
                          postbypass =
                            postbypass + parseInt(e.cpb_detail.blood[key]);
                        }
                      }
                    }
                  }

                  const blood_used = transfused
                    ? {
                        prebypass: prebypass,
                        bypass: bypass,
                        postbypass: postbypass,
                      }
                    : undefined;

                  const new_obj = {
                    name: surgeon.user_full_name,
                    patients: 1,
                    transfused_count: transfused ? 1 : 0,
                    total_used: total_used,
                    blood_used: [blood_used],
                  };
                  temp_blood_data.push(new_obj);
                }
              }
            }
          });
          let totals = {
            patients: 0,
            transfused: 0,
            units: 0,
          };
          const temp_surgeon_data = [];
          temp_blood_data.map((e) => {
            if (e.transfused_count > 0) {
              totals.patients = totals.patients + e.patients;
              totals.transfused = totals.transfused + e.transfused_count;
              totals.units = totals.units + e.total_used;

              let total = 0;
              let prebypass = 0;
              let bypass = 0;
              let postbypass = 0;
              e.blood_used.map((x) => {
                if (x !== undefined) {
                  if (x.prebypass > 0) {
                    total = total + x.prebypass;
                    prebypass = prebypass + x.prebypass;
                  }
                  if (x.bypass > 0) {
                    total = total + x.bypass;
                    bypass = bypass + x.bypass;
                  }
                  if (x.postbypass > 0) {
                    total = total + x.postbypass;
                    postbypass = postbypass + x.postbypass;
                  }
                }
              });
              const prebypass_percent = ((prebypass / total) * 100).toFixed(2);
              const bypass_percent = ((bypass / total) * 100).toFixed(2);
              const postbypass_percent = ((postbypass / total) * 100).toFixed(
                2
              );

              const new_obj = {
                name: e.name,
                patients: e.patients,
                transfused_count: e.transfused_count,
                units_used: e.total_used,
                prebypass: `${prebypass_percent}%`,
                bypass: `${bypass_percent}%`,
                postbypass: `${postbypass_percent}%`,
              };
              temp_surgeon_data.push(new_obj);
            }
          });
          const blood_usage_summary = {
            surgeons: temp_surgeon_data,
            totals: totals,
          };
          res.blood_usage_summary = blood_usage_summary;

          // #region Blood Product Usage Detail
          const temp_blood_detail_data = [];
          data.map((e) => {
            const cpb = e.procedure.find((e) => e.id === 1);
            if (cpb) {
              const surgeon = e.personnel_roles.find((x) => x.job?.id === 15);
              if (surgeon) {
                const existingIndex = temp_blood_detail_data.findIndex(
                  (x) => x.name === surgeon.user_full_name
                );
                if (existingIndex > -1) {
                  const existing = temp_blood_detail_data[existingIndex];

                  let transfused = false;
                  const products = [];
                  if (e.cpb_detail?.blood) {
                    if (
                      e.cpb_detail.blood.prbc_prebypass ||
                      e.cpb_detail.blood.prbc_bypass ||
                      e.cpb_detail.blood.prbc_postbypass
                    ) {
                      transfused = true;
                      products.push({
                        name: "PRBC",
                        prebypass: e.cpb_detail.blood.prbc_prebypass ?? 0,
                        bypass: e.cpb_detail.blood.prbc_bypass ?? 0,
                        postbypass: e.cpb_detail.blood.prbc_postbypass ?? 0,
                        total:
                          (e.cpb_detail.blood.prbc_postbypass ?? 0) +
                          (e.cpb_detail.blood.prbc_bypass ?? 0) +
                          (e.cpb_detail.blood.prbc_prebypass ?? 0),
                      });
                    }
                    if (
                      e.cpb_detail.blood.platelet_prebypass ||
                      e.cpb_detail.blood.platelet_bypass ||
                      e.cpb_detail.blood.platelet_postbypass
                    ) {
                      transfused = true;
                      products.push({
                        name: "Platelet",
                        prebypass: e.cpb_detail.blood.platelet_prebypass ?? 0,
                        bypass: e.cpb_detail.blood.platelet_bypass ?? 0,
                        postbypass: e.cpb_detail.blood.platelet_postbypass ?? 0,
                        total:
                          (e.cpb_detail.blood.platelet_postbypass ?? 0) +
                          (e.cpb_detail.blood.platelet_bypass ?? 0) +
                          (e.cpb_detail.blood.platelet_prebypass ?? 0),
                      });
                    }
                    if (
                      e.cpb_detail.blood.ffp_prebypass ||
                      e.cpb_detail.blood.ffp_bypass ||
                      e.cpb_detail.blood.ffp_postbypass
                    ) {
                      transfused = true;
                      products.push({
                        name: "FFP",
                        prebypass: e.cpb_detail.blood.ffp_prebypass ?? 0,
                        bypass: e.cpb_detail.blood.ffp_bypass ?? 0,
                        postbypass: e.cpb_detail.blood.ffp_postbypass ?? 0,
                        total:
                          (e.cpb_detail.blood.ffp_prebypass ?? 0) +
                          (e.cpb_detail.blood.ffp_bypass ?? 0) +
                          (e.cpb_detail.blood.ffp_postbypass ?? 0),
                      });
                    }
                    if (
                      e.cpb_detail.blood.whole_prebypass ||
                      e.cpb_detail.blood.whole_bypass ||
                      e.cpb_detail.blood.whole_postbypass
                    ) {
                      transfused = true;
                      products.push({
                        name: "Whole Blood",
                        prebypass: e.cpb_detail.blood.whole_prebypass ?? 0,
                        bypass: e.cpb_detail.blood.whole_bypass ?? 0,
                        postbypass: e.cpb_detail.blood.whole_postbypass ?? 0,
                        total:
                          (e.cpb_detail.blood.whole_postbypass ?? 0) +
                          (e.cpb_detail.blood.whole_bypass ?? 0) +
                          (e.cpb_detail.blood.whole_prebypass ?? 0),
                      });
                    }
                    if (
                      e.cpb_detail.blood.cryo_prebypass ||
                      e.cpb_detail.blood.cryo_bypass ||
                      e.cpb_detail.blood.cryo_postbypass
                    ) {
                      transfused = true;
                      products.push({
                        name: "CRYO",
                        prebypass: e.cpb_detail.blood.cryo_prebypass ?? 0,
                        bypass: e.cpb_detail.blood.cryo_bypass ?? 0,
                        postbypass: e.cpb_detail.blood.cryo_postbypass ?? 0,
                        total:
                          (e.cpb_detail.blood.cryo_prebypass ?? 0) +
                          (e.cpb_detail.blood.cryo_bypass ?? 0) +
                          (e.cpb_detail.blood.cryo_postbypass ?? 0),
                      });
                    }
                  }

                  let personnel_string = "";
                  e.personnel_roles.map((x, idx) => {
                    if (idx === 0) {
                      personnel_string = `${x.user_full_name}`;
                    } else {
                      personnel_string = `${personnel_string} / ${x.user_full_name}`;
                    }
                  });

                  if (transfused) {
                    temp_blood_detail_data[existingIndex].patient_rows = [
                      ...(existing.patient_rows ?? []),
                      {
                        mrn: e.medical_record_num,
                        name: e.patient_name,
                        date: e.date,
                        personnel: personnel_string,
                        operation: `${e.cpb_detail?.top?.classification} / CABGx${e.cpb_detail?.bypass_count}`,
                        products: products,
                      },
                    ];
                  }

                  temp_blood_detail_data[existingIndex].patients =
                    existing.patients + 1;
                } else {
                  let transfused = false;
                  const products = [];
                  if (e.cpb_detail?.blood) {
                    if (
                      e.cpb_detail.blood.prbc_prebypass ||
                      e.cpb_detail.blood.prbc_bypass ||
                      e.cpb_detail.blood.prbc_postbypass
                    ) {
                      transfused = true;
                      products.push({
                        name: "PRBC",
                        prebypass: e.cpb_detail.blood.prbc_prebypass ?? 0,
                        bypass: e.cpb_detail.blood.prbc_bypass ?? 0,
                        postbypass: e.cpb_detail.blood.prbc_postbypass ?? 0,
                        total:
                          (e.cpb_detail.blood.prbc_postbypass ?? 0) +
                          (e.cpb_detail.blood.prbc_bypass ?? 0) +
                          (e.cpb_detail.blood.prbc_prebypass ?? 0),
                      });
                    }
                    if (
                      e.cpb_detail.blood.platelet_prebypass ||
                      e.cpb_detail.blood.platelet_bypass ||
                      e.cpb_detail.blood.platelet_postbypass
                    ) {
                      transfused = true;
                      products.push({
                        name: "Platelet",
                        prebypass: e.cpb_detail.blood.platelet_prebypass ?? 0,
                        bypass: e.cpb_detail.blood.platelet_bypass ?? 0,
                        postbypass: e.cpb_detail.blood.platelet_postbypass ?? 0,
                        total:
                          (e.cpb_detail.blood.platelet_postbypass ?? 0) +
                          (e.cpb_detail.blood.platelet_bypass ?? 0) +
                          (e.cpb_detail.blood.platelet_prebypass ?? 0),
                      });
                    }
                    if (
                      e.cpb_detail.blood.ffp_prebypass ||
                      e.cpb_detail.blood.ffp_bypass ||
                      e.cpb_detail.blood.ffp_postbypass
                    ) {
                      transfused = true;
                      products.push({
                        name: "FFP",
                        prebypass: e.cpb_detail.blood.ffp_prebypass ?? 0,
                        bypass: e.cpb_detail.blood.ffp_bypass ?? 0,
                        postbypass: e.cpb_detail.blood.ffp_postbypass ?? 0,
                        total:
                          (e.cpb_detail.blood.ffp_prebypass ?? 0) +
                          (e.cpb_detail.blood.ffp_bypass ?? 0) +
                          (e.cpb_detail.blood.ffp_postbypass ?? 0),
                      });
                    }
                    if (
                      e.cpb_detail.blood.whole_prebypass ||
                      e.cpb_detail.blood.whole_bypass ||
                      e.cpb_detail.blood.whole_postbypass
                    ) {
                      transfused = true;
                      products.push({
                        name: "Whole Blood",
                        prebypass: e.cpb_detail.blood.whole_prebypass ?? 0,
                        bypass: e.cpb_detail.blood.whole_bypass ?? 0,
                        postbypass: e.cpb_detail.blood.whole_postbypass ?? 0,
                        total:
                          (e.cpb_detail.blood.whole_postbypass ?? 0) +
                          (e.cpb_detail.blood.whole_bypass ?? 0) +
                          (e.cpb_detail.blood.whole_prebypass ?? 0),
                      });
                    }
                    if (
                      e.cpb_detail.blood.cryo_prebypass ||
                      e.cpb_detail.blood.cryo_bypass ||
                      e.cpb_detail.blood.cryo_postbypass
                    ) {
                      transfused = true;
                      products.push({
                        name: "CRYO",
                        prebypass: e.cpb_detail.blood.cryo_prebypass ?? 0,
                        bypass: e.cpb_detail.blood.cryo_bypass ?? 0,
                        postbypass: e.cpb_detail.blood.cryo_postbypass ?? 0,
                        total:
                          (e.cpb_detail.blood.cryo_prebypass ?? 0) +
                          (e.cpb_detail.blood.cryo_bypass ?? 0) +
                          (e.cpb_detail.blood.cryo_postbypass ?? 0),
                      });
                    }
                  }

                  let personnel_string = "";
                  e.personnel_roles.map((x, idx) => {
                    if (idx === 0) {
                      personnel_string = `${x.user_full_name}`;
                    } else {
                      personnel_string = `${personnel_string} / ${x.user_full_name}`;
                    }
                  });

                  let new_obj = {
                    name: surgeon.user_full_name,
                    patients: 1,
                  };

                  if (transfused) {
                    new_obj = {
                      ...new_obj,
                      patient_rows: [
                        {
                          mrn: e.medical_record_num,
                          name: e.patient_name,
                          date: e.date,
                          personnel: personnel_string,
                          operation: `${e.cpb_detail?.top?.classification} / CABGx${e.cpb_detail?.bypass_count}`,
                          products: products,
                        },
                      ],
                    };
                  }

                  temp_blood_detail_data.push(new_obj);
                }
              }
            }
          });
          const blood_detail_data = [];
          temp_blood_detail_data.map((e) => {
            const transfused_percent = (
              (e.patient_rows?.length / e.patients) *
              100
            ).toFixed(2);
            let prebypass = 0;
            let bypass = 0;
            let postbypass = 0;
            e.patient_rows?.map((x) => {
              x.products.map((z) => {
                if (z.prebypass > 0) {
                  prebypass = prebypass + z.prebypass;
                }
                if (z.bypass > 0) {
                  bypass = bypass + z.bypass;
                }
                if (z.postbypass > 0) {
                  postbypass = postbypass + z.postbypass;
                }
              });
            });
            if (e.patient_rows && e.patient_rows.length) {
              blood_detail_data.push({
                name: e.name,
                patients: e.patient_rows,
                totals: {
                  transfused_percent: `${transfused_percent}%`,
                  prebypass: prebypass,
                  bypass: bypass,
                  postbypass: postbypass,
                  total_total: prebypass + bypass + postbypass,
                },
              });
            }
          });
          res.blood_detail_data = blood_detail_data;

          // #region Continuous Quality Improvement
          const temp_qc_data = [];
          data.map((e) => {
            e.qc_items.map((x) => {
              const existingProcedureIndex = temp_qc_data.findIndex(
                (z) => z.procedure_id === x.procedure
              );
              if (existingProcedureIndex > -1) {
                const existingItemIndex = temp_qc_data[
                  existingProcedureIndex
                ]?.items.findIndex((z) => z.item_name === x.item.description);
                if (existingItemIndex > -1) {
                  temp_qc_data[existingProcedureIndex].items[
                    existingItemIndex
                  ].total_count =
                    temp_qc_data[existingProcedureIndex].items[
                      existingItemIndex
                    ].total_count + 1;
                  temp_qc_data[existingProcedureIndex].items[
                    existingItemIndex
                  ].yes_count =
                    temp_qc_data[existingProcedureIndex].items[
                      existingItemIndex
                    ].yes_count + (x.achieved ?? false ? 1 : 0);
                  temp_qc_data[existingProcedureIndex].items[
                    existingItemIndex
                  ].no_count =
                    temp_qc_data[existingProcedureIndex].items[
                      existingItemIndex
                    ].no_count + (x.achieved ?? false ? 0 : 1);
                } else {
                  temp_qc_data[existingProcedureIndex].items.push({
                    item_name: x.item.description,
                    total_count: 1,
                    yes_count: x.achieved ?? false ? 1 : 0,
                    no_count: x.achieved ?? false ? 0 : 1,
                    target: x.item.threshold,
                  });
                }
              } else {
                const proc_string = PROCEDURES.find(
                  (z) => z.id === x.procedure
                )?.value;
                temp_qc_data.push({
                  procedure: proc_string ?? "",
                  procedure_id: x.procedure,
                  items: [
                    {
                      item_name: x.item.description,
                      total_count: 1,
                      yes_count: x.achieved ?? false ? 1 : 0,
                      no_count: x.achieved ?? false ? 0 : 1,
                      target: x.item.threshold,
                    },
                  ],
                });
              }
            });
          });
          const quality_improvement_data = temp_qc_data.map((e) => {
            const new_items = e.items.map((z) => {
              return {
                item_name: z.item_name,
                total_count: z.total_count,
                yes_count: z.yes_count,
                no_count: z.no_count,
                compliance: `${((z.yes_count / z.total_count) * 100).toFixed(
                  2
                )}%`,
                target: z.target,
              };
            });
            return {
              procedure: e.procedure,
              items: new_items,
            };
          });
          res.quality_improvement_data = quality_improvement_data;

          // #region Non-Compliance
          const non_compliance_data = [];
          data.map((e) => {
            e.qc_items.map((x) => {
              if (!x.achieved || x.achieved === false) {
                const existingItemIndex = non_compliance_data.findIndex(
                  (z) => z.item_name === x.item.description
                );
                if (existingItemIndex > -1) {
                  const surgeon = e.personnel_roles.find(
                    (x) => x.job?.id === 15
                  );
                  non_compliance_data[existingItemIndex].data.push({
                    hospital_name: e.facility_name,
                    patient: e.patient_name,
                    date: e.date,
                    user: e.user.user_full_name,
                    surgeon: surgeon?.user_full_name ?? "",
                    notes: x.notes,
                  });
                } else {
                  const surgeon = e.personnel_roles.find(
                    (x) => x.job?.id === 15
                  );
                  const proc_string = PROCEDURES.find(
                    (z) => z.id === x.procedure
                  )?.value;
                  non_compliance_data.push({
                    procedure: proc_string,
                    item_name: x.item.description,
                    data: [
                      {
                        hospital_name: e.facility_name,
                        patient: e.patient_name,
                        date: e.date,
                        user: e.user.user_full_name,
                        surgeon: surgeon?.user_full_name ?? "",
                        notes: x.notes,
                      },
                    ],
                  });
                }
              }
            });
          });
          res.non_compliance_data = non_compliance_data;

          // #region Study Data
          const temp_study_data = [];
          data.map((e) => {
            e.study_items.map((x) => {
              const existingProcIndex = temp_study_data.findIndex(
                (z) => z.procedure_id === x.procedure
              );
              if (existingProcIndex > -1) {
                const existingItemIndex = temp_study_data[
                  existingProcIndex
                ].data.findIndex((z) => z.study_question === x.item.details);
                if (existingItemIndex > -1) {
                  const existing =
                    temp_study_data[existingProcIndex].data[existingItemIndex];
                  if (x.item.value_type === "Numeric") {
                    let val = 0;
                    if (!isNaN(parseFloat(x.achieved_numeric))) {
                      val = parseFloat(x.achieved_numeric);
                    }
                    temp_study_data[existingProcIndex].data[
                      existingItemIndex
                    ].count = existing.count + 1;
                    temp_study_data[existingProcIndex].data[
                      existingItemIndex
                    ].values = [
                      ...temp_study_data[existingProcIndex].data[
                        existingItemIndex
                      ].values,
                      val,
                    ];
                  } else {
                    temp_study_data[existingProcIndex].data[
                      existingItemIndex
                    ].yes_count = existing.yes_count + (x.achieved ? 1 : 0);
                    temp_study_data[existingProcIndex].data[
                      existingItemIndex
                    ].no_count = existing.no_count + (x.achieved ? 0 : 1);
                    temp_study_data[existingProcIndex].data[
                      existingItemIndex
                    ].count = existing.count + 1;
                  }
                } else {
                  if (x.item.value_type === "Numeric") {
                    let val = 0;
                    if (!isNaN(parseFloat(x.achieved_numeric))) {
                      val = parseFloat(x.achieved_numeric);
                    }
                    temp_study_data[existingProcIndex].data.push({
                      numeric: true,
                      study_question: x.item.details,
                      count: 1,
                      values: [val],
                    });
                  } else {
                    temp_study_data[existingProcIndex].data.push({
                      numeric: false,
                      study_question: x.item.details,
                      count: 1,
                      yes_count: x.achieved ? 1 : 0,
                      no_count: x.achieved ? 0 : 1,
                    });
                  }
                }
              } else {
                const procedure = PROCEDURES.find(
                  (z) => z.id === x.procedure
                )?.value;
                if (x.item.value_type === "Numeric") {
                  let val = 0;
                  if (!isNaN(parseFloat(x.achieved_numeric))) {
                    val = parseFloat(x.achieved_numeric);
                  }
                  temp_study_data.push({
                    procedure: procedure,
                    procedure_id: x.procedure,
                    data: [
                      {
                        numeric: true,
                        study_question: x.item.details,
                        count: 1,
                        values: [val],
                      },
                    ],
                  });
                } else {
                  temp_study_data.push({
                    procedure: procedure,
                    procedure_id: x.procedure,
                    data: [
                      {
                        numeric: false,
                        procedure: procedure,
                        study_question: x.item.details,
                        count: 1,
                        yes_count: x.achieved ? 1 : 0,
                        no_count: x.achieved ? 0 : 1,
                      },
                    ],
                  });
                }
              }
            });
          });
          const study_data = temp_study_data.map((x) => {
            const newData = x.data.map((e) => {
              if (e.numeric) {
                let high = 0;
                let low = 10000;
                let total = 0;
                e.values.map((x) => {
                  if (x > high) {
                    high = x;
                  }
                  if (x < low) {
                    low = x;
                  }
                  total = total + x;
                });

                const n = e.values.length;
                const mean = e.values.reduce((a, b) => a + b) / n;
                const std_deviation = Math.sqrt(
                  e.values
                    .map((x) => Math.pow(x - mean, 2))
                    .reduce((a, b) => a + b) / n
                );

                return {
                  numeric: true,
                  study_question: e.study_question,
                  count: e.count,
                  low: low,
                  high: high,
                  average: (total / e.values.length).toFixed(2),
                  std_deviation: std_deviation.toFixed(2),
                };
              } else {
                return e;
              }
            });
            return {
              procedure: x.procedure,
              data: newData,
            };
          });
          res.study_data = study_data;

          // #region Disposables
          const temp_disposable_data = [];
          data.map((e) => {
            e.items.map((x) => {
              const existingManuIndex = temp_disposable_data.findIndex(
                (z) => z.manufacturer === x.item.manufacturer_name
              );
              if (existingManuIndex > -1) {
                const existingItemIndex = temp_disposable_data[
                  existingManuIndex
                ].items.findIndex(
                  (z) =>
                    z.catalog === x.item.catalog &&
                    z.description === x.item.description
                );
                if (existingItemIndex > -1) {
                  temp_disposable_data[existingManuIndex].items[
                    existingItemIndex
                  ].units =
                    temp_disposable_data[existingManuIndex].items[
                      existingItemIndex
                    ].units + (x.amount ?? 1);
                } else {
                  temp_disposable_data[existingManuIndex].items.push({
                    catalog: x.item.catalog,
                    category: x.item.category_name,
                    description: x.item.description,
                    units: x.amount ?? 1,
                    type: x.item.type,
                    quantity: x.item.quantity,
                  });
                }
              } else {
                temp_disposable_data.push({
                  manufacturer: x.item.manufacturer_name,
                  items: [
                    {
                      catalog: x.item.catalog,
                      category: x.item.category_name,
                      description: x.item.description,
                      units: x.amount ?? 1,
                      type: x.item.type,
                      quantity: x.item.quantity,
                    },
                  ],
                });
              }
            });
          });
          const disposable_data = temp_disposable_data.map((e) => {
            const items = e.items.map((x) => {
              const case_count =
                x.type === 1 && x.quantity
                  ? Math.ceil((x.units ?? 1) / x.quantity)
                  : x.units;

              return {
                catalog: x.catalog,
                category: x.category,
                description: x.description,
                units: x.units,
                case_count: case_count,
              };
            });

            return {
              manufacturer: e.manufacturer,
              items: items,
            };
          });
          res.disposable_data = disposable_data;
          successCallback(res);
        }
      })
      .catch((error) => {
        console.log(error);

        // Unauthorized
        if (error.response.status == 401 && Session) {
          Session.logout();
          logout();
          return;
        }
        let message = this.formatError(error);
        if (errorCallback) errorCallback(message);
      });
    return cancelRequest;
  }

  static uploadFile(
    method,
    path,
    params,
    data,
    successCallback,
    errorCallback,
    alwaysCallback
  ) {
    let cancelRequest;
    axios({
      method: method,
      url: this.formatPath(path, params),
      data: data,
      cancelToken: new CancelToken(function executor(c) {
        cancelRequest = c;
      }),
      headers: {
        ...this.getHeaders(),
        "Content-Type": "multipart/form-data",
      },
    })
      .then((resp) => {
        if (successCallback) successCallback(resp.data);
        if (alwaysCallback) alwaysCallback();
      })
      .catch((error) => {
        console.log(error);

        // Unauthorized
        if (error.response.status == 401) {
          Session.logout();
          return;
        }
        let message = this.formatFileError(error);
        if (errorCallback) errorCallback(message);
        if (alwaysCallback) alwaysCallback();
      });
    return cancelRequest;
  }

  static downloadFile(
    method,
    path,
    params,
    data,
    successCallback,
    errorCallback,
    alwaysCallback
  ) {
    let cancelRequest;
    axios({
      method: method,
      url: this.formatPath(path, params),
      data: data,
      cancelToken: new CancelToken(function executor(c) {
        cancelRequest = c;
      }),
      headers: {
        ...this.getHeaders(),
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob",
    })
      .then((resp) => {
        const contentType = resp.headers["Content-Type"];
        if (params && params["type"] == "download_file") {
          let headerLine = resp.headers["content-disposition"];
          let fileName = headerLine.split("filename=");

          const href = URL.createObjectURL(resp.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", fileName[1]);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        }

        if (successCallback) successCallback(resp.data, contentType);
        if (alwaysCallback) alwaysCallback();
      })
      .catch((error) => {
        console.log(error);

        // Unauthorized
        if (error.response.status == 401) {
          Session.logout();
          return;
        }
        let message = this.formatFileError(error);
        if (errorCallback) errorCallback(message);
        if (alwaysCallback) alwaysCallback();
      });
    return cancelRequest;
  }

  static formatPath(path, params) {
    if (params) {
      Object.keys(params).forEach((key, index) => {
        if (index === 0) {
          path += "?";
        } else {
          path += "&";
        }
        path += key + "=" + params[key];
      });
    }
    return path;
  }

  static getHeaders() {
    const headers = {};
    const token = localStorage.getItem("token").replace(/(^"|"$)/g, "");
    if (token) headers.Authorization = `Token ${token}`;
    return headers;
  }

  static formatFileError(error) {
    console.log("error", error);
    if (error.response.data.includes("The specified blob already exists")) {
      return "A file with this name already exists, please rename.";
    } else {
      return "Error uploading file.";
    }
  }

  static formatError(error) {
    // console.log(error)
    if (!error.response) {
      return "Something went wrong";
    }

    // Server error
    if (error.response.status == 500) {
      return "Something went wrong";
    }

    if (error.response.status == 504) {
      return "Request took too long";
    }

    let message = "Something went wrong";

    // https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
    if (error.response.data) {
      let data = error.response.data;
      if (typeof data == "string") {
        message = data;
      } else if (Array.isArray(data)) {
        if (data.length > 0) {
          if (typeof data[0] == "string") {
            message = data[0];
          }
        }
      } else if (typeof data == "object") {
        let keys = Object.keys(data);
        if (keys.length > 0) {
          let key = keys[0];
          let obj = data[key];

          key = key.replace(/_/g, " ");
          key = key.charAt(0).toUpperCase() + key.slice(1);

          if (Array.isArray(obj)) {
            if (key == "Non field errors") {
              message = obj[0];
            } else {
              message = key + ": " + obj;
            }
          } else {
            if (typeof obj === "object") obj = JSON.stringify(obj);
            message = key + ": " + obj;
          }
        }
      }
    }

    return message;
  }
}

export default API;
