import React, { useEffect, useState, useMemo } from "react";
import DynamicForm from "../../../components/DynamicForm";
import SetupModal from "../../../components/SetupModal";
import API from "../../../../services/API";
import DataTable from 'react-data-table-component';
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

import studyDataSearch from "../../../forms/studyDataSearch";
import studyDataModal from "../../../forms/studyDataModal";
import ErrorModal from "../../../components/ErrorModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import VisitTimer from "../../../components/VisitTimer";
import Session from "../../../../services/Session"

function StudyDataSetup(props){
  const [filterValues,setFilterValues] = useState();
  const [studyData, setStudyData] = useState([]);
  const [studyDataOptions, setStudyDataOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [procedureOptions, setProcedureOptions] = useState([]);
  const [showSetupModal,setShowSetupModal] = useState(false);

  const [editValues, setEditValues] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  
  const onFilterChange = (values) => {
    setFilterValues(values);
  }

  const onEditChange = (values) => {
    setEditValues(values)
  }

  const onSave = () => {
    if(!isSaving) {
      setIsSaving(true)
      let data = {
        'details':editValues['details'],
        'procedures':editValues['procedures'],
        'facilities':editValues['facilities'],
        'status':(editValues['status'] && editValues['status'] == 'false')?false:true,
        'value_type':editValues['value_type'],
        'unit_measure':editValues['unit_measure']
      }
      let method = isEditMode ? 'PUT' : 'POST'
      let uri = isEditMode ? `/api/studydata/${editValues.id}/` : '/api/studydata/'
      API.query(method,uri,{},data, function(resp) {
        let newStudyData = studyData
        if(isEditMode) {
          let i = newStudyData.findIndex(j => j.id == editValues.id)
          if(i > -1) {
            data['id'] = editValues.id
            newStudyData[i] = data
          }
        }else {
          data['id'] = resp.id
          newStudyData.push(data)
        }
        setStudyData(newStudyData)
        setIsSaving(false)
        setShowSetupModal(false)
        setEditValues([])
      },onDataLoadError)
    }
  }

  const onSetupModalClosed = () => {
    setShowSetupModal(false)
    setEditValues([])
    setIsEditMode(false)
  }

  const onEditClicked = (row) => {
    row.status = row.status?"true":"false"
    setEditValues(row)
    setShowSetupModal(true)
    setIsEditMode(true)
  }

  const onDeleteClicked = (row) => {
    setEditValues(row)
    setShowConfirmationModal(true)
  }

  const onConfirmDelete = () => {
    API.query("DELETE",`/api/studydata/${editValues.id}/`,{},{}, function(resp) {
      let newStudyData = studyData.filter(j => j.id !== editValues.id)
      setStudyData(newStudyData)
      setShowConfirmationModal(false)
      setEditValues([])
    },onDataLoadError)
  }

  const onButtonPressed = (id, row) => {
    if(id == "clear") {
      setFilterValues([])
      
    } else if (id == "create_new") {
      setShowSetupModal(true)

    } else {

    }

  }

  const filterByFacility = (obj) => {
    var i, x, valid, abort=false
    if (filterValues.hospital && filterValues.hospital.length != 0) {
      if(obj.facilities.length != 0) {
        for(i=0; i < filterValues.hospital.length && !abort; i++) {
          for(x=0; x < obj.facilities.length && !abort; x++) {
            if (filterValues.hospital[i] == obj.facilities[x]) {
              valid = true
              abort = true
            } else {
              valid = false
            }
          }
        }
      } else {
        valid = false
      }
    } else {
      valid = true
    }
    return valid
  }

  const filterByProcedure = (obj) => {
    var y, z, valid, abort=false
    if(filterValues.procedure && filterValues.procedure.length != 0) {
      if(obj.procedures.length != 0) {
        for(y=0; y < filterValues.procedure.length && !abort; y++) {
          for(z=0; z < obj.procedures.length && !abort; z++) {
            if (filterValues.procedure[y] == obj.procedures[z]) {
              valid = true
              abort = true
            } else {
              valid = false
            }
          }
        }
      } else {
        valid = false
      }
    } else {
      valid = true
    }
    return valid
  }

  const filterByItself = (obj) => {
    var valid
    if(filterValues.studyData && filterValues.studyData.length != 0) {
      if(filterValues.studyData.findIndex(f => obj.id == f) == -1) {
        valid = false 
      } else { 
        valid = true 
      }
    } else {
      valid = true
    }
    return valid
  }

  const filterByStatus = (obj) => {
    var valid
    if(filterValues.status == "active") {
      if(obj.status) { valid=true; } else { valid = false; }
    } else if(filterValues.status == "inactive") {
      if(!obj.status) { valid=true; } else { valid=false; }
    } else {
      valid = true
    }
    return valid
  }

  const filteredStudyData =  studyData.filter((obj) =>{
    var valid = false
    if (filterByStatus(obj)) {
      if (filterByFacility(obj)) {
        if (filterByProcedure(obj)) {
          if (filterByItself(obj)) {
            valid = true
          }
        }
      }
    }
    return valid
  })

  const columns = useMemo(() => [
    {name:'Study Data Item Details',selector: row => row.details,sortable:true},
    {name:'Field Type',selector: row => row.value_type,sortable:true},
    {name:'Status',selector: row => row.status ? "Active" : "Inactive"},
    {name:'Actions',selector: row =><div>
    <a title="Edit" onClick={() => onEditClicked(row)}><FaPencilAlt size={14} /></a>
    <a className="ml-3" title="Delete" onClick={() => onDeleteClicked(row)}><FaTrashAlt size={14} /></a>
    </div>}
  ])

  useEffect(() => {
    let queries = [
      { 'method': 'GET', 'path': '/api/studydata/', },
      { 'method': 'GET', 'path': '/api/facilities/', },
      { 'method': 'GET', 'path': '/api/procedures/', }
    ]
    API.all(queries, function (resp) {
      if(props.permissions.includes(11) || props.extraPermissions.includes(11)) {
        let user = Session.get('user')
        let filteredStudy = resp[0].filter((obj) => {
          var i, x, abort=false, valid=false
          for(i=0; i < user.facilities.length && !abort; i++) {
            for(x=0; x < obj.facilities.length && !abort; x++) {
              if(user.facilities[i] == obj.facilities[x]) {
                valid=true
                abort=true
              }
            }
          }
          return valid
        })
        let filteredFacils = resp[1].filter((obj) => {
          var i, abort=false, valid=false
          for(i=0; i < user.facilities.length && !abort; i++) {
            if(user.facilities[i] == obj.id) {
              valid = true
              abort = true
            }
          }
          return valid
        })
        setFacilityOptions(filteredFacils.map(i => ({ "label": i.name, "value": i.id })))
        setStudyData(filteredStudy)
        setStudyDataOptions(filteredStudy.map(i => ({ "label": i.details, "value": i.id })))
      } else {
        setFacilityOptions(resp[1].map(i => ({ "label": i.name, "value": i.id })))
        setStudyData(resp[0])
        setStudyDataOptions(resp[0].map(i => ({ "label": i.details, "value": i.id })))
      }
      setProcedureOptions(resp[2].map(i => ({ "label": i.name, "value": i.id })))
    }, onDataLoadError)
  }, []);

  const onDataLoadError = (error) => {
    setErrorMessage(error)
    setShowErrorModal(true)
    setIsSaving(false)
  }

  const visited = () => {
    // get pageviewhistory id from user session data
    var user = Session.get('user')
    API.query('GET',`/api/pageviewhistory/${user.page_view_history}/`,{},{},function(response) {
      let data = {
        "setup_study": response.setup_study + 1
      }
      API.query('PATCH',`/api/pageviewhistory/${user.page_view_history}/`,{},data,function(resp) {}, onDataLoadError)
    }, onDataLoadError)
  }

  return <div>
    <p className="title">Study Data Setup</p>
    <hr />
    <DynamicForm
      form={studyDataSearch}
      values={filterValues}
      onValuesChanged={onFilterChange}
      onButtonPressed={onButtonPressed}
      source={{ "procedures": procedureOptions, "facilities": facilityOptions, "studyData": studyDataOptions }}
    />
    <hr />

    {
      showSetupModal
      && 
      <SetupModal
        titleString={"Study Data Setup"}
        form={studyDataModal}
        editValues={editValues}
        onEditChange={onEditChange}
        source={{"procedures": procedureOptions, "facilities": facilityOptions}}
        isSaving={isSaving}
        onSave={onSave}
        onClose={onSetupModalClosed}
      />
    }

    {
      showErrorModal
      && 
      <ErrorModal
        message={errorMessage}
        onClose={() => setShowErrorModal(false)}
      />
    }

    {
      showConfirmationModal
      && 
      <ConfirmationModal
        message={"You are about to delete a Study Data Item. This cannot be undone."}
        onConfirm={onConfirmDelete}
        onClose={() => setShowConfirmationModal(false)}
      />
    }

    <p className="title is-5">Results</p>
    <DataTable
      striped
      pagination
      data={filteredStudyData}
      columns={columns}
    />

    <VisitTimer visited={visited}/>

  </div>
}

export default StudyDataSetup