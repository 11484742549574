import React, { useEffect, useState, useMemo } from "react";
import DynamicForm from "../../../components/DynamicForm";
import SetupModal from "../../../components/SetupModal";
import API from "../../../../services/API";
import DataTable from 'react-data-table-component';
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

import roleSearch from "../../../forms/roleSearch";
import roleModal from "../../../forms/roleModal";
import ErrorModal from "../../../components/ErrorModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import VisitTimer from "../../../components/VisitTimer"
import Session from "../../../../services/Session"

function RoleSetup(props){
  const [filterValues,setFilterValues] = useState();
  const [jobs, setJobs] = useState([]);
  const [procedureOptions, setProcedureOptions] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [showSetupModal,setShowSetupModal] = useState(false);
  const [editValues, setEditValues] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const onFilterChanged = (values) => {
    setFilterValues(values)
  }

  const onEditChange = (values) => {
    setEditValues(values)
  }

  const onSave = () => {
    if(!isSaving) {
      setIsSaving(true)
      let data = {
        'name':editValues['name'],
        'procedures':editValues['procedures'],
        'facilities':editValues['facilities'],
        'status':(editValues['status'] && editValues['status'] == 'false')?false:true,
        'required':editValues['required']
      }
      let method = isEditMode ? 'PUT' : 'POST'
      let uri = isEditMode ? `/api/jobs/${editValues.id}/` : '/api/jobs/'
      API.query(method,uri,{},data, function(resp) {
        let newJobs = jobs
        if(isEditMode) {
          let i = newJobs.findIndex(j => j.id == editValues.id)
          if(i > 0) {
            data['id'] = editValues.id
            newJobs[i] = data
          }
        }else {
          data['id'] = resp.id
          newJobs.push(data)
        }
        setJobs(newJobs)
        setIsSaving(false)
        setShowSetupModal(false)
        setEditValues([])
      },onDataLoadError)
    }
  }

  const onSetupModalClosed = () => {
    setShowSetupModal(false)
    setEditValues([])
    setIsEditMode(false)
  }

  const onEditClicked = (row) => {
    row.status = row.status?"true":"false"
    row.required = row.required?"true":"false"
    setEditValues(row)
    setShowSetupModal(true)
    setIsEditMode(true)
  }

  const onDeleteClicked = (row) => {
    setEditValues(row)
    setShowConfirmationModal(true)
  }

  const onConfirmDelete = () => {
    API.query("DELETE",`/api/jobs/${editValues.id}/`,{},{}, function(resp) {
      let newJobs = jobs.filter(j => j.id !== editValues.id)
      setJobs(newJobs)
      setShowConfirmationModal(false)
      setEditValues([])
    },onDataLoadError)
  }

  const onButtonPressed = (id, row) => {
    if(id == "clear") {
      setFilterValues([])
    } else if (id == "create_new") {
      setShowSetupModal(true)
    } else {
      //unknown button
    }
  }

  const filterByStatus = (obj) => {
    var valid
    if(filterValues.status == "active") {
      if(obj.status) { valid=true; } else { valid = false; }
    } else if(filterValues.status == "inactive") {
      if(!obj.status) { valid=true; } else { valid=false; }
    } else {
      valid = true
    }
    return valid
  }

  const filterByFacility = (obj) => {
    var i, x, valid, abort=false
    if(filterValues.facilities && filterValues.facilities.length != 0) {
      if(obj.facilities.length != 0) {
        for(i=0; i < filterValues.facilities.length && !abort; i++) {
          for(x=0; x < obj.facilities.length && !abort; x++) {
            if(filterValues.facilities[i] == obj.facilities[x]) {
              valid = true
              abort = true
            } else {
              valid = false
            }
          }
        }
      } else {
        valid = false
      }
    } else {
      valid = true
    }
    return valid
  }

  const filterByProcedure = (obj) => {
    var y, z, valid, abort=false
    if(filterValues.procedures && filterValues.procedures.length != 0) {
      if(obj.procedures.length != 0) {
        for(y=0; y < filterValues.procedures.length && !abort; y++) {
          for(z=0; z < obj.procedures.length && !abort; z++) {
            if (filterValues.procedures[y] == obj.procedures[z]) {
              valid = true
              abort = true
            } else {
              valid = false
            }
          }
        }
      } else {
        valid = false
      }
    } else {
      valid = true
    }
    return valid
  }

  const filteredJobs = jobs.filter((obj) =>{
    let valid = false
    
    if(filterByStatus(obj) && filterByFacility(obj) && filterByProcedure(obj)) {
      valid = true
    }
    return valid;
  })

  const columns = useMemo(() => [
    {name:'Role Name',selector: row => row.name,sortable:true},
    {name:'Status',selector: row => row.status ? "Active" : "Inactive"},
    {name:'Actions',selector: row =><div>
    <a title="Edit" onClick={() => onEditClicked(row)}><FaPencilAlt size={14} /></a>
    <a className="ml-3" title="Delete" onClick={() => onDeleteClicked(row)}><FaTrashAlt size={14} /></a>
    </div>}
  ])

  useEffect(() => {
    let queries = [
      { 'method': 'GET', 'path': '/api/jobs/', },
      { 'method': 'GET', 'path': '/api/procedures/', },
      { 'method': 'GET', 'path': '/api/facilities/', }
    ]
    API.all(queries, function (resp) {
      setJobs(resp[0])
      setFacilities(resp[2].map(i => ({ "label": i.name, "value": i.id })))
      setProcedureOptions(resp[1].map(i => ({ "label": i.name, "value": i.id })))
    }, onDataLoadError)
  }, []);

  const onDataLoadError = (error) => {
    setErrorMessage(error)
    setShowErrorModal(true)
    setIsSaving(false)
  }
  
  return <div>
    <p className="title">Role Setup</p>
    <hr />
    <DynamicForm 
      form={roleSearch} 
      values={filterValues} 
      onValuesChanged={onFilterChanged} 
      onButtonPressed={onButtonPressed}
      source={{"procedures": procedureOptions, "facilities": facilities}}
    />
    <hr />

    {
      showSetupModal
      && 
      <SetupModal
        titleString={"Role Setup"}
        form={roleModal}
        editValues={editValues}
        onEditChange={onEditChange}
        source={{"procedures": procedureOptions, "facilities": facilities}}
        isSaving={isSaving}
        onSave={onSave}
        onClose={onSetupModalClosed}
      />
    }

    {
      showErrorModal
      && 
      <ErrorModal
        message={errorMessage}
        onClose={() => setShowErrorModal(false)}
      />
    }

    {
      showConfirmationModal
      && 
      <ConfirmationModal
        message={"You are about to delete a Role. This cannot be undone."}
        onConfirm={onConfirmDelete}
        onClose={() => setShowConfirmationModal(false)}
      />
    }

    <p className="title is-5">Results</p>
    <DataTable
      striped
      pagination
      data={filteredJobs}
      columns={columns}
    />

  </div>
}

export default RoleSetup