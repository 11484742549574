import React, { useState } from "react";
import DynamicForm from "./DynamicForm";
import personnelRoleForm from  "../forms/personnelRole";

function PersonnelRoleModal(props){
  //const [data,setData] = useState({...props.data})
  const {data, setData} = props
  const [missingFields, setMissingFields] = useState([])

  const onSave = () => {
    if (validate()) props.onSave(data)
  }

  const onCancel = () => {
    props.onClose()
  }

  const validate = () => {
    let missingFields = []
    personnelRoleForm.fields.forEach(f => {
      if (f.required && f.required == "true") {
        if (!(f.id in data) || data[f.id] == "" || data[f.id] == []) missingFields.push(f.id)
      }
    })
    if (missingFields.length > 0) setMissingFields(missingFields)
    return missingFields.length == 0
  }

  const isEdit = !!data.id

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head has-background-white">
        <p className="is-size-4">{isEdit?"Edit":"Add"} Role - Personnel</p>
      </header>
      
      <section style={{ paddingBottom: "300px" }} className="modal-card-body">
        <DynamicForm
          form={personnelRoleForm}
          values={data}
          onValuesChanged={setData}
          source={{
            personnel: props.personnelOptions,
            roles: props.roleOptions
          }}
          missingFields={missingFields}
        />
      </section>

      <footer className="modal-card-foot has-background-white">
        <div className="level w-100">
          <div className="level-left">
            <button className="level-item button is-danger is-inverted" onClick={onCancel}>Cancel</button>
          </div>
          <div className="level-right">
            <button className="level-item button is-success" onClick={onSave}>Save</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
}

export default PersonnelRoleModal