import React, { useEffect, useState } from "react"
import DynamicForm from "../../../components/DynamicForm"
import profileForm from "../../../forms/profileSettings"
import API from "../../../../services/API"
import Session from "../../../../services/Session"
import ConfirmationModal from "../../../components/ConfirmationModal"

function Profile(props) {
    const [profileValues, setProfileValues] = useState([])
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const onProfileChange = (values) => {
      setProfileValues(values)
    }

    const onConfirm = () => {
      setShowConfirmationModal(true)
    }

    const onSave = () => {
      let user = Session.get('user')
      let data = {
        'profile_photo': profileValues['profile_photo'],
        'first_name': profileValues['first_name'],
        'last_name': profileValues['last_name'],
        'phone': profileValues['phone'],
        'phone_type': profileValues['phone_type'],
        'birthday': profileValues['birthday'],
        'skills': profileValues['skills']
      }
      API.query('PATCH',`/api/users/${user.id}/`,{},data,function(resp){
        Session.set('user',resp)
        setShowConfirmationModal(false)
      })
    }

    console.log('profileValues',profileValues)

    useEffect(() => {
      let user = Session.get('user')
      user.phone_type = `${user.phone_type}`
      setProfileValues(user)
    }, []);

    return <div>
        <p className="title is-size-2">Profile Settings</p>
        <p>Change, edit, update or add new settings specific to your preferences.</p>
        <hr />
        <div className="columns">
          <div className="column is-4">
            <p className="title is-size-4">My Details</p>
            <p>Personal information, private, but some fields are not editable by the user.</p>
          </div>
          <div className="column is-8">
            <DynamicForm 
              form={profileForm}
              values={profileValues}
              onValuesChanged={onProfileChange}
            />
          </div>
        </div>

        {
          showConfirmationModal
          &&
          <ConfirmationModal 
            message={"You are about to update your profile. Please confirm."}
            onConfirm={onSave}
            onClose={() => setShowConfirmationModal(false)}
          />
        }

        <hr />
        <div className="level w-100">
          <div className="level-left">

          </div>
          <div className="level-right">
            <button className="button is-success" onClick={onConfirm}>Save</button>
          </div>
        </div>
    </div>
}

export default Profile;