import React, { useRef, useMemo, useState } from "react";
import { useReactToPrint } from 'react-to-print';
import Select from 'react-select';
import DatePicker from 'react-datepicker'
import API from "../../services/API";

function PdfLogReport(props) {
    const [filters, setFilters] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState()
    const componentRef = useRef()
    const today = new Date()

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onFilterChanged = (value, type) => {
        let fValues = { ...filters }
        if (type === 0) {
            fValues['start_date'] = value
        } else if (type === 1) {
            fValues['end_date'] = value
        } else if (type === 2) {
            fValues['facility'] = value
        }
        setFilters(fValues)
    }

    const renderData = () => {
        if (filters.facility != null) {
            if(!isLoading) {
                setIsLoading(true)
                let params = {
                    'facility_id': filters.facility
                }
                API.query('GET',`/api/inventorylogreports/`,params,{},function(resp) {
                    setData(resp)
                    setIsLoading(false)
                },onDataLoadError())
            }
        }
    }

    const filteredData = data ? data.filter((e, idx) => {
        var valid = true
        let caseDate = new Date(e.date_time)
        if (filters.start_date && filters.end_date) {
            let startDate = new Date(filters.start_date)
            let endDate = new Date(filters.end_date)
            if (caseDate >= startDate) {
                if (caseDate <= endDate) { valid = true } else { valid = false }
            } else { valid = false }
        } else {
            valid = true
        }
        return valid
    }) : null

    const onDataLoadError = (error) => {
        console.log(error)
    }

    const start_date = filters.start_date ? new Date(filters.start_date).toLocaleDateString() : ''
    const end_date = filters.end_date ? new Date(filters.end_date).toLocaleDateString() : ''

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card modal-extra-large">
            <header className="modal-card-head">
                <p className="modal-card-title">Inventory Log Report</p>
            </header>

            <section className="modal-card-body p-0">
                <div className="p-5">
                    <div className="w-100">
                        <div className="columns">
                            <div className="column"></div>
                            <div className="column">
                                <div style={{ midWidth: '499px', maxWidth: '500px' }}>
                                    <Select
                                        options={props.facilities}
                                        placeholder={'Select a Facility'}
                                        value={props.facilities.find((e) => e.value == filters.facility)}
                                        onChange={(option) => onFilterChanged(option.value, 2)}
                                    />
                                </div>
                            </div>
                            <div className="column"></div>
                        </div>
                        <div className="level w-100">
                            <div className="level-item"></div>
                            <div className="level-item">
                                <div style={{ maxWidth: '200px', minWidth: '200px' }}>
                                    <DatePicker
                                        className="input"
                                        selectsStart
                                        startDate={filters.start_date}
                                        endDate={filters.end_date}
                                        selected={filters.start_date}
                                        onChange={(date) => onFilterChanged(date, 0)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        maxDate={new Date()}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Start Date"
                                    />
                                </div>
                            </div>
                            <div className="level-item">
                                <div style={{ maxWidth: '200px', minWidth: '200px' }}>
                                    <DatePicker
                                        className="input"
                                        selectsStart
                                        startDate={filters.start_date}
                                        endDate={filters.end_date}
                                        selected={filters.end_date}
                                        onChange={(date) => onFilterChanged(date, 1)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        maxDate={new Date()}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="End Date"
                                    />
                                </div>
                            </div>
                            <div className="level-item"></div>
                        </div>

                        <div className="level w-100">
                            <div className="level-item has-text-centered">
                                <button className="button is-success" onClick={() => renderData()}>Generate</button>
                            </div>
                        </div>

                        {
                            !filteredData
                            &&
                            <div>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        }

                        {
                            filteredData
                            &&
                            <div ref={componentRef} className="has-background-white">
                                <div className="level w-100">
                                    <div className="level-left">
                                        <p className="title is-5">Inventory Log Report</p>
                                    </div>
                                    <div className="level-right">
                                        <p>{`(Printed ${today.toLocaleDateString()})`}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="columns">
                                    <div className="column is-2">
                                        <p className="has-text-weight-semibold">Hospital:</p>
                                        <p className="has-text-weight-semibold">Date Range:</p>
                                    </div>
                                    <div className="column is-6">
                                        <p>{props.facilities.find(e => e.value == filters.facility).label}</p>
                                        <p>{`${start_date} - ${end_date}`}</p>
                                    </div>
                                    <div className="column is-4"></div>
                                </div>

                                <hr />

                                <table className="table is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Manufacturer</th>
                                            <th>Catalog</th>
                                            <th>Old On Hand</th>
                                            <th>Diff</th>
                                            <th>New On Hand</th>
                                            <th>Old Par</th>
                                            <th>New Par</th>
                                            <th>User</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            filteredData.reverse().map((e, idx) => {
                                                return <tr key={idx}>
                                                    <td>{e.date_time.split(' ', 1)[0]}</td>
                                                    <td>{e.date_time.split(' ', 3)[1]} {e.date_time.split(' ', 3)[2]}</td>
                                                    <td>{e.manufacturer}</td>
                                                    <td>{e.catalog}</td>
                                                    <td>{e.old_on_hand}</td>
                                                    <td>{e.diff}</td>
                                                    <td>{e.new_on_hand}</td>
                                                    <td>{e.old_par}</td>
                                                    <td>{e.new_par}</td>
                                                    <td>{e.user_name}</td>
                                                    <td>{e.status}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>

                <button onClick={props.onClose} className="modal-close is-large" aria-label="close"></button>
            </section>

            <footer className="modal-card-foot">
                <div className="level w-100">
                    <div className="level-left">
                        <button className="button is-danger" onClick={props.onClose}>Close</button>
                    </div>
                    <div className="level-right">
                        {
                            data
                            &&
                            <button className="button is-success" onClick={handlePrint}>Print</button>
                        }
                    </div>
                </div>
            </footer>
        </div>
    </div>
}

export default PdfLogReport