import React, { useState, useEffect, useMemo } from "react";
import DynamicForm from "../../../components/DynamicForm";
import API from "../../../../services/API";
import chartingProcSearch from "../../../forms/chartingProcSearch";
import chartingLocSearch from "../../../forms/chartingLocSearch"
import DataTable from 'react-data-table-component';
import eventForm from "../../../forms/chartingDetailEvent";
import medicationForm from "../../../forms/chartingDetailMedication";
import { FaTrashAlt } from "react-icons/fa";
import ConfirmationModal from "../../../components/ConfirmationModal";
import VisitTimer from "../../../components/VisitTimer";
import Select from "react-select";
import Session from "../../../../services/Session"

function ChartingDetails(props) {
  const [activeTab, setActiveTab] = useState(1);
  const [chartingDetails, setChartingDetails] = useState([]);
  const [medications, setMedications] = useState([]);
  const [medicationOptions, setMedicationOptions] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventOptions, setEventOptions] = useState([]);
  const [procFilterValues, setProcFilterValues] = useState([]);
  const [locFilterValues, setLocFilterValues] = useState([])
  const [procedureOptions, setProcedureOptions] = useState([]);
  const [medicationAssociation, setMedicationAssociation] = useState([]);
  const [eventAssociation, setEventAssociation] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [editValues, setEditValues] = useState([])
  const [showMedConfirmation, setShowMedConfirmation] = useState(false)
  const [showEventConfirmation, setShowEventConfirmation] = useState(false)
  const [labNumberValue, setLabNumberValue] = useState(0)
  const [labSelectValues, setLabSelectValues] = useState({})
  const [cpgNumberValue, setCPGNumberValue] = useState(0)
  const [cpgSelectValues, setCPGSelectValues] = useState({})
  const [autoNumberValue, setAutoNumberValue] = useState(0)
  const [autoSelectValues, setAutoSelectValues] = useState({})
  const [facilityOptions, setFacilityOptions] = useState([])
  const [showSaveConfirmationModal, setShowSaveConfirmationModal] = useState(false)

  const timeOptions = [
    { value: 'sec', label: 'Seconds' },
    { value: 'min', label: 'Minutes' },
    { value: 'hour', label: 'Hours'}
  ]

  useEffect(() => {
    let queries = [
      { 'method': 'GET', 'path': '/api/procedures/' },
      { 'method': 'GET', 'path': '/api/medications/' },
      { 'method': 'GET', 'path': '/api/events/' },
      { 'method': 'GET', 'path': '/api/chartingdetails/' },
      { 'method': 'GET', 'path': '/api/facilities/' }
    ]
    API.all(queries, function (resp) {
      //remove filter on proc options when additional charts for other procedures are incorporated
      setProcedureOptions(resp[0].filter(e => e.name === 'CPB').map(i => ({ "label": i.name, "value": i.id })))
      setMedications(resp[1])
      setMedicationOptions(resp[1].map(i => ({ "label": i.medication, "value": i.id })))
      setEvents(resp[2])
      setEventOptions(resp[2].map(i => ({ "label": i.name, "value": i.id })))
      setChartingDetails(resp[3])
      if(props.permissions.includes(11) || props.extraPermissions.includes(11)) {
        let user = Session.get('user')
        let facils = resp[4]
        let filtered = facils.filter((obj) => {
          let i, abort = false, valid = false
          for(i=0; i < user.facilities.length; i++) {
            if(user.facilities[i] == obj.id) {
              valid = true
              abort = true
            }
          }
          return valid
        })
        setFacilityOptions(filtered.map(i => ({ "label": i.name, "value": i.id })))
      } else {
        setFacilityOptions(resp[4].map(i => ({ "label": i.name, "value": i.id })))
      }
    }, onDataLoadError)
  }, []);

  const findSelectedDetail = () => {
    var x, abort = false, selectedDetail
    for (x = 0; x < chartingDetails.length && !abort; x++) {
      if (procFilterValues.procedure == chartingDetails[x].procedure) {
        if (locFilterValues.facility == chartingDetails[x].facility) {
          selectedDetail = chartingDetails[x]
          abort = true
        }
      }
    }
    return selectedDetail
  }

  const onDataLoadError = (error) => {
    console.log(error)
  }

  const onMedicationChange = (value) => {
    setMedicationAssociation(value)
  }

  const onEventChange = (value) => {
    setEventAssociation(value)
  }

  const onProcFilterChange = (value) => {
    setProcFilterValues(value)

    let detail = chartingDetails.find((obj) => {
      var valid = false

      if (locFilterValues.facility != null) {
        if (obj.facility == locFilterValues.facility) {
          if (obj.procedure == value.procedure) {
            valid = true
          }
        }
      }

      return valid
    })

    if (detail != null) {
      let labSelect = timeOptions.find((time) => {
        let valid = false
        if(time.value == detail.lab_warning_unit) valid = true
        return valid
      })

      let cpgSelect = timeOptions.find((time) => {
        let valid = false
        if(time.value == detail.cpg_warning_unit) valid = true
        return valid
      })

      let autoSelect = timeOptions.find((time) => {
        let valid = false
        if(time.value == detail.auto_logging_unit) valid = true
        return valid
      })

      //setLabValues({ lab_warning_time: detail.lab_warning_time, lab_warning_unit: detail.lab_warning_unit })
      setLabSelectValues(labSelect)
      setLabNumberValue(detail.lab_warning_time)

      //setCPGValues({ cpg_warning_time: detail.cpg_warning_time, cpg_warning_unit: detail.cpg_warning_unit })
      setCPGSelectValues(cpgSelect)
      setCPGNumberValue(detail.cpg_warning_time)

      //setAutoValues({ auto_logging_time: detail.auto_logging_time, auto_logging_unit: detail.auto_logging_unit })
      setAutoSelectValues(autoSelect)
      setAutoNumberValue(detail.auto_logging_time)
    } else if (locFilterValues.facility != null) {
      let data = {
        'facility': locFilterValues.facility,
        'procedure': value.procedure
      }
      API.query('POST', `/api/chartingdetails/`, {}, data, function (resp) {
        let newDetails = [...chartingDetails]
        newDetails.push(resp)
        setChartingDetails(newDetails)
        setLabSelectValues({ value: 'min', label: 'Minutes' })
        setCPGSelectValues({ value: 'min', label: 'Minutes' })
        setAutoSelectValues({ value: 'min', label: 'Minutes' })
        setLabNumberValue(resp.lab_warning_time)
        setCPGNumberValue(resp.cpg_warning_time)
        setAutoNumberValue(resp.auto_logging_time)
      }, onDataLoadError)
    }
  }

  const onLocFilterChange = (value) => {
    setLocFilterValues(value)
    let detail = chartingDetails.find((obj) => {
      var valid = false

      if (procFilterValues.procedure != null) {
        if (obj.procedure == procFilterValues.procedure) {
          if (obj.facility == value.facility) {
            valid = true
          }
        }
      }

      return valid
    })

    if (detail != null) {
      let labSelect = timeOptions.find((time) => {
        let valid = false
        if(time.value == detail.lab_warning_unit) valid = true
        return valid
      })

      let cpgSelect = timeOptions.find((time) => {
        let valid = false
        if(time.value == detail.cpg_warning_unit) valid = true
        return valid
      })

      let autoSelect = timeOptions.find((time) => {
        let valid = false
        if(time.value == detail.auto_logging_unit) valid = true
        return valid
      })

      //setLabValues({ lab_warning_time: detail.lab_warning_time, lab_warning_unit: detail.lab_warning_unit })
      setLabSelectValues(labSelect)
      setLabNumberValue(detail.lab_warning_time)

      //setCPGValues({ cpg_warning_time: detail.cpg_warning_time, cpg_warning_unit: detail.cpg_warning_unit })
      setCPGSelectValues(cpgSelect)
      setCPGNumberValue(detail.cpg_warning_time)

      //setAutoValues({ auto_logging_time: detail.auto_logging_time, auto_logging_unit: detail.auto_logging_unit })
      setAutoSelectValues(autoSelect)
      setAutoNumberValue(detail.auto_logging_time)
    } else {
      let data = {
        'facility': value.facility,
        'procedure': procFilterValues.procedure
      }
      API.query('POST', `/api/chartingdetails/`, {}, data, function (resp) {
        let newDetails = [...chartingDetails]
        newDetails.push(resp)
        setChartingDetails(newDetails)
        setLabSelectValues({ value: 'min', label: 'Minutes' })
        setCPGSelectValues({ value: 'min', label: 'Minutes' })
        setAutoSelectValues({ value: 'min', label: 'Minutes' })
        setLabNumberValue(resp.lab_warning_time)
        setCPGNumberValue(resp.cpg_warning_time)
        setAutoNumberValue(resp.auto_logging_time)
      }, onDataLoadError)
    }
  }

  const filteredMedicationOptions = () => {
    let selectedDetail = findSelectedDetail()
    let filteredMedicationOptions = medicationOptions
    if (selectedDetail != null) {
      filteredMedicationOptions = medicationOptions.filter((obj) => {
        var i, valid = true, abort = false
        for (i = 0; i < selectedDetail.medications.length && !abort; i++) {
          if (selectedDetail.medications[i] == obj.value) {
            valid = false
            abort = true
          }
        }
        return valid
      })
    }


    return filteredMedicationOptions
  }


  const filteredEventOptions = () => {
    let selectedDetail = findSelectedDetail()
    let filteredEventOptions = eventOptions

    if (selectedDetail != null) {
      filteredEventOptions = eventOptions.filter((obj) => {
        var i, valid = true, abort = false
        for (i = 0; i < selectedDetail.events.length && !abort; i++) {
          if (selectedDetail.events[i] == obj.value) {
            valid = false
            abort = true
          }
        }
        return valid
      })
    }

    return filteredEventOptions
  }

  const filteredEvents = () => {
    let selectedDetail = findSelectedDetail()

    let filteredEvents = []

    if (selectedDetail != null) {
      filteredEvents = events.filter((obj) => {
        var i, abort2 = false, valid = false
        for (i = 0; i < selectedDetail.events.length && !abort2; i++) {
          if (selectedDetail.events[i] == obj.id) {
            valid = true
            abort2 = false
          }
        }
        return valid
      })
    }

    return filteredEvents
  }

  const filteredMedications = () => {
    let selectedDetail = findSelectedDetail()

    let filteredMedication = []

    if (selectedDetail != null) {
      filteredMedication = medications.filter((obj) => {
        var i, abort2 = false, valid = false
        for (i = 0; i < selectedDetail.medications.length && !abort2; i++) {
          if (selectedDetail.medications[i] == obj.id) {
            valid = true
            abort2 = false
          }
        }
        return valid
      })
    }

    return filteredMedication
  }

  const toggleTab = (index) => {
    setActiveTab(index)
  }

  const onMedDeleteClicked = (row) => {
    setEditValues(row)
    setShowMedConfirmation(true)
  }

  const onEventDeleteClicked = (row) => {
    setEditValues(row)
    setShowEventConfirmation(true)
  }

  const medColumns = useMemo(() => [
    { name: 'Name', selector: row => row.medication, sortable: true },
    { name: 'Notes', selector: row => row.description, sortable: true },
    { name: 'Unit', selector: row => row.unit, sortable: true },
    {
      name: 'Actions', selector: row => <div>
        <a className="ml-3" title="Delete" onClick={() => onMedDeleteClicked(row)}><FaTrashAlt size={14} /></a>
      </div>
    }
  ])

  const eventColumns = useMemo(() => [
    { name: 'Event', selector: row => row.name, sortable: true },
    { name: 'Details', selector: row => row.details, sortable: true },
    { name: 'Unit', selector: row => row.unit, sortable: true },
    {
      name: 'Actions', selector: row => <div>
        <a className="ml-3" title="Delete" onClick={() => onEventDeleteClicked(row)}><FaTrashAlt size={14} /></a>
      </div>
    }
  ])

  const appendMedication = (selectedMedications, newMedID) => {
    let newMedications = selectedMedications
    newMedications.push(newMedID)
    return newMedications
  }

  const appendEvent = (selectedEvents, newEventID) => {
    let newEvents = selectedEvents
    newEvents.push(newEventID)
    return newEvents
  }

  const onMedAdd = () => {
    let selectedDetail = findSelectedDetail()
    if (!isSaving) {
      setIsSaving(true)
      let data = {
        'medications': appendMedication(selectedDetail['medications'], medicationAssociation.medication),
        'events': selectedDetail['events'],
        'procedure': selectedDetail['procedure'],
        'facility': selectedDetail['facility'],
        'lab_warning_time': selectedDetail['lab_warning_time'],
        'lab_warning_unit': selectedDetail['lab_warning_unit'],
        'cpg_warning_time': selectedDetail['cpg_warning_time'],
        'cpg_warning_unit': selectedDetail['cpg_warning_unit'],
        'auto_logging_time': selectedDetail['auto_logging_time'],
        'auto_logging_unit': selectedDetail['auto_logging_unit'],
      }
      API.query('PUT', `/api/chartingdetails/${selectedDetail.id}/`, {}, data, function (resp) {
        let newDetails = chartingDetails
        let i = newDetails.findIndex(j => j.id == selectedDetail.id)
        if (i > -1) {
          data['id'] = selectedDetail.id
          newDetails[i] = data
        }

        setChartingDetails(newDetails)
        setIsSaving(false)
        setMedicationAssociation([])
      }, onDataLoadError)
    }
  }

  const onEventAdd = () => {
    let selectedDetail = findSelectedDetail()
    if (!isSaving) {
      setIsSaving(true)
      let data = {
        'medications': selectedDetail['medications'],
        'events': appendEvent(selectedDetail['events'], eventAssociation.event),
        'procedure': selectedDetail['procedure'],
        'facility': selectedDetail['facility'],
        'lab_warning_time': selectedDetail['lab_warning_time'],
        'lab_warning_unit': selectedDetail['lab_warning_unit'],
        'cpg_warning_time': selectedDetail['cpg_warning_time'],
        'cpg_warning_unit': selectedDetail['cpg_warning_unit'],
        'auto_logging_time': selectedDetail['auto_logging_time'],
        'auto_logging_unit': selectedDetail['auto_logging_unit'],
      }
      API.query('PUT', `/api/chartingdetails/${selectedDetail.id}/`, {}, data, function (resp) {
        let newDetails = chartingDetails
        let i = newDetails.findIndex(j => j.id == selectedDetail.id)
        if (i > -1) {
          data['id'] = selectedDetail.id
          newDetails[i] = data
        }

        setChartingDetails(newDetails)
        setIsSaving(false)
        setEventAssociation([])
      }, onDataLoadError)
    }
  }

  const onMedConfirmDelete = () => {
    let selectedDetail = findSelectedDetail()
    let data = {
      'medications': removeMed(selectedDetail['medications'], editValues.id),
      'events': selectedDetail['events'],
      'procedure': selectedDetail['procedure'],
      'facility': selectedDetail['facility'],
      'lab_warning_time': selectedDetail['lab_warning_time'],
      'lab_warning_unit': selectedDetail['lab_warning_unit'],
      'cpg_warning_time': selectedDetail['cpg_warning_time'],
      'cpg_warning_unit': selectedDetail['cpg_warning_unit'],
      'auto_logging_time': selectedDetail['auto_logging_time'],
      'auto_logging_unit': selectedDetail['auto_logging_unit'],
    }
    API.query("PUT", `/api/chartingdetails/${selectedDetail.id}/`, {}, data, function (resp) {
      // let newMeds = medications.filter(j => j.id !== editValues.id)
      // setMedications(newMeds)
      setShowMedConfirmation(false)
      setEditValues([])
      API.query("GET", `/api/chartingdetails/`, {}, {}, function (resp) {
        setChartingDetails(resp)
      }, onDataLoadError)
    }, onDataLoadError)
  }

  const onEventConfirmDelete = () => {
    let selectedDetail = findSelectedDetail()
    let data = {
      'medications': selectedDetail['medications'],
      'events': removeEvent(selectedDetail['events'], editValues.id),
      'procedure': selectedDetail['procedure'],
      'facility': selectedDetail['facility'],
      'lab_warning_time': selectedDetail['lab_warning_time'],
      'lab_warning_unit': selectedDetail['lab_warning_unit'],
      'cpg_warning_time': selectedDetail['cpg_warning_time'],
      'cpg_warning_unit': selectedDetail['cpg_warning_unit'],
      'auto_logging_time': selectedDetail['auto_logging_time'],
      'auto_logging_unit': selectedDetail['auto_logging_unit'],
    }
    API.query("PUT", `/api/chartingdetails/${selectedDetail.id}/`, {}, data, function (resp) {
      // let newEvents = events.filter(j => j.id !== editValues.id)
      // setEvents(newEvents)
      setShowEventConfirmation(false)
      setEditValues([])
      API.query("GET", `/api/chartingdetails/`, {}, {}, function (resp) {
        setChartingDetails(resp)
      }, onDataLoadError)
    }, onDataLoadError)
  }

  const onReminderSave = () => {
    let selectedDetail = findSelectedDetail()
    if (!isSaving) {
      setIsSaving(true)
      let data = {
        'medications': selectedDetail['medications'],
        'events': selectedDetail['events'],
        'procedure': selectedDetail['procedure'],
        'facility': locFilterValues.facility,
        'lab_warning_time': labNumberValue,
        'lab_warning_unit': labSelectValues.value,
        'cpg_warning_time': cpgNumberValue,
        'cpg_warning_unit': cpgSelectValues.value,
        'auto_logging_time': autoNumberValue,
        'auto_logging_unit': autoSelectValues.value
      }
      API.query('PUT', `/api/chartingdetails/${selectedDetail.id}/`, {}, data, function (resp) {
        let newDetails = [...chartingDetails]
        let i = newDetails.findIndex(j => j.id == selectedDetail.id)
        if (i > -1) {
          data['id'] = selectedDetail.id
          newDetails[i] = data
        }
        setShowSaveConfirmationModal(false)
        setChartingDetails(newDetails)
        setIsSaving(false)
      }, onDataLoadError)
    }
  }

  const removeMed = (medArray, id) => {
    let newMedArray = medArray.filter((obj) => {
      var valid = true
      if (obj == id) {
        valid = false
      }
      return valid
    })
    return newMedArray
  }

  const removeEvent = (eventArray, id) => {
    let newEventArray = eventArray.filter((obj) => {
      var valid = true
      if (obj == id) {
        valid = false
      }
      return valid
    })
    return newEventArray
  }

  const visited = () => {
    // get pageviewhistory id from user session data
    var user = Session.get('user')
    API.query('GET', `/api/pageviewhistory/${user.page_view_history}/`, {}, {}, function (response) {
      let data = {
        "setup_charting_details": response.setup_charting_details + 1
      }
      API.query('PATCH', `/api/pageviewhistory/${user.page_view_history}/`, {}, data, function (resp) { }, onDataLoadError)
    }, onDataLoadError)
  }

  const getOptionLabelByValue = (source, id) => {
    let option = source.find(o => o.value == id)
    return option ? option.label : null
  }

  const onTimeChange = (section, value) => {
    if(section == 'lab') {
      setLabNumberValue(value)
    } else if(section == 'cpg') {
      setCPGNumberValue(value)
    } else if(section == 'auto') {
      setAutoNumberValue(value)
    }
  }

  const onTimerSelectChange = (section, value) => {
    if(section == 'lab') {
      setLabSelectValues(value)
    } else if(section == 'cpg') {
      setCPGSelectValues(value)
    } else if(section == 'auto') {
      setAutoSelectValues(value)
    }
  }

  const onReminderSaveClick = () => {
    setShowSaveConfirmationModal(true)
  }

  return <div>
    <p className="title">Charting Details Setup</p>
    <DynamicForm
      form={chartingProcSearch}
      values={procFilterValues}
      onValuesChanged={onProcFilterChange}
      source={{ "procedures": procedureOptions }}
    />

    <br />

    {
      procFilterValues.procedure != null
      &&
      <div>
        <DynamicForm
          form={chartingLocSearch}
          values={locFilterValues}
          onValuesChanged={onLocFilterChange}
          source={{ "facilities": facilityOptions }}
        />
      </div>
    }

    {
      locFilterValues.facility != null
      &&
      <div>
        <br />
        <div className="columns is-centered">
          <p>{getOptionLabelByValue(procedureOptions, procFilterValues.procedure)} {' > '} {getOptionLabelByValue(facilityOptions, locFilterValues.facility)} {'>'} Results </p>
        </div>
        
        <div className="tabs is-boxed">
          <ul>
            <li className={activeTab === 1 ? "is-active" : ""}>
              <a onClick={() => toggleTab(1)}>Medications</a>
            </li>
            <li className={activeTab === 2 ? "is-active" : ""}>
              <a onClick={() => toggleTab(2)}>Events</a>
            </li>
            <li className={activeTab === 3 ? "is-active" : ""}>
              <a onClick={() => toggleTab(3)}>Reminders/Timers</a>
            </li>
          </ul>
        </div>
        <div>
          <div className={activeTab === 1 ? "is-active" : "is-hidden"}>
            <div className="columns is-centered is-vcentered">
              <div className="column is-half">
                <DynamicForm
                  form={medicationForm}
                  values={medicationAssociation}
                  source={{ "medications": filteredMedicationOptions() }}
                  onValuesChanged={onMedicationChange}
                />
              </div>
              <button onClick={onMedAdd} className="button is-small">Add</button>
            </div>
            <DataTable
              striped
              pagination
              data={filteredMedications()}
              columns={medColumns}
            />
          </div>
          <div className={activeTab === 2 ? "is-active" : "is-hidden"}>
            <div className="columns is-centered is-vcentered">
              <div className="column is-half">
                <DynamicForm
                  form={eventForm}
                  values={eventAssociation}
                  source={{ "events": filteredEventOptions() }}
                  onValuesChanged={onEventChange}
                />
              </div>
              <button onClick={onEventAdd} className="button is-small">Add</button>
            </div>
            <DataTable
              striped
              pagination
              data={filteredEvents()}
              columns={eventColumns}
            />
          </div>
          <div className={activeTab === 3 ? "is-active" : "is-hidden"}>
            <br />
            <div className="columns is-centered">
              <p className="title">Lab Warning</p>
              <br />
            </div>
            <div className="columns is-centered">
              <div style={{maxWidth: 200, minWidth: 199, marginRight: 40}}>
              <input 
                type="number"
                className="input"
                onChange={(e) => onTimeChange('lab', e.target.value)}
                value={labNumberValue}
              />
              </div>
              <div style={{maxWidth: 200, minWidth: 199}}>
              <Select 
                options={timeOptions}
                isSearchable={false}
                isMulti={false}
                placeholder={'Select a Time Unit'}
                onChange={(option) => onTimerSelectChange('lab', option)}
                value={labSelectValues}
              />
              </div>
              
            </div>
            <br />
            <div className="columns is-centered">
              <p className="title">CPG Warning</p>
              <br />
            </div>
            <div className="columns is-centered">
              <div style={{maxWidth: 200, minWidth: 199, marginRight: 40}}>
              <input 
                type="number"
                className="input"
                onChange={(e) => onTimeChange('cpg', e.target.value)}
                value={cpgNumberValue}
              />
              </div>
              <div style={{maxWidth: 200, minWidth: 199}}>
              <Select 
                options={timeOptions}
                isSearchable={false}
                isMulti={false}
                placeholder={'Select a Time Unit'}
                onChange={(option) => onTimerSelectChange('cpg', option)}
                value={cpgSelectValues}
              />
              </div>
            </div>
            <br />
            <div className="columns is-centered">
              <p className="title">Auto Logging</p>
              <br />
            </div>
            <div className="columns is-centered">
              <div style={{maxWidth: 200, minWidth: 199, marginRight: 40}}>
              <input 
                type="number"
                className="input"
                onChange={(e) => onTimeChange('auto', e.target.value)}
                value={autoNumberValue}
              />
              </div>
              <div style={{maxWidth: 200, minWidth: 199}}>
              <Select 
                options={timeOptions}
                isSearchable={false}
                isMulti={false}
                placeholder={'Select a Time Unit'}
                onChange={(option) => onTimerSelectChange('auto', option)}
                value={autoSelectValues}
              />
              </div>
            </div>
            <br />
            <div className="level w-100">
              <div className="level-left">

              </div>
              <div className="level-right">
                <button className="button is-success" onClick={onReminderSaveClick}>Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    {
      showMedConfirmation
      &&
      <ConfirmationModal
        message={"You are about to delete a Medication. This cannot be undone."}
        onConfirm={onMedConfirmDelete}
        onClose={() => setShowMedConfirmation(false)}
      />
    }

    {
      showEventConfirmation
      &&
      <ConfirmationModal
        message={"You are about to delete an Event. This cannot be undone."}
        onConfirm={onEventConfirmDelete}
        onClose={() => setShowEventConfirmation(false)}
      />
    }

    {
      showSaveConfirmationModal
      &&
      <ConfirmationModal
        message={"You are about to update Reminders and Timers. This cannot be undone."}
        onConfirm={onReminderSave}
        onClose={() => setShowSaveConfirmationModal(false)}
      />
    }

    <VisitTimer visited={visited} />

  </div>
}

export default ChartingDetails