import React, { Component } from 'react';

class VisitTimer extends Component {
    constructor(props) {
      super(props)
      this.state = {
        timeToGo: 15
      }
    }

    componentDidMount() {
        this.interval = setInterval(
            () => {
              if(this.state.timeToGo > 0) {
                this.setState(prevState => ({
                  timeToGo: prevState.timeToGo - 1
                }))
              } else {
                clearInterval(this.interval)
                this.props.visited()
              }
            },
            1000
        )
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }
    render() {
        return <div>

        </div>
    }
}

export default VisitTimer